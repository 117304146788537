import { InputLabelClassKey, InputLabelProps } from '@material-ui/core/InputLabel'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import { atlasTextSmall } from 'components/atlas-components/typography'

export const MuiInputLabel: Partial<StyleRules<InputLabelClassKey, InputLabelProps>> = {
    root: {
        ...atlasTextSmall
    },
    outlined: {
        transform: 'none',
        zIndex: 0,
        '&$shrink': {
            marginLeft: '2px',
            transform: 'none'
        }
    },
    shrink: {
        transform: 'none'
    }
}
