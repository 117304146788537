import React, { useState, FC } from 'react'
import styled from '@emotion/styled'
import UtilityIconBar from '../sectionlist/UtilityIconBar'
import { DocumentDetailsLayout } from 'assets/styles/LayoutConstants'
import { useCommitteesState } from 'components/contexts/committee-context'
import { translate } from 'components/shared/internationalization'
import MarkCompleteView from './MarkCompleteView/MarkCompleteView'
import { SendInvitationButton } from 'components/shared/StyledComponents'
import { NormalizedReviewer } from '../MinutesReview/MinutesReviewContainer'
import { countlyEvent } from 'businesslayer/Countly'
import { Toolbar } from '../../shared/Toolbar'
import { useSavingContext } from 'components/contexts/saving'
import { css } from '@emotion/css'
import { PopoverButtonCss } from '../sectionlist/toolbar/StatusPopoverButton/StatusPopoverButton'

const toolbarHeight = DocumentDetailsLayout.toolbar.height

type Props = {
    userRole: UserRole
    isInvited?: boolean
    isToolbarLabelsCollapsed: boolean
    minutesId: string
    minutesTitle: string
    currentReview: NormalizedReviewer | null
    onToggleExpandAll: (isAllExpanded: boolean) => void
    onMarkedComplete: () => void
    onStatusChange: (status: DocumentStatusKey) => void
}

export const MinutesReviewToolbar = (props: Props) => {
    const { isToolbarLabelsCollapsed, onToggleExpandAll } = props
    const [isCompleting, setIsCompleting] = useState(false)
    const { currentCommittee } = useCommitteesState()
    const { isSaving } = useSavingContext()

    /**
     * Helper Function for toggling Modal
     */
    const toggleIsCompleting = () => {
        if (!isCompleting) {
            countlyEvent('Reviewer_MarkComplete_Button')
        }
        setIsCompleting(!isCompleting)
    }
    /**
     * Submission success for invite reviewers
     */
    const handleMarkCompleteSuccess = () => {
        toggleIsCompleting()
        props.onMarkedComplete()
    }

    const margin = <Margin />
    return (
        <Wrapper>
            <Toolbar>
                {({ isSavingSection, currentMinuteItem, minutesSections }) => (
                    <>
                        <ReviewerToolbar
                            currentMinuteItem={currentMinuteItem}
                            minutesSections={minutesSections}
                            role={props.userRole}
                            shouldShowButton={
                                !!props.currentReview && !props.currentReview.reviewCompleted
                            }
                            isToolbarLabelsCollapsed={isToolbarLabelsCollapsed}
                            isSavingSection={isSavingSection || isSaving}
                            onToggleExpandAll={onToggleExpandAll}
                            onMarkCompleteClick={toggleIsCompleting}
                            disabled={!currentCommittee || !props.minutesTitle}
                            dueDate={
                                !!props.currentReview ? props.currentReview.dueDate : undefined
                            }
                            onStatusChange={props.onStatusChange}
                            isInvited={props.isInvited}
                        />
                        {margin}
                    </>
                )}
            </Toolbar>
            {isCompleting && currentCommittee && (
                <MarkCompleteView
                    isVisible={isCompleting}
                    onClose={toggleIsCompleting}
                    onSubmit={handleMarkCompleteSuccess}
                    minutesId={props.minutesId}
                    minutesName={props.minutesTitle}
                    workroomName={currentCommittee.name}
                />
            )}
        </Wrapper>
    )
}

const ReviewerToolbar = (props: {
    currentMinuteItem: any
    minutesSections: any[]
    role: UserRole
    isToolbarLabelsCollapsed: boolean
    isSavingSection?: boolean
    onToggleExpandAll: (isAllExpanded: boolean) => void
    onMarkCompleteClick: () => void
    shouldShowButton: boolean
    disabled?: boolean
    dueDate?: string
    onStatusChange: (status: DocumentStatusKey) => void
    isInvited?: boolean
}) => {
    return (
        <>
            <UtilityIconBar
                currentMinuteItem={props.currentMinuteItem}
                minutesSections={props.minutesSections}
                withExport={false}
                isIconOnlyMode={props.isToolbarLabelsCollapsed}
                isSavingSection={props.isSavingSection}
                onToggleExpandAll={props.onToggleExpandAll}
                onStatusChange={props.role !== 'ADMIN' ? undefined : props.onStatusChange}
                disableSubmitStatus
                collapseAllVisible
                disabled={props.disabled}
            >
                {props.shouldShowButton && props.isInvited && (
                    <MarkCompleteButton
                        data-testid="CompleteMyReviewBtn"
                        disabled={props.disabled}
                        isIconOnlyMode={props.isToolbarLabelsCollapsed}
                        onClick={props.onMarkCompleteClick}
                    />
                )}
            </UtilityIconBar>
        </>
    )
}

interface ToolbarButtonProps {
    isIconOnlyMode: boolean
    onClick: () => void
    disabled?: boolean
    'data-testid'?: string
}

const noop = () => {}

const MarkCompleteButton: FC<ToolbarButtonProps> = ({
    onClick,
    disabled,
    'data-testid': dataTestid
}) => {
    return (
        <SendInvitationButton
            data-testid={dataTestid}
            onClick={disabled ? noop : onClick}
            className={css`
                ${PopoverButtonCss}
            `}
            disableFocusRipple
            disableRipple
            disabled={disabled}
        >
            <StyledCenterButton>
                <span>{translate('MARK_COMPLETE')}</span>
            </StyledCenterButton>
        </SendInvitationButton>
    )
}

/* width: ${(props: { widthOffset: number }) => `calc(100vw - ${props.widthOffset}px);`}; */
const Wrapper = styled('div')`
    min-height: ${toolbarHeight}px;
    height: ${toolbarHeight}px;
`

const StyledCenterButton = styled('div')`
    display: flex;
    align-items: center;
    text-transform: none;
`
export const Separator = styled('div')`
    height: 70%;
    border-right: 2px solid #e0e0e0;
    margin-right: 10px;
    margin-left: 10px;
`
const Margin = styled('div')`
    margin-right: 10px;
`

export default MinutesReviewToolbar
