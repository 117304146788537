import { getJSON, postJSON } from 'common/util/request'

export const getReviewers = (committeeId) =>
    getJSON(`committees/${committeeId}/users`, {
        shouldNormalize: false
    })

export interface InviteReviewers {
    body: string
    recipients: Array<{ id: number; email: string }>
    subject: string
    date?: string
}

export const inviteReviewers = (committeeId: string, minutesId: string, data: InviteReviewers) =>
    postJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/reviews/invite_reviewers`,
        JSON.stringify(data),
        {
            shouldNormalize: false
        }
    )
export const getCurrentReviewDetails = (committeeId: string, minutesId: string) =>
    getJSON(`committees/${committeeId}/minutes_documents/${minutesId}/get_reviews`, {
        shouldNormalize: false
    })
