import React from 'react'

type Props = {
    title?: string
    className?: string
}

const PlusIcon: React.FC<Props> = ({ title, className }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.125 12.2803C2.125 6.48128 6.82601 1.78027 12.625 1.78027C18.424 1.78027 23.125 6.48128 23.125 12.2803C23.125 18.0793 18.424 22.7803 12.625 22.7803C6.82601 22.7803 2.125 18.0793 2.125 12.2803ZM12.625 0.280273C5.99758 0.280273 0.625 5.65286 0.625 12.2803C0.625 18.9077 5.99758 24.2803 12.625 24.2803C19.2524 24.2803 24.625 18.9077 24.625 12.2803C24.625 5.65286 19.2524 0.280273 12.625 0.280273ZM13.375 6.28027C13.375 5.86606 13.0392 5.53027 12.625 5.53027C12.2108 5.53027 11.875 5.86606 11.875 6.28027V11.5303H6.625C6.21079 11.5303 5.875 11.8661 5.875 12.2803C5.875 12.6945 6.21079 13.0303 6.625 13.0303H11.875V18.2803C11.875 18.6945 12.2108 19.0303 12.625 19.0303C13.0392 19.0303 13.375 18.6945 13.375 18.2803V13.0303H18.625C19.0392 13.0303 19.375 12.6945 19.375 12.2803C19.375 11.8661 19.0392 11.5303 18.625 11.5303H13.375V6.28027Z"
            fill="white"
        />
    </svg>
)

export default PlusIcon
