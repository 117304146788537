/** @jsx jsx */
import { useState, useEffect, useCallback, FC } from 'react'
import { EditorSidebarActions } from 'components/contexts/EditorSidebarConstate'
import { jsx, css } from '@emotion/react'

type Props = {
    minutesSections: any[]
}

export const TrackChangesSidebar: FC<Props> = (_props) => {
    const [sidebarRef, setSidebarRef] = useState<Element | undefined>(undefined)
    const onRefSet = useCallback((ref) => {
        if (ref !== null) {
            setSidebarRef(ref)
        }
    }, [])

    const { setEditorSidebar } = EditorSidebarActions()

    useEffect(() => {
        // On Unmount
        return () => {
            setEditorSidebar(undefined)
        }
    }, [setEditorSidebar])

    useEffect(() => {
        if (!!sidebarRef) {
            setEditorSidebar(sidebarRef)
        }
    }, [sidebarRef, setEditorSidebar])

    //<SidebarContainer>
    return (
        <div ref={onRefSet} id="changes-sidebars-parent">
            {_props.minutesSections
                .filter((s) => s.sectionType === 'minutes')
                .map((section) => (
                    <div
                        key={`sectionChanges-${section.id}`}
                        id={`sectionChangesSidebar-${section.id}`}
                        css={EditorSidebarClass}
                    />
                ))}
        </div>
    )
    //</SidebarContainer>
}

const EditorSidebarClass = css`
    padding: 0 5px;
`
// const SidebarContainer = styled('div')`
//     /* To create the column layout. */
//     display: flex;
//     flex-direction: column;
//     border-top: solid 1px #000;
//     border-bottom: solid 1px #000;
//     /* width: 350px; */
//     /* min-width: 250px; */
//     /* To make the container relative to its children. */
//     position: relative;
// `

export default TrackChangesSidebar
