import React, { useMemo } from 'react'
import { PersonChip } from '../types'
import i18n from 'i18next'
import { makeOnChipChange, AtlasChipInputField } from '../chip-input-field'
import { makeMuiFieldAdapter } from 'components/final-form/final-form-helper'
import { getAtlasHelperText } from 'components/text-field/input-error-text'
import { useContactsSWR } from 'businesslayer/networkrequests/contacts'
import { ClassNames } from '@emotion/react'

type AttendeesChipInputProps = {
    maxAttendeesCount: number
}

export const AttendeesChipInput = ({
    maxAttendeesCount,
    helperText,
    value,
    onChange,
    onBlur,
    'data-testid': data_testid,
    ...props
}) => {
    const { data: allContacts } = useContactsSWR()

    const allAttendeesChips = useMemo(() => getAttendeesDataSource(allContacts), [allContacts])

    const AtlasHelperTextComponent = useMemo(
        () =>
            getAtlasHelperText({
                errorText: helperText,
                value: value,
                maxValue: maxAttendeesCount
            }),
        [helperText, value, maxAttendeesCount]
    )

    return (
        <ClassNames>
            {({ css }) => (
                <AtlasChipInputField
                    isAtlas
                    placeholder={i18n.t('ATTENDEES_HINT')}
                    aria-label={i18n.t('ADD_INVITEES_HINT')}
                    floatingLabelText={`${i18n.t('ATTENDEES')} (${i18n.t(
                        'MEETING_EDITOR_DISCLAIMER'
                    )})`}
                    value={value}
                    className={css`
                        p {
                            margin-right: 7px;
                        }
                    `}
                    options={allAttendeesChips}
                    helperText={AtlasHelperTextComponent}
                    onChange={(options, action) => {
                        const newAttendees = makeOnChipChange<PersonChip>({
                            addChip: onAttendeesAdd,
                            deleteChip: onAttendeesDelete
                        })({ options, action })

                        if (!!newAttendees) {
                            onChange(newAttendees)
                            // onBlur forces final-form to validate on change for
                            // just this field but doesn't actually blur the field
                            onBlur(newAttendees)
                        }
                    }}
                    {...props}
                />
            )}
        </ClassNames>
    )
}

export const AttendeesChipInputAdapter = makeMuiFieldAdapter<
    AttendeesChipInputProps & TestIdAttribute
>(AttendeesChipInput)

export const getAttendeesDataSource = (allContacts: Attendee[] | undefined): PersonChip[] => {
    if (!allContacts) {
        return []
    }

    return allContacts.map((contact) => {
        return {
            text: contact.display_name,
            email: contact.email,
            id: contact.id,
            value: contact.display_name
        }
    }) as PersonChip[]
}

export const onAttendeesAdd = (
    newAttendee: any,
    newPersonChipList: PersonChip[]
): PersonChip[] | undefined => {
    if (!isValidAttendeeDataToAdd(newAttendee)) {
        return undefined
    }

    return newPersonChipList
}

export const isValidAttendeeDataToAdd = (newContact: PersonChip): boolean => {
    if (!newContact.text || !newContact.text.trim()) {
        return false
    }
    return true
}

export const onAttendeesDelete = (_: PersonChip, newPersonChipList: PersonChip[]) => {
    return newPersonChipList
}
