import React, { useEffect } from 'react'

//#region Components
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'

import { UserChip } from 'components/shared/UserChip'
import i18n from 'i18next'

//#endregion

//#region Props

type Props = {
    section: any
    attendees: Attendee[]
    attendeesInfo: string
    isAllExpanded: boolean
}

//#endregion

//#region Implementation
export const MinutesAttendeesReadOnly = (props: Props) => {
    const { attendees, attendeesInfo } = props
    // If expand all is toggled in the toolbar, set current isExpanded to that value
    useEffect(() => {}, [props.isAllExpanded])

    return (
        <ClassNames>
            {({ css }) => (
                <AttendeesSection>
                    <SectionContent>
                        <label
                            aria-label={i18n.t('ADD_INVITEES')}
                            className={css`
                                ${labelStyle}
                            `}>
                            {i18n.t('ADD_INVITEES')}
                        </label>
                        <ChipArea>
                            {attendees.map((attendee) => {
                                return (
                                    <UserChip
                                        key={attendee.id}
                                        name={attendee.display_name}
                                        email={attendee.email || i18n.t('NO_EMAIL_PERSON_LABEL')}
                                        readonly
                                    />
                                )
                            })}
                        </ChipArea>
                        <div style={{ marginTop: '20px' }}>
                            <label
                                aria-label={i18n.t('ADD_MORE_INFORMATION')}
                                className={css`
                                    ${labelStyle}
                                `}>
                                {i18n.t('ADD_MORE_INFORMATION')}
                            </label>
                            <AdditionalInfo>{attendeesInfo}</AdditionalInfo>
                        </div>
                    </SectionContent>
                </AttendeesSection>
            )}
        </ClassNames>
    )
}

const AttendeesSection = styled('div')`
    display: flex;
    flex-flow: column;
    margin-top: 2px;
    width: 90vw;
    margin: 20px;
`
//    height: 45px;
const SectionContent = styled('div')`
    margin: 20px 24px 0px;
`
const ChipArea = styled('div')`
    border: 1px solid #949494;
    border-radius: 4px;
    padding: 0px 20px 0px 16px;
    margin-top: 8px;
    min-height: 40px;
`
const AdditionalInfo = styled('div')`
    border: 1px solid #949494;
    border-radius: 4px;
    padding: 10px 16px;
    margin-top: 6px;
    min-height: 90px;
    line-height: 20px;
    margin-bottom: 8rem;
    line-break: anywhere;
`

const labelStyle = css`
    font-size: 0.75rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #768693;
    line-height: 1;
`

export default MinutesAttendeesReadOnly
//#endregion
