import normalize from 'json-api-normalizer'
import {
    RequestOptions,
    addSortOrder,
    getAdditionalPostHeader,
    getOfficeHeaders,
    getPostHeaders,
    getRawPostHeaders,
    getStandardHeaders,
    handleError,
    processError,
    sanitizeResponse
} from './requestUtils'

export const getJSON = (
    path: string,
    { shouldNormalize }: RequestOptions = { shouldNormalize: true },
    callback: (r: any) => any = (r) => r
) =>
    requestGet(path)
        .then(parseResponse)
        .then(makeNormalizeJSON(shouldNormalize))
        .then((r) => (!!r ? callback(r) : r))
        .catch(processError)

export const getJSONBody = (
    path: string,
    requestData,
    { shouldNormalize }: RequestOptions = { shouldNormalize: true },
    callback: (r: any) => any = (r) => r
) =>
    requestGetBody(path, requestData)
        .then(parseResponse)
        .then(makeNormalizeJSON(shouldNormalize))
        .then((r) => (!!r ? callback(r) : r))
        .catch(processError)

export const postJSON = (
    path: string,
    body,
    { shouldNormalize }: RequestOptions = { shouldNormalize: true }
) =>
    requestPost(path, body)
        .then(parseResponse)
        .then(makeNormalizeJSON(shouldNormalize))
        .catch(processError)

export const postRawJSON = (path: string, body) =>
    requestRawPost(path, body).then(parseResponse).catch(processError)

export const putJSON = (
    path: string,
    body,
    { shouldNormalize }: RequestOptions = { shouldNormalize: true }
) =>
    requestPut(path, body)
        .then(parseResponse)
        .then(makeNormalizeJSON(shouldNormalize))
        .catch(processError)

export const deleteJSON = (path: string) =>
    requestDelete(path).then(handleError).catch(processError)

export const getOfficeDoc = (path: string) =>
    requestOfficeDoc(path).then(handleError).catch(processError)

export const getOfficeXls = (path: string) =>
    requestOfficeXls(path).then(handleError).catch(processError)

export const getOfficeXlsPost = (path: string, body: any) =>
    requestOfficeXlsPost(path, body).then(handleError).catch(processError)

const apiPrefix = '/api/'
const customHost = '' // 'https://minutes-rizzo01.diligentdatasystems.com'

const requestGet = (apiPath) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        credentials: 'include',
        headers: getStandardHeaders()
    })

const requestGetBody = (apiPath, requestData) =>
    fetch(`${customHost}${apiPrefix}${apiPath}?${requestData}`, {
        method: 'GET',
        credentials: 'include',
        headers: getStandardHeaders()
    })

const requestPost = (apiPath, jsonApiString) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        method: 'POST',
        credentials: 'include',
        headers: getPostHeaders(),
        body: jsonApiString
    })

const requestRawPost = (apiPath, jsonApiString) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        method: 'POST',
        credentials: 'include',
        headers: getRawPostHeaders(),
        body: jsonApiString
    })

const requestPut = (apiPath, jsonApiString) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        method: 'PUT',
        credentials: 'include',
        headers: getPostHeaders(),
        body: jsonApiString
    })

const requestDelete = (apiPath) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: getStandardHeaders()
    })

const requestOfficeDoc = (apiPath) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        method: 'GET',
        credentials: 'include',
        headers: getOfficeHeaders()
    })

const requestOfficeXls = (apiPath) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        method: 'GET',
        credentials: 'include',
        headers: getOfficeHeaders(false)
    })

const requestOfficeXlsPost = (apiPath, jsonApiString) =>
    fetch(`${customHost}${apiPrefix}${apiPath}`, {
        method: 'POST',
        credentials: 'include',
        headers: getAdditionalPostHeader(),
        body: jsonApiString
    })

const parseResponse = (response) => handleError(response)?.json?.()

const makeNormalizeJSON = (shouldNormalize, shouldSanitize = true) => (json) => {
    if (shouldSanitize) {
        sanitizeResponse(json)
    }

    const normalizedJson = shouldNormalize && json ? normalize(json) : json
    const normalizeWithSort = addSortOrder(normalizedJson, json)

    return normalizeWithSort
}
