/**
 * Factor class that manages local/session storage for us, even in the case where
 * the user's browser does not support modern local/session storage api.
 *
 * Borrowed from https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 *
 * Original code is: ISC License (ISC). Copyright 2017 Michal Zalecki
 * Changed by Diligent to suite our needs
 */
function storageFactory(getStorage: () => Storage): Storage {
    let inMemoryStorage: { [key: string]: string } = {}

    function isSupported() {
        try {
            const testKey = '__some_random_key_you_are_not_going_to_use__'
            getStorage().setItem(testKey, testKey)
            getStorage().removeItem(testKey)
            return true
        } catch (e) {
            return false
        }
    }

    function clear(): void {
        if (isSupported()) {
            getStorage().clear()
        } else {
            inMemoryStorage = {}
        }
    }

    function getItem(name: string): string | null {
        if (isSupported()) {
            return getStorage().getItem(name)
        }
        if (inMemoryStorage.hasOwnProperty(name)) {
            return inMemoryStorage[name]
        }
        return null
    }

    function key(index: number): string | null {
        if (isSupported()) {
            return getStorage().key(index)
        } else {
            return Object.keys(inMemoryStorage)[index] || null
        }
    }

    function removeItem(name: string): void {
        if (isSupported()) {
            getStorage().removeItem(name)
        } else {
            delete inMemoryStorage[name]
        }
    }

    function setItem(name: string, value: string): void {
        if (isSupported()) {
            getStorage().setItem(name, value)
        } else {
            inMemoryStorage[name] = String(value) // not everyone uses TypeScript
        }
    }

    function length(): number {
        if (isSupported()) {
            return getStorage().length
        } else {
            return Object.keys(inMemoryStorage).length
        }
    }

    return {
        getItem,
        setItem,
        removeItem,
        clear,
        key,
        get length() {
            return length()
        }
    }
}

// = = = = = = = = =
// Local Storage
// = = = = = = = = =
const dilLocalStorage = storageFactory(() => localStorage)

type LocalStorageKeys = 'userLanguage' | 'sessionId' | 'landingPath'

export const getLocalStorageItem = (key: LocalStorageKeys) => {
    return dilLocalStorage.getItem(key) || ''
}
export const setLocalStorageItem = (key: LocalStorageKeys, value: string) => {
    dilLocalStorage.setItem(key, value)
}
export const removeLocalStorageItem = (key: LocalStorageKeys) => {
    dilLocalStorage.removeItem(key)
}

// = = = = = = = = =
// Session Storage
// = = = = = = = = =
const dilSessionStorage = storageFactory(() => sessionStorage)

type SessionStorageKeys =
    | 'parentPlatform'
    | 'sessionId'
    | 'siteName'
    | 'currentCommitteeId'
    | 'currentCommitteeName'
    | 'selectedMinuteItemId'
    | 'selectedActionItemId'
    | 'selectedCommitteeFilter'
    | 'selectedStatusFilter'
    | 'persistedStateData'
    | 'landingPath'
    | 'committeeId'
    | 'committeeName'
    | 'bookId'
    | 'authToken'

export const getSessionStorageItem = (key: SessionStorageKeys) => {
    return dilSessionStorage.getItem(key) || ''
}
export const setSessionStorageItem = (key: SessionStorageKeys, value: string) => {
    dilSessionStorage.setItem(key, value)
}
export const removeSessionStorageItem = (key: SessionStorageKeys) => {
    dilSessionStorage.removeItem(key)
}
