/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import { formatMeetingDate, formatDate } from 'common/formatters/date'
import { isBeforeToday } from 'common/util/date'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import {
    AtlasStatusSuccessVar,
    AtlasStatusAlertVar,
    AtlasDataviz_09Var,
    AtlasGreyDarkVar
} from 'assets/styles/variables'
import { AtlasIcon } from 'web-components/atlas-icon'
import {
    IconNames,
    IconColors
} from '@diligentcorp/atlas/dist/types/components/atlas-icon/icon-types'
import { pathOr } from 'rambdax'
import { MetaDataGroupedList } from 'components/meta-data-column-list/meta-data-column-list'

/**
 * Logic for Minutes Card on Manager
 */
export const getMinutesMetaData = (minutes): MetaDataGroupedList => {
    if (!minutes) return []
    const { status, attendees } = minutes

    const isInvited = pathOr(false, 'review.is_invited', minutes)
    const showReviewData = status === 'in_review' && isInvited

    const NO_DUE_DATE = i18n.t('NO_DUE_DATE')
    const meetingDate = getMeetingDate(minutes, NO_DUE_DATE)

    const actionsCount = pathOr('0', 'action_items_data.total', minutes)
    const actionsIncompeleteCount = pathOr('0', 'action_items_data.incomplete', minutes)
    const actionStatusTitle = `${actionsCount} ${i18n.t('ACTION_ITEMS')}`
    const actionStatus = getActionStatus(actionsCount, actionsIncompeleteCount)
    const attendeesCount = pathOr('0', 'length', attendees)

    const reviewDueDate = getReviewDueDate(minutes, NO_DUE_DATE)
    const reviewRequestedBy = pathOr('', 'review.requestor.display_name', minutes)

    const reviewMetaData = showReviewData
        ? [
              { key: i18n.t('REVIEW_DUE'), value: reviewDueDate },
              { key: i18n.t('REQUESTED_BY'), value: reviewRequestedBy }
          ]
        : []

    return [
        [
            { key: i18n.t('MEETING_DATE'), value: meetingDate },
            { key: actionStatusTitle, value: actionStatus }
        ],
        [
            { key: i18n.t('ATTENDEES'), value: attendeesCount },
            { key: i18n.t('DOCUMENT_STATUS'), value: documentStatusMap[status] }
        ],
        reviewMetaData
    ]
}
export const getMeetingDate = (minutes, defaultDate) => {
    const { dateFormat } = transientStorageManager
    const minutesDates = pathOr(null, 'meeting_dates', minutes)
    return (
        formatMeetingDate({
            date: minutesDates,
            dateFormat
        }) || defaultDate
    )
}
const getReviewDueDate = (minutes, defaultDate) => {
    const { dateFormat } = transientStorageManager

    // Handle for complete review
    const isReviewComplete = pathOr(false, 'review.review_completed', minutes) as boolean
    if (isReviewComplete) return withCompleteIcon(i18n.t('REVIEW_COMPLETED'))

    // Format Due Date
    const dueDate = pathOr(null, 'review.due_date', minutes)
    const formattedDueDate = formatDate({ date: dueDate, dateFormat: dateFormat })

    // Handle overdue review
    const isOverdue = !!dueDate && isBeforeToday(dueDate)
    if (isOverdue && !!formattedDueDate) return withOverdueIcon(formattedDueDate)

    return formattedDueDate || defaultDate
}

const getActionStatus = (totalActionCount, incompleteActionCount) => {
    if (+totalActionCount === 0) return i18n.t('NO_ACTIONS')
    if (+incompleteActionCount === 0) return i18n.t('ACTION_ITEM_COMPLETE')

    return `${incompleteActionCount} ${i18n.t('ACTION_ITEM_INCOMPLETE')}`
}

export const getMinutesCardColor = (status: DocumentStatusKey) => {
    const minutesCardColorMap = {
        draft: 'alert',
        in_review: 'default',
        final_draft: 'archive',
        approved: 'success'
    }
    const varient = minutesCardColorMap[status]
    // Atlas uses undefined as default variant
    if (varient === 'default') return undefined
    return varient || 'alert'
}

export const getMinutesIconColor = (status: DocumentStatusKey): string => {
    const minutesIconColorMap = {
        draft: AtlasStatusAlertVar, // #eaa14b
        in_review: AtlasDataviz_09Var, // #5d7599
        final_draft: AtlasGreyDarkVar, // #676767
        approved: AtlasStatusSuccessVar // #05704b
    }
    return minutesIconColorMap[status] || AtlasStatusAlertVar
}

const documentStatusMap = {
    draft: translate('DRAFT'),
    in_review: translate('IN_REVIEW'),
    final_draft: translate('FINAL_DRAFT'),
    approved: translate('APPROVED')
}

export const getCanDuplicate = (status: DocumentStatusKey) => {
    const whiteList = ['draft', 'final_draft', 'approved']
    return whiteList.includes(status)
}

export const getCanExportMinutes = (status: DocumentStatusKey) => {
    const whiteList = ['draft', 'final_draft', 'approved']
    return whiteList.includes(status)
}

// Functions for Data Items with Icons
const withStatusIcon = (label: string, iconName: IconNames, iconColor: IconColors) => {
    return (
        <span
            css={css`
                display: flex;
                align-items: center;
            `}>
            {label}
            <AtlasIcon
                name={iconName}
                size={16}
                color={iconColor}
                css={css`
                    margin-left: 5px;
                `}
            />
        </span>
    )
}
const withCompleteIcon = (label: string) => {
    return withStatusIcon(label, 'status-success', AtlasStatusSuccessVar)
}
const withOverdueIcon = (label: string) => {
    return withStatusIcon(label, 'status-alert', AtlasStatusAlertVar)
}
