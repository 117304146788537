//#region Components Material

import React, { Component } from 'react'

//#endregion
//#region Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IconNoActions } from '../../../assets/images'
//#endregion
//#region Components
import { translate } from 'components/shared/internationalization'
import { actions as editorActions } from '../../../reducers/actionEditorReducer'
import { actions } from '../../../reducers/minuteActionItemsReducer'
import { actions as takerActions } from '../../../reducers/minuteTakerReducer'
import selectors from '../../../selectors/minuteActionItemsSelectors'
import takerSelectors from '../../../selectors/minuteTakerSelectors'
import DomHelper from '../../helpers/DomHelper'
import NotificationView from './NotificationView'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import { ActionSummaryListContainer } from '../components/ActionSummaryList'
import { ActionItemDetailsCard } from '../components/ActionItemDetailsCard'
import actionEditorSelectors from '../../../selectors/actionEditorSelectors'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { mapSectionNameById, mapSectionOrderById } from 'common/mappers/minutes'
import { mapActionId } from 'common/mappers/routes'
import { SortProvider } from 'components/contexts/actions/SortConstate'
import { Toolbar } from '../../shared/Toolbar'
import { ActionsToolbar } from '../../shared/Toolbar/ComposedToolbars/ActionsToolbar'

//#endregion

//#region Styles

const tableHeaderHeight = 32

const headerHeight = 80
const initialHeight = 480
const minifiedGridViewBreakPoint = 1260
const labelsCollapsedBreakPoint = 980
const iconBarHeight = 56

const noActionsIconStyle = css`
    position: inherit;
    top: inherit;
    right: inherit;
    transform: none;
    width: 115px;
    height: 115px;
    padding-bottom: 30px;
    margin-top: -115px;
`

export const Separator = styled('div')`
    height: 70%;
    border-right: 2px solid #e0e0e0;
    margin-right: 10px;
    margin-left: 10px;
`

//#endregion

//#region Props
type Props = {
    actions: {
        sortActions: (...args: any[]) => void
        handleActionStatusChanged: (...args: any[]) => void
        resetSort: () => void
        selectActionItem: (...args: any[]) => void
        saveExistingAction: (...args: any[]) => void
        updateTakerListAction: (...args: any[]) => void
        loadContacts: (...args: any[]) => void
    }
    savedAction: any
    minutesActions: Array<any>
    minutesSections: Array<any>
    dateFormat: string
    selectedActionItem: any
    currentMinuteItem: any
    sort: any
    onDocumentStatusChange: (status: string) => void
    reloadActions: () => void
} & RouteComponentProps

type State = {
    height: number
    gridHasVScroller: boolean
    selectedIndex: number
    selectedId: string | null
    notifyingItem: any
    isMinifiedGridView: boolean
    isToolbarLabelsCollapsed: boolean
}

//#endregion

//#region Implementation

class ActionListView extends Component<Props, State> {
    state = {
        height: initialHeight,
        gridHasVScroller: false,
        selectedIndex: -1,
        selectedId: null,
        notifyingItem: null,
        isMinifiedGridView: false,
        isToolbarLabelsCollapsed: false
    }

    componentDidMount() {
        this.props.actions.resetSort()
        this.props.actions.loadContacts()
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { selectedActionItem } = this.props

        if (nextProps.minutesSections !== this.props.minutesSections) {
            this.forceUpdate()
        }

        if (nextProps.savedAction && nextProps.savedAction.isUpdated) {
            delete nextProps.savedAction.isUpdated

            this.props.actions.updateTakerListAction(nextProps.savedAction)
        }

        this.scrollSelectedRowIfNeeded(selectedActionItem)
    }

    //TODO: Move to ActionTable
    private scrollSelectedRowIfNeeded = (selectedActionId) => {
        if (selectedActionId) {
            this.setState({ selectedId: `${selectedActionId}`, selectedIndex: 0 })
        }
    }

    onResize = (evt) => {
        //Top part includes all the parent constructions, like page header, tabs and margin + table header
        const topPart = tableHeaderHeight + headerHeight
        const isMinifiedGridView = evt.width < minifiedGridViewBreakPoint
        const isToolbarLabelsCollapsed = evt.width < labelsCollapsedBreakPoint

        // Only rerender if the state changes
        if (
            isMinifiedGridView !== this.state.isMinifiedGridView ||
            isToolbarLabelsCollapsed !== this.state.isToolbarLabelsCollapsed ||
            evt.height - topPart !== this.state.height
        ) {
            this.setState({
                height: evt.height - topPart,
                isMinifiedGridView,
                isToolbarLabelsCollapsed
            })
        }
    }

    onNotify = (action) => {
        this.setState({ notifyingItem: action })
    }

    private onCloseNotificationView = () => {
        this.setState({ notifyingItem: null })
    }

    private onNotificationSent = (action) => {
        this.props.actions.handleActionStatusChanged(action)
    }

    private onCompleteStatus = (action) => {
        this.setState({ selectedId: action.id })
        this.props.actions.saveExistingAction(action)
    }

    //TODO: Extract Component
    private renderNoActions = () => {
        return (
            <EmptyView height={this.state.height}>
                <IconNoActions className={noActionsIconStyle} />
                {translate('NO_ACTIONS_TEXT')}
                <DomHelper onResize={this.onResize} />
            </EmptyView>
        )
    }

    private renderNotificationView = (currentMinuteItem: any, notifyingItem: any) => {
        return (
            notifyingItem && (
                <NotificationView
                    action={notifyingItem}
                    minutesItem={currentMinuteItem}
                    onClose={this.onCloseNotificationView}
                    onSaved={this.onNotificationSent}
                    reloadActions={this.props.reloadActions}
                />
            )
        )
    }

    private renderTableBody = (_: number, __: boolean) => {
        return (
            <ActionSummaryListContainer
                onCompleteActionChecked={(action) => {
                    if (action.completionStatus === 'complete') {
                        action.completionStatus = 'incomplete'
                    } else {
                        action.completionStatus = 'complete'
                    }

                    this.onCompleteStatus(action)
                }}
            />
        )
    }

    private renderActionSummaryList = ({ selectedActionItem }) => {
        const body = this.renderTableBody(this.state.height - iconBarHeight, false)

        return (
            <ActionSummaryListContainerWrapper isActionItemSelected={!!selectedActionItem}>
                {body}
            </ActionSummaryListContainerWrapper>
        )
    }

    private renderActionDetailsCard = ({ selectedActionItem, section }) =>
        selectedActionItem && (
            <ActionItemDetailsCardWrapper>
                <ActionItemDetailsCard
                    onNotify={this.onNotify}
                    onCompleteActionChecked={(action) => {
                        if (action.completionStatus === 'complete') {
                            action.completionStatus = 'incomplete'
                        } else {
                            action.completionStatus = 'complete'
                        }

                        this.onCompleteStatus(action)
                    }}
                    section={section}
                    actionItem={selectedActionItem}
                    dateFormat={this.props.dateFormat}
                />
            </ActionItemDetailsCardWrapper>
        )

    render() {
        const {
            minutesActions,
            currentMinuteItem,
            minutesSections,
            onDocumentStatusChange
        } = this.props
        const { isToolbarLabelsCollapsed } = this.state
        const selectedActionId = mapActionId(this.props)

        const selectedActionItem =
            minutesActions && minutesActions.find((action) => +action.id === +selectedActionId)

        const mappedSelectedActionItem = selectedActionItem && {
            ...selectedActionItem,
            section: mapSectionNameById(selectedActionItem.minutesSectionId, minutesSections),
            sectionOrder: mapSectionOrderById(selectedActionItem.minutesSectionId, minutesSections)
        }

        if (!minutesActions || !minutesActions.length) {
            return this.renderNoActions()
        }

        const notification =
            this.state.notifyingItem &&
            this.renderNotificationView(currentMinuteItem, this.state.notifyingItem)

        const section =
            selectedActionItem &&
            minutesSections.find((c) => +c.id === selectedActionItem.minutesSectionId)
        const actionDetailsCard = this.renderActionDetailsCard({
            selectedActionItem: mappedSelectedActionItem,
            section
        })

        const actionSummaryList = this.renderActionSummaryList({
            selectedActionItem: mappedSelectedActionItem
        })

        return (
            <ClassNames>
                {({ css }) => (
                    <React.Fragment>
                        <SortProvider
                            initialState={{
                                sortOrder: 'ascending',
                                sortField: 'sectionOrder'
                            }}>
                            <Wrapper>
                                <Toolbar
                                    css={css`
                                        padding-left: 30px;
                                    `}>
                                    {(toolbarProps) => (
                                        <ActionsToolbar
                                            isToolbarLabelsCollapsed={isToolbarLabelsCollapsed}
                                            onDocumentStatusChange={onDocumentStatusChange}
                                            {...toolbarProps}
                                        />
                                    )}
                                </Toolbar>
                                <div
                                    className={css`
                                        grid-row: 2;
                                        -ms-grid-row: 2;
                                        background-color: #e2e5e9;
                                        min-width: 500px;
                                        overflow-y: auto;
                                        align-items: flex-start;
                                        display: flex;
                                        justify-content: center;
                                        word-break: break-word;
                                    `}>
                                    {actionSummaryList}
                                    {actionDetailsCard}
                                </div>
                                <DomHelper onResize={this.onResize} />
                            </Wrapper>
                        </SortProvider>
                        {notification}
                    </React.Fragment>
                )}
            </ClassNames>
        )
    }
}

//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        minutesActions: takerSelectors.minutesActions(state.minuteTakerReducer),
        minutesSections: takerSelectors.minutesSections(state.minuteTakerReducer),
        dateFormat: takerSelectors.dateFormat(state.minuteTakerReducer),
        selectedActionItem: takerSelectors.selectedActionItem(state.minuteTakerReducer),
        currentMinuteItem: takerSelectors.currentMinuteItem(state.minuteTakerReducer),
        savedAction: actionEditorSelectors.savedAction(state.actionEditorReducer),
        sort: selectors.sortField(state.minuteActionItemsReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        resetSort,
        selectActionItem,
        handleActionStatusChanged,
        sortActions,
        saveExistingAction,
        updateTakerListAction,
        loadContacts
    } = { ...actions, ...takerActions, ...editorActions }

    return {
        actions: bindActionCreators(
            {
                resetSort,
                selectActionItem,
                handleActionStatusChanged,
                sortActions,
                saveExistingAction,
                updateTakerListAction,
                loadContacts
            },
            dispatch
        )
    }
}

const Wrapper = styled('div')`
    color: #fff;
    background-color: #f4f4f5;
    display: grid;
    display: -ms-grid;
    height: 100%;
    grid-template-rows: 56px 1fr;
    -ms-grid-rows: 56px 1fr;
    -ms-grid-columns: 1fr;
`

const ActionSummaryListContainerWrapper = styled('div')<{ isActionItemSelected: boolean }>`
    background-color: #e2e5e9;
    position: relative;
    box-sizing: border-box;
    margin-left: 15px;
    margin-right: ${({ isActionItemSelected }) => !isActionItemSelected && '15px'};
    width: ${({ isActionItemSelected }) => (isActionItemSelected ? 865 : 1200)}px;
    max-width: ${({ isActionItemSelected }) =>
        isActionItemSelected && '-webkit-calc(100% - 315px)'};
    max-width: ${({ isActionItemSelected }) => isActionItemSelected && '-moz-calc(100% - 315px)'};
    max-width: ${({ isActionItemSelected }) => isActionItemSelected && 'calc(100% - 315px)'};
`
const ActionItemDetailsCardWrapper = styled('div')`
    padding: 10px 15px;
    color: black;
    position: -webkit-sticky;
    position: sticky;
    left: 15px;
    top: 0px;
`

const EmptyView = styled('div')<{ height: any }>`
    font-size: 22px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    color: #8a92a1;
    background-color: #fff;
    padding: 0;
    height: ${({ height }) => height + tableHeaderHeight}px;
    min-height: 200px;
    overflow-y: auto;
`

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionListView))

//#endregion
