import React from 'react'

const RestrictedWarningIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.750968 16C0.193431 16 -0.169191 15.4133 0.0801473 14.9146L7.33015 0.41459C7.60654 -0.138197 8.3954 -0.138197 8.67179 0.41459L15.9218 14.9146C16.1711 15.4133 15.8085 16 15.251 16H0.750968ZM7.25097 6H8.75097V11H7.25097V6ZM8.00097 13.5C8.41518 13.5 8.75097 13.1642 8.75097 12.75C8.75097 12.3358 8.41518 12 8.00097 12C7.58675 12 7.25097 12.3358 7.25097 12.75C7.25097 13.1642 7.58675 13.5 8.00097 13.5Z"
                fill="#EAA14B"
            />
        </svg>
    )
}

export default RestrictedWarningIcon
