import { RouteComponentProps } from 'react-router'
import { pathOr } from 'rambdax'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

export const mapActionId = ({ match, location }: RouteComponentProps) => {
    let actionId = pathOr('', ['params', 'actionId'], match)
    if (!actionId) {
        actionId = pathOr('', ['state', 'actionId'], match)
    }
    if (!actionId) {
        const pathname = location.pathname
        const params = pathname.split('/') // Split the pathname into an array of segments
        actionId = params[params.length - 1]
    }
    if (!actionId) {
        actionId = getSessionStorageItem('selectedActionItemId')
    }
    return actionId
}
