import React from 'react'
import { AtlasButton as AtlasButtonOriginal } from '@diligentcorp/atlas-react'
import { Button, ButtonProps } from '@material-ui/core'
import { Uppercase } from 'assets/sharedStyles'
import { useAtlasButtonStyles } from 'material-atlas/overrides/mui-button-base'
import { merge } from 'rambdax'

/**
 * We have to inject the uppercase here because the shadow DOM is scoping
 * any overrides. If we move to a completely MUI Button Implementation we
 * can remove this.
 */
export const AtlasButtonOld = ({ disableUppercase = false, ...props }) => {
    const wrappedChildren = !!disableUppercase ? (
        props.children
    ) : (
        <Uppercase>{props.children}</Uppercase>
    )
    return <AtlasButtonOriginal {...props}>{wrappedChildren}</AtlasButtonOriginal>
}

/**
 * Wrapper around Mui Button for Atlas styles.
 * We are doing this instead of global styles because
 * the global styles were messing up the date/time mui-pickers.
 */
export const AtlasButton = ({ classes, ...props }: ButtonProps) => {
    const atlasButtonStyles = useAtlasButtonStyles()
    const combineClasses = merge(atlasButtonStyles, classes || {})

    return <Button classes={combineClasses} {...props} />
}
