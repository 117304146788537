import { createSelector } from 'reselect'

const selectorDataState = (state) => {
    return state.dataState
}

const selectorCommunicationState = (state) => {
    return state.communicationState
}

const selectorControlState = (state) => {
    return state.controlState
}

export default {
    lastError: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.lastError
    ),
    restrictedError: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.restrictedError
    ),

    fullscreenProgressData: createSelector(selectorControlState, (controlState) => {
        return {
            retryAction: controlState.fullscreenProgressLastAction,
            isVisible: controlState.isShowingFullscreenProgress,
            title: controlState.fullscreenProgressTitle,
            message: controlState.fullscreenProgressMessage,
            hideActions: controlState.hideActions
        }
    }),

    platform: createSelector(selectorDataState, (dataState) => dataState.platform)
}
