import { DataState, TakerControlState } from 'reducers/minuteTakerReducer'
import { DATE_STEPS, INVITEE_CONSTANTS, Reviewer, SelectChangeAction } from './types'
import { equals, prop, uniqWith } from 'rambdax'
import { PersonChip } from 'components/chip-input/types'
import { invalidDate } from 'common/util/validators/minutes-properties/minutes-properties-validators'
import i18n from 'common/util/internationalization'
import moment from 'moment'
import { translate } from 'components/shared/internationalization'
import { format } from 'date-fns'
import { getExistingAssigneesData } from 'components/minutetaker/actionlist/listeditors/EditAssigneesDialog'

/**
 * Helper function for shaping network reviewer
 * data to chip input expected shape
 *
 * Reviewer -> PersonChip
 *
 * @param data
 */
export const normalizeUsers = (data: Reviewer[]) => {
    return data.map((item) => ({
        text: item.attributes.display_name,
        email: item.attributes.email,
        id: item.id,
        value: item.attributes.display_name
    }))
}
export const STEPS = {
    GENERAL_REVIEWER: 0,
    DUE_DATE_STEP: 1,
    EMAIL_NOTIFICATION: 2
}
export const prepareContacts = (data, admindata) => {
    const adminDataMap = new Map(admindata.map((item) => [Number(item.user_id), item.review_id]))

    return Object.values(data.contacts).map((contact) => {
        const { id, attributes } = contact as Reviewer
        const contactId = Number(id)
        const reviewId = adminDataMap.get(contactId) ?? undefined

        return {
            text: attributes.displayName,
            email: attributes.email,
            id: contactId,
            value: attributes.displayName,
            review_id: reviewId
        }
    }) as PersonChip[]
}
const data = {
    recipients: [],
    notification_send: false,
    subject: INVITEE_CONSTANTS.EMPTY,
    body: INVITEE_CONSTANTS.EMPTY,
    edited_reviews: [],
    cancelled_reviews: [],
    new_invitees: [],
    parent_platform: INVITEE_CONSTANTS.EMPTY
}
export const DataTemplate = Object.freeze({ ...data })
export const mapReviewersToInvities = (
    res: { data: { reviews: any[] } },
    data: TakerControlState,
    dataState: DataState
) => {
    return {
        ...data.reviewers,
        edited_reviews: [],
        cancelled_reviews: [],
        new_invitees: [],
        subject: i18n.t('SEND_INVITATION_DEFAULT_SUBJECT'),
        body: i18n.t('SEND_INVITATION_DEFAULT_MESSAGE', {
            workroomName: dataState.currentMinuteItem.attributes.committeeName,
            minutesName: dataState.currentMinuteItem.attributes.title
        }),
        recipients: uniqWith(
            (a, b) => equals(prop('id', a), prop('id', b)),
            res.data.reviews.map((contact) => ({
                ...contact,
                text: contact.name,
                email: contact.email,
                id: contact.user_id,
                value: contact.name
            }))
        )
    }
}

export const generateReviewers = (
    reviewers: PersonChip[],
    dataState: DataState,
    minuteTakerState: TakerControlState,
    isGeneral: boolean,
    adminList: number[]
) => {
    const attendeesSectionId = Number(
        dataState.minutesSections.find(
            (item: { sectionType: INVITEE_CONSTANTS }) =>
                item.sectionType === INVITEE_CONSTANTS.ATTENDEES
        )?.id
    )

    const reviewersToInclude = reviewers.map((reviewer) => ({
        ...reviewer,
        section_ids:
            isGeneral || adminList.includes(reviewer.id)
                ? Array.from(new Set(dataState.minutesSections.map((item) => Number(item.id))))
                : Array.from(new Set([attendeesSectionId, ...(reviewer.section_ids ?? [])])),
        is_general: isGeneral
    }))

    const generalReviewers = minuteTakerState.reviewers.recipients.filter(
        (it) => it.is_general !== isGeneral
    )

    return [...reviewersToInclude, ...generalReviewers]
}

export const prepareDataForExecution = (
    recipients: PersonChip[],
    item: SelectChangeAction,
    minuteTakerState: TakerControlState
) => {
    const { edited_reviews, new_invitees, cancelled_reviews } = minuteTakerState.reviewers

    if (item.removedValue) {
        const review_id = item.removedValue.review_id ?? item.removedValue.id
        const newIndex = new_invitees.indexOf(review_id)
        if (!cancelled_reviews.includes(review_id) && item.removedValue.review_id) {
            cancelled_reviews.push(review_id)
        }
        const index = edited_reviews.indexOf(review_id)
        if (index !== -1) {
            edited_reviews.splice(index, 1)
        }

        if (newIndex !== -1) {
            new_invitees.splice(newIndex, 1)
        }

        const newInvitees = new_invitees.filter((x) => item.removedValue.id !== x)

        return {
            ...minuteTakerState.reviewers,
            recipients,
            new_invitees: newInvitees,
            edited_reviews,
            cancelled_reviews
        }
    } else if (item?.option?.id) {
        const { id: optionId, review_id } = item.option
        const reviewIdOrOptionId = review_id ?? optionId
        const cancelIndex = cancelled_reviews.indexOf(reviewIdOrOptionId)
        const index = edited_reviews.indexOf(optionId)
        if (index !== -1) {
            edited_reviews.splice(index, 1)
        }
        if (cancelled_reviews.includes(review_id)) {
            edited_reviews.push(review_id)
        } else if (!new_invitees.includes(optionId)) {
            new_invitees.push(optionId)
        }

        if (cancelIndex !== -1) {
            cancelled_reviews.splice(cancelIndex, 1)
        }

        return {
            ...minuteTakerState.reviewers,
            recipients,
            new_invitees,
            edited_reviews,
            cancelled_reviews
        }
    }

    return minuteTakerState.reviewers
}

export const prepareDataForSections = (user, minuteTakerState) => {
    const { reviewers } = minuteTakerState
    const { edited_reviews, new_invitees, cancelled_reviews } = reviewers

    if (user.review_id && ![...edited_reviews, ...new_invitees].includes(user.review_id)) {
        if (cancelled_reviews.includes(user.review_id))
            cancelled_reviews.splice(cancelled_reviews.indexOf(user.review_id), 1)
        edited_reviews.push(user.review_id)
    } else if (
        !user.review_id &&
        user.id &&
        ![...edited_reviews, ...new_invitees].includes(user.id)
    ) {
        edited_reviews.push(user.id)
    }
    const updatedRecipients = reviewers.recipients.map((item) =>
        user.id === item.id ? user : item
    )

    return {
        ...reviewers,
        recipients: updatedRecipients,
        cancelled_reviews,
        edited_reviews
    }
}

export const getCheckedValue = (dataState: DataState, contact: PersonChip) =>
    dataState.minutesSections?.filter(
        (item: { id: number; sectionType: INVITEE_CONSTANTS }) =>
            !(contact.section_ids ?? []).includes(Number(item.id)) &&
            item.sectionType !== INVITEE_CONSTANTS.ATTENDEES
    ).length === 0

export const sameDueDate = (values, errors) => {
    if (values.sameDueDate) {
        const validDate = invalidDate(values.sameDueDate)

        if (typeof validDate === 'string') {
            errors.sameDueDate = validDate
        } else {
            errors.sameDueDate = INVITEE_CONSTANTS.EMPTY
        }
    } else {
        errors.sameDueDate = i18n.t('DUE_DATE_CAPTION')
    }
    return errors
}

export const differentDueDate = (values, errors, count) => {
    values.recipients.forEach((fieldName, index) => {
        errors.recipients.splice(index, 0, { due_date: INVITEE_CONSTANTS.EMPTY })
        if (fieldName.due_date) {
            const inValidDate = invalidDate(fieldName.due_date)
            if (inValidDate) {
                errors.recipients.splice(index, 1, { due_date: `${inValidDate}` })
            }
        }
        if (!fieldName.due_date) {
            errors.recipients.splice(index, 1, { due_date: INVITEE_CONSTANTS.EMPTY })
        }
    })
    errors.recipients.forEach((fieldName) => {
        if (!fieldName.due_date) {
            count++
        }
    })
    if (count === errors.recipients.length) {
        errors.recipients = []
    }
    return errors
}
export const getReviewNames = (chipOptions: PersonChip[], reviewIds: number[], key: string) =>
    chipOptions
        .filter((item) => reviewIds.includes(item[key]!))
        .map((item) => item.text)
        .join(', ')

const invalidDateCheck = (due_date: any) => {
    if (due_date) {
        return moment(due_date).format(INVITEE_CONSTANTS.DATE_FORMAT)
    }
    return null
}

export const differentDueDateOnChange = (
    selectedDate: Moment | null | string,
    dateFormatInput: string,
    props: any,
    values: DueDateFormValues
) => {
    const fdate = moment(selectedDate).format(dateFormatInput)
    if (
        fdate === DATE_STEPS.INVALID_DATE &&
        values.due_date_fields !== DATE_STEPS.DIFFERENT_DUE_DATE
    ) {
        props?.setStepTwoError()
        props.form.change(`recipients[${props.k}].due_date`, undefined)
    }
}
export const checkEditedReviews = (initialRec: any[], updatedValues: PersonChip[]) => {
    const differentIds: number[] = []

    initialRec.forEach((item1) => {
        const matchingItem = updatedValues.find(
            (item2) =>
                item2.review_id === item1.review_id &&
                invalidDateCheck(item2.due_date) !== item1.due_date
        )
        if (matchingItem && !matchingItem?.review_completed) {
            differentIds.push(item1.review_id)
        }
    })

    return differentIds
}

export const removeDuplicates = (inputSet: Set<unknown>): unknown[] => {
    const result: unknown[] = []
    inputSet.forEach((item) => {
        result.push(item)
    })
    return result
}

export const evaluateDates = (recepients: PersonChip[]) => {
    const uniqueDates = new Set(
        recepients.map((task) => {
            const d = !!task.due_date ? task.due_date : null
            return d
        })
    )

    if (uniqueDates.size === 1 && uniqueDates.has(null)) {
        return null // All dates are null
    } else if (uniqueDates.size === 1) {
        return true // All dates are the same
    } else {
        return false // Dates are different
    }
}

export const changeDueDateValue = (
    receipentsDetails: PersonChip[],
    dueDateFields: string | null
) => {
    for (let i = 0; i < receipentsDetails.length; i++) {
        if (dueDateFields === INVITEE_CONSTANTS.DIFFERENT_DUE_DATE) {
            receipentsDetails[i].due_date = receipentsDetails[i].due_date
                ? moment(receipentsDetails[i].due_date)
                : null
        }
        if (dueDateFields === INVITEE_CONSTANTS.SAME_DUE_DATE) {
            for (let i = 0; i < receipentsDetails.length; i++) {
                receipentsDetails[i].due_date = INVITEE_CONSTANTS.EMPTY
            }
        }
    }
    return receipentsDetails
}

export const validate = (values) => {
    let errors = {
        recipients: [{ due_date: INVITEE_CONSTANTS.EMPTY }],
        sameDueDate: INVITEE_CONSTANTS.EMPTY
    }

    errors.recipients = []

    if (values.due_date_fields === INVITEE_CONSTANTS.SAME_DUE_DATE) {
        errors = sameDueDate(values, errors)
    }

    let count = 0
    if (values.due_date_fields === INVITEE_CONSTANTS.DIFFERENT_DUE_DATE) {
        errors = differentDueDate(values, errors, count)
    }

    return errors
}

export const formSpySameDueDateOnChange = (
    values: DueDateFormValues,
    form: any,
    dateFormatInput: string
) => {
    const fdate = moment(values.sameDueDate).format(dateFormatInput)

    if (fdate === DATE_STEPS.INVALID_DATE && values.due_date_fields !== DATE_STEPS.SAME_DUE_DATE) {
        form.change('sameDueDate', null)
    }
}

export const removeBrackets = (str) => {
    switch (true) {
        case str.startsWith('[') && !str.endsWith(']'):
            str += ']'
            break

        case str.startsWith(',') && !str.endsWith(']'):
            str = '[' + str.substring(1) + ']'
            break

        case str.startsWith(',') && str.endsWith(']'):
            str = '[' + str.substring(1)
            break

        default:
            // Default case if none of the conditions match
            break
    }
    return str
}
export const generateMeetingData = (minutesActions, committeeName, minutesSections) =>
    minutesActions?.map((action) => ({
        ...action,
        action_title: action.text,
        minutes_meeting:
            minutesSections.find(
                (section) => String(section.id) === String(action.minutesSectionId)
            )?.name || '',
        due_date: action.dueDate ?? i18n.t('NONE'),
        completed_date: action.completed_date,
        assigned_to: action.assignees.map((assin) => assin.text).join(', '),
        status: action.completionStatus,
        notificationStatus: action.notificationStatus,
        committeeName: committeeName,
        notes: action.notes
    })) ?? []
export const updateActionWithValues = (action: any, values: any) => {
    action.text = values.action_title
    action.dueDate = values.action_date
    action.assignees = values.action_assignees
    action.notes = values.action_notes
    action.completionStatus = values.action_completed ? 'complete' : 'incomplete'
}
export const validateActionValues = (values: any) => {
    let fieldErrors = {}

    if (!(typeof values.action_title === 'string' && values.action_title.trim().length > 0)) {
        fieldErrors['action_title'] = translate('ENTER_AN_ACTION')
    }

    if (!(typeof values.action_section === 'string' && values.action_section.trim().length > 0)) {
        fieldErrors['action_section'] = translate('INVALID_ACTION_SECTION')
    }
    if (!values.action_date && values.action_date !== undefined) {
        fieldErrors['action_date'] = true
    }
    return fieldErrors
}
const checkSectionExists = (selectedSectionId: string, sections: any[]) => {
    return sections?.some((section) => selectedSectionId === section.id)
}

export const getPreselectedSectionId = ({ action, selectedSection, sections }): string => {
    let selectedSectionId = ''
    if (selectedSection) {
        if (checkSectionExists(selectedSection.id, sections)) {
            selectedSectionId = selectedSection.id
        }
    }
    return action?.minutesSectionId ? `${action.minutesSectionId}` : selectedSectionId
}

export const getIntialValuesActions = (action, selectedSection, sections, dateInputMask) => ({
    action_title: action?.action_title || '',
    action_section: getPreselectedSectionId({
        action,
        selectedSection,
        sections
    } as any),
    action_date:
        action?.dueDate || action?.due_date
            ? format(action.dueDate ?? action?.due_date, dateInputMask)
            : undefined,
    action_assignees: getExistingAssigneesData(action),
    action_completed:
        action?.completionStatus === 'complete' || action?.action_completed === 'complete',
    action_notes: action?.notes || ''
})
