/**
 * common/formatters/date.ts
 *
 * This file exports functions to help format dates using Moment (https://momentjs.com/)
 */
import moment from 'moment'
import { find, isNil, pathOr, uniqWith } from 'rambdax'
import i18n from 'i18next'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'

export const USA_DATE_FORMAT = 'MM/DD/YYYY'
export const WORLD_DATE_FORMAT = 'DD/MM/YYYY'

export const getDateFormat_WorldOrUSA = (isDateInputMaskUS: boolean) => {
    return isDateInputMaskUS ? USA_DATE_FORMAT : WORLD_DATE_FORMAT
}
export const getDateMaskText = (isDateInputMaskUS: boolean) => {
    return isDateInputMaskUS ? i18n.t('USA_DATE_MASK') : i18n.t('NON_USA_DATE_MASK')
}

export const DateTimeFormat = 'MMM DD, YYYY HH:mm'

export const formatMeetingDate = ({ date, dateFormat }) => {
    if (!date || !date.length) {
        return null
    }

    if (!dateFormat) {
        dateFormat = 'MMMM DD, YYYY'
    }

    if (date.length === 1) {
        return moment(date[0].start_date).format(dateFormat)
    }

    if (date.length > 1) {
        const firstDate = moment(date[0].start_date).format(dateFormat)
        const lastDate = moment(date[date.length - 1].start_date).format(dateFormat)

        return `${firstDate} - ${lastDate}`
    }
    return null
}

export const formatDate = ({ date, dateFormat }: { date?: any; dateFormat?: string }) => {
    if (!date) {
        return null
    }

    if (!dateFormat) {
        dateFormat = transientStorageManager.dateFormat ?? 'MMMM DD, YYYY'
    }

    return moment(date).format(dateFormat)
}

export const formatDate_WorldOrUSA = ({ date, isDateInputMaskUS }) => {
    if (!date) {
        return null
    }

    const dateFormat = getDateFormat_WorldOrUSA(isDateInputMaskUS)
    const dateString = moment(date).format(dateFormat)
    return dateString || ''
}

export const formatDateOr = (defaultDate, path, rootObject, dateFormat: string) => {
    const dateString = pathOr(defaultDate, path, rootObject)
    if (dateString === defaultDate) return defaultDate

    const formattedDueDate = formatDate({ date: dateString, dateFormat: dateFormat })

    return formattedDueDate || defaultDate
}

// Time

export const formatTime = ({ time, timeFormat = 'hh:mm A' }) => {
    if (!time) {
        return null
    }

    return moment(time).format(timeFormat)
}

/**
 * Create a moment datetime with the given hour and minute
 * @param hour
 * @param minute
 */
export const getMomentTime = (
    hour: number | null | undefined,
    minute: number | null | undefined
) => {
    if (!isNil(hour)) {
        return moment()
            .hour(hour)
            .minute(minute ?? 0)
    } else {
        return null
    }
}

/**
 * Returns the number of minutes from midnight of a given moment datetime.
 * This helps compare just the time part of a datetime.
 * Example: A datetime at 10:15 AM would return (15 + (10 * 60)) = 615
 * @param m
 */
export const minutesOfDay = (m: Moment | null) => {
    if (!m) return 0

    return m.minutes() + m.hours() * 60
}

// Time Zones

/**
 * Returns a timezone list with duplicate entries of timezone code removed
 * @param timezoneList
 */
export const timezoneListWithUniqueCodes = (timezoneList: Timezone[]) => {
    return uniqWith((tz1, tz2) => tz1.attributes.code === tz2.attributes.code, timezoneList)
}

export const getTimezoneDetails = (timezoneId: string) => {
    const timeZones = transientStorageManager.timeZones as Timezone[]
    const predicate = (tz) => tz.id === timezoneId
    const timezoneDetails = find(predicate, timeZones)

    return timezoneDetails
}

export const getTimezoneLabelByID = (timezoneId: string) => {
    const timezoneDetails = getTimezoneDetails(timezoneId)
    const timezoneName = pathOr('', 'attributes.name', timezoneDetails)
    const timezoneOffset = pathOr('', 'attributes.utc_offset', timezoneDetails)

    return formatTimezoneLabel(timezoneOffset, timezoneName)
}

export const formatTimezoneLabel = (timezoneOffset?: string, timezoneName?: string) => {
    const timezoneNameString = timezoneName || ''
    const timezoneOffsetString = !!timezoneOffset ? `(GMT${timezoneOffset})` : ''

    return `${timezoneOffsetString} ${timezoneNameString}`
}
