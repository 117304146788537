import React, { memo, useCallback, useMemo } from 'react'
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    makeStyles,
    Typography,
    Tooltip
} from '@material-ui/core'
import { translate } from 'components/shared/internationalization'
import { PersonChip } from 'components/chip-input/types'
import { GeneralReviewTableProps, INVITEE_CONSTANTS } from '../../types'
import { getCheckedValue, prepareDataForSections } from '../../util'
import { ColorBaseWhite } from 'assets/styles/variables'

const sharedTextStyle = {
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center'
}
const useStyles = makeStyles(() => ({
    col: {
        height: '40px',
        padding: '10px 12px 10px 12px',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        border: '1px solid #EBEEF2'
    },
    tableContainer: {
        marginTop: '16px',
        overflow: 'auto',
        width: '100% !important',
        marginBottom: '16px'
    },
    header: {
        ...sharedTextStyle,
        textAlign: 'center',
        margin: 'auto',
        color: '#1E1E1E'
    },
    header1: {
        fontFamily: 'Source Sans Pro',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '20px',
        textAlign: 'left',
        width: '180px'
    },
    title: {
        width: '161.4px',
        height: '20px',
        textWrap: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    row: {
        height: '40px'
    },
    tableBody: {},
    th: {
        background: '#f0f0f0',
        width: '185px',
        height: '40px',
        padding: '10px 12px 10px 12px',
        textAlign: 'center',
        borderRadius: '2px'
    },
    generalTag: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        wordWrap: 'break-word'
    },
    selectAll: {
        fontFamily: 'Source Sans Pro',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'center',
        textWrap: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    checkbox: {
        padding: '0px',
        '&.Mui-checked': {
            color: '#2F3B4D'
        },
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
        },
        marginRight: '8px',
        width: '24px',
        height: '24px',
        '&.Mui-focusVisible, &:active': {
            boxShadow: `0px 0px 0px 3px #0774EE, 0px 0px 0px 1px ${ColorBaseWhite}`,
            borderRadius: '4px'
        }
    },
    tableHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#EBEEF2',
        borderColor: '#EBEEF2',
        zIndex: 999,
        backfaceVisibility: 'hidden'
    }
}))

const GeneralReviewTable: React.FC<GeneralReviewTableProps> = ({
    minuteTakerState,
    restrictedUsers,
    updateReviewers,
    dataState,
    setErrorObject,
    adminList
}) => {
    const classes = useStyles()

    const renderCell = (content: React.ReactNode, key: string | number) => (
        <TableCell className={classes.col} key={key}>
            {content}
        </TableCell>
    )
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, user: PersonChip, sectionId: number) => {
            const isChecked = e.target.checked
            user.section_ids = user.section_ids ?? []
            if (!isChecked) {
                user.section_ids = user.section_ids.filter((item) => item !== sectionId)
            } else {
                user.section_ids.push(sectionId)
            }
            updateReviewers(prepareDataForSections(user, minuteTakerState))
            setErrorObject(false)
        },
        [minuteTakerState, updateReviewers, setErrorObject]
    )
    const handleSelectAll = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, user: PersonChip, forceUncheck?: boolean) => {
            user.section_ids =
                e.target.checked && !forceUncheck
                    ? dataState.minutesSections.map((item) => Number(item.id))
                    : [
                          dataState.minutesSections.find(
                              (item) => item.sectionType === INVITEE_CONSTANTS.ATTENDEES
                          )?.id
                      ]
            updateReviewers(prepareDataForSections(user, minuteTakerState))
            setErrorObject(false)
        },
        [dataState.minutesSections, minuteTakerState, updateReviewers, setErrorObject]
    )
    const minutesSections = useMemo(
        () =>
            dataState.minutesSections.filter(
                ({ sectionType }) => sectionType !== INVITEE_CONSTANTS.ATTENDEES
            ),
        [dataState.minutesSections]
    )
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow className={classes.row}>
                        <TableCell className={`${classes.th} ${classes.tableHeader}`}></TableCell>
                        {restrictedUsers.map(({ text, id }) => (
                            <TableCell
                                className={`${classes.th} ${classes.tableHeader}`}
                                key={text + id}
                                scope="col">
                                <Tooltip title={text}>
                                    <Typography className={classes.header}>{text}</Typography>
                                </Tooltip>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    <TableRow className={classes.row}>
                        <TableCell className={classes.col}>
                            <Typography className={classes.header1}>
                                {translate('REVIEWER_TABLE_SELECT_ALL')}
                            </Typography>
                        </TableCell>
                        {restrictedUsers?.map?.((contact) => {
                            const checkedAll = getCheckedValue(dataState, contact)
                            const isindeterminate = !checkedAll && contact?.section_ids?.length > 1
                            return (
                                <TableCell className={classes.col} key={contact.text}>
                                    {isindeterminate ? (
                                        <Checkbox
                                            checked={true}
                                            color="primary"
                                            indeterminate
                                            disabled={adminList.includes(contact.id)}
                                            className={classes.checkbox}
                                            onChange={(e) => handleSelectAll(e, contact, true)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    const ev = (e as unknown) as React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                    ev.target.checked = true
                                                    handleSelectAll(ev, contact, true)
                                                }
                                            }}
                                            aria-label={`${contact.text}`}
                                            aria-checked="mixed"
                                            role="checkbox"
                                        />
                                    ) : (
                                        <Checkbox
                                            checked={checkedAll}
                                            color="primary"
                                            disabled={adminList.includes(contact.id)}
                                            className={classes.checkbox}
                                            onChange={(e) => handleSelectAll(e, contact)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    const ev = (e as unknown) as React.ChangeEvent<
                                                        HTMLInputElement
                                                    >
                                                    ev.target.checked = !checkedAll
                                                    handleSelectAll(ev, contact)
                                                }
                                            }}
                                            aria-label={`${contact.text}`}
                                            aria-checked={checkedAll}
                                            role="checkbox"
                                        />
                                    )}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                    {minutesSections?.map(
                        ({ name, id, sectionType }) =>
                            sectionType !== 'attendees' && (
                                <TableRow key={name} className={classes.row}>
                                    <TableCell className={classes.col}>
                                        <Tooltip title={name}>
                                            <Typography className={classes.generalTag}>
                                                {name}
                                            </Typography>
                                        </Tooltip>
                                    </TableCell>
                                    {restrictedUsers.map((contact) => {
                                        const checked = (contact.section_ids ?? []).includes(
                                            Number(id)
                                        )
                                        return renderCell(
                                            <Checkbox
                                                role="checkbox"
                                                aria-checked={checked}
                                                checked={checked}
                                                className={classes.checkbox}
                                                onChange={(e) =>
                                                    handleChange(e, contact, Number(id))
                                                }
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const ev = (e as unknown) as React.ChangeEvent<
                                                            HTMLInputElement
                                                        >
                                                        ev.target.checked = !checked
                                                        handleChange(ev, contact, Number(id))
                                                    }
                                                }}
                                                disabled={adminList.includes(contact.id)}
                                                aria-label={`${contact.text}`}
                                            />,
                                            contact.id
                                        )
                                    })}
                                </TableRow>
                            )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default memo(GeneralReviewTable)
