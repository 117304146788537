import React, { PureComponent } from 'react'

import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'

import { translate } from 'components/shared/internationalization/Trans'
import { css } from '@emotion/css'
import { ColorBaseSkyBlue } from 'assets/styles/variables'

type Sort = 'completionStatus' | 'dueDate' | 'assignees'

type SortPopoverProps = {
    onClosePopover: ({ closeReason }: { closeReason: Sort }) => void
    onSortChange: (sort: Sort) => void
    onRequestCloseExportPopover: () => void
    isOpen: boolean
    anchorElement: any
    closeReason?: string
}

const menuItems = [
    { primaryText: translate('ACTION_COMPLETE'), value: 'completionStatus' },
    { primaryText: translate('DUE_DATE'), value: 'dueDate' },
    { primaryText: translate('ASSIGNEES_MULTIPLE'), value: 'assignees' },
    { primaryText: translate('SECTION_LABEL'), value: 'sectionOrder' }
]

export class SortPopover extends PureComponent<SortPopoverProps> {
    onClosePopover = (closeReason) => {
        this.props.onClosePopover({ closeReason })
        this.props.onSortChange(closeReason)
    }

    render() {
        const { isOpen, onRequestCloseExportPopover, anchorElement, closeReason } = this.props
        const anchorOrigin: any = { horizontal: 'left', vertical: 'bottom' }
        const targetOrigin: any = { horizontal: 'left', vertical: 'top' }

        return (
            <Menu
                open={isOpen}
                anchorEl={anchorElement}
                anchorOrigin={anchorOrigin}
                transformOrigin={targetOrigin}
                onClose={onRequestCloseExportPopover}
                getContentAnchorEl={null}>
                {menuItems.map((menuItem) => (
                    <MenuItem
                        key={`popover-menu-item-${menuItem.value}`}
                        className={css`
                            &:focus,
                            &:active {
                                border: 2px solid ${ColorBaseSkyBlue};
                                border-radius: 2px;
                            }
                            &:hover {
                                background-color: #ebeef2;
                                border-radius: 2px;
                            }
                        `}
                        onClick={this.onClosePopover.bind(this, menuItem.value)}
                        selected={!!closeReason && closeReason === menuItem.value}>
                        {menuItem.primaryText}
                    </MenuItem>
                ))}
            </Menu>
        )
    }
}
