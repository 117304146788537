import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { actions as editorActions } from 'reducers/minuteTakerReducer'
import { pathOr } from 'rambdax'
import minuteTakerSelectors from 'selectors/minuteTakerSelectors'
import RightCaretIcon from 'assets/icons/RightCaretIcon'
import { atlasFontFamily } from 'components/atlas-components/typography'
import {
    AtlasGreyLight,
    ColorBaseBlueLight,
    ColorBaseMulberryBlue,
    ColorBaseWhite,
    ColorEastBay
} from 'assets/styles/variables'
import i18n from 'i18next'
import {
    DOCUMENT_STATUS,
    EDITOR_TABS
} from 'components/minutetaker/components/InvitationView/types'
import { useCommitteesState } from 'components/contexts/committee-context'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '2%',
        paddingRight: '2%',
        textTransform: 'none',
        borderRadius: '1px 0px 1px 0px',
        border: `1px solid ${AtlasGreyLight}`,
        boxShadow: 'none',
        position: 'relative',
        zIndex: 100,
        '& .MuiTabs-indicator': {
            backgroundColor: ColorEastBay,
            height: '3px'
        },
        '& .MuiTab-root.Mui-selected': {
            color: ColorBaseBlueLight
        }
    },

    tab: {
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 600,
        minWidth: '78px',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        color: ColorEastBay,
        '&:hover': {
            backgroundColor: '#94949429',
            borderRadius: '2px'
        },
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 3px ${ColorBaseWhite}, 0px 0px 0px 2px ${ColorBaseMulberryBlue} inset`,
            borderRadius: '2px'
        }
    },
    icon: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '10px'
    },
    count: {
        backgroundColor: '#E6E6E6',
        padding: '3px 4px 3px 4px',
        marginLeft: '10px',
        borderRadius: '2px',
        color: '#000000',
        fontFamily: atlasFontFamily
    },
    tooltip: {
        backgroundColor: '#1E1E1E',
        height: '36px',
        width: '234px',
        fontSize: '16px',
        fontFamily: atlasFontFamily,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center'
    },
    tooltipArrow: {
        color: '#1E1E1E'
    }
})
type Props = {
    currentMinuteItem: any
    currentSelectedTab: string
    showSideBar: boolean
    minutesActions: any
    actions: {
        currentSelectedTab: (...args: any[]) => void
        showSideBar: (...args: any[]) => void
    }
}

function NewEditorToolbar(props: Props) {
    const classes = useStyles()

    const handleChange = useCallback(
        (_event: React.ChangeEvent<{}>, newValue: string) => {
            props.actions.currentSelectedTab(newValue)
            if (newValue !== EDITOR_TABS.EDITOR && props.showSideBar)
                props.actions.showSideBar(false)
        },
        [props.actions, props.showSideBar]
    )

    const handleReviewDetails = useCallback(() => {
        const show = !props.showSideBar
        props.actions.showSideBar(show)
    }, [props.actions, props.showSideBar])

    const documentStatus = pathOr(
        '',
        ['attributes', 'status'],
        props.currentMinuteItem
    ) as DocumentStatusKey
    const { currentCommitteeRole } = useCommitteesState()

    const attendeesCount = (
        <div>
            {i18n.t('EDITOR_TOOLBAR.ATTENDEES')}
            <Tooltip
                title={String(
                    i18n.t('NUMBER_OF_ATTENDEES', {
                        count: props.currentMinuteItem.attributes.attendees
                            ? props.currentMinuteItem.attributes.attendees.length
                            : 0
                    })
                )}
                arrow
                placement="bottom-start"
                classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow
                }}>
                <span className={classes.count}>
                    {props.currentMinuteItem.attributes.attendees
                        ? props.currentMinuteItem.attributes.attendees.length
                        : 0}
                </span>
            </Tooltip>
        </div>
    )

    const actionCount = (
        <div>
            {i18n.t('EDITOR_TOOLBAR.ACTIONS')}
            <Tooltip
                title={String(
                    i18n.t('NUMBER_OF_ACTIONS', {
                        count: props.minutesActions?.length ? props.minutesActions?.length : 0
                    })
                )}
                arrow
                placement="bottom-start"
                classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow
                }}>
                <span className={classes.count}>
                    {props.minutesActions?.length ? props.minutesActions?.length : 0}
                </span>
            </Tooltip>
        </div>
    )
    return (
        <Paper
            className={classes.root}
            role="tabpanel"
            aria-label="Minutes Tabs"
            data-testid="minutes-tabs">
            <Tabs
                value={props.currentSelectedTab || EDITOR_TABS.EDITOR}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                aria-label={i18n.t('MINUTES_NAVIGATION')}>
                <Tab
                    className={classes.tab}
                    id="ContentContainer_ContentBody"
                    disableFocusRipple
                    disableRipple
                    label={i18n.t('EDITOR_TOOLBAR.EDITOR')}
                    value={EDITOR_TABS.EDITOR}
                    aria-label={i18n.t('EDITOR_TOOLBAR.EDITOR')}
                    data-testid="editor-tab"
                    tabIndex={0}
                />
                <Tab
                    className={classes.tab}
                    disableFocusRipple
                    disableRipple
                    label={attendeesCount}
                    value={EDITOR_TABS.ATTENDEES}
                    aria-label={`${i18n.t('EDITOR_TOOLBAR.ATTENDEES')}. ${i18n.t(
                        'NUMBER_OF_ATTENDEES',
                        {
                            count: props.currentMinuteItem.attributes.attendees
                                ? props.currentMinuteItem.attributes.attendees.length
                                : 0
                        }
                    )}`}
                    data-testid="attendees-tab"
                    tabIndex={0}
                />
                {currentCommitteeRole === 'ADMIN' && (
                    <Tab
                        className={classes.tab}
                        disableFocusRipple
                        disableRipple
                        label={actionCount}
                        value={EDITOR_TABS.ACTIONS}
                        aria-label={`${i18n.t('EDITOR_TOOLBAR.ACTIONS')}. ${i18n.t(
                            'NUMBER_OF_ACTIONS',
                            {
                                count: props.currentMinuteItem.attributes.actionItemsData.total
                            }
                        )}`}
                        data-testid="actions-tab"
                        tabIndex={0}
                    />
                )}
            </Tabs>
            {documentStatus === DOCUMENT_STATUS.IN_REVIEW &&
                props.currentSelectedTab === EDITOR_TABS.EDITOR && (
                    <Tab
                        disableFocusRipple
                        disableRipple
                        className={classes.tab}
                        onClick={handleReviewDetails}
                        label={
                            <span>
                                <span className={classes.icon}>
                                    <RightCaretIcon />
                                </span>
                                {i18n.t('EDITOR_TOOLBAR.REVIEW_DETAILS')}
                            </span>
                        }
                        tabIndex={0}
                    />
                )}
        </Paper>
    )
}

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: minuteTakerSelectors.currentMinuteItem(state.minuteTakerReducer),
        currentSelectedTab: minuteTakerSelectors.currentSelectedTab(state.minuteTakerReducer),
        showSideBar: minuteTakerSelectors.showSideBar(state.minuteTakerReducer),
        minutesActions: minuteTakerSelectors.minutesActions(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    const { currentSelectedTab, showSideBar } = editorActions
    return {
        actions: bindActionCreators(
            {
                currentSelectedTab,
                showSideBar
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEditorToolbar)
