import { atlasFontFamily, atlasTextBody } from 'components/atlas-components/typography'
import { OutlinedInputClassKey, OutlinedInputProps } from '@material-ui/core/OutlinedInput'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import { AtlasGreyMedium, ColorBaseSkyBlue } from 'assets/styles/variables'

export const MuiOutlinedInput: Partial<StyleRules<OutlinedInputClassKey, OutlinedInputProps>> = {
    root: {
        width: '99%',
        minHeight: '40px',
        backgroundColor: '#FFFFFF',
        marginLeft: '2px',
        fontFamily: atlasFontFamily,
        borderRadius: '4px',
        marginTop: '20px',
        '&:focus': {
            outline: 'none',
            borderColor: 'var(--black-near)',
            boxShadow: 'var(--medium)'
        },
        '&:hover': {
            outline: 'none',
            borderColor: 'var(--black-near)',
            boxShadow: 'var(--medium)'
        },
        '&$focused $notchedOutline': {
            boxShadow: `0 0 0 2px ${ColorBaseSkyBlue}`
        }
    },
    notchedOutline: {
        borderRadius: '4px',
        border: '1px solid #949494',
        borderColor: AtlasGreyMedium
    },
    adornedEnd: {
        paddingRight: '8px'
    },
    input: {
        ...atlasTextBody,
        padding: '6px 3px',
        textIndent: '8px',
        border: 'none',
        '&:focus': {
            outline: 'none',
            backgroundColor: '#FFF'
        }
    }
}
