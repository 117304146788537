const templateObject = (): any => {
    return {
        data: {
            type: 'minutes_sections',
            attributes: {
                name: null,
                weight: null,
                json_body: null,
                html_body: null,
                section_type: null,
                level: null
            }
        }
    }
}

export const serialize = (item) => {
    const requestData = templateObject()
    requestData.data.attributes.name = item.name
    requestData.data.attributes.order = item.order

    //Performance optimization
    if (typeof item.body === 'function') {
        item.body = item.body()
    }
    requestData.data.attributes.json_body = item.body
    requestData.data.attributes.html_body = item.html
    requestData.data.attributes.section_type = item.sectionType
    requestData.data.attributes.level = item.level

    return JSON.stringify(requestData)
}
