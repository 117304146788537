import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
`
const DiligentIcon = () => (
    <Svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.2692 15.9791C29.2692 20.8944 27.5173 24.9442 24.5221 27.7434C24.4876 27.7738 24.4617 27.8056 24.4272 27.836C22.1995 29.8812 19.2949 31.2403 15.9131 31.7599L15.6846 31.5254L20.7249 2.32461L20.9146 1.79053C26.0743 4.32922 29.2692 9.39648 29.2692 15.9791Z"
            fill="#EE312E"
        />
        <path
            d="M20.9163 1.79199L0.399902 17.1081V31.4762V32.0001H12.7543C13.848 32.0001 14.9015 31.9162 15.9148 31.7599C15.9148 31.7613 20.9163 1.79199 20.9163 1.79199Z"
            fill="#D3222A"
        />
        <path
            d="M20.9163 1.79185L20.774 2.00461L0.506257 17.2078L0.399902 17.108C0.399902 17.108 0.399902 17.1036 0.399902 17.0993V0H12.7069C13.5563 0 14.3798 0.0492107 15.1775 0.146185H15.2076C17.2226 0.386449 19.0781 0.914741 20.7381 1.70645C20.8042 1.7383 20.9163 1.79185 20.9163 1.79185Z"
            fill="#AF292E"
        />
    </Svg>
)
export default DiligentIcon
