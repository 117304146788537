import { executeRequest } from 'businesslayer/request-executor'
import * as requests from 'businesslayer/networkrequests/review'
import { translate } from 'components/shared/internationalization'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

export interface Reviewer {
    id: string
    attributes: {
        display_name: string
        first_name?: string
        last_name?: string
        email: string
        due_date?: Date
        review_completed: boolean
        userId: number
    }
}

export const tokenEndpoint = (minutesId: string) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')
    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        // TODO: Get error message
        return translate('LOAD_CONTACTS_ERROR', {
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(requests.tokenEndpoint(committeeId, minutesId), null, onError, {
        isForeground: false
    })
}
