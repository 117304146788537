import React from 'react'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'
import { setMinuteStatus } from 'businesslayer/api/minutes'
import UtilityIconBar from 'components/minutetaker/sectionlist/UtilityIconBar'
import { AtlasGreyLight } from 'assets/styles/variables'

//Note: true is not working and requires quite sophisticated code so far.

export const DocumentToolbar = (props: Props) => {
    const {
        isDocumentReadOnly,
        isIconOnlyMode,
        minutesSections,
        currentMinuteItem,
        isExportingMinutes,
        collapseAllVisible,
        isSavingSection,
        onStatusChange
    } = props
    const { toggleExpandCollapseAll } = props.actions

    const handleExpandCollapse = (isExpanded: boolean) => toggleExpandCollapseAll(isExpanded)

    const handleStatusChange = async (status, prevStatus?: string) => {
        const minutesId = currentMinuteItem.id
        await setMinuteStatus(minutesId, status, null)
        onStatusChange(status, prevStatus)
    }

    return (
        <ClassNames>
            {({ css }) => (
                <>
                    {!isDocumentReadOnly && (
                        <div
                            className={css`
                                display: flex;
                                background: ${AtlasGreyLight};
                                column-gap: 1px;
                            `}></div>
                    )}
                    <Margin />
                    <UtilityIconBar
                        minutesSections={minutesSections}
                        currentMinuteItem={currentMinuteItem}
                        isExportingMinutes={isExportingMinutes}
                        collapseAllVisible={collapseAllVisible}
                        isIconOnlyMode={isIconOnlyMode}
                        onStatusChange={handleStatusChange}
                        onToggleExpandAll={handleExpandCollapse}
                        isSavingSection={isSavingSection}
                        withExport
                    />
                </>
            )}
        </ClassNames>
    )
}

const Margin = styled('div')`
    margin-right: 10px;
`
type Props = {
    //TODO: Remove implementation detail of redux.  Should be "on" callbacks handled in container.
    actions: {
        addAction: () => void
        addSection: () => void
        toggleExpandCollapseAll: (isExpanded: boolean) => void
    }
    minutesSections: Array<any>
    currentMinuteItem: any
    isEditingAction: boolean
    isExpanded: boolean
    isExportingMinutes: boolean
    isSavingSection?: boolean
    collapseAllVisible: boolean
    isIconOnlyMode: boolean
    sortField: ActionListSort
    isDocumentReadOnly?: boolean
    onStatusChange: (status: string, prevStatus?: string) => void
    children: any
}
