import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: inherit;
    stroke: inherit;
    height: 1.5em;
    width: 1.5em;
`

type Props = {
    title?: string
    className?: string
}

export const MenuEditActionIcon = (props: Props) => (
    <Svg id="prefix__Layer_1" x={0} y={0} viewBox="0 0 24 23" xmlSpace="preserve" {...props}>
        <style>{'.prefix__st10rcea{fill:#247aa1}'}</style>
        <path
            transform="rotate(43.006 15.813 15.025)"
            className="prefix__st10rcea"
            d="M14.3 9.4h3v11.3h-3z"
        />
        <path
            transform="rotate(43.006 21.258 9.187)"
            className="prefix__st10rcea"
            d="M19.7 7.9h3v2.6h-3z"
        />
        <path
            className="prefix__st10rcea"
            d="M12.2 21.2l-3.4 1.3 1.2-3.4zM7.2 17.8l7-8.4-2.4-.6 2.8-4.6-3.4-.4L7 10.6l2.5.6-2.3 6.6zM7 18"
        />
        <path
            className="prefix__st10rcea"
            d="M10.4.6C4.9.6.5 5 .5 10.5c0 4.5 3.1 8.4 7.2 9.5l.6-1c-3.8-1-6.7-4.4-6.7-8.6 0-4.9 4-8.8 8.8-8.8 3.6 0 6.7 2.2 8.1 5.3l.8-.8c-1.6-3.2-5-5.5-8.9-5.5z"
        />
    </Svg>
)

export default MenuEditActionIcon
