import { atlasFontFamily } from 'components/atlas-components/typography'
import { AccordionClassKey } from '@material-ui/core/Accordion'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import { AtlasGreyLight } from 'assets/styles/variables'

export const MuiAccordion: Partial<StyleRules<AccordionClassKey, {}>> = {
    root: {
        fontFamily: atlasFontFamily,
        margin: '0px',
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: '0px',
            borderBottom: `solid 1px ${AtlasGreyLight}`
        },
        '&:focus': {
            outline: 'none'
        },
        borderBottom: `solid 1px ${AtlasGreyLight}`
    },
    expanded: {}
}
