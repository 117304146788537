import React from 'react'
import styled from '@emotion/styled'

export const EmptyList = ({ icon: Icon, message }: { message: React.ReactNode; icon?: any }) => {
    return (
        <EmptyWrapper>
            <div>
                {Icon && <Icon />}
                <div>{message}</div>
            </div>
        </EmptyWrapper>
    )
}

const EmptyWrapper = styled('div')`
    font-size: 22px;
    color: #8a92a1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`
