import { Overrides } from '@material-ui/core/styles/overrides'
import { MuiAccordion } from './mui-accordion'
import { MuiAccordionSummary } from './mui-accordion-summary'
import { MuiAppBar } from './mui-app-bar'
import { MuiButtonBase } from './mui-button-base'
import { MuiChip } from './mui-chip'
import { MuiCircularProgress } from './mui-circular-progress'
import { MuiDialog } from './mui-dialog'
import { MuiDialogActions } from './mui-dialog-actions'
import { MuiDialogContent } from './mui-dialog-content'
import { MuiDialogTitle } from './mui-dialog-title'
import { MuiFormControlLabel } from './mui-form-control-label'
import { MuiFormHelperText } from './mui-form-helper-text'
import { MuiIconButton } from './mui-icon-button'
import { MuiInputBase } from './mui-input-base'
import { MuiInputLabel } from './mui-input-label'
import { MuiMenu } from './mui-menu'
import { MuiMenuItem } from './mui-menu-item'
import { MuiOutlinedInput } from './mui-outlined-input'
import { MuiRadio } from './mui-radio'
import { MuiSelect } from './mui-select'

export const overrides: Overrides = {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAppBar,
    MuiButtonBase,
    MuiChip,
    MuiCircularProgress,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiFormControlLabel,
    MuiFormHelperText,
    MuiIconButton,
    MuiInputBase,
    MuiInputLabel,
    MuiMenu,
    MuiMenuItem,
    MuiOutlinedInput,
    MuiRadio,
    MuiSelect
}
