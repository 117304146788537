import { getSearchParameter, getUUID } from 'components/helpers/DomHelper'
import { getSupportedLanguageTag } from 'components/shared/internationalization/InternationalizationProvider'

import {
    setLocalStorageItem,
    getSessionStorageItem,
    setSessionStorageItem,
    getLocalStorageItem,
    removeSessionStorageItem
} from 'businesslayer/minutesLocalStore'
import Cookies from 'js-cookie'

export const getLaunchValues = () => {
    const {
        urlCommitteeId,
        committeeName,
        siteName,
        sessionId,
        initialAuthToken,
        userLanguageFromRequest,
        platform,
        uuid
    } = getUrlParameters()

    const initialCommitteeId = determineCurrentCommitteeId(urlCommitteeId)
    const userLanguage = determineUserLanguage(userLanguageFromRequest)

    return {
        initialCommitteeId,
        initialAuthToken,
        sessionId,
        committeeName,
        siteName,
        userLanguage,
        platform,
        uuid
    }
}

const getUrlParameters = () => {
    let urlCommitteeId = ''
    let committeeName = ''
    let siteName = ''
    let sessionId = ''
    let userLanguageFromRequest = ''
    const queryPlatform = getSearchParameter(window.location, ['parentPlatform', 'parentplatform'])
    if (queryPlatform === null || queryPlatform === undefined || queryPlatform === '') {
        urlCommitteeId = getSearchParameter(window.location, ['committeeId', 'committeeid'])
        userLanguageFromRequest = getSearchParameter(window.location, [
            'userLanguage',
            'userlanguage'
        ])
    } else {
        // It will run in case of boardswebadmin or boardswebdirector
        committeeName = getSessionStorageItem('committeeName')
        siteName = getSessionStorageItem('siteName')
        // Version 1.0 approach for authenticating via minutes-api; deprecated, and unsafe, and won't be supported in future releases.
        sessionId = getSessionStorageItem('sessionId')
        urlCommitteeId = getSessionStorageItem('committeeId')
        userLanguageFromRequest = getLocalStorageItem('userLanguage')
        removeSessionStorageItem('selectedStatusFilter')
    }
    const platform = getSessionStorageItem('parentPlatform')
    // Version >1.1 approach for authenticating via minutes-api, which follows the Oauth2 pattern

    // let uuid = getSearchParameter(window.location, ['uuid', 'uuid'])
    let uuid = getUUID(window.location.pathname)
    let initialAuthToken = ''
    if (platform !== 'boardswebadmin' && platform !== 'boardswebdirector') {
        initialAuthToken = Cookies.get('authToken')
        // if (initialAuthToken) {
        //     Cookies.remove('authToken', { path: '' })
        // }
    }
    // only applicable for one Click user
    if (
        (initialAuthToken === '' || initialAuthToken === undefined || initialAuthToken === null) &&
        (platform === '' || platform === undefined || platform === null)
    ) {
        initialAuthToken = getSearchParameter(window.location, ['authToken', 'authtoken'])
    }
    // We may or may not receive the user language passed as a request parameter; likely BoardsWeb would be doing this

    return {
        urlCommitteeId,
        committeeName,
        siteName,
        sessionId,
        initialAuthToken,
        userLanguageFromRequest,
        platform,
        uuid
    } as Record<string, string | null> & { platform: Platform | null }
}
const determineCurrentCommitteeId = (urlCommitteeId): string => {
    // Committee Id in the URL takes top priority, Then check the local storage
    return urlCommitteeId || getSessionStorageItem('currentCommitteeId')
}
const determineUserLanguage = (urlUserLanguage): string | null => {
    if (!!urlUserLanguage) {
        const languageTag = getSupportedLanguageTag(urlUserLanguage)
        if (!!languageTag) {
            return languageTag
        }
    }

    return null
}

export const storeUrlParams = ({
    siteName,
    committeeId,
    committeeName,
    userLanguage,
    platform
}) => {
    //Note we dont override values with null becuase users can refresh browser in BE
    //If there is UUID donot update the session storage details because there might be worng session storage details
    if (siteName !== null) {
        setSessionStorageItem('siteName', siteName)
    }
    if (!!committeeId) {
        setSessionStorageItem('currentCommitteeId', committeeId)
    }

    if (!!committeeName) {
        setSessionStorageItem('currentCommitteeName', committeeName)
    }

    if (!!userLanguage) {
        setLocalStorageItem('userLanguage', userLanguage)
    }

    if (!!platform) {
        setSessionStorageItem('parentPlatform', platform)
    }
}
