import { IconButtonClassKey, IconButtonProps } from '@material-ui/core/IconButton'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiIconButton: Partial<StyleRules<IconButtonClassKey, IconButtonProps>> = {
    root: {
        padding: 0,
        height: 24,
        width: 24,

        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}
