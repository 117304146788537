import { getJSON, postRawJSON } from 'common/util/request'
const templateObject = () => {
    return {
        authToken: ''
    }
}

export const exchangeToken = (authToken) => {
    const requestData = templateObject()
    requestData.authToken = authToken

    const jsonApiBody = JSON.stringify(requestData)

    const result = postRawJSON(`auth_token/exchange`, jsonApiBody)
    return result
}

export const exchangeUuid = (uuid) => {
    const result = getJSON(`v1/notification/${uuid}`, { shouldNormalize: false })

    return result
}
