import React, { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Typography,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MuiThemeProvider,
    Box
} from '@material-ui/core'
import { AtlasChipInputField } from 'components/chip-input/chip-input-field'
import { translate } from 'components/shared/internationalization'
import GeneralReviewTable from './GeneralRiviewTable'
import { GeneralReviewerProps } from '../../types'
import { generateReviewers, prepareDataForExecution } from '../../util'
import RestrictedErrorIcon from 'assets/icons/RestrictedErrorIcon'
import { Divider, IconButton } from 'components/shared/StyledComponents'
import { AtlasIcon } from 'web-components/atlas-icon'
import { StyledAtlasButtonOld } from 'components/minutetaker/components/ActionItemEditDialog/ActionItemEditDialog'
import { materialAtlasTheme } from 'material-atlas/theme'
import i18n from 'i18next'
import { ColorBaseSkyBlue } from 'assets/styles/variables'

const useStyles = makeStyles((theme) => ({
    generalTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingTop: '10px',
        color: 'var(--semantic-color-action-active, #252C44)'
    },
    generalTitle1: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: 'var(--semantic-color-action-active, #252C44)'
    },
    generalTag: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '8px',
        color: '#000000'
    },
    generalHeaderTag: {
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        color: '#000000'
    },
    chipInputField: {
        marginBottom: '16px',
        marginTop: '8px',
        '& > div > div': {
            margin: '0px !important',
            width: '100% !important'
        }
    },
    layout: {
        gap: '4px',
        display: 'grid'
    },
    layout1: {
        gap: '8px',
        display: 'flex'
    },
    retrictTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: 'var(--semantic-color-action-active, #252C44)'
    },
    root: {
        height: '40px',
        width: '40px',
        padding: 0,
        margin: theme.spacing(1),
        '& .MuiOutlinedInput-root': {
            margin: '5px !important'
        },
        '&:focus': {
            border: `2px solid ${ColorBaseSkyBlue}`,
            borderRadius: '4px'
        }
    },
    generalTag1: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '8px',
        placeItems: 'center',
        display: 'flex',
        color: '#000000'
    },
    button: {
        textTransform: 'capitalize',
        height: '40px',
        padding: ' 8px 12px 8px 12px',
        borderRadius: '2px',
        border: '1px solid  #455D82',
        gap: '8px',
        color: '#455D82'
    },
    layout2: {
        gap: '8px',
        display: 'flex',
        marginTop: '6px'
    },
    errorBox: {
        maxWidth: 'fit-content',
        height: '50px',
        padding: '4px 8px',
        borderRadius: '4px',
        border: '1px solid #AF292E',
        gap: '8px',
        display: 'flex',
        margin: '10px 0'
    },
    errorBox2: {
        height: '50px',
        padding: '4px 8px',
        borderRadius: '4px',
        border: '1px solid #AF292E',
        gap: '8px',
        display: 'flex',
        margin: '10px 0',
        width: 'fit-content',
        paddingRight: '48px'
    },
    errorIcon: {
        placeSelf: 'center',
        padding: '10px'
    },
    errorText: {
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    errorTextContainer: {
        gap: '4px',
        display: 'grid'
    },
    closeButton: {
        padding: 0,
        float: 'right',
        margin: 0,
        display: 'flex'
    },
    actions: {
        padding: '16px 24px',
        height: '74px'
    },
    header: {
        height: '72px',
        fontFamily: 'Source Sans Pro',
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '26px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#1E1E1E'
    },
    noBorder: {
        border: 'none',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left'
    },
    dailog: {
        maxWidth: '500px',
        margin: 'auto'
    },
    divider: {
        backgroundColor: '#e6e6e6'
    },
    dialogContent: {
        padding: '0px'
    }
}))

const GeneralReviewer: FC<GeneralReviewerProps> = ({
    minuteTakerState,
    updateReviewers,
    dataState,
    chipOptions,
    errorObject,
    setErrorObject,
    adminList
}) => {
    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false)

    const executeChanges = useCallback(
        (recipients, item) => {
            updateReviewers(prepareDataForExecution(recipients, item, minuteTakerState))
            setErrorObject(false)
        },
        [minuteTakerState, updateReviewers, setErrorObject]
    )
    const handleGeneralReviewer = useCallback(
        (e, item) =>
            executeChanges(
                generateReviewers(e, dataState, minuteTakerState, true, adminList),
                item
            ),
        [minuteTakerState, executeChanges, dataState, adminList]
    )

    const handleClose = useCallback(() => setOpen(false), [])
    const handleOpen = useCallback(() => setOpen(true), [])

    const handleRestrictedReviewer = useCallback(
        (e, item) =>
            adminList.includes(Number(item?.option?.id))
                ? handleOpen()
                : executeChanges(
                      generateReviewers(e, dataState, minuteTakerState, false, adminList),
                      item
                  ),
        [minuteTakerState, executeChanges, dataState, adminList, handleOpen]
    )
    const {
        genOptions,
        resOptions,
        genSelected,
        revSelected,
        unselectedsections,
        recipients
    } = useMemo(() => {
        const recipients = minuteTakerState.reviewers.recipients
        const generalReviewers = recipients.filter((it) => it.is_general)
        const nonGeneralReviewers = recipients.filter((it) => !it.is_general)

        const updatedGenOptions = chipOptions.filter(
            (it) => !nonGeneralReviewers.find(({ id }) => id === it.id)
        )

        const updatedResOptions = chipOptions.filter(
            (it) => !generalReviewers.find(({ id }) => id === it.id)
        )
        const unselectedsections = nonGeneralReviewers
            .filter((it) => (it.section_ids?.length ?? 0) <= 1)
            .map((it) => it.text)
            .join(', ')
        return {
            genOptions: updatedGenOptions,
            resOptions: updatedResOptions,
            genSelected: generalReviewers,
            revSelected: nonGeneralReviewers,
            unselectedsections,
            recipients
        }
    }, [minuteTakerState, chipOptions])

    const AlertDialog = useMemo(
        () => (
            <Dialog open={open} onClose={handleClose} className={classes.dailog}>
                <DialogTitle id="alert-dialog-title" className={classes.header}>
                    {translate('REVIEWER_REMOVE_DIALOG')}
                    <IconButton
                        tabIndex={0}
                        disableFocusRipple
                        disableRipple
                        onClick={handleClose}
                        className={classes.closeButton}
                        classes={{ root: classes.root }}>
                        <AtlasIcon name="close" size={24} data-testid="AtlasModal_CloseIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContent id="alert-dialog-description" className={classes.noBorder}>
                        {translate('REVIEWER_REMOVE_DIALOG_CONTENT')}
                    </DialogContent>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <StyledAtlasButtonOld
                        data-testid="ReviewInvitationModal_CancelBtn"
                        disableFocusRipple
                        disableRipple
                        onClick={handleClose}>
                        {translate('OK')}
                    </StyledAtlasButtonOld>
                </DialogActions>
            </Dialog>
        ),
        [handleClose, open, classes]
    )
    return (
        <MuiThemeProvider theme={materialAtlasTheme}>
            <Box className={classes.layout1}>
                <Typography className={classes.generalHeaderTag}>
                    {translate('REVIEWER_HEADER_TAG')}
                </Typography>
            </Box>
            <Divider className={classes.divider} />
            {AlertDialog}
            {errorObject && !recipients.length && (
                <Box className={classes.errorBox2}>
                    <Box className={classes.errorIcon}>
                        <RestrictedErrorIcon />
                    </Box>
                    <Box className={classes.errorTextContainer}>
                        <Typography className={classes.errorText}>
                            {translate('REVIEWER_UNSELECTED_ERROR')}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Box className={classes.layout}>
                <Typography className={classes.generalTitle}>
                    {translate('REVIEWER_GENERAL')}
                </Typography>
                <Typography className={classes.generalTag}>
                    {translate('REVIEWER_GENERAL_TAG')}
                </Typography>
            </Box>
            <Box className={classes.layout}>
                <AtlasChipInputField
                    aria-label={i18n.t('REVIEWER_GENERAL')}
                    className={classes.chipInputField}
                    options={genOptions}
                    value={genSelected}
                    onChange={handleGeneralReviewer}
                    disableCreate={true}
                    variant="outlined"
                    error={errorObject && !recipients.length}
                    isAtlas
                />
            </Box>

            <Box className={classes.layout}>
                <Typography className={classes.retrictTitle}>
                    {translate('REVIEWER_RESTRICTED')}
                </Typography>
                <Typography className={classes.generalTag}>
                    {translate('REVIEWER_RESTRICTED_TAG_A')}
                    <br /> {translate('REVIEWER_RESTRICTED_TAG_B')}
                </Typography>
            </Box>
            <Box className={classes.layout}>
                <AtlasChipInputField
                    aria-label={i18n.t('REVIEWER_RESTRICTED')}
                    className={classes.chipInputField}
                    options={resOptions}
                    value={revSelected}
                    onChange={handleRestrictedReviewer}
                    disableCreate={true}
                    variant="outlined"
                    error={errorObject && !recipients.length}
                    isAtlas
                />
            </Box>
            {revSelected.length > 0 && (
                <Fragment>
                    <Box className={classes.layout}>
                        <Typography className={classes.generalTitle1}>
                            {translate('REVIEWER_SECTION_TAG')}
                        </Typography>
                    </Box>
                    {errorObject && unselectedsections.trim().length > 0 && (
                        <Box className={classes.errorBox}>
                            <Box className={classes.errorIcon}>
                                <RestrictedErrorIcon />
                            </Box>
                            <Box className={classes.errorTextContainer}>
                                <Typography className={classes.errorText}>
                                    {translate('REVIEWER_SECTION_ERROR')}
                                </Typography>
                                <Tooltip title={unselectedsections}>
                                    <Typography className={classes.errorText}>
                                        {unselectedsections}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Box>
                    )}
                    {/* <Box className={classes.layout}> */}
                    <GeneralReviewTable
                        minuteTakerState={minuteTakerState}
                        restrictedUsers={revSelected}
                        updateReviewers={updateReviewers}
                        dataState={dataState}
                        setErrorObject={setErrorObject}
                        adminList={adminList}
                    />
                    {/* </Box> */}
                </Fragment>
            )}
        </MuiThemeProvider>
    )
}

export default GeneralReviewer
