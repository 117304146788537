import { createMuiTheme } from '@material-ui/core'
import { atlasFontFamily } from 'components/atlas-components/typography'
import { AtlasAccordionExpandIcon, AtlasChipDeleteIcon } from './icons'
import { overrides } from './overrides'
import { palette } from './palette'

export const materialAtlasTheme = createMuiTheme({
    palette,
    typography: {
        fontFamily: atlasFontFamily
    },
    props: {
        MuiAccordion: {
            square: true,
            TransitionProps: { timeout: 0 }
        },
        MuiAccordionSummary: {
            expandIcon: AtlasAccordionExpandIcon
        },
        MuiChip: {
            variant: 'outlined',
            deleteIcon: AtlasChipDeleteIcon
        },
        MuiDialog: {
            maxWidth: 'md',
            fullWidth: true,
            disableBackdropClick: true,
            PaperProps: { square: true }
        },
        MuiDialogTitle: {
            disableTypography: true
        },
        MuiIconButton: {
            disableRipple: true
        },
        MuiMenu: {
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            }
        },
        MuiTextField: {
            variant: 'outlined',
            fullWidth: true
            //disableUnderline: true
        },
        MuiOutlinedInput: {
            notched: false
        },
        MuiInputLabel: {
            shrink: true
        },
        MuiRadio: {
            color: 'primary',
            disableRipple: true
        },
        MuiSelect: {}
    },
    overrides: {
        ...overrides
    }
})
