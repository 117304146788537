//#region Components
import React from 'react'
import { Field } from 'react-final-form'
import { RadioButtonGroup } from 'components/shared/StyledComponents'
//#endregion

//#region Implementation
export const RadioButtonGroupField = ({ name, defaultSelected, children }) => (
    <Field
        name={name}
        render={({ input: { value, name, onChange, ...rest } }) => (
            <RadioButtonGroup
                name={name}
                defaultValue={defaultSelected}
                onChange={onChange}
                value={value}
                {...rest}>
                {children({ value })}
            </RadioButtonGroup>
        )}
        type="radio-group"
    />
)
//#endregion

export default RadioButtonGroupField
