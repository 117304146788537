import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: inherit;
    stroke: inherit;
    height: 1.5em;
    width: 1.5em;
`

type Props = {
    title?: string
    className?: string
}

export const MenuDeleteActionIcon = (props: Props) => (
    <Svg id="prefix__Layer_1" x={0} y={0} viewBox="0 0 24 23" xmlSpace="preserve" {...props}>
        <style>{'.prefix__st11rcdi{fill:#eb3732}'}</style>
        <path
            className="prefix__st11rcdi"
            d="M6.8 20.9H17l.9-14.8H5.8l1 14.8zm10-13.8L16 19.8H7.8L7 7.1h9.8zM14 3.8l-.6-.9h-3l-.6.9h-4v1H17.9v-1z"
        />
        <path
            className="prefix__st11rcdi"
            d="M11.5 8.8h.8v10.1h-.8zM13.9 9.3h.8v9h-.8zM9.1 9.3h.8v9h-.8z"
        />
    </Svg>
)

export default MenuDeleteActionIcon
