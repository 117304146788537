import React, { FC } from 'react'
import styled from '@emotion/styled'
import { ColorBaseSkyBlue, ColorBaseWhite } from 'assets/styles/variables'
import FoldIcon from 'assets/icons/FoldIcon'
import { addKeyPressHandler } from 'common/util/functions'
import { css } from '@emotion/css'
interface SidebarCardProps {
    children?: any
    sectionKey: string
    title: any
    onClick: any
    isExpanded: boolean
    'data-testid'?: string
}

export const SidebarCard: FC<SidebarCardProps> = ({
    children,
    isExpanded,
    onClick,
    title,
    sectionKey,
    'data-testid': dataTestid
}) => {
    return (
        <>
            <SidebarCardTitle
                data-testid={dataTestid}
                onClick={() => onClick(sectionKey)}
                aria-expanded={isExpanded ? 'true' : 'false'}
                role="button"
                tabIndex={0}
                className={css`
                    border: 2px solid transparent;
                    outline: none;
                    &:focus,
                    &:active {
                        border: 2px solid ${ColorBaseSkyBlue};
                        border-radius: 2px;
                        background: none;
                    }
                    width: 380px;
                    // height: calc(100vh - 240px);
                `}
                onKeyPress={(e) => (addKeyPressHandler(e) ? onClick(sectionKey) : null)}
                isExpanded={isExpanded}>
                <StyledCarrotIcon isExpanded={isExpanded} />
                {title}
            </SidebarCardTitle>
            <SidebarCardContent isExpanded={isExpanded}>{children}</SidebarCardContent>
        </>
    )
}

const SidebarCardTitle = styled('div')<{ isExpanded: boolean }>`
    display: flex;
    align-items: center;
    padding-left: 15px;
    min-height: 52px;
    line-height: 1.33;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    background-color: ${ColorBaseWhite};
    ${(props) => {
        return props.isExpanded
    }};

    cursor: pointer;
`
const SidebarCardContent = styled('div')<{ isExpanded: boolean }>`
    ${(props) => {
        return !!props.isExpanded
            ? `
            padding: 8px 16px 0px;
            background-color: ${ColorBaseWhite};
            // border-bottom: 1px solid #e6e6e6;
            box-sizing: border-box;`
            : `
            padding: 0px;
            height: 0px;
            overflow: hidden;
            box-sizing: border-box;`
    }};
`

const StyledCarrotIcon = styled(FoldIcon)<{ isExpanded: boolean }>`
    height: 12px;
    width: 12px;
    margin-right: 10px;
    ${(props) => (props.isExpanded ? `transform: rotate(90deg);` : `transform: rotate(0deg);`)};
    transition: transform 0.3s;
`

export default SidebarCard
