import React, { Component } from 'react'
import ReactDOM from 'react-dom'

//#region Components

import Attendees from './MinuteEditorAttendees'
import EditorListItem from './MinuteEditorListItem'
import { EditorToolbar, Toolbar } from '../../shared/Toolbar'
import Footer from './MinuteEditorListViewFooter'
import AutoScroller from './MinuteEditorListAutoScroller'

import DomHelper from '../../helpers/DomHelper'
import { deleteAction } from 'businesslayer/api/actions'
import { getMinutesDetail } from 'businesslayer/api/minutes'
//#endregion

//#region Redux

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actions } from '../../../reducers/minuteTakerReducer'
import { actions as actionEditorReducer } from 'reducers/actionEditorReducer'

import selectors from '../../../selectors/minuteTakerSelectors'
import styled from '@emotion/styled'
import { List, RootRef } from 'components/shared/StyledComponents'
import { ColorBaseWhite } from 'assets/styles/variables'
import { pathOr } from 'rambdax'
import ActionItemEditDialog from '../components/ActionItemEditDialog/ActionItemEditDialog'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { AddActionButton, AddSectionButton } from 'components/shared/Toolbar/ToolbarElements'
import { css } from '@emotion/css'
import { DocumentToolbar } from 'components/shared/Toolbar/ComposedToolbars/DocumentToolbar'
import { Box } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import UtilityExpandCollapse from './UtilityExpandCollapse'
import ListSubheader from '@material-ui/core/ListSubheader'
import MinutesActions from '../components/MinutesActions'
import { EDITOR_TABS, DOCUMENT_STATUS } from '../components/InvitationView/types'
import LinearIndeterminate from 'components/shared/LinearProgress'

//#endregion

//#region Styles

const headerHeight = 80

// const progressSize = 60
const progressContainerSize = 71
const toolbarSize = 56
const newToolbarSize = 80
const initialHeight = 640

const StyleWrapper = styled('div')<{ height: number }>`
    color: ${ColorBaseWhite};
    height: ${({ height }) => height}px;
`

const ListWrapper = styled('div')`
    background-color: ${ColorBaseWhite};
    min-width: 700px;
    max-width: 1090px;
    margin: 24px auto;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
`
const ToolbarCss = css`
    display: flex;
    gap: 16px;
    align-items: center;
`
const ToolbarBoxCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid #e6e6e6;
`
const listSubheader = css`
    z-index: 999;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
`
//#endregion

//#region Props

type Props = {
    actions: {
        parseSectionData: (...args: any[]) => void
        addAction: () => void
        addSection: () => void
        editAction: (...args: any[]) => void
        toggleExpandCollapseAll: (arg: boolean) => void
        removeAction: (...args: any[]) => void
        selectActionItem: (...args: any[]) => void
        // setGlobalFocusLockState: (...args: any[]) => void
        changeSectionOrder: (...args: any[]) => void
        loadActionsFulfilled: (...args: any[]) => void
        fetchMinuteItemFulfilled: (...args: any[]) => void
    }

    minutesSections: Array<any>
    currentMinuteItem: any
    selectedTabIndex: number
    actionItems: Array<any>
    isEditingAction: boolean
    currentSelectedTab: string
    onActionChecked: (...args: any[]) => void
    style: any
    setIsLoading: any
    onStatusChange: (status: string) => void
} & RouteComponentProps

//#endregion

//#region Implementation
//This is umprella component for sections.
//It also hosts tollbar with buttons and has some scroll management logic
class MinuteEditorListView extends React.PureComponent<Props> {
    scrolledNewItems: Map<string, any> = new Map()
    allListNodes: Map<string, any> = new Map()

    lastListItem: Component | null = null
    list?: any
    isScrollingToLastItem: boolean = false
    state = {
        height: initialHeight,
        isExpanded: true
    }

    handleExpandCollapse = (isExpanded: boolean) =>
        this.props.actions.toggleExpandCollapseAll(isExpanded)

    scrollToLastListItem = () => {
        //Note we scroll to last added item (only once per new added item)
        if (!this.lastListItem || !this.list || this.isScrollingToLastItem) {
            return
        }
        this.isScrollingToLastItem = true

        const lastItem = ReactDOM.findDOMNode(this.lastListItem)

        if (!(lastItem instanceof HTMLElement)) {
            return
        }

        const list = ReactDOM.findDOMNode(this.list) as Element

        if (lastItem && list && lastItem.offsetTop) {
            list.scrollTop = lastItem.offsetTop
            setTimeout(() => (this.isScrollingToLastItem = false), 50)
        }
    }

    //This function return actuial node by id. It is required for the scroller.
    getListNodeById = (id) => {
        if (!this.allListNodes || !id) {
            return null
        }

        return this.allListNodes.get(id)
    }

    onResize = (evt) => {
        this.setState({ height: evt.height - headerHeight })
    }

    handleEditAction = (actionId) => {
        // this.props.actions.setGlobalFocusLockState(true)
        this.props.actions.editAction({ action: { actionId: actionId }, block: null })
    }

    handleDeleteAction = async (actionId) => {
        const minutesId = this.props.currentMinuteItem.id
        await deleteAction(minutesId, actionId, deleteAction.bind(null, minutesId, actionId))

        this.props.actions.removeAction(actionId)

        const minutesDetails = await getMinutesDetail(minutesId, null, true)
        this.props.actions.fetchMinuteItemFulfilled(minutesDetails)
    }

    handleViewAllActions = (actionId) => {
        this.props.history.push(`/taker/${this.props.currentMinuteItem.id}/actions/${actionId}`, {
            actionId
        })
        this.props.actions.selectActionItem(actionId)
    }

    handleSectionReorder = (drag, hover) => {
        this.props.actions.changeSectionOrder(drag, hover)
    }

    createEditorItem = (
        list: Array<JSX.Element>,
        key: any,
        id: string,
        order: number,
        totalSections: number,
        isReadonlyDocument: boolean,
        count: number
    ) => {
        const ref = (c) => {
            this.lastListItem = c
            if (!c || !c.props) {
                return
            }
            this.allListNodes.set(c.props.sectionId, c)
        }

        const sectionActionItems = this.props.actionItems
            ? this.props.actionItems.filter((action) => {
                  return action.minutesSectionId === +id
              })
            : []

        //Sort section actions by sortOrder
        sectionActionItems.sort((a, b) => {
            if (a.sortOrder > b.sortOrder) return 1
            if (a.sortOrder < b.sortOrder) return -1

            return 0
        })

        const element = (
            <Draggable
                key={key}
                draggableId={key}
                index={count}
                direction="vertical"
                bounds="parent">
                {(provided, snapshot) => {
                    //for vertical movement while dragging
                    var transform = provided.draggableProps.style.transform
                    if (transform) {
                        var t = transform.split(',')[1]
                        var newStyle = {
                            ...provided.draggableProps.style,
                            transform: `translate(0px, ${t}`
                        }
                        provided.draggableProps.style = newStyle
                    }
                    return (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <EditorListItem
                                ref={ref}
                                key={key}
                                originalKey={key}
                                sectionId={id}
                                order={order}
                                totalCount={totalSections}
                                readonly={isReadonlyDocument}
                                onSectionReorder={this.handleSectionReorder}
                                actionItems={sectionActionItems}
                                onEditAction={this.handleEditAction}
                                onDeleteAction={this.handleDeleteAction}
                                onActionChecked={this.props.onActionChecked}
                                onViewAllActions={this.handleViewAllActions}
                                dragProps={provided.dragHandleProps}
                                snapshot={snapshot}
                            />
                        </div>
                    )
                }}
            </Draggable>
        )

        list.push(element)

        //If we have new item added its key is guid and so NaN
        //We then can scroll to it
        //We also store all scrolled to new item in a map
        //To scroll only once to those new items
        if (isNaN(key) && !this.scrolledNewItems.get(key)) {
            this.scrolledNewItems.set(key, true)
            setTimeout(() => this.scrollToLastListItem(), 50)
        }
    }

    getElementIdAndKey = (element) => {
        const id = element.id || element.tempId
        const key = element.tempId || element.id

        return {
            id,
            key
        }
    }
    getEditorItems = (isReadonlyDocument: boolean) => {
        const { currentMinuteItem, minutesSections } = this.props

        if (!currentMinuteItem || !minutesSections) {
            return null
        }

        let result: Array<JSX.Element> = []
        let count = 0
        for (var i in minutesSections) {
            if (minutesSections.hasOwnProperty(i)) {
                //Each list item has title and body. Body has section content.
                var element = minutesSections[i]
                if (element.sectionType !== 'minutes') {
                    continue
                }

                const { id, key } = this.getElementIdAndKey(element)

                //NOTE: Trick here is to use OLD tempId as key if available
                //That is to prevent items being destroyed as soon as new item is saved
                //We also pass original key attribute so underlying keyed controls could
                //use the same key before and after first save not destroying the original editor
                if (element.loaded) {
                    this.createEditorItem(
                        result,
                        key,
                        id,
                        element.order,
                        minutesSections.length,
                        isReadonlyDocument,
                        count
                    )
                } else {
                    //Note: loading all items at once is time consuming task
                    //because parsing server data takes time for each editor
                    //Parsing JSON into Draft JS is basically intrinsically slow
                    //So we load items one by one marking them loaded = false when
                    //we get them from server and then processing it here one by one
                    this.parseSectionData(element, minutesSections.length)
                    return result
                }
            }
            count++
        }
        return result
    }

    parseSectionData = (section, totalSectionsCount) => {
        //All this function does so far is marks items as loaded every X msec one by one
        //Then updates reducer so it has more "loaded" items and so it it would keep rendering
        //them in serial way
        setTimeout(() => {
            //Short breaks for smaller count of sections
            this.props.actions.parseSectionData(section)
        }, Math.min(100, totalSectionsCount * 5))
    }

    renderLoadingProgress = (minutesSections: Array<any>): JSX.Element | null => {
        if (minutesSections) {
            //We showing loading indicator
            if (minutesSections.find((c) => !c.loaded)) {
                return <LinearIndeterminate loading={true} />
            }
        }
        return null
    }
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        this.props.actions.changeSectionOrder(result.source.index + 1, result.destination.index + 1)
    }
     onDragStart = () => {
        document.querySelectorAll('.ai-modal').forEach((it: any) => (it.style.display = 'none'))
    }
    render() {
        const {
            minutesSections,
            currentMinuteItem,
            currentSelectedTab,
            isEditingAction
        } = this.props

        let progress: JSX.Element | null = this.renderLoadingProgress(minutesSections)

        const preogressHeightCorrection = progress ? progressContainerSize : 0
        const rootHeight =
            this.state.height - preogressHeightCorrection - toolbarSize - newToolbarSize

        const documentStatus = pathOr(
            '',
            ['attributes', 'status'],
            currentMinuteItem
        ) as DocumentStatusKey

        const isReadonlyDocument =
            documentStatus === DOCUMENT_STATUS.FINAL_DRAFT ||
            documentStatus === DOCUMENT_STATUS.APPROVED
        const allItems = this.getEditorItems(isReadonlyDocument)
        const isMinutesLoading = () =>
            minutesSections ? minutesSections.find((c) => !c.loaded) : false

        // Required to remmount Action Dialog every time we use it in order to
        // reference the correct Action Item
        const actionItemDialog = isEditingAction ? <ActionItemEditDialog /> : null

        return (
            <StyleWrapper height={rootHeight}>
                <Toolbar disabled={isMinutesLoading()}>
                    {(toolbarProps) => (
                        <DocumentToolbar
                            onStatusChange={this.props.onStatusChange}
                            isDocumentReadOnly={isReadonlyDocument}
                            {...toolbarProps}
                        />
                    )}
                </Toolbar>
                <EditorToolbar
                    currentMinuteItem={currentMinuteItem}
                    onStatusChange={this.props.onStatusChange}
                />
                {progress}
                {currentSelectedTab === EDITOR_TABS.ATTENDEES && (
                    <Attendees readonly={isReadonlyDocument} />
                )}

                {currentSelectedTab === EDITOR_TABS.EDITOR && (
                    <AutoScroller items={allItems} getListNodeById={this.getListNodeById}>
                        <RootRef rootRef={(c) => (this.list = c)}>
                            <List id="list-scroll">
                                <ListWrapper>
                                    <ListSubheader className={listSubheader}>
                                        {documentStatus === DOCUMENT_STATUS.DRAFT && (
                                            <Box className={ToolbarBoxCss}>
                                                <Box
                                                    className={ToolbarCss}
                                                    role="toolbar"
                                                    aria-label="Editor Toolbar">
                                                    <AddSectionButton
                                                        showLabels={true}
                                                        onClick={this.props.actions.addSection}
                                                        aria-label={i18n.t('ADD_SECTION_ITEM')}
                                                    />
                                                    <AddActionButton
                                                        showLabels={true}
                                                        onClick={this.props.actions.addAction}
                                                        aria-label={i18n.t('ADD_ACTION_ITEM')}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Toolbar>
                                                        {(toolbarProps) => (
                                                            <UtilityExpandCollapse
                                                                toggleExpandCollapseAll={
                                                                    toolbarProps.actions
                                                                        .toggleExpandCollapseAll
                                                                }
                                                            />
                                                        )}
                                                    </Toolbar>
                                                </Box>
                                            </Box>
                                        )}
                                    </ListSubheader>
                                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    {allItems}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Footer
                                        sectionCount={allItems ? allItems.length : 0}
                                        onSectionReorder={this.handleSectionReorder}
                                    />
                                </ListWrapper>
                            </List>
                        </RootRef>
                    </AutoScroller>
                )}
                {currentSelectedTab === EDITOR_TABS.ACTIONS && (
                    <MinutesActions setIsLoading={this.props.setIsLoading} />
                )}
                {actionItemDialog}
                <DomHelper onResize={this.onResize} />
            </StyleWrapper>
        )
    }
}
//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        minutesSections: selectors.minutesSections(state.minuteTakerReducer),
        selectedTabIndex: selectors.selectedTabIndex(state.minuteTakerReducer),
        actionItems: selectors.minutesActions(state.minuteTakerReducer),
        isEditingAction: selectors.isEditingAction(state.minuteTakerReducer),
        currentSelectedTab: selectors.currentSelectedTab(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        parseSectionData,
        addAction,
        addSection,
        editAction,
        removeAction,
        toggleExpandCollapseAll,
        selectActionItem,
        changeSectionOrder,
        loadActionsFulfilled,
        fetchMinuteItemFulfilled
    } = {
        ...actions,
        ...actionEditorReducer
    }

    return {
        actions: bindActionCreators(
            {
                parseSectionData,
                addAction,
                addSection,
                editAction,
                removeAction,
                toggleExpandCollapseAll,
                selectActionItem,
                changeSectionOrder,
                loadActionsFulfilled,
                fetchMinuteItemFulfilled
            },
            dispatch
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MinuteEditorListView))
//#endregion
