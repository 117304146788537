import React, { FC } from 'react'
import styled from '@emotion/styled'
import { ClassNames, css } from '@emotion/react'
import {
    Dialog,
    DialogActions,
    NewDialogTitle,
    NewDialogContent,
    IconButton,
    NewDialogTextField,
    AddActionEditActionButton
} from 'components/shared/StyledComponents'
import TextLengthCounter from 'components/shared/TextLengthCounter'
import { translate } from 'components/shared/internationalization'
import useMarkCompleteForm from './useMarkCompleteForm'
import { MAX_COMMENT } from './validation'
import { InputLabelStyled } from '../form/AssigneeChipInputField'
import { ColorBaseRichBlack } from 'assets/styles/variables'
import { AtlasButtonCss, StyledAtlasButtonOld } from '../ActionItemEditDialog/ActionItemEditDialog'
import { AtlasIcon } from 'web-components/atlas-icon'
import { errorMsgStyle } from '../InvitationView/InvitationView'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../../../reducers/applicationReducer'

import selectors from '../../../../selectors/applicationSelectors'
import { RestrictedError } from 'components/chip-input/types'
interface Props {
    workroomName: string
    minutesId: string
    minutesName: string
    isVisible: boolean
    onClose: () => void
    onSubmit: (networkResponse: any) => void
    actions: {
        setRestrictedError: (...args: any[]) => void
    }
    restrictedError: RestrictedError
}

const MarkCompleteView: FC<Props> = (props) => {
    /**
     * Submit handler for modal
     */
    const handleSubmitSuccess = (res: any) => {
        props.onSubmit(res)
    }

    const {
        // Input values
        commentInput,
        // Validation
        validation,
        // Network data
        isSubmitting,
        submitForm,
        //Events
        handleMessageChange,
        // Utils
        getCanSubmit,
        clearInputs
    } = useMarkCompleteForm(
        props.workroomName,
        props.minutesId,
        props.minutesName,
        handleSubmitSuccess,
        props.actions.setRestrictedError,
        props.onClose
    )

    /**
     * Close handler for modal
     */
    const handleClose = () => {
        clearInputs()
        props.onClose()
    }
    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    className={css`
                        .MuiPaper-root {
                            max-width: 1000px;
                            max-height: 100%;
                        }
                    `}
                    data-testid="ReviewCompleteModal"
                    scroll="paper"
                    fullWidth={true}
                    maxWidth="md"
                    open={props.isVisible}
                    onClose={handleClose}>
                    <NewDialogTitle>
                        <StyledTitle>
                            <div>{translate('MARK_COMPLETE_TITLE')}</div>
                        </StyledTitle>
                        <IconButton
                            tabIndex={0}
                            disableFocusRipple
                            disableRipple
                            className={css`
                                ${AtlasButtonCss}
                            `}
                            onClick={handleClose}>
                            <AtlasIcon name="close" size={24} data-testid="AtlasModal_CloseIcon" />
                        </IconButton>
                    </NewDialogTitle>

                    <NewDialogContent>
                        <div>
                            <StyledMessage
                                className={css`
                                    ${fontFamilyCss}
                                `}>
                                {translate('MARK_COMPLETE_MESSAGE')}
                            </StyledMessage>
                            <InputLabelStyled>
                                {translate('MARK_COMPLETE_COMMENT')}
                            </InputLabelStyled>
                            <NewDialogTextField
                                className={css`
                                    ${errorMsgStyle}
                                    margin: 8px 0px 0;
                                    .MuiOutlinedInput-inputMultiline {
                                        min-height: 116px !important;
                                        resize: vertical;
                                    }
                                `}
                                id="message-input"
                                variant="outlined"
                                data-testid="ReviewCompleteModal_Message"
                                // label={translate('MARK_COMPLETE_COMMENT')}
                                value={commentInput}
                                multiline={true}
                                onChange={handleMessageChange}
                                error={!!validation.commentError}
                                helperText={
                                    <TextLengthCounter
                                        errorText={validation.commentError}
                                        maxValue={MAX_COMMENT}
                                        value={commentInput}
                                    />
                                }
                            />
                        </div>
                    </NewDialogContent>
                    <DialogActions
                    // className={css`
                    //     height: auto;
                    //     padding: 28px 40px;
                    // `}
                    >
                        <AddActionEditActionButton
                            data-testid="ReviewCompleteModal_CancelBtn"
                            disabled={isSubmitting}
                            disableFocusRipple
                            onClick={isSubmitting ? () => {} : handleClose}>
                            {translate('CANCEL')}
                        </AddActionEditActionButton>
                        <StyledAtlasButtonOld
                            data-testid="ReviewCompleteModal_SendBtn"
                            className={css`
                                span {
                                    display: flex;
                                    justify-content: center;
                                }
                            `}
                            onClick={!getCanSubmit() || isSubmitting ? () => {} : submitForm}
                            disabled={!getCanSubmit() || isSubmitting}>
                            {translate('NOTIFY_BUTTON_TEXT')}
                        </StyledAtlasButtonOld>
                    </DialogActions>
                </Dialog>
            )}
        </ClassNames>
    )
}

const StyledTitle = styled('div')`
    display: flex;
    align-items: center;
`
const StyledMessage = styled('p')`
    margin-top: 24px;
    margin-bottom: 32px;
`
export const fontFamilyCss = css`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${ColorBaseRichBlack};
`
const mapStateToProps = (state, _) => {
    return {
        restrictedError: selectors.restrictedError(state.applicationReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarkCompleteView))
