import React from 'react'
import MinutesDetailPage from './MinutesDetailPage'
import { useCommitteesActions, useCommitteesState } from 'components/contexts/committee-context'
import { useMinutesManager } from 'pages/minutes-manager-page/use-minutes-manager'
/**
 * Container in order to useHooks for committees
 */
const MinutesDetailContainer = (props) => {
    const { setCurrentCommittee } = useCommitteesActions()
    const { currentCommitteeRole, currentCommittee } = useCommitteesState()

    const handleSetCommittee = (committeeId: string) => {
        setCurrentCommittee(committeeId)
    }
    useMinutesManager()
    return (
        <MinutesDetailPage
            {...props}
            onSetCommittee={handleSetCommittee}
            committeeRole={currentCommitteeRole}
            currentCommittee={currentCommittee}
        />
    )
}
export default MinutesDetailContainer
