import React, { useState, useEffect, FC, useCallback } from 'react'
import { uniqWith, find, pathOr } from 'rambdax'
import moment from 'moment'

//#region Components
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'

import MinutesEditor from '../components/MinutesEditor'

import { ColorBaseSkyBlue } from 'assets/styles/variables'
import { TrackChangesToolbar } from 'assets/ckeditor/toolbars'
import { MinuteActionsContainer as MinuteActions } from './MinuteActions/MinuteActionsContainer'
import { EditorSidebarState } from 'components/contexts/EditorSidebarConstate'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import RevealListIcon from 'assets/icons/RevealListIcon'
import HideListIcon from 'assets/icons/HideListIcon'
//#endregion

//#region Props

const CK_CLASSES = {
    ANNOTATION: 'ck-annotation',
    ACCEPT: '.ck-suggestion--accept',
    DISCARD: '.ck-suggestion--discard',
    NAME_ON_CARD: '.ck-annotation__info-name',
    INITIALS_ON_CARD: '.ck-user__name'
}

type Props = {
    minutesId: string
    section: any
    reviewers: any[]
    isSelected: boolean
    isAllExpanded: boolean
    actionItems: ActionItem[]
    role: UserRole
    reviewComplete?: boolean
    // We must autofocus the first editor in the list, this prevents the user from approving
    // a suggestion before any editors are focused causing preventing the onchange from being called
    autoFocus: boolean
    loading?: boolean
    onEditorChange: (sectionId: string, data: string) => void
    onSectionSelect: (sectionID: number | null) => void
    onActionReorder: (...args: any[]) => void
    onActionChecked: (...args: any[]) => void
    onEditAction: (...args: any[]) => void
    onDeleteAction: (...args: any[]) => void
    onViewAllActions: (...args: any[]) => void
    showSideBar?: boolean
    setIsLoading: any
}

//#endregion

type EditorDateType = string | number | Date

//#region Implementation
export const MinutesReviewEditorListItem: FC<Props> = (props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true)
    const [isEditorInit, setIsEditorInit] = useState<boolean>(false)
    const { editorSidebarRef } = EditorSidebarState()

    const { reviewers } = props
    const siteId = props.section.siteId
    const getCKEUserId = useCallback(
        (siteId: string | number, userId: string | number) => `${siteId}-${userId}`,
        []
    )

    // If expand all is toggled in the toolbar, set current isExpanded to that value
    useEffect(() => {
        setIsExpanded(props.isAllExpanded)
    }, [props.isAllExpanded])

    // expand/collapse the section when the editor and sidebar mounts/unmounts
    useEffect(() => {
        /**
         * Remove buttons based on user role
         * This cannot be done natively, so we must crawl the DOM and hide
         * items based on the author and role
         *
         * Get all CKEditor annotations and loop through them,
         * If the user is a reviewer
         * - Hide all accept icons
         * - If annotation belongs to user show reject icon, else hide it
         * If the user is an admin, show the icons
         *
         */
        const removeCKEditorButtons = () => {
            const sidebars = Array.from(document.querySelectorAll('.ck-sidebar'))

            const { currentUser } = transientStorageManager as any
            const { ACCEPT, ANNOTATION, DISCARD, NAME_ON_CARD, INITIALS_ON_CARD } = CK_CLASSES

            if (sidebars.length && currentUser) {
                const annotations = nodesToArr(document.getElementsByClassName(ANNOTATION))
                annotations.forEach((el) => {
                    const acceptButton = el.querySelector(ACCEPT) as HTMLElement
                    const rejectButton = el.querySelector(DISCARD) as HTMLElement

                    // Check if authorId matces the old userId (contact_id) or siteScoped userId (site_id-contact_id)
                    const authorId = String(pathOr('', 'dataset.authorId', el))
                    const isUser =
                        authorId === String(currentUser.id) ||
                        authorId === getCKEUserId(siteId, currentUser.id)

                    const showAccept = props.role === 'ADMIN'
                    const showReject = (isUser && !props.reviewComplete) || showAccept

                    if (acceptButton)
                        acceptButton.style.display = showAccept ? 'inline-flex' : 'none'
                    if (rejectButton)
                        rejectButton.style.display = showReject ? 'inline-flex' : 'none'

                    // TODO: Remove after all reviews currently pending during 2.3.1 release are closed
                    // Fix display name and initials for cards using old userId (pre site scoped userId)
                    const isOldId = !!authorId && !authorId.includes('-')
                    if (isOldId) {
                        const displayNameEl = el.querySelector(NAME_ON_CARD) as HTMLElement
                        const initialsEl = el.querySelector(INITIALS_ON_CARD) as HTMLElement
                        maskDisplayNameAndInitials(displayNameEl, initialsEl, authorId, reviewers)
                    }
                })
            }
        }

        setIsExpanded(isEditorInit && !!editorSidebarRef)
        let interval
        if (editorSidebarRef) {
            interval = setInterval(removeCKEditorButtons, 200)
        }
        return () => {
            if (interval) clearInterval(interval)
        }
    }, [
        isEditorInit,
        editorSidebarRef,
        props.reviewComplete,
        props.role,
        getCKEUserId,
        siteId,
        reviewers
    ])

    const {
        minutesId,
        section,
        onSectionSelect,
        isSelected,
        actionItems,
        onActionReorder,
        onActionChecked,
        onEditAction,
        onDeleteAction,
        onViewAllActions,
        showSideBar
    } = props

    // expand section when selected
    const handleSelected = (sectionId) => {
        onSectionSelect(sectionId)
        setIsExpanded(true)
    }

    /**
     * Debounced input handler
     */
    const handleChange = (data: string) => {
        props.onEditorChange(section.id, data)
    }

    const FoldIcon = renderFoldIcon(isExpanded)

    const sectionSidebarRef = !!editorSidebarRef
        ? editorSidebarRef.querySelector(`#sectionChangesSidebar-${section.id}`)
        : null
    const handleOnInit = useCallback(
        (editor) => {
            setIsEditorInit(true)
            editor.execute('trackChanges')
            const usersPlugin = editor.plugins.get('Users')
            const { currentUser } = transientStorageManager
            const currentUserData = currentUser && {
                id: getCKEUserId(siteId, currentUser.id),
                name: getReviewerDisplayName(currentUser)
            }

            const dedupedUsers = uniqWith(
                (x, y) => x.userId === y.userId,
                [...reviewers, currentUserData].filter(Boolean)
            )

            dedupedUsers.forEach((reviewer) => {
                try {
                    usersPlugin.addUser({
                        id: getCKEUserId(siteId, reviewer.userId),
                        name: getReviewerDisplayName(reviewer)
                    })
                } catch (_e) {
                    // Handle the error if necessary
                }
            })

            if (props.autoFocus) {
                setTimeout(() => {
                    editor.editing.view.focus()
                }, 300)
            }
        },
        [props, siteId, reviewers, getCKEUserId]
    )

    return (
        <ClassNames>
            {({ css }) => (
                <MinutesSection showSideBar={showSideBar}>
                    <SectionTitle isSelected={isSelected}>
                        <button
                            className={css`
                                ${reviewS}
                            `}
                            onClick={(e) => {
                                e.preventDefault()
                                setIsExpanded(!isExpanded)
                            }}>
                            <span>{FoldIcon}</span>
                        </button>
                        <SectionTitleText onClick={() => handleSelected(section.id)}>
                            <p>{section.name}</p>
                        </SectionTitleText>
                    </SectionTitle>
                    {!!sectionSidebarRef && !props.loading && minutesId ? (
                        <div
                            className={css`
                                ${sectionContentClass(isExpanded)}
                            `}
                            onClick={() => onSectionSelect(section.id)}>
                            <MinutesEditor
                                realtime={true}
                                // onChange={handleChange}
                                data={section.reviewHtmlBody || section.htmlBody}
                                editorConfig={{
                                    sidebar: {
                                        container: sectionSidebarRef
                                    },
                                    toolbar: TrackChangesToolbar.items,
                                    locale: {
                                        dateTimeFormat: (date: EditorDateType) =>
                                            toFormattedLocalTime(
                                                date,
                                                // `${transientStorageManager.timeFormat} ${
                                                //     transientStorageManager.dateFormat
                                                // }`
                                                `${transientStorageManager.dateFormat}`
                                            )
                                    },
                                    cloudServices: {
                                        documentId: `${section.siteId}-${section.id}`,

                                        connectionTimeout: 60,
                                        requestTimeout: 60
                                    },
                                    collaboration: {
                                        channelId: `${section.siteId}-${section.id}`
                                    },

                                    autosave: {
                                        save(editor: any) {
                                            return handleChange(editor.getData())
                                        }
                                    }
                                }}
                                readonly={props.role === 'DIRECTOR' && props.reviewComplete}
                                onEditorInit={handleOnInit}
                            />
                            <section>
                                <MinuteActions
                                    readonly
                                    actionItems={sectionActions(actionItems, section.id)}
                                    onActionReorder={onActionReorder}
                                    onActionChecked={onActionChecked}
                                    onEditAction={onEditAction}
                                    onDeleteAction={onDeleteAction}
                                    onViewAllActions={onViewAllActions}
                                />
                            </section>
                        </div>
                    ) : null}
                </MinutesSection>
            )}
        </ClassNames>
    )
}
const getReviewerDisplayName = (reviewer): string => {
    return reviewer.firstName && reviewer.lastName
        ? `${reviewer.firstName} ${reviewer.lastName}`
        : reviewer.displayName || reviewer.display_name
}
const getReviewerInitials = (displayName: string) => {
    try {
        if (!displayName || typeof displayName !== 'string') return ''

        const FIRST_CHAR_OF_WORDS = /\b\w/g
        const [firstInitial, secondInitial] = displayName.match(FIRST_CHAR_OF_WORDS) || ['', '']

        return `${firstInitial || ''}${secondInitial || ''}`.toUpperCase()
    } catch {
        return ''
    }
}
// TODO: Remove after all reviews currently pending during 2.3.1 release are closed
const maskDisplayNameAndInitials = (
    displayNameEl: HTMLElement,
    initialsEl: HTMLElement,
    oldId: string,
    reviewers: any[]
) => {
    try {
        const reviewer = find((r) => +r.userId === +oldId, reviewers)
        if (displayNameEl) {
            const mask = getReviewerDisplayName(reviewer)
            !!reviewer && displayNameEl.innerText !== mask && (displayNameEl.innerText = mask)
        }
        if (initialsEl) {
            const mask = getReviewerInitials(getReviewerDisplayName(reviewer))
            !!reviewer && initialsEl.innerText !== mask && (initialsEl.innerText = mask)
        }
    } catch {
        // Could not mask card display name
    }
}

/**
 * Convert HTML Collection to array
 * @param nodes
 */
const nodesToArr = (nodes: HTMLCollectionOf<Element>) => {
    return Array.from(nodes) as HTMLElement[]
}
const sectionActions = (actions: ActionItem[], id) => {
    if (!actions) {
        return []
    }

    return actions.filter((a) => {
        return +a.minutesSectionId === +id
    })
}

const renderFoldIcon = (isExpanded) => {
    return isExpanded ? <StyledRevealListIcon /> : <StyledHideListIcon />
}

const toFormattedLocalTime = (date: EditorDateType, format: string) => {
    return moment.utc(date).local().format(format)
}

const MinutesSection = styled('div')<{ showSideBar?: boolean }>`
    display: flex;
    flex-flow: column;
    border-bottom: 15px solid transparent;
    min-width: 60vw;
    width: ${({ showSideBar }) => (showSideBar ? '60vw' : '85vw')};
    @media (max-width: 1181px) {
        width: ${({ showSideBar }) => (showSideBar ? '60vw' : '100vw')};
        max-width: 100vw;
    }
`
const SectionTitle = styled('div')<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    min-height: 60px;
    column-gap: 4px;
    padding-left: 10px;
    margin: 0px;
    color: #1e1e1e;
    background-color: #f5f8f9;
    cursor: pointer;
`
const SectionTitleText = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    word-break: break-word;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    font-family: Source Sans Pro;
`

const sectionContentClass = (isExpanded: boolean) => css`
    ${!isExpanded && 'display: none;'};
`

export const reviewS = css`
    background: none;
    outline: none;
    border: none;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    :focus {
        box-shadow: 0px 0px 0px 1px ${ColorBaseSkyBlue}, 0px 0px 0px 2px ${ColorBaseSkyBlue};
        border-radius: 2px;
        background: none;
        outline: none;
        border: none;
    }
`
const StyledRevealListIcon = styled(RevealListIcon)`
    stroke: #1e1e1e;
    color: #1e1e1e;
`
const StyledHideListIcon = styled(HideListIcon)`
    stroke: #1e1e1e;
    color: #1e1e1e;
`

export default MinutesReviewEditorListItem
//#endregion
