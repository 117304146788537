import { atlasFontFamily } from 'components/atlas-components/typography'
import { ButtonBaseClassKey, ButtonBaseProps } from '@material-ui/core/ButtonBase'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import { makeStyles } from '@material-ui/core'

export const MuiButtonBase: Partial<StyleRules<ButtonBaseClassKey, ButtonBaseProps>> = {
    root: {
        fontFamily: atlasFontFamily
    }
}

export const useLowecaseButtonStyles = makeStyles({
    label: {
        textTransform: 'none'
    }
})

export const useAtlasButtonStyles = makeStyles({
    root: {
        height: '40px'
    }
})
