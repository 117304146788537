import React from 'react'
//================ Components Material =======================
//================ Components ===============================
import { ColorBaseGreyDark, AtlasStatusError, FontWeightRegular } from 'assets/styles/variables'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'
//================ Redux ====================================
//#region Props
type Props = {
    maxValue: number
    errorText?: string | null
    value: string | null
    style?: any
    colour?: boolean
}
//#endregion
//#region Implementation
function TextLengthCounter(props: Props) {
    const currentCount = props.value ? props.value.length : 0
    return (
        <ClassNames>
            {({ css }) => (
                <LengthCounterHelperText error={!!props.errorText}>
                    <span
                        className={
                            props.colour
                                ? css`
                                      color: #af292e;
                                  `
                                : ''
                        }>
                        {props.errorText}
                    </span>
                    <CounterStyle
                        maxReached={currentCount > props.maxValue}
                        style={{
                            ...props.style
                        }}>
                        {props.maxValue > 0 ? `${currentCount}/${props.maxValue}` : null}
                    </CounterStyle>
                </LengthCounterHelperText>
            )}
        </ClassNames>
    )
}
//#endregion
//#region Styled Components
const CounterStyle = styled('span')<{ maxReached: boolean }>`
    text-align: right;
    font-size: 12px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: ${FontWeightRegular};
    font-size: 13px;
    color: ${({ maxReached }) => (maxReached ? AtlasStatusError : ColorBaseGreyDark)};
`
const LengthCounterHelperText = styled('span')<{ error: boolean }>`
    display: flex;
    justify-content: ${({ error }) => (error ? 'space-between' : 'flex-end')};
    align-items: flex-end;
    color: #af292e;
`
// const errorClass = css`
//     color: ${AtlasStatusError};
// `
export default TextLengthCounter
