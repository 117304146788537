import React from 'react'

type Props = {
    title?: JSX.Element | string
    className?: string
}

export const StatusErrorIcon = ({ title, className }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        className={className}>
        <title>{title}</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.70711 3.29289L3.29289 4.70711L11.2929 12.7071L12.7071 11.2929L4.70711 3.29289Z"
            fill="#AF292E"
        />
    </svg>
)

export default StatusErrorIcon
