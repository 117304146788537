import React, { useEffect, useState } from 'react'
import ActionItemContainer from './MinuteActionItemContainer'
import MinuteActionFooter from './MinuteActionFooter'
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd'
import RevealListIcon from 'assets/icons/RevealListIcon'
import HideListIcon from 'assets/icons/HideListIcon'
import { Button } from 'components/shared/StyledComponents'
import i18n from 'common/util/internationalization'

type Props = {
    actionItems: Array<ActionItem>
    readonly?: boolean
    sectionId?: any
    onActionReorder: (...args: any[]) => void
    onActionChecked: (...args: any[]) => void
    onEditAction: (...args: any[]) => void
    onDeleteAction: (...args: any[]) => void
    onViewAllActions: (...args: any[]) => void
}

export function MinuteActionsContainer(props: Props) {
    const { actionItems, readonly } = props
    const [actionItemsArray, setactionItemsArray] = useState(actionItems)

    useEffect(() => {
        setactionItemsArray(actionItems)
    }, [actionItems])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        let tempUser = [...actionItemsArray]
        let [selectedRaw] = tempUser.splice(result.source.index, 1)
        tempUser.splice(result.destination.index, 0, selectedRaw)
        setactionItemsArray(tempUser)

        props.onActionReorder(tempUser)
    }

    const [showActions, setShowActions] = useState(true)

    const toggleActions = () => {
        setShowActions(!showActions)
    }
    const onDragStart = () => {
        document.querySelectorAll('.ai-modal').forEach((it: any) => (it.style.display = 'none'))
    }
    return (
        <div>
            <div>
                {actionItems.length === 0 ? null : (
                    <div datatest-id="action_item_counter" className="action-header">
                        <span aria-label={i18n.t('ACTION_ITEMS')}>
                            {i18n.t('ACTION_ITEMS')} ({actionItems.length})
                        </span>
                        <Button
                            aria-label={showActions ? i18n.t(';') : i18n.t('HIDE_ACTIONS')}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            aria-expanded={showActions ? 'true' : 'false'}
                            className="action-header-button"
                            onClick={toggleActions}>
                            {showActions ? (
                                <RevealListIcon className="accordion-list-icon" />
                            ) : (
                                <HideListIcon className="accordion-list-icon  " />
                            )}
                        </Button>
                    </div>
                )}
            </div>

            {showActions && (
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <Droppable droppableId={`droppable`}>
                        {(provided, _snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {actionItemsArray.map((action, index) => {
                                    return (
                                        <Draggable
                                            key={action.id}
                                            draggableId={action.id}
                                            index={index}
                                            sortOrder={action}>
                                            {(provided, snapshot) => {
                                                var transform =
                                                    provided.draggableProps.style.transform
                                                if (transform) {
                                                    var t = transform.split(',')[1]
                                                    var newStyle = {
                                                        ...provided.draggableProps.style,
                                                        transform: `translate(0px, ${t}`
                                                    }
                                                    provided.draggableProps.style = newStyle
                                                }
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}>
                                                        <ActionItemContainer
                                                            key={action.id}
                                                            isReadonly={!!readonly}
                                                            previewHeight={0}
                                                            actionItem={action}
                                                            onActionItemReorder={
                                                                props.onActionReorder
                                                            }
                                                            dragProps={provided.dragHandleProps}
                                                            snapshot={snapshot}
                                                            onActionChecked={() =>
                                                                props.onActionChecked(action)
                                                            }
                                                            onEditAction={() =>
                                                                props.onEditAction(action.id)
                                                            }
                                                            onDeleteAction={() =>
                                                                props.onDeleteAction(action.id)
                                                            }
                                                            onViewAllActions={() =>
                                                                props.onViewAllActions(action.id)
                                                            }
                                                        />
                                                    </div>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <MinuteActionFooter
                onActionItemReorder={props.onActionReorder}
                previewHeight={0}
                actionCount={actionItems.length}
                sectionId={actionItems.length > 0 ? actionItems[0].minutesSectionId : -1}
                isDragging={false}
            />
        </div>
    )
}

export default MinuteActionsContainer
