import React from 'react'
import { AtlasIcon } from 'web-components/atlas-icon'
import { AtlasGreyDarkVar } from 'assets/styles/variables'
import { jsx, css } from '@emotion/react'

const chipDeleteIconCls = css`
    position: relative;
    & > svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`

export const AtlasAccordionExpandIcon = React.createElement(AtlasIcon, { name: 'expand-right' })
export const AtlasChipDeleteIcon = jsx(AtlasIcon, {
    name: 'close',
    size: 16,
    color: AtlasGreyDarkVar,
    css: chipDeleteIconCls,
    'data-testid': 'person_chip_delete_btn'
})
