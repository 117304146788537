import React from 'react'

type Props = {
    title?: JSX.Element | string
    className?: string
}

export const ManageColumnIcon = ({ title, className }: Props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}>
        <title>{title}</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 3C0 1.89543 0.895431 1 2 1H22C23.1046 1 24 1.89543 24 3V10C24 11.1046 23.1046 12 22 12H2C0.89543 12 0 11.1046 0 10V3ZM2 2.5H22C22.2761 2.5 22.5 2.72386 22.5 3V10C22.5 10.2761 22.2761 10.5 22 10.5H2C1.72386 10.5 1.5 10.2761 1.5 10V3C1.5 2.72386 1.72386 2.5 2 2.5Z"
            fill="#5D7599"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 15C0 13.8954 0.895431 13 2 13H22C23.1046 13 24 13.8954 24 15V21C24 22.1046 23.1046 23 22 23H2C0.89543 23 0 22.1046 0 21V15ZM2 14.5H22C22.2761 14.5 22.5 14.7239 22.5 15V21C22.5 21.2761 22.2761 21.5 22 21.5H2C1.72386 21.5 1.5 21.2761 1.5 21V15C1.5 14.7239 1.72386 14.5 2 14.5Z"
            fill="#5D7599"
        />
    </svg>
)

export default ManageColumnIcon
