import { atlasFontFamily } from 'components/atlas-components/typography'
import { AtlasLow, AtlasGreyMedium } from 'assets/styles/variables'

export const MuiMenu = {
    paper: {
        fontFamily: atlasFontFamily,
        minWidth: '160px',
        borderRadius: '0px',
        boxShadow: AtlasLow
    },
    list: {
        padding: '0px'
    }
}

/**
 * We are adding a light border to the dropdown
 * menu so it doesn't blend into the rest of the
 * form
 */
export const atlasSelectMenuStyles: any = {
    ...MuiMenu,
    paper: {
        ...MuiMenu.paper,
        border: `1px solid ${AtlasGreyMedium}`
    }
}
