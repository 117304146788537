import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isSigninResult, SigninError } from '@diligent/sso-login/dist/index.js'
import ssoManagerFactoryService, {
    doLogout
} from '../../businesslayer/sso/SsoManagerFactoryService'
import {
    getLocalStorageItem,
    getSessionStorageItem,
    removeLocalStorageItem,
    removeSessionStorageItem,
    setLocalStorageItem,
    setSessionStorageItem
} from 'businesslayer/minutesLocalStore'

interface ISsoState {
    returnUrl: string
}

const IdentityLoginCallback: React.FunctionComponent = () => {
    const history = useHistory()

    useEffect(() => {
        const signin = async () => {
            const ssoManager = await ssoManagerFactoryService.create()
            const result = await ssoManager.signinRedirectCallback<ISsoState>()

            if (!isSigninResult(result)) {
                switch (result) {
                    case SigninError.DoubleAuthorization:
                        // Nothing to do. We're about to be redirected away.
                        return
                    case SigninError.Cancelled:
                    case SigninError.InvalidState:
                        // Just start again. No idea what they were trying to do, so just send them to the root after signing in.
                        await ssoManager.signinRedirect<ISsoState>({
                            state: {
                                returnUrl: '/'
                            }
                        })
                        return
                    default:
                        return
                }
            }

            // In development, we want to ensure that the user is logging in to the correct authority after qst_env change.
            // logout if the user is not logging in to the correct authority.
            if (
                // process.env.NODE_ENV === 'development' &&
                localStorage.getItem('diligent.identity.authority')?.indexOf('') === -1
            ) {
                doLogout()
            } else {
                // If the user is logging in to the correct authority, update the token. Ignore if the user is a mock user.
                if (process.env.NODE_ENV !== 'development') {
                    removeLocalStorageItem('sessionId')
                    removeSessionStorageItem('sessionId')
                    setLocalStorageItem('sessionId', result.user.access_token)
                    setSessionStorageItem('sessionId', result.user.access_token)
                } else {
                    removeLocalStorageItem('sessionId')
                    removeSessionStorageItem('sessionId')
                    setLocalStorageItem('sessionId', 'mock')
                    setSessionStorageItem('sessionId', 'mock')
                }
                sessionStorage.setItem('reloaded', 'false')
                const landingPath = getLocalStorageItem('landingPath')
                const bookIdParam = getSessionStorageItem('bookId') as any
                const bookCommitteeIdParam = getSessionStorageItem('committeeId') as any
                if (landingPath === '/' && bookIdParam && bookCommitteeIdParam) {
                    const params = new URLSearchParams({
                        bookId: bookIdParam,
                        committeeId: bookCommitteeIdParam
                    }).toString()
                    removeSessionStorageItem('bookId')
                    history.push(`/?${params}`)
                } else {
                    history.push(landingPath || '/')
                }
                setSessionStorageItem('authToken', 'false')
            }
        }

        signin()
    }, [history])

    return null
}

export default IdentityLoginCallback
