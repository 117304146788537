import { useMemo } from 'react'
import { getLaunchValues, storeUrlParams } from 'LaunchValues'
import { removeLocalStorageItem } from 'businesslayer/minutesLocalStore'

type Props = {
    children: (props: LaunchValues) => any
}

type LaunchValues = {
    committeeId: string | null
    authTokens: {
        initialAuthToken: string | null
        sessionId: string | null
    }
    platform: Platform | null
    siteName: string | null
    uuid: string | null
}

export const StoreUrlParams = (props: Props) => {
    // Memoize this so we only execute launch values logic on initial App load
    const launchValues = useMemo<LaunchValues>(() => {
        const {
            initialCommitteeId,
            initialAuthToken: urlInitialAuthToken,
            sessionId: urlSessionId,
            committeeName,
            siteName,
            userLanguage,
            platform,
            uuid
        } = getLaunchValues()

        const isFirstPageLoad = !!urlInitialAuthToken || !!urlSessionId
        if (!userLanguage && isFirstPageLoad) {
            // We didn't receive a language string in our GET parameters, but we did get an
            // authtoken; so this is the first page load, and we should delete any value stored
            // for the language.
            removeLocalStorageItem('userLanguage')
        }

        /* * * Set Initial Session Storage * * */
        storeUrlParams({
            committeeId: initialCommitteeId,
            committeeName,
            siteName,
            userLanguage,
            platform
        })

        return {
            committeeId: initialCommitteeId,
            authTokens: {
                initialAuthToken: urlInitialAuthToken,
                sessionId: urlSessionId
            },
            platform,
            uuid,
            siteName
        }
    }, [])

    return props.children(launchValues)
}
