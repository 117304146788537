/**
 * This is the Editor Sidebar Constate. It is a context module used store and update
 * the reference to the editor sidebar for track changes
 *
 */
import { useState, useCallback } from 'react'
import constate from 'constate'

// Types
// type Type = {}

// Create a custom hook
function useEditorSidebar() {
    /* * * STATE * * */
    const [editorSidebarRef, setEditorSidebarRef] = useState<Element | undefined>(undefined)

    /* * * ACTIONS * * */
    const setEditorSidebar = useCallback((sidebarRef: Element | undefined) => {
        setEditorSidebarRef(sidebarRef)
    }, [])

    // Consolidate state and actions for the Constate factory to use
    const editorSidebarState = { editorSidebarRef }
    const editorSidebarActions = { setEditorSidebar }

    return { editorSidebarState, editorSidebarActions }
}

// Constate factory returns the useable (Provider, useStateHook, useActionsHook)
const [EditorSidebarProvider, EditorSidebarState, EditorSidebarActions] = constate(
    useEditorSidebar,
    (value) => {
        return value.editorSidebarState
    },
    (value) => {
        return value.editorSidebarActions
    }
)

// Export (Provider, useStateHook, useActionsHook)
export { EditorSidebarProvider, EditorSidebarState, EditorSidebarActions }
