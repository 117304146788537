//Mock data

import blockingOperation from './blockingOperation'

import i18n from 'i18next'

import { transientStorageManager } from './minutesSessionStore'
import { guid } from '../Types'
import { getSessionStorageItem } from './minutesLocalStore'

export default class minutesDataManager {
    static setStore = (newStore) => {
        //store = newStore
        blockingOperation.setStore(newStore)
        //Use: store.dispatch(actions.nameOfTheAction(data))
    }

    static createDefaultMinuteItem = () => {
        return {
            id: null,
            title: null,
            includeLogo: true,
            includeBookStructure: true,
            includeSubTabs: false,
            logo: null,
            logoFilename: null,
            meetingDates: [
                {
                    id: 1,
                    date: null,
                    startTime: null,
                    endTime: null,
                    timeZoneCode: null
                }
            ],
            attendees: null,
            location: null
        }
    }

    //When we load book as linked at a time of creating book by link, we dont save it

    static addMeetingDay(minuteItem) {
        if (minuteItem.meetingDates.length >= 5) {
            //Cannot have more than 5 days
            return [...minuteItem.meetingDates]
        }

        let nextId = 1

        if (minuteItem.meetingDates.length) {
            nextId = minuteItem.meetingDates[minuteItem.meetingDates.length - 1].id + 1
        }
        const result = [
            ...minuteItem.meetingDates,
            {
                id: nextId,
                date: null,
                startTime: null,
                endTime: null,
                timeZoneCode: null
            }
        ]

        return result
    }

    static removeMeetingDay(minuteItem, dateObject) {
        //We dont remove last row
        if (minuteItem.meetingDates.length <= 1) {
            return [
                {
                    id: null,
                    date: null,
                    startTime: null,
                    endTime: null,
                    timeZoneCode: null
                }
            ]
        }
        const index = minuteItem.meetingDates.indexOf(dateObject)
        const result = [
            ...minuteItem.meetingDates.slice(0, index),
            ...minuteItem.meetingDates.slice(index + 1)
        ]

        return result
    }

    //Here we store selecte item so taker could load it into its state:
    static storeSelectedMinuteItem(allItems, id) {
        if (!allItems || !id) {
            return
        }

        if (Array.isArray(allItems)) {
            transientStorageManager.selectedMinuteItem = allItems.find((c) => +c.id === +id)
        } else {
            transientStorageManager.selectedMinuteItem = allItems[id]
        }
    }

    static removeMinuteItem(allItems, id, returnedSort) {
        if (!allItems || !allItems[id]) {
            return
        }

        const keys = returnedSort ? returnedSort : Object.keys(allItems)
        const currentIndex = keys.indexOf(id)

        if (currentIndex > 0) {
            //Only preselect if there is anything to preselect at all
            if (currentIndex === keys.length - 1) {
                //If that is last item we preselect previous
                transientStorageManager.selectedMinuteItem = allItems[keys[currentIndex - 1]]
            } else {
                transientStorageManager.selectedMinuteItem = allItems[keys[currentIndex + 1]]
            }
        } else {
            //Deleting first item
            if (keys.length > 1) {
                transientStorageManager.selectedMinuteItem = allItems[keys[1]]
            } else {
                transientStorageManager.selectedMinuteItem = null
            }
        }
        //transientStorageManager.selectedMinuteItem
        delete allItems[id]
        if (returnedSort) {
            var index = returnedSort.indexOf(id)

            if (index >= 0) {
                returnedSort.splice(index, 1)
            }
        }

        return {
            minutes: allItems,
            selectedMinuteItem: transientStorageManager.selectedMinuteItem,
            returnedSort: returnedSort
        }
    }

    static getLastTransientSelectedMinutesItem(allItems, sort) {
        //We check if we have latest selected item otherwise just return 0 item.
        //This function is important when a user clicks back
        if (!allItems) {
            return null
        }
        const keys = Object.keys(allItems)
        const sessionSelectedMinuteItemId = getSessionStorageItem('selectedMinuteItemId')
        const transientSelectedMinuteItem = transientStorageManager.selectedMinuteItem
        if (!transientSelectedMinuteItem && !sessionSelectedMinuteItemId) {
            const firstInSort = sort ? sort[0] : null
            return firstInSort || allItems[keys[0]].id
        }

        if (transientSelectedMinuteItem) {
            return transientSelectedMinuteItem.id
        }

        if (sessionSelectedMinuteItemId) {
            return sessionSelectedMinuteItemId
        }
    }

    //Sections

    static createDefaultSection(minutesId, sectionType = 'minutes') {
        return {
            minutesId: minutesId,
            name: sectionType === 'minutes' ? i18n.t('DEFAULT_SECTION_TITLE') : i18n.t('ATTENDEES'),
            order: sectionType === 'minutes' ? 1 : 0,
            body: null,
            html: null,
            tempId: guid(),
            sectionType: sectionType,
            loaded: true
        }
    }

    static updateSectionObject(toObject, fromObject, minutesId) {
        toObject.id = fromObject.id
        //We dont update body because user keeps typing while we are saving and if we changed
        //while we were saving we dont want to overwrite most recent changes
        //toObject.name = fromObject.attributes.name
        //to.body = from.attributes.jsonBody
        //to.html = from.attributes.htmlBody

        toObject.order =
            fromObject.attributes.order !== null ? parseInt(fromObject.attributes.order, 10) : 1
        toObject.minutesId = minutesId
        toObject.level = fromObject.attributes.level
        toObject.sectionType = fromObject.attributes.sectionType
        toObject.createdAt = fromObject.attributes.createdAt
        toObject.updatedAt = fromObject.attributes.updatedAt
    }

    // ================ ACTIONS =======================

    static createDefaultMinuteAction(minutesId) {
        return {
            id: null,
            minutesId: minutesId,
            minutesSectionId: null,
            text: '',
            notificationStatus: 'unsent',
            completionStatus: 'incomplete',
            dueDate: null,
            assignees: []
        }
    }
}
