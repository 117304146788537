import { setSessionStorageItem, removeSessionStorageItem } from './minutesLocalStore'

//Transient storage (wiped if user refreshes browser)
//If the items are gone from transitory storage we reload them from server.
export const transientStorageManager: TransientStorageManager = {
    _selectedMinuteItem: undefined,
    timeZones: [],
    currentTimezone: '',
    isDateInputMaskUS: true,
    dateFormatInput: '',
    dateFormat: '',
    timeFormat: '',
    platform: null,
    siteName: '',
    linkedBookDetails: null,
    linkedBookError: null,
    createForBook: null,
    committeeName: null,
    allContacts: null,
    allBooks: new Map(),
    currentUser: null,
    clientTitle: '',
    linkedBookId: null,
    book_summarization_enabled: false,
    ai_chat_bot: {
        content_ai_for_bwd_enabled: false,
        help_ai_for_bwa_enabled: false,
        help_ai_for_bwd_enabled: false
    },

    get selectedMinuteItem() {
        return this._selectedMinuteItem
    },
    set selectedMinuteItem(item) {
        this._selectedMinuteItem = item

        //Save just the selectedMinutesId is sessionStorage
        if (item) {
            setSessionStorageItem('selectedMinuteItemId', item.id)
        } else {
            removeSessionStorageItem('selectedMinuteItemId')
        }
    },
    logo_blob: '',
    parentAppUrl: ''
}

type TransientStorageManager = {
    allContacts: any | null
    allBooks: Map<any, any>
    clientTitle: any
    committeeName: any | null
    createForBook: any | null
    currentTimezone: string
    currentUser: Contact | null
    dateFormat: string
    dateFormatInput: string
    isDateInputMaskUS: boolean
    linkedBookDetails: any | null
    linkedBookError: any | null
    linkedBookId: string | null
    platform: Platform | null
    _selectedMinuteItem: any
    selectedMinuteItem: any
    siteName: string
    timeFormat: string
    timeZones: any[]
    ai_chat_bot: AI_CHAT_BOT_TYPE
    logo_blob: string | null
    parentAppUrl: string
    book_summarization_enabled: boolean
}
export type AI_CHAT_BOT_TYPE = {
    content_ai_for_bwd_enabled: boolean
    help_ai_for_bwa_enabled: boolean
    help_ai_for_bwd_enabled: boolean
}
