import React, { useState, useCallback, useEffect } from 'react'
import { DialogActions, NewDialogTitle } from 'components/shared/StyledComponents'
import MainInfoSection from './MainInfoSection'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { AtlasButton } from 'components/atlas-material/button'
import MomentUtils from '@date-io/moment'
import MinutesMeetings from 'components/minutes-meetings/minutes-meetings'
import selectors from 'selectors/minuteAttendeesSelectors'
import { connect } from 'react-redux'
import { useCreateReport } from 'pages/minutes-attendance-report/use-create-report'
import { makeStyles } from '@material-ui/core/styles'
import { actions } from 'reducers/minutesAttendeesReducer'
import { bindActionCreators, Dispatch, Action } from 'redux'
import i18n from 'common/util/internationalization'
import { ReportData, ReportRow } from 'components/minutes-report-details/minutes-report-details'
import { Theme, Box, Typography } from '@material-ui/core'
import { AtlasGreyLight, ColorBaseWhite } from 'assets/styles/variables'
type Props = {
    newReportData?: ReportData
    actions: {
        storeCreateReportResponse: () => void
        setCreateReportLoading: () => void
        preserveFormState: (data) => void
        initializeState: () => void
    }
    attendanceReportResponse?: {
        attendance_data: ReportRow[]
        total_meeting_count?: number
    }
    createReportLoading?: boolean
    preserveFormState?: {
        isStateMatched: boolean
        isReportGenerated: boolean
    }
}
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        borderRadius: '8px',
        border: `1px solid ${AtlasGreyLight}`,
        backgroundColor: theme.palette.common.white
    },
    titleSection: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        borderRadius: '8px 8px 0px 0px',
        borderWidth: '1px !important'
    },
    dialogActions: {
        borderTop: `1px solid ${theme.palette.divider}`,
        height: '88px'
    },
    dialogActionsAlt: {
        borderTop: `1px solid ${theme.palette.divider}`,
        height: '88px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            height: '140px',
            display: 'block',
            padding: '1rem !important'
        }
    },
    dateSection: {
        padding: '1rem',
        overflow: 'auto',
        height: 'calc( 99vh - 250px )',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 310px )',
            padding: '1rem !important'
        }
    },
    dateSectionAlt: {
        padding: '1rem',
        overflow: 'auto',
        height: 'calc( 100vh - 250px )',
        [theme.breakpoints.down('lg')]: {
            height: 'calc(100vh - 310px )',
            padding: '1rem !important'
        }
    },
    updateReport: {
        color: theme.palette.error.main,
        marginLeft: '-0.5rem',
        fontWeight: 400,
        flex: '2',
        fontSize: '13px',
        lineHeight: '14px',
        fontStyle: 'normal',
        display: 'flex',
        padding: '4px 5px 4px 5px',
        alignItems: 'center',
        gap: '8px',
        height: '56px',
        width: '220px',
        borderRadius: '4px',
        margin: '-10px 0px 0px 0px',
        border: `1px solid #EAA14B`,
        background: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            float: 'none',
            margin: '0',
            width: 'auto',
            marginBottom: '5px',
            padding: '5px'
        }
    },
    createReportBtn: {
        fontWeight: 600,
        textTransform: 'none',
        padding: 'inherit',
        fontSize: '16px',
        fontStyle: 'normal',
        fontFamily: 'Source Sans Pro',
        lineHeight: '20px',
        float: 'right',
        textWrap: 'nowrap',
        // height: '56px',
        width: '124px',
        background: '#455D82',
        margin: '-10px -10px 0px 0px',
        color: theme.palette.common.white,
        '&:focus': {
            outline: 'none',
            boxShadow: '0px 0px 0px 1px #ffffff, 0px 0px 0px 3px #3e95fa',
            borderRadius: '2px'
        },
        '& button': {
            height: '56px !important'
        },
        [theme.breakpoints.down('sm')]: {
            float: 'none',
            margin: '0 !important',
            width: '100%',
            height: '40px'
        },
        '&:hover': {
            background: '#455D82'
        }
    },
    statusAlert: {
        marginLeft: '-2rem',
        marginTop: '0px',
        [theme.breakpoints.down('sm')]: {
            margin: '5px'
        }
    }
}))

const MinutesReport: React.FC<Props> = React.memo(
    ({ newReportData, actions, createReportLoading, preserveFormState }) => {
        const classes = useStyles()
        const [canSaveMinutes, setCanSaveMinutes] = useState(false)
        const [reportData, setReportData] = useState<any>({})

        const { handleCreateReport } = useCreateReport({
            storeCreateReportResponse: actions.storeCreateReportResponse,
            setCreateReportLoading: actions.setCreateReportLoading
        })

        const onHandleSubmit = useCallback(() => {
            handleCreateReport(newReportData)
            // preserveFormState after action
            actions.preserveFormState({
                committeeName: newReportData?.committeeName ?? '',
                startDate: newReportData?.startDate ?? '',
                endDate: newReportData?.endDate ?? '',
                isReportGenerated: true
            })
        }, [handleCreateReport, newReportData, actions])

        const submitMinutesPropertiesForm = useCallback(() => {
            const form = document.getElementById('formBody')
            if (form) form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
        }, [])

        const handleClick = useCallback(() => {
            submitMinutesPropertiesForm()
        }, [submitMinutesPropertiesForm])

        const isSubmitConditionMet =
            !preserveFormState?.isStateMatched &&
            preserveFormState?.isReportGenerated &&
            newReportData?.selectedCommittee?.length
        useEffect(() => {
            return () => {
                actions.initializeState()
            }
        }, [actions])
        const isButtonDisabled =
            !canSaveMinutes ||
            !reportData.startDate ||
            createReportLoading ||
            !newReportData?.selectedCommittee?.length
        return (
            <div className={classes.container}>
                <NewDialogTitle className={classes.titleSection}>
                    {i18n.t('CREATE_ATTENDANCE_REPORT')}
                </NewDialogTitle>
                <Box
                    className={isSubmitConditionMet ? classes.dateSectionAlt : classes.dateSection}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MainInfoSection
                            onHandleSubmit={onHandleSubmit}
                            canSaveMinutes={canSaveMinutes}
                            setCanSaveMinutes={setCanSaveMinutes}
                            reportData={reportData}
                            setReportData={setReportData}
                        />
                    </MuiPickersUtilsProvider>
                    <MinutesMeetings />
                </Box>
                <DialogActions
                    className={
                        isSubmitConditionMet ? classes.dialogActionsAlt : classes.dialogActions
                    }>
                    {isSubmitConditionMet ? (
                        <Typography className={classes.updateReport}>
                            {/* <StatusAlertIcon className={classes.statusAlert} /> */}
                            {i18n.t('UPDATE_REPORT_MESSAGE')}
                        </Typography>
                    ) : null}
                    <AtlasButton
                        disableRipple
                        disableFocusRipple
                        style={{
                            ...(isButtonDisabled
                                ? {
                                      background: 'var(--action-primary-disabled)',
                                      color: `${ColorBaseWhite}`
                                  }
                                : {}),
                            ...(isSubmitConditionMet ? { flex: '1' } : {})
                        }}
                        disabled={isButtonDisabled}
                        onClick={handleClick}
                        className={classes.createReportBtn}
                        data-testid="CreateReport_SaveBtn">
                        {i18n.t('CREATE_REPORT')}
                    </AtlasButton>
                </DialogActions>
            </div>
        )
    }
)

const mapStateToProps = (state, _) => {
    return {
        newReportData: selectors.setNewReportData(state.minutesAttendeesReducer),
        attendanceReportResponse: selectors.setCreateReportDataResponse(
            state.minutesAttendeesReducer
        ),
        createReportLoading: selectors.setCreateReportLoading(state.minutesAttendeesReducer),
        preserveFormState: selectors.getFormChangeState(state.minutesAttendeesReducer)
    }
}
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(MinutesReport)
