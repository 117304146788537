import React from 'react'
import Modal from 'react-modal'

//================ Components Material =======================

//================ Components ===============================
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import DiligentLogo from 'assets/DiligentLogo'
import { Body, Title } from './typography/Typography'
import { translate } from './internationalization'
import { AtlasPrimary, AtlasWhite_100 } from 'assets/styles/variables'
//#region Styles

//#endregion

//#region Implementation

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root')

export function UnsupportedModal() {
    const title = translate('DEVICE_SUPPORT.title')
    const unsupportedMessage = translate('DEVICE_SUPPORT.unsupported_message')
    const supportedMessage = translate('DEVICE_SUPPORT.supported_message')

    return (
        <StyledModal isOpen={true} style={customStyles}>
            <StyledBody>
                <StyledDiligentLogo />
                <Title
                    className={css`
                        margin-bottom: 1rem;
                    `}>
                    {title}
                </Title>
                <Body type="body2" className={alignCenterClss}>
                    {unsupportedMessage}
                </Body>
                <Body
                    type="body2"
                    className={css`
                        margin: 0.2rem;
                        ${alignCenterClss};
                    `}>
                    {supportedMessage}
                </Body>
            </StyledBody>
        </StyledModal>
    )
}

//#endregion

const StyledModal = styled(Modal)`
    height: 100%;
    width: 100%;
`

const StyledBody = styled('section')`
    background-color: ${AtlasPrimary};
    color: ${AtlasWhite_100};
    justify-content: center;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: x-large;
    flex-direction: column;
    padding: 20px;
`

const StyledDiligentLogo = styled(DiligentLogo)`
    fill: ${AtlasWhite_100};
    max-width: 220px;
    /* max-height: 100%; */
    transform: none;
    width: 200px;
    margin-bottom: 1rem;
`

const alignCenterClss = css`
    text-align: center;
`

const customStyles = {
    overlay: {
        zIndex: 2000
    }
}

export default UnsupportedModal
