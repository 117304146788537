import { getEditPropertiesModal } from 'businesslayer/networkrequests/editPropertiesModal'
import { executeRequest } from 'businesslayer/request-executor'

export const geteditProperties = (documentId, committeeId, repeatAction) => {
    const request = getEditPropertiesModal(documentId, committeeId)

    const result = executeRequest(request, null, null, { repeatAction, isForeground: false })

    return result
}
