import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #fff;
`
type Props = {
    title?: JSX.Element
    className?: string
}

const CalendarIcon = ({ title, ...props }: Props) => (
    <Svg
        id="prefix__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 31.1 27.6"
        xmlSpace="preserve"
        {...props}
        width="1em"
        height="1em">
        <title>{title}</title>
        <style />
        <path
            className="prefix__st0"
            d="M29.4 0H1.7C.8 0 0 .8 0 1.7V26c0 .9.8 1.7 1.7 1.7h27.7c.9 0 1.7-.8 1.7-1.7V1.7c0-.9-.7-1.7-1.7-1.7zm.5 26c0 .2-.2.4-.4.4H1.7c-.2 0-.4-.2-.4-.4V8h28.6v18z"
            id="prefix__calendar_1_"
        />
        <path
            className="prefix__st0"
            d="M23.9 2.5h-2.4c-.5 0-1-.5-1-1v-3.7c0-.5.5-1 1-1h2.4c.6 0 1 .5 1 1v3.7c0 .5-.5 1-1 1zM9.2 2.5H6.9c-.5 0-1-.5-1-1v-3.7c0-.5.5-1 1-1h2.4c.6 0 1 .5 1 1v3.7c-.1.5-.5 1-1.1 1zM22.4 19h5.4v5.4h-5.4z"
        />
    </Svg>
)

export default CalendarIcon
