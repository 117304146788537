import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

//================ Redux ====================================
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../reducers/applicationReducer'

import selectors from '../../selectors/applicationSelectors'
import Reachability from './Reachability/Reachability'
import { translate } from './internationalization'
import { css, ClassNames } from '@emotion/react'
//================ Styles ===================================

const offlineStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: #d98f1d;
    color: #fff;
    z-index: 2002;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.6);
`
const screenLockStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000;
    z-index: 2001;
`

const focusCatcherStyle = css`
    z-index: 2000;
    top: 0;
    left: 0;
    position: absolute;
`

//#region Props
type Props = {
    actions: {
        loadPlatformInfo: (...args: any[]) => void
    }
    platform: string
}
//#endregion

//#region Implementation
class OfflineNotifier extends Component<Props> {
    state = {
        isOffline: false
    }

    private focusCatcher?: HTMLElement = undefined

    componentDidMount() {
        if (this.props && this.props.actions) {
            this.props.actions.loadPlatformInfo()
        }
    }

    onBlur = (_) => {
        setTimeout(() => {
            if (this.focusCatcher) {
                this.focusCatcher.focus()
            }
        }, 50)
    }

    renderOfflineMessage = (reason: 'api' | 'internet') => {
        setTimeout(() => {
            if (this.focusCatcher) {
                this.focusCatcher.focus()
            }
        }, 50)

        const message = determineErrorMessage(reason, this.props.platform)

        return (
            <ClassNames>
                {({ css }) => (
                    <React.Fragment>
                        <p
                            className={css`
                                ${offlineStyle}
                            `}>
                            {message}
                        </p>
                        <section
                            className={css`
                                ${screenLockStyle}
                            `}
                        />
                        <input
                            tabIndex={-1}
                            className={css`
                                ${focusCatcherStyle}
                            `}
                            onBlur={this.onBlur}
                            type="text"
                            ref={(c) => (this.focusCatcher = c ? c : undefined)}
                        />
                    </React.Fragment>
                )}
            </ClassNames>
        )
    }

    render() {
        if (process.env.NODE_ENV !== 'production') {
            return null
        }

        return (
            <Reachability polling={{ url: '/ping' }}>
                {({ status: minutesReachableStatus, reset }) =>
                    minutesReachableStatus === 'reachable' ? null : (
                        <Reachability
                            polling={{
                                url: 'https://ipv4.icanhazip.com/',
                                timeout: 2000,
                                retryCount: 0
                            }}
                            onChange={(status, previousStatus) => {
                                status === 'reachable' &&
                                    previousStatus === 'unreachable' &&
                                    reset()
                            }}>
                            {({ status: internetReachableStatus }) => {
                                return internetReachableStatus === 'reachable'
                                    ? this.renderOfflineMessage('internet')
                                    : this.renderOfflineMessage('api')
                            }}
                        </Reachability>
                    )
                }
            </Reachability>
        )
    }
}
//#endregion

//================ Export / Redux Bindings ==================

//Note: we listen to data changes just so dialog could auto resize itself
//in case more dates added and a need to have scroll is added.
const mapStateToProps = (state, _) => {
    return {
        platform: selectors.platform(state.applicationReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const { loadPlatformInfo } = bindActionCreators(actions, dispatch)
    return {
        actions: { loadPlatformInfo }
    }
}

const determineErrorMessage = (reason: 'api' | 'internet', platform: string) => {
    return reason === 'internet'
        ? translate('MINUTES_UNAVAILABLE')
        : platform === 'boardeffect'
        ? translate('OFFLINE_MESSAGE_BE')
        : translate('OFFLINE_MESSAGE_BOARDS')
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfflineNotifier))
