import useSWR from 'swr'
import { makeFetcher } from 'businesslayer/networkrequests/swr-util'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { Reviewer } from 'businesslayer/api/review'

export const useReviewsSWR = (
    minutesId,
    swrOptionsOverride?: ConfigInterface,
    fetcherOptionsOverride?: FetcherOptions
) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')
    const url = `committees/${committeeId}/minutes_documents/${minutesId}/reviews`

    const fetcherOptions = {
        shouldNormalize: true,
        fetcherCallback: normalizeReviewers,
        ...fetcherOptionsOverride
    }

    const fetcher = makeFetcher(fetcherOptions)
    return useSWR<Attendee[]>(url, fetcher, swrOptionsOverride)
}

/**
 * Update shape of reviewers
 * Give it the shape of the other users
 */
const normalizeReviewers = (reviewDetails: {
    reviews: Record<string, Reviewer>
    sort: string[]
}): any => {
    return !!reviewDetails && !!reviewDetails.reviews
        ? reviewObjectToArray(reviewDetails.reviews)
        : []
}
/**
 * Converts a review object where (key, value) = (reviewID, Reviewer details)
 * to an array of ReviewerDetails and flatten out the attributes.
 */
const reviewObjectToArray = (reviewObj: Record<string, Reviewer>) => {
    return Object.values(reviewObj).map((r) => ({
        id: String(r.id),
        ...r.attributes,
        userId: String(r.attributes.userId)
    }))
}
