import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #2379a0;
`

type Props = {
    title?: JSX.Element | string
    className?: string
}

export const ExportReportIcon = ({ title, className }: Props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}>
        <title>{title}</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.25 16.381V2.83427L8.45128 6.19435C8.18618 6.51262 7.71327 6.55572 7.395 6.29062C7.07673 6.02553 7.03362 5.55261 7.29872 5.23434L11.42 0.286382C11.4438 0.257464 11.4696 0.230354 11.4974 0.205289C11.6304 0.0851306 11.8066 0.0119629 12 0.0119629C12.2159 0.0119629 12.4105 0.103212 12.5474 0.249252C12.5586 0.261259 12.5695 0.273635 12.5799 0.286362L16.7013 5.23434C16.9664 5.55261 16.9233 6.02553 16.605 6.29062C16.2867 6.55572 15.8138 6.51262 15.5487 6.19435L12.75 2.83427V16.381C12.75 16.7952 12.4142 17.131 12 17.131C11.5858 17.131 11.25 16.7952 11.25 16.381ZM5.25 9.91673C4.83579 9.91673 4.5 10.2525 4.5 10.6667V23.2382C4.5 23.6524 4.83579 23.9882 5.25 23.9882H18.75C19.1642 23.9882 19.5 23.6524 19.5 23.2382V10.6667C19.5 10.2525 19.1642 9.91673 18.75 9.91673H15C14.5858 9.91673 14.25 10.2525 14.25 10.6667C14.25 11.0809 14.5858 11.4167 15 11.4167H18V22.4882H6V11.4167H9C9.41421 11.4167 9.75 11.0809 9.75 10.6667C9.75 10.2525 9.41421 9.91673 9 9.91673H5.25Z"
            fill="white"
        />
    </Svg>
)

export default ExportReportIcon
