import React, { FC } from 'react'

import { css } from '@emotion/react'
import ReactHintFactory from 'react-hint'

const ReactHint = ReactHintFactory(React)

export const ReactHintProvider = () => (
    <>
        {/* Default Tooltips */}
        <ReactHint autoPosition events delay={100} className={`${hintClassName} react-hint`} />

        {/* Menu Tooltips */}
        <ReactHint
            persist
            attribute="data-rh-menu"
            buttom
            events
            delay={100}
            className={`${hintMenuClass}`}
        />
    </>
)

/**
 * Provides a tooltip only if showHint is true
 */
export const ConditionalTooltip: FC<{ showHint: boolean; children: any }> = (props) => {
    const { showHint, children, ...rest } = props
    return showHint ? <span {...rest}>{children}</span> : children
}

const hintClassName = css`
    .react-hint__content {
        background-color: rgba(0, 0, 0, 0.7);
        font-size: 0.8em;
        padding: 5px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
    }
`
const hintMenuClass = css`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 0.8em;
    padding: 7px;
    margin: -12px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
    z-index: 9999;
    color: white;
`
