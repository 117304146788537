import React, { useState } from 'react'

//================ Components Material =======================

//#region Components
import i18n from 'i18next'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import { Body } from './typography/Typography'
import {
    Dialog,
    DialogActions,
    IconButton,
    NewDialogContent,
    NewDialogTitle,
    AddActionEditActionButton
} from './StyledComponents'
import { AtlasModal } from 'components/atlas-components/atlas-modal/atlas-modal'
import { AtlasButtonOld } from 'components/atlas-material/button'
import { DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { AtlasIcon } from 'web-components/atlas-icon'
import {
    AtlasButtonCss,
    StyledAtlasButtonOld
} from 'components/minutetaker/components/ActionItemEditDialog/ActionItemEditDialog'
import {
    ColorBaseDarkBlack,
    ColorBaseDarkRed,
    ColorBaseRichBlack,
    ColorBaseWhite,
    FontWeightLightBold,
    FontWeightRegular
} from 'assets/styles/variables'
const useStyles = makeStyles({
    dialog: {
        width: '440px',
        height: '244px'
    }
})

//#endregion

//#region Props

type Props = {
    title?: string
    message: string
    onCancel?: () => void
    onConfirm: (() => void) | null
    confirmLabel?: string
    cancelLabel?: string
    preserveFormatting?: boolean
    hideCancel?: boolean
    isAtlas?: boolean
    isActionRequired?: boolean
}

//#endregion

//#region Implementation

function ConfirmationDialog({
    message,
    isActionRequired,
    title,
    confirmLabel,
    cancelLabel,
    hideCancel,
    isAtlas,
    ...rest
}: Props) {
    const [isOpen, setIsOpen] = useState(true)
    const onCancel = () => {
        if (rest.onCancel) {
            rest.onCancel()
        }
        setIsOpen(false)
    }

    const onConfirm = () => {
        if (rest.onConfirm) {
            rest.onConfirm()
        }
        setIsOpen(false)
    }
    if (!title) {
        title = i18n.t('CONFIRMATION_TITLE')
    }

    if (!confirmLabel) {
        confirmLabel = i18n.t('CONFIRMATION_DEFAULT_BUTTON')
    }

    if (!cancelLabel) {
        cancelLabel = i18n.t('CANCEL')
    }
    const classes = useStyles()
    // TODO: We can remove old dialog buttons once fully committed to Atlas
    const dialogActions_OLD = [
        hideCancel ? null : (
            <AddActionEditActionButton
                disableFocusRipple
                disableRipple
                key="cancel-btn"
                onClick={onCancel}
                color="primary">
                {cancelLabel}
            </AddActionEditActionButton>
        ),
        isActionRequired ? (
            <StyledAtlasButtonOld key="confirm-btn" onClick={onConfirm} color="primary">
                {confirmLabel}
            </StyledAtlasButtonOld>
        ) : (
            <ClassNames>
                {({ css }) => (
                    <AddActionEditActionButton
                        className={css`
                            ${ConfirmButtonCss}
                        `}
                        disableFocusRipple
                        disableRipple
                        key="confirm-btn"
                        onClick={onConfirm}
                        color="primary">
                        {confirmLabel}
                    </AddActionEditActionButton>
                )}
            </ClassNames>
        )
    ]
    const renderDialogActions = (shouldHideCancel) => {
        const confirmationButton = (
            <AtlasButtonOld
                key="confirm-btn"
                onClick={onConfirm}
                dataTestId="ConfirmationModal_Confirm">
                {confirmLabel}
            </AtlasButtonOld>
        )
        const cancelButton = (
            <AtlasButtonOld
                key="cancel-btn"
                secondary
                onClick={onCancel}
                dataTestId="ConfirmationModal_Cancel">
                {cancelLabel}
            </AtlasButtonOld>
        )

        return shouldHideCancel ? [confirmationButton] : [cancelButton, confirmationButton]
    }
    const messageFormatted = rest.preserveFormatting ? (
        <Preformatted>{message}</Preformatted>
    ) : (
        message
    )

    const dialogActions = renderDialogActions(hideCancel)

    return !!isAtlas ? (
        <AtlasModal
            open={isOpen}
            maxWidth="xs"
            modalTitle={title}
            actions={dialogActions}
            onClose={onCancel}>
            <DialogContent>{message}</DialogContent>
        </AtlasModal>
    ) : (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    classes={{ paper: isActionRequired ? classes.dialog : null }}
                    maxWidth="sm"
                    className={css`
                        .MuiDialog-paperWidthSm {
                            max-width: 440px;
                        }
                    `}
                    open={isOpen}
                    scroll="paper"
                    onClose={onCancel}>
                    {isActionRequired ? (
                        <NewDialogTitle
                            className={css`
                                font-family: Source sans pro;
                                font-size: 22px;
                                font-weight: 600;
                            `}>
                            {title}
                            <IconButton
                                tabIndex={0}
                                disableFocusRipple
                                disableRipple
                                onClick={onCancel}
                                className={css`
                                    ${AtlasButtonCss}
                                `}>
                                <AtlasIcon
                                    name="close"
                                    size={24}
                                    data-testid="AtlasModal_CloseIcon"
                                />
                            </IconButton>
                        </NewDialogTitle>
                    ) : (
                        <NewDialogTitle
                            className={css`
                                ${DialogTitleCss}
                            `}>
                            {title}
                            <IconButton
                                tabIndex={0}
                                disableFocusRipple
                                disableRipple
                                className={css`
                                    ${AtlasButtonCss}
                                `}
                                onClick={onCancel}>
                                <AtlasIcon
                                    name="close"
                                    size={24}
                                    data-testid="AtlasModal_CloseIcon"
                                />
                            </IconButton>
                        </NewDialogTitle>
                    )}

                    <NewDialogContent>
                        <div
                            className={css`
                                ${dialogBodyCss}
                            `}>
                            <Body
                                className={
                                    isActionRequired
                                        ? css`
                                              font-family: 'Source Sans Pro';
                                              color: ${ColorBaseDarkBlack};
                                              font-size: 16px;
                                          `
                                        : css``
                                }
                                type="body1">
                                {messageFormatted}
                            </Body>
                        </div>
                    </NewDialogContent>

                    <DialogActions>{dialogActions_OLD}</DialogActions>
                </Dialog>
            )}
        </ClassNames>
    )
}

//#endregion

export default ConfirmationDialog

const dialogBodyCss = css`
    color: ${ColorBaseRichBlack};
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    span {
        font-family: 'Source Sans Pro';
        font-weight: ${FontWeightRegular};
        font-size: 16px;
        line-height: 20px;
    }
`

const Preformatted = styled('pre')`
    font: inherit;
    text-wrap: wrap;
`
const ConfirmButtonCss = css`
    background-color: ${ColorBaseDarkRed};
    border-radius: 2px;
    color: ${ColorBaseWhite};
    &:hover {
        background-color: ${ColorBaseDarkRed};
    }
`
const DialogTitleCss = css`
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-weight: ${FontWeightLightBold};
    line-height: 26px;
`
