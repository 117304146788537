import { translate } from 'components/shared/internationalization/Trans'
import { join } from 'rambdax'
import React from 'react'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import { Card, CardSubtitle, CardSummary, CardTitle, Section, StyledLink } from './Atoms'
import { Body } from 'components/shared/typography/Typography'
import {
    ColorBaseGreyDark,
    ColorBaseBlueGreyMedium,
    ColorBaseRed,
    ColorBaseRichBlack
} from 'assets/styles/variables'
import RightCaretIcon from 'assets/icons/RightCaretIcon'
import LeftCaretIcon from 'assets/icons/LeftCaretIcon'
import moment from 'moment'
import ReactDOM from 'react-dom'

import Truncate from 'react-truncate'
import { FormControlLabel } from '@material-ui/core'
import { Checkbox } from 'components/shared/StyledComponents'
import { addKeyPressHandler } from 'common/util/functions'
import { MinutesManager_BtnFocusCss } from 'components/layout/page-layout'

type ActionItemCardProps = {
    dateFormat?: string
    actionItem: ActionItem
    isSelected?: boolean
    onCompleteActionChecked: (actionItem: ActionItem) => void
}

const statusColorMap = {
    complete: {
        label: translate('ACTION_ITEM_COMPLETE'),
        color: ColorBaseRichBlack
    },
    incomplete: {
        label: translate('DUE_DATE'),
        color: ColorBaseBlueGreyMedium
    },
    overdue: {
        label: translate('OVERDUE_ACTION_LIST_LABEL'),
        color: ColorBaseRed
    }
}

export class ActionItemCard extends React.Component<ActionItemCardProps> {
    static defaultProps = {
        minutesItem: {
            attendees: []
        }
    }

    card?: HTMLElement = undefined

    componentDidMount() {
        if (this.card && this.props.isSelected) {
            const card = ReactDOM.findDOMNode(this.card)
            if (card instanceof HTMLElement) {
                card.focus()
            }
        }
    }

    renderStatusEditor = ({ actionItem }) => {
        const isComplete = actionItem.completionStatus === 'complete'
        const { dateFormat } = this.props

        return (
            <ClassNames>
                {({ css }) => (
                    <FormControlLabel
                        className={css`
                            width: 35%;
                            padding-bottom: 0px;
                            padding-left: 5px;
                        `}
                        id="grouplabel"
                        label={
                            <ActionItemStatus
                                actionItem={actionItem}
                                aria-labelledby="grouplabel"
                                dateFormat={dateFormat}
                            />
                        }
                        control={
                            <Checkbox
                                id="Checkbox_Field"
                                className={css`
                                    padding-top: 0px;
                                    padding-bottom: 0px;
                                `}
                                disableFocusRipple
                                disableRipple
                                aria-checked={isComplete}
                                checked={isComplete}
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.props.onCompleteActionChecked(actionItem)
                                }}
                                onKeyPress={(e) =>
                                    addKeyPressHandler(e)
                                        ? this.props.onCompleteActionChecked(actionItem)
                                        : null
                                }
                            />
                        }
                    />
                )}
            </ClassNames>
        )
    }

    renderCardTitle = ({ actionItem, isSelected }) => {
        const OpenClosedIcon = styled(isSelected ? LeftCaretIcon : RightCaretIcon)`
            height: 0.8rem;
        `
        const assignees =
            actionItem.assignees && actionItem.assignees.length > 0 ? (
                <Truncate>
                    {translate('ASSIGNED_TO')}:{' '}
                    {join(
                        ', ',
                        actionItem.assignees.map((row) => row.text)
                    )}
                </Truncate>
            ) : (
                translate('UNASSIGNED')
            )

        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            display: flex;
                            align-items: center;
                            align-items: center;
                            justify-content: space-between;
                            padding-left: 5px;
                            color: ${ColorBaseGreyDark};
                        `}>
                        <CardTitle
                            display="flex"
                            alignItems="center"
                            flex="1"
                            textDecoration="none">
                            <Separator style={{ marginLeft: '-33.2px' }} />
                            <div
                                className={css`
                                    display: flex;
                                    flex: 1;
                                    margin-left: 0.5rem;
                                    margin-bottom: -2px;
                                `}>
                                <Body
                                    type="body1"
                                    className={css`
                                        flex: 1;
                                    `}>
                                    {assignees}
                                </Body>
                            </div>

                            <button
                                className={css`
                                    padding-top: 4px;
                                    ${ExpandButtonStyle}
                                    ${MinutesManager_BtnFocusCss}
                                `}
                                aria-expanded={isSelected ? 'true' : 'false'}
                                tabIndex={0}>
                                <OpenClosedIcon />
                            </button>
                        </CardTitle>
                    </div>
                )}
            </ClassNames>
        )
    }

    renderCardSubtitle = ({ actionItem }) => {
        return (
            <ClassNames>
                {({ css }) => (
                    <CardSubtitle
                        my="0px"
                        ml="36px"
                        mr="1rem"
                        display="flex"
                        color={ColorBaseBlueGreyMedium}>
                        <p
                            className={css`
                                font-size: 1rem;
                                font-weight: normal;
                                margin-left: 0px;
                                margin-top: -28px;
                            `}>
                            {actionItem!.text}
                        </p>
                    </CardSubtitle>
                )}
            </ClassNames>
        )
    }

    render() {
        const { actionItem, isSelected } = this.props
        const urlToAction = isSelected
            ? { pathname: `/taker/${actionItem.minutesId}/actions`, state: { actionId: 0 } }
            : {
                  pathname: `/taker/${actionItem.minutesId}/actions/${actionItem.id}`,
                  state: { actionId: actionItem.id }
              }

        return (
            <Card
                ref={(card) => {
                    this.card = card
                }}>
                <CardSummary style={{ paddingBottom: '25px' }}>
                    {this.renderStatusEditor({ actionItem })}
                    <StyledLink to={urlToAction} tabIndex={-1}>
                        <Section alignItems="center">
                            {this.renderCardTitle({ actionItem, isSelected })}
                        </Section>
                    </StyledLink>
                </CardSummary>
                {this.renderCardSubtitle({
                    actionItem
                })}
            </Card>
        )
    }
}

const ActionItemStatus = ({ actionItem, dateFormat }) => {
    const isOverdue =
        actionItem.completionStatus === 'incomplete' &&
        moment(actionItem.dueDate).isBefore(new Date(), 'day')
    const status = isOverdue ? 'overdue' : actionItem.completionStatus

    const statusColor = statusColorMap[status].color

    const formattedDueDate = actionItem.dueDate
        ? moment(actionItem.dueDate).format(dateFormat)
        : translate('NO_DUE_DATE_ACTION')

    return (
        <Body
            type="body1"
            color={statusColor}
            className={css`
                width: 145px;
                display: flex;
                margin-bottom: -2px;
            `}
            //mr="2"
        >
            {translate('DUE')}: {formattedDueDate}
        </Body>
    )
}

export const Separator = styled('div')`
    height: 16px;
    margin: 0 0.25rem;
    border-right: 2px solid ${ColorBaseBlueGreyMedium};
`
const ExpandButtonStyle = css`
    background-color: white;
    border-width: 0px;
`
