import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
`
const DiligentIcon = () => (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.5 17.6344V16.1345H20.5V17.6344H3.5ZM3.5 12.7498V11.2499H20.5V12.7498H3.5ZM3.5 7.86521V6.36523H20.5V7.86521H3.5Z"
            fill="white"
        />
    </Svg>
)
export default DiligentIcon
