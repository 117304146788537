import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: inherit;
    stroke: inherit;
    height: 1.5em;
    width: 1.5em;
`

type Props = {
    title?: string
    className?: string
}

export const MenuActionManagerIcon = (props: Props) => (
    <Svg id="prefix__Layer_1" x={0} y={0} viewBox="0 0 24 23" xmlSpace="preserve" {...props}>
        <style>{'.prefix__st2rcgta{fill:#5aac46}'}</style>
        <path
            className="prefix__st2rcgta"
            d="M11.5 21.3c-5.5 0-9.9-4.4-9.9-9.9S6 1.5 11.5 1.5s9.9 4.4 9.9 9.9-4.4 9.9-9.9 9.9zm0-18.7c-4.9 0-8.8 4-8.8 8.8s4 8.8 8.8 8.8 8.8-4 8.8-8.8-3.9-8.8-8.8-8.8z"
        />
        <path
            className="prefix__st2rcgta"
            d="M8.3 18.7l7-8.4-2.4-.6 2.8-4.6-3.4-.4-4.1 6.8 2.5.6-2.4 6.6zm-.1.1"
        />
    </Svg>
)

export default MenuActionManagerIcon
