import { isEdge } from 'components/helpers/DomHelper'
import normalize from 'json-api-normalizer'
import emitter from 'common/util/events'
import { remove_non_ascii } from './functions'
import i18n from './internationalization'
import authenticationManager from 'businesslayer/authenticationManager'
import { removeSessionStorageItem } from 'businesslayer/minutesLocalStore'

const emptyHeaderName = 'unknown'

export const sanitizeResponse = (json) => {
    //Fixing some bad data case coming from server
    if (json?.included) {
        Object.keys(json.included).forEach((key) => {
            if (!json.included[key]) {
                delete json.included[key]
            }
        })
    }
}

export const addSortOrder = (normalized: NormalizedType, raw: RawType): NormalizedType => {
    if (!raw?.data || !Array.isArray(raw?.data)) {
        return normalized
    }

    const sortIds = raw.data.map((c) => c.id || -1).filter((c) => !isNaN(c) && c >= 0)

    if (sortIds.length) {
        normalized.sort = sortIds
    }

    return normalized
}

//Note: we use normalizer for json
export type RequestOptions = {
    shouldNormalize: boolean
}

export interface CustomError extends Error {}
const postProcessException = (exception) => {
    if (isEdge()) {
        const ua = window.navigator.userAgent
        const edge = ua.indexOf('Edge/')
        const edgeVersion = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)

        //That is how old Edge handles promise rejection...
        if (edgeVersion < 15 && exception.name === 'TypeMismatchError') {
            //
            emitter.emit('locked')
        }
    }
}
const parseResponse = async (response: Response): Promise<any> => {
    try {
        if (!response.ok) {
            throw new Error('Response not OK')
        }
        return await response.json()
    } catch (ex) {
        return processError(ex)
    }
}

const processError = (ex: Error): Promise<never> => {
    postProcessException(ex as CustomError)
    return Promise.reject(ex)
}

const makeNormalizeJSON = (shouldNormalize: boolean, shouldSanitize: boolean = true) => (
    json: JSONType
): JSONType => {
    if (shouldSanitize) {
        sanitizeResponse(json)
    }

    return shouldNormalize ? addSortOrder(normalize(json), json) : json
}
class FetchError extends Error {
    constructor(message?: string, public response?: any) {
        super(message) // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    }
}
const handleError = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        let url = new URL(response.url)
        const hasNotification = url.pathname.includes('/notification')
        if (response.status === 404) {
            emitter.emit('notFound')
            return
        }
        if (window.location.pathname === '/not-found') return
        if (response.status === 401 && !hasNotification) {
            const message = i18n.t('SESSION_EXPIRED_MESSAGE')
            removeSessionStorageItem('selectedStatusFilter')
            removeSessionStorageItem('selectedCommitteeFilter')
            emitter.emit('locked', { message })
        }
        let error
        try {
            error = new FetchError(response.statusText)
            error.response = response
        } catch (err) {
            console.error(err)
        }

        if (response.status === 422 && !hasNotification) {
            response.json().then((json) => {
                emitter.emit('error', json.error)
            })

            return Promise.reject(error)
        } else throw error
    }
}

const additionalHeaders = () => ({
    Authorization: authenticationManager.getApiToken(),
    SiteName: remove_non_ascii(authenticationManager.getSiteName()) || emptyHeaderName,
    CommitteeName: remove_non_ascii(authenticationManager.getCommitteeName()) || emptyHeaderName,
    'Accept-Language': i18n.language
})

const getStandardHeaders = () => ({
    accept: 'application/vnd.api+json',

    ...additionalHeaders()
})

const getRawPostHeaders = () => ({
    'content-type': 'application/json'
})

const getPostHeaders = () => ({
    'content-type': 'application/vnd.api+json',
    ...additionalHeaders()
})

const getOfficeHeaders = (isWord = true) => {
    const accept = isWord
        ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    return {
        accept,
        ...additionalHeaders()
    }
}

const getAdditionalPostHeader = () => ({
    'content-type': 'application/json',
    ...additionalHeaders()
})
export const headers = {
    default: getStandardHeaders,
    raw: getRawPostHeaders,
    post: getPostHeaders,
    office: getOfficeHeaders
}
export {
    parseResponse,
    processError,
    makeNormalizeJSON,
    getStandardHeaders,
    getOfficeHeaders,
    getAdditionalPostHeader,
    getPostHeaders,
    getRawPostHeaders,
    handleError
}
export interface NormalizedType {
    success?: boolean
    sort?: number[]
}

export interface RawType {
    data?: { id?: number }[]
}
interface JSONType {}
