import React from 'react'
import styled from '@emotion/styled'
import i18n from 'common/util/internationalization'

type Props = {
    className?: string
    title?: string
    signout: () => void
}
const Svg = styled('svg')`
    fill: #ffffff;
`

export const SignOutIcon = (props: Props) => (
    <Svg
        onClick={props.signout}
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        aria-label={i18n.t('SIGN_OUT')}>
        <title>{i18n.t('SIGN_OUT')}</title>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1V21C0.25 21.4142 0.585786 21.75 1 21.75H12C12.4142 21.75 12.75 21.4142 12.75 21V14.8462C12.75 14.4319 12.4142 14.0962 12 14.0962C11.5858 14.0962 11.25 14.4319 11.25 14.8462V20.25H1.75V1.75H11.25V7.15385C11.25 7.56806 11.5858 7.90385 12 7.90385C12.4142 7.90385 12.75 7.56806 12.75 7.15385V1C12.75 0.585786 12.4142 0.25 12 0.25H1ZM15.7838 6.42874C15.4683 6.16035 14.995 6.19854 14.7266 6.51404C14.4582 6.82954 14.4964 7.30287 14.8119 7.57126L17.9609 10.25H5C4.58579 10.25 4.25 10.5858 4.25 11C4.25 11.4142 4.58579 11.75 5 11.75H17.9609L14.8119 14.4287C14.4964 14.6971 14.4582 15.1705 14.7266 15.486C14.995 15.8015 15.4683 15.8397 15.7838 15.5713L20.486 11.5713C20.6535 11.4288 20.75 11.2199 20.75 11C20.75 10.7801 20.6535 10.5712 20.486 10.4287L15.7838 6.42874Z"
            fill="white"
        />
    </Svg>
)

export default SignOutIcon
