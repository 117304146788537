import { executeRequest } from 'businesslayer/request-executor'
import * as requests from 'businesslayer/networkrequests/sections'
import { translate } from 'components/shared/internationalization'
import blockingOperation from 'businesslayer/blockingOperation'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

export const getSections = (minutesId, repeatAction?) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    if (!currentCommitteeId) {
        //TODO: Redirect ot login?

        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }
    const committeeId = getSessionStorageItem('currentCommitteeId')

    //TODO: Implement sorting
    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_SECTIONS_ERROR', {
            minutesId: minutesId,
            committeeId: committeeId,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(requests.getSections(minutesId), null, onError, repeatAction)
}

export const saveSection = (section) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    if (!currentCommitteeId) {
        //TODO: Redirect ot login?

        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    if (!section.minutesId) {
        return Promise.reject(translate('SECTION_MISSING_MINUTE_ID_ERROR'))
    }

    const request = requests.saveSection(currentCommitteeId, section.minutesId, section)

    return executeRequest(request, null, null, {
        isForeground: false
    })
}

export const deleteSection = (sectionId, minutesId, repeatAction) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')

    if (!currentCommitteeId) {
        //TODO: Redirect ot login?
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onSuccess = undefined

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''

        return translate('DELETE_SECTION_ERROR', {
            committeeId: currentCommitteeId,
            minutesId: minutesId,
            sectionId: sectionId,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    const request = requests
        .deleteSection(currentCommitteeId, minutesId, sectionId)
        .then(() => sectionId)

    return executeRequest(request, onSuccess, onError, repeatAction)
}

export const updateSectionOrder = (sections, actionOnComplete) => {
    //Note: this is a workaround of background operation still update UI on complete
    //This is more exception to use
    const onComplete = () => {
        const store = blockingOperation.getStore()
        store.dispatch(actionOnComplete())
    }

    if (!sections || !sections.length) {
        onComplete()
        return
    }

    const onSuccess = () => {
        onComplete()
    }

    const onError = (errorObject) => {
        onComplete()
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('SAVE_ORDER_ERROR', {
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    const request = requests.saveSectionOrder(currentCommitteeId, sections)
    return executeRequest(request, onSuccess, onError, {
        isForeground: false
    })
}

export function updateSectionName({ sectionId, minutesId, name }) {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    if (!currentCommitteeId) {
        //TODO: Redirect ot login?

        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    if (!minutesId) {
        return Promise.reject(translate('SECTION_MISSING_MINUTE_ID_ERROR'))
    }

    const request = requests.updateSectionName(currentCommitteeId, minutesId, {
        sectionId,
        name
    })

    return executeRequest(request, null, null, {
        isForeground: false
    })
}

export function updateSectionContent({ sectionId, minutesId, content, reviewContent }) {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    if (!currentCommitteeId) {
        //TODO: Redirect ot login?

        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    if (!minutesId) {
        return Promise.reject(translate('SECTION_MISSING_MINUTE_ID_ERROR'))
    }

    const request = requests.updateSectionContent(currentCommitteeId, minutesId, {
        sectionId,
        content,
        reviewContent
    })

    return executeRequest(request, null, null, {
        isForeground: false
    })
}
