import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: ColorBaseGreyDark;
`

type Props = {
    title?: JSX.Element
    className?: string
}

const AddNotesIcon = ({ title, ...props }: Props) => (
    <Svg width="1em" height="1em" viewBox="0 0 22 20" {...props}>
        <title>{title}</title>
        <g strokeWidth={0.4} fillRule="evenodd">
            <path d="M14.89 15.048v3.408l-12.817.017-.017-17.176L14.89 1.28v2.218l.986-.986V1.28a.97.97 0 0 0-.968-.968H2.057a.97.97 0 0 0-.97.968v17.193c0 .535.435.97.97.97h12.85a.97.97 0 0 0 .968-.97v-4.411l-.986.986z" />
            <path d="M19.176 6.688l-1.92-1.92 1.247-1.25 1.921 1.922-1.248 1.248zm-8.246 8.246l-2.685.821.77-2.746 7.544-7.545 1.92 1.921-7.549 7.549zm10.54-9.842l-2.618-2.618a.494.494 0 0 0-.698 0l-9.928 9.928a.508.508 0 0 0-.126.216l-1.047 3.74a.495.495 0 0 0 .474.626.463.463 0 0 0 .145-.023l3.664-1.121a.486.486 0 0 0 .205-.122l9.928-9.929a.494.494 0 0 0 0-.697zM4.996 5.525h7.073v-.986H4.996zM4.996 9.116h3.559v-.985H4.996z" />
        </g>
    </Svg>
)

export default AddNotesIcon
