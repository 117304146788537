import { putJSON } from '../../common/util/request'

export interface MarkCompleted {
    body: string
    subject: string
}

export const markCompleted = (committeeId: string, minutesId: string, data: MarkCompleted) =>
    putJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/reviews/mark_completed`,
        JSON.stringify(data),
        {
            shouldNormalize: false
        }
    )
