import { AtlasMedium } from 'assets/styles/variables'
import { RadioClassKey, RadioProps } from '@material-ui/core/Radio'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiRadio: Partial<StyleRules<RadioClassKey, RadioProps>> = {
    root: {
        width: '20px',
        height: '20px',
        margin: '13px',
        padding: '0px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: AtlasMedium,
            '&$checked': {
                backgroundColor: 'transparent'
            }
        }
    },
    checked: {}
}
