import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { executeRequest } from 'businesslayer/request-executor'
import * as requests from 'businesslayer/networkrequests/contacts'
import { translate } from 'components/shared/internationalization'

export const getContacts = (_, repeatAction) => {
    //
    const onSuccess = (allContacts) => {
        transientStorageManager.allContacts = allContacts.contacts ? allContacts.contacts : null
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_CONTACTS_ERROR', {
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(requests.getContacts(), onSuccess, onError, repeatAction)
}

export const getCurrentUser = () => {
    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_CONTACTS_ERROR', {
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(requests.getCurrentUser(), null, onError)
}
