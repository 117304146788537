/**
 * User Agent Reference
 * iPhone:      "Mozilla/5.0 (iPhone; CPU iPhone OS 13_1_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.1 Mobile/15E148 Safari/604.1"
 * iPod Touch:  "Mozilla/5.0 (iPod; CPU iPhone OS 13_1_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.1 Mobile/15E148 Safari/604.1"
 * iPad Mini:   "Mozilla/5.0 (iPad; CPU iPhone OS 13_1_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.1 Mobile/15E148 Safari/604.1"
 * iPad:        "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.1 Safari/605.1.15"
 * Macintosh:   "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1 Safari/605.1.15"
 */

import { concat } from 'rambdax'

const RESTRICTED_DEVICES: DeviceType[] = [
    'ANDROID_PHONE',
    'IPAD_MINI',
    'IPHONE',
    'IPOD_TOUCH',
    'MOBILE',
    'WINDOWS_PHONE'
]
const SUPPORTED_TABLET_DEVICES: DeviceType[] = ['ANDROID_TABLET', 'IPAD']

/**
 * Returns whether a User is using any mobile device in general based on their userAgent
 * (just looks for "mobi" in the user agent)
 * @param userAgentString
 */
export const isMobile = (userAgentString: string) => {
    return get_is_mobile(userAgentString)
}

/**
 * Returns whether a User is using a supported tablet device based on their userAgent
 * @param userAgentString
 */
export const isSupportedTablet = (userAgentString: string) => {
    const device = getDevice(userAgentString)
    return SUPPORTED_TABLET_DEVICES.includes(device)
}

/**
 * Returns whether a User should be restricted from accessing minutes based on their userAgent
 * @param userAgentString
 * @param isTabletRestricted (default: false)
 */
export const isDeviceRestricted = (userAgentString: string, isTabletRestricted = false) => {
    const device = getDevice(userAgentString)
    // If tablet use is restricted we concat the tablet device list to the list of restricted devices
    const restricted_tablet_list = !!isTabletRestricted ? SUPPORTED_TABLET_DEVICES : []
    const restricted_device_list = concat(RESTRICTED_DEVICES)(restricted_tablet_list)
    // check if the user's device is included in the revised restricted list.
    return restricted_device_list.includes(device)
}

/**
 * Derives a device from the User's userAgent
 * @param userAgentString
 */
export const getDevice = (userAgentString: string): DeviceType => {
    // Most commonly used
    if (get_is_macintosh(userAgentString)) return 'MACINTOSH'
    if (get_is_windows(userAgentString)) return 'WINDOWS'
    if (get_is_ipad(userAgentString)) return 'IPAD'

    // Tablets
    if (get_is_android_tablet(userAgentString)) return 'ANDROID_TABLET'

    //Small devices
    if (get_is_ipad_mini(userAgentString)) return 'IPAD_MINI'
    if (get_is_iphone(userAgentString)) return 'IPHONE'
    if (get_is_android_phone(userAgentString)) return 'ANDROID_PHONE'
    if (get_is_ipod(userAgentString)) return 'IPOD_TOUCH'
    if (get_is_windows_phone(userAgentString)) return 'WINDOWS_PHONE'

    // Fallback mobile
    if (get_is_mobile(userAgentString)) return 'MOBILE'

    // Desktop
    if (get_is_linux(userAgentString)) return 'LINUX'
    if (get_is_chrome_os(userAgentString)) return 'CHROME_OS'

    // Catch all
    return 'UNKNOWN'
}

// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
// Getters for whether a user agent describes a certain device
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
const get_is_iphone = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_iphone = userAgentLowerCase.includes('iphone')
    const includes_ipad = userAgentLowerCase.includes('ipad')
    const includes_ipod = userAgentLowerCase.includes('ipod')
    return includes_iphone && !includes_ipad && !includes_ipod
}
const get_is_android_phone = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_mobi = userAgentLowerCase.includes('mobi')
    const includes_android = userAgentLowerCase.includes('android')
    return includes_android && includes_mobi
}
const get_is_windows_phone = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_windows_phone = userAgentLowerCase.includes('windows phone')
    return includes_windows_phone
}
const get_is_ipod = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_ipod = userAgentLowerCase.includes('ipod')
    return includes_ipod
}
const get_is_mobile = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_mobi = userAgentLowerCase.includes('mobi')
    return includes_mobi
}
const get_is_android_tablet = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_mobi = userAgentLowerCase.includes('mobi')
    const includes_android = userAgentLowerCase.includes('android')
    return includes_android && !includes_mobi
}
const get_is_ipad_mini = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_iphone = userAgentLowerCase.includes('iphone')
    const includes_ipad = userAgentLowerCase.includes('ipad')
    return includes_ipad && includes_iphone
}
const get_is_ipad = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_ipad = userAgentLowerCase.includes('ipad')
    return includes_ipad
}
const get_is_windows = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_windows = userAgentLowerCase.includes('windows')
    const includes_windows_phone = userAgentLowerCase.includes('windows phone')
    return includes_windows && !includes_windows_phone
}
const get_is_macintosh = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_macintosh = userAgentLowerCase.includes('macintosh')
    return includes_macintosh
}
const get_is_linux = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_linux_os = userAgentLowerCase.includes('linux')
    const includes_android = userAgentLowerCase.includes('android')
    return includes_linux_os && !includes_android
}
const get_is_chrome_os = (userAgent) => {
    const userAgentLowerCase = userAgent.toLowerCase()
    const includes_chrome_os = userAgentLowerCase.includes('cros')
    const includes_microsoft = userAgentLowerCase.includes('microsoft')
    return includes_chrome_os && !includes_microsoft
}

type DeviceType =
    | 'ANDROID'
    | 'ANDROID_PHONE'
    | 'ANDROID_TABLET'
    | 'CHROME_OS'
    | 'IPAD'
    | 'IPAD_MINI'
    | 'IPHONE'
    | 'IPOD_TOUCH'
    | 'LINUX'
    | 'MACINTOSH'
    | 'MOBILE'
    | 'WINDOWS'
    | 'WINDOWS_PHONE'
    | 'UNKNOWN'
