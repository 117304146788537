import useSWR from 'swr'
import { makeFetcher } from 'businesslayer/networkrequests/swr-util'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { PersonChip } from 'components/chip-input/types'

export const useCurrentReviewDetails = (
    minutesId,
    swrOptionsOverride?: ConfigInterface,
    fetcherOptionsOverride?: FetcherOptions
) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')
    const url = `committees/${committeeId}/minutes_documents/${minutesId}/get_reviews`

    const fetcherOptions = {
        shouldNormalize: false,
        fetcherCallback: normalizeRestrictedReviewers,
        ...fetcherOptionsOverride
    }

    const fetcher = makeFetcher(fetcherOptions)
    return useSWR<PersonChip[]>(url, fetcher, swrOptionsOverride)
}

const normalizeRestrictedReviewers = (data): PersonChip[] => data.data.reviews ?? []
