import React from 'react'
import { MuiThemeProvider, Grid, makeStyles, Box } from '@material-ui/core'
import { materialAtlasTheme } from 'material-atlas/theme'
import GlobalProgressModal from 'components/global-progress-modal/global-progress-modal'
import { PageLayout } from 'components/layout/page-layout'
import MinutesReportContainer from 'components/minutes-report/minutes-report-container'
import MinutesReportDetailsContainer from 'components/minutes-report-details/minutes-report-details-container'
import i18n from 'common/util/internationalization'
import { useMinutesManager } from 'pages/minutes-manager-page/use-minutes-manager'
import NotFound from 'pages/NotFound'
import MinuteTakerHeader from 'components/minutetaker/MinuteTakerHeader'

/**
 * Minutes Attendance Report Page
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        minWidth: '96%'
    },

    headerCenterLogo: {
        flex: '1 0 0',
        color: 'var(--core-color-legacy-white, #fff)',
        textAlign: 'center',
        fontFamily: 'source sans pro',
        font: ' 600 18px/24px Source Sans Pro'
    }
}))
export const MinutesAttendanceReportPage = () => {
    const classes = useStyles()
    const { canAddMinutes } = useMinutesManager()

    if (!canAddMinutes) {
        return <NotFound />
    }
    return (
        <MuiThemeProvider theme={materialAtlasTheme}>
            <>
                <PageLayout>
                    <MinuteTakerHeader title={i18n.t('ATTENDANCE_REPORTING')} />
                    <Box className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={4} lg={3}>
                                <MinutesReportContainer />
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} lg={9}>
                                <MinutesReportDetailsContainer />
                            </Grid>
                        </Grid>
                    </Box>
                </PageLayout>
                <GlobalProgressModal />
            </>
        </MuiThemeProvider>
    )
}
