import React from 'react'
import styled from '@emotion/styled'
import { ActionItemCard } from '../ActionItemCard'
import { translate } from 'components/shared/internationalization'

import { EmptyList } from 'components/shared/EmptyList'
import { pathOr } from 'rambdax'

type Props = {
    dateFormat?: string
    actionItems: ActionItem[]
    selectedActionItem?: ActionItem
    onCompleteActionChecked: (actionItem: ActionItem) => void
}

export const ActionSummaryList = ({
    actionItems = [],
    selectedActionItem,
    dateFormat,
    onCompleteActionChecked,
    ...rest
}: Props) => {
    if (actionItems.length === 0) {
        return <EmptyList message={translate('NO_MINUTES_BOARDS')} />
    }

    const selectedActionItemId = pathOr('-1', ['id'], selectedActionItem)

    return (
        <React.Fragment>
            {actionItems.map((actionItem) => {
                const isSelected = +actionItem.id === +selectedActionItemId
                const opacity = selectedActionItem ? (isSelected ? '1' : '.5') : '1'

                return (
                    <ActionItemsCardWrapper
                        key={actionItem.id}
                        opacity={opacity}
                        isExpanded={!!selectedActionItemId}>
                        <ActionItemCard
                            dateFormat={dateFormat}
                            actionItem={actionItem}
                            isSelected={isSelected}
                            onCompleteActionChecked={onCompleteActionChecked}
                            {...rest}
                        />
                    </ActionItemsCardWrapper>
                )
            })}
        </React.Fragment>
    )
}

const ActionItemsCardWrapper = styled('div')<{ opacity?: string; isExpanded?: boolean }>`
    margin: 10px auto;
    background-color: #fff;
    border-radius: 2px;
    opacity: ${({ opacity }) => opacity};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
    max-width: ${({ isExpanded }) => (isExpanded ? 865 : 1200)}px;
`

export default ActionSummaryList
