import React, { FC } from 'react'

type Props = {
    name: string
    size?: number
    color?: string
    onClick?: any
    slot?: any
    className?: any
    'data-testid'?: string
}

export const AtlasIcon: FC<Props> = ({ className, ...props }) => {
    return <atl-icon class={className} {...props} />
}
