import React, { FC } from 'react'

import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'

import moment from 'moment-timezone'
import authenticationManager from 'businesslayer/authenticationManager'
import i18n from 'i18next'
import { isSafari } from 'components/helpers/DomHelper'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { pathOr } from 'rambdax'
import { css, ClassNames } from '@emotion/react'
import { countlyEvent } from 'businesslayer/Countly'

type ExportPopoverProps = {
    onClosePopover: (closeReason: string, event?: any) => void
    isOpen: boolean
    anchorElement: any
    closeReason?: string
    minutesItem: MinutesRaw | Minutes
    onExport: (currentMinuteItem: any) => void
    onExportMinutesOnly: (currentMinuteItem: any) => void
    onExportActionsOnly: (currentMinuteItem: any) => void
    onConsolidatedActions: () => void
    whoIsUsingMe?: WhosIsUsingMeType
}

export const ExportPopover: FC<ExportPopoverProps> = ({
    minutesItem,
    isOpen,
    onClosePopover,
    onExport,
    onExportMinutesOnly,
    onExportActionsOnly,
    onConsolidatedActions,
    anchorElement,
    whoIsUsingMe
}) => {
    const anchorOrigin: any = { horizontal: 'left', vertical: 'bottom' }
    const targetOrigin: any = { horizontal: 'left', vertical: 'top' }

    const minutesAndActionsMenuContent = getMinutesAndActionsMenuContent(
        minutesItem,
        i18n.t('EXPORT_POPOVER_MINUTES_AND_ACTIONS'),
        false,
        false,
        false,
        onClosePopover
    )

    const minutesMenuContent = getMinutesAndActionsMenuContent(
        minutesItem,
        i18n.t('EXPORT_POPOVER_MINUTES_ONLY'),
        true,
        false,
        false,
        onClosePopover
    )

    const actionsMenuContent = getMinutesAndActionsMenuContent(
        minutesItem,
        i18n.t('EXPORT_POPOVER_ACTIONS_ONLY'),
        false,
        true,
        false,
        onClosePopover
    )

    const minutesConsolidatedActions = getMinutesAndActionsMenuContent(
        minutesItem,
        i18n.t('EXPORT_CONSOLIDATED_ACTIONS'),
        false,
        true,
        true,
        onClosePopover
    )

    function handleClose(cb?: (minutesItem: any) => void) {
        onClosePopover('export')
        if (cb) cb(minutesItem)
    }

    function handleCountlyExportOptions(
        whoIsUsingMe: WhosIsUsingMeType | undefined,
        exportOption: ExportOptionType
    ) {
        if (whoIsUsingMe === 'MINUTES_MANAGER') {
            countlyEvent('Mgr_ExportOptions', {
                exportOption: exportOption
            })
        }
    }

    const renderMenuItems = () => {
        const documentStatus = getDocumentStatus(minutesItem)

        // When Document is in review
        if (documentStatus === 'in_review') {
            const disabledTooltip = i18n.t('UNAVAILABLE_IN_STATUS')
            return [
                <></>,
                <span
                    title={disabledTooltip}
                    className="dropDownFocus"
                    data-rh-menu-at="bottom"
                    key="exportMinutesOnly_rh">
                    <MenuItem disabled key="exportMinutesOnly">
                        {minutesMenuContent}
                    </MenuItem>
                </span>,
                <MenuItem
                    key="exportActionsOnly"
                    className="dropDownFocus"
                    onClick={() => {
                        handleCountlyExportOptions(whoIsUsingMe, 'actionsOnly')
                        handleClose(onExportActionsOnly)
                    }}>
                    {actionsMenuContent}
                </MenuItem>,

                <span
                    title={disabledTooltip}
                    className="dropDownFocus"
                    data-rh-menu-at="bottom"
                    key="exportMinutesWithActions_rh">
                    <MenuItem disabled key="exportMinutesWithActions">
                        {minutesAndActionsMenuContent}
                    </MenuItem>
                </span>,
                <MenuItem
                    key="consolidatedActions"
                    className="dropDownFocus"
                    onClick={() => {
                        handleCountlyExportOptions(whoIsUsingMe, 'consolidatedActions')
                        handleClose(onConsolidatedActions)
                    }}>
                    {minutesConsolidatedActions}
                </MenuItem>
            ]
        }

        return [
            <MenuItem
                key="exportMinutesOnly"
                className="dropDownFocus"
                onClick={() => {
                    handleCountlyExportOptions(whoIsUsingMe, 'minutesOnly')
                    handleClose(onExportMinutesOnly)
                }}>
                {minutesMenuContent}
            </MenuItem>,
            <MenuItem
                key="exportActionsOnly"
                className="dropDownFocus"
                onClick={() => {
                    handleCountlyExportOptions(whoIsUsingMe, 'actionsOnly')
                    handleClose(onExportActionsOnly)
                }}>
                {actionsMenuContent}
            </MenuItem>,
            <MenuItem
                key="exportMinutesWithActions"
                className="dropDownFocus"
                onClick={() => {
                    handleCountlyExportOptions(whoIsUsingMe, 'minutesWithActions')
                    handleClose(onExport)
                }}>
                {minutesAndActionsMenuContent}
            </MenuItem>,
            <MenuItem
                key="consolidatedActions"
                className="dropDownFocus"
                onClick={() => {
                    handleCountlyExportOptions(whoIsUsingMe, 'consolidatedActions')
                    handleClose(onConsolidatedActions)
                }}>
                {minutesConsolidatedActions}
            </MenuItem>
        ]
    }

    return (
        <Menu
            open={isOpen}
            anchorEl={anchorElement}
            anchorOrigin={anchorOrigin}
            transformOrigin={targetOrigin}
            onClose={onClosePopover}
            getContentAnchorEl={null}
            disableAutoFocusItem={true}>
            {renderMenuItems()}
        </Menu>
    )
}

const getMinutesAndActionsMenuContent = (
    currentMinuteItem: object,
    regularWording: string,
    minutesOnly: boolean,
    actionsOnly: boolean,
    isConsolidatedActions: boolean,
    onRequestCloseExportPopover
) => {
    if (!currentMinuteItem) {
        return regularWording
    }

    //Safari cannot open files downloaded form API
    //So we use some nasty known workaround
    // TODO: We might not need this anymore. Safari seems
    // to have fixed this issue but more testing is required.
    return isSafari()
        ? renderSafariExportText(
              currentMinuteItem,
              regularWording,
              minutesOnly,
              actionsOnly,
              isConsolidatedActions,
              onRequestCloseExportPopover
          )
        : regularWording
}

const renderSafariExportText = (
    currentMinuteItem: object,
    regularWording: string,
    minutesOnly: boolean,
    actionsOnly: boolean,
    isConsolidatedActions: boolean,
    onRequestCloseExportPopover
): JSX.Element => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    const committeeId: string = pathOr(
        currentCommitteeId,
        ['relationships', 'committee', 'data', 'id'],
        currentMinuteItem
    )
    const minutesId: string = pathOr('-1', 'id', currentMinuteItem)

    const url = actionsOnly
        ? getActionsExportUrl(committeeId, minutesId)
        : getMinutesExportUrl(committeeId, minutesId, minutesOnly)

    // Clicking consolidated actions opens a modal not a download
    // so we don't need the link hack for that option.
    return isConsolidatedActions ? (
        <ClassNames>
            {({ css }) => (
                <div
                    className={css`
                        ${safariExportLinkStyle}
                    `}
                    onClick={onRequestCloseExportPopover}>
                    {regularWording}
                </div>
            )}
        </ClassNames>
    ) : (
        <ClassNames>
            {({ css }) => (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css`
                        ${safariExportLinkStyle}
                    `}
                    onClick={onRequestCloseExportPopover}>
                    {regularWording}
                </a>
            )}
        </ClassNames>
    )
}

const getMinutesExportUrl = (committeeId: string, minutesId: string, minutesOnly: boolean) => {
    const doc = `/committees/${committeeId}/minutes_documents/${minutesId}/export`

    const timeZone = encodeURIComponent(moment.tz.guess())
    const rawToken = authenticationManager.getRawToken()
    const url = `/api${doc}?session_token=${rawToken}&minutes_only=${minutesOnly}&timezone=${timeZone}&include_page_numbers=true`
    return url
}

const getActionsExportUrl = (committeeId: string, minutesId: string) => {
    //Default
    const sortField = { name: 'section', ascending: true }

    const doc = `/committees/${committeeId}/minutes_documents/${minutesId}/action_items/export`

    const timeZone = encodeURIComponent(moment.tz.guess())
    const rawToken = authenticationManager.getRawToken()
    const url = `/api${doc}?session_token=${rawToken}&timezone=${timeZone}&sort_name=${
        sortField.name
    }&sort_direction=${sortField.ascending ? 'asc' : 'desc'}`
    return url
}

const getDocumentStatus = (minutesItem: Minutes | MinutesRaw): DocumentStatusKey => {
    // If type Minutes
    const MinutesStatus: DocumentStatusKey | undefined = pathOr(
        undefined,
        ['attributes', 'status'],
        minutesItem
    )
    // If type MinutesRaw
    const MinutesRawStatus: DocumentStatusKey | undefined = pathOr(
        undefined,
        ['status'],
        minutesItem
    )

    return MinutesStatus || MinutesRawStatus || ''
}

const safariExportLinkStyle = css`
    text-decoration: none;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    color: #2a455a;
`

export type WhosIsUsingMeType = 'MINUTES_MANAGER' | 'MINUTES_TAKER'
