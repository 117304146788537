import { atlasTextBody } from 'components/atlas-components/typography'
import { MenuItemClassKey, MenuItemProps } from '@material-ui/core/MenuItem'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiMenuItem: Partial<StyleRules<MenuItemClassKey, MenuItemProps>> = {
    root: {
        height: '40px',
        minHeight: '40px',
        padding: '0 12px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...atlasTextBody
    }
}
