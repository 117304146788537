import React, { FC, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import {
    AddActionEditActionButton,
    Dialog,
    DialogContent,
    NewDialogTitle
} from 'components/shared/StyledComponents'
import { DialogActions, IconButton, makeStyles } from '@material-ui/core'
import { ColorBaseDarkRed, ColorBaseSkyBlue } from 'assets/styles/variables'
import i18n from 'i18next'
import { AtlasIcon } from 'web-components/atlas-icon'
import selectors from '../../../../selectors/applicationSelectors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import StepperComponent from './steps/StepperComponent'
import { actions } from 'reducers/minuteTakerReducer'
import { RootState } from 'reducers'
import useInvitationView from './useInvitationView'
import { StyledAtlasButtonOld } from '../ActionItemEditDialog/ActionItemEditDialog'
import { InvitationViewProps } from './types'

const useStyles = makeStyles(() => ({
    root: {
        fontFamily: 'Source Sans Pro',
        height: '40px',
        width: '40px',
        '&:focus': {
            border: `2px solid ${ColorBaseSkyBlue}`,
            borderRadius: '4px'
        }
    },
    closeButton: {
        padding: 0,
        float: 'right',
        display: 'flex',
        gap: '10px'
    },
    label: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '16px',
        marginBottom: '8px',
        display: 'inline-block'
    },
    DialogActions: {
        fontFamily: 'Source Sans Pro',
        justifyContent: 'normal',
        display: 'unset',
        padding: '20px 24px 10px',
        height: '62px'
    },
    DialogActions1: {
        fontFamily: 'Source Sans Pro',
        justifyContent: 'normal',
        display: 'unset',
        padding: '20px 39px 10px',
        height: '62px'
    },
    button: {
        fontFamily: 'Source Sans Pro',
        textTransform: 'capitalize',
        height: '40px',
        padding: ' 8px 12px 8px 12px',
        borderRadius: '2px',
        border: '1px solid  #455D82',
        gap: '8px',
        color: '#455D82'
    }
}))

const InvitationView: FC<InvitationViewProps> = (props) => {
    const classes = useStyles()
    const { isVisible, minuteTakerState, dataState } = props
    const {
        activeStep,
        handleClose,
        getStepContent,
        handlePrevClick,
        handleSubmit,
        handleNextClick
    } = useInvitationView(props)
    const submitMinutesPropertiesForm = useCallback(() => {
        const form = document.getElementById('dueDatesformBody')

        if (form) form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
    }, [])
    const handleClick = useCallback(() => {
        submitMinutesPropertiesForm()
    }, [submitMinutesPropertiesForm])
    const disableSend = useMemo(() => {
        const { cancelled_reviews, edited_reviews, new_invitees } = minuteTakerState.reviewers
        return !(cancelled_reviews.length || edited_reviews.length || new_invitees.length)
    }, [minuteTakerState.reviewers])

    const showAlt = useMemo(() => {
        const recipients = minuteTakerState.reviewers.recipients
        const nonGeneralReviewers = recipients.filter((it) => !it.is_general)
        return (
            dataState?.minutesSections.length > 4 &&
            nonGeneralReviewers.length > 0 &&
            activeStep !== 2
        )
    }, [dataState, minuteTakerState, activeStep])

    const MemoisedActions: JSX.Element = useMemo(
        () => (
            <DialogActions className={showAlt ? classes.DialogActions1 : classes.DialogActions}>
                {activeStep !== 0 && (
                    <AddActionEditActionButton
                        data-testid="ReviewInvitationModal_BackBtn"
                        className={classes.button}
                        disableFocusRipple
                        disableRipple
                        onClick={handlePrevClick}>
                        {i18n.t('BACK')}
                    </AddActionEditActionButton>
                )}
                <div className={classes.closeButton}>
                    {!(disableSend && activeStep === 2) && (
                        <AddActionEditActionButton
                            data-testid="ReviewInvitationModal_CancelBtn"
                            disableFocusRipple
                            disableRipple
                            onClick={handleClose}>
                            {i18n.t('CANCEL')}
                        </AddActionEditActionButton>
                    )}

                    {activeStep === 2 ? (
                        <StyledAtlasButtonOld
                            data-testid="ReviewInvitationModal_SendBtn"
                            onClick={disableSend ? handleClose : handleSubmit}>
                            {disableSend ? i18n.t('CLOSE') : i18n.t('REVIEWER_BUTTON_SEND')}
                        </StyledAtlasButtonOld>
                    ) : (
                        <StyledAtlasButtonOld
                            data-testid="ReviewInvitationModal_NextBtn"
                            disableFocusRipple
                            disableRipple
                            disabled={false}
                            onClick={activeStep ? handleClick : handleNextClick}>
                            {i18n.t('NEXT')}
                        </StyledAtlasButtonOld>
                    )}
                </div>
            </DialogActions>
        ),
        [
            activeStep,
            handleNextClick,
            handleClose,
            handlePrevClick,
            handleSubmit,
            classes,
            handleClick,
            disableSend,
            showAlt
        ]
    )
    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    className={css`
                        .MuiPaper-root {
                            max-height: 100%;
                            padding-bottom: 10px;
                            max-width: 1000px;
                            overflow-y: auto;
                           
                        }
                    `}
                    data-testid="ReviewInvitationModal"
                    scroll="paper"
                    aria-labelledby={i18n.t('SEND_INVITATION_ARIA_LABELLEDBY')}
                    fullWidth={true}
                    maxWidth="md"
                    open={isVisible}
                    onClose={handleClose}>
                    <div>
                        <NewDialogTitle>
                            <StyledTitle id={i18n.t('SEND_INVITATION_ARIA_LABELLEDBY')}>
                                {i18n.t('REVIEWERS_CAPTION')}
                            </StyledTitle>
                            <IconButton
                                tabIndex={0}
                                disableFocusRipple
                                disableRipple
                                onClick={handleClose}
                                classes={{ root: classes.root }}>
                                <AtlasIcon
                                    name="close"
                                    size={24}
                                    data-testid="AtlasModal_CloseIcon"
                                />
                            </IconButton>
                        </NewDialogTitle>
                    </div>
                    <DialogContent>
                        <StepperComponent activeStep={activeStep} />
                        {getStepContent(activeStep)}
                    </DialogContent>
                    {MemoisedActions}
                </Dialog>
            )}
        </ClassNames>
    )
}
const StyledTitle = styled('div')`
    display: flex;
    align-items: center;
    color: black;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
`

export const labelStyle = css`
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
    display: inline-block;
`

export const errorMsgStyle = css`
    p {
        font-family: 'Source Sans Pro';
        font-size: 13px;
        font-weight: 400;
        margin-left: 0px;
        margin-right: 0px;
    }
    .MuiFormHelperText-root {
        color: ${ColorBaseDarkRed};
    }
`
const mapStateToProps = (state: RootState, _) => {
    return {
        platform: selectors.platform(state.applicationReducer),
        minuteTakerState: state.minuteTakerReducer.controlState,
        dataState: state.minuteTakerReducer.dataState
    }
}
export default withRouter(
    connect(mapStateToProps, {
        updateReviewers: actions.updateReviewers
    })(InvitationView)
)
