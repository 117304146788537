import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ColorBaseGreyLight, ColorBaseBlueMedium, ColorBaseGreyDark } from 'assets/styles/variables'
import CalendarIcon from 'assets/icons/CalendarIcon'
import { SectionIcon as NewSectionIcon } from 'assets/icons/SectionIcon'
import { AttendiesIcon } from 'assets/icons/AttendiesIcon'
import AddNotesIcon from 'assets/icons/AddNotesIcon'
import SendNotificationIcon from 'assets/icons/SendNotificationIcon'
import { isFirefox, isEdge } from 'components/helpers/DomHelper'

export const Content = styled('div')`
    min-width: 325px;
    width: 325px;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px 0px;
`

export const centerClass = css`
    display: flex;
    align-items: center;
`

export const iconWrapperClass = css`
    min-width: 35px;
    /* padding: 5px; */
    display: flex;
    ${centerClass};
    justify-content: center;
`

export const bodyClass = css`
    min-height: 55px;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid ${ColorBaseGreyLight};
`

const determineIconColor = (isActive?: boolean) =>
    !!isActive ? ColorBaseBlueMedium : ColorBaseGreyDark

export const StyledIconWrapper = styled('div')`
    ${iconWrapperClass};
    ${centerClass};
`

export const StyledAttendiesIcon = styled(AttendiesIcon)<{ isActive?: boolean }>`
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    fill: ${({ isActive }) => determineIconColor(isActive)};
`

export const StyledSendNotificationIcon = styled(SendNotificationIcon)<{ isActive?: boolean }>`
    fill: ${({ isActive }) => determineIconColor(isActive)};
`
export const StyledInviteNotificationIcon = styled(SendNotificationIcon)<{
    isActive?: boolean
    fill?: string
}>`
    fill: ${({ fill }) => fill ?? '#ffffff'};
`

export const StyledCalendarIcon = styled(CalendarIcon)<{ color: string }>`
    fill: ${({ color }) => color};
`

export const StyledNewSectionIcon = styled(NewSectionIcon)<{ isActive?: boolean }>`
    fill: ${({ isActive }) => determineIconColor(isActive)};
`

export const StyledAddNotesIcon = styled(AddNotesIcon)<{ isActive?: boolean }>`
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    fill: ${({ isActive }) => determineIconColor(isActive)};
    stroke: ${({ isActive }) => determineIconColor(isActive)};
`

export const DetailsSection = styled('div')`
    ${centerClass};
    ${bodyClass};
`

export const WordWrapContainer = styled('p')`
    white-space: pre-line;

    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: ${isFirefox() || isEdge() ? 'break-all' : 'break-word'};
    word-break: ${isFirefox() || isEdge() ? 'break-all' : 'break-word'};

    -ms-hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
    hyphens: none;
`

export const Italic = styled('span')`
    font-style: italic;
`
