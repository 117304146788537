import { PaletteOptions } from '@material-ui/core/styles/createPalette'

/**
 * MUI Palette cannot take css variables "var(--variable)" as a color.
 * Since Atlas Core does not yet export the actual hex values we have
 * to hard code them here for now.
 */
export const AtlasStatusError = '#af292e'
export const AtlasPrimary = '#2f3b4d'
export const AtlasBlackNear = '#1e1e1e'
export const AtlasGreyDark = '#676767'
export const AtlasGreyMedium = '#949494'

export const palette: PaletteOptions = {
    type: 'light',
    primary: { light: AtlasPrimary, main: AtlasPrimary, dark: AtlasPrimary },
    secondary: { light: AtlasPrimary, main: AtlasPrimary, dark: AtlasPrimary },
    error: { light: AtlasStatusError, main: AtlasStatusError, dark: AtlasStatusError },
    text: {
        primary: AtlasBlackNear,
        secondary: AtlasGreyDark,
        disabled: AtlasGreyMedium,
        hint: AtlasGreyDark
    }
}
