import React, { useState, FC, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import { translate } from 'components/shared/internationalization/Trans'
import i18n from 'i18next'
import { Reviewer } from 'businesslayer/api/review'
import SidebarCard from './SidebarCard'
import { TrackChangesSidebar } from './TrackChangesSidebar'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { formatDate } from 'common/formatters/date'
import { AtlasIcon } from 'web-components/atlas-icon'
import { countlyEvent } from 'businesslayer/Countly'
import InvitationView from '../InvitationView'
import { useCommitteesState } from 'components/contexts/committee-context'
import { Box, Button, Divider, Tooltip, Typography, makeStyles } from '@material-ui/core'
import PencilIcon from 'assets/icons/PencilIcon'
import { StyledInviteNotificationIcon } from '../ActionItemDetailsCard/Atoms'
import { ColorBaseWhite } from 'assets/styles/variables'

type Props = {
    role: UserRole
    hasEdits?: boolean
    reviewers: Reviewer[]
    minutesId: string
    onInvitationSent: () => void
    minutesTitle: string
    minutesSections: any[]
    reviewersList: PersonChip[]
    setLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#000000',
        height: '20px'
    },
    subtitleContainer: {
        fontFamily: 'Source Sans Pro',
        width: '98%'
    },
    subtitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        width: '15vw',
        color: '#000000',
        wordWrap: 'break-word',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    mainSubTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
        width: '100%',
        margin: '8px 0',
        color: '#252C44'
    },
    completed: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'right',
        color: '#05704B',
        gap: '10px',
        width: '100%'
    },
    notcompleted: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'right',
        color: '#676767',
        gap: '10px',
        width: '100%'
    },
    completedStatus: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#05704B',
        width: '100%'
    },
    noncompletedStatus: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#676767',
        width: '100%'
    },
    button: {
        fontFamily: 'Source Sans Pro',
        textTransform: 'none',
        height: '40px',
        padding: ' 8px 12px 8px 12px',
        borderRadius: '2px',
        border: '1px solid  #455D82',
        gap: '8px',
        color: '#455D82',
        margin: '10px 0 0',
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px #0774EE`,
            borderRadius: '2px'
        }
    },
    buttonAlt: {
        textWrap: 'noWrap',
        fontFamily: 'Source Sans Pro',
        textTransform: 'none',
        height: '40px',
        padding: ' 8px 12px 8px 12px',
        borderRadius: '2px',
        border: '1px solid  #455D82',
        backgroundColor: '#455D82 !important',
        color: '#ffffff',
        marginTop: '12px',
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px #0774EE`,
            borderRadius: '2px'
        },
        '& .MuiButton-label': {
            gap: '8px'
        }
    },
    reviewerListItem: {
        paddingTop: '0px !important',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'right',
        color: '#676767',
        gap: '10px',
        width: '100%'
    },
    titlePadding: {
        paddingTop: '0px !important',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        padding: '0 4px'
    },
    listTitle: {
        paddingTop: '0px !important',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        padding: '0 4px'
    },
    btnText: {
        fontFamily: 'Source Sans Pro',
        padding: '0 10px',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'center'
    },
    divider: {
        margin: '16px 0',
        backgroundColor: '#e6e6e6'
    },
    bottomdivider: {
        margin: '16px 0 0px',
        backgroundColor: '#e6e6e6'
    }
}))

const MinutesReviewSidebar: FC<Props> = ({
    role,
    reviewers,
    reviewersList,
    onInvitationSent,
    hasEdits,
    minutesTitle,
    minutesId,
    minutesSections,
    setLoaded
}) => {
    const [expandedSection, setExpandedSection] = useState<string[]>(['changes_section'])
    const [isInviting, setIsInviting] = useState(false)
    const { currentCommittee } = useCommitteesState()

    const toggle = true

    const handleSectionClick = useCallback(
        (sectionKey: string) => {
            if (expandedSection.includes(sectionKey)) {
                setExpandedSection(expandedSection.filter((s) => s !== sectionKey))
            } else {
                toggle
                    ? setExpandedSection([sectionKey])
                    : setExpandedSection([...expandedSection, sectionKey])
            }
        },
        [expandedSection, setExpandedSection, toggle]
    )

    const toggleInviting = useCallback(() => {
        if (!isInviting) {
            countlyEvent('ReviewMin_InviteReviewers')
        }
        setIsInviting(!isInviting)
    }, [isInviting])

    const handleInvitationSubmitSuccess = useCallback(() => {
        toggleInviting()
        onInvitationSent()
    }, [toggleInviting, onInvitationSent])

    const { dateFormatInput } = transientStorageManager

    return (
        <ClassNames>
            {({ css }) => (
                <div
                    className={css`
                        ${wrapperClass}
                    `}>
                    <div
                        className={css`
                            ${overlayScrollbarClass}
                        `}>
                        <ReviewSidebar
                            reviewers={reviewers}
                            expandedSection={expandedSection}
                            handleSectionClick={handleSectionClick}
                            role={role}
                            hasEdits={hasEdits}
                            onInviteClick={toggleInviting}
                            disabled={!currentCommittee || !minutesTitle}
                            minutesSections={minutesSections}
                            reviewersList={reviewersList}
                        />
                        {isInviting && currentCommittee && (
                            <InvitationView
                                reviewersList={reviewersList}
                                isVisible={isInviting}
                                onClose={toggleInviting}
                                onSubmit={handleInvitationSubmitSuccess}
                                minutesId={minutesId}
                                minutesName={minutesTitle}
                                workroomName={currentCommittee.name}
                                dateFormat={dateFormatInput}
                                setLoaded={setLoaded}
                            />
                        )}
                    </div>
                </div>
            )}
        </ClassNames>
    )
}

const ReviewSidebar: FC<{
    reviewers?: Reviewer[]
    expandedSection: string[]
    handleSectionClick: (str: string) => void
    role: UserRole
    hasEdits?: boolean
    disabled?: boolean
    onInviteClick: () => void
    minutesSections: any[]
    reviewersList: PersonChip[]
}> = ({
    handleSectionClick,
    reviewers,
    expandedSection,
    role,
    hasEdits,
    reviewersList,
    onInviteClick,
    disabled,
    minutesSections
}) => {
    const { generalReviewers, nonGeneralReviewers, completedReviewers } = useMemo(() => {
        const generalReviewers = reviewersList.filter((it) => it.is_general)
        const nonGeneralReviewers = reviewersList.filter((it) => !it.is_general)
        const completedReviewers = reviewersList.filter((item) => item.review_completed).length
        return { generalReviewers, nonGeneralReviewers, completedReviewers }
    }, [reviewersList])

    const classes = useStyles()
    const ReviewerSectionContent = useCallback(
        (reviewers: PersonChip[]) => {
            return (
                <ReviewerList>
                    {!reviewers.length && (
                        <Typography className={classes.listItem}>
                            {i18n.t('NO_DUE_DATE')}
                        </Typography>
                    )}
                    {reviewers.map((reviewer) => (
                        <ReviewerListItem key={`reviewer-${reviewer.id}`}>
                            <ReviewerDetailes>
                                <Box className={classes.subtitleContainer}>
                                    <Tooltip title={reviewer.name ?? ''}>
                                        <Typography className={classes.subtitle}>
                                            {reviewer.name}
                                        </Typography>
                                    </Tooltip>
                                    <div className={classes.listItem}>
                                        {translate('DUE_DATE_ALT')}:{' '}
                                        {reviewer.due_date
                                            ? formatDate({
                                                  date: reviewer.due_date,
                                                  dateFormat: transientStorageManager.dateFormat
                                              })
                                            : translate('NO_ACTIONS')}
                                    </div>
                                </Box>
                                {reviewer.review_completed ? (
                                    <Typography className={classes.completed}>
                                        {translate('REVIEW_COMPLETED')}
                                    </Typography>
                                ) : (
                                    <Typography className={classes.notcompleted}>
                                        {translate('REVIEW_NOT_COMPLETED')}
                                    </Typography>
                                )}
                            </ReviewerDetailes>
                        </ReviewerListItem>
                    ))}
                </ReviewerList>
            )
        },
        [classes]
    )
    return (
        <SidebarColumn>
            {reviewers && role === 'ADMIN' && (
                <SidebarCard
                    key="reviewers_section"
                    data-testid="RHP_ReviewerSection"
                    sectionKey="reviewers_section"
                    onClick={handleSectionClick}
                    isExpanded={expandedSection.includes('reviewers_section')}
                    title={ReviewerSectionTitle('REVIEWER_STATUS', classes)}>
                    {!reviewersList.length ? (
                        <NoResultsWrap>
                            <NoResults>
                                <AtlasIcon name="group" size={72} color="--grey-medium" />
                                <SidebarText>{i18n.t('SIDEBAR_NO_REVIEWERS')}</SidebarText>
                            </NoResults>
                            <ButtonWrap>
                                <Button
                                    disableRipple
                                    disabled={disabled}
                                    onClick={onInviteClick}
                                    data-testid="ReviewerSection_InviteReviewersBtn"
                                    className={classes.buttonAlt}>
                                    <StyledInviteNotificationIcon
                                        isActive={false}
                                        skeleton={true}
                                    />
                                    <span className={classes.titlePadding}>
                                        {translate('SEND_INVITATION')}
                                    </span>
                                </Button>
                            </ButtonWrap>
                        </NoResultsWrap>
                    ) : (
                        <>
                            <ReviewerList className={classes.reviewerListItem}>
                                <ReviewerListItem>
                                    <Typography
                                        aria-label={i18n.t('REVIEW_MANAGER_COMPLETED_OF_TOTAL', {
                                            completed: completedReviewers,
                                            total: reviewersList.length
                                        })}
                                        className={
                                            reviewersList.length &&
                                            completedReviewers === reviewersList.length
                                                ? classes.completedStatus
                                                : classes.noncompletedStatus
                                        }>
                                        {`${completedReviewers}/${reviewersList.length} `}
                                        {translate('REVIEW_COMPLETED')}
                                    </Typography>
                                </ReviewerListItem>
                            </ReviewerList>
                            <Divider className={classes.divider} />
                            <Typography className={classes.mainSubTitle}>
                                {i18n.t('REVIEWER_GENERAL')}
                            </Typography>
                            {ReviewerSectionContent(generalReviewers)}
                            <Divider className={classes.divider} />
                            <Typography className={classes.mainSubTitle}>
                                {i18n.t('REVIEWER_RESTRICTED')}
                            </Typography>
                            {ReviewerSectionContent(nonGeneralReviewers)}
                            <Button
                                disableRipple
                                className={classes.button}
                                onClick={onInviteClick}
                                disabled={disabled}>
                                <PencilIcon />
                                <Typography className={classes.btnText}>
                                    {translate('REVIEWER_EDIT')}
                                </Typography>
                            </Button>
                            <Divider className={classes.bottomdivider} />
                        </>
                    )}
                </SidebarCard>
            )}

            <SidebarCard
                key="changes_section"
                data-testid="RHP_ChangesSection"
                sectionKey="changes_section"
                onClick={handleSectionClick}
                isExpanded={expandedSection.includes('changes_section')}
                title={ReviewerSectionTitle('TRACKED_CHANGES', classes)}>
                {!hasEdits && (
                    <NoResultsWrap>
                        <NoResults>
                            <AtlasIcon name="take-minutes" size={72} color="--grey-medium" />
                            <SidebarText>{i18n.t('SIDEBAR_NO_CHANGES')}</SidebarText>
                        </NoResults>
                    </NoResultsWrap>
                )}
                <div>
                    <TrackChangesSidebar minutesSections={minutesSections} />
                </div>
            </SidebarCard>
        </SidebarColumn>
    )
}

const ReviewerSectionTitle = (status: string, classes) => (
    <Typography className={classes.listTitle}>{i18n.t(status)}</Typography>
)

const wrapperClass = css`
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
`

const overlayScrollbarClass = css`
    width: inherit;
    position: relative;
`

const SidebarColumn = styled('div')`
    display: flex;
    flex-flow: column;
    font-family: Source Sans Pro;
    background-color: #ffffff;
    left: 15px;
    top: 0px;
`

const ReviewerList = styled('div')`
    margin-top: 0px;
`

const ReviewerListItem = styled('div')`
    display: flex;
    align-items: center;
    font-family: Source Sans Pro;
`

const ReviewerDetailes = styled('div')`
    display: flex;
    justify-content: space-between;
    padding: 5px 0 5px;
    min-height: 45px;
    width: 100%;
    gap: 6px;
    font-family: Source Sans Pro;
    flex-direction: row;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
`

const SidebarText = styled('p')`
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    color: #676767;
    margin-top: 8px;
    font-family: Source Sans Pro;
`

const NoResults = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Source Sans Pro;
`

const NoResultsWrap = styled('div')`
    padding: 16px 32px;
    font-family: Source Sans Pro;
`
const ButtonWrap = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
`

export default MinutesReviewSidebar
