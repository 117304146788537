import textValidator from './textValidator'
import objectErrorHelper from './objectErrorHelper'

import i18n from 'i18next'

const maxEmailsAllowed = 100
const maxSubject = 500
const maxBody = 10000

export default class notificationValidator {
    static validateNotification(initialObject) {
        let resultObject = notificationValidator.validateToField(initialObject)

        if (!textValidator.isLengthValid(resultObject.subject, 1, maxSubject)) {
            if (resultObject.subject.trim()) {
                resultObject.subject = resultObject.subject.substr(
                    0,
                    Math.min(resultObject.subject.length, maxSubject)
                )
            } else {
                objectErrorHelper.addValidationError(
                    resultObject,
                    'subject',
                    i18n.t('INVALID_EMAIL_SUBJECT')
                )
            }
        } else {
            objectErrorHelper.removeValidationError(initialObject, 'subject')
        }

        if (!textValidator.isLengthValid(resultObject.body, 1, maxBody)) {
            if (resultObject.body && resultObject.body.trim()) {
                resultObject.body = resultObject.body.substr(
                    0,
                    Math.min(resultObject.body.length, maxBody)
                )
            } else {
                objectErrorHelper.addValidationError(
                    resultObject,
                    'body',
                    i18n.t('INVALID_EMAIL_BODY')
                )
            }
        } else {
            objectErrorHelper.removeValidationError(initialObject, 'body')
        }

        return resultObject
    }

    static validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }

    static validateToField(initialObject) {
        const tooManyEmails = initialObject.to && initialObject.to.length > maxEmailsAllowed
        const noEmails = !initialObject.to || !initialObject.to.length

        if (tooManyEmails || noEmails) {
            objectErrorHelper.addValidationError(
                initialObject,
                'to',
                i18n.t('INVALID_EMAILS_COUNT')
            )
        } else {
            objectErrorHelper.removeValidationError(initialObject, 'to')
        }

        if (!initialObject.invalidEmails) {
            initialObject.invalidEmails = new Map()
        }

        //Now we validate every email user added inline

        if (initialObject.to) {
            initialObject.to.forEach((c) => {
                //Per user story we dont validate pre-existing emails
                if (c.id !== null) {
                    return
                }

                //
                if (!notificationValidator.validateEmail(c.email)) {
                    initialObject.invalidEmails.set(c.email, true)

                    objectErrorHelper.addValidationError(
                        initialObject,
                        'email',
                        i18n.t('INVALID_EMAIL')
                    )
                } else {
                    initialObject.invalidEmails.delete(c.email)
                }
            })
        } else {
            objectErrorHelper.removeValidationError(initialObject, `email`)
            initialObject.invalidEmails = new Map()
        }

        if (initialObject.invalidEmails.size === 0) {
            objectErrorHelper.removeValidationError(initialObject, `email`)
        }

        return Object.assign({}, initialObject)
    }
}
