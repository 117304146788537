import { ChipClassKey } from '@material-ui/core/Chip'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import { AtlasGreyDark } from 'assets/styles/variables'

export const MuiChip: Partial<StyleRules<ChipClassKey, {}>> = {
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    outlined: {
        height: '24px',
        borderColor: AtlasGreyDark,
        '& $deleteIcon': {
            marginRight: '4px'
        }
    },
    label: {
        paddingLeft: '10px',
        paddingRight: '12px',
        textIndent: 0
    },
    deleteIcon: {
        width: '16px',
        height: '16px',
        marginRight: '4px',
        marginLeft: 0
    }
}
