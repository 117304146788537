import React, { Component } from 'react'

//#region Components Material

import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'

//#endregion

//#region Components
import i18n from 'i18next'

import AttendeeEditor from '../../minutemanager/dialog/MinuteManagerAttendees'

//#endregion

//#region Redux

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../../reducers/minuteTakerReducer'

import selectors from '../../../selectors/minuteTakerSelectors'
import { AttendiesIcon } from 'assets/icons/AttendiesIcon'
import { NewDialogTextField } from 'components/shared/StyledComponents'
import TextareaAutosize from 'react-autosize-textarea'
import TextLengthCounter from 'components/shared/TextLengthCounter'

import {
    ColorBaseGreyDark,
    ColorBaseBlueGreyLight,
    ColorBaseBlueGreyDark,
    ColorBaseWhite,
    ColorBaseMulberryBlue
} from 'assets/styles/variables'
import { InputLabelStyled } from '../components/form/AssigneeChipInputField'

//#endregion

//#region Props

type Props = {
    actions: {
        syncSectionLocalDataChanges: (...args: any[]) => void
        syncAttendeesLocalDataChanges: (...args: any[]) => void
        saveSection: (...args: any[]) => void
        setSelectedSection: (...args: any[]) => void
    }
    minutesSections: Array<any>
    currentMinuteItem: any
    readonly?: boolean
    selectedSection: any
}

//#endregion

//#region Implementation

class MinuteEditorAttendees extends Component<Props> {
    state = {
        attendeesFocus: false
    }

    onTitleChange = (event) => {
        const section = this.getSection()
        let newValue = event.target.value
        if (!section) {
            return
        }

        if (newValue && newValue.length > 300) {
            newValue = newValue.substr(0, 300)
        }

        section.name = newValue

        //There are several places generating debounced call to save.
        //While debounce is waiting we have to make sure we update underlying object
        //in the reducer so every new save initiator had very updated object to
        //apply his changes. If we dont do that, we can have a setuation when
        //a component tries to inject stale object into debounce process

        this.props.actions.syncSectionLocalDataChanges(section)

        this.props.actions.saveSection(section)

        this.forceUpdate()
    }

    onInformationChange = (event) => {
        const section = this.getSection()

        if (!section) {
            return
        }

        if (event.target.value.length <= 3000) {
            section.body = event.target.value

            this.props.actions.syncSectionLocalDataChanges(section)

            this.props.actions.saveSection(section)

            this.forceUpdate()
        }
    }

    onInformationFocus = () => {
        this.props.actions.setSelectedSection(null)
    }

    getExistingAttendeesText = () => {
        const { currentMinuteItem } = this.props

        if (!currentMinuteItem || !currentMinuteItem.attributes.attendees.length) {
            return ''
        }

        return currentMinuteItem.attributes.attendees
            .map((item) => item.name)
            .reduce((sum, current) => `${sum}, ${current}`)
    }

    getSection = () => {
        const { minutesSections } = this.props

        if (!minutesSections) {
            return null
        }

        const attendeesSection = minutesSections.find((c) => c.sectionType === 'attendees')

        return attendeesSection
    }

    onAttendeesAdd = (attendees) => {
        this.props.actions.syncAttendeesLocalDataChanges(attendees)
    }

    onAttendeesDelete = (attendees) => {
        this.props.actions.syncAttendeesLocalDataChanges(attendees)
    }

    getAttendeesView = () => {
        const attendees = this.props.currentMinuteItem ? (
            <AttendeeEditor
                readonly={this.props.readonly}
                hideFloatingLabel={false}
                hideAvatars={true}
                currentMinuteItem={this.props.currentMinuteItem}
                saveAttendeesUponEdit={true}
                onAttendeesAdd={this.onAttendeesAdd}
                onAttendeesDelete={this.onAttendeesDelete}
            />
        ) : null

        const key = this.props.currentMinuteItem ? this.props.currentMinuteItem.id : 0

        return (
            <ClassNames key={key}>
                {({ css }) => (
                    <div
                        key={key}
                        className={css`
                            width: 100%;
                            z-index: ${this.state.attendeesFocus ? 200 : 0};
                        `}>
                        {attendees}
                        <div style={{ marginTop: '20px' }}>
                            <InputLabelStyled aria-label={i18n.t('ADD_MORE_INFORMATION')}>
                                {i18n.t('ADD_MORE_INFORMATION')}
                            </InputLabelStyled>
                        </div>
                        <NewDialogTextField
                            disabled={this.props.readonly}
                            multiline={true}
                            InputLabelProps={{ variant: 'outlined' }}
                            InputProps={{ disableUnderline: false }}
                            onChange={this.onInformationChange}
                            className={css`
                                ${TextFieldStyles}
                            `}
                            value={this.getAttendeeBody()}
                            helperText={
                                <TextLengthCounter
                                    errorText={
                                        this.getAttendeeBody().length >= 3000
                                            ? `${i18n.t('MAXIMUM_CHARACTER_LENGTH')}`
                                            : ''
                                    }
                                    maxValue={3000}
                                    value={this.getAttendeeBody()}
                                    colour={true}
                                />
                            }
                        />
                    </div>
                )}
            </ClassNames>
        )
    }

    getAttendeeName = () => {
        const section = this.getSection()

        if (!section) {
            return ''
        }

        return section.name
    }

    getAttendeeBody = () => {
        const section = this.getSection()

        if (!section) {
            return ''
        }

        return section.body || ''
    }

    getAttendeeIcon = () => {
        const { currentMinuteItem } = this.props

        if (!currentMinuteItem) {
            return null
        }
        const count = `${
            currentMinuteItem.attributes.attendees
                ? currentMinuteItem.attributes.attendees.length
                : 0
        }`
        const attendeesIcon = (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            display: flex;
                            align-items: flex-end;
                            margin-left: 30px;
                        `}>
                        {this.state.attendeesFocus ? (
                            <StyledAttendiesIconFocus />
                        ) : (
                            <StyledAttendiesIcon />
                        )}
                        <AttendeesCountLabel
                            style={{
                                color: this.state.attendeesFocus
                                    ? ColorBaseWhite
                                    : ColorBaseBlueGreyLight
                            }}>
                            ({count})
                        </AttendeesCountLabel>
                    </div>
                )}
            </ClassNames>
        )

        return attendeesIcon
    }

    renderTitleText = (): JSX.Element => {
        const saveInfo = '' //process.env.NODE_ENV === 'development' ? <span style={getUpdatedStyle()}>{updatedLabel}</span> : null

        const text = (
            <ClassNames>
                {({ css }) => (
                    <AttendeesTitle>
                        {saveInfo}
                        <StyledTextArea
                            id="attendee_title"
                            aria-label={i18n.t('ATTENDEES')}
                            disabled={this.props.readonly}
                            className={css`
                                font-weight: 500;
                                color: ${this.state.attendeesFocus
                                    ? ColorBaseWhite
                                    : ColorBaseBlueGreyDark};
                                &:focus {
                                    outline: 2px solid ${ColorBaseMulberryBlue};
                                    border-radius: 2px;
                                    background: none;
                                }
                            `}
                            value={this.getAttendeeName()}
                            onChange={this.onTitleChange}
                            label=""
                        />
                    </AttendeesTitle>
                )}
            </ClassNames>
        )

        return text
    }
    handleAttendeesFocus = () => {
        this.setState({ attendeesFocus: true })
        this.props.actions.setSelectedSection(null)
    }

    render() {
        const nestedItems = [this.getAttendeesView()]

        return (
            <AttendeesContainer
                onFocus={this.handleAttendeesFocus}
                onBlur={() => this.setState({ attendeesFocus: false })}>
                {<AttendeesContent>{nestedItems}</AttendeesContent>}
            </AttendeesContainer>
        )
    }
}
//#endregion

//#region Styles
const StyledTextArea = styled(TextareaAutosize)`
    width: 100%;
    resize: none;
    background: transparent;
    outline: none;
    border: none;
    font-size: 16px;
    color: inherit;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin: 9px 0;
`

const AttendeesContainer = styled('div')`
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #f5f8f9;
    overflow-y: auto;
`

const AttendeesTitle = styled('div')`
    width: 100%;
`

const AttendeesContent = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    background-color: #ffffff;
    .MuiOutlinedInput-inputMultiline {
        min-height: 106px;
        resize: vertical;
    }
`

const StyledAttendiesIcon = styled(AttendiesIcon)`
    width: 12px;
    height: 12px;
    margin-right: 5px;
    fill: ${ColorBaseGreyDark};
`
const StyledAttendiesIconFocus = styled(AttendiesIcon)`
    width: 12px;
    height: 12px;
    margin-right: 5px;
    fill: ${ColorBaseWhite};
`
const AttendeesCountLabel = styled('span')`
    color: ${ColorBaseBlueGreyLight};
    font-size: 12px;
`
const TextFieldStyles = css`
    .MuiInputBase-root.MuiOutlinedInput-root {
        margin-top: 5px;
    }
    p {
        margin-left: 0px;
        margin-right: 0px;
    }
`
//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        minutesSections: selectors.minutesSections(state.minuteTakerReducer),
        selectedSection: selectors.selectedSection(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        syncSectionLocalDataChanges,
        syncAttendeesLocalDataChanges,
        saveSection,
        setSelectedSection
    } = actions

    return {
        actions: bindActionCreators(
            {
                syncSectionLocalDataChanges,
                syncAttendeesLocalDataChanges,
                saveSection,
                setSelectedSection
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinuteEditorAttendees)
//#endregion
