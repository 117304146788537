import React from 'react'
import styled from '@emotion/styled'
import { ColorBaseGreyDark } from 'assets/styles/variables'
import { translate } from 'components/shared/internationalization'

const Svg = styled('svg')`
    fill: ${ColorBaseGreyDark};
    height: 15px;
`

type Props = {
    title?: JSX.Element
    className?: string
} & React.SVGAttributes<SVGElement>

export const DeleteDateRowIcon = ({ title = translate(''), className, ...rest }: Props) => {
    return (
        <Svg
            aria-labelledby="Close-title"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...rest}>
            <title id="Close-title">Close</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.72703 13.773C1.09099 11.1369 1.09099 6.86307 3.72703 4.22703C6.36307 1.59099 10.6369 1.59099 13.273 4.22703C15.909 6.86307 15.909 11.1369 13.273 13.773C10.6369 16.409 6.36307 16.409 3.72703 13.773ZM2.66637 3.16637C-0.555456 6.38819 -0.555456 11.6118 2.66637 14.8336C5.88819 18.0555 11.1118 18.0555 14.3336 14.8336C17.5555 11.6118 17.5555 6.38819 14.3336 3.16637C11.1118 -0.0554565 5.88819 -0.0554562 2.66637 3.16637ZM6.28012 5.71969C5.98724 5.42679 5.51237 5.42677 5.21946 5.71965C4.92656 6.01253 4.92653 6.4874 5.21941 6.78031L7.43901 9.0001L5.21919 11.2199C4.92629 11.5128 4.92629 11.9877 5.21919 12.2806C5.51208 12.5735 5.98695 12.5735 6.27985 12.2806L8.49962 10.0608L10.7192 12.2806C11.012 12.5735 11.4869 12.5735 11.7798 12.2806C12.0727 11.9877 12.0728 11.5128 11.7799 11.2199L9.56028 9.00015L11.7798 6.78058C12.0727 6.48769 12.0727 6.01281 11.7798 5.71992C11.487 5.42703 11.0121 5.42703 10.7192 5.71992L8.49967 7.93944L6.28012 5.71969Z"
                fill="#1E1E1E"
            />
        </Svg>
    )
}
