import React, { useState } from 'react'
import { AtlasTag } from '@diligentcorp/atlas-react'
import i18n from 'i18next'
import { css, ClassNames } from '@emotion/react'
import { DeleteDateRowIcon as IconDeleteDateRow } from 'assets/icons/DeleteDateRowIcon'
import { AtlasGreyLight, AtlasGreyMedium } from 'assets/styles/variables'
import { EllipseString } from 'assets/sharedStyles'
import { SelectChip } from './StyledComponents'
import { v4 as uuid } from 'uuid'
import styled from '@emotion/styled'

type Props = {
    className?: string
    name: string
    email: string | null
    readonly?: boolean
    onDelete?: (...args) => void
}

export const UserChip = (props: Props) => {
    const [displayType, setDisplayType] = useState<DisplayType>('name')

    // TODO: use translate instead of t when fully committing to Atlas
    const chipEmail = !!props.email ? props.email : i18n.t('NO_EMAIL_PERSON_LABEL')
    const label = displayType === 'name' ? props.name : chipEmail

    const handleClick = () => {
        displayType === 'name' ? setDisplayType('email') : setDisplayType('name')
    }
    const key = uuid()
    return props.readonly ? (
        <ClassNames>
            {({ css }) => (
                <AtlasTag
                    className={css`
                        ${props.className} ${ChipClass}
                    `}
                    color={AtlasGreyLight}
                    data-testid={`Chip_${key}`}
                    tabIndex={-1}
                    onClick={handleClick}>
                    <EllipseString>{label}</EllipseString>
                </AtlasTag>
            )}
        </ClassNames>
    ) : (
        // Atlas Chip

        // <AtlasChip
        //     className={ChipClass}
        //     data-testid={`Chip_${key}`}
        //     tabIndex={-1}
        //     onClick={handleClick}>
        //     <Inline>
        //         <EllipseString>{label}</EllipseString>
        //         <StyledAtlasIcon name="close" size="16" />
        //     </Inline>
        // </AtlasChip>

        // Use until we commit to Atlas
        <SelectChip
            className={props.className}
            key={key}
            data-testid={`Chip_${key}`}
            tabIndex={-1}
            label={label}
            onClick={handleClick}
            onTouchEnd={handleClick}
            deleteIcon={<StyledIconDelete onTouchEnd={props.onDelete} />}
            onDelete={props.onDelete}
        />
    )
}

const ChipClass = css`
    display: inline-block;
    max-width: 335px;
    margin: 10px 20px 5px 0px;
    outline: none;
    cursor: pointer;
`
const StyledIconDelete = styled(IconDeleteDateRow)`
    display: inline-block;
    fill: ${AtlasGreyMedium};
    stroke: inherit;
    width: 20px;
    height: 20px;
`
// const StyledAtlasIcon = styled(AtlasIcon)`
//     padding-left: 5px;
// `
// const Inline = styled('div')`
//     display: flex;
// `

type DisplayType = 'name' | 'email'

export default UserChip
