import React, { useRef, FC, ChangeEvent } from 'react'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import { translate } from 'components/shared/internationalization'
import { ColorBaseBlueLight, ColorBaseSkyBlue } from 'assets/styles/variables'
import { triggerClick } from 'components/helpers/DomHelper'
import { AtlasButtonOld } from 'components/atlas-material/button'

type Props = {
    disabled?: boolean
    fileTypeRegex: RegExp
    onFileMismatch: () => void
    onFileTooLarge: (...args: any[]) => void
    onFileUploaded: (...args: any[]) => any
    maxSize: number
}

export const FileUploader: FC<Props> = ({
    disabled,
    fileTypeRegex,
    maxSize,
    onFileMismatch,
    onFileTooLarge,
    onFileUploaded
}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) {
            return
        }
        const file = e.target.files[0]

        const isMatchedFile = isValidExtToUpload(file, fileTypeRegex)

        if (!isMatchedFile && !!onFileMismatch) {
            onFileMismatch()
            return
        }

        if (file.size > maxSize) {
            onFileTooLarge(file.size)
            return
        }
        const reader = new FileReader()
        reader.onload = () => onFileUploaded(file, reader)
        reader.readAsDataURL(file)
    }

    const handleChooseFile = (e) => {
        e.preventDefault()
        if (disabled) return

        triggerClick(fileInputRef.current)
    }

    return (
        <ClassNames>
            {({ css }) => (
                <Wrapper>
                    <AtlasButtonOld
                        dataTestId="MinuteManagerModal_ChooseFileBtn"
                        className={css`
                            ${buttonStyleClass()}
                        `}
                        secondary
                        disabled={disabled}
                        onClick={handleChooseFile}>
                        {translate('CHOOSE_FILE')}
                    </AtlasButtonOld>
                    <input
                        className={css`
                            ${inputClass};
                        `}
                        onChange={handleInputChange}
                        tabIndex={-1}
                        type="file"
                        ref={fileInputRef}
                    />
                </Wrapper>
            )}
        </ClassNames>
    )
}

const isValidExtToUpload = (file: File, fileTypeRegex) => {
    const regex = fileTypeRegex
    return file.name.match(regex) !== null
}

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
`

const buttonStyleClass = () => css`
    white-space: nowrap;
    position: relative;
    margin-right: 10px;
    color: ${ColorBaseBlueLight};
    width: 100%;
    span {
        font-family: 'Source Sans Pro';
        border-color: #1e1e1e;
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
    }
    :first-child {
        margin-left: 3px;
    }
    :focus {
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
`
const inputClass = css`
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
`
