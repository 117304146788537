import React from 'react'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'
import {
    ColorBaseGreen,
    ColorBaseRed,
    ColorBaseGreyMedium,
    ColorBaseBlueMedium,
    ColorBaseGreyDark,
    ColorBaseBlueGreyMedium
} from 'assets/styles/variables'
import moment from 'moment'
import InlineNotesTextEditor from 'components/minutetaker/actionlist/listeditors/InlineNotesTextEditor'
import InlineDateEditor from 'components/minutetaker/actionlist/listeditors/InlineDateEditor'
import InlineAssigneesEditor from 'components/minutetaker/actionlist/listeditors/InlineAssigneesEditor'
import InlineActionTextEditor from 'components/minutetaker/actionlist/listeditors/InlineActionTextEditor'

import IconButtonView from 'components/minutetaker/actionlist/IconButtonView'
import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import {
    StyledIconWrapper,
    StyledAttendiesIcon,
    StyledAddNotesIcon,
    DetailsSection,
    StyledNewSectionIcon,
    StyledCalendarIcon,
    Content,
    StyledSendNotificationIcon
} from './Atoms'
import { Checkbox, FormControlLabel } from 'components/shared/StyledComponents'
import { addKeyPressHandler } from 'common/util/functions'

type ActionItemDetailsCardProps = {
    dateFormat: string
    actionItem: ActionItem
    section?: { name: string }
    onCompleteActionChecked: (actionItem: ActionItem) => void
    onNotify: (actionItem: ActionItem) => void
}

const statusColorMap = {
    complete: {
        label: translate('ACTION_COMPLETE'),
        color: ColorBaseGreen
    },
    incomplete: {
        label: translate('ACTION_INCOMPLETE'),
        color: ColorBaseBlueGreyMedium
    },
    overdue: {
        label: translate('ACTION_INCOMPLETE'),
        color: ColorBaseBlueGreyMedium
    }
}

const dateColorMap = {
    complete: {
        color: ColorBaseGreen
    },
    incomplete: {
        color: ColorBaseBlueMedium
    },
    no_date: {
        color: ColorBaseGreyDark
    },
    overdue: {
        color: ColorBaseRed
    }
}

export class ActionItemDetailsCard extends React.Component<ActionItemDetailsCardProps> {
    static defaultProps = {
        minutesItem: {
            attendees: []
        }
    }

    private renderNotificationArea = (action: ActionItem): JSX.Element => (
        <ClassNames>
            {({ css }) => (
                <div
                    className={css`
                        display: flex;
                        align-items: flex-start;
                        width: 100%;
                    `}>
                    <StyledIconWrapper>
                        <StyledSendNotificationIcon
                            isActive={action.notificationStatus === 'sent'}
                        />
                    </StyledIconWrapper>
                    <IconButtonView
                        className={css`
                            width: 100%;
                        `}
                        isActive={action.notificationStatus === 'sent'}
                        isFailed={action.notificationStatus === 'failed'}
                        activeLabel={i18n.t('NOTIFICATION_SENT_LABEL')}
                        inactiveLabel={i18n.t('NOTIFICATION_SEND_LABEL')}
                        failedLabel={i18n.t('NOTIFICATION_FAILED_LABEL')}
                        labelActiveColor={ColorBaseBlueGreyMedium}
                        labelInactiveColor={ColorBaseBlueGreyMedium}
                        onToggle={() => this.props.onNotify(action)}
                    />
                </div>
            )}
        </ClassNames>
    )

    private renderStatus = ({ actionItem }) => {
        const isComplete = actionItem.completionStatus === 'complete'
        const isOverdue = !isComplete && moment(actionItem.dueDate).isBefore(new Date(), 'day')
        const status = isOverdue ? 'overdue' : actionItem.completionStatus

        const statusLabel = statusColorMap[status].label
        const statusColor = statusColorMap[status].color

        const statusEditor = (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={actionItem.completionStatus === 'complete'}
                        disableFocusRipple
                        disableRipple
                        onClick={() => this.props.onCompleteActionChecked(actionItem)}
                        onKeyPress={(e) =>
                            addKeyPressHandler(e)
                                ? this.props.onCompleteActionChecked(actionItem)
                                : null
                        }
                    />
                }
                label={<React.Fragment>{statusLabel}</React.Fragment>}
            />
        )

        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            color: ${statusColor};
                            font-size: '1rem';
                        `}>
                        {statusEditor}
                    </div>
                )}
            </ClassNames>
        )
    }

    private renderAssigneesSection = ({ actionItem }: { actionItem: ActionItem }) => {
        const assigneesEditor = (
            <InlineAssigneesEditor rowIndex={actionItem.id} action={actionItem} />
        )

        return (
            <ClassNames>
                {({ css }) => (
                    <React.Fragment>
                        <div
                            className={css`
                                display: flex;
                                align-items: flex-start;
                                width: 100%;
                                padding: 5px 0;
                            `}>
                            <StyledIconWrapper>
                                <StyledAttendiesIcon
                                    isActive={
                                        actionItem.assignees && actionItem.assignees.length > 0
                                    }
                                />
                            </StyledIconWrapper>
                            {assigneesEditor}
                        </div>
                        <div
                            className={css`
                                margin: 15px 0;
                                border-bottom: 1px solid ${ColorBaseGreyMedium};
                                width: 95%;
                            `}
                        />
                        {this.renderNotificationArea(actionItem)}
                    </React.Fragment>
                )}
            </ClassNames>
        )
    }

    private renderSectionTitle = ({ section }) =>
        section && (
            <ClassNames>
                {({ css }) => (
                    <DetailsSection
                        className={css`
                            align-items: center;
                        `}>
                        <StyledSectionTitleLabel>
                            <StyledIconWrapper>
                                <StyledNewSectionIcon />
                            </StyledIconWrapper>
                            {section.name}
                        </StyledSectionTitleLabel>
                    </DetailsSection>
                )}
            </ClassNames>
        )

    private renderActionDueDateSection = ({
        actionItem,
        dateFormat
    }: {
        actionItem: ActionItem
        dateFormat: string
    }) => {
        const isComplete = actionItem.completionStatus === 'complete'
        const isOverdue = !isComplete && moment(actionItem.dueDate).isBefore(new Date(), 'day')
        const status = actionItem.dueDate
            ? isOverdue
                ? 'overdue'
                : actionItem.completionStatus
            : 'no_date'
        // Avoid turning the Date Icon green when complete
        const dateColor =
            status === 'overdue' || status === 'no_date'
                ? dateColorMap[status].color
                : dateColorMap.incomplete.color
        const actionDateEditor = (
            <InlineDateEditor isSelected={true} action={actionItem} dateFormat={dateFormat} />
        )

        return (
            <React.Fragment>
                <StyledIconWrapper>
                    <StyledCalendarIcon color={dateColor} />
                </StyledIconWrapper>
                {actionDateEditor}
            </React.Fragment>
        )
    }

    private renderNotesSection = ({ actionItem }: { actionItem: ActionItem }) => {
        const notesEditLabel = (
            <InlineNotesTextEditor rowIndex={actionItem.id} action={actionItem} />
        )

        return (
            <ClassNames>
                {({ css }) => (
                    <StyledNotesSection>
                        <StyledIconWrapper>
                            <StyledAddNotesIcon isActive={!!actionItem.notes} />
                        </StyledIconWrapper>
                        <div
                            className={css`
                                flex: 1;
                            `}>
                            {notesEditLabel}
                        </div>
                    </StyledNotesSection>
                )}
            </ClassNames>
        )
    }

    render() {
        const { actionItem, dateFormat, section } = this.props

        const actionDueDateSection = this.renderActionDueDateSection({ actionItem, dateFormat })

        const actionEditTitle = (
            <InlineActionTextEditor rowIndex={actionItem.id} action={actionItem} />
        )

        const actionStatus = this.renderStatus({ actionItem })
        const assigneesSection = this.renderAssigneesSection({ actionItem })
        const minutesSection = this.renderSectionTitle({ section })
        const notesSection = this.renderNotesSection({ actionItem })

        return (
            <ClassNames>
                {({ css }) => (
                    <Content>
                        <DetailsSection>{actionStatus}</DetailsSection>
                        <DetailsSection aria-label={i18n.t('ACTION')}>
                            <div
                                className={css`
                                    padding-left: 7px;
                                    flex: 1;
                                `}>
                                {actionEditTitle}
                            </div>
                        </DetailsSection>
                        <DetailsSection>{actionDueDateSection}</DetailsSection>
                        <DetailsSection
                            className={css`
                                flex-direction: column;
                            `}>
                            {assigneesSection}
                        </DetailsSection>
                        {minutesSection}
                        <DetailsSection
                            aria-label={i18n.t('INTERNAL_NOTE')}
                            className={css`
                                min-height: 100px;
                                align-items: flex-start;
                            `}>
                            {notesSection}
                        </DetailsSection>
                    </Content>
                )}
            </ClassNames>
        )
    }
}

const StyledNotesSection = styled('div')`
    display: flex;
    align-items: flex-start;
    padding: 5px 0px;
    width: 100%;
`

const StyledSectionTitleLabel = styled('div')`
    display: flex;
    align-items: flex-start;
    font-size: 1rem;
`
