import { createSelector } from 'reselect'

const selectorDataState = (state) => {
    return state.dataState
}

const selectorCommunicationState = (state) => {
    return state.communicationState
}

const selectorControlState = (state) => {
    return state.controlState
}

export default {
    allTimeZones: createSelector(
        selectorDataState,
        (dataState) => dataState.timeZones
    ),
    editedItem: createSelector(
        selectorDataState,
        (dataState) => dataState.editedItem
    ),
    linkedBook: createSelector(
        selectorDataState,
        (dataState) => dataState.linkedBook
    ),
    linkedBookError: createSelector(
        selectorDataState,
        (dataState) => dataState.linkedBookError
    ),
    allContacts: createSelector(
        selectorDataState,
        (dataState) => dataState.allContacts
    ),
    meetingDates: createSelector(
        selectorDataState,
        (dataState) => (dataState.editedItem ? dataState.editedItem.meetingDates : null)
    ),
    saveComplete: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.saveComplete
    ),
    isSavingExisting: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.isSavingExisting
    ),
    readyToSave: createSelector(
        selectorControlState,
        (controlState) => controlState.readyToSave
    ),
    validationTriggeredOnce: createSelector(
        selectorControlState,
        (controlState) => controlState.validationTriggeredOnce
    ),
    lastSaveFailed: createSelector(
        selectorControlState,
        (controlState) => controlState.lastSaveFailed
    )
}
