import { createSelector } from 'reselect'
import { transientStorageManager } from '../businesslayer/minutesSessionStore'

const selectorDataState = (state, id) => {
    return state.dataState
}

const selectorCommunicationState = (state) => {
    return state.communicationState
}

const selectorControlState = (state) => {
    return state.controlState
}

export default {
    saveComplete: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.saveComplete
    ),
    readyToSave: createSelector(
        selectorControlState,
        (controlState) => controlState.readyToSave
    ),
    currentAction: createSelector(
        selectorDataState,
        (dataState) => dataState.currentAction
    ),
    allContacts: createSelector(
        selectorDataState,
        (dataState) => dataState.allContacts
    ),
    savedAction: createSelector(
        selectorDataState,
        (dataState) => dataState.savedAction
    ),
    isSavingExisting: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.isSavingExisting
    ),
    dateFormat: createSelector(
        selectorDataState,
        (dataState) => dataState.dateFormat
    ),
    dateInputMask: createSelector(
        selectorDataState,
        (dataState) => transientStorageManager.dateFormatInput
    )
}
