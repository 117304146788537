import styled from '@emotion/styled'
import {
    space,
    color,
    fontSize,
    fontWeight,
    lineHeight,
    style,
    flex,
    flexDirection,
    justifyContent,
    display,
    alignItems
} from 'styled-system'
import { Link } from 'react-router-dom'

const userSelect = style({
    prop: 'userSelect',
    cssProperty: 'userSelect'
})

const cursor = style({
    prop: 'cursor',
    cssProperty: 'cursor'
})

const textTransform = style({
    prop: 'textTransform',
    cssProperty: 'textTransform'
})

const wordBreak = style({
    prop: 'wordBreak',
    cssProperty: 'wordBreak'
})

export const Text = styled('span')`
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${color}
  ${userSelect}
  ${cursor}
  ${textTransform}
  ${wordBreak}
` as any

Text.defaultProps = {
    color: '#2a455a',
    margin: '0 0 0 0'
} as any

export const Card = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    outline: none;
    label: card;
` as any

export const CardSummary = styled('div')<{ isOpen?: boolean }>`
    flex: 1;
    display: flex;
    padding-bottom: ${({ isOpen }) => isOpen && '1rem'};
    border-bottom: ${({ isOpen }) => isOpen && '1px solid #dcdcdc'};
    align-items: center;
    min-height: 63px;
    ${space};
    label: card-summary;
` as any

export const CardTitle = styled('div')`
  word-break: break-word;
  ${fontSize}
  ${lineHeight}
  ${userSelect}
  ${cursor}
  ${space}
  ${color}
  ${flex}
  ${flexDirection}
  ${display}
  ${space}
  ${justifyContent}
  ${alignItems}
` as any

CardTitle.defaultProps = {
    fontSize: 3,
    lineHeight: 1.5,
    m: 0,
    color: '#2a455a',
    justifyContent: 'space-between',
    alignItems: 'stretch'
}

export const StyledLink = styled(Link)`
    text-decoration: none;
    width: 100%;
`

export const CardSubtitle = Text.withComponent('div')

export const CardBody = styled('div')`
    display: flex;
    margin-top: 1rem;
    ${space};
    label: card-body;
`

export const CardActions = styled('div')`
    min-width: 8rem;
    display: flex;
    flex-direction: column;
    label: card-actions;
`

export const Section = styled('div')`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${space};
    label: section;
` as any
