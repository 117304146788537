/**
 * All Global Context Providers.
 * Only use global providers
 * if they absolutely need to be global
 */
import React, { FC } from 'react'

import { CommitteesProvider } from 'components/contexts/committee-context'
import { useFlag } from 'components/contexts/flag/Flag'
type Props = {
    committeeId: string
    platform: Platform
}

export const AppContextProviders: FC<Props> = ({ committeeId, platform, children }) => {
    const isStaticRoleMode = useFlag('isStaticRoleMode')
    return (
        <CommitteesProvider
            initialCommitteeId={committeeId}
            isStaticRoleMode={isStaticRoleMode}
            platform={platform}>
            {children}
        </CommitteesProvider>
    )
}

export default AppContextProviders
