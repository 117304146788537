import React, { useCallback, useMemo, useState } from 'react'
import { AtlasGreyLight, ColorBaseWhite } from 'assets/styles/variables'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { exportAttendanceReport } from 'businesslayer/api/minutes'
import { saveFile } from 'common/util/file'
import selectors from 'selectors/minuteAttendeesSelectors'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actions } from 'reducers/minutesAttendeesReducer'
import { LocaleSpecification } from 'moment'
import ReportContentView from './minutes-report-content-view'
import i18n from 'common/util/internationalization'
import { makeStyles, Typography, Box } from '@material-ui/core'
import StatusAlertIcon from 'assets/icons/formatting/StatusAlertIcon'
import { DialogActions } from 'components/shared/StyledComponents'
import LinearIndeterminate from 'components/shared/LinearProgress'

const useStyles = makeStyles((theme) => ({
    reportContainer: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        backgroundColor: ColorBaseWhite,
        borderRadius: 8,
        border: `1px solid ${AtlasGreyLight}`,
        height: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 150px )'
        }
    },
    noDataViewContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    reportBlankHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '16px',
        [theme.breakpoints.down('md')]: {
            padding: '2%',
            textWrap: 'balance'
        }
    },
    loaderStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'top'
    },
    loaderText: {
        paddingLeft: '1%',
        color: theme.palette.text.disabled,
        textAlign: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    dialogActions: {
        borderTop: `1px solid ${theme.palette.divider}`,
        height: '100%',
        gap: '20px',
        placeContent: 'center'
    },
    updateReport: {
        color: theme.palette.error.main,
        marginLeft: '-0.5rem',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '16px',
        fontStyle: 'normal',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '8px',
        maxWidth: '500px',
        width: 'auto',
        borderRadius: '4px',
        border: `1px solid #EAA14B`,
        background: theme.palette.background.paper,
        margin: '0'
    },
    iconContainer: {},
    textContainer: {},
    statusAlert: {
        marginTop: '10px'
    },
    createMinutesTxtClsheader: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '0px',
        color: '#935206',
        margin: '0',
        marginTop: '15px'
    },
    contentText: {
        color: '#AF292E',
        margin: '0',
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400
    }
}))
export interface ReportRow {
    name: string
    attended: string
    percentage: string
}

export type ReportData = {
    startDate: LocaleSpecification
    endDate: LocaleSpecification
    committeeName?: string
    selectedCommittee?: MinutesUnnormalized[]
}
export type ReportResponseData = {
    start_date: string
    end_date: string
    committee_name: string
    attendance_data: ReportRow[]
    total_meeting_count: number
}
interface Props {
    newReportData: ReportData
    actions?: {
        [key: string]: (...args: any[]) => void
    }
    attendanceReportResponse: ReportResponseData
    createReportLoading: boolean
    preserveFormState: {
        startDate: string
        endDate: string
    }
    disableExportReport: {
        isStateMatched: boolean
        isReportGenerated: boolean
    }
}

const NoDataView: React.FC = React.memo(() => {
    const classes = useStyles()

    return (
        <div className={classes.noDataViewContainer}>
            <div className={classes.reportBlankHeader}>
                <div dangerouslySetInnerHTML={{ __html: i18n.t('HEADER_TEXT') }}></div>
            </div>
        </div>
    )
})

export const LoaderView: React.FC = React.memo(() => {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.loaderStyle}>
                <LinearIndeterminate loading={true} />
            </div>
            <h1 className={classes.loaderText}>{i18n.t('LOADING_INFO')}</h1>
        </div>
    )
})
export const NoReportView: React.FC = React.memo(() => {
    const classes = useStyles()
    return (
        <DialogActions className={classes.dialogActions}>
            <Typography className={classes.updateReport}>
                <Box className={classes.iconContainer}>
                    <StatusAlertIcon className={classes.statusAlert} width={'25'} height={'25'} />
                </Box>
                <Box className={classes.textContainer}>
                    <Box className={classes.createMinutesTxtClsheader}>
                        {i18n.t('CANNOT_CREATE_REPORT')}
                    </Box>
                    <br />
                    <Box className={classes.contentText}>{i18n.t('NO_RESULT_RETURN')}</Box>
                </Box>
            </Typography>
        </DialogActions>
    )
})
const MinutesReportDetails: React.FC<Props> = React.memo(
    ({
        attendanceReportResponse,
        newReportData,
        createReportLoading,
        preserveFormState,
        disableExportReport
    }) => {
        const classes = useStyles()
        const tableData: ReportRow[] | undefined = attendanceReportResponse?.attendance_data ?? []
        const totalRowCount = useMemo(() => (tableData ? tableData.length : ''), [tableData])
        const [isDownloading, setDownloading] = useState<boolean>(false)
        const handleDownloadFile = useCallback(({ response, responseType }) => {
            saveFile({ response, responseType })
            setDownloading(false)
        }, [])

        const makeHandleExportMinutes = useCallback(async () => {
            try {
                const committeeId = getSessionStorageItem('currentCommitteeId')
                const response = await exportAttendanceReport(committeeId, newReportData)
                handleDownloadFile({ response, responseType: 'xlsx' })
            } catch (error) {
                console.error(error)
                setDownloading(false)
            }
        }, [newReportData, handleDownloadFile])

        const exportReportClickHandler = useCallback(() => {
            setDownloading(true)
            makeHandleExportMinutes()
        }, [makeHandleExportMinutes])

        const shouldShowLoader = createReportLoading
        const shouldShowNoReportContent =
            !shouldShowLoader &&
            !attendanceReportResponse?.attendance_data.length &&
            attendanceReportResponse
        const shouldShowNoData =
            !shouldShowLoader && !shouldShowNoReportContent && tableData.length === 0
        const shouldShowReportContent =
            !shouldShowLoader && !shouldShowNoReportContent && tableData.length > 0
        return (
            <div className={classes.reportContainer}>
                {shouldShowLoader && <LoaderView />}
                {shouldShowNoData && <NoDataView />}
                {shouldShowNoReportContent && <NoReportView />}
                {shouldShowReportContent && (
                    <ReportContentView
                        totalRowCount={totalRowCount}
                        exportReportClickHandler={exportReportClickHandler}
                        reportData={attendanceReportResponse}
                        isDownloading={isDownloading}
                        preservedFormState={preserveFormState}
                        disableExportReport={disableExportReport}
                    />
                )}
            </div>
        )
    }
)

const mapStateToProps = (state, _) => {
    return {
        newReportData: selectors.setNewReportData(state.minutesAttendeesReducer),
        attendanceReportResponse: selectors.setCreateReportDataResponse(
            state.minutesAttendeesReducer
        ),
        createReportLoading: selectors.setCreateReportLoading(state.minutesAttendeesReducer),
        preserveFormState: state.minutesAttendeesReducer.controlState.preservedFormState,
        disableExportReport: selectors.getFormChangeState(state.minutesAttendeesReducer)
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MinutesReportDetails)
