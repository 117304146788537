import textValidator from 'businesslayer/validation/textValidator'
import i18n from 'i18next'

export const MIN_COMMENT = 0
export const MAX_COMMENT = 500

/**
 * Validate Message
 * @param message
 */
export const validateMessage = (message: string) => {
    if (!textValidator.isLengthValid(message, MIN_COMMENT, MAX_COMMENT)) {
        return i18n.t('INVALID_EMAIL_SUBJECT')
    } else {
        return ''
    }
}
