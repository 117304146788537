import React, { ReactNode } from 'react'

import { I18nextProvider } from 'react-i18next'
import i18n from 'common/util/internationalization'
import { find } from 'rambdax'

const DEFAULT_LANGUAGE = 'en-us'

export default ({ language, children }: { language: string; children: ReactNode }) => {
    const supportedLanguage = getSupportedLanguageTag(language, DEFAULT_LANGUAGE)
    if (supportedLanguage !== i18n.language) {
        i18n.changeLanguage(supportedLanguage)
    }

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

/**
 * Trys to find a matching language key for i18n and return it
 * @param language
 * @returns the supported language key or empty string ('')
 */
export const getSupportedLanguageTag = (
    languageTag: string,
    defaultTag?: SupportedLanguageTags
) => {
    // First look for matching long code
    const standardizedTag = languageTag.toLowerCase()
    const longTagFound = find((l) => l === standardizedTag, languageMap)
    if (!!longTagFound) return longTagFound

    // try fallback on matching short code
    const standardizedShortTag = standardizedTag.substring(0, 2)
    const shortTagFound = find((l) => l === standardizedShortTag, languageMap)
    if (!!shortTagFound) return shortTagFound

    // return defaultTag or '' if no matching long or short code
    return defaultTag || ''
}

const languageMap = [
    'en',
    'en-us',
    'es',
    'es-la',
    'es-ar',
    'fr',
    'fr-fr',
    'it',
    'it-it',
    'nl',
    'nl-nl',
    'pt',
    'pt-br',
    'de',
    'de-de',
    'zh',
    'zh-cn',
    'ja',
    'ja-jp'
]
