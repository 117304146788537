import i18n from 'common/util/internationalization'
import { getPostHeaders } from 'common/util/requestUtils'
import emitter from 'common/util/events'
import { removeSessionStorageItem } from 'businesslayer/minutesLocalStore'

export const fetchAiSupport = async (body) => {
    try {
        return fetch(`/api/ai_rewrites`, {
            method: 'POST',
            credentials: 'include',
            headers: getPostHeaders(),
            body
        }).then((response) => {
            if (response.status === 504) {
                throw new Error(i18n.t('AI_REWRITE_ERROR'))
            }
            if (response.status === 401) {
                const message = i18n.t('SESSION_EXPIRED_MESSAGE')
                removeSessionStorageItem('selectedStatusFilter')
                removeSessionStorageItem('selectedCommitteeFilter')
                emitter.emit('locked', { message })
                return Promise.reject(response)
            }
            const data = response.json()

            return data
        })
    } catch (error) {
        throw new Error(i18n.t('AI_REWRITE_ERROR'))
    }
}
