import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import { css, ClassNames } from '@emotion/react'
import {
    circularLoaderStyles,
    CircularProgress,
    NewDialogContent
} from 'components/shared/StyledComponents'
import { AtlasModal, AtlasModalTitle } from 'components/atlas-components/atlas-modal/atlas-modal'
import { AtlasButtonOld } from 'components/atlas-material/button'
import { CircularProgressProps } from '@material-ui/core'
//================ Redux ====================================
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../reducers/applicationReducer'
import selectors from '../../selectors/applicationSelectors'
import {
    errorMessage,
    dialogBodyCss,
    GlobalprogressDefaultWordingCss,
    GlobalProgressTitleCss
} from 'components/shared/FullscreenProgress'
import { StyledAtlasButtonOld } from 'components/minutetaker/components/ActionItemEditDialog/ActionItemEditDialog'

type Props = {
    actions: {
        toggleProgress: (...args: any[]) => void
        cancelBlockingOperation: (...args: any[]) => void
        resetLastError: (...args: any[]) => void
        loadPlatformInfo: (...args: any[]) => void
        [x: string]: (...args: any[]) => void
    }
    controlState: {
        title: string
        message: string
        isVisible: boolean
        retryAction: () => void | null
        hideActions: boolean
    }
    isFirstLoad?: boolean
    lastError: string
}

type DialogDetails = {
    dialogTitle: JSX.Element | null
    dialogMessageTitle: string
    dialogMessage: string | JSX.Element
    modalWidth: 'xs' | 'md'
}

function GlobalProgressModal(props: Props) {
    const [showingRealError, setShowingRealError] = useState(false)
    const onCancel = () => {
        props.actions.toggleProgress(false, null, null)
        props.actions.cancelBlockingOperation()

        setShowingRealError(false)
    }

    const onRetry = () => {
        if (props.controlState.retryAction) {
            props.actions.resetLastError()
            props.controlState.retryAction()
            setShowingRealError(false)
        } else {
            onCancel()
        }
    }

    const onErrorDoubleClick = () => {
        setShowingRealError(!showingRealError)
    }

    const getDialogActions = () => {
        const okButton = ({ secondary }: { secondary: boolean }) => (
            <ClassNames>
                {({ css }) => (
                    <StyledAtlasButtonOld
                        key="cancel-btn"
                        dataTestId="ProgressModal_CancelBtn"
                        className={css`
                            span {
                                display: flex;
                                justify-content: center;
                            }
                        `}
                        secondary={secondary}
                        onClick={onCancel}>
                        {dialogMessageTitle === i18n.t('GLOBAL_PROGRESS_TITLE')
                            ? translate('CANCEL')
                            : translate('CLOSE')}
                    </StyledAtlasButtonOld>
                )}
            </ClassNames>
        )

        //As of 1.3 we now need to support cases where retry button needs to be hidden.
        //null action will be indicator for that.
        if (
            props.lastError &&
            props.controlState.retryAction &&
            props.controlState.retryAction?.()
        ) {
            const retryButton = (
                <AtlasButtonOld
                    key="retry-btn"
                    onClick={onRetry}
                    dataTestId="ProgressModal_RetryBtn">
                    {translate('RETRY')}
                </AtlasButtonOld>
            )

            return [okButton({ secondary: true }), retryButton]
        }

        if (!props.isFirstLoad) {
            return okButton({ secondary: false })
        }

        return []
    }

    const getDialogDetails = (): DialogDetails => {
        if (props.isFirstLoad) {
            return {
                dialogTitle: null,
                dialogMessageTitle: '',
                dialogMessage: <CircularProgress size={70} thickness={4} color="primary" />,
                modalWidth: 'xs'
            }
        }

        if (props.lastError) {
            const dialogMessage = showingRealError
                ? props.lastError
                : i18n.t('GLOBAL_PROGRESS_ERROR_MESSAGE')
            return {
                dialogTitle: i18n.t('GLOBAL_PROGRESS_ERROR'),
                dialogMessageTitle: '',
                dialogMessage,
                modalWidth: 'md'
            }
        }

        return {
            dialogTitle: <></>,
            dialogMessageTitle: i18n.t('GLOBAL_PROGRESS_TITLE'),
            dialogMessage: i18n.t('GLOBAL_PROGRESS_TITLE_DEFAULT_WORDING'),
            modalWidth: 'xs'
        }
    }
    const { dialogTitle, dialogMessageTitle, dialogMessage, modalWidth } = getDialogDetails()
    const { isVisible, hideActions } = props.controlState

    const dialogActions = hideActions ? <></> : getDialogActions()
    return (
        <ClassNames>
            {({ css }) => (
                <AtlasModal
                    className={css`
                        ${dialogZIndexCls};
                    `}
                    open={isVisible}
                    actions={dialogActions}
                    maxWidth={modalWidth}>
                    <AtlasModalTitle
                        style={{
                            minHeight:
                                dialogMessageTitle === i18n.t('GLOBAL_PROGRESS_TITLE')
                                    ? '64px'
                                    : '84px'
                        }}
                        {...props}
                        center
                        onClose={onCancel}>
                        {dialogTitle}
                    </AtlasModalTitle>
                    <NewDialogContent
                        className={css`
                            overflow-y: hidden;
                            padding: ${dialogMessageTitle === i18n.t('GLOBAL_PROGRESS_TITLE')
                                ? '10px 24px'
                                : '24px 24px'};
                        `}>
                        {dialogMessageTitle === i18n.t('GLOBAL_PROGRESS_TITLE') ? (
                            <section
                                className={css`
                                    ${dialogBodyCss}
                                `}>
                                <CircularProgressLoader />
                                <span
                                    className={css`
                                        ${GlobalProgressTitleCss}
                                    `}>
                                    {dialogMessageTitle}
                                </span>
                                <span
                                    className={css`
                                        ${GlobalprogressDefaultWordingCss} ${errorMessage}
                                    `}
                                    onDoubleClick={onErrorDoubleClick}>
                                    {dialogMessage}
                                </span>
                            </section>
                        ) : (
                            <span onDoubleClick={onErrorDoubleClick}>{dialogMessage}</span>
                        )}
                    </NewDialogContent>
                </AtlasModal>
            )}
        </ClassNames>
    )
}
export function CircularProgressLoader(props: CircularProgressProps) {
    const classes = circularLoaderStyles()
    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={54}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle
                }}
                size={54}
                thickness={4}
                {...props}
            />
        </div>
    )
}

const Z_INDEX = 1501
const dialogZIndexCls = css`
    z-index: ${Z_INDEX};
    div div {
        max-width: 440px;
        max-height: 100%;
    }
`

//================ Export / Redux Bindings ==================

//Note: we listen to data changes just so dialog could auto resize itself
//in case more dates added and a need to have scroll is added.
const mapStateToProps = (state, _) => {
    return {
        lastError: selectors.lastError(state.applicationReducer),
        controlState: selectors.fullscreenProgressData(state.applicationReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalProgressModal))
