/**
 * This is the Minutes List Context.
 * This context helps maintain page state on a specific location.pathname
 * This should only be used to initialize component state.
 * It should not be used to maintain state throughout the component's lifetime.
 */
import { useState, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import constate from 'constate'
import { find, pathOr, partition } from 'rambdax'
import emitter from 'common/util/events'
// Types
type MinutesManagerPageState = {
    statusFilter?: DocumentStatusFilter
    expandedCommittees?: string[]
}

//type MinutesTakerPageState = {}

type RouteState = MinutesManagerPageState // | MinutesTakerPageState
type RouteStateEntry = {
    key: string
    state: RouteState
}
type SetRouteState<S> = S | ((prevState: S) => S)
type Dispatch = (value: SetRouteState<RouteState>) => void
// Minutes List Hook
function useRouteStateHook() {
    /* * * STATE * * */
    const [routeStateList, setRouteStateList] = useState<RouteStateEntry[]>([])
    const location = useLocation<Record<string, any>>()
    const history = useHistory()
    const routeKey = location.pathname
    const currentRouteState = getRouteState(routeKey, routeStateList)
    useEffect(() => {
        emitter.on('notFound', () => {
            history.push('/not-found')
        })
    }, [history])
    /* * * ACTIONS * * */
    const setRouteState = useCallback<Dispatch>(
        (routeState): void => {
            setRouteStateList(createSetRouteState(routeState, routeKey))
        },
        [routeKey]
    )

    // Consolidate state and actions for the Constate factory to use
    const routeStateState = {
        currentRouteState,
        routeStateList
    }
    const routeStateActions = {
        setRouteState
    }
    return {
        routeStateState,
        routeStateActions
    }
}

/* Functions */
const createSetRouteState = (state: SetRouteState<RouteState>, key: string) => (
    prevRouteStateList: RouteStateEntry[]
) => {
    const [allOtherRoutes, prevRouteStateEntry] = partition(
        (r) => r.key !== key,
        prevRouteStateList
    )
    const prevRoutsState = pathOr({}, '0.state', prevRouteStateEntry)

    // If setRouteState() was passed a function call it with the previous Route State
    const newRouteState = typeof state === 'function' ? state(prevRoutsState) : state

    return [...allOtherRoutes, { key, state: newRouteState }]
}

const getRouteState = (key: string, routeStateList: RouteStateEntry[]): RouteState => {
    try {
        const routeStateListItem = find((r) => r.key === key, routeStateList) || { state: {} }
        return routeStateListItem.state || {}
    } catch (e) {
        console.log(e)
        return {}
    }
}

/* Create Context */

// Context factory returns the useable (Provider, useStateHook, useActionsHook)
const [RouteStateProvider, useRouteState, useRouteStateActions] = constate(
    useRouteStateHook,
    (value) => {
        return value.routeStateState
    },
    (value) => {
        return value.routeStateActions
    }
)

// Export (Provider, useStateHook, useActionsHook)
export { RouteStateProvider, useRouteState, useRouteStateActions }
