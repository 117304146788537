import React from 'react'
import { Box } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import {
    getCanDuplicate,
    getCanExportMinutes
} from 'components/minutes-card/minutes-card-functions'
import { pathOr } from 'rambdax'
import { DOCUMENT_STATUS } from 'components/minutetaker/components/InvitationView/types'

export const statusData = (status, classes) => {
    switch (status) {
        case DOCUMENT_STATUS.IN_REVIEW:
            return (
                <>
                    <Box className={classes.inReviewBox}></Box>
                    <Box> {i18n.t('IN_REVIEW')}</Box>
                </>
            )
        case DOCUMENT_STATUS.DRAFT:
            return (
                <>
                    <Box className={classes.inDraftBox}></Box>
                    <Box> {i18n.t('DRAFT')}</Box>
                </>
            )
        case DOCUMENT_STATUS.FINAL_DRAFT:
            return (
                <>
                    <Box className={classes.finalDraftBox}></Box>
                    <Box> {i18n.t('FINAL_DRAFT')}</Box>
                </>
            )
        case DOCUMENT_STATUS.APPROVED:
            return (
                <>
                    <Box className={classes.approvedBox}></Box>
                    <Box> {i18n.t('APPROVED')}</Box>
                </>
            )
        default:
            return null
    }
}

enum HeadCellID {
    Title = 'title',
    CommitteeName = 'committee_name',
    MeetingDate = 'meeting_date',
    UpdatedAt = 'updated_at',
    Status = 'status',
    Options = 'options'
}
interface HeadCell {
    id: HeadCellID
    label: string
}

// i18n type guard
const translate = (key: string): string => i18n.t(key)

// Main function
export const headCells = (canAddMinutes: boolean): HeadCell[] => {
    const commonItems: HeadCell[] = [
        { id: HeadCellID.Title, label: translate('MEETING_NAME') },
        { id: HeadCellID.CommitteeName, label: translate('COMMITTEE') },
        { id: HeadCellID.MeetingDate, label: translate('MEETING_DATE') },
        { id: HeadCellID.UpdatedAt, label: translate('LAST_MODIFIED') },
        { id: HeadCellID.Status, label: translate('ACTION_ITEM_STATUS') }
    ]

    // Conditional items
    const additionalItems: HeadCell[] = canAddMinutes
        ? [{ id: HeadCellID.Options, label: translate('OPTIONS') }]
        : []

    return [...commonItems, ...additionalItems]
}
export const mapDocumentListsForTable = (documentLists, isAdminOfCommittee) => {
    let isAdmin = false
    const documentsData = documentLists.map((minutesList, index) => {
        const labelId = `enhanced-table-checkbox-${index}`
        const minutesId = pathOr(undefined, 'id', minutesList)
        const documentStatus = pathOr('', 'status', minutesList) as DocumentStatusKey
        const canDuplicate = getCanDuplicate(documentStatus)
        const canExportMinutes = getCanExportMinutes(documentStatus)
        if (isAdminOfCommittee(minutesList.committee_id) && !!minutesId) {
            isAdmin = true
        }
        return {
            ...minutesList,
            labelId,
            minutesId,
            documentStatus,
            canDuplicate,
            canExportMinutes
        }
    })
    return {
        documentsData,
        isAdmin
    }
}
