import { getJSON } from 'common/util/request'
import { makeFetcher } from 'businesslayer/networkrequests/swr-util'
import useSWR from 'swr'

export const getBookDetails = (bookId, committeeId) => {
    return getJSON(`committees/${committeeId}/books/${bookId}`)
}

export default getBookDetails

export const useBookDetailsSWR = (
    bookId: string | number | null | undefined,
    committeeId: string | number | null | undefined,
    swrOptionsOverride?: ConfigInterface,
    fetcherOptionsOverride?: FetcherOptions
) => {
    const path = `committees/${committeeId}/books/${bookId}`
    const conditionalPath = !!bookId ? path : null
    const fetcherOptions = { shouldNormalize: true, ...fetcherOptionsOverride }

    const fetcher = makeFetcher(fetcherOptions)
    const result = useSWR(conditionalPath, fetcher, swrOptionsOverride)

    // If no bookId given, return undefined instead of cache
    if (!bookId) {
        return { ...result, data: undefined }
    }

    return result
}
