//TODO: Very barebones implementation, should be able to pass in env variables at build time. Should be able to pull from api
const { _env_ } = window as any

export const initFlags = (): Flags => {
    return {
        /**
         * isTabletRestricted
         * Owner: Minutes Devs
         * Obsolete Date: When tablets are no longer restricted from accessing Minutes App
         */
        isTabletRestricted: _env_.RESTRICT_TABLET === 'true',

        /**
         * isStaticRoleMode
         * Owner: Andrew English
         * Obsolete Date: When a single Manager Page that
         *   accommodates both admin and reviewer Committee Roles
         *   is implemented
         */
        isStaticRoleMode: true,

        /**
         * isAtlas
         * Owner: Andrew English
         * Obsolete Date: When Atlas integration is complete
         */
        isAtlas: true
    }
}

export type Flags = {
    isTabletRestricted: boolean
    isStaticRoleMode: boolean
    isAtlas: boolean
}
