import React from 'react'
import Modal from 'react-modal'

//================ Components Material =======================

//================ Components ===============================
import styled from '@emotion/styled'
import i18n from 'i18next'
import { Button } from './StyledComponents'
import { ColorButtonHover, ColorEastBay } from 'assets/styles/variables'
import { makeStyles } from '@material-ui/core/styles'
import { doLogin } from 'businesslayer/sso/SsoManagerFactoryService'
import DiligentErrorIcon from 'assets/icons/DiligentErrorIcon'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { getSessionStorageItem, setLocalStorageItem } from 'businesslayer/minutesLocalStore'
import { ProgressSpinner } from './ProgressSpinner'

declare global {
    interface Window {
        cefQuery: (...args: any[]) => void
        handleReauthenticate?: (sessionToken: string) => void
    }
}
//#region Styles

//#endregion

//#region Props
type Props = {
    isExchanging?: boolean
    message?: string | React.ReactNode
}
//#endregion

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: `${ColorEastBay}`,
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        height: '40px',
        width: '79px',
        borderRadius: '2px',
        textAlign: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: `${ColorButtonHover}`
        },
        color: '#FFFFFF'
    }
}))
const getUrlPath = (): string => {
    const url = window.location.href
    const urlObj = new URL(url)
    return urlObj.pathname
}
//#region Implementation
const manageLogin = () => {
    // update landing path
    setLocalStorageItem('landingPath', getUrlPath())
    if (
        transientStorageManager.platform === 'boardeffect' &&
        window._env_.ENABLE_OIDC_LOGIN_BOARD_EFFECT === 'true'
    ) {
        const committeeId = getSessionStorageItem('currentCommitteeId')
        window.location.href = `${transientStorageManager.parentAppUrl}/extensions/minutes?committee_id=${committeeId}`
    } else {
        doLogin()
    }
}

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root')

export const LockedModal = ({ message, isExchanging }: Props) => {
    const classes = useStyles()
    const renderMessageBody = () => {
        if (isExchanging) {
            return (
                <>
                    <ProgressSpinner size={70} thickness={4} />
                    <div>{i18n.t('AUTHENTICATING')}</div>
                </>
            )
        }

        if (!!message) {
            return <div data-testid="LockedModal_Message">{message}</div>
        }

        return null
    }
    const platform = getSessionStorageItem('parentPlatform')
    const authTokens = getSessionStorageItem('authToken')
    const renderStyledBody = () => {
        if (
            (authTokens === 'false') &&
            message !== undefined &&
            message !== '' &&
            message !== null &&
            (platform === 'boardswebadmin' || platform === 'boardswebdirector')
        ) {
            return (
                <StyledBody>
                    <div style={sessionExpiredText}>{i18n.t('SESSION_TIME_OUT')}</div>
                    <div style={signInText}>
                        {platform === 'boardswebadmin' || platform === 'boardswebdirector'
                            ? i18n.t('SESSION_TIME_OUT_BODY_BOARD')
                            : i18n.t('SESSION_TIME_OUT_BODY_BOARD_EFFECT')}
                    </div>
                    <Button className={classes.customButton} onClick={manageLogin}>
                        {i18n.t('SIGN_IN')}
                    </Button>
                </StyledBody>
            )
        } else {
            return <StyledBody>{renderMessageBody()}</StyledBody>
        }
    }

    return (
        <StyledModal isOpen={true} style={customStyles}>
            <StyledLogoWrapper>
                <DiligentErrorIcon />
            </StyledLogoWrapper>
            {renderStyledBody()}
        </StyledModal>
    )
}

//#endregion

const StyledModal = styled(Modal)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: 0;
`

const StyledLogoWrapper = styled('section')`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const StyledBody = styled('section')`
    color: #2a455a;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    font-family: Source Sans Pro;
    color: #1e1e1e;
    gap: 16px;
`
const customStyles = {
    overlay: {
        zIndex: 1000
    }
}

const sessionExpiredText = {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '32px',
    height: '32px'
}

const signInText = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Source Sans Pro',
    color: '#1E1E1E',
    marginBottom: '25px'
}

export default LockedModal
