import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
`

type Props = {
    title?: string
    className?: string
}

const FoldIcon = ({ title, className }: Props) => (
    <Svg viewBox="0 0 9.5 14.6" width="1em" height="1em" className={className}>
        <title id='new-section-title'>{title}</title>
        <path d="M9.5 7.3c0 .2-.1.5-.3.7l-7 6.4c-.4.3-.9.3-1.3-.1-.3-.3-.3-.9.1-1.2l6.3-5.7-7-5.8C-.1 1.3-.1.7.2.3c.3-.4.9-.4 1.3-.1l7.7 6.4c.2.2.3.4.3.7z" />
    </Svg>
)

export default FoldIcon
