
export const controlState = (state, objectData) => {

    const controlState = {...state.controlState, ...objectData}
    const result = {
                ...state,
                controlState
            }

    return result
}

export const dataState = (state, objectData) => {

    const dataState = {...state.dataState, ...objectData}
    const result = {
                ...state,
                dataState
            }

    return result
}

export const communicationState = (state, objectData) => {

    const communicationState = {...state.communicationState, ...objectData}
    const result = {
                ...state,
                communicationState
            }

    return result
}

export const debounce = (callback, wait, context = this) => {
  let timeout = null 
  let callbackArgs = null
  
  const later = () => callback.apply(context, callbackArgs)
  
  return function() {
    callbackArgs = arguments
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}