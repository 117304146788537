import { pathOr } from 'rambdax'
import { translate } from 'components/shared/internationalization'
import moment from 'moment'
import { formatMeetingDate } from 'common/formatters/date'
import { determineLastDate, determineFirstDate } from 'common/util/date'

export const mapMinutes = (id) => ({ minutesList, dateFormat, timeFormat }) => {
    //This is all temp code which needs adjustment once real objects are read.
    const minutesData = minutesList[id]
    const isLinked: boolean = !!pathOr(false, ['relationships', 'book', 'data'], minutesData)

    const date = formatMeetingDate({
        date: minutesData.attributes.meetingDates,
        dateFormat
    })

    const lastMeetingDate = determineLastDate({ dates: minutesData.attributes.meetingDates })
    const firstMeetingDate = determineFirstDate({
        dates: minutesData.attributes.meetingDates
    })

    const author = getAuthor(minutesData)
    const title = pathOr('', ['attributes', 'title'], minutesData)
    const attendees: string[] = pathOr([], ['attributes', 'attendees'], minutesData).map(
        (attendee: Attendee) => attendee.display_name
    )
    const createdAt = pathOr('', ['attributes', 'createdAt'], minutesData)
    const updatedAt = pathOr('', ['attributes', 'updatedAt'], minutesData)
    const actionSummary = pathOr('', ['attributes', 'actionItemsData'], minutesData)
    const bookId = pathOr('', ['relationships', 'book', 'data', 'id'], minutesData)
    const updateDate = moment(minutesData.attributes.updatedAt).format(
        determineDateFormat({ dateFormat, timeFormat })
    )
    const updatedBy = translate('UPDATED_BY', {
        by: minutesData.attributes.updatedBy || '',
        date: updateDate
    })
    const status = pathOr('', ['attributes', 'status'], minutesData)

    return {
        id,
        title,
        attendees,
        date,
        dateCreated: moment(createdAt).format(determineDateFormat({ dateFormat, timeFormat })),
        dateUpdated: moment(updatedAt).format(dateFormat),
        lastMeetingDate,
        firstMeetingDate,
        actionSummary,
        isLinked,
        linkedTo: '',
        author,
        bookId,
        status,
        updatedBy
    }
}

export const getAuthor = (minutesData: object): string =>
    pathOr('', ['attributes', 'author', 'display_name'], minutesData)

const determineDateFormat = (props: { dateFormat: string; timeFormat?: string }): string => {
    const { dateFormat = 'MMMM DD, YYYY', timeFormat } = props
    return [dateFormat, timeFormat].filter(Boolean).join(' ')
}
export const mapSectionNameById = (sectionId, sections) =>
    pathOr('', ['name'], sections.find((section) => +section.id === sectionId))

export const mapSectionOrderById = (sectionId, sections) =>
    pathOr('', ['order'], sections.find((section) => +section.id === sectionId))
