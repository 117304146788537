import React from 'react'
import PopoverButton from 'components/shared/PopoverButton'
import { Menu, MenuItem } from '@material-ui/core'
import { AtlasIcon } from 'web-components/atlas-icon'
import { translate } from 'components/shared/internationalization'
import styled from '@emotion/styled'
import { countlyEvent } from 'businesslayer/Countly'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { AtlasButton } from 'components/atlas-material/button'
import { css } from '@emotion/css'
import { ColorBaseSkyBlue } from 'assets/styles/variables'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { actions as editorActions } from 'reducers/minuteTakerReducer'
import { EDITOR_TABS } from 'components/minutetaker/components/InvitationView/types'

type Props = {
    isVisible?: boolean
    canDuplicate?: boolean
    canExportMinutes?: boolean
    onClickOptions: (optionValue: OptionType) => void
    actions: {
        currentSelectedTab: (...args: any[]) => void
    }
}

const MinutesCardOverflow = (props: Props) => {
    const handleCardOverflowClick = (optionId, popoverOnClose) => {
        // Open the Actions tab when the user clicks on the Actions option
        if (optionId === 2) {
            props.actions.currentSelectedTab(EDITOR_TABS.ACTIONS)
        }
        popoverOnClose(optionId)
        const clickedOption = OPTIONS.find((o) => +o.id === +optionId)
        !!clickedOption && props.onClickOptions(clickedOption.value)
    }

    const isDisabled = (optionValue: OptionType) => {
        if (optionValue === 'EXPORT_MINUTES' && !props.canExportMinutes) return true
        if (optionValue === 'EXPORT_MINUTES_WITH_ACTIONS' && !props.canExportMinutes) return true
        if (optionValue === 'DUPLICATE' && !props.canDuplicate) return true

        return false
    }
    const { platform } = transientStorageManager
    const canPlatformExport = platform !== 'boardsios'
    return props.isVisible ? (
        <PopoverButton>
            {({ onShowPopover, isOpen, anchorElement, onClosePopover }) => {
                return (
                    <>
                        <AtlasButton
                            data-testid="MinutesManager_Overflow_Btn"
                            tabIndex={0}
                            className="label-small-link"
                            disableFocusRipple
                            disableRipple
                            onClick={onShowPopover}>
                            <AtlasIcon name="more" size={24} />
                        </AtlasButton>
                        <Menu
                            open={isOpen}
                            anchorEl={anchorElement}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            onClose={onClosePopover}>
                            {OPTIONS.filter(optionsFilter(canPlatformExport)).map((option) => (
                                <MenuItem
                                    key={option.id}
                                    className={css`
                                        &:focus {
                                            border: 2px solid ${ColorBaseSkyBlue};
                                            border-radius: 2px;
                                        }
                                        &:hover {
                                            background-color: #ebeef2;
                                            border-radius: 2px;
                                        }
                                    `}
                                    data-testid={option.testid}
                                    disabled={isDisabled(option.value)}
                                    onClick={() => {
                                        option.recordClickWithCountly()
                                        handleCardOverflowClick(option.id, onClosePopover)
                                    }}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )
            }}
        </PopoverButton>
    ) : (
        <Spacer />
    )
}

const Spacer = styled.div`
    width: 24px;
    height: 24px;
`

const optionsFilter = (canExport: boolean) => (option) => {
    if (!canExport) {
        // Don't show export options
        const isExportOption = [
            'EXPORT_MINUTES',
            'EXPORT_MINUTES_WITH_ACTIONS',
            'EXPORT_ACTIONS',
            'EXPORT_CONSOLIDATED_ACTIONS'
        ].includes(option.value)

        return isExportOption ? false : true
    }
    return true
}

export type OptionType =
    | 'EDIT'
    | 'ACTIONS'
    | 'DUPLICATE'
    | 'EXPORT_MINUTES'
    | 'EXPORT_MINUTES_WITH_ACTIONS'
    | 'EXPORT_ACTIONS'
    | 'EXPORT_CONSOLIDATED_ACTIONS'
    | 'DELETE'

const OPTIONS: any[] = [
    {
        id: 1,
        value: 'EDIT',
        testid: 'MinutesCardOverflow_Edit',
        label: translate('EDIT_PROPERTIES'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_EditProperties')
        }
    },
    {
        id: 2,
        value: 'ACTIONS',
        testid: 'MinutesCardOverflow_ViewActions',
        label: translate('OPEN_ACTIONS'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_ViewActions')
        }
    },
    {
        id: 3,
        value: 'DUPLICATE',
        testid: 'MinutesCardOverflow_Duplicate',
        label: translate('DUPLICATE'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_Duplicate')
        }
    },
    {
        id: 4,
        value: 'EXPORT_MINUTES',
        testid: 'MinutesCardOverflow_ExportMinutesOnly',
        label: translate('EXPORT_POPOVER_MINUTES_ONLY'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_ExportOptions', { exportOption: 'minutesOnly' })
        }
    },
    {
        id: 5,
        value: 'EXPORT_ACTIONS',
        testid: 'MinutesCardOverflow_ExportActions',
        label: translate('EXPORT_POPOVER_ACTIONS_ONLY'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_ExportOptions', { exportOption: 'actionsOnly' })
        }
    },
    {
        id: 6,
        value: 'EXPORT_MINUTES_WITH_ACTIONS',
        testid: 'MinutesCardOverflow_ExportMinutesWithActions',
        label: translate('EXPORT_POPOVER_MINUTES_AND_ACTIONS'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_ExportOptions', { exportOption: 'minutesWithActions' })
        }
    },
    {
        id: 7,
        value: 'EXPORT_CONSOLIDATED_ACTIONS',
        testid: 'MinutesCardOverflow_ExportActionsModal',
        label: translate('EXPORT_CONSOLIDATED_ACTIONS'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_ExportOptions', { exportOption: 'consolidatedActions' })
        }
    },
    {
        id: 8,
        value: 'DELETE',
        testid: 'MinutesCardOverflow_Delete',
        label: translate('DELETE'),
        recordClickWithCountly: () => {
            countlyEvent('Mgr_DeleteButton')
        }
    }
]

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    const { currentSelectedTab } = editorActions
    return {
        actions: bindActionCreators(
            {
                currentSelectedTab
            },
            dispatch
        )
    }
}

const MinutesCardOverflowComp = connect(null, mapDispatchToProps)(MinutesCardOverflow)

export { MinutesCardOverflowComp as MinutesCardOverflow }
