import i18n from 'common/util/internationalization'
import {
    CHAT_BOT,
    UseChatBotAIConnectorProps
} from 'components/minutetaker/components/InvitationView/types'
import { removeBrackets } from 'components/minutetaker/components/InvitationView/util'
import { useCallback, useRef, useState } from 'react'

const useChatBoardAiConnector = (props: UseChatBotAIConnectorProps) => {
    const stopFetchingRef = useRef(false)
    const [isWriting, setIsWriting] = useState<boolean>(false)
    const [isExecutionComplete, setIsExecutionComplete] = useState<boolean>(false)
    const [chatMessages, setChatMessages] = useState<string>('')
    const [convertError, setConvertError] = useState<string>('')
    const [ischatBotMessageContainer, setIschatBotMessageContainer] = useState<boolean>(true)
    const [inputValue, setInputValue] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const bottomRef = useRef<HTMLDivElement>(null)

    const handleSendMessage = useCallback(() => {
        if (inputValue.trim() !== '') {
            props.actions.setChatMessages({ message: inputValue, sender: CHAT_BOT.USER })
            stopFetchingRef.current = false

            setIsWriting(true)
        }
    }, [inputValue, props.actions])

    const fetchChatData = useCallback(
        async (messagesToSend: ChatPayload) => {
            // calling boards ai connctor service
            try {
                const controller = new AbortController()
                setAbortController(controller)
                const response = await fetch(
                    `${props.identityToken?.connector_api_url}/api/help/inquiry`,
                    {
                        method: 'POST',
                        signal: controller.signal,
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${props.identityToken?.connector_token}`
                            // Authorization: `Bearer ${authtoken}`
                        },

                        body: JSON.stringify(messagesToSend)
                    }
                )
                if (!response.ok) {
                    setIsWriting(false)
                    setErrorMessage(i18n.t('CHAT_ERROR_MESSAGE'))
                }
                const reader = response.body?.getReader()

                if (!reader) {
                    throw new Error('ReadableStream not supported')
                }

                while (true) {
                    const { done, value } = await reader.read()

                    setIsExecutionComplete(done)
                    if (done) {
                        break
                    }

                    if (stopFetchingRef.current) {
                        controller.abort() // stop the api call on click of stop generating button

                        break
                    }

                    const chunkString = new TextDecoder('utf-8').decode(value) //handling chunk responses
                    const str = removeBrackets(`${chunkString}`) //converting into valid json

                    const formatedJson: ChatResponse[] = JSON.parse(str)
                    formatedJson.forEach((obj) => {
                        setChatMessages((prev) => prev + obj.message)
                    })
                }
            } catch (error) {
                setConvertError(error)
            }
        },
        [props.identityToken.connector_api_url, props.identityToken.connector_token]
    )
    const handleClick = useCallback(
        (e: any) => {
            const messagesToSend = {
                context: { application: CHAT_BOT.MINUTES },
                question: inputValue
            }
            if (!!errorMessage) {
                setErrorMessage('')
            }

            setInputValue('')
            e.preventDefault()

            handleSendMessage()

            //   // Call the fetch function
            fetchChatData(messagesToSend)
        },
        [fetchChatData, handleSendMessage, inputValue, errorMessage]
    )

    const handleStopButtonClick = () => {
        // Abort the ongoing API call
        stopFetchingRef.current = true
        setIsWriting(false)
        if (abortController) {
            //if you make another api call quickly after canceling the previous one the new api results comes with some previous api responses due to async nature of fetch opertaion
            abortController.abort()
            setAbortController(null) // so to handle the scenario we need to clear the previous response
        }
    }
    return {
        handleClick,
        isWriting,
        isExecutionComplete,
        chatMessages,
        handleStopButtonClick,
        setChatMessages,
        setIsWriting,
        setIsExecutionComplete,
        setIschatBotMessageContainer,
        ischatBotMessageContainer,
        inputValue,
        setInputValue,
        bottomRef,
        errorMessage,
        convertError
    }
}
export default useChatBoardAiConnector
