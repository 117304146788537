import React from 'react'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    customSortIcon: {
        right: theme.spacing(1), // Using theme spacing
        transition: 'transform 0.2s ease-in-out',
        display: 'grid',
        paddingLeft: '15px'
    }
}))
export const CustomSortIcon: React.FC<{ isAscending: boolean; selected: boolean }> = React.memo(
    ({ isAscending, selected }) => {
        const classes = useStyles()

        const ascendingClassName = isAscending && selected
        const descendingClassName = !isAscending && selected

        return (
            <div className={classes.customSortIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                    <path
                        id="Path"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.29289 0.292893C3.65338 -0.0675907 4.22061 -0.0953203 4.6129 0.209705L4.70711 0.292893L7.70711 3.29289C8.09763 3.68342 8.09763 4.31658 7.70711 4.70711C7.34662 5.06759 6.77939 5.09532 6.3871 4.7903L6.29289 4.70711L4 2.415L1.70711 4.70711C1.34662 5.06759 0.779392 5.09532 0.387101 4.7903L0.292893 4.70711C-0.0675907 4.34662 -0.0953203 3.77939 0.209705 3.3871L0.292893 3.29289L3.29289 0.292893Z"
                        fill={ascendingClassName ? '#1E1E1E' : '#949494'}
                    />
                    <path
                        id="Path Copy"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.70711 13.7071C4.34662 14.0676 3.77939 14.0953 3.3871 13.7903L3.29289 13.7071L0.292893 10.7071C-0.0976305 10.3166 -0.0976305 9.68342 0.292893 9.29289C0.653378 8.93241 1.22061 8.90468 1.6129 9.2097L1.70711 9.29289L4 11.585L6.29289 9.29289C6.65338 8.93241 7.22061 8.90468 7.6129 9.2097L7.70711 9.29289C8.06759 9.65338 8.09532 10.2206 7.7903 10.6129L7.70711 10.7071L4.70711 13.7071Z"
                        fill={descendingClassName ? '#1E1E1E' : '#949494'}
                    />
                </svg>
            </div>
        )
    }
)
export const CustomSortIconsObject = {
    sortAscending: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
    <path
        fill="#1E1E1E"
        d="M3.29289 0.292893C3.65338 -0.0675907 4.22061 -0.0953203 4.6129 0.209705L4.70711 0.292893L7.70711 3.29289C8.09763 3.68342 8.09763 4.31658 7.70711 4.70711C7.34662 5.06759 6.77939 5.09532 6.3871 4.7903L6.29289 4.70711L4 2.415L1.70711 4.70711C1.34662 5.06759 0.779392 5.09532 0.387101 4.7903L0.292893 4.70711C-0.0675907 4.34662 -0.0953203 3.77939 0.209705 3.3871L0.292893 3.29289L3.29289 0.292893Z"
    />
    <path
        fill="#949494"
        d="M4.70711 13.7071C4.34662 14.0676 3.77939 14.0953 3.3871 13.7903L3.29289 13.7071L0.292893 10.7071C-0.0976305 10.3166 -0.0976305 9.68342 0.292893 9.29289C0.653378 8.93241 1.22061 8.90468 1.6129 9.2097L1.70711 9.29289L4 11.585L6.29289 9.29289C6.65338 8.93241 7.22061 8.90468 7.6129 9.2097L7.70711 9.29289C8.06759 9.65338 8.09532 10.2206 7.7903 10.6129L7.70711 10.7071L4.70711 13.7071Z"
    />
</svg>`,
    sortDescending: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
    <path
        fill="#949494"
        d="M3.29289 0.292893C3.65338 -0.0675907 4.22061 -0.0953203 4.6129 0.209705L4.70711 0.292893L7.70711 3.29289C8.09763 3.68342 8.09763 4.31658 7.70711 4.70711C7.34662 5.06759 6.77939 5.09532 6.3871 4.7903L6.29289 4.70711L4 2.415L1.70711 4.70711C1.34662 5.06759 0.779392 5.09532 0.387101 4.7903L0.292893 4.70711C-0.0675907 4.34662 -0.0953203 3.77939 0.209705 3.3871L0.292893 3.29289L3.29289 0.292893Z"
    />
    <path
        fill="#1E1E1E"
        d="M4.70711 13.7071C4.34662 14.0676 3.77939 14.0953 3.3871 13.7903L3.29289 13.7071L0.292893 10.7071C-0.0976305 10.3166 -0.0976305 9.68342 0.292893 9.29289C0.653378 8.93241 1.22061 8.90468 1.6129 9.2097L1.70711 9.29289L4 11.585L6.29289 9.29289C6.65338 8.93241 7.22061 8.90468 7.6129 9.2097L7.70711 9.29289C8.06759 9.65338 8.09532 10.2206 7.7903 10.6129L7.70711 10.7071L4.70711 13.7071Z"
    />
</svg>`
}
