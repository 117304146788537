import { DialogActionsClassKey, DialogActionsProps } from '@material-ui/core/DialogActions'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiDialogActions: Partial<StyleRules<DialogActionsClassKey, DialogActionsProps>> = {
    root: {
        height: '90px',
        padding: '0px 24px',
        '& *': {
            margin: '8px'
        }
    }
}
