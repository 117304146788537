import React from 'react'
import { MenuItem } from '@material-ui/core'
import i18n from 'i18next'
import { ColorBaseSkyBlue } from 'assets/styles/variables'
import { css } from '@emotion/css'

export const statusMenuItems = (): JSX.Element[] => {
    // t() will not be in scope if we define this outside the function
    const statusFilterItemList: StatusFilterItem[] = [
        { label: i18n.t('ALL_STATUSES'), value: 'all' },
        { label: i18n.t('DRAFT'), value: 'draft' },
        { label: i18n.t('IN_REVIEW'), value: 'in_review' },
        { label: i18n.t('FINAL_DRAFT'), value: 'final_draft' },
        { label: i18n.t('APPROVED'), value: 'approved' }
    ]

    return statusFilterItemList.map((m, idx) => {
        return (
            <MenuItem
                className={css`
                    &:focus {
                        border: 2px solid ${ColorBaseSkyBlue};
                        border-radius: 2px;
                    }
                `}
                key={idx}
                value={m.value}
                data-testid="StatusSelect_FilterItem">
                {m.label}
            </MenuItem>
        )
    })
}
type StatusFilterItem = { label: string; value: DocumentStatusFilter }
