import textValidator from './textValidator'
import objectErrorHelper from './objectErrorHelper'
import { transientStorageManager } from '../minutesSessionStore'
import i18n from 'i18next'
import { getDateMaskText } from 'common/formatters/date'
import moment from 'moment'
const maxAttendeesAllowed = 100

const validateRowConsistency = (meetingDate) => {
    objectErrorHelper.removeValidationError(meetingDate, 'meetingStartTime')
    objectErrorHelper.removeValidationError(meetingDate, 'meetingEndTime')
    objectErrorHelper.removeValidationError(meetingDate, 'meetingDate')
    objectErrorHelper.removeValidationError(meetingDate, 'meetingTimeZone')

    const all = meetingDate.startTime && meetingDate.timeZoneCode && meetingDate.date
    const nothing =
        !meetingDate.startTime &&
        !meetingDate.endTime &&
        !meetingDate.timeZoneCode &&
        !meetingDate.date

    if (all || nothing) {
        objectErrorHelper.removeValidationError(meetingDate, 'meetingDateRow')
    } else {
        objectErrorHelper.addValidationError(
            meetingDate,
            'meetingDateRow',
            i18n.t('INVALID_MINUTES_DATE_ROW')
        )

        if (!meetingDate.startTime) {
            objectErrorHelper.addValidationError(meetingDate, 'meetingStartTime', '')
        }

        if (!meetingDate.endTime) {
            objectErrorHelper.addValidationError(meetingDate, 'meetingEndTime', '')
        }

        if (!meetingDate.date) {
            objectErrorHelper.addValidationError(meetingDate, 'meetingDate', '')
        }

        if (!meetingDate.timeZoneCode) {
            objectErrorHelper.addValidationError(meetingDate, 'meetingTimeZone', '')
        }
    }
}

const validateDateMask = (dateObject) => {
    objectErrorHelper.removeValidationError(dateObject, 'meetingDateFormat')

    if (!dateObject.dateText && dateObject.date) {
        //Date is there so it is valid situation
        return
    }

    if (dateObject.dateText) {
        const { dateFormatInput, isDateInputMaskUS } = transientStorageManager
        if (moment(dateObject.dateText, dateFormatInput, true).isValid()) {
            objectErrorHelper.removeValidationError(dateObject, `meetingDateFormat`)

            dateObject.date = moment(dateObject.dateText, dateFormatInput).toDate()
        } else {
            const maskText = getDateMaskText(isDateInputMaskUS)
            objectErrorHelper.addValidationError(
                dateObject,
                `meetingDateFormat`,
                i18n.t('INVALID_MINUTES_DATE_FORMAT', { mask: maskText })
            )
        }
    }
}

const validateMinMaxDate = (dateObject) => {
    objectErrorHelper.removeValidationError(dateObject, 'meetingDateMinMax')

    if (dateObject.dateText && moment(dateObject.dateText).isValid()) {
        if (
            moment(dateObject.dateText)
                .utc()
                .isBefore('1899-12-30', 'day')
        ) {
            objectErrorHelper.addValidationError(
                dateObject,
                `meetingDateMinMax`,
                i18n.t('MINIMUM_DATE_MESSAGE')
            )
        } else if (
            moment(dateObject.dateText)
                .utc()
                .isAfter('2100-01-01', 'day')
        ) {
            objectErrorHelper.addValidationError(
                dateObject,
                `meetingDateMinMax`,
                i18n.t('MAXIMUM_DATE_MESSAGE')
            )
        } else {
            objectErrorHelper.removeValidationError(dateObject, `meetingDateMinMax`)
        }
    }
}

const validateStartTimeMask = (dateObject) => {
    objectErrorHelper.removeValidationError(dateObject, 'meetingStartTimeFormat')

    if (!dateObject.startTimeText && dateObject.startTime) {
        //startTime is there so it is valid situation
        return
    }

    if (!transientStorageManager.timeFormat) {
        return
    }

    if (dateObject.startTimeText) {
        const mask = transientStorageManager.timeFormat.indexOf('HH') >= 0 ? 'HH:mm' : 'hh:mm a'

        if (moment(dateObject.startTimeText, mask, true).isValid()) {
            objectErrorHelper.removeValidationError(dateObject, `meetingStartTimeFormat`)

            dateObject.startTime = moment(dateObject.startTimeText, mask).toDate()
        } else {
            const maskText =
                transientStorageManager.timeFormat.indexOf('HH') >= 0
                    ? i18n.t('NON_USA_TIME_MASK')
                    : i18n.t('USA_TIME_MASK')
            objectErrorHelper.addValidationError(
                dateObject,
                `meetingStartTimeFormat`,
                i18n.t('INVALID_MINUTES_TIME_FORMAT', { mask: maskText })
            )
        }
    }
}

const validateEndTimeMask = (dateObject) => {
    objectErrorHelper.removeValidationError(dateObject, 'meetingEndTimeFormat')

    if (!dateObject.endTimeText && dateObject.endTime) {
        //startTime is there so it is valid situation
        return
    }

    if (!transientStorageManager.timeFormat) {
        return
    }

    if (dateObject.endTimeText) {
        const mask = transientStorageManager.timeFormat.indexOf('HH') >= 0 ? 'HH:mm' : 'hh:mm a'

        if (moment(dateObject.endTimeText, mask, true).isValid()) {
            objectErrorHelper.removeValidationError(dateObject, `meetingEndTimeFormat`)

            dateObject.endTime = moment(dateObject.endTimeText, mask).toDate()
        } else {
            const maskText =
                transientStorageManager.timeFormat.indexOf('HH') >= 0
                    ? i18n.t('NON_USA_TIME_MASK')
                    : i18n.t('USA_TIME_MASK')
            objectErrorHelper.addValidationError(
                dateObject,
                `meetingEndTimeFormat`,
                i18n.t('INVALID_MINUTES_TIME_FORMAT', { mask: maskText })
            )
        }
    }
}

export default class minuteItemValidator {
    static validateMinuteItem(initialObject) {
        const targetObject = Object.assign({}, initialObject)

        //TODO: Refactor in the text validator
        if (!textValidator.isLengthValid(targetObject.title, 1, 1024)) {
            if (targetObject.title.trim()) {
                targetObject.title = targetObject.title.substr(
                    0,
                    Math.min(targetObject.title.length, 1024)
                )
            } else {
                objectErrorHelper.addValidationError(
                    targetObject,
                    'title',
                    i18n.t('INVALID_MINUTES_TITLE.required')
                )
            }
        } else {
            objectErrorHelper.removeValidationError(targetObject, 'title')
        }

        //Just cutting length
        if (targetObject.location) {
            targetObject.location = targetObject.location.substr(
                0,
                Math.min(targetObject.location.length, 255)
            )
        }

        //Validate consistency of date row
        targetObject.meetingDates.forEach((meetingDate) => {
            validateRowConsistency(meetingDate)
        })

        //Truncate extra attendees:

        if (targetObject.attendees && targetObject.attendees.length > maxAttendeesAllowed) {
            targetObject.attendees = targetObject.attendees.slice(0, maxAttendeesAllowed)
        }

        return targetObject
    }

    static validateMeetingDate(minuteItem, dateObject) {
        // Always convert to Date: When setting first time the date will be moment, when editing date will be Date
        if (moment.isMoment(dateObject.startTime)) {
            dateObject.startTime = dateObject.startTime.toDate()
        }
        if (moment.isMoment(dateObject.endTime)) {
            dateObject.endTime = dateObject.endTime.toDate()
        }

        validateDateMask(dateObject)
        validateMinMaxDate(dateObject)
        validateStartTimeMask(dateObject)
        validateEndTimeMask(dateObject)

        if (dateObject.startTime || dateObject.endTime || dateObject.date) {
            if (dateObject.timeZoneCode === null) {
                dateObject.timeZoneCode = transientStorageManager.currentTimezone
            }
        }

        validateRowConsistency(dateObject)

        //End time should be larger than start time
        if (dateObject.startTime && dateObject.endTime) {
            const startMinutes =
                dateObject.startTime.getHours() * 60 + dateObject.startTime.getMinutes()
            const finishMinutes =
                dateObject.endTime.getHours() * 60 + dateObject.endTime.getMinutes()

            if (startMinutes > finishMinutes) {
                objectErrorHelper.addValidationError(
                    dateObject,
                    `meetingDates`,
                    i18n.t('INVALID_MINUTES_START_FINISH_TIMES')
                )
            } else {
                objectErrorHelper.removeValidationError(dateObject, `meetingDates`)
            }
        }
        if (dateObject.startTime && !dateObject.endTime) {
            objectErrorHelper.removeValidationError(dateObject, `meetingDates`)
        }

        return dateObject
    }
}
