import useSWR, { mutate } from 'swr'
import { makeFetcher } from 'businesslayer/networkrequests/swr-util'

export const useMinutesDetailSWR = (
    minutesId,
    swrOptionsOverride?: ConfigInterface,
    fetcherOptionsOverride?: FetcherOptions
) => {
    const url = `minutes_documents/fetchone/${minutesId}`
    const fetcherOptions = { fetcherCallback, ...fetcherOptionsOverride }

    const fetcher = makeFetcher(fetcherOptions)
    return useSWR<Attendee[]>(url, fetcher, swrOptionsOverride)
}
const fetcherCallback = (response): MinutesUnnormalized => {
    const minutesDetails = response.data
    return !!minutesDetails ? { id: minutesDetails.id, ...minutesDetails.attributes } : undefined
}

export const minutesDetailsMutator = (minutesId, minutes, shouldRevalidate) => {
    const path = `minutes_documents/fetchone/${minutesId}`
    // Remove Minutes
    if (!minutes) {
        mutate(path, undefined, shouldRevalidate)
    }
    // Update minutes
    else {
        const minutesData = fetcherCallback({ data: minutes })
        mutate(path, minutesData, shouldRevalidate)
    }
}
