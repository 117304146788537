import React from 'react'

type Props = {
    title?: JSX.Element
    className?: string
}

export const WarningAlertIcon = ({ className }: Props) => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.750968 16.5C0.193431 16.5 -0.169191 15.9133 0.0801473 15.4146L7.33015 0.91459C7.60654 0.361803 8.3954 0.361803 8.67179 0.91459L15.9218 15.4146C16.1711 15.9133 15.8085 16.5 15.251 16.5H0.750968ZM7.25097 6.5H8.75097V11.5H7.25097V6.5ZM8.00097 14C8.41518 14 8.75097 13.6642 8.75097 13.25C8.75097 12.8358 8.41518 12.5 8.00097 12.5C7.58675 12.5 7.25097 12.8358 7.25097 13.25C7.25097 13.6642 7.58675 14 8.00097 14Z"
            fill="#EAA14B"
        />
    </svg>
)

export default WarningAlertIcon
