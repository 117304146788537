import { headers } from 'common/util/requestUtils'
import ky, { Options } from 'ky'

const addHeadersBeforeRequest = (options: Options) => {
    options.headers = headers.post()
}

const api = ky.extend({
    prefixUrl: '/api',
    retry: 1,
    hooks: {
        beforeRequest: [addHeadersBeforeRequest]
    },
    timeout: 1000
})

export const log = ({ message }: { message: string }) => {
    const controller = new AbortController()
    const { signal } = controller
    return api.post('log', { json: { message }, signal }).catch((_) => controller.abort())
}
