import textValidator from './textValidator'
import objectErrorHelper from './objectErrorHelper'
import { transientStorageManager } from '../minutesSessionStore'

import i18n from 'i18next'
import { isDate, format } from 'date-fns'

const maxAssigneesAllowed = 100

const validateDateMask = (dateObject) => {
    objectErrorHelper.removeValidationError(dateObject, 'dateFormat')

    if (!dateObject.dateText && dateObject.dueDate) {
        //Date is there so it is valid situation
        return
    }

    if (dateObject.dateText) {
        const mask = transientStorageManager.isDateInputMaskUS ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
        if (isDate(dateObject.dateText)) {
            objectErrorHelper.removeValidationError(dateObject, `dateFormat`)

            dateObject.dueDate = format(dateObject.dateText, mask)
        } else {
            objectErrorHelper.addValidationError(
                dateObject,
                `dateFormat`,
                i18n.t('INVALID_MINUTES_DATE_FORMAT', { mask: mask })
            )
        }
    }
}

export default class takerActionValidator {
    static validateAction(initialObject) {
        const targetObject = Object.assign({}, initialObject)

        //We may have server lags sending more than 5 megs of data
        const maxLength = 1024 * 1024 * 5
        if (!textValidator.isLengthValid(targetObject.text, 1, maxLength)) {
            if (targetObject.text.trim()) {
                targetObject.title = targetObject.text.substr(
                    0,
                    Math.min(targetObject.text.length, maxLength)
                )
            } else {
                objectErrorHelper.addValidationError(
                    targetObject,
                    'text',
                    i18n.t('INVALID_ACTION_TEXT')
                )
            }
        } else {
            objectErrorHelper.removeValidationError(targetObject, 'text')
        }

        validateDateMask(targetObject)

        //Truncate extra assignees:

        if (targetObject.assignees && targetObject.assignees.length > maxAssigneesAllowed) {
            targetObject.assignees = targetObject.assignees.slice(0, maxAssigneesAllowed)
        }

        //Test for non empty section
        //-1 value means there will be new section created
        if (!targetObject.minutesSectionId && targetObject.minutesSectionId !== -1) {
            objectErrorHelper.addValidationError(
                targetObject,
                'section',
                i18n.t('INVALID_ACTION_SECTION')
            )
        } else {
            objectErrorHelper.removeValidationError(targetObject, 'section')
        }
        return targetObject
    }
}
