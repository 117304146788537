import React, { FC, useMemo, useState } from 'react'
import { FileUploader } from 'components/file-uploader/file-uploader'
import { FormSpy, Field } from 'react-final-form'
import { Container, marginBetweenChildren, EllipsisCls } from 'assets/sharedStyles'
import { pathOr } from 'rambdax'
import { AtlasSelectAdapter } from 'components/atlas-material/select'
import { logoMenuItems, DEFAULT_LOGO_ITEM } from 'components/popovers/logo-select/logo-select-items'
import styled from '@emotion/styled'
import i18n from 'common/util/internationalization'
import { AtlasStatusError } from 'assets/styles/variables'
import { validateMinutesLogoField } from 'common/util/validators/minutes-properties/minutes-properties-validators'
import { MINUTES_EDIT_OPTIONS } from 'components/minutetaker/components/InvitationView/types'

const MAX_FILE_SIZE = 1024 * 1024 * 15
const LOGO_TYPE_REGEX = /\.(jpe?g|png|gif|tiff)$/i

type Props = {
    setLogoFields: ({
        logo_filename,
        logo,
        include_logo,
        logoFilename
    }: {
        logo_filename: string
        logo: string
        include_logo: boolean
        logoFilename: string
    }) => void
    editMode: any
}

export const LogoField: FC<Props> = ({ setLogoFields, editMode }) => {
    const [fileError, setFileError] = useState(null)
    const memoLogoMenuItems = useMemo(() => logoMenuItems(), [])

    const onFileUploaded = (file, reader) => {
        setLogoFields({
            logo_filename: file.name,
            logo: reader.result,
            include_logo: false,
            logoFilename: file.name
        })
    }
    const onFileMismatch = () => {
        setFileError(i18n.t('FILE_MISMATCH_ERROR'))
        setTimeout(() => setFileError(null), 3000)
    }

    const onFileTooLarge = () => {
        setFileError(i18n.t('FILE_SIZE_ERROR'))
        setTimeout(() => setFileError(null), 3000)
    }

    return (
        <LogoFieldWrapper>
            <Container width={197}>
                <Field
                    name={`logoType`}
                    render={AtlasSelectAdapter}
                    label={i18n.t('LOGO_LABEL')}
                    data-testid="MinutesProperties_Logo"
                    role="combobox"
                    id="logo"
                    ariaLabelledby="logo"
                    validate={validateMinutesLogoField}
                    children={memoLogoMenuItems}
                    error={null}
                    showErrorWhen={true}
                />
            </Container>
            <FormSpy
                render={({ values }) => {
                    const logoType = pathOr<LogoOption>(DEFAULT_LOGO_ITEM, 'logoType', values)
                    const isChooseFileDisabled = logoType !== 'UPLOAD_LOGO'
                    const logoFileName =
                        editMode === MINUTES_EDIT_OPTIONS.EDIT ||
                        editMode === MINUTES_EDIT_OPTIONS.DUPLICATE
                            ? values.logoFilename
                            : values.logo_filename
                    const logoFileNameLabel =
                        !isChooseFileDisabled && !!logoFileName && !!values.logo ? logoFileName : ''
                    return (
                        <LogoUploadWrapper>
                            <FileUploader
                                disabled={isChooseFileDisabled}
                                fileTypeRegex={LOGO_TYPE_REGEX}
                                onFileMismatch={onFileMismatch}
                                onFileTooLarge={onFileTooLarge}
                                onFileUploaded={onFileUploaded}
                                maxSize={MAX_FILE_SIZE}
                            />
                            <LogoFileName isError={!!fileError}>
                                {fileError || logoFileNameLabel}
                            </LogoFileName>
                        </LogoUploadWrapper>
                    )
                }}
            />
        </LogoFieldWrapper>
    )
}

const LogoFieldWrapper = styled.div`
    display: flex;
    ${marginBetweenChildren('24px', 'div')}
    overflow-x: hidden;
    p {
        margin-left: 0px;
    }
`
const LogoUploadWrapper = styled.div`
    display: flex;
    flex: 1 1 0;
    align-items: center;
    ${marginBetweenChildren('24px')}
    overflow-x: hidden;
`
const LogoFileName = styled.div<{ isError: boolean }>`
    ${EllipsisCls}
    color: ${({ isError }) => (!!isError ? AtlasStatusError : 'inherit')};
    overflow-x: hidden;
`
