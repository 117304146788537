import React from 'react'
import styled from '@emotion/styled'
import { translate } from 'components/shared/internationalization'
import { ColorBaseGreyDark } from 'assets/styles/variables'

const Svg = styled('svg')`
    fill: ${ColorBaseGreyDark};
`

type Props = {
    title?: JSX.Element
    className?: string
}

export const AttendiesIcon = ({ title = translate('ATTENDEES'), className }: Props) => (
    <Svg viewBox="0 0 12.67 15" aria-hidden="true" className={className}>
        <title>{title}</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="side_bar" data-name="side bar">
                <g id="MIN_TAKER_subtabs" data-name="MIN TAKER subtabs">
                    <g id="v5_-_fave" data-name="v5 - fave">
                        <g id="attendees">
                            <g id="attendees-2" data-name="attendees">
                                <path d="M12.67,15H0v-.4c0-3.33,2.66-5.74,6.34-5.74s6.33,2.41,6.33,5.74Z" />
                                <path d="M6.34,7.18A3.59,3.59,0,1,1,9.92,3.59,3.59,3.59,0,0,1,6.34,7.18Z" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </Svg>
)
