import i18n from 'i18next'

export const DefualtToolbar = {
    items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'findAndReplace',
        '|',
        'bold',
        'italic',
        'underline',
        {
            label: 'More basic styles',
            icon: 'threeVerticalDots',
            items: ['strikethrough', 'superscript', 'subscript', 'link']
        },
        '|', 
        'Highlight',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'insertTable',
        '|',
        'alignment',
        '|',
        'indent',
        'outdent',
        '|',
        'removeFormat',
        '|',
        'AIbutton'
    ]
}
export const TrackChangesToolbar = {
    items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'findAndReplace',
        '|',
        'bold',
        'italic',
        'underline',
        {
            label: 'More basic styles',
            icon: 'threeVerticalDots',
            items: ['strikethrough', 'superscript', 'subscript', 'link']
        },
        '|',
        'Highlight',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'insertTable',
        '|',
        'comment',
        'alignment',
        '|',
        'indent',
        'outdent',
        '|',
        'removeFormat'
    ]
}

export const ToolbarOptions = {
    heading: {
        options: [
            { model: 'paragraph', title: i18n.t('NORMAL'), class: 'ck-heading_paragraph' },
            {
                model: 'heading1',
                view: 'h1',
                title: i18n.t('HEADING_ONE'),
                class: 'ck-heading_heading1'
            },
            {
                model: 'heading2',
                view: 'h2',
                title: i18n.t('HEADING_TWO'),
                class: 'ck-heading_heading2'
            },
            {
                model: 'heading3',
                view: 'h3',
                title: i18n.t('HEADING_THREE'),
                class: 'ck-heading_heading3'
            },
            {
                model: 'heading4',
                view: 'h4',
                title: i18n.t('HEADING_FOUR'),
                class: 'ck-heading_heading4'
            },
            {
                model: 'heading5',
                view: 'h5',
                title: i18n.t('HEADING_FIVE'),
                class: 'ck-heading_heading5'
            },
            {
                model: 'heading6',
                view: 'h6',
                title: i18n.t('HEADING_SIX'),
                class: 'ck-heading_heading6'
            }
        ]
    },
    highlight: {
        options: [
            {
                model: 'yellowMarker',
                class: 'marker-yellow',
                title: 'Yellow marker',
                color: '#FFFF00',
                type: 'marker'
            },
            {
                model: 'greenMarker',
                class: 'marker-green',
                title: 'Green Marker',
                color: '#00FF00',
                type: 'marker'
            },
            {
                model: 'redMarker',
                class: 'marker-red',
                title: 'Red marker',
                color: '#f50404',
                type: 'marker'
            },
            {
                model: 'cyanMarker',
                class: 'marker-cyan',
                title: 'Cyan marker',
                color: '#00FFFF',
                type: 'marker'
            },
            {
                model: 'magentaMarker',
                class: 'marker-magenta',
                title: 'Magenta marker',
                color: '#FF00FF',
                type: 'marker'
            },
            {
                model: 'redPen',
                class: 'pen-red',
                title: 'Red pen',
                color: '#f50404',
                type: 'pen'
            },
            {
                model: 'greenPen',
                class: 'pen-green',
                title: 'Green pen',
                color: '#00FF00',
                type: 'pen'
            }
        ]
    },
    /*list: { //Enable this comment to activete the multiple list feature
        properties: {
            styles: false
        }
    },*/
    typing: {
        transformations: {
            include: [
                // Use only the 'quotes' and 'typography' groups.
                'quotes',
                'typography',
                'mathematical',
                'symbols'
            ],

            extra: [
                // You can also define patterns using regular expressions.
                // Note: The pattern must end with `$` and all its fragments must be wrapped
                // with capturing groups.
                // The following rule replaces ` "foo"` with ` «foo»`.
                {
                    from: /(^|\s)(")([^"]*)(")$/,
                    to: [null, '«', null, '»']
                },

                // Finally, you can define `to` as a callback.
                // This (naive) rule will auto-capitalize the first word after a period, question mark, or an exclamation mark.
                {
                    from: /([.?!] )([a-z])$/,
                    to: (matches) => [null, matches[1].toUpperCase()]
                }
            ]
        }
    }
}
