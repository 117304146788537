import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionItemListContainer } from './ActionSummaryListContainer'
import { actions as editorActions } from '../../../../reducers/actionEditorReducer'
import { actions } from '../../../../reducers/minuteActionItemsReducer'
import { actions as takerActions } from '../../../../reducers/minuteTakerReducer'
import takerSelectors from '../../../../selectors/minuteTakerSelectors'

const mapStateToProps = (state, _) => {
    return {
        actionItems: takerSelectors.minutesActions(state.minuteTakerReducer),
        minutesSections: takerSelectors.minutesSections(state.minuteTakerReducer),
        dateFormat: takerSelectors.dateFormat(state.minuteTakerReducer),
        currentMinuteItem: takerSelectors.currentMinuteItem(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        resetSort,
        selectActionItem,
        handleActionStatusChanged,
        sortActions,
        saveExistingAction
    } = { ...actions, ...takerActions, ...editorActions }

    return {
        actions: bindActionCreators(
            {
                resetSort,
                selectActionItem,
                handleActionStatusChanged,
                sortActions,
                saveExistingAction
            },
            dispatch
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ActionItemListContainer)
)
