import styled from '@emotion/styled'
import { Button as StyledButton } from 'components/shared/StyledComponents'
import { ColorBaseBlueMedium } from 'assets/styles/variables'

export const Button = styled(StyledButton)`
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    text-transform: none;
    height: 50px;
    text-align: left;
    font-size: 17px;
    padding: 0.25rem 0.75rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${ColorBaseBlueMedium};
`
