import { AtlasGreyLight } from 'assets/styles/variables'
import { DialogTitleClassKey, DialogTitleProps } from '@material-ui/core/DialogTitle'
import { CSSProperties, StyleRules } from '@material-ui/core/styles/withStyles'
import { atlasTitleMedium } from 'components/atlas-components/typography'
import { omit } from 'rambdax'

export const MuiDialogTitle: Partial<StyleRules<DialogTitleClassKey, DialogTitleProps>> = {
    root: {
        ...atlasTitleMedium,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '64px',
        minHeight: '64px',
        borderBottom: `2px solid ${AtlasGreyLight}`,
        padding: '0 23px',
        // Remove the following when Roboto is not a global font
        '& *': {
            // Don't force the color on every child
            ...omit<CSSProperties>('color', atlasTitleMedium)
        }
    }
}
