import React, { useEffect, useState } from 'react'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'

//================ Components Material =======================
import SvgIcon from '@material-ui/core/SvgIcon'

//================ Components ===============================
import { translate } from 'components/shared/internationalization'
import { css, ClassNames } from '@emotion/react'
import LinearIndeterminate from 'components/shared/LinearProgress'

//#region Implementation
const LogoIcon = (props) => (
    <SvgIcon
        viewBox="0 0 640 640"
        {...props}
        className={css`
            width: ${props.height / 2}px;
            height: ${props.height / 2}px;
        `}>
        <path
            d="M61.466 596.46s62.072-.063 204.463 0c20.103 0 47.407-.9 63.792-2.994 51.74-6.592 89.263-23.658 119.014-40.758 29.216-16.79 64.03-47.222 89.342-92.577L410.025 231.02 61.465 596.46z"
            fill="#931a1e"
        />
        <path
            d="M458.72 93.552C404.112 56.787 335.043 43.54 281.454 43.54c-114.047 0-219.943.017-219.943.017l348.514 187.46 100.266-91.7c-15.733-18.32-33.345-33.497-51.57-45.765"
            fill="#ed3430"
        />
        <path
            d="M538.077 460.13c20.48-36.697 34.745-83.142 34.745-140.135 0-79.262-26.206-138.375-62.533-180.68L410.023 231.02l128.053 229.11z"
            fill="#bb2327"
        />
        <path d="M61.508 43.556l-.045 552.904 348.56-365.44L61.507 43.557z" fill="#cd2028" />
    </SvgIcon>
)

function NotFound(_: RouteComponentProps) {
    const [navigatingOut, setNavigatingOut] = useState(false)
    const isReloaded = sessionStorage.getItem('reloaded') === 'false'
    useEffect(() => {
        if (isReloaded) {
            sessionStorage.setItem('reloaded', 'true')
            window.location.reload()
        }
    }, [isReloaded])

    if (navigatingOut) {
        return (
            <Redirect
                to={{
                    pathname: '/',
                    state: {}
                }}
            />
        )
    }

    if (isReloaded) {
        return <LinearIndeterminate loading={true} />
    }
    return (
        <ClassNames>
            {({ css }) => (
                <div style={{ width: '100%' }}>
                    <section
                        className={css`
                            ${headerCss}
                        `}>
                        <section
                            className={css`
                                ${logoCss}
                            `}>
                            <LogoIcon height={90} />
                            {translate('WINDOW_TITLE')}
                        </section>
                    </section>
                    <section
                        className={css`
                            ${bodyCss}
                        `}>
                        {translate('NOT_FOUND_TITLE')}
                        <br />
                        <div
                            className={css`
                                ${errorMessageCss}
                            `}>
                            {translate('NOT_FOUND_MESSAGE')}
                            <button
                                onClick={() => setNavigatingOut(true)}
                                className={css`
                                    ${errorLinkCss}
                                `}>
                                {translate('NOT_FOUND_LINK')}
                            </button>
                        </div>
                    </section>
                </div>
            )}
        </ClassNames>
    )
}
//#endregion

export default withRouter(NotFound)

const headerCss = css`
    background-color: #2a455a;
    color: #fff;
    height: 100px;
    text-align: left;
    width: 100%;
`
const bodyCss = css`
    color: #2a455a;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    flex-direction: column;
    padding: 20px;
    margin-top: 100px;
`

const logoCss = css`
    color: #fff;
    height: 100px;
    display: flex;
    align-items: center;
    font-size: x-large;
    flex-direction: row;
    padding: 20px;
    margin-left: 20px;
`

const errorMessageCss = css`
    font-size: medium;
    max-width: 650px;
    text-align: center;
    color: #2a455a;
    line-height: 22px;
`

const errorLinkCss = css`
    text-decoration: none;
    color: #ea2031;
    line-height: 22px;
    font-size: medium;
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
`
