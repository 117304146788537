import React from 'react'
import { MenuItem } from '@material-ui/core'
import i18n from 'common/util/internationalization'

export type LogoOption = 'DEFAULT_LOGO' | 'UPLOAD_LOGO' | 'NONE'
type LogoItem = { id: LogoOption; label: string }

/**
 * Recommended to memoize the result when using this
 * Ex: useMemo(() => timezoneMenuItems(), []))
 */
export const logoMenuItems = (): JSX.Element[] => {
    const items: LogoItem[] = [
        { id: 'NONE', label: i18n.t('LOGO_SELECT_ITEMS.NONE') },
        { id: 'DEFAULT_LOGO', label: i18n.t('LOGO_SELECT_ITEMS.DEFAULT_LOGO') },
        { id: 'UPLOAD_LOGO', label: i18n.t('LOGO_SELECT_ITEMS.UPLOAD_LOGO') }
    ]

    const menuItems = items.map((val, index) => {
        return (
            <MenuItem
                key={val.id + index}
                value={val.id}
                className='dropDownFocus'
                data-testid={`LinkedBookStructureSelect_Option`}>
                {val.label}
            </MenuItem>
        )
    })

    return menuItems
}

export const DEFAULT_LOGO_ITEM = 'DEFAULT_LOGO'
