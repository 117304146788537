import React from 'react'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import i18n from 'i18next'
import { v4 as uuid } from 'uuid'
import PersonChip from 'components/person-chip/person-chip'
import { UserChip } from 'components/shared/UserChip'
import { ChipInputComponents } from './types'
import { EllipseString } from 'assets/sharedStyles'
import { path } from 'rambdax'

export function NoOptionsMessage(props: any) {
    return props.hasValue ? (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>
            {i18n.t('PREVIOUSLY_ADDED')}
        </Typography>
    ) : null
}

export function inputComponent({ inputRef, ...props }: { inputRef: any }) {
    return <div ref={inputRef} {...props} />
}

export function Control(props: any) {
    const isAtlas = !!path('isAtlas', props.selectProps)
    // The notched prop only exists if it's an atlas component
    // so we can destructure the notchedProp to conditionally add it
    // based on isAtlas.
    const notchedProp = isAtlas ? { notched: false } : {}
    return (
        <TextField
            data-testid="ChipInput_MUITextField"
            fullWidth
            InputProps={{
                inputComponent,
                ...notchedProp,
                inputProps: {
                    className: props.selectProps.classes.input,
                    // inputRef: props.innerRef,
                    // TODO: Find a better way access input element
                    inputRef: (elem) => {
                        !!elem &&
                            elem
                                .getElementsByTagName('input')[0]
                                .setAttribute('data-testid', 'ChipInput_InputField')
                        // for testing-library
                        !!elem &&
                            elem
                                .getElementsByTagName('input')[0]
                                .setAttribute('data-testid', 'ChipInput_InputField')
                        props.innerRef(elem)
                    },
                    children: props.children,
                    ...props.innerProps
                }
            }}
            {...props.selectProps.textFieldProps}
        />
    )
}

export function Option(props: any) {
    const backgroundColor = props.isFocused || props.isSelected ? '#EBEEF2' : 'inherit'
    return (
        <MenuItem
            key={uuid()} //props.data.text.toString()}
            data-testid={`ChipInputOption_${props.data.id}`}
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{ backgroundColor }}
            {...props.innerProps}>
            <EllipseString>
                {!!props.data.id ? props.children : `${i18n.t('CREATE')} "${props.children}"`}
            </EllipseString>
        </MenuItem>
    )
}

export function Placeholder(props: any) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

export function SingleValue(props: any) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

export function ValueContainer(props: any) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

export const OldMultiValue = (props: any) => {
    return (
        <UserChip
            className={props.selectProps.classes.chip}
            name={props.data.text}
            email={props.data.email}
            onDelete={props.removeProps.onClick}
        />
    )
}

export const AtlasMultiValue = (props: any) => {
    return (
        <PersonChip
            className={props.selectProps.classes.chip}
            name={props.data.text}
            email={props.data.email}
            onDelete={props.removeProps.onClick}
        />
    )
}

export function Menu(props: any) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    )
}

const IndicatorsContainer = () => {
    return <span />
}

export const chipInputComponents: ChipInputComponents = {
    Control,
    Menu,
    MultiValue: OldMultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorsContainer
}
