//Purpose of this class is to provide interface for every reducer to switch application into a different route
//This is done via application reducer which controlls state of the application (current route)

import { push } from 'connected-react-router'

let store = null

export default class applicationRouter {
    static setStore = (newStore) => {
        store = newStore
        //Use: store.dispatch(actions.nameOfTheAction(data))
    }

    static navigateTo(route) {
        setTimeout(() => store.dispatch(push(route)), 5)
    }
}
