import React, { useEffect } from 'react'

import emitter from 'common/util/events'
import UnsupportedModal from 'components/shared/UnsupportedModal'
import { useFlag } from 'components/contexts/flag/Flag'
import { useCommitteesState } from 'components/contexts/committee-context'
import { isDeviceRestricted } from 'common/util/user-agent'
import { checkValidPlatform } from 'common/util/platform'

type Props = {
    platform: Platform
    inProgress?: boolean
    children: any
    onLoadedSuccessful: (...args: any) => void
}
/**
 * Conditional rendering of the App and and it's lock states
 */
export const AppLock = (props: Props) => {
    const isTabletRestricted = useFlag('isTabletRestricted')
    const { loading: loadingCommittees, hasAccessibleCommittees } = useCommitteesState()

    useEffect(() => {
        props.onLoadedSuccessful()
    })
    // Block mobile devices
    if (isDeviceRestricted(navigator.userAgent, isTabletRestricted)) {
        return <UnsupportedModal />
    }

    const isValidPlatform = checkValidPlatform(props.platform)
    const noCommitteeAccess = !loadingCommittees && !hasAccessibleCommittees
    // App is locked probably from not being authorized
    if (noCommitteeAccess || !isValidPlatform) {
        // Add Message in 2.3
        // const message = noCommitteeAccess ? '' : props.lockedMessage
        emitter.emit('locked')
        return null
    }

    // Not all data is retrieved yet but we are working on it (committee id)
    if (props.inProgress || loadingCommittees) {
        return null
    }

    // A callback to let the App know we are ready to render the App

    return props.children
}
