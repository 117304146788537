import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    stroke: #303a48;
    fill: #303a48;
`

type Props = {
    title?: string
    className?: string
}

const LeftCaretIcon = ({ title, ...props }: Props) => (
    <Svg width="1em" height="1em" viewBox="0 0 10 15" {...props}>
        <title>{title}</title>
        <path
            d="M.945 7.542c0 .22.094.431.262.581l6.331 5.681a.814.814 0 0 0 1.136-.05.776.776 0 0 0-.05-1.112L2.969 7.57l6.259-5.103a.778.778 0 0 0 .103-1.109.814.814 0 0 0-1.13-.102L1.235 6.937a.779.779 0 0 0-.29.587v.018z"
            fill="#303A48"
            stroke="#303A48"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </Svg>
)

export default LeftCaretIcon
