import { FontWeightRegular, FontWeightBold, FontWeightMedium } from 'assets/styles/variables'
import React, { SFC } from 'react'
import styled from '@emotion/styled'
import { color, fontSize, fontWeight, lineHeight, space, style } from 'styled-system'

const userSelect = style({
    prop: 'userSelect',
    cssProperty: 'userSelect'
})

const cursor = style({
    prop: 'cursor',
    cssProperty: 'cursor'
})

const textTransform = style({
    prop: 'textTransform',
    cssProperty: 'textTransform'
})

// const wordBreak = style({
//     prop: 'wordBreak',
//     cssProperty: 'wordBreak'
// })

export const Text = styled('span')`
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${color}
  ${userSelect}
  ${cursor}
  ${textTransform}
` as any

Text.defaultProps = {
    fontSize: '1rem',
    wordBreak: 'break-all'
} as any

export const LeadText = Text.withComponent('span') as any

LeadText.defaultProps = {
    fontWeight: 'bold'
} as any

type Headings = 'h1' | 'h2' | 'h3' | 'h4'
type Bodies = 'body1' | 'body2' | 'body3'

export const Heading: SFC<{ as: Headings; className?: string }> = ({
    as = 'h1',
    ...props
}: {
    as: Headings
    className?: string
}) => {
    const Tag = Text.withComponent(as)

    Tag.defaultProps = {
        m: '0 0 0 0'
    } as any

    const fontSize = ['h1', 'h2'].includes(as) ? '1.25rem' : '1rem'
    const fontWeight = ['h2', 'h4'].includes(as) ? FontWeightMedium : FontWeightBold

    return <Tag fontSize={fontSize} fontWeight={fontWeight} {...props} />
}

export const Title = styled(Text)`
    font-size: 1.5rem;
    font-weight: ${FontWeightRegular};
`

export const Subtitle = styled(Text)<{ type: 'subtitle1' | 'subtitle2' }>`
    font-size: '0.9375rem';
    font-weight: ${({ type }) =>
        ['subtitle1'].includes(type) ? FontWeightMedium : FontWeightBold};
`

export const Body = styled(Text)<{ type?: Bodies }>`
    font-size: ${({ type = 'body1' }) => {
        if (['body2'].includes(type)) {
            return '0.75rem'
        } else if (['body3'].includes(type)) {
            return '0.625rem'
        } else {
            // body1 | default
            return '0.9375rem'
        }
    }};
    font-weight: ${FontWeightRegular}; 
    ${space} 
    ${lineHeight} 
    ${color} 
    ${userSelect} 
    ${cursor}  
    ${textTransform} 
`

export const Button = styled(Text)`
    font-weight: ${FontWeightMedium};
    font-size: '1.0625rem';
`

export const Footer = styled(Text)`
    font-style: italic;
    font-size: '0.6875rem';
`
