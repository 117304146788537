import React, { useRef } from 'react'

//#region DnD

import { useDrop } from 'react-dnd'
import { ClassNames } from '@emotion/react'

//#endregion

//#region Props

type Props = {
    onSectionReorder: (...args: any[]) => void
    sectionCount: number
}

//#endregion

//#region Implementation

//That is a special component which is last item in the list view.
//It is designed to provide an area for drag and drop below the list
function MinuteEditorListViewFooter(props: Props) {
    const ref = useRef(null)

    const [{ isOver }, drop] = useDrop({
        accept: 'section',
        collect: (monitor) => ({
            isOver: !!monitor.canDrop() && !!monitor.isOver()
        }),
        canDrop: (item: any) => {
            return item.index !== props.sectionCount
        },
        drop: (item: any) => {
            props.onSectionReorder(item.index, null)
        }
    })
    drop(ref)
    const markup = (
        <ClassNames>
            {({ css }) => (
                <div
                    ref={ref}
                    className={css`
                        background-color: transparent;
                        height: 48px;
                        border-top: ${isOver ? '4px solid #24AEE3' : '1px solid #e6e6e6'};
                    `}
                />
            )}
        </ClassNames>
    )

    return markup
}

export default MinuteEditorListViewFooter
//#endregion
