import React from 'react'
import editorSelectors from '../../../selectors/minuteEditorSelectors'
import actionEditorSelectors from '../../../selectors/actionEditorSelectors'

import selectors from '../../../selectors/minuteTakerSelectors'
import moment from 'moment'
import { SaveStatus } from 'components/shared/SaveStatus'
import { connect } from 'react-redux'
import { pathOr } from 'rambdax'

const ToolbarSaveStatus = (props) => {
    const {
        dateFormat = 'MMMM DD, YYYY',
        timeFormat,
        currentMinuteItem,
        currentMinuteItemInEditor,
        isSavingAction,
        isSavingMinutes,
        isSavingSection,
        minutesActions,
        minutesSections,
        savedAction
    } = props

    const minutesUpdatedAt = pathOr('', ['attributes', 'updatedAt'], currentMinuteItem)

    const updatedDate = currentMinuteItem
        ? calculateLastUpdatedDate({
              minutesUpdatedAt,
              minutesActions,
              minutesSections,
              currentMinuteItemInEditor,
              savedAction
          })
        : ''
    const saveStatus = isSavingAction || isSavingMinutes || isSavingSection ? 'saving' : 'saved'

    return (
        <SaveStatus
            status={saveStatus}
            updatedDate={updatedDate}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
        />
    )
}

const mapStateToProps = (state, _) => {
    return {
        isSavingMinutes: editorSelectors.isSavingExisting(state.minuteEditorReducer),
        isSavingAction: editorSelectors.isSavingExisting(state.actionEditorReducer),
        savedAction: actionEditorSelectors.savedAction(state.actionEditorReducer),
        isSavingSection: selectors.isSavingSection(state.minuteTakerReducer),

        dateFormat: selectors.dateFormat(state.minuteTakerReducer),
        timeFormat: selectors.timeFormat(state.minuteTakerReducer),
        minutesSections: selectors.minutesSections(state.minuteTakerReducer),
        minutesActions: selectors.minutesActions(state.minuteTakerReducer),
        isEditingAction: selectors.isEditingAction(state.minuteTakerReducer),
        //FIXME: Duplication for days...
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        currentMinuteItemInEditor: editorSelectors.editedItem(state.minuteEditorReducer),
        textFormattingRequestUpdatePanelState: selectors.textFormattingRequestUpdatePanelState(
            state.minuteTakerReducer
        )
    }
}

export default connect(mapStateToProps)(ToolbarSaveStatus)

type CalculateLastUpdatedDateProps = {
    minutesUpdatedAt: string
    minutesSections: { updatedAt: string }[]
    minutesActions: { updatedAt: string }[]
    savedAction: { updatedAt: string }
    currentMinuteItemInEditor?: { updatedAt: string }
}

const calculateLastUpdatedDate = (props: CalculateLastUpdatedDateProps) => {
    //We have to find out latter of all update times for minutes item and its child sections
    const {
        minutesUpdatedAt,
        minutesSections,
        minutesActions,
        currentMinuteItemInEditor,
        savedAction
    } = props

    //So when save happens via attendee editor we would like get last updated date from the
    //object which is captured and update in that editor reducer.
    //That is because we reuse entire attendee editor with its reducer.
    let maxDate = moment(minutesUpdatedAt)

    if (currentMinuteItemInEditor && currentMinuteItemInEditor.updatedAt) {
        maxDate = moment.max(moment(minutesUpdatedAt), moment(currentMinuteItemInEditor.updatedAt))
    }

    //Each section can have updated date refreshed
    if (minutesSections) {
        //
        minutesSections.forEach((c) => {
            if (c.updatedAt) {
                maxDate = moment.max(maxDate, moment(c.updatedAt))
            }
        })
    }

    //A user may also update action not updating its text. We have to get that new date as well
    if (minutesActions) {
        //
        minutesActions.forEach((c) => {
            if (!c.updatedAt) {
                return
            }
            maxDate = moment.max(maxDate, moment(c.updatedAt))
        })
    }

    if (savedAction) {
        maxDate = moment.max(maxDate, moment(savedAction.updatedAt))
    }

    return maxDate.toISOString()
}
