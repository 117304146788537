import { CHAT_BOT } from 'components/minutetaker/components/InvitationView/types'
import React, { FC, useEffect, useState } from 'react'

type PageHeaderProps = {
    message: string
    interval: number
    className: any
    isExecutionComplete: boolean
    setChatMessages: React.Dispatch<React.SetStateAction<string>>
    reduxChat: (data: ChatObjects) => void
    isWriting: boolean
    setIswriting: React.Dispatch<React.SetStateAction<boolean>>
    bottomRef: any
    setIsExecutionComplete: React.Dispatch<React.SetStateAction<boolean>>
    allowAutoScrollRef: any
}

const SequentialCharacter: FC<PageHeaderProps> = ({
    message,
    interval,
    className,
    isExecutionComplete,
    setChatMessages,
    reduxChat,
    isWriting,
    setIswriting,
    bottomRef,
    setIsExecutionComplete,
    allowAutoScrollRef
}) => {
    const [currentText, setCurrentText] = useState<string>('')
    const [currentIndex, setCurrentIndex] = useState<number>(0)

    useEffect(() => {
        let timeout

        if (currentIndex < message.length && isWriting) {
            //providing a typing effect while displaying the response
            timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + message[currentIndex])
                setCurrentIndex((prevIndex) => prevIndex + 1)
            }, interval)

            if (bottomRef.current && allowAutoScrollRef.current) {
                //auto scroll on typing
                bottomRef.current.scrollTop = bottomRef.current.scrollHeight
            }

            return () => clearTimeout(timeout)
        }

        return () => clearTimeout(timeout)
    }, [
        currentIndex,
        interval,
        message,
        reduxChat,
        setChatMessages,
        isExecutionComplete,
        currentText,
        isWriting,
        setIswriting,
        bottomRef,
        allowAutoScrollRef
    ])
    useEffect(() => {
        if ((currentIndex === message.length && isExecutionComplete) || !isWriting) {
            reduxChat({
                sender: CHAT_BOT.SYSTEM,
                message: currentText
            })
            setIswriting(false)

            setIsExecutionComplete(false)
            setChatMessages('')
        }
    }, [
        currentIndex,
        currentText,
        isWriting,
        message.length,
        reduxChat,
        setChatMessages,
        setIswriting,
        isExecutionComplete,
        setIsExecutionComplete
    ])

    return <div className={className}>{currentText}</div>
}

export default SequentialCharacter
