import { useState, useEffect } from 'react'
import i18n from 'i18next'
import { InputChange } from './types'
import { validateMessage, MAX_COMMENT } from './validation'
import { markCompleted } from 'businesslayer/api/markReviewComplete'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'

/**
 * Hook for handling the business logic of the marl complete form
 * Handles sending network requests and validating/storing form inputs
 * @param workroomName
 * @param minutesId
 * @param dateFormat
 * @param onSubmit
 */
const useMarkCompleteForm = (
    workroomName: string,
    minutesId: string,
    minutesName: string,
    onSubmit: any /** TODO: type */,
    setRestrictedError: (...args: any[]) => void,
    onClose: any
) => {
    // Form Inputs
    const [commentInput, setCommentInput] = useState(
        i18n
            .t('MARK_COMPLETE_DEFAULT_COMMENT', {
                minutesName: minutesName,
                workroomName: workroomName
            })
            .slice(0, MAX_COMMENT)
    )

    // Validation
    const [validation, setValidation] = useState({
        commentError: ''
    })

    // Network Data
    const [isSubmitting, setIsSubmitting] = useState(false)

    /**
     * Handle validation for Message on change
     */
    useEffect(() => {
        setValidation((vState) => ({
            ...vState,
            commentError: validateMessage(commentInput)
        }))
    }, [commentInput])

    /**
     * Helper method for resetting inputs
     */
    const clearInputs = () => {
        setCommentInput(
            i18n
                .t('MARK_COMPLETE_DEFAULT_COMMENT', {
                    minutesName: minutesName,
                    workroomName: workroomName
                })
                .slice(0, MAX_COMMENT)
        )
    }
    /**
     * Update state variable for message
     * @param e Input change event
     */
    const handleMessageChange: InputChange = (e) => {
        // Input only allows 500 length, so we trim the remainder off the end
        setCommentInput(e.target.value.slice(0, MAX_COMMENT))
    }

    /**
     * Send data to endpoint to submit form
     * and send email
     */
    const submitForm = async () => {
        try {
            setIsSubmitting(true)
            const data = await markCompleted(minutesId, generateObject())
            setIsSubmitting(false)
            onSubmit(data)
        } catch (e) {
            const edata = await e.response.json()

            setRestrictedError({ error: edata.error, assistance: edata.assistance })

            setIsSubmitting(false)
            onClose()
        }
    }

    /**
     * Helper that checks whether or nor form is valid
     */
    const getCanSubmit = () => {
        return !Object.values(validation).some((val) => val.length > 0)
    }

    /**
     * Generate form object for submission to server
     */
    const generateObject = () => {
        // Email message content
        const input =
            commentInput.trim() ||
            i18n.t('MARK_COMPLETE_DEFAULT_COMMENT', {
                minutesName: minutesName,
                workroomName: workroomName
            })
        // Name for adding to end of email body
        const name = (transientStorageManager as any).currentUser.display_name
        return {
            subject: i18n.t('MARK_COMPLETE_EMAIL_SUBJECT'),
            body: `${input}\n${name}`
        }
    }
    return {
        // Input values
        commentInput,
        // validators
        validation,
        // Network data
        isSubmitting,
        submitForm,
        //Events
        handleMessageChange,
        // Utils
        getCanSubmit,
        clearInputs,
        generateObject
    }
}

export default useMarkCompleteForm
