import React from 'react'

import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'
import { CircularProgress } from './StyledComponents'
type Props = {
    size: number
    thickness: number
}
export const FullScreenProgressSpinner = (props: Props) => {
    return (
        <ClassNames>
            {({ css }) => (
                <FullScreenCircularProgressWrapper
                    className={css`
                        padding: 5;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #37465b;
                    `}>
                    <CircularProgress
                        size={props.size}
                        thickness={props.thickness}
                        color="inherit"
                    />
                </FullScreenCircularProgressWrapper>
            )}
        </ClassNames>
    )
}
export const ProgressSpinner = (props: Props) => {
    return (
        <ClassNames>
            {({ css }) => (
                <CircularProgressWrapper
                    className={css`
                        padding: 5px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #37465b;
                    `}>
                    <CircularProgress
                        size={props.size}
                        thickness={props.thickness}
                        color="inherit"
                    />
                </CircularProgressWrapper>
            )}
        </ClassNames>
    )
}

const FullScreenCircularProgressWrapper = styled('div')`
    background-color: #dfe3e8;
    border-top: 1px solid #eff7f4;
    position: fixed;
    padding: 5px;
    bottom: 0;
    width: 100%;
    color: #37465b;
`
const CircularProgressWrapper = styled('div')`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px;
`
