//Operation blocks UI after certain time showing progress
import { actions } from '../reducers/applicationReducer'
import moment from 'moment'

let store = null

const requestProgressTreshhold = 3000
const minimumHoldOnTime = 1500
let timeout = {
    value: null,
    showTime: moment(),
    cancelationPending: false
}

export default class blockingOperation {
    static setStore = (newStore) => {
        store = newStore
        //Use: store.dispatch(actions.nameOfTheAction(data))
    }

    static getStore() {
        return store
    }

    static showProgress = (repeatActionDispatched, options) => {
        timeout.showTime = moment()
        //We are calling reducer to get action creator and then call dispatcher
        //This is a short cut to initiate UI change to show progress

        if (timeout.cancellationPending) {
            return
        }

        store.dispatch(actions.toggleProgress(true, repeatActionDispatched, null, options))
    }

    static hideProgress = (repeatActionDispatched, lastError) => {
        const elapsed = moment() - timeout.showTime
        timeout.showTime = moment()

        //We prevent progress from hiding in case it was shown less than minimum time in milliseconds
        const func = () =>
            store.dispatch(actions.toggleProgress(false, repeatActionDispatched, lastError))

        if (elapsed < minimumHoldOnTime) {
            setTimeout(func, minimumHoldOnTime - elapsed)
        } else {
            func()
        }
    }

    static start = (repeatAction) => {
        timeout.cancellationPending = false
        //This is quite tricky part of retry process
        //When we provide "repeatAction" it must be already
        //closured (captured) with dispatcher.
        //UI will just call actions stored in reducer as lambdas
        //So that action has to be self contained tructure aready
        //having everything in place. We cannot have strandard action be called
        //because this last action is "dynamic", but list of action creator's actions
        //is static

        //As of 1.3 we now need to support cases where retry button needs to be hidden.
        //null action will be indicator for that.
        const repeatActionDispatched = repeatAction ? () => store.dispatch(repeatAction()) : null

        if (timeout.value) {
            clearTimeout(timeout.value)
        }

        timeout.value = setTimeout(() => {
            timeout.value = null

            //We only show progress after certain time and if that timeout is not
            //Reached the stop function below will clear this timeout

            blockingOperation.showProgress(repeatActionDispatched)
        }, requestProgressTreshhold)
    }

    static stop = (repeatAction, lastError) => {
        //As of 1.3 we now need to support cases where retry button needs to be hidden.
        //null action will be indicator for that.
        const repeatActionDispatched = repeatAction ? () => store.dispatch(repeatAction()) : null

        if (timeout.value) {
            clearTimeout(timeout.value)
            timeout.value = null
        }
        //If user requested cancellation and there was an error from getting long operation complete, we dont report this error
        //TODO: Perform actual cancelation of underlying operation
        const canReportError = !timeout.cancellationPending

        blockingOperation.hideProgress(repeatActionDispatched, canReportError ? lastError : null)
    }

    static cancel = () => {
        timeout.cancellationPending = true

        if (timeout.value) {
            clearTimeout(timeout.value)
            timeout.value = null
        }
    }

    static isCancellationPending = () => {
        return timeout.cancellationPending
    }
}
