import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './businesslayer/store'
import { Route, Switch } from 'react-router-dom'
import { RouteStateProvider } from 'components/contexts/route-context/route-context'
import { MinutesManagerPage } from 'pages/minutes-manager-page/minutes-manager-page'
import MinutesDetailPage from 'pages/MinutesDetail'
import NotFound from 'pages/NotFound'
import { MinutesAttendanceReportPage } from 'pages/minutes-attendance-report/minutes-attendance-report'
import { useChatAiToken } from 'minutes-ai-help-chat/hooks/useChatAiToken'
import { connect } from 'react-redux'
import selectors from 'selectors/minuteAttendeesSelectors'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { actions } from 'reducers/minutesAttendeesReducer'
import { AiChatTokenProps } from 'components/minutetaker/components/InvitationView/types'
import LeftNavBar from 'components/minutetaker/components/LeftNavBar'
import { Box } from '@material-ui/core'
import ActionDashboard from 'components/minutetaker/components/ActionDashboard'

/**
 * Application Routes
 */

const AdminRoute = ({ component: Component, isProMember, ...rest }) => (
    <Route {...rest} render={(props) => (isProMember ? <Component {...props} /> : <NotFound />)} />
)

const Routes = (props: AiChatTokenProps) => {
    const isProMember = true //blc pro member logic will be added here
    useChatAiToken(props)

    return (
        <ConnectedRouter history={history}>
            <RouteStateProvider>
                <Box
                    style={{
                        display: 'flex',
                        maxWidth: '100vw'
                    }}>
                    <LeftNavBar />
                    <Switch>
                        <Route path="/not-found" component={NotFound} />
                        <Route exact path="/" component={MinutesManagerPage} />
                        <Route exact path="/taker/:minutesId" component={MinutesDetailPage} />
                        <Route path="/taker/:minutesId/actions" component={MinutesDetailPage} />
                        <AdminRoute
                            exact
                            path="/attendanceReport"
                            isProMember={isProMember}
                            component={MinutesAttendanceReportPage}
                        />
                        <AdminRoute
                            exact
                            path="/actionDashboard"
                            isProMember={isProMember}
                            component={ActionDashboard}
                        />
                        <Route path="/*" component={NotFound} />
                    </Switch>
                </Box>
            </RouteStateProvider>
        </ConnectedRouter>
    )
}
const mapStateToProps = (state, _) => {
    return {
        identityToken: selectors.getIdentityToken(state.minutesAttendeesReducer)
    }
}
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(Routes)
