import React, { FC } from 'react'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider as MuiPickersUtilsProviderOLD } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { LoadsProvider } from 'react-loads'
import { StylesProvider } from '@material-ui/styles'

import { DialogProvider } from 'components/shared/context/dialog'
import { theme } from 'theme'
import { initFlags } from 'common/constants/flags'
import { FeatureContext } from 'components/contexts/flag'
import InternationalizationProvider from 'components/shared/internationalization/InternationalizationProvider'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { AppContextProviders } from 'AppContextProviders'

import configureStore from './businesslayer/store'
import { Provider as ReduxProvider } from 'react-redux'
import { ReactHintProvider } from 'components/shared/MinutesReactHint'
import { cache } from '@emotion/css'
import { CacheProvider } from '@emotion/react'
import { SWRConfig } from 'swr'
import { defaultFetcher } from 'businesslayer/networkrequests/swr-util'

const defaultStore = configureStore()

export const AppProviders: FC<Props> = ({
    children,
    language = 'en-us',
    platform,
    committeeId
}) => (
    <ReduxProvider store={defaultStore}>
        <DialogProvider>
            <SWRConfig
                value={{
                    fetcher: defaultFetcher
                }}>
                <LoadsProvider>
                    <StylesProvider injectFirst>
                        <InternationalizationProvider language={language}>
                            <DndProvider backend={HTML5Backend}>
                                <FeatureContext.Provider value={initFlags()}>
                                    <CacheProvider value={cache}>
                                        <MuiThemeProvider theme={theme}>
                                            <AppContextProviders
                                                committeeId={committeeId}
                                                platform={platform}>
                                                <MuiPickersUtilsProviderOLD utils={DateFnsUtils}>
                                                    <ReactHintProvider />
                                                    {children}
                                                </MuiPickersUtilsProviderOLD>
                                            </AppContextProviders>
                                        </MuiThemeProvider>
                                    </CacheProvider>
                                </FeatureContext.Provider>
                            </DndProvider>
                        </InternationalizationProvider>
                    </StylesProvider>
                </LoadsProvider>
            </SWRConfig>
        </DialogProvider>
    </ReduxProvider>
)

type Props = {
    language: string
    platform: Platform
    committeeId: string
}

export default AppProviders
