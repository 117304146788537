/** @jsx jsx */
import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import selectors from 'selectors/minuteTakerSelectors'
import { actions } from 'reducers/minuteTakerReducer'
import { actions as actionEditorReducer } from 'reducers/actionEditorReducer'
import actionItemsSelectors from 'selectors/minuteActionItemsSelectors'
import { useMediaQuery } from '@material-ui/core'
import { jsx, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'

const ToolbarContainer: FC<Props> = (props) => {
    const matchesIconOnlyBreakpoint = useMediaQuery(`(max-width:${iconOnlyBreakpoint}px)`)

    const onPanelMouseDown = (e) => {
        //We handle mouse down on the panel so if none of the buttons handle it we treat it as incidental click
        //And so we don't want section to loose its focus
        e.preventDefault()
        return false
    }
    const { css, isIconOnlyMode, ...rest } = props

    return (
        <ToolbarWrapper css={css} disabled={props.disabled} onMouseDown={onPanelMouseDown}>
            {props.children({
                isIconOnlyMode: matchesIconOnlyBreakpoint,
                ...rest
            })}
        </ToolbarWrapper>
    )
}

export const ToolbarWrapper = styled('section')<{ disabled?: boolean }>``

const mapStateToProps = (state, _) => {
    return {
        isExpanded: selectors.isMinutesExpanded(state.minuteTakerReducer),
        isExportingMinutes: selectors.isExportingMinutes(state.minuteTakerReducer),
        minutesSections: selectors.minutesSections(state.minuteTakerReducer),
        isEditingAction: selectors.isEditingAction(state.minuteTakerReducer),
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        collapseAllVisible: selectors.collapseAllVisible(state.minuteTakerReducer),
        sortField: actionItemsSelectors.sortField(state.minuteActionItemsReducer),
        isSavingSection: selectors.isSavingSection(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const { addAction, addSection, toggleExpandCollapseAll } = {
        ...actions,
        ...actionEditorReducer
    }
    return {
        actions: bindActionCreators(
            {
                addAction,
                addSection,
                toggleExpandCollapseAll
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarContainer)

type Props = {
    actions: {
        addAction: () => void
        addSection: () => void
        toggleExpandCollapseAll: () => void
    }
    minutesSections: Array<any>
    currentMinuteItem: any
    isEditingAction: boolean
    isExpanded: boolean
    isExportingMinutes: boolean
    collapseAllVisible: boolean
    isIconOnlyMode: boolean
    isSavingSection?: boolean
    sortField: ActionListSort
    children: any

    disabled?: boolean

    css?: SerializedStyles
}

const iconOnlyBreakpoint = 1260
