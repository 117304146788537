import React from 'react'
import MinutesReportDetails from './minutes-report-details'

/**
 * Use this container to generate new reports 
 * call the the usehook here usehook for get report details 
 */

const MinutesReportDetailsContainer: React.FC = (props) => {
    return (
        <MinutesReportDetails
        {...props}
        />
    )
}

export default MinutesReportDetailsContainer