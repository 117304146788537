import React, { FC, useMemo } from 'react'
import { AtlasDatePickerAdapter } from 'components/date-time-pickers/date-picker'
import { AtlasTimePickerAdapter } from 'components/date-time-pickers/time-picker'
import { Container } from 'assets/sharedStyles'
import { AtlasSelectAdapter } from 'components/atlas-material/select'
import { Field } from 'react-final-form'
import {
    validateMeetingDate,
    validateMeetingStartTime,
    validateMeetingEndTime,
    validateMeetingTimezone
} from 'common/util/validators/minutes-properties/minutes-properties-validators'
import { timezoneMenuItems } from 'components/popovers/timezone-select/timezone-menu-items'
import i18n from 'i18next'
import { ClassNames, css } from '@emotion/react'
import { ColorBaseDarkGray, ColorBaseSkyBlue } from 'assets/styles/variables'

const DATE_FIELD_LENGTH = 175
const TIME_FIELD_LENGTH = 160
const TIMEZONE_FIELD_LENGTH = 300

export const MeetingDateFields: FC = () => {
    const memoTimezoneMenuItems = useMemo(() => timezoneMenuItems(), [])

    return (
        <ClassNames>
            {({ css }) => (
                <>
                    <Container width={DATE_FIELD_LENGTH}>
                        <Field
                            name={`date`}
                            label={i18n.t('MEETING_DATE_LABEL.default')}
                            data-testid="MinutesProperties_MeetingDatePicker"
                            className={css`
                                ${LabelCss}
                            `}
                            validate={validateMeetingDate}
                            render={AtlasDatePickerAdapter}
                            helperText={null} // Text will be displayed at row level
                            showErrorWhen={true}
                        />
                    </Container>
                    <Container width={TIME_FIELD_LENGTH}>
                        <Field
                            name={`startTime`}
                            label={i18n.t('START_TIME_LABEL.default')}
                            data-testid="MinutesProperties_StartTimePicker"
                            className={css`
                                ${LabelCss}
                            `}
                            validate={validateMeetingStartTime}
                            render={AtlasTimePickerAdapter}
                            helperText={null} // Text will be displayed at row level
                            showErrorWhen={true}
                        />
                    </Container>
                    <Container width={TIME_FIELD_LENGTH}>
                        <Field
                            name={`endTime`}
                            label={i18n.t('FINISH_TIME')}
                            data-testid="MinutesProperties_EndTimePicker"
                            className={css`
                                ${LabelCss}
                            `}
                            validate={validateMeetingEndTime}
                            render={AtlasTimePickerAdapter}
                            helperText={null} // Text will be displayed at row level
                            showErrorWhen={true}
                        />
                    </Container>
                    <Container width={TIMEZONE_FIELD_LENGTH}>
                        <Field
                            name={`timezone`}
                            label={i18n.t('TIME_ZONE')}
                            validate={validateMeetingTimezone}
                            render={AtlasSelectAdapter}
                            className={css`
                                ${LabelCss}
                            `}
                            placeholder={i18n.t('TIMEZONE_LABEL.default')}
                            data-testid="MinutesProperties_TimezoneSelect"
                            role="combobox"
                            id="timezone"
                            ariaLabelledby="timezone"
                            children={memoTimezoneMenuItems}
                            helperText={null} // Text will be displayed at row level
                            showErrorWhen={true}
                        />
                    </Container>
                </>
            )}
        </ClassNames>
    )
}

export const LabelCss = css`
    && div {
        color: ${ColorBaseDarkGray};
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
    .Mui-focusVisible {
        box-shadow: 0px 0px 0px 2px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
    button {
        height: 40px;
        width: 40px;
    }
`
