import React from 'react'

type Props = {
    title?: string
    className?: string
}

const BackButtonIcon = ({ title, className }: Props) => (
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}>
        <title>{title}</title>
        <path
            d="M5.95192 11.6538L0.298096 6.00002L5.95192 0.346191L7.00575 1.43079L3.1865 5.25004H15.7019V6.74999H3.1865L7.00575 10.5692L5.95192 11.6538Z"
            fill="#455D82"
        />
    </svg>
)

export default BackButtonIcon
