import { translate } from 'components/shared/internationalization'
import * as requests from '../networkrequests/markReviewComplete'
import { executeRequest } from '../request-executor'
import { countlyEvent } from 'businesslayer/Countly'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

export const markCompleted = (minutesId: string, data: requests.MarkCompleted) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('MARK_COMPLETE_ERROR', {
            committeeId: committeeId,
            minutesId: minutesId,
            message: errorObject.message,
            code: httpCode
        })
    }

    const onSuccess = () => void countlyEvent('Reviewer_MarkComplete_Options')

    return executeRequest(requests.markCompleted(committeeId, minutesId, data), onSuccess, onError)
}
