import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    width: 5rem;
`
type Props = {
    title?: string
    className?: string
}
const DiligentLogo = ({ title, className }: Props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="39"
        viewBox="0 0 102 39"
        fill="none"
        className={className}>
        <title id="logo-title">{title ? title : 'Diligent Minutes'}</title>
        <path
            d="M42.275 36.0588V23.3193H43.472L48.26 30.2619H47.5931L52.364 23.3193H53.561V36.0588H52.193V25.1661L52.6376 25.3371L47.9522 32.1258H47.8838L43.2155 25.3371L43.643 25.1661V36.0588H42.275Z"
            fill="#E6E6E6"
        />
        <path
            d="M55.7639 36.0588V26.8932H57.0464V36.0588H55.7639ZM55.7639 25.2003V23.3193H57.0464V25.2003H55.7639Z"
            fill="#E6E6E6"
        />
        <path
            d="M59.0541 36.0588V26.8932H60.3366V28.6716L60.0459 28.6032C60.2739 28.0104 60.6444 27.543 61.1574 27.201C61.6818 26.859 62.286 26.688 62.97 26.688C63.6198 26.688 64.2012 26.8362 64.7142 27.1326C65.2386 27.429 65.649 27.8394 65.9454 28.3638C66.2532 28.8768 66.4071 29.4582 66.4071 30.108V36.0588H65.1246V30.6039C65.1246 30.0453 65.0219 29.5722 64.8167 29.1846C64.6229 28.797 64.3437 28.5006 63.9789 28.2954C63.6255 28.0788 63.2151 27.9705 62.7477 27.9705C62.2803 27.9705 61.8642 28.0788 61.4994 28.2954C61.1346 28.5006 60.8496 28.8027 60.6444 29.2017C60.4391 29.5893 60.3366 30.0567 60.3366 30.6039V36.0588H59.0541Z"
            fill="#E6E6E6"
        />
        <path
            d="M71.488 36.264C70.8496 36.264 70.2682 36.1158 69.7438 35.8194C69.2194 35.5116 68.8033 35.0898 68.4955 34.554C68.1991 34.0068 68.0509 33.3798 68.0509 32.673V26.8932H69.3334V32.5191C69.3334 33.0093 69.4303 33.4425 69.6241 33.8187C69.8293 34.1835 70.1086 34.4685 70.462 34.6737C70.8268 34.8789 71.2429 34.9815 71.7103 34.9815C72.1777 34.9815 72.5938 34.8789 72.9586 34.6737C73.3234 34.4571 73.6084 34.155 73.8136 33.7674C74.0188 33.3684 74.1214 32.8953 74.1214 32.3481V26.8932H75.4039V36.0588H74.1214V34.2804L74.4121 34.3488C74.1955 34.9416 73.825 35.409 73.3006 35.751C72.7762 36.093 72.172 36.264 71.488 36.264Z"
            fill="#E6E6E6"
        />
        <path
            d="M80.9294 36.1614C80.1086 36.1614 79.4759 35.922 79.0313 35.4432C78.5867 34.9644 78.3644 34.2804 78.3644 33.3912V28.1757H76.6715V26.8932H77.099C77.4866 26.8932 77.7944 26.7678 78.0224 26.517C78.2504 26.2548 78.3644 25.9299 78.3644 25.5423V24.7728H79.6469V26.8932H81.6647V28.1757H79.6469V33.3912C79.6469 33.699 79.6925 33.9726 79.7837 34.212C79.8749 34.44 80.0288 34.6224 80.2454 34.7592C80.4734 34.896 80.7755 34.9644 81.1517 34.9644C81.2429 34.9644 81.3512 34.9587 81.4766 34.9473C81.602 34.9359 81.7103 34.9245 81.8015 34.9131V36.0588C81.6647 36.093 81.5108 36.1158 81.3398 36.1272C81.1688 36.15 81.032 36.1614 80.9294 36.1614Z"
            fill="#E6E6E6"
        />
        <path
            d="M87.2744 36.264C86.4194 36.264 85.6442 36.0588 84.9488 35.6484C84.2648 35.2266 83.7233 34.6509 83.3243 33.9213C82.9253 33.1917 82.7258 32.3652 82.7258 31.4418C82.7258 30.5184 82.9196 29.7033 83.3072 28.9965C83.6948 28.2783 84.2192 27.714 84.8804 27.3036C85.553 26.8932 86.3054 26.688 87.1376 26.688C87.7988 26.688 88.3859 26.8134 88.8989 27.0642C89.4233 27.3036 89.8679 27.6342 90.2327 28.056C90.5975 28.4664 90.8768 28.9338 91.0706 29.4582C91.2644 29.9712 91.3613 30.5013 91.3613 31.0485C91.3613 31.1625 91.3556 31.2936 91.3442 31.4418C91.3328 31.5786 91.3157 31.7211 91.2929 31.8693H83.5466V30.6723H90.5576L89.942 31.1853C90.0446 30.5583 89.9705 29.9997 89.7197 29.5095C89.4803 29.0079 89.1326 28.6146 88.6766 28.3296C88.2206 28.0332 87.7076 27.885 87.1376 27.885C86.5676 27.885 86.0375 28.0332 85.5473 28.3296C85.0685 28.626 84.6923 29.0421 84.4187 29.5779C84.1451 30.1023 84.0368 30.7293 84.0938 31.4589C84.0368 32.1885 84.1508 32.8269 84.4358 33.3741C84.7322 33.9099 85.1312 34.326 85.6328 34.6224C86.1458 34.9188 86.693 35.067 87.2744 35.067C87.947 35.067 88.5113 34.9074 88.9673 34.5882C89.4233 34.269 89.7938 33.87 90.0788 33.3912L91.1732 33.9726C90.9908 34.383 90.7115 34.7649 90.3353 35.1183C89.9591 35.4603 89.5088 35.7396 88.9844 35.9562C88.4714 36.1614 87.9014 36.264 87.2744 36.264Z"
            fill="#E6E6E6"
        />
        <path
            d="M96.1856 36.264C95.3078 36.264 94.5383 36.0417 93.8771 35.5971C93.2159 35.1525 92.7371 34.5483 92.4407 33.7845L93.518 33.2715C93.7802 33.8301 94.145 34.2747 94.6124 34.6053C95.0912 34.9359 95.6156 35.1012 96.1856 35.1012C96.7328 35.1012 97.1945 34.9701 97.5707 34.7079C97.9469 34.4343 98.135 34.0809 98.135 33.6477C98.135 33.3285 98.0438 33.0777 97.8614 32.8953C97.679 32.7015 97.4681 32.5533 97.2287 32.4507C96.9893 32.3481 96.7784 32.274 96.596 32.2284L95.2793 31.8522C94.4357 31.6128 93.8258 31.2765 93.4496 30.8433C93.0734 30.4101 92.8853 29.9085 92.8853 29.3385C92.8853 28.8027 93.0221 28.3353 93.2957 27.9363C93.5693 27.5373 93.9398 27.2295 94.4072 27.0129C94.8746 26.7963 95.3933 26.688 95.9633 26.688C96.7385 26.688 97.4396 26.8932 98.0666 27.3036C98.705 27.7026 99.1553 28.2612 99.4175 28.9794L98.3231 29.4924C98.0951 28.9794 97.7645 28.5804 97.3313 28.2954C96.9095 27.999 96.4364 27.8508 95.912 27.8508C95.399 27.8508 94.9886 27.9819 94.6808 28.2441C94.373 28.5063 94.2191 28.8369 94.2191 29.2359C94.2191 29.5437 94.2989 29.7888 94.4585 29.9712C94.6181 30.1536 94.8005 30.2904 95.0057 30.3816C95.2223 30.4728 95.4104 30.5412 95.57 30.5868L97.0919 31.0314C97.8329 31.248 98.4086 31.5843 98.819 32.0403C99.2408 32.4963 99.4517 33.0321 99.4517 33.6477C99.4517 34.1493 99.3092 34.5996 99.0242 34.9986C98.7506 35.3976 98.3687 35.7111 97.8785 35.9391C97.3883 36.1557 96.824 36.264 96.1856 36.264Z"
            fill="#E6E6E6"
        />
        <path d="M56.5432 8.7918H54.5877V17.6736H56.5432V8.7918Z" fill="white" />
        <path d="M56.5432 5.46532H54.5877V7.67737H56.5432V5.46532Z" fill="white" />
        <path d="M60.3583 5.26928H58.4027V17.6746H60.3583V5.26928Z" fill="white" />
        <path d="M64.4938 8.7918H62.5382V17.6736H64.4938V8.7918Z" fill="white" />
        <path d="M64.4938 5.46532H62.5382V7.67737H64.4938V5.46532Z" fill="white" />
        <path
            d="M72.9414 9.86428C72.6616 9.51412 72.3327 9.23316 71.9558 9.02139C71.4484 8.73728 70.8626 8.59575 70.2007 8.59575C69.3989 8.59575 68.6826 8.78446 68.0531 9.16082C67.4224 9.53823 66.9234 10.054 66.5538 10.7093C66.1852 11.3645 66.0004 12.113 66.0004 12.9549C66.0004 13.7852 66.1842 14.5337 66.5538 15.2005C66.9223 15.8662 67.4277 16.3914 68.0687 16.773C68.7098 17.1557 69.4312 17.3465 70.2331 17.3465C70.8846 17.3465 71.4703 17.2049 71.9882 16.9198C72.3191 16.7384 72.6042 16.5036 72.8453 16.2153V17.2469C72.8453 17.705 72.7461 18.0992 72.5488 18.4263C72.3515 18.7544 72.0811 19.0029 71.7397 19.1717C71.3972 19.3405 71.0026 19.4254 70.5536 19.4254C69.9867 19.4254 69.5085 19.2943 69.1191 19.0322C68.7286 18.7702 68.4644 18.4315 68.3256 18.0164L66.5141 18.7376C66.6958 19.2513 66.9839 19.6969 67.3796 20.0733C67.7743 20.4507 68.2452 20.7453 68.7902 20.9581C69.3352 21.1709 69.9282 21.2778 70.5693 21.2778C71.392 21.2778 72.1218 21.1028 72.7576 20.7537C73.3935 20.4035 73.8925 19.9265 74.2569 19.3195C74.6203 18.7125 74.8019 18.0216 74.8019 17.2469V8.7918H72.9424V9.86428H72.9414ZM72.5415 14.2664C72.3379 14.649 72.0581 14.9489 71.7 15.168C71.3419 15.386 70.9326 15.4961 70.4732 15.4961C70.0023 15.4961 69.5837 15.3839 69.2151 15.1596C68.8465 14.9363 68.5584 14.6354 68.3496 14.258C68.1408 13.8806 68.0374 13.4528 68.0374 12.9716C68.0374 12.4904 68.1418 12.0596 68.3496 11.6769C68.5584 11.2942 68.8465 10.9913 69.2151 10.7669C69.5837 10.5436 70.0034 10.4304 70.4732 10.4304C70.9222 10.4304 71.3252 10.5426 71.6833 10.7669C72.0414 10.9913 72.3243 11.2942 72.5332 11.6769C72.742 12.0596 72.8453 12.4904 72.8453 12.9716C72.8453 13.4518 72.7441 13.8837 72.5415 14.2664Z"
            fill="white"
        />
        <path
            d="M83.3852 9.87372C83.0271 9.46905 82.5885 9.15559 82.0707 8.93124C81.5518 8.70793 80.9619 8.59471 80.2989 8.59471C79.4866 8.59471 78.7547 8.7939 78.1022 9.19228C77.4507 9.59171 76.9349 10.1379 76.5549 10.8309C76.1759 11.5249 75.9859 12.3143 75.9859 13.1991C75.9859 14.0735 76.1727 14.8629 76.5465 15.5674C76.9203 16.2719 77.4444 16.8317 78.1178 17.2469C78.7913 17.662 79.566 17.8696 80.4419 17.8696C81.0402 17.8696 81.5883 17.7763 82.0853 17.5907C82.5823 17.4052 83.0124 17.1462 83.3758 16.8129C83.7391 16.4795 84.0064 16.1052 84.1776 15.6901L82.575 14.887C82.3714 15.2476 82.0937 15.5401 81.7418 15.7634C81.3889 15.9878 80.9619 16.1 80.4597 16.1C79.9679 16.1 79.5294 15.9825 79.1452 15.7477C78.7599 15.5129 78.4666 15.1774 78.264 14.7402C78.1335 14.4582 78.0542 14.1469 78.0249 13.8061H84.3875C84.4303 13.6751 84.4574 13.5304 84.4679 13.3721C84.4783 13.2138 84.4835 13.0639 84.4835 12.9213C84.4835 12.3311 84.3896 11.7765 84.2027 11.2576C84.0158 10.7397 83.7433 10.2784 83.3852 9.87372ZM78.2473 11.6192C78.4394 11.1768 78.715 10.8382 79.0732 10.6034C79.4313 10.3685 79.8395 10.2511 80.2999 10.2511C80.7698 10.2511 81.1759 10.3717 81.5184 10.6118C81.8608 10.8518 82.1114 11.1747 82.2722 11.5784C82.3547 11.788 82.4017 12.0113 82.4131 12.2504H78.0656C78.1043 12.0229 78.1638 11.8121 78.2473 11.6192Z"
            fill="white"
        />
        <path
            d="M92.0093 9.02139C91.5123 8.73728 90.9433 8.59575 90.3022 8.59575C89.682 8.59575 89.1402 8.73833 88.6755 9.02139C88.3268 9.23421 88.0512 9.52146 87.8497 9.88315V8.7918H85.9902V17.6736H87.9457V12.4454C87.9457 12.0302 88.023 11.6727 88.1786 11.3718C88.3331 11.0709 88.5523 10.8393 88.8363 10.6757C89.1193 10.5122 89.4429 10.4293 89.8063 10.4293C90.1592 10.4293 90.4745 10.5111 90.7522 10.6757C91.0299 10.8393 91.246 11.072 91.4016 11.3718C91.5561 11.6727 91.6344 12.0302 91.6344 12.4454V17.6736H93.59V11.9379C93.59 11.2827 93.4511 10.704 93.1734 10.2008C92.8936 9.69968 92.5073 9.3055 92.0093 9.02139Z"
            fill="white"
        />
        <path
            d="M99.9284 15.9867C99.8272 15.9972 99.7332 16.0035 99.6476 16.0035C99.3166 16.0035 99.041 15.949 98.8217 15.84C98.6025 15.7309 98.4427 15.5726 98.3404 15.365C98.2391 15.1575 98.188 14.9006 98.188 14.5945V10.5793H100.176V8.79179H98.189V6.76006H96.2335V7.44779C96.2335 7.87342 96.1186 8.20471 95.8889 8.43954C95.6592 8.67438 95.3356 8.79179 94.919 8.79179H94.7269V10.5782H96.2335V14.691C96.2335 15.6743 96.4955 16.4333 97.0186 16.9691C97.5417 17.5048 98.2799 17.7721 99.231 17.7721C99.3803 17.7721 99.5484 17.7616 99.7363 17.7396C99.9232 17.7176 100.086 17.6956 100.225 17.6736V15.9532C100.129 15.9647 100.03 15.9762 99.9284 15.9867Z"
            fill="white"
        />
        <path
            d="M49.9155 6.23586C48.9915 5.72216 47.9036 5.46636 46.6538 5.46636H42.275V17.6756H46.6538C47.9046 17.6756 48.9915 17.4156 49.9155 16.8967C50.8395 16.3778 51.5557 15.6596 52.0631 14.7413C52.5706 13.8239 52.8243 12.764 52.8243 11.5616C52.8243 10.3601 52.5706 9.30024 52.0631 8.38187C51.5557 7.46455 50.8405 6.74956 49.9155 6.23586ZM50.2684 13.8156C49.9322 14.4551 49.4592 14.9488 48.8495 15.299C48.2408 15.6491 47.5194 15.8232 46.6851 15.8232H44.3099V7.31778H46.6851C47.5183 7.31778 48.2398 7.48971 48.8495 7.83357C49.4582 8.17744 49.9312 8.66702 50.2684 9.30024C50.6046 9.9345 50.7737 10.682 50.7737 11.5458C50.7737 12.4191 50.6056 13.176 50.2684 13.8156Z"
            fill="white"
        />
        <path
            d="M34.2826 19.9693C34.2826 25.8062 32.2021 30.6153 28.6453 33.9394C28.6043 33.9755 28.5736 34.0133 28.5327 34.0494C25.8873 36.478 22.438 38.0919 18.4221 38.7089L18.1508 38.4305L24.1362 3.75457L24.3615 3.12035C30.4886 6.13505 34.2826 12.1524 34.2826 19.9693Z"
            fill="#EE312E"
        />
        <path
            d="M24.3632 3.12209L0 21.31V38.3721V38.9943H14.6708C15.9696 38.9943 17.2207 38.8946 18.4239 38.709C18.4239 38.7107 24.3632 3.12209 24.3632 3.12209Z"
            fill="#D3222A"
        />
        <path
            d="M24.3632 3.1221L24.1943 3.37475L0.126296 21.4286L0 21.31C0 21.31 0 21.3048 0 21.2997V0.994274H14.6145C15.6232 0.994274 16.6011 1.05271 17.5483 1.16787H17.5842C19.977 1.45318 22.1803 2.08053 24.1516 3.02069C24.2301 3.0585 24.3632 3.1221 24.3632 3.1221Z"
            fill="#AF292E"
        />
    </Svg>
)

export default DiligentLogo
