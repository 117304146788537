import React from 'react'
import Loads from 'react-loads'
import { getJSON } from 'common/util/request'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

type InjectedGetBookDetailsProps = {
    isSuccess: boolean
    isLoading: boolean
    isError: boolean
    response: any
}

type GetBookDetailsProps = {
    bookId: string
    children(props: InjectedGetBookDetailsProps): React.ReactNode
}

export const GetBookDetails = ({ bookId, children }: GetBookDetailsProps) => (
    <Loads
        cacheKey={`${getCommitteeId()}-${bookId}`}
        loadOnMount
        loadPolicy="cache-first"
        fn={getBookDetails({ bookId, committeeId: getCommitteeId() })}>
        {({ isLoading, isSuccess, isError, response }) => {
            return children({ isError, isSuccess, isLoading, response })
        }}
    </Loads>
)

const getBookDetails = ({ committeeId, bookId }) => () =>
    getJSON(`committees/${committeeId}/books/${bookId}`)

const getCommitteeId = () => getSessionStorageItem('currentCommitteeId')
