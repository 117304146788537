import React, { Component } from 'react'
import ReactDOM from 'react-dom'

//#region Components

import { isScrolledIntoView } from '../../helpers/DomHelper'

//#endregion

//#region Redux

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import editorSelectors from '../../../selectors/actionEditorSelectors'
import styled from '@emotion/styled'
import { DocumentDetailsLayout } from 'assets/styles/LayoutConstants'

//#endregion

//#region Styles

const tabsHeight = 40
const headerHeight = DocumentDetailsLayout.header.height

const Wrapper = styled('div')`
    color: #fff;
    height: 100%;
    background-color: #f5f8f9;
`

//#endregion

//#region Props

type Props = {
    actions: any
    getListNodeById: (...args: any[]) => any
    items: Array<any>
    saveComplete: boolean
    savedAction: any
    isSavingExisting: boolean
}

//#endregion

//#region Implementation
//This component scrolls list so newly added action would become in the view.
class MinuteEditorListAutoScroller extends Component<Props> {
    parentElement: HTMLDivElement | null = null

    scrollToSection = (getListNodeById: (sectionId: string) => any, sectionId: string) => {
        //The only way to get real mounted node is to callback to owner of this component
        //And that owner should return actual component which he stores in its refs
        const reactNode = getListNodeById(sectionId)

        if (!reactNode) {
            return
        }
        const domNode = ReactDOM.findDOMNode(reactNode) as Element
        //We have to correct top point because the actual view is shifted down from the top of window

        const parentElementTop = this.parentElement ? this.parentElement.offsetTop : 0
        const topCorrection = parentElementTop + headerHeight + tabsHeight

        if (isScrolledIntoView(domNode, topCorrection) || !domNode) {
            return
        }

        if (domNode.scrollIntoView) {
            domNode.scrollIntoView()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { saveComplete, items, getListNodeById } = this.props

        const newActionSaved =
            nextProps.saveComplete &&
            !saveComplete &&
            nextProps.savedAction &&
            nextProps.savedAction.isNew

        if (newActionSaved) {
            //isNew is temporary flag which we use once so we did not add it to the reducer
            //because reducer already has a lot of flags which are hard to manage

            const sectionId = `${nextProps.savedAction.minutesSectionId}`

            if (!nextProps.savedAction.minutesSectionId || !items) {
                return
            }

            const sectionElementToScroll = items.find((c) => c.props.sectionId === sectionId)

            if (!sectionElementToScroll || !getListNodeById) {
                return
            }

            this.scrollToSection(getListNodeById, sectionId)
        }
    }

    render() {
        const { children } = this.props

        return <Wrapper ref={(c) => (this.parentElement = c)}>{children}</Wrapper>
    }
}
//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        saveComplete: editorSelectors.saveComplete(state.actionEditorReducer),
        savedAction: editorSelectors.savedAction(state.actionEditorReducer),
        isSavingExisting: editorSelectors.isSavingExisting(state.actionEditorReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinuteEditorListAutoScroller)

//#endregion
