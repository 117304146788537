import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')``

type Props = {
    title?: string
    className?: string
}

export const NewMinutesIcon = ({ title, className }: Props) => (
    <Svg viewBox="0 0 25.77 25" width="1em" height="1em" className={className}>
        <title>{title}</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Manager_Cards_copy" data-name="Manager Cards copy">
                <g id="toolbar">
                    <g id="Create_New_Button_Icon" data-name="Create New Button Icon">
                        <path
                            className="cls-1"
                            d="M4 14.59a.14.14 0 0 1-.11-.09.15.15 0 0 1 .07-.21l7-3.48a.15.15 0 0 1 .21.07.16.16 0 0 1-.07.22l-7 3.47a.12.12 0 0 1-.1.02z"
                        />
                        <path
                            className="cls-1"
                            d="M11 11.33h-.15a.49.49 0 0 1-.31-.61L13 4.15a.48.48 0 0 1 .91.31L11.45 11a.47.47 0 0 1-.45.33z"
                        />
                        <path
                            className="cls-1"
                            d="M11 11.44a.5.5 0 0 1-.41-.22l-2.7-3.34a.48.48 0 0 1 .81-.52l2.7 3.34a.48.48 0 0 1-.14.66.5.5 0 0 1-.26.08z"
                        />
                        <circle className="cls-1" cx={10.99} cy={10.95} r={0.8} />
                        <path
                            className="cls-1"
                            transform="rotate(-45 17.996 17.156)"
                            d="M11.29 15.64h13.39v3.03H11.29z"
                        />
                        <path
                            className="cls-1"
                            d="M13.67 23.62L10.15 25l1.38-3.53 2.14 2.15zM25.51 11.79a.75.75 0 0 0 .08-1L24.4 9.56a.74.74 0 0 0-1 .08l-1.05 1 2.14 2.15zM1.48 11a9.53 9.53 0 0 1 18.93-1.55l1.25-1.24A11 11 0 1 0 8.21 21.66l1.24-1.25A9.53 9.53 0 0 1 1.48 11z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </Svg>
)

export default NewMinutesIcon
