import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'

import * as requests from '../networkrequests/actions'
import { executeRequest } from 'businesslayer/request-executor'
import { pathOr } from 'rambdax'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

export const getActions = (minutesId) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')
    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)
        return translate('LOAD_ACTIONS_ERROR', {
            minutesId: minutesId,
            committeeId: committeeId,
            message: errorObject.message,
            code: httpCodeOrStack
        })
    }

    return executeRequest(requests.getActions(minutesId), null, onError, {
        isForeground: false
    })
}

export const getFilteredActions = (urlParams) => {
    const onError = (errorObject) => {
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)
        return translate('LOAD_ACTIONS_ERROR', {
            message: errorObject.message,
            code: httpCodeOrStack
        })
    }

    return executeRequest(requests.getFilteredActions(urlParams), null, onError, {
        isForeground: false
    })
}

export const exportActions = (committeeId, minutesId, sortField, repeatAction) => {
    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)

        return translate('EXPORT_MINUTES_ERROR', {
            committeeId: committeeId,
            minutesId: minutesId,
            message: errorObject.message,
            code: httpCodeOrStack
        })
    }

    const request = requests.exportActions(committeeId, minutesId, sortField)

    return executeRequest(request, null, onError, { repeatAction })
}

export const exportConsolidatedActions = (committeeIds, sortField, repeatAction) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)
        const currentCommitteeId = getSessionStorageItem('currentCommitteeId')

        // TODO: Add Error String
        return translate('EXPORT_MINUTES_ERROR', {
            committeeId: currentCommitteeId,
            minutesId: 'minutesId',
            message: errorObject.message,
            code: httpCodeOrStack
        })
    }

    const request = requests.exportConsolidatedActions(committeeIds, sortField)

    return executeRequest(request, null, onError, { repeatAction })
}

export const deleteAction = (minutesId, actionId, repeatAction, selectedAction?: any) => {
    const currentCommitteeId =
        selectedAction?.committee_id ?? getSessionStorageItem('currentCommitteeId')

    if (!currentCommitteeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)

        return translate('DELETE_ACTION_ERROR', {
            committeeId: currentCommitteeId,
            minutesId: minutesId,
            actionId: actionId,
            message: errorObject.message,
            code: httpCodeOrStack
        })
    }

    const request = requests.deleteAction(currentCommitteeId, minutesId, actionId)

    return executeRequest(request, null, onError, { repeatAction })
}

export const saveAction = (action, repeatAction) => {
    const currentCommitteeId = action.committee_id ?? getSessionStorageItem('currentCommitteeId')

    if (!currentCommitteeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const text = action.text.length > 50 ? `${action.text.substr(0, 50)}...` : action.text
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)
        return translate('SAVE_ACTION_ERROR', {
            actionText: text,
            message: errorObject.message,
            code: httpCodeOrStack
        })
    }

    return executeRequest(requests.saveAction(currentCommitteeId, action), null, onError, {
        repeatAction
    })
}

export const sendNotification = (notification, repeatAction) => {
    const currentCommitteeId =
        notification.committee_id ?? getSessionStorageItem('currentCommitteeId')

    if (!currentCommitteeId) {
        //TODO: Redirect ot login?
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''

        return translate('SEND_NOTIFICATION_ERROR', {
            committeeId: currentCommitteeId,
            minutesId: notification.minutesId,
            actionId: notification.actionId,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    const request = requests.sendNotification(currentCommitteeId, notification)

    return executeRequest(request, null, onError, repeatAction)
}

export const reorderActions = (actionItems, repeatAction) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)
        return `${i18n.t('GLOBAL_PROGRESS_ERROR')}, ${errorObject.message}: ${httpCodeOrStack}`
    }

    return executeRequest(requests.reorderActions(committeeId, actionItems), null, onError, {
        repeatAction
    })
}
export const actionComplete = (body) => {
    const onError = (errorObject) => {
        const httpCodeOrStack = pathOr(errorObject.stack, ['response', 'status'], errorObject)
        return translate('SAVE_ACTION_ERROR', {
            message: errorObject.message,
            code: httpCodeOrStack
        })
    }

    return executeRequest(requests.completeAction(body), null, onError, {})
}
