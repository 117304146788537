import React, { useState } from 'react'
import ExportActionsDialog from 'components/minutetaker/components/ExportActionsDialog'

export const useExportActionsModal = () => {
    const [showModal, setShowModal] = useState(false)

    const handleOpenExportModal = () => {
        setShowModal(true)
    }
    const handleCloseExportModal = () => {
        setShowModal(false)
    }

    const ExportModal = !!showModal ? (
        <ExportActionsDialog key="exportActionsModal" onClose={handleCloseExportModal} />
    ) : null
    return { ExportModal, handleOpenExportModal }
}
