import React from 'react'
import { MenuItem } from '@material-ui/core'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { formatTimezoneLabel } from 'common/formatters/date'

/**
 * Recommended to memoize the result when using this
 * Ex: useMemo(() => timezoneMenuItems(), []))
 */
export const timezoneMenuItems = (): JSX.Element[] => {
    const allTimeZones: Timezone[] = transientStorageManager.timeZones

    if (!allTimeZones) {
        return []
    }
    const menuItems = allTimeZones.map((val, index) => {
        const timezoneLabel = formatTimezoneLabel(val.attributes.utc_offset, val.attributes.name)
        return (
            <MenuItem key={val.id + index} className='dropDownFocus' value={val.id} data-testid={`TimeZoneSelect_TimeZone`}>
                {timezoneLabel}
            </MenuItem>
        )
    })

    return menuItems
}
