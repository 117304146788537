import React from 'react'
import { ReactSVG } from 'react-svg'
import { css } from '@emotion/react'

const baseStyle = css`
    height: 18px;
    width: 18px;
    fill: #fff;
    stroke: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 9px;
`

export const IconFailure = (props) => (
    <ReactSVG
        className={css`
            ${baseStyle};
            ${props.className ? props.className : ''};
        `}
        src="/images/svg/failed_icon-34.svg"
    />
)

export const IconNoActions = (props) => (
    <ReactSVG
        className={css`
            ${baseStyle};
            color: inherit;
            ${props.className ? props.className : ''};
        `}
        src="/images/svg/no_actions_icon.svg"
    />
)
