import React from 'react'

const NoActionIcon: React.FC = () => {
    return (
        <svg
            width="160"
            height="133"
            viewBox="0 0 160 133"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1637_96289)">
                <rect
                    x="162.186"
                    y="38.8252"
                    width="48"
                    height="110"
                    transform="rotate(30 162.186 38.8252)"
                    fill="#E6E6E6"
                />
                <rect
                    x="44.3674"
                    y="56.7373"
                    width="1"
                    height="87.1864"
                    transform="rotate(30 44.3674 56.7373)"
                    fill="url(#paint0_linear_1637_96289)"
                />
                <rect
                    x="132.764"
                    y="-0.364258"
                    width="1"
                    height="153"
                    transform="rotate(30 132.764 -0.364258)"
                    fill="url(#paint1_linear_1637_96289)"
                />
                <g filter="url(#filter0_d_1637_96289)">
                    <path
                        d="M44 42.5C44 35.8726 49.3726 30.5 56 30.5H104C110.627 30.5 116 35.8726 116 42.5V90.5C116 97.1274 110.627 102.5 104 102.5H56C49.3726 102.5 44 97.1274 44 90.5V42.5Z"
                        fill="white"
                        shapeRendering="crispEdges"
                    />
                    <rect
                        x="66.75"
                        y="49.25"
                        width="26.5"
                        height="34.5"
                        stroke="#282E37"
                        strokeWidth="1.5"
                        strokeDasharray="6 4"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_1637_96289"
                    x="40"
                    y="26.5"
                    width="112"
                    height="112"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="16" dy="16" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1637_96289"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1637_96289"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1637_96289"
                    x1="44.8674"
                    y1="56.7373"
                    x2="44.8674"
                    y2="143.924"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E71613" stopOpacity="0" />
                    <stop offset="1" stopColor="#E71613" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1637_96289"
                    x1="133.264"
                    y1="-0.364258"
                    x2="133.264"
                    y2="152.636"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E71613" />
                    <stop offset="1" stopColor="#E71613" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_1637_96289">
                    <path
                        d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5H148C154.627 0.5 160 5.87258 160 12.5V120.5C160 127.127 154.627 132.5 148 132.5H12C5.37258 132.5 0 127.127 0 120.5V12.5Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default NoActionIcon
