import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { executeRequest } from 'businesslayer/request-executor'
import * as requests from 'businesslayer/networkrequests/minutes'
import { translate } from 'components/shared/internationalization'
import { getSettings } from 'businesslayer/api/settings'
import emitter from 'common/util/events'
import { countlyEvent } from 'businesslayer/Countly'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import normalize from 'json-api-normalizer'
import { saveNewRewReportProperties } from 'businesslayer/networkrequests/minutes'

type ReportAction = () => void
export const getMinutesByCommittee = (committeeId, sortField, ascending, repeatAction) => {
    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_MINUTES_ERROR', {
            committeeId: committeeId,
            message: errorObject.message,
            code: httpCode
        })
    }

    return executeRequest(
        requests.getMinutesByCommittee(committeeId, sortField, ascending),
        null,
        onError,
        repeatAction
    )
}

export const deleteMinutes = (minutesId, repeatAction) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('DELETE_MINUTES_ERROR', {
            minutesId: minutesId,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }
    if (!currentCommitteeId) {
        //TODO: Redirect ot login?

        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }
    return executeRequest(
        requests.deleteMinutes(currentCommitteeId, minutesId),
        null,
        onError,
        repeatAction
    )
}

export const saveMinutes = (item, isBackground, repeatAction) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    if (!currentCommitteeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onSuccess = (result) => {
        if (result.minutesDocuments) {
            const id = Object.keys(result.minutesDocuments)[0]
            transientStorageManager.selectedMinuteItem = result.minutesDocuments[id]
            emitter.emit('selectedMinuteChanged', transientStorageManager.selectedMinuteItem)
        }
    }

    const onError = (errorObject) => {
        const title = item.title.length > 50 ? `${item.title.substr(0, 50)}...` : item.title
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('SAVE_MINUTES_ERROR', {
            minutesName: title,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    const request = requests.saveMinutes(currentCommitteeId, item)

    return executeRequest(request, onSuccess, onError, {
        repeatAction,
        isForeground: !isBackground
    })
}
export const saveMinutesProperties = (item: MinutesUnnormalized, isBackground, repeatAction) => {
    const committeeId = item.committee.id
    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onSuccess = (result) => {
        const normalizedResult = normalize(result)
        if (normalizedResult.minutesDocuments) {
            const id = Object.keys(normalizedResult.minutesDocuments)[0]
            transientStorageManager.selectedMinuteItem = normalizedResult.minutesDocuments[id]
            emitter.emit('selectedMinuteChanged', transientStorageManager.selectedMinuteItem)
        }
    }

    const onError = (errorObject) => {
        const title = item.title.length > 50 ? `${item.title.substr(0, 50)}...` : item.title
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('SAVE_MINUTES_ERROR', {
            minutesName: title,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    const request = requests.saveMinutesProperties(item)

    return executeRequest(request, onSuccess, onError, {
        repeatAction,
        isForeground: !isBackground
    })
}

export const saveNewReport = (newReportData: CreateReportPayload, repeatAction: ReportAction) => {
    const request = saveNewRewReportProperties(newReportData)

    return executeRequest(request, null, null, {
        repeatAction,
        isForeground: false
    })
}
export const getMinutesDetail = (
    minutesId = getSessionStorageItem('selectedMinuteItemId'),
    repeatAction?,
    networkOnly = false
) => {
    const selectedMinuteItem = transientStorageManager.selectedMinuteItem as any
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!networkOnly) {
        if (!minutesId && selectedMinuteItem) {
            return Promise.resolve(selectedMinuteItem)
        }

        if (selectedMinuteItem !== undefined && minutesId && selectedMinuteItem.id === minutesId) {
            return Promise.resolve(selectedMinuteItem)
        }
    }

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_MINUTE_ITEM_ERROR', {
            minutesId: getSessionStorageItem('selectedMinuteItemId'),
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    const request = requests.getMinutesDetail(minutesId)

    const executedRequest = executeRequest(request, null, onError, { repeatAction })

    return !!transientStorageManager.dateFormat
        ? executedRequest
        : getSettings().then(() => executedRequest)
}

export const setMinuteStatus = (minutesId, status: DocumentStatusKey, repeatAction) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onSuccess = () => {
        countlyEvent('MinStatus_Options', { statusOption: status })
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return httpCode
    }

    const request = requests.setMinuteStatus(committeeId, minutesId, status)

    return executeRequest(request, onSuccess, onError, { repeatAction })
}

export const exportMinutes = (committeeId, minutesId, minutesOnly = false, repeatAction) => {
    if (!committeeId) {
        //TODO: Redirect ot login?
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const request = requests.exportMinutes(committeeId, minutesId, minutesOnly)

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''

        return translate('EXPORT_MINUTES_ERROR', {
            committeeId: committeeId,
            minutesId: minutesId,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(request, null, onError, repeatAction)
}
export const exportAttendanceReport = (committeeId, payload) => {
    if (!committeeId) {
        //TODO: Redirect ot login?
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const request = requests.exportAttendanceReport(committeeId, payload)

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''

        return translate('EXPORT_REPORT_ERROR', {
            committeeId: committeeId,
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(request, null, onError, { isForeground: false })
}
export const exportActionlist = (committeeId) => {
    const request = requests.exportXlsList(committeeId)
    return executeRequest(request, null, { isForeground: false })
}
