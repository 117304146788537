import { emphasize } from '@material-ui/core/styles'
import { AtlasBlackNear } from 'assets/styles/variables'

export const chipInputStyles: any = (theme) => ({
    root: {
        flexGrow: 1,
        height: 350
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
        minHeight: '40px'
        // paddingLeft: '8px !important'
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
        // padding: '0px 7px'
    },
    chip: {
        // margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        )
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        fontWeight: 500
    },
    singleValue: {
        fontSize: 16
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16
    },
    paper: {
        maxHeight: 300,
        width: 325,
        maxWidth: 332,
        position: 'fixed',
        zIndex: 9999,
        marginTop: theme.spacing(1),
        overflowY: 'auto'
    },
    divider: {
        height: theme.spacing(2)
    }
})

export const atlasChipInputStyles: any = (theme) => ({
    root: {
        flexGrow: 1,
        height: 350
    },
    input: {
        // display: 'flex',
        padding: 0,
        height: 'auto',
        paddingLeft: '8px !important'
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
    },
    chip: {
        gap: '5px',
        margin: '5px'
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        )
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        fontWeight: 500
    },
    singleValue: {
        fontSize: 16
    },
    placeholder: {
        position: 'absolute',
        left: '10px',
        bottom: '8px',
        fontSize: 16,
        color: AtlasBlackNear,
        opacity: '0.42',
        userSelect: 'none'
    },
    paper: {
        maxHeight: 300,
        width: 325,
        maxWidth: 332,
        position: 'fixed',
        zIndex: 9999,
        marginTop: theme.spacing(1),
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    divider: {
        height: theme.spacing(2)
    }
})
