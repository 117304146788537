import React, { useState } from 'react'

export type InjectedPopoverButtonProps = {
    onClosePopover(...args: any[]): void
    onShowPopover(...args: any[]): void
    isOpen: boolean
    closeReason: string
    anchorElement: any
}

type PopoverButtonProps = {
    onClosePopover?(...args: any[]): void
    onShowPopover?(event: any): void
    children({ onClosePopover, onShowPopover }: InjectedPopoverButtonProps): React.ReactNode
}

export default function PopoverButton(props: PopoverButtonProps) {
    const [anchorElement, setAnchorElement] = useState(undefined)
    const [isOpen, setIsOpen] = useState(false)
    const [closeReason, setCloseReason] = useState('')

    const onClosePopover = (args) => {
        setIsOpen(false)
        setCloseReason(args!.closeReason ? args!.closeReason : closeReason)

        if (props.onClosePopover) {
            props.onClosePopover(args)
        }
    }

    const onShowPopover = (e) => {
        e.persist()
        e.preventDefault()
        setAnchorElement(e.currentTarget)
        setIsOpen(true)

        if (props.onShowPopover) {
            props.onShowPopover(e)
        }
    }

    return (
        <React.Fragment>
            {props.children({
                anchorElement: anchorElement,
                isOpen: isOpen,
                closeReason: closeReason,
                onShowPopover: onShowPopover,
                onClosePopover: onClosePopover
            })}
        </React.Fragment>
    )
}
