import { dataState, controlState, communicationState } from '../components/helpers/reducerHelpers'
import { createAction, handleActions } from 'redux-actions'

import blockingOperation from '../businesslayer/blockingOperation'

import { transientStorageManager } from '../businesslayer/minutesSessionStore'

import i18n from 'i18next'

export const actions = {
    toggleProgress: createAction('TOGGLE_PROGRES', (visible, lastAction, lastError, options) => {
        return { visible, lastAction, lastError, options }
    }),

    cancelBlockingOperation: createAction('CANCEL_BLOCKING_OPERATION'),

    resetLastError: createAction('RESET_LAST_ERROR'),
    setRestrictedError: createAction('SET_RESTRICTED_ERROR'),
    loadPlatformInfo: createAction('LOAD_PLATFORM_INFO')
}

//http://jamesknelson.com/5-types-react-application-state/
const initialState = {
    controlState: {
        isShowingFullscreenProgress: false,
        fullscreenProgressTitle: i18n.t('GLOBAL_PROGRESS_TITLE'),
        fullscreenProgressMessage: i18n.t('GLOBAL_PROGRESS_TITLE_DEFAULT_WORDING'),
        fullscreenProgressLastAction: null,
        hideActions: false
    },
    dataState: {
        platform: null
    },
    communicationState: {
        lastError: null,
        restrictedError: {
            error: '',
            assistance: ''
        }
    },
    sessionState: {}
}

//https://github.com/pburtchaell/redux-promise-middleware/blob/master/docs/introduction.md
//https://github.com/acdlite/redux-actions

let updatedState = null
const reducer = handleActions(
    {
        [actions.toggleProgress]: (state, action) => {
            let isVisible = action.payload.lastError ? true : action.payload.visible

            //It may happen that this action is handled by reducer already after cancelation initiated so we
            //explicitly set visible to false to prevent this flase triggering...
            if (isVisible && blockingOperation.isCancellationPending()) {
                isVisible = false
            }

            updatedState = controlState(state, {
                isShowingFullscreenProgress: isVisible
            })

            //Last action can be null as of 1.3
            updatedState = controlState(updatedState, {
                fullscreenProgressLastAction: action.payload.lastAction,
                hideActions: action.payload.options
            })

            //Same here: if cancellation pending in the operation we are not reporting last error
            return communicationState(updatedState, {
                lastError: blockingOperation.isCancellationPending()
                    ? null
                    : action.payload.lastError
            })
        },

        [actions.resetLastError]: (state, action) => {
            return communicationState(state, { lastError: null })
        },
        [actions.setRestrictedError]: (state, action) => {
            return communicationState(state, { restrictedError: action.payload })
        },

        [actions.cancelBlockingOperation]: (state, action) => {
            blockingOperation.cancel()
            return state
        },

        [actions.loadPlatformInfo]: (state, action) => {
            const platform = transientStorageManager.platform
            return dataState(state, { platform: platform })
        }
    },
    initialState
)

export default reducer
