/** @jsx jsx */
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'
import { getRandomHexString } from 'common/util/functions'
import { cx } from '@emotion/css'
import { atlasFontFamily, AtlasTypographyNames } from 'components/atlas-components/typography'

// This file contains styles which are used in more than one place

export const getIconButtonStyle = (isDisabled = false) => {
    return {
        color: isDisabled ? '#606772' : '#fff',
        paddingLeft: 30,
        cursor: isDisabled ? 'not-allowed' : 'pointer'
    }
}

export const getIconButtonIconStyle = () => {
    return {
        left: 0,
        top: 18
    }
}

export const getMessageBoxTitleStyle = () => {
    return {
        backgroundColor: '#00A6D0',
        color: '#fff',
        height: 100,
        fontSize: 18,
        padding: 0,
        paddingTop: 20,
        textAlign: 'center',
        width: '100%'
    }
}

export const EllipseHeading = styled('h1')<{ truncateWidth?: string | undefined }>`
    ${({ truncateWidth }) => !!truncateWidth && `max-width: ${truncateWidth};`};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
`
export const EllipseString = styled('div')<{ truncateWidth?: string | undefined }>`
    ${({ truncateWidth }) => !!truncateWidth && `max-width: ${truncateWidth};`};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
`
export const EllipsisCls = css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
`
export const Uppercase = styled.span`
    font-family: ${atlasFontFamily};
    text-transform: uppercase;
`
export const debugBorder = (color = undefined) => {
    // const colorPalette = ['#f0f', '#FF0']
    const borderColor =
        color || `#${getRandomHexString(16)}${getRandomHexString(16)}${getRandomHexString(16)}`
    return css`
        border: 1px solid ${borderColor};
    `
}

export const FlexFill = styled('span')`
    flex: 1;
`

export const Container = styled.div<{ width: number }>`
    width: ${({ width }) => width}px;
`

export const marginBetweenChildren = (spaceBetween: string, selector = '*') => css`
    ${selector}:first-child {
        margin-left: 0px;
    }
    > ${selector} {
        margin-left: ${spaceBetween};
    }
`

/**
 * Allows us to wrap emotion styled components
 * in a component with css class names
 */
export const withClasses = (...classNames) => {
    return (tag: any) => {
        const ComponentWithClasses = (props: any) => {
            return jsx(tag, {
                ...props,
                className: cx(
                    props.className,
                    ...classNames.map((cn) => (typeof cn === 'function' ? cn(props) : cn))
                )
            })
        }

        return ComponentWithClasses
    }
}

// Uses withClasses but is typed checked for Atlas font classes
export function withFont(fontClass?: AtlasTypographyNames) {
    return withClasses(fontClass)
}
