import { getJSON } from 'common/util/request'
import { makeFetcher } from 'businesslayer/networkrequests/swr-util'
import useSWR from 'swr'

export const getContacts = () => getJSON(`users`)
export const getCommitteContacts = (committeeId: string) =>
    getJSON(`committees/${committeeId}/users`)
export const getCurrentUser = () => getJSON(`users/current/details`, { shouldNormalize: false })
export const getAdminContacts = (committeeId: string) =>
    getJSON(`committees/${committeeId}/admins_by_committee`, { shouldNormalize: false })

export const useContactsSWR = () => {
    const url = `users`
    const fetcher = makeFetcher({ fetcherCallback })
    return useSWR<Attendee[]>(url, fetcher)
}

const fetcherCallback = (response: ContactsResponseUnnormalized): Attendee[] => {
    // Maybe sort
    const { data: contacts } = response
    const contactsArray = contacts.map((contact) => {
        const { id, attributes } = contact
        return { id, ...attributes }
    })
    return contactsArray
}
type ContactsResponseUnnormalized = {
    data: RawAttendee[]
    sort: string[]
}

type RawAttendee = {
    id: string
    attributes: Omit<Attendee, 'id'>
}
