import React, { useRef } from 'react'

//#region DnD

import { useDrop } from 'react-dnd'
import { ColorBaseGreyLight, ColorBaseBlueLight } from 'assets/styles/variables'
import { css, ClassNames } from '@emotion/react'

//#endregion

//#region Styles
const getFooterStyle: any = (isDragging, isDroppingOver, height) => {
    return css`
        display: ${isDragging ? 'flex' : 'none'};
        justify-content: center;
        align-items: center;
        background-color: ${isDroppingOver ? '#dff2f9' : '#fff'};
        min-height: 60px;
        height: ${isDroppingOver ? height + 'px' : '60px'};
        margin-bottom: 0;
        border-top: 1px solid ${ColorBaseGreyLight};
    `
}

const footerTextClass = css`
    color: ${ColorBaseBlueLight};
    font-size: 32px;
`

//#endregion

//#region Props

type Props = {
    onActionItemReorder: (...args: any[]) => void
    sectionId: number
    actionCount: number
    previewHeight: number
    isDragging: boolean
}

//#endregion

//#region Implementation

//That is a special component which is last item in the list view.
//It is designed to provide an area for drag and drop below the list
function MinuteActoinFooter(props: Props) {
    const ref = useRef(null)

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'actionItem',
        collect: (monitor) => ({
            isOver: !!monitor.canDrop() && !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        }),
        canDrop: (item: any) => {
            return item.index !== props.actionCount - 1
        },
        drop: (item: any) => {
            const origin = { sectionId: item.sectionId, sortOrder: item.index }
            const target = {
                sectionId: props.sectionId,
                sortOrder: null
            }
            props.onActionItemReorder({ origin, target })
        }
    })
    drop(ref)
    const markup = (
        <ClassNames>
            {({ css }) => (
                <div
                    ref={ref}
                    className={css`
                        ${getFooterStyle(props.isDragging && canDrop, isOver, props.previewHeight)}
                    `}>
                    <span
                        className={css`
                            ${footerTextClass}
                        `}></span>
                </div>
            )}
        </ClassNames>
    )

    return markup
}

export default MinuteActoinFooter
//#endregion
