import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme) => ({
    root: {
        postion: 'sticky',
        zIndex: 1000,
        width: '100%',
        height: 2,
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    },
    line: {
        backgroundColor: '#D5E3F1',
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#252C44'
        }
    }
}))

export default function LinearIndeterminate({ loading }) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {loading === true && <LinearProgress className={classes.line} />}
        </div>
    )
}
