import React, { FC, useMemo } from 'react'
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import { TextField } from '@material-ui/core'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { makeMuiFieldAdapter } from 'components/final-form/final-form-helper'
import { getAtlasHelperText } from 'components/text-field/input-error-text'
import { AtlasIcon } from 'web-components/atlas-icon'
import { AtlasBlackNearVar } from 'assets/styles/variables'
import { getDateMaskText } from 'common/formatters/date'
import i18n from 'common/util/internationalization'

export const AtlasDatePicker: FC<KeyboardDatePickerProps & TestIdAttribute> = ({
    helperText,
    value,
    'data-testid': data_testid,
    ...props
}) => {
    const { dateFormatInput, isDateInputMaskUS } = transientStorageManager
    const placeholder = getDateMaskText(isDateInputMaskUS)
    const AtlasHelperTextComponent = useMemo(() => getAtlasHelperText({ errorText: helperText }), [
        helperText
    ])

    return (
        <KeyboardDatePicker
            value={value || null}
            placeholder={placeholder}
            format={dateFormatInput}
            variant="inline"
            keyboardIcon={<AtlasIcon name="calendar" color={AtlasBlackNearVar} />}
            autoOk={true}
            helperText={AtlasHelperTextComponent}
            PopoverProps={{
                onBlur: (e) => {
                    !!props.onBlur && props.onBlur(e as any)
                },
                PaperProps: {
                    role: 'dialog',
                    'aria-label': i18n.t('DATE_PICKER'),
                    'aria-modal': 'true'
                }
            }}
            TextFieldComponent={TextField}
            inputProps={{ 'data-testid': data_testid }}
            KeyboardButtonProps={{ 'data-testid': `${data_testid}_Btn` } as any}
            {...props}
        />
    )
}

export const AtlasDatePickerAdapter = makeMuiFieldAdapter<
    KeyboardDatePickerProps & TestIdAttribute
>(AtlasDatePicker)
