import React, { FC } from 'react'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type Props = {
    name: AtlasTypographyNames
}
/**
 * Wrapper component to style stray text with atlas typography.
 * This provides easy auto complete since we typed css class names.
 */
export const Typography: FC<Props> = ({ name, children }) => {
    return <span className={name}>{children}</span>
}

export const atlasFontFamily = ['Source Sans Pro', 'sans-serif'].join(',')

export const atlasBillboardWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '64px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasBillboardGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '64px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasBillboard: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '64px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTitleDisplayGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '40px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTitleDisplayWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '40px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTitleDisplay: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '40px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTitleLargeLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '28px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: 'var(--link)'
}
export const atlasTitleLargeEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '28px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTitleLargeEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '28px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTitleLargeEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '28px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTitleLargeWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '28px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTitleLargeGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '28px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTitleLarge: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '28px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTitleMediumLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: 'normal',
    color: 'var(--link)'
}
export const atlasTitleMediumEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '22px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTitleMediumEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '22px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTitleMediumEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '22px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTitleMediumWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '22px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTitleMediumGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '22px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTitleMedium: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '22px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTitleSmallLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: 'var(--link)'
}
export const atlasTitleSmallEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTitleSmallEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTitleSmallEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTitleSmallWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTitleSmallGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTitleSmall: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasLabelLargeLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    color: 'var(--link)'
}
export const atlasTextBodyLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--link)'
}
export const atlasTextBodyEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasLabelLargeEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    color: 'var(--grey-dark)'
}
export const atlasLabelLargeEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    color: 'var(--white-100)'
}
export const atlasTextBodyEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasLabelLargeEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    color: 'var(--black-near)'
}
export const atlasTextBodyEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasLabelLargeWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    color: 'var(--white-100)'
}
export const atlasTextBodyWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasLabelLargeGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    color: 'var(--grey-dark)'
}
export const atlasTextBodyGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasLabelLarge: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    color: 'var(--black-near)'
}
export const atlasTextBody: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTextMediumLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    color: 'var(--link)'
}
export const atlasTextMediumEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTextMediumEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTextMediumEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTextMediumWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTextMediumGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTextMedium: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTextSmallLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '13px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: 'var(--link)'
}
export const atlasTextSmallEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '13px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTextSmallEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '13px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTextSmallEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '13px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasTextSmallWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasTextSmallGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasTextSmall: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasLabelSmallLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '1px',
    textAlign: 'center',
    color: 'var(--link)'
}
export const atlasTextXsmallLink: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: 'var(--link)'
}
export const atlasTextXsmallEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasLabelSmallEmphasisGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '1px',
    color: 'var(--grey-dark)'
}
export const atlasLabelSmallEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '1px',
    color: 'var(--white-100)'
}
export const atlasTextXsmallEmphasisWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasLabelSmallEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '1px',
    color: 'var(--black-near)'
}
export const atlasTextXsmallEmphasis: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}
export const atlasLabelSmallWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '1px',
    color: 'var(--white-100)'
}
export const atlasTextXsmallWhite: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: 'var(--white-100)'
}
export const atlasLabelSmallGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '1px',
    color: 'var(--grey-dark)'
}
export const atlasTextXsmallGrey: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: 'var(--grey-dark)'
}
export const atlasLabelSmall: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '1px',
    color: 'var(--black-near)'
}
export const atlasTextXsmall: CSSProperties = {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: 'var(--black-near)'
}

export type AtlasTypographyNames =
    | 'billboard-white'
    | 'billboard-grey'
    | 'billboard'
    | 'title-display-grey'
    | 'title-display-white'
    | 'title-display'
    | 'title-large-link'
    | 'title-large-emphasis-grey'
    | 'title-large-emphasis-white'
    | 'title-large-emphasis'
    | 'title-large-white'
    | 'title-large-grey'
    | 'title-large'
    | 'title-medium-link'
    | 'title-medium-emphasis-grey'
    | 'title-medium-emphasis-white'
    | 'title-medium-emphasis'
    | 'title-medium-white'
    | 'title-medium-grey'
    | 'title-medium'
    | 'title-small-link'
    | 'title-small-emphasis-grey'
    | 'title-small-emphasis-white'
    | 'title-small-emphasis'
    | 'title-small-white'
    | 'title-small-grey'
    | 'title-small'
    | 'label-large-link'
    | 'text-body-link'
    | 'text-body-emphasis-grey'
    | 'label-large-emphasis-grey'
    | 'label-large-emphasis-white'
    | 'text-body-emphasis-white'
    | 'label-large-emphasis'
    | 'text-body-emphasis'
    | 'label-large-white'
    | 'text-body-white'
    | 'label-large-grey'
    | 'text-body-grey'
    | 'label-large'
    | 'text-body'
    | 'text-medium-link'
    | 'text-medium-emphasis-grey'
    | 'text-medium-emphasis-white'
    | 'text-medium-emphasis'
    | 'text-medium-white'
    | 'text-medium-grey'
    | 'text-medium'
    | 'text-small-link'
    | 'text-small-emphasis-grey'
    | 'text-small-emphasis-white'
    | 'text-small-emphasis'
    | 'text-small-white'
    | 'text-small-grey'
    | 'text-small'
    | 'label-small-link'
    | 'text-xsmall-link'
    | 'text-xsmall-emphasis-grey'
    | 'label-small-emphasis-grey'
    | 'label-small-emphasis-white'
    | 'text-xsmall-emphasis-white'
    | 'label-small-emphasis'
    | 'text-xsmall-emphasis'
    | 'label-small-white'
    | 'text-xsmall-white'
    | 'label-small-grey'
    | 'text-xsmall-grey'
    | 'label-small'
    | 'text-xsmall'
    | 'ellipsis-string'
