import { minutesDocumentsList } from 'businesslayer/networkrequests/minutesDocuments'
import { executeRequest } from 'businesslayer/request-executor'

export const getMinutesDocuments = (page, status, committeeId, sortBy, order, repeatAction) => {
    const request = minutesDocumentsList(page, status, committeeId, sortBy, order)

    const result = executeRequest(request, null, null, { repeatAction, isForeground: false })

    return result
}
