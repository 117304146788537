import { postJSON } from 'common/util/request'

export const tokenEndpoint = (committeeId: string, minutesId: string) =>
    postJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/collaboration_token`,
        JSON.stringify({
            auth: {
                collaboration: {
                    '*': { role: 'writer' }
                }
            }
        }),
        {
            shouldNormalize: false
        }
    )
