import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
    Tooltip
} from '@material-ui/core'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import {
    AtlasBlackNear,
    AtlasDataviz_02,
    AtlasGreyLight,
    ColorBaseMulberryBlue,
    ColorEastBay,
    AtlasStatusAlertVar
} from 'assets/styles/variables'
import {
    MinutesCardOverflow,
    OptionType
} from 'components/popovers/minutes-card-popover/minutes-card-popover-button'

import * as React from 'react'

import { FC } from 'react'
import { countlyEvent } from 'businesslayer/Countly'
import i18n from 'common/util/internationalization'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { isBeforeToday } from 'common/util/date'
import { formatDate as formatedDate } from 'common/formatters/date'
import { AtlasIcon } from 'web-components/atlas-icon'
import { formatDate } from 'common/formatters/date'
import { headCells, mapDocumentListsForTable, statusData } from './MinutesDocumentUtils'
import { CustomSortIcon } from 'assets/icons/CustomSortIcon'
import { DOCUMENT_STATUS } from 'components/minutetaker/components/InvitationView/types'

interface openMinutesParams {
    id: string
    status: DocumentStatusKey
}
type Props = {
    statusFilter: DocumentStatusFilter
    onOpenMinutes: (paramInfo: openMinutesParams) => void
    onClickOptions: (optionValue: OptionType, minutesId: any, committeeId: string) => void
    documentLists: DocumentsList[]
    handlePageChange: any
    page: any
    handleSortBy: any
    isAdminOfCommittee: any
    totalDocuments: number
    sortBy: string
    order: string
}
const useStyles = makeStyles((theme) => ({
    tableRow: {
        '& > *': {
            borderBottom: 'none'
        },
        borderRadius: '10px',
        outline: `1px solid ${AtlasGreyLight}`,
        textAlign: 'left'
    },
    tableContainer: {
        padding: '10px',
        alignItems: 'center',
        overflowX: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            background: 'white',
            width: '100%'
        },
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#acb1b8',
            borderRadius: '4px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            borderRadius: '4px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#acb1b8'
        }
    },
    tableHead: (props: { isAdmin: boolean }) => ({
        color: `${AtlasBlackNear}`,
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        width: 'auto',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingLeft: '10px',
        cursor: 'pointer',
        '&:nth-child(1)': {
            width: '500px',
            maxWidth: '500px'
        },
        [theme.breakpoints.down('md')]: {
            '&:nth-child(1)': {
                width: '300px !important'
            }
        },
        '@media (max-width: 1367px)': {
            '&:nth-child(1)': {
                width: '170px !important'
            }
        },
        '&:nth-last-child(1)': props.isAdmin
            ? {
                  width: '100px',
                  textAlign: 'right',
                  cursor: 'default',
                  '& div': {
                      float: 'right'
                  }
              }
            : {},
        '&:nth-last-child(2)': props.isAdmin
            ? {
                  width: '100px'
              }
            : {}
    }),
    tableHead1: {
        display: 'flex',
        alignItems: 'center',
        '&:focus, &:active': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    titleHyperlink: {
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        textTransform: 'none',
        color: `${ColorEastBay}`,
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'left',
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    openBtn: {
        textDecoration: 'none',
        textTransform: 'none',
        color: `${ColorEastBay}`,
        fontWeight: 600,
        lineHeight: '18px',
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    openBtnRight: {
        textTransform: 'none',
        color: `${ColorEastBay}`,
        fontWeight: 600,
        lineHeight: '18px',
        float: 'right'
    },
    rowData: {
        color: `${AtlasBlackNear}`,
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        padding: '8px',
        columnGap: '1rem',
        justifyContent: 'left',
        width: '100%',
        whiteSpace: 'normal',
        overflow: 'hidden',
        borderBottom: 'none',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '&:nth-last-child(1)': {
            width: '100px',
            textAlign: 'right'
        }
    },
    rowDataCommitteeName: {
        fontWeight: 400
    },
    statusColumn: {
        display: 'grid',
        gridTemplateColumns: '1.2rem 3.9rem',
        justifyContent: 'left',
        alignItems: 'center'
    },
    inReviewBox: {
        background: `${AtlasDataviz_02}`,
        height: '12px',
        maxWidth: '12px',
        borderRadius: '2px'
    },
    inDraftBox: {
        background: `#FFB400`,
        height: '12px',
        maxWidth: '12px',
        borderRadius: '2px'
    },
    finalDraftBox: {
        background: `#D64933`,
        height: '12px',
        maxWidth: '12px',
        borderRadius: '2px'
    },
    approvedBox: {
        background: `#3E8914`,
        height: '12px',
        maxWidth: '12px',
        borderRadius: '2px'
    },
    showMoreBtn: {
        textTransform: 'none',
        color: `${ColorEastBay}`,
        fontWeight: 600,
        lineHeight: '20px',
        border: `1px solid ${ColorEastBay}`,
        padding: '8px 12px 8px 12px',
        maxWidth: '169px',
        display: 'block',
        margin: 'auto',
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    dueDateLabel: {
        fontWeight: 400,
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    overDueIcon: {
        fontWeight: 600,
        display: 'flex',
        gap: '0.5rem',
        color: '#935206'
    }
}))

const EnhancedTableHead = (props) => {
    const { order, sortBy, handleSortBy, isAdmin } = props
    const classes = useStyles({ isAdmin })

    const handleSortOnClick = React.useCallback(
        (id) => {
            const mode = order === 'asc' && sortBy === id ? 'desc' : 'asc'
            if (id === 'options') return
            handleSortBy(id, mode)
        },
        [handleSortBy, order, sortBy]
    )
    const mapHeaderCell = React.useMemo(() => headCells(isAdmin) ?? [], [isAdmin])
    return (
        <TableHead>
            <TableRow>
                {mapHeaderCell.map((headCell) =>
                    headCell.id.length > 0 ? (
                        <TableCell
                            key={headCell.id}
                            align={headCell.id !== 'title' ? 'left' : 'left'}
                            style={{ borderBottom: 'none' }}
                            className={`${classes.tableHead}`}
                            sortDirection={sortBy === headCell.id ? order : false}
                            role="columnheader">
                            <Box
                                className={classes.tableHead1}
                                onClick={() => handleSortOnClick(headCell.id)}
                                aria-label={headCell.label}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        handleSortOnClick(headCell.id)
                                    }
                                }}
                                aria-sort={
                                    sortBy === headCell.id
                                        ? order === 'asc'
                                            ? 'ascending'
                                            : 'descending'
                                        : 'none'
                                }
                                tabIndex={0}>
                                {headCell.label}
                                {headCell.id && sortBy === headCell.id && (
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label={` ${
                                            order === 'asc' ? 'ascending' : 'descending'
                                        }`}>
                                        <CustomSortIcon
                                            isAscending={order === 'asc'}
                                            selected={sortBy === headCell.id}
                                        />
                                    </IconButton>
                                )}
                            </Box>
                        </TableCell>
                    ) : (
                        <td></td>
                    )
                )}
            </TableRow>
        </TableHead>
    )
}

const Minutesdocuments: FC<Props> = ({
    statusFilter,
    onClickOptions,
    onOpenMinutes,
    documentLists,
    handlePageChange,
    page,
    handleSortBy,
    isAdminOfCommittee,
    totalDocuments,
    sortBy,
    order
}) => {
    const { documentsData, isAdmin } = React.useMemo(
        () => mapDocumentListsForTable(documentLists, isAdminOfCommittee),
        [documentLists, isAdminOfCommittee]
    )
    const classes = useStyles({ isAdmin })
    const statusBox = React.useCallback((status) => statusData(status, classes), [classes])

    const handleClickOptions = React.useCallback(
        (optionValue, minutesList) => {
            onClickOptions(optionValue, minutesList, minutesList.committee_id)
        },
        [onClickOptions]
    )
    const dispalyDueDate = (minutesList) => {
        if (
            minutesList.status === DOCUMENT_STATUS.IN_REVIEW &&
            minutesList.review?.due_date &&
            !minutesList.review?.review_completed &&
            minutesList.review?.is_invited
        ) {
            const { dateFormat } = transientStorageManager
            const isOverdue = isBeforeToday(minutesList.review.due_date)
            const formattedDueDate = formatedDate({
                date: minutesList.review.due_date,
                dateFormat: dateFormat
            })
            return (
                <Box data-testid="overDue">
                    <Box className={classes.dueDateLabel}>
                        {i18n.t('REVIEW_DUE_DATE')}: {formattedDueDate}
                        {isOverdue && (
                            <Box className={classes.overDueIcon}>
                                <AtlasIcon
                                    name={'status-alert'}
                                    size={16}
                                    color={AtlasStatusAlertVar}
                                    aria-label={i18n.t('OVERDUE_ACTION_LIST_LABEL')}
                                />
                                {i18n.t('OVERDUE_ACTION_LIST_LABEL')}
                            </Box>
                        )}
                    </Box>
                </Box>
            )
        }
        return null
    }

    const filteredDocumentsData = React.useMemo(() => {
        if (documentLists) {
            if (statusFilter && statusFilter !== 'all') {
                return documentsData.filter((document) => document.status === statusFilter)
            } else {
                return documentsData
            }
        }
        return []
    }, [documentsData, statusFilter, documentLists])

    const truncateTitle = (title, limit = 366) =>
        title.length > limit ? `${title.slice(0, limit)}...` : title

    const renderTitleLink = (title, minutesId, documentStatus) => {
        return (
            <div
                id="ContentContainer_ContentBody"
                tabIndex={0}
                onClick={() => {
                    countlyEvent('Mgr_OpenMinutes')
                    onOpenMinutes({ id: minutesId, status: documentStatus })
                }}
                onKeyPress={() => {
                    countlyEvent('Mgr_OpenMinutes')
                    onOpenMinutes({ id: minutesId, status: documentStatus })
                }}
                className={classes.titleHyperlink}>
                {truncateTitle(title)}
            </div>
        )
    }
    return (
        <TableContainer className={classes.tableContainer}>
            <Table
                style={{
                    borderSpacing: '0 10px',
                    tableLayout: 'fixed',
                    maxWidth: '100%'
                }}>
                <EnhancedTableHead
                    handleSortBy={handleSortBy}
                    sortBy={sortBy}
                    order={order}
                    isAdmin={isAdmin}
                />
                <TableBody>
                    {documentsData &&
                        filteredDocumentsData.map((minutesList, index) => {
                            const {
                                labelId,
                                title,
                                committee_name,
                                meeting_dates,
                                updated_at,
                                status,
                                minutesId,
                                documentStatus,
                                canDuplicate,
                                canExportMinutes,
                                committee_id
                            } = minutesList
                            const isVisible = isAdminOfCommittee(committee_id) && !!minutesId

                            /* Customized Tooltip theme */
                            const theme = createMuiTheme({
                                overrides: {
                                    MuiTooltip: {
                                        tooltip: {
                                            fontFamily: 'Source Sans Pro'
                                        }
                                    }
                                }
                            })
                            return (
                                <React.Fragment key={index}>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            align="left"
                                            padding="default"
                                            className={classes.rowData}
                                            role="cell">
                                            <ThemeProvider theme={theme}>
                                                {title.length > 366 ? (
                                                    <Tooltip
                                                        title={title}
                                                        PopperProps={{
                                                            style: { minWidth: '160px' }
                                                        }}
                                                        aria-label={title}
                                                        placement="bottom-start">
                                                        <span>
                                                            {renderTitleLink(
                                                                title,
                                                                minutesId,
                                                                documentStatus
                                                            )}
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <span>
                                                        {renderTitleLink(
                                                            title,
                                                            minutesId,
                                                            documentStatus
                                                        )}
                                                    </span>
                                                )}
                                            </ThemeProvider>
                                            {dispalyDueDate(minutesList)}
                                        </TableCell>

                                        <TableCell
                                            className={`${classes.rowDataCommitteeName}${classes.rowData}`}
                                            padding="default"
                                            align="left"
                                            role="cell">
                                            {committee_name}
                                        </TableCell>
                                        <TableCell
                                            padding="default"
                                            className={`${classes.rowDataCommitteeName}${classes.rowData}`}
                                            align="left"
                                            role="cell">
                                            {formatDate({
                                                date: meeting_dates[0]?.start_date
                                            }) ?? i18n.t('NO_DUE_DATE')}
                                        </TableCell>
                                        <TableCell
                                            padding="default"
                                            className={`${classes.rowDataCommitteeName}${classes.rowData}`}
                                            align="left"
                                            role="cell">
                                            {formatDate({ date: updated_at })}
                                        </TableCell>
                                        <TableCell
                                            padding="default"
                                            className={classes.rowData}
                                            align="left"
                                            role="cell">
                                            <Box className={classes.statusColumn}>
                                                {statusBox(status)}
                                            </Box>
                                        </TableCell>
                                        {isAdmin && (
                                            <TableCell
                                                padding="default"
                                                className={classes.rowData}
                                                align="left"
                                                role="cell">
                                                <Box>
                                                    {isVisible ? (
                                                        <MinutesCardOverflow
                                                            data-testid="MinutesManager_MinutesOptions_Btn"
                                                            isVisible={isVisible}
                                                            canDuplicate={canDuplicate}
                                                            canExportMinutes={canExportMinutes}
                                                            onClickOptions={(e) =>
                                                                handleClickOptions(e, minutesList)
                                                            }
                                                        />
                                                    ) : (
                                                        <Button
                                                            disabled
                                                            className={classes.openBtn}></Button>
                                                    )}
                                                </Box>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    <tr style={{ height: '10px' }}></tr>
                                </React.Fragment>
                            )
                        })}
                </TableBody>
            </Table>
            {totalDocuments > documentLists.length && (
                <Button
                    disableFocusRipple
                    disableRipple
                    onClick={() => handlePageChange(page + 1)}
                    data-testid="ShowMore_Btn"
                    aria-label={i18n.t('SHOW_MORE_DATA')}
                    className={classes.showMoreBtn}>
                    {i18n.t('SHOW_MORE_DATA')}
                </Button>
            )}
        </TableContainer>
    )
}

export default Minutesdocuments
