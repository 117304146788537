import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

//#region Components
import MinutesDraftEditor from './sectionlist/MinuteEditorListView'
import { isNil } from 'rambdax'
import ActionList from 'components/minutetaker/actionlist/ActionListView'
//#endregion

//#region Redux
import styled from '@emotion/styled'
import MinutesReview from './MinutesReview'
import { setSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { SavingProvider } from 'components/contexts/saving'
//#endregion

//#region Props

enum SelectedTab {
    Taker = 0,
    Actions = 1
}

enum DocumentView {
    Draft = 0,
    InReview = 1,
    FinalDraft = 2,
    Approved = 3
}

type Props = {
    minutesActions: ActionItem[]
    onActionChecked: (...args: any[]) => void
    selectedTab: number
    currentMinuteItem: any
    onStatusChange: (status: DocumentStatusKey) => void
    history: any
    reloadActions: () => void
    setIsLoading: any
} & RouteComponentProps

//#endregion

//#region Implementation
const MinutesDocumentDetail = (props: Props) => {
    const { currentMinuteItem, history } = props

    useEffect(() => {
        // Set selectedActionId in sessionStorage based on the url
        history.listen((location) => {
            if (location.state && !isNil(location.state.actionId)) {
                setSessionStorageItem('selectedActionItemId', location.state.actionId)
            }
        })
    }, [history])

    useEffect(() => {
        // We should avoid reading/writing from the session storage like this unless we are
        // preserving state across page refresh. The problem is that storing the
        // selectedMinuteItemId in session storage was implemented this way since the beginning
        // and is tangled up in redux. This will require a tech debt story to refactor.
        setSessionStorageItem('selectedMinuteItemId', currentMinuteItem.id)
    }, [currentMinuteItem])

    const getDocumentComponent = (documentStatus: DocumentView) => {
        return documentStatus === DocumentView.InReview ? (
            // Review Page
            <MinutesReview
                onStatusChange={props.onStatusChange}
                minutesActions={props.minutesActions}
            />
        ) : (
            // Draft, Review (Pre Review Process), Final, Draft, Approved
            <MinutesDraftEditor
                onStatusChange={props.onStatusChange}
                onActionChecked={props.onActionChecked}
                setIsLoading={props.setIsLoading}
            />
        )
    }

    const showActions = props.selectedTab === SelectedTab.Actions
    // TODO: will be used for rendering (review, final draft, and approved) pages
    const currentDocumentView = determineDocumentView(currentMinuteItem.attributes.status)
    const DocumentComponent = getDocumentComponent(currentDocumentView)
    return (
        <>
            {/* We conditionally hide the Minutes Document in order to optimize the
                transition from Actions to Document view. Unmounting and Mounting the Document is expensive!
                TODO: future improvement virtual scroll or lazy load the sections on document. */}
            <SavingProvider>
                <DocumentDetailContainer isHidden={showActions}>
                    {DocumentComponent}
                </DocumentDetailContainer>
            </SavingProvider>
            {showActions && (
                <ActionList
                    onDocumentStatusChange={props.onStatusChange}
                    reloadActions={props.reloadActions}
                />
            )}
        </>
    )
}

const DocumentDetailContainer = styled('div')<{ isHidden: boolean }>`
    display: ${({ isHidden }) => (isHidden ? 'none' : 'inherit')};
    height: 100%;
`

const determineDocumentView = (status) => {
    return status === 'draft'
        ? DocumentView.Draft
        : status === 'in_review'
        ? DocumentView.InReview
        : status === 'final_draft'
        ? DocumentView.FinalDraft
        : status === 'approved'
        ? DocumentView.Approved
        : DocumentView.Draft
}

export default withRouter(MinutesDocumentDetail)

//#endregion
