import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import { resources } from 'assets/strings'

i18n.use(reactI18nextModule as any) // passes i18n down to react-i18next
    .init({
        resources: resources(),
        lng: 'en',
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
            prefix: '${',
            suffix: '}'
        }
    })

export default i18n
