import { putJSON, postJSON, deleteJSON, getJSON } from 'common/util/request'
import { serialize } from 'businesslayer/networkrequests/sections/serializer'

export const deleteSection = (committeeId, minutesId, sectionId) => {
    return deleteJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/minutes_sections/${sectionId}`
    )
}

export const saveSection = (committeeId, minutesId, item) => {
    const jsonApiBody = serialize(item)

    if (item.id) {
        return putJSON(
            `committees/${committeeId}/minutes_documents/${minutesId}/minutes_sections/${item.id}`,
            jsonApiBody
        )
    } else {
        return postJSON(
            `committees/${committeeId}/minutes_documents/${minutesId}/minutes_sections`,
            jsonApiBody
        )
    }
}

// export const useMinutesSectionsSWR = (minutesId, options?: RequestOptionsSWR) => {
//     const url = `minutes_sections/fetchall/${minutesId}`
//     return useSWRRequest(url, { ...options })
// }

export const getSections = (minutesId) => {
    return getJSON(`minutes_sections/fetchall/${minutesId}`)
}

export const saveSectionOrder = (committeeId, sections) => {
    const templateListObject = (): any => {
        return {
            data: []
        }
    }

    const templateObject = (sectionId, order) => {
        return {
            type: 'minutes_sections',
            id: sectionId,
            attributes: {
                order: order
            }
        }
    }

    const requestData = templateListObject()

    sections.forEach((c) => {
        if (c.sectionType !== 'minutes') {
            return
        }
        requestData.data.push(templateObject(c.id, c.order))
    })

    const jsonApiBody = JSON.stringify(requestData)

    const minutesId = sections[0].minutesId

    return postJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/minutes_sections/reorder`,
        jsonApiBody
    )
}

export function updateSectionContent(
    committeeId,
    minutesId,
    { sectionId, content, reviewContent }
) {
    const buildRequestBody = ({ sectionId, content }) => {
        return {
            data: {
                type: 'minutes_sections',
                id: sectionId,
                attributes: {
                    ...(typeof content === 'string' ? { html_body: content } : {}),
                    ...(typeof reviewContent === 'string'
                        ? { review_html_body: reviewContent }
                        : {})
                }
            }
        }
    }

    const requestData = buildRequestBody({ sectionId, content })
    const jsonApiBody = JSON.stringify(requestData)

    return putJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/minutes_sections/${sectionId}/html`,
        jsonApiBody
    )
}

export function updateSectionName(committeeId, minutesId, { sectionId, name }) {
    const buildRequestBody = ({ sectionId, name }) => {
        return {
            data: {
                type: 'minutes_sections',
                id: sectionId,
                attributes: {
                    name
                }
            }
        }
    }

    const requestData = buildRequestBody({ sectionId, name })
    const jsonApiBody = JSON.stringify(requestData)

    return putJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/minutes_sections/${sectionId}/name`,
        jsonApiBody
    )
}
