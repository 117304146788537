import { useState, useEffect, useCallback } from 'react'
import { useCommitteesActions, useCommitteesState } from 'components/contexts/committee-context'
import { useAboutModal, useMinutesPropertiesModal } from 'components/modals'
import { saveMinutesProperties } from 'businesslayer/api/minutes'
import { useHistory } from 'react-router-dom'
import {
    useRouteStateActions,
    useRouteState
} from 'components/contexts/route-context/route-context'
import { useDeleteMinutesConfirmation } from 'components/modals/delete-minutes-confirmation/use-delete-minutes-confirmation'
import { useExportActionsModal } from 'components/modals/export-actions-modal/use-export-actions-modal'
import { deleteMinutes, useBookDetailsSWR } from 'businesslayer/networkrequests'
import { countlyEvent } from 'businesslayer/Countly'
import { MinutesCardPopoverDispatcher } from 'components/popovers/minutes-card-popover/minutes-card-popover-dispatcher'
import { pathOr, union, pluck, path } from 'rambdax'
import { getBookDetailsForCreateMinutes } from './book-helpers'
import {
    updateMinutesItemOfCommittee,
    revalidateMinutesOfCommittee
} from 'businesslayer/networkrequests/minutes/use-minutes-of-committee'
import blockingOperation from 'businesslayer/blockingOperation'
import { getSearchParameter } from 'components/helpers/DomHelper'
import emitter from 'common/util/events'

export type RequestedBook = {
    bookId: string | null
    bookCommitteeId: string | null
}
const EMPTY_REQUESTED_BOOK: RequestedBook = {
    bookId: null,
    bookCommitteeId: null
}

/**
 * Logic for Minutes Manager Page
 */
export const useMinutesManager = () => {
    const history = useHistory()
    const { setRouteState } = useRouteStateActions()
    const { currentRouteState } = useRouteState()
    const [requestedBook, setRequestedBook] = useState<RequestedBook>(EMPTY_REQUESTED_BOOK)
    const { setStaticRoleMode, setCurrentCommittee } = useCommitteesActions()
    const { committees, adminCommittees, currentCommittee } = useCommitteesState()
    const [deleteResponse, setDeleteResponse] = useState<string>('')
    const [openCommittees, setOpenCommittees] = useState<string[]>(
        currentRouteState.expandedCommittees || [currentCommittee.id]
    )

    const [updatedMinutesItem, setUpdatedMinutesItem] = useState<any>()
    const { data: bookDetails } = useBookDetailsSWR(requestedBook.bookId, currentCommittee.id)

    // Filter State
    const [statusFilter, setStatusFilter] = useState<DocumentStatusFilter>('all')
    const [isDeleting, setIsdeleting] = useState<boolean>(false)

    /** * * Set currentRouteState on Unmount * * * * *
     * This allows any component to route away from this
     * page without having to remember to pass around the state.
     * currentRouteState is just preserving state across mounts/routes
     * NOT acting as a source of truth.
     */
    useEffect(() => {
        return () => {
            setRouteState({ statusFilter, expandedCommittees: openCommittees })
        }
    }, [statusFilter, openCommittees, setRouteState])

    /**
     * Clean up the url by removing the search parameters
     * from initially launching the app.
     */
    useEffect(() => {
        const searchParams = path<string>('location.search', history)
        if (!!searchParams) {
            const bookIdParam = getSearchParameter(window.location, ['bookId', 'bookid']) as
                | string
                | null
            const bookCommitteeIdParam = getSearchParameter(window.location, [
                'committeeId',
                'committeeid'
            ]) as string | null

            setRequestedBook({ bookId: bookIdParam, bookCommitteeId: bookCommitteeIdParam })
            history.replace('/')
        }
    }, [history])
    /**
     * Use the access role of the current Committee
     * instead of what "role" they entered the app with
     */
    useEffect(() => {
        setStaticRoleMode(false)
    }, [setStaticRoleMode])

    // Getters

    /**
     * Returns boolean if User is admin of a given committeeId
     * taking into account top_level_admin
     */
    const isAdminOfCommittee = (committeeId: string | number) => {
        return !!adminCommittees.find((ac) => +ac.id === +committeeId)
    }

    // = = = = = = = = = = = = =
    // Modals
    // = = = = = = = = = = = = =

    // Minutes Properties Modal (create, edit, duplicate)
    const handleSaveMinutesItem = async ({
        updatedMinutesItem
    }: {
        updatedMinutesItem: MinutesUnnormalized
    }) => {
        if (!updatedMinutesItem) return
        setUpdatedMinutesItem(updatedMinutesItem)
        const committeeId = updatedMinutesItem.committee.id
        updateMinutesItemOfCommittee(updatedMinutesItem, committeeId, false)
        const savedMinutes = await saveMinutesProperties(
            updatedMinutesItem,
            false,
            saveMinutesProperties.bind(null, updatedMinutesItem, false)
        )
        revalidateMinutesOfCommittee(committeeId)

        const isCreatingNewMinutes = !updatedMinutesItem.id
        const isDuplicatingMinutes = !!updatedMinutesItem.duplicateOption
        if (isCreatingNewMinutes || isDuplicatingMinutes) {
            // Expand Committee for Minutes Document created
            const committeeId = path<string>('data.relationships.committee.data.id', savedMinutes)
            !!committeeId && handleToggleCommitteeOpen(committeeId, true)

            // Route to Taker of created Minutes Document
            const newMinutesId = pathOr('', 'data.id', savedMinutes)
            !!newMinutesId && handleOpenMinutes({ id: newMinutesId, status: 'draft' })
        }
        blockingOperation.hideProgress()
    }

    const canAddMinutes = adminCommittees.length > 0
    const { MinutesPropertiesModal, handleOpenMinutesModal } = useMinutesPropertiesModal({
        canAddMinutes,
        onSave: handleSaveMinutesItem
    })
    if (canAddMinutes) {
        emitter.emit('canAddMinutes')
    }
    // About Modal
    const { AboutModal, handleOpenAboutModal } = useAboutModal()

    // Consolidated Actions Modal
    const { ExportModal, handleOpenExportModal } = useExportActionsModal()

    // Delete Minutes Confirmation
    const handleDeleteMinutesConfirmed = async (committeeId, minutesId) => {
        setIsdeleting(true)
        const response = await deleteMinutes(committeeId, minutesId)
        setDeleteResponse(response)
        setIsdeleting(false)
    }
    const {
        DeleteMinutesConfirmation,
        handleOpenDeleteMinutesConfirmation
    } = useDeleteMinutesConfirmation({ onConfirm: handleDeleteMinutesConfirmed })

    const modals = [MinutesPropertiesModal, AboutModal, ExportModal, DeleteMinutesConfirmation]

    // = = = = = = = = = =
    // Handlers
    // = = = = = = = = = =

    // Committee Accordions
    const handleToggleCommitteeOpen = useCallback(
        (id: string, shouldOpen: boolean) => {
            if (shouldOpen) {
                setCurrentCommittee(id.toString())
            }
            setOpenCommittees((prevState) => {
                const newOpenCommittees = shouldOpen
                    ? union(prevState, [id])
                    : prevState.filter((c) => +c !== +id)
                return newOpenCommittees
            })
        },
        [setCurrentCommittee]
    )
    const handleExpandAll = useCallback(() => {
        const ids = pluck('id')(committees)
        setOpenCommittees(ids)
    }, [committees])

    const handleCollapseAll = useCallback(() => {
        setOpenCommittees([])
    }, [])

    // Minutes Card
    const handleOpenMinutes = useCallback(
        ({ id, status }: { id: string; status: DocumentStatusKey }) => {
            status === 'in_review' && countlyEvent('Reviewer_OpenMinutes')

            history.push({ pathname: `/taker/${id}`, state: { from: '/' } })
        },
        [history]
    )

    // Minutes Overflow Actions
    const handleClickMinutesOptions = MinutesCardPopoverDispatcher({
        history,
        handleOpenMinutesModal,
        handleOpenExportModal,
        handleOpenDeleteMinutesConfirmation
    })

    // Linked Book
    useEffect(() => {
        const { bookId, bookCommitteeId } = requestedBook
        if (!!bookId && !!bookDetails) {
            const validBookDetails = getBookDetailsForCreateMinutes(
                bookDetails,
                bookId,
                bookCommitteeId,
                committees
            )

            // Open Existing Linked Minutes
            if (!!validBookDetails && !!validBookDetails.minutesDocument.id) {
                setCurrentCommittee(validBookDetails.committee.id)
                handleOpenMinutes({
                    id: validBookDetails.minutesDocument.id,
                    status: validBookDetails.minutesDocument.status || 'draft'
                })
            }

            // Create Minutes Linked to Book
            if (!!validBookDetails) {
                const { book, committee, attendees, meeting_dates, location } = validBookDetails
                // Open Create Minutes Modal for Linked Book
                handleOpenMinutesModal('CREATE', {
                    committee,
                    book,
                    attendees,
                    meeting_dates,
                    location,
                    include_logo: true
                })
            }

            // Clear requestedBook State
            setRequestedBook({ bookId: null, bookCommitteeId: null })
        }
    }, [
        requestedBook,
        bookDetails,
        committees,
        setCurrentCommittee,
        handleOpenMinutesModal,
        handleOpenMinutes,
        setRequestedBook
    ])

    return {
        committees,
        openCommittees,
        statusFilter,
        canAddMinutes,
        isAdminOfCommittee,
        handleToggleCommitteeOpen,
        handleExpandAll,
        handleCollapseAll,
        handleOpenMinutesModal,
        handleOpenAboutModal,
        handleOpenMinutes,
        handleClickMinutesOptions,
        setStatusFilter,
        modals,
        deleteResponse,
        setDeleteResponse,
        updatedMinutesItem,
        setUpdatedMinutesItem,
        isDeleting
    }
}
