import textValidator from 'businesslayer/validation/textValidator'
import i18n from 'i18next'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { Moment } from 'moment'
import { FieldValidator } from 'final-form'
import { PLACEHOLDER_KEY } from 'components/atlas-material/select'
import {
    EMPTY_MEETING_DATE_KEYS,
    EMPTY_MEETING_ATTENDEES_DATE_KEYS
} from 'components/modals/minutes-properties-modal/minutes-properties-form-functions'
import { pick, Dictionary } from 'rambdax'
import { allObjectValues } from 'common/util/functions'
import { PersonChip } from 'components/chip-input/types'
import { getDateMaskText } from 'common/formatters/date'
import { getTimeMaskText } from 'common/util/date'
import { addYears } from 'date-fns'
import { MINUTES_EDIT_OPTIONS } from 'components/minutetaker/components/InvitationView/types'

export const MAX_TITLE_LENGTH = 1024
export const MAX_LOCATION_LENGTH = 255
export const MAX_ATTENDEE_COUNT = 100
export const DO_NOT_VALIDATE = 'DO_NOT_VALIDATE'

type ErrorType = string | true | undefined
export const composeValidators = (...validators: Array<(val: any) => ErrorType>) => (value) => {
    const result = validators.reduce<ErrorType>(
        (error, validator) => error || validator(value),
        undefined
    )
    return result === DO_NOT_VALIDATE ? undefined : result
}

// = = = = = = = = =
// Field Formatter
// = = = = = = = = =
export const truncateString = (string: string | undefined, maxLength: number) => {
    if (typeof string !== 'string') return ''
    const trimmedString = string.trim()
    if (trimmedString.length > maxLength)
        return trimmedString.substr(0, Math.min(trimmedString.length, maxLength))
    return trimmedString
}

// = = = = = = = = = = = = = =
// Field Validation Handlers
// = = = = = = = = = = = = = =
export const validateMinutesCommitteeField: FieldValidator<Committee> = (committee: Committee) => {
    const platform = transientStorageManager.platform as Platform
    const fieldRequieredMessage =
        platform === 'boardeffect'
            ? i18n.t('SELECT_COMMITTEE_HINT.BE')
            : i18n.t('SELECT_COMMITTEE_HINT.BB')

    const isValid = !!committee && !!Number(committee.id)
    return !isValid ? fieldRequieredMessage : undefined
}

/**
 * This is an idea for a validation wrapper to optimize forms
 * that validate on change. It will only run the validation if
 * the field is active but could be modified to use other meta
 * field state.
 * @example
 * <Field
 *      validate={whenActive(validateMinutesLocationField)}
 *      ...props
 * />
 */
// export const whenActive = <FieldValue>(
//     validationFn: FieldValidator<FieldValue>
// ): FieldValidator<FieldValue> => (fieldValue, allValues, meta) => {
//     if (!!meta && meta.active) {
//         return validationFn(fieldValue, allValues, meta)
//     }
//     return undefined
// }

export const validateMinutesTitleField = (title: string | undefined): string | undefined => {
    const isValid = !!title && textValidator.isLengthValid(title, 1, MAX_TITLE_LENGTH)
    return !isValid ? i18n.t('INVALID_MINUTES_TITLE.required') : undefined
}

export const validateMinutesLocationField = (
    location: string | undefined
): string | undefined | true => {
    if (!location) return undefined
    return undefined //validLength(0, MAX_LOCATION_LENGTH)(location)
}

export const validateAttendees = (
    newAttendeesList: PersonChip[] | undefined
): string | undefined | true => {
    // I wonder why? shouldn't there be a row per attendee in the database
    // associating the attendee to the minutes document
    if (!!newAttendeesList && newAttendeesList.length > MAX_ATTENDEE_COUNT) {
        //We cannot add more than certain amount of folks
        const errorMsg = i18n.t('INVALID_ATTENDEE_COUNT', { count: MAX_ATTENDEE_COUNT })
        return errorMsg
    }
    return undefined
}
export const validateMeetingDate: FieldValidator<Moment> = (meetingDate: Moment, allValues) => {
    const requiredField = makeRequiredField(i18n.t('INVALID_MINUTES_DATE_ROW'))
    return composeValidators(
        whenMeetingDateIsPartial(allValues),
        requiredField,
        invalidDate
    )(meetingDate)
}

export const validateMeetingStartTime: FieldValidator<Moment> = (
    startTime: Moment | null,
    allValues
) => {
    const requiredField = makeRequiredField(i18n.t('INVALID_MINUTES_DATE_ROW'))
    return composeValidators(
        whenMeetingDateIsPartial(allValues),
        requiredField,
        invalidTime
    )(startTime)
}

export const validateMeetingEndTime: FieldValidator<Moment> = (
    endTime: Moment | null,
    allValues
) => {
    const startTime = (allValues as any).startTime || undefined
    return composeValidators(
        whenMeetingDateIsPartial(allValues),
        invalidTime,
        validateTimeSpan(startTime)
    )(endTime)
}
export const validateMeetingStartDate: FieldValidator<Moment> = (startDate: Moment, allValues) => {
    const requiredField = makeRequiredField(i18n.t('INVALID_START_DATE'))

    return composeValidators(
        whenAttendeesMeetingDateIsPartial(allValues),
        invalidDate,
        requiredField
    )(startDate)
}
export const validateMeetingEndDate: FieldValidator<Moment> = (endDate: Moment, allValues) => {
    const startDate = (allValues as any).startDate || undefined
    const requiredField = makeRequiredField(i18n.t('INVALID_END_DATE'))

    return composeValidators(
        whenAttendeesMeetingDateIsPartial(allValues),
        requiredField,
        invalidDate,
        validateDateSpan(startDate),
        validateDateRange(allValues)
    )(endDate)
}

/**
 * The last parameter "meta" is the name given by final-form to the field meta data or FieldState
 * FieldState is defined in the docs here: https://final-form.org/docs/final-form/types/FieldState
 */
export const validateMeetingTimezone: FieldValidator<string> = (timezoneId, allValues, meta) => {
    const { currentTimezone } = transientStorageManager
    const meetingDate = pick(EMPTY_MEETING_DATE_KEYS, allValues as Dictionary<unknown>) as DateRow
    const { timezone, ...restOfDateFields } = meetingDate
    const otherDateFieldsAreEmpty = allObjectValues((v) => !v, restOfDateFields)

    const requiredField = makeRequiredField(i18n.t('INVALID_MINUTES_DATE_ROW'))

    // Set timezone to Default Timezone when other date fields have values
    const shouldDefaultTimezone = !timezone && !otherDateFieldsAreEmpty
    const timezoneValue = shouldDefaultTimezone ? currentTimezone || '' : timezoneId
    if (shouldDefaultTimezone && !!meta) meta.change(timezoneValue)

    const isValid = !!timezoneValue && timezoneValue !== '' && timezoneValue !== PLACEHOLDER_KEY
    return composeValidators(whenMeetingDateIsPartial(allValues), requiredField)(isValid)
}

export const isDateRowEmpty = (rowValues) => {
    if (!rowValues) return true

    return allObjectValues((v) => !v, rowValues)
}

export const validateMinutesLogoField: FieldValidator<LogoOption> = (
    logoType,
    allValues
): string | undefined => {
    const allFormValues = allValues as MinutesPropertiesFormValues
    const isEditMode =
        allFormValues?.editMode === MINUTES_EDIT_OPTIONS.EDIT ||
        allFormValues?.editMode === MINUTES_EDIT_OPTIONS.DUPLICATE
    const isNoFileUploaded =
        !allFormValues.logo || isEditMode
            ? !allFormValues.logoFilename
            : !allFormValues.logo_filename
    if (logoType === 'UPLOAD_LOGO' && isNoFileUploaded) {
        return i18n.t('NO_FILE_UPLOADED')
    }

    return undefined
}

// = = = = = = = =
// Validators
// = = = = = = = =

// Pre-conditions these will be prefixed with 'when' these are
// meant to conditionally prevent the compose validator from
// from validating the field
const whenMeetingDateIsPartial = (allValues) => () =>
    isDateRowEmpty(pick(EMPTY_MEETING_DATE_KEYS, allValues)) ? DO_NOT_VALIDATE : undefined

const whenAttendeesMeetingDateIsPartial = (allValues) => () =>
    isDateRowEmpty(pick(EMPTY_MEETING_ATTENDEES_DATE_KEYS, allValues)) ? DO_NOT_VALIDATE : undefined

// const whenTouchedAndDirty = (meta) => () =>
//     !!meta && (!meta.touched || !meta.dirty) ? DO_NOT_VALIDATE : undefined

/**
 * string:      error message to display
 * true:        error with no message
 * undefined:   valid
 */
type Validator<T> = (value: T) => string | true | undefined

// Granular validation functions that can be composed with composeValidators()
const makeRequiredField = (errorText: string): Validator<Moment> => (
    value: any
): string | undefined => {
    if (!value) return errorText || i18n.t('INVALID_MINUTES_DATE_ROW')
    return undefined
}

// const validLength = (min: number, max: number): Validator<string> => (text: string) => {
//     return textValidator.isLengthValid(text, min, max) ? undefined : true
// }
export const invalidDate: Validator<Moment> = (date) => {
    const { isDateInputMaskUS } = transientStorageManager
    const dateMaskText = getDateMaskText(isDateInputMaskUS)
    if (!!date && !date.isValid()) {
        const errorMsg = i18n.t('INVALID_MINUTES_DATE_FORMAT', { mask: dateMaskText })
        return errorMsg
    }

    return undefined
}
const invalidTime: Validator<Moment> = (time) => {
    const { timeFormat } = transientStorageManager
    const timeFormatHint = getTimeMaskText(timeFormat)
    if (!!time && !time.isValid()) {
        const errorMsg = i18n.t('INVALID_MINUTES_TIME_FORMAT', { mask: timeFormatHint })
        return errorMsg
    }

    return undefined
}
const validateTimeSpan = (startTime: Moment | null): Validator<Moment> => (
    endTime: Moment
): string | undefined => {
    if (!!startTime && !!endTime && startTime.isValid() && endTime.isValid()) {
        if (endTime.isBefore(startTime)) return i18n.t('INVALID_MINUTES_START_FINISH_TIMES')
    }
    return undefined
}

const validateDateSpan = (startDate: Moment | null): Validator<Moment> => (
    endDate: Moment
): string | undefined => {
    if (!!startDate && !!endDate && startDate.isValid() && endDate.isValid()) {
        if (endDate.isBefore(startDate)) {
            return i18n.t('INVALID_MINUTES_START_FINISH_DATES')
        }
    }
    return undefined
}
//End date should not be more than 24months from start date
const validateDateRange = (allValues) => () => {
    if (allValues.startDate && allValues.endDate) {
        if (new Date(allValues.endDate) > addYears(new Date(allValues.startDate), 2)) {
            return i18n.t('INVALID_DATE_RANGE')
        }
    }

    return undefined
}
