import i18n from 'common/util/internationalization'
import { LockedModal } from 'components/shared/LockedModal'
import emitter from 'common/util/events'
import React, { useCallback, useEffect, useState } from 'react'
import { useAuthentication } from 'use-authentication'
import { pathOr } from 'rambdax'
import InternationalizationProvider from 'components/shared/internationalization/InternationalizationProvider'
import {
    getLocalStorageItem,
    removeLocalStorageItem,
    removeSessionStorageItem
} from 'businesslayer/minutesLocalStore'
import { useUuid } from 'use-Uuid'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    makeStyles,
    Typography,
    Divider
} from '@material-ui/core'
import { AtlasButtonOld } from 'components/atlas-material/button'

type Props = {
    authTokens: {
        initialAuthToken: string | null
        sessionId: string | null
    }
    children: any
    uuid: string | null
    platform: any
    siteName: string | null
}
const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2)
    },
    errorMessage: {
        marginTop: theme.spacing(2),
        minWidth: '300px',
        wrap: 'break-word'
    }
}))
export const AuthLock = ({ authTokens, uuid, platform, children }: Props) => {
    const language = getLocalStorageItem('userLanguage') || 'en-us'
    const [lockedState, setLockedState] = useState({ isLocked: true, message: '' })
    const [errorDialogOpen, setErrorDialogOpen] = useState(false)
    const [error, setErrorMessage] = useState('')

    const parentPlatform = platform || transientStorageManager.platform // TODO: walk around for BoardEffect
    if (parentPlatform) {
        if (parentPlatform === 'boardeffect') {
            removeLocalStorageItem('sessionId')
        } else {
            removeSessionStorageItem('sessionId')
        }
    }

    const { isExchanging, hasAuthToken, reauthenticate } = useAuthentication(
        authTokens,
        parentPlatform
    )
    const { errorMessage } = useUuid(uuid)

    const handleErrorControl = useCallback((message) => {
        setErrorDialogOpen(true)
        setErrorMessage(message)
    }, [])

    // On Mount
    useEffect(() => {
        const handleLocked = (e) => {
            const message = pathOr('', 'message', e)
            reauthenticate()
            setLockedState({ isLocked: true, message })
        }

        document.title = i18n.t('WINDOW_TITLE')
        emitter.on('locked', handleLocked)

        // Unmount
        return () => {
            emitter.off('locked', handleLocked)
        }
    }, [reauthenticate])

    useEffect(() => {
        emitter.on('error', handleErrorControl)
        return () => emitter.off('error', handleErrorControl)
    }, [handleErrorControl])

    // Unlock App If we have an AuthToken
    useEffect(() => {
        if (hasAuthToken) {
            setLockedState({ isLocked: false, message: '' })
        }
    }, [hasAuthToken])
    if (errorMessage.status === 401 && window.location.href !== '/not-found') {
        return (
            <InternationalizationProvider language={language}>
                <LockedModal message={errorMessage.message} />
            </InternationalizationProvider>
        )
    }

    // User is Unauthorized; Return lock screen
    if (lockedState.isLocked && window.location.href !== '/not-found') {
        return (
            <InternationalizationProvider language={language}>
                <LockedModal isExchanging={isExchanging} message={lockedState.message} />
            </InternationalizationProvider>
        )
    } else {
        return (
            <>
                <ErrorPopUp
                    errorDialogOpen={errorDialogOpen}
                    setErrorDialogOpen={setErrorDialogOpen}
                    error={error}
                />
                {children}
            </>
        )
    }
}

const ErrorPopUp = ({ errorDialogOpen, setErrorDialogOpen, error }) => {
    const classes = useStyles()
    return (
        <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
            <DialogTitle>{i18n.t('AUTOSAVE_ERROR')}</DialogTitle>
            <Divider />
            <DialogContent className={classes.dialogContent}>
                <Typography variant="body2" className={classes.errorMessage}>
                    {error}
                </Typography>
            </DialogContent>
            <Divider />
            <DialogActions>
                <AtlasButtonOld onClick={() => setErrorDialogOpen(false)}>
                    {i18n.t('CLOSE')}
                </AtlasButtonOld>
            </DialogActions>
        </Dialog>
    )
}
