import { translate } from 'components/shared/internationalization'
import { makeFetcher } from 'businesslayer/networkrequests/swr-util'
import { minutesDetailsMutator } from './use-minutes-details-swr'
import { path, pathOr, sort } from 'rambdax'
import useSWR, { mutate, cache } from 'swr'

// in milliseconds
const ONE_SECOND = 1000

/**
 * Get all the minutes for a given Committee that the User has access to.
 */
export const useMinutesOfCommittee = (
    committeeId,
    shouldFetch,
    swrOptionsOverride?: ConfigInterface,
    fetcherOptionsOverride?: FetcherOptions
) => {
    const sordField = 'sort_date'
    const ascending = true
    const url = `committees/${committeeId}/minutes_documents?sort_by=${sordField}&sort_direction=${
        ascending ? 'asc' : 'desc'
    }&page=1&per_page=500`

    const onError = (errorObject: ErrorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_MINUTES_ERROR', {
            committeeId: committeeId,
            message: errorObject.message,
            code: httpCode
        })
    }
    const shouldNormalize = path('shouldNormalize', fetcherOptionsOverride)
    const fetcherCallback = !shouldNormalize ? fetcherCallbackUnnormalized : undefined

    // Override defaults
    const swrOptions = {
        focusThrottleInterval: 30 * ONE_SECOND,
        dedupingInterval: 30 * ONE_SECOND,
        ...swrOptionsOverride
    }
    const fetcherOptions = { onError, fetcherCallback, ...fetcherOptionsOverride }

    const fetcher = makeFetcher(fetcherOptions)
    const result = useSWR<Attendee[]>(shouldFetch ? url : null, fetcher, swrOptions)

    // If shouldFetch is false then return the stale value
    // to avoid returning 'undefined' for already cached keys
    if (!shouldFetch) {
        return { ...result, data: cache.get(url) }
    }

    return result
}
/**
 * Check for an array of Minutes because you get different shapes back from minutes-api
 * when there are no minutes. It will instead just pass the committee details.
 * Refer to index function of 'minutes_documents_controller.rb' in minutes-api
 */
const fetcherCallbackUnnormalized = (result) => {
    if (Array.isArray(result.data)) {
        const minutesList = result.data.map((minutes) => {
            minutesDetailsMutator(minutes.id, minutes, false)
            const committeeId = path('relationships.committee.data.id', minutes)
            const committeeName = path('attributes.committee_name', minutes)
            const committee = { id: committeeId, name: committeeName }
            const book = path('relationships.book.data', minutes)
            return { id: minutes.id, ...minutes.attributes, committee, book }
        })

        // This sort will be replaced by a ui sort/filter in the future
        return sort(sortMinutesByMeetingDate, minutesList)
    } else {
        return []
    }
}

const sortMinutesByMeetingDate = (x, y) => {
    const date_x = pathOr<string>('0', 'meeting_dates.0.start_date', x)
    const date_y = pathOr<string>('0', 'meeting_dates.0.start_date', y)

    return date_x < date_y ? 1 : -1
}

/**
 * Update the local cache for useMinutesOfCommittee.
 * This updates the cache for the related useMinutesDetails as well
 * minutesItem:
 * committeeId:
 * shouldRevalidate: if true, refetch the data
 */
export const updateMinutesItemOfCommittee = (minutesItem, committeeId, shouldRevalidate) => {
    const sordField = 'sort_date'
    const ascending = true
    const url = `committees/${committeeId}/minutes_documents?sort_by=${sordField}&sort_direction=${
        ascending ? 'asc' : 'desc'
    }&page=1&per_page=500`
    mutate(
        url,
        (currentData) => {
            const updatedMinutesOfCommittee = updateMinutesItem(minutesItem, currentData)

            return updatedMinutesOfCommittee
        },
        shouldRevalidate
    )

    // Update single minutes item as well
    minutesDetailsMutator(minutesItem.id, minutesItem, false)
}

/**
 * Update the local cache for useMinutesOfCommittee.
 * This updates the cache for the related useMinutesDetails as well
 * minutesItem:
 * committeeId:
 * shouldRevalidate: if true, refetch the data
 */
export const removeMinutesItemOfCommittee = (minutesId, committeeId, shouldRevalidate) => {
    const sordField = 'sort_date'
    const ascending = true
    const url = `committees/${committeeId}/minutes_documents?sort_by=${sordField}&sort_direction=${
        ascending ? 'asc' : 'desc'
    }&page=1&per_page=500`
    mutate(
        url,
        (currentData) => {
            const updatedMinutesOfCommittee = removeMinutesItem(minutesId, currentData)
            return updatedMinutesOfCommittee
        },
        shouldRevalidate
    )

    // Update single minutes item as well
    minutesDetailsMutator(minutesId, undefined, false)
}

/**
 * Force refetch of useMinutesOfCommittee
 * committeeId:
 */
export const revalidateMinutesOfCommittee = (committeeId) => {
    const sordField = 'sort_date'
    const ascending = true
    const url = `committees/${committeeId}/minutes_documents?sort_by=${sordField}&sort_direction=${
        ascending ? 'asc' : 'desc'
    }&page=1&per_page=500`

    mutate(url)
}

// Helpers
const updateMinutesItem = (minutesItem, currentData) => {
    if (!Array.isArray(currentData)) return currentData

    return currentData.map((minutes) => {
        return +minutes.id === +minutesItem.id ? minutesItem : minutes
    })
}
const removeMinutesItem = (minutesId, currentData) => {
    return currentData.filter((minutes) => +minutes.id !== +minutesId)
}
