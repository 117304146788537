import {
    getLocalStorageItem,
    getSessionStorageItem,
    removeLocalStorageItem,
    removeSessionStorageItem
} from './minutesLocalStore'
import emitter from 'common/util/events'
import i18n from 'i18next'
import { transientStorageManager } from './minutesSessionStore'

/**
 * Implicitly use sessionStorage to return values needed for authentication
 */
export default class authenticationManager {
    /**
     * Implicitly use sessionStorage to return apiToken
     */
    static getApiToken = (): string => {
        let sessionId

        if (!getLocalStorageItem('sessionId')) {
            // TODO: walk around for BoardEffect
            sessionId = getSessionStorageItem('sessionId')
        } else {
            sessionId = getLocalStorageItem('sessionId')
        }

        return !!sessionId ? `Bearer ${sessionId}` : 'Bearer no-token'
    }

    /**
     * Implicitly use sessionStorage to return rawToken
     */
    static getRawToken = (): string => {
        let sessionId

        if (!getLocalStorageItem('sessionId')) {
            // TODO: walk around for BoardEffect
            sessionId = getSessionStorageItem('sessionId')
        } else {
            sessionId = getLocalStorageItem('sessionId')
        }
        return !!sessionId ? `${sessionId}` : 'mock'
    }

    /**
     * Implicitly use sessionStorage to return siteName
     */
    static getSiteName = (): string => {
        return getSessionStorageItem('siteName')
    }

    /**
     * Implicitly use sessionStorage to return committeeName
     */
    static getCommitteeName = (): string => {
        return getSessionStorageItem('currentCommitteeName')
    }

    static handleSignout = (message = i18n.t('SIGNOUT_MESSAGE')) => {
        const parentPlatform = transientStorageManager.platform
        if (parentPlatform === 'boardeffect') {
            removeSessionStorageItem('sessionId')
        } else {
            removeLocalStorageItem('sessionId')
        }
        removeSessionStorageItem('selectedStatusFilter')
        removeSessionStorageItem('selectedCommitteeFilter')
        emitter.emit('locked', { message })
    }
}
