import {
    SsoManager,
    SsoManagerFactory,
    IUserManagerConfig
} from '@diligent/sso-login/dist/index.js'
import {
    getSessionStorageItem,
    removeLocalStorageItem,
    removeSessionStorageItem
} from 'businesslayer/minutesLocalStore'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
// import ConfigurationManager from 'services/ConfigurationManager';

export interface ISsoState {
    returnUrl: string
    onboardingCompleteRedirectUrl?: string
}

export interface ClientConfiguration {
    authority: string
    clientId: string
    scope: string
    accountSecurityUrl: string
}

class SsoManagerFactoryService {
    public async create(): Promise<SsoManager> {
        const ssoManagerFactory: SsoManagerFactory = new SsoManagerFactory()
        const config = this.createConfig()

        return ssoManagerFactory.create(config, {
            logger: console
        })
    }

    private createConfig(): IUserManagerConfig {
        const SSO_SCOPE_LIST = [
            'diligent_identity.manage_own_account',
            'openid',
            'profile',
            'security_questions_required',
            'diligent_id_claims',
            'diligent_identity.session_expiry',
            'diligent_identity.manage_own_account',
            'minutes_service.full_access'
        ]
        const { location, _env_ } = window
        const redirectUri = `${location.origin}/identity-login-callback`
        const postLogoutRedirectUri = location.origin
        const SSO_AUTHORITY_URL = _env_.SSO_AUTHORITY_URL

        const config: IUserManagerConfig = {
            authority: SSO_AUTHORITY_URL,
            clientId: 'minutes',
            redirectUri,
            postLogoutRedirectUri,
            responseType: 'code',
            scope: SSO_SCOPE_LIST.join(' '),
            automaticSilentRenew: true
        }
        return config
    }
}

const ssoManagerFactoryService = new SsoManagerFactoryService()

export const doLogin = async () => {
    const ssoManager = await ssoManagerFactoryService.create()
    ssoManager.signinRedirect()
}

export const doLogout = async () => {
    if (transientStorageManager.platform === 'boardeffect') {
        let destination = `/extensions/minutes?committee_id=${getSessionStorageItem(
            'currentCommitteeId'
        )}`
        window.location.href = `${
            transientStorageManager.parentAppUrl
        }/logout?destination=${encodeURIComponent(destination)}`
    } else {
        const ssomanager = await ssoManagerFactoryService.create()
        // ignore mock session for local developement
        if (process.env.NODE_ENV !== 'development') {
            ssomanager.signoutRedirect()
        } else {
            console.log('Do nothing for development')
        }
    }
    if (process.env.NODE_ENV !== 'development') {
        // Remove the selected filters for landing page
        removeLocalStorageItem('sessionId')
        removeSessionStorageItem('bookId')
        removeSessionStorageItem('committeeId')
        removeSessionStorageItem('committeeName')
        removeSessionStorageItem('selectedStatusFilter')
        removeSessionStorageItem('selectedCommitteeFilter')
        removeSessionStorageItem('persistedStateData')
    }
}
export default ssoManagerFactoryService
