import { merge } from 'rambdax'

export const resources = () => {
    const pendingStrings = require(`./pending.json`)
    return {
        en: {
            translation: merge(require(`./en-us.json`), pendingStrings)
        },
        es: {
            translation: merge(require(`./es.json`), pendingStrings)
        },
        fr: {
            translation: merge(require(`./fr.json`), pendingStrings)
        },
        it: {
            translation: merge(require(`./it.json`), pendingStrings)
        },
        nl: {
            translation: merge(require(`./nl.json`), pendingStrings)
        },
        pt: {
            translation: merge(require(`./pt.json`), pendingStrings)
        },
        de: {
            translation: merge(require(`./de.json`), pendingStrings)
        },
        zh: {
            translation: merge(require(`./zh-cn.json`), pendingStrings)
        },
        ja: {
            translation: merge(require(`./ja.json`), pendingStrings)
        }
    }
}
