import React, { FC } from 'react'
import { ClassNames } from '@emotion/react'

import { CircularProgress } from 'components/shared/StyledComponents'
import { StatusPopoverButton } from './toolbar/StatusPopoverButton/StatusPopoverButton'
import { setMinuteStatus } from 'businesslayer/api/minutes'
import ExportPopoverButton from 'components/shared/ExportPopoverButton/ExportPopoverButton'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import {
    AtlasGreyLight,
    ColorBaseMulberryBlue,
    ColorBaseWhite,
    ColorEastBay
} from 'assets/styles/variables'
import { Box, Button, Paper, makeStyles } from '@material-ui/core'
import ToolbarSaveStatus from 'components/shared/Toolbar/ToolbarSaveStatus'
import selectors from '../../../selectors/minuteTakerSelectors'
import { connect } from 'react-redux'
import moment from 'moment'
import i18n from 'common/util/internationalization'
import { useHistory } from 'react-router'
import BackButtonIcon from 'assets/icons/BackButtonIcon'
import { actions as editorActions } from 'reducers/minuteTakerReducer'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { EDITOR_TABS } from '../components/InvitationView/types'

interface IconBarProps {
    currentMinuteItem?: any
    minutesSections?: Array<any>
    height?: number
    isIconOnlyMode?: boolean
    disabled?: boolean
    isSavingSection?: boolean
    withExport?: boolean
    isExportingMinutes?: boolean
    disableSubmitStatus?: boolean
    collapseAllVisible?: boolean
    dateFormat?: string

    onToggleExpandAll?: (shouldExpand: boolean) => void
    onStatusChange?: (status: DocumentStatusKey, prevStatus?: any) => void
    actions: {
        currentSelectedTab: (...args: any[]) => void
    }
}
const useStyles = makeStyles((theme) => ({
    ButtonCss: {
        color: ColorEastBay,
        backgroundColor: 'transparent',
        borderRadius: '2px',
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        },
        '&:hover': {
            color: ColorEastBay,
            backgroundColor: '#94949429'
        },
        padding: 0
    },
    CollapseExpandToggleCss: {
        border: `1px solid ${ColorEastBay}`,
        color: ColorEastBay,
        textTransform: 'capitalize',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        outline: 'none',
        padding: '8px 12px 8px 12px',
        borderRadius: '2px'
    },
    minutesHeaderTitle: {
        gap: '16px',
        display: 'flex',
        fontFamily: 'Source Sans Pro',
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '32px',
        color: '#282E37'
    },
    minutesHeaderDate: {
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#282E37'
    },
    headerFlex: {
        marginTop: '-2px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px',
        width: '100%',
        borderRadius: '0px',
        boxShadow: 'none',
        position: 'relative',
        zIndex: 100,
        borderBottom: `1px solid ${AtlasGreyLight}`,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: '5px'
        }
    },
    headerTitleFlex: {
        display: 'block'
    },
    savedText: {
        display: 'flex',
        fontFamily: 'Source Sans Pro',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
        textAlign: 'left',
        height: 'fit-content',
        padding: '5px 10px',
        marginTop: '4px',
        gap: '10px',
        textWrap: 'nowrap',
        borderRadius: '12px',
        border: `1px solid ${AtlasGreyLight}`,
        background: AtlasGreyLight
    },
    buttonSectionCss: {
        marginLeft: '1rem',
        display: 'flex',
        alignItems: 'flex-start'
    },
    titleCss: {
        maxWidth: '350px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '30vw'
        }
    },
    backButton: {
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        marginRight: '8px',
        width: '40px',
        height: '40px',
        '&:focus, &:active': {
            outline: 'none',
            width: '40px',
            height: '40px',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    }
}))

export const UtilityIconBar: FC<IconBarProps> = (props) => {
    const handleStatusChange = async (status, prevStatus) => {
        // This flag is for when parent components are handling this step
        if (!props.disableSubmitStatus) {
            await setMinuteStatus(props.currentMinuteItem.id, status, null)
        }
        !!props.onStatusChange && props.onStatusChange(status, prevStatus)
    }
    const history = useHistory()
    const handleNewButton = () => {
        localStorage.removeItem('selectedTab')
        history.push(`/`)
        props.actions.currentSelectedTab(EDITOR_TABS.EDITOR)
    }

    const { platform } = transientStorageManager
    const canPlatformExport = platform !== 'boardsios'
    const shouldRenderStatus = !!props.onStatusChange && props.currentMinuteItem
    const shouldRenderExport = props.withExport && props.currentMinuteItem && canPlatformExport
    const classes = useStyles()
    const title = props.currentMinuteItem.attributes.title
    const dateFormat = props.dateFormat || 'MMMM DD, YYYY'
    const createdAt = props.currentMinuteItem?.attributes?.sortDate
    const formatedDate = moment(createdAt).isValid()
        ? moment(createdAt).format(dateFormat)
        : i18n.t('NO_DUE_DATE')

    return (
        <ClassNames>
            {({ css }) => (
                <Box>
                    <Paper
                        className={classes.headerFlex}
                        role="banner"
                        aria-label="Minutes Editor Header"
                        data-testid="minutes-editor-header">
                        <Box style={{ display: 'flex' }}>
                            <Button
                                disableFocusRipple
                                disableRipple
                                onClick={handleNewButton}
                                className={classes.backButton}>
                                <BackButtonIcon
                                    title={i18n.t('BACK_TO_MINUTES_MANAGER')}
                                    data-rh={i18n.t('BACK_TO_MINUTES_MANAGER')}
                                    data-testid="back-to-minutes-manager"
                                    aria-label={i18n.t('BACK_TO_MINUTES_MANAGER')}
                                />
                            </Button>
                            <Box className={classes.minutesHeaderTitle}>
                                <Box className={classes.headerTitleFlex}>
                                    <div
                                        className={classes.titleCss}
                                        title={title}
                                        role="heading"
                                        aria-level={2}>
                                        {title}
                                    </div>
                                    <div
                                        className={classes.minutesHeaderDate}
                                        role="heading"
                                        aria-label={i18n.t('MEETING_DATE')}>
                                        {i18n.t('MEETING_DATE')}
                                        {':'} {formatedDate}
                                    </div>
                                </Box>
                                <div className={classes.savedText}>
                                    <ToolbarSaveStatus />
                                </div>
                            </Box>
                        </Box>
                        <div className={classes.buttonSectionCss}>
                            {props.children}
                            {shouldRenderExport && (
                                <ExportPopoverButton
                                    minutesItem={props.currentMinuteItem}
                                    actionSortField={{ name: 'section', ascending: true }}
                                    isIconOnlyMode={props.isIconOnlyMode}
                                />
                            )}
                            {shouldRenderStatus && (
                                <>
                                    {props.isExportingMinutes ? (
                                        <CircularProgress
                                            classes={{
                                                colorPrimary: css`
                                                    color: #a1b5b7;
                                                `
                                            }}
                                            color="primary"
                                            size={27}
                                            thickness={2}
                                            style={{ top: 7 }}
                                        />
                                    ) : (
                                        <StatusPopoverButton
                                            isDisabled={props.isSavingSection}
                                            statusField={props.currentMinuteItem.attributes.status}
                                            onStatusChange={handleStatusChange}
                                            isIconOnly={props.isIconOnlyMode}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Paper>
                </Box>
            )}
        </ClassNames>
    )
}

const mapStateToProps = (state, _) => {
    return {
        dateFormat: selectors.dateFormat(state.minuteTakerReducer)
    }
}
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    const { currentSelectedTab } = editorActions
    return {
        actions: bindActionCreators(
            {
                currentSelectedTab
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilityIconBar)
