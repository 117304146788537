import { createStore, applyMiddleware } from 'redux'
import combinedReducer from '../reducers'

import promiseMiddleware from 'redux-promise-middleware'

import minutesDataManager from './minutesDataManager'
import applicationRouter from './applicationRouter'
//Connected router
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter } from 'connected-react-router'

export const history = createBrowserHistory()

/* eslint-disable no-underscore-dangle */
export const configureStore = () => {
    const store = createStore(
        connectRouter(history)(combinedReducer),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        applyMiddleware(promiseMiddleware(), routerMiddleware(history))
    )

    minutesDataManager.setStore(store)
    //Application route needs store to trigger routes
    applicationRouter.setStore(store)

    return store
}
/* eslint-enable */

export default configureStore
