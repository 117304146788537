import moment from 'moment'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ActionSummaryList from './ActionSummaryList'
import { switchcase } from 'common/util/switchcase'
import { sort, pathOr, take, head } from 'rambdax'
import { mapSectionNameById, mapSectionOrderById } from 'common/mappers/minutes'
import { mapActionId } from 'common/mappers/routes'
import { useSortState } from 'components/contexts/actions/SortConstate'

type ActionClickFn = (...args: any[]) => void

type Props = {
    actionItems: Array<any>
    minutesSections: Array<any>
    dateFormat: string
    currentMinuteItem: any
    onCompleteActionChecked: (actionItem: ActionItem) => void

    actions: {
        resetSort: ActionClickFn
        selectActionItem: ActionClickFn
        handleActionStatusChanged: ActionClickFn
        sortActions: ActionClickFn
        saveExistingAction: ActionClickFn
    }
} & RouteComponentProps

export const ActionItemListContainer = (props: Props) => {
    const { actionItems, dateFormat, minutesSections } = props
    const actionId = mapActionId(props)

    const mappedActionItems = actionItems.map((actionItem) => ({
        ...actionItem,
        section: mapSectionNameById(actionItem.minutesSectionId, minutesSections),
        sectionOrder: mapSectionOrderById(actionItem.minutesSectionId, minutesSections)
    }))

    const { sortField, sortOrder } = useSortState()

    const selectedActionItem = mappedActionItems.find((actionItem) => +actionItem.id === +actionId)
    const sortActionItems = makeSortActions(sortField, sortOrder)
    const sortedActionItems = sort<ActionItem>(sortActionItems, mappedActionItems)

    return (
        <ActionSummaryList
            selectedActionItem={selectedActionItem}
            dateFormat={dateFormat}
            actionItems={sortedActionItems}
            onCompleteActionChecked={props.onCompleteActionChecked}
        />
    )
}

const maxDate = new Date(8640000000000000)
const minDate = new Date(-8640000000000000)

const diffByDate = (aDate, bDate, defaultDate = maxDate) =>
    moment(aDate || defaultDate).diff(moment(bDate || defaultDate), 'minutes')

const determineFirstAssigneeName = (arr) => pathOr('', ['text'], head(take(1, arr)))

const sortByName = (aName, bName, sortOrder = 'ascending') =>
    !aName
        ? 1
        : !bName
        ? -1
        : sortOrder === 'ascending'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName)

const sortByOrder = (aOrder, bOrder, sortOrder = 'ascending') =>
    sortOrder === 'ascending' ? aOrder - bOrder : bOrder - aOrder

const sortByDate = (aDate, bDate, sortOrder = 'ascending') => {
    const getDiff = switchcase({
        descending: diffByDate(aDate, bDate),
        ascending: diffByDate(bDate, aDate, minDate)
    })(diffByDate(bDate, aDate, minDate))

    return getDiff(sortOrder)
}

const makeSortActions = (sortField = 'sectionOrder', sortOrder = 'ascending') => (
    a: ActionItem,
    b: ActionItem
) => {
    if (sortField === 'dueDate') {
        return sortByDate(a.dueDate, b.dueDate, sortOrder)
    }

    if (sortField === 'assignees') {
        const aName = determineFirstAssigneeName(a[sortField])
        const bName = determineFirstAssigneeName(b[sortField])

        return sortByName(aName, bName, sortOrder)
    }

    if (sortField === 'sectionOrder') {
        return sortByOrder(a.sectionOrder, b.sectionOrder, sortOrder)
    }

    return sortOrder === 'ascending'
        ? a[sortField].localeCompare(b[sortField])
        : b[sortField].localeCompare(a[sortField])
}

export default ActionItemListContainer
