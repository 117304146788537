import React from 'react'
import styled from '@emotion/styled'
import fileSaver from 'file-saver'

//#region Components Material

import { exportConsolidatedActions } from 'businesslayer/api/actions'

import { ClassNames } from '@emotion/react'

//#endregion

//#region Components

import { translate } from 'components/shared/internationalization'
import {
    ColorBaseBlueMedium,
    ColorBaseBlueGreyMedium,
    ColorBaseDarkBlack
} from 'assets/styles/variables'
import ExportActionsForm from 'components/minutetaker/components/ExportActionsForm'
import DialogSubscriber from 'components/shared/context/dialog/Dialog'
import {
    Dialog,
    DialogActions,
    IconButton,
    NewDialogTitle,
    NewDialogContent,
    AddActionEditActionButton
} from 'components/shared/StyledComponents'
import { AtlasIcon } from 'web-components/atlas-icon'
import { AtlasButtonCss, StyledAtlasButtonOld } from './ActionItemEditDialog/ActionItemEditDialog'

//#endregion

type Props = {
    onClose?: (...args: any) => void
}

type State = {
    committeeIds: string
    isCommitteeFormSavable: boolean
    isDialogVisible: boolean
}

//#region Implementation
class ExportActionsDialog extends React.Component<Props, State> {
    _isMounted: boolean = false
    state = {
        committeeIds: '',
        isCommitteeFormSavable: true,
        isDialogVisible: true
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getFormSavable = () => {
        // When adding more child forms and all form savable states
        return this.state.isCommitteeFormSavable
    }

    handleFileDownloaded = ({ response, filename }) => {
        //Read only once
        if (response.body && response.body.locked) {
            return
        }

        response.blob().then((blob) => {
            fileSaver.saveAs(blob, filename)
        })
    }

    renderDialogActions = ({ saveButtonEnabled, onSave, hideExportActionsDialog }): Array<any> => {
        const cancelButton = (
            <AddActionEditActionButton
                key={`dialog-cancel`}
                disableFocusRipple
                disableRipple
                onClick={() => {
                    !!this.props.onClose && this.props.onClose()
                    hideExportActionsDialog('ExportActionsDialog')
                }}>
                {translate('CANCEL')}
            </AddActionEditActionButton>
        )

        const okButton = (
            <StyledAtlasButtonOld
                key={`dialog-export`}
                disabled={!saveButtonEnabled}
                onClick={onSave}>
                {translate('EXPORT')}
            </StyledAtlasButtonOld>
        )
        return [cancelButton, okButton]
    }

    setCommitteeSavable = (isSavable) => {
        // Since this is a callback function check if component is still mounted inorder to prevent a memory leak
        this._isMounted && this.setState({ isCommitteeFormSavable: isSavable })
    }

    handleExportActionsSubmit = (committeeIds) => {
        if (committeeIds !== '' && committeeIds.length > 0) {
            // Call API to get export file stream
            const promise = exportConsolidatedActions(
                committeeIds,
                { name: 'due_date', ascending: true },
                null
            )
            const filename = `Consolidated Actions.xlsx`

            // Download file based on response
            promise.then((response) => {
                this.handleFileDownloaded({ response, filename })
            })
        }
    }

    renderAddMinutesDialog = () => {
        const saveButtonEnabled = this.getFormSavable()

        return (
            <DialogSubscriber name="ExportActionsDialog">
                {({ hideDialog }) => {
                    const dialogActions = this.renderDialogActions({
                        saveButtonEnabled,
                        onSave: () => {
                            // Trigger final-form to submit
                            const form = document.getElementById('exportActionsForm')
                            if (form)
                                form.dispatchEvent(
                                    new Event('submit', { bubbles: true, cancelable: true })
                                )
                            !!hideDialog && hideDialog('ExportActionsDialog')
                        },
                        hideExportActionsDialog: hideDialog
                    })

                    return (
                        <ClassNames>
                            {({ css }) => (
                                <Dialog
                                    className={css`
                                        .MuiDialog-paperWidthMd {
                                            max-width: 1000px;
                                            max-height: 100%;
                                        }
                                    `}
                                    open={true}
                                    disableRestoreFocus>
                                    <NewDialogTitle
                                        className={css`
                                            margin-bottom: 19px;
                                            font-family: 'Source Sans Pro';
                                            font-size: 22px;
                                            line-height: 26px;
                                            font-weight: 600;
                                        `}>
                                        {translate('EXPORT_ACTIONS_REPORT')}
                                        <IconButton
                                            tabIndex={0}
                                            disableFocusRipple
                                            disableRipple
                                            onClick={() => this.handleCloseIconClick(hideDialog)}
                                            className={css`
                                                ${AtlasButtonCss}
                                            `}>
                                            <AtlasIcon
                                                name="close"
                                                size={24}
                                                data-testid="AtlasModal_CloseIcon"
                                            />
                                        </IconButton>
                                    </NewDialogTitle>
                                    <NewDialogContent
                                        className={css`
                                            .MuiPaper-elevation1 {
                                                box-shadow: none;
                                            }
                                        `}>
                                        <div>
                                            <label
                                                className={css`
                                                    font-family: 'Source Sans Pro';
                                                    font-weight: 600;
                                                    font-size: 18px;
                                                `}>
                                                {translate('CHOOSE_COMMITTEE')}
                                            </label>
                                        </div>
                                        <div>
                                            <ExportInstructions>
                                                {translate('COMMITTEE_EXPORT_INSTRUCTIONS')}
                                            </ExportInstructions>
                                        </div>
                                        <ExportActionsForm
                                            onExportActionsSubmit={this.handleExportActionsSubmit}
                                            isSavable={(isSavable) =>
                                                this.setCommitteeSavable(isSavable)
                                            }
                                        />
                                    </NewDialogContent>
                                    <DialogActions>{dialogActions}</DialogActions>
                                </Dialog>
                            )}
                        </ClassNames>
                    )
                }}
            </DialogSubscriber>
        )
    }

    handleCloseIconClick = (hideExportActionsDialog) => {
        !!this.props.onClose && this.props.onClose()
        hideExportActionsDialog('ExportActionsDialog')
    }
    render() {
        return <div>{this.renderAddMinutesDialog()}</div>
    }
}

//#endregion

//#region Styles

export const getDialogTitleStyle = () => {
    return {
        backgroundColor: ColorBaseBlueMedium,
        color: ColorBaseDarkBlack,
        height: 50,
        fontSize: 24,
        padding: 0,
        paddingLeft: 25,
        lineHeight: '50px',
        fontWeight: 'normal',
        marginBottom: 0
    }
}

const ExportInstructions = styled('div')`
    color: ${ColorBaseBlueGreyMedium};
    padding-top: 19px;
    padding-top: 19px;
    padding-bottom: 18px;
    padding-left: 2px;
    font-size: 14px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 15px;
`

//#endregion

export default ExportActionsDialog
