import { Button, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Box } from '@material-ui/core'
import {
    AtlasBlackNear,
    AtlasGreyDark,
    AtlasGreyLight,
    AtlasLink,
    ColorBaseMulberryBlue,
    ColorEastBay
} from 'assets/styles/variables'
import styled from '@emotion/styled'
import selectors from 'selectors/minuteAttendeesSelectors'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { actions } from 'reducers/minutesAttendeesReducer'
import { AtlasIcon } from 'web-components/atlas-icon'
import SendIcon from 'assets/icons/SendIcon'
import useChatBoardAiConnector from 'minutes-ai-help-chat/hooks/useChatBoardAiConnector'
import StopIcon from 'assets/icons/StopIcon'
import { AiChatBotProps, CHAT_BOT } from 'components/minutetaker/components/InvitationView/types'
import SequentialCharacter from './SequentialCharacter'
import i18n from 'common/util/internationalization'
import ClearIcon from 'assets/icons/ClearIcon'
import { getAtlasHelperText } from 'components/text-field/input-error-text'

const useStyles = makeStyles((theme: Theme) => ({
    hiddenBox: {
        // Default to hide the box
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem'
    },

    chatBtn: {
        float: 'right',
        background: `${ColorEastBay}`,

        width: '56px',
        height: '56px',
        borderRadius: '50%',
        boxShadow: theme.shadows[5],
        '&:hover': {
            background: `${ColorEastBay}`
        }
    },
    chatBox: {
        float: 'right',

        marginTop: '-15rem',
        maxWidth: '450px',

        background: theme.palette.common.white,
        width: 'calc(100vw - 48px)',

        maxHeight: '600px',
        minHeight: '230px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        boxShadow: ' 0 4px 24px -8px #00000029, 0 1px 6px -2px #0000008f',
        transform: 'scale(0) translateY(64px)',
        opacity: 0,
        transformOrigin: ' 95% bottom',
        transition: 'transform .2s ease-out 0s,opacity .1s ease-out .1s'
    },
    chatBoxContainer: {
        maxHeight: '100%',
        minHeight: '23rem',
        zIndex: 1000,
        bottom: '2.4rem',
        right: '2.4rem',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        borderRadius: '4px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 24px -8px #00000029, 0 1px 6px -2px #0000008f',
        color: '#000',
        transform: 'translate(500px)',
        transition: 'transform 0.3s ease-out',
        whiteSpace: 'initial',
        position: 'fixed',
        height: '90vh',

        width: 'calc(100vw - 4.8rem)',
        maxWidth: '25rem',
        padding: '0 1rem 1rem 1rem',
        top: '8%',
        left: '80%',
        [theme.breakpoints.down('sm')]: {
            left: 'calc(100vw - 26rem)',
            top: '6%',
            height: '85vh'
        },
        '@media (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape)': {
            //  styles for iPad landscape mode here
            height: '79vh'
        }
    },

    openContainer: {
        top: '8%',
        [theme.breakpoints.down('sm')]: {
            left: 'calc(100vw - 26rem)',
            top: '6%',
            height: '85vh'
        },
        [theme.breakpoints.between('md', 'xl')]: {
            left: 'calc(100vw - 26.5rem)'
        },

        '@media (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape)': {
            //  styles for iPad landscape mode here
            height: '79vh'
        },

        transform: 'translate(0)',
        transition: 'transform 0.3s ease-in;'
    },

    chatBoxHeader: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',

        height: '64px',
        borderBottom: `1px solid ${AtlasGreyLight}`
    },
    chatBoxContent: {
        overflow: 'auto',
        overflowX: 'hidden',

        '&::-webkit-scrollbar': {
            width: '6px'
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#a0a0a0' /* Color of the scrollbar thumb */,
            borderRadius: '6px' /* Adjust the border-radius for rounded corners */
        },

        /* For Firefox */
        '&::-moz-scrollbar': {
            width: '6px'
        },

        '&::-moz-scrollbar-thumb': {
            backgroundColor: '#a0a0a0',
            borderRadius: '6px'
        },

        /* For Internet Explorer */
        '&::-ms-scrollbar': {
            width: '6px'
        },

        '&::-ms-scrollbar-thumb': {
            backgroundColor: '#a0a0a0',
            borderRadius: '6px'
        },

        flex: 5
    },

    welcomeMessage: {
        fontWeight: 600,
        color: `${AtlasBlackNear}`,
        lineHeight: '32px',
        fontSize: '28px'
    },
    helpMessage: {
        fontWeight: 400,
        color: `${AtlasBlackNear}`,
        lineHeight: '20px',
        fontSize: '16px'
    },
    chatBotMessage: {
        fontWeight: 400,
        color: `${AtlasBlackNear}`,
        lineHeight: '18px',
        fontSize: '14px'
    },
    chatBotMessageContainer: {
        background: '#F5F8F9',
        padding: '8px',
        display: 'flex',
        columnGap: '8px',
        flexFlow: ' row-reverse nowrap'
    },
    senderChat: {
        background: '#364262',
        padding: '8px',
        borderRadius: '8px 8px 0px 8px',
        color: '#fff',
        maxWidth: '182px',
        marginLeft: 'auto!important',
        wordWrap: 'break-word'
    },
    responseChat: {
        background: '#F5F8F9',
        padding: '8px',
        borderRadius: '8px 8px 8px 0px',
        color: `${AtlasBlackNear}`,
        width: '368px',
        whiteSpace: 'pre-line',
        fontWeight: 400,
        fontSize: '16px'
    },
    messagesContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        maxHeight: '300px',
        marginTop: '1rem'
    },
    defaultScreen: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    chatHeading: {
        fontWeight: 600,
        color: `${AtlasBlackNear}`,
        lineHeight: '24px',
        fontSize: '18px'
    },
    headingCloseBtn: {
        position: 'absolute',
        right: '0.5rem',
        width: ' 2.4rem',
        height: ' 2.4rem',
        '&:focus': {
            border: `2px solid ${ColorBaseMulberryBlue}`,
            borderRadius: '4px'
        }
    },
    headingCloseBtn1: {
        right: '2rem',
        width: ' 2.4rem',
        height: ' 2.4rem',
        '&:focus': {
            border: `2px solid ${ColorBaseMulberryBlue}`,
            borderRadius: '4px'
        }
    },
    descriptionCloseBtn: {
        width: ' 2rem',
        height: ' 2rem',
        marginTop: '-0.4rem',
        marginRight: '-0.4rem',
        '&:focus': {
            border: `2px solid ${ColorBaseMulberryBlue}`,
            borderRadius: '4px'
        }
    },
    privacyPolicyText: {
        color: `${AtlasLink}`,
        fontWeight: 700,
        textDecoration: 'none',
        display: 'inline-block',
        '&:focus': {
            outline: `2px solid ${ColorBaseMulberryBlue}`,
            borderRadius: '4px'
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    message: {
        listStyleType: 'none',
        flexFlow: 'row nowrap'
    },
    messageItem: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },

    loaderDot: {
        animation: '$blinkAnimation 1s infinite'
    },
    loaderDot2: {
        animation: '$blinkAnimation 1s infinite',
        animationDelay: '250ms'
    },
    loaderDot3: {
        animation: '$blinkAnimation 1s infinite',
        animationDelay: '500ms'
    },
    lastChildMessage: {
        '&:last-child .message': {
            marginBlockEnd: '0.6rem'
        }
    },
    '@keyframes blinkAnimation': {
        '50%': {
            color: 'transparent'
        }
    },
    nogenerating: {
        alignSelf: 'center',
        border: `1px solid ${ColorEastBay}`,
        borderRadius: '2px',
        color: `${ColorEastBay}`,
        paddingBlock: '0.3rem',
        marginBlock: '0.4rem',
        opacity: 0,
        width: '160px',
        height: '32px',
        fontWeight: 600,
        textTransform: 'none',
        lineHeight: '18px',
        transform: 'scale(0)',
        transition: 'opacity 0.3s ease-in 0.2s, transform 0.3s ease-in 0.2s'
    },
    generating: {
        opacity: 1,
        transform: 'scale(1)',
        transitionTimingFunction: 'ease-out',
        '&:focus': {
            border: `2px solid ${ColorBaseMulberryBlue}`,
            borderRadius: '4px'
        }
    },
    sendIcon: {
        transform: 'scale(1) rotate(15deg)',
        opacity: 1,
        transition: 'transform 0.8s cubic-bezier(0, 1.8, 0.83, 1.24), opacity ease-out 0.25s 0.55s',
        transformOrigin: 'center 25%',
        '&:hover': {
            transition:
                'transform 0.6s cubic-bezier(0, 1.8, 0.83, 1.24), opacity ease-out 0.2s 0.3s',
            transform: 'scale(1.15) rotate(0deg)'
        }
    },
    errorMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    sendIconBtn: {
        width: '40px',
        height: '40px',
        padding: '0',
        '&:focus': {
            width: '40px',
            height: '40px',
            border: `2px solid ${ColorBaseMulberryBlue}`,
            borderRadius: '4px'
        }
    },
    textField: {
        width: '100%',
        '& textarea': {
            color: `${AtlasGreyDark}`,
            fontWeight: 400,
            lineHeight: '20px',
            fontSize: '16px',
            textIndent: '0px'
        }
    },
    filledTexField: {
        width: '100%',
        '& textarea': {
            textIndent: '0px'
        }
    }
}))

const AiHelpChatBox = (props: AiChatBotProps) => {
    const {
        handleClick,

        isWriting,
        isExecutionComplete,
        chatMessages,
        handleStopButtonClick,
        setChatMessages,
        setIsWriting,
        setIsExecutionComplete,
        setIschatBotMessageContainer,
        ischatBotMessageContainer,
        inputValue,
        setInputValue,
        bottomRef,
        errorMessage
    } = useChatBoardAiConnector(props)

    const classes = useStyles()
    const { setIsOpen, isOpen } = props

    const allowAutoScrollRef = useRef(true)

    useEffect(() => {
        // on click of send button user shoud scrolled down to the botton
        if (bottomRef.current && props.chatMessages.length > 0 && allowAutoScrollRef.current) {
            bottomRef.current.scrollTop = bottomRef.current.scrollHeight
        }
    }, [props.chatMessages, bottomRef])

    const handleScroll = useCallback(() => {
        if (bottomRef.current) {
            // if user scrolls up stop the auto scroll
            const scrollTop = bottomRef.current.scrollTop
            const scrollHeightMinusClientHeight =
                bottomRef.current.scrollHeight - bottomRef.current.clientHeight
            const marginOfError = 5

            allowAutoScrollRef.current =
                scrollTop >= scrollHeightMinusClientHeight - marginOfError &&
                scrollTop <= scrollHeightMinusClientHeight + marginOfError
        }
    }, [bottomRef])
    const handleKeyPress = (event: any) => {
        // Prevent new line on "Enter" key press
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            // Trigger form submission
            if (!isSendBtndisabled) {
                handleClick(event)
            }
        }
    }

    const isSendBtndisabled = !inputValue.trim() || isWriting
    return (
        <>
            <Box
                className={
                    props.isOpen
                        ? ` ${classes.openContainer} ${classes.chatBoxContainer}`
                        : classes.chatBoxContainer
                }>
                <Box className={classes.chatBoxHeader}>
                    <Typography className={classes.chatHeading}>
                        {i18n.t('CHAT_BOT_HEADING')}
                    </Typography>
                    <IconButton
                        className={classes.headingCloseBtn}
                        disabled={!isOpen}
                        onClick={() => setIsOpen(false)}>
                        <AtlasIcon name="close" size={24} data-testid="AtlasModal_CloseIcon" />
                    </IconButton>
                </Box>
                <div className={classes.chatBoxContent} ref={bottomRef} onScroll={handleScroll}>
                    {props.chatMessages.length === 0 ? (
                        <Box className={classes.defaultScreen}>
                            <Box className={classes.hiddenBox}>
                                <Typography className={classes.welcomeMessage}>
                                    {i18n.t('CHAT_BOT_WELCOE_MESSAGE')}
                                </Typography>
                                <Typography className={classes.helpMessage}>
                                    {i18n.t('CHAT_BOT_HELP_MESSAGE')}
                                </Typography>
                            </Box>

                            {ischatBotMessageContainer && (
                                <Box className={classes.chatBotMessageContainer}>
                                    <IconButton
                                        disableRipple
                                        disableFocusRipple
                                        disabled={!isOpen}
                                        className={classes.descriptionCloseBtn}
                                        onClick={() => setIschatBotMessageContainer(false)}>
                                        <ClearIcon />
                                    </IconButton>
                                    <Typography
                                        className={classes.chatBotMessage}
                                        dangerouslySetInnerHTML={{
                                            __html: i18n.t('CHAT_BOT_MESSAGE', {
                                                privacyLink:
                                                    'https://www.diligent.com/legal/privacy',
                                                privacyPolicyText: `${classes.privacyPolicyText}`,
                                                noopener: 'noopener noreferrer',
                                                index: isOpen ? 0 : -1
                                            })
                                        }}></Typography>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box className={classes.messagesContainer}>
                            {props.chatMessages.map((messageObj, index) => (
                                <Box
                                    style={{ whiteSpace: 'pre-line' }}
                                    key={index}
                                    className={
                                        messageObj.sender === 'user'
                                            ? classes.senderChat
                                            : classes.responseChat
                                    }>
                                    {messageObj.message}
                                </Box>
                            ))}

                            {chatMessages.trim().length < 1 && isWriting && (
                                <p className={classes.responseChat}>
                                    <li className={` ${classes.message}`}>
                                        <span className={classes.loaderDot}>.</span>
                                        <span className={classes.loaderDot2}>.</span>
                                        <span className={classes.loaderDot3}>.</span>
                                    </li>
                                </p>
                            )}
                            {chatMessages.trim().length > 0 && !errorMessage && (
                                <SequentialCharacter
                                    className={classes.responseChat}
                                    interval={5}
                                    message={chatMessages.trimLeft()}
                                    isExecutionComplete={isExecutionComplete}
                                    setChatMessages={setChatMessages}
                                    reduxChat={props.actions.setChatMessages}
                                    isWriting={isWriting}
                                    setIswriting={setIsWriting}
                                    bottomRef={bottomRef}
                                    setIsExecutionComplete={setIsExecutionComplete}
                                    allowAutoScrollRef={allowAutoScrollRef}
                                />
                            )}
                        </Box>
                    )}
                </div>
                <Box className={classes.formContainer}>
                    {props.chatMessages.length > 0 && (
                        <Button
                            disableFocusRipple
                            disableRipple
                            disabled={!isOpen}
                            className={
                                isWriting
                                    ? `${classes.nogenerating} ${classes.generating}`
                                    : classes.nogenerating
                            }
                            onClick={handleStopButtonClick}>
                            {i18n.t('STOP_GENERATING_BUTTON')}
                            <StopIcon />
                        </Button>
                    )}
                    {!!errorMessage && !isWriting && (
                        <Box className={classes.errorMessage}>
                            {getAtlasHelperText({ errorText: errorMessage })}
                        </Box>
                    )}
                    <form onSubmit={handleClick}>
                        <TextField
                            placeholder={i18n.t('TYPE_QUESTIONS')}
                            variant="outlined"
                            name={CHAT_BOT.QUESTION}
                            multiline
                            disabled={!isOpen}
                            className={!inputValue ? classes.textField : classes.filledTexField}
                            onKeyPress={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            tabIndex={isOpen ? '0' : '-1'}
                                            type="submit"
                                            className={classes.sendIconBtn}
                                            disabled={isSendBtndisabled}>
                                            <SendStyledIcon
                                                disabled={isSendBtndisabled}
                                                className={classes.sendIcon}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            rowsMax={4}
                        />
                    </form>
                </Box>
            </Box>
        </>
    )
}

const SendStyledIcon = styled(SendIcon)<{ disabled: boolean }>`
    fill: ${({ disabled }) => (disabled ? '#B3B3B3' : `${ColorEastBay}`)};
    height: 24px;
    width: 24px;
    margin-left: 12px;
    margin-right: 0;
`

const mapStateToProps = (state, _) => {
    return {
        chatMessages: selectors.getChatMessages(state.minutesAttendeesReducer),
        identityToken: selectors.getIdentityToken(state.minutesAttendeesReducer)
    }
}
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(AiHelpChatBox)
