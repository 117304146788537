import { SelectClassKey, SelectProps } from '@material-ui/core/Select'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiSelect: Partial<StyleRules<SelectClassKey, SelectProps>> = {
    select: {
        '&:focus': {
            backgroundColor: 'transparent'
        }
    }
}
