import moment from 'moment'
import { pathOr, path, find } from 'rambdax'

export const getBookDetailsForCreateMinutes = (
    bookDetails,
    bookId: string,
    bookCommitteeId: string | null,
    committees: Committee[]
) => {
    const bookExists = path<string | number>(`books.${bookId}`, bookDetails)

    if (bookExists && !!bookCommitteeId) {
        const linkedMinutes = getMinutesfromBookDetails(bookId, bookDetails)
        const bookCommittee = find((c) => +c.id === +bookCommitteeId, committees)
        // convenience variable for readability
        const bookAttributes = path(`books.${bookId}.attributes`, bookDetails)

        const bookTitle = path<string>('title', bookAttributes)
        const attendees = path<Attendee[]>('attendees', bookAttributes)
        const meetingStartDate = path<string>('startDate', bookAttributes)
        const meetingStartTime = path<string>('startTime', bookAttributes)
        const meetingLocation = path<string>('location', bookAttributes)

        // This will still create moment objects using your local time zone,
        // but it won't do any sort of auto-timezone calculation
        const adjustedStartDate = !!meetingStartDate
            ? moment(meetingStartDate, 'YYYY-MM-DDTHH:mm:ss[Z]')
                  .startOf('day')
                  .format()
            : null

        const [startHour, startMinute]: Array<string | null> = !!meetingStartTime
            ? meetingStartTime.split(':', 2)
            : [null, null]

        return !!bookCommittee
            ? {
                  book: { id: bookId, title: bookTitle },
                  committee: bookCommittee,
                  attendees: attendees,
                  meeting_dates: [
                      {
                          start_date: adjustedStartDate,
                          start_hour: startHour ? +startHour : null,
                          start_minute: startMinute ? +startMinute : null,
                          end_date: null,
                          end_hour: null,
                          end_minute: null
                      }
                  ],
                  minutesDocument: linkedMinutes,
                  location: meetingLocation
              }
            : undefined
    }

    return undefined
}

type LinkedMinutesSummary = { id: string | null; status: DocumentStatusKey | null }
const getMinutesfromBookDetails = (bookId, bookDetails) => {
    const emptyLinkedMinutes: LinkedMinutesSummary = { id: null, status: null }
    return pathOr(emptyLinkedMinutes, `books.${bookId}.attributes.minutesDocument`, bookDetails)
}
