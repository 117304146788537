import { combineReducers, Reducer } from 'redux'

import applicationReducer from './applicationReducer'
import minuteEditorReducer, { ActionEditorStateType } from './minuteEditorReducer'
import minuteTakerReducer, { MinutesTakerState } from './minuteTakerReducer'
import actionEditorReducer from './actionEditorReducer'
import minuteActionItemsReducer, { MinuteActionItemsStateType } from './minuteActionItemsReducer'
import minutesAttendeesReducer, { MinutesAttendeesStateType } from './minutesAttendeesReducer'
import actionDashboardReducer, { ActionDashboardStateType } from './actionDashboardReducer'
export type RootState = {
    applicationReducer: ApplicationStateType
    minuteEditorReducer: MinuteEditorStateType
    minuteTakerReducer: MinutesTakerState
    actionEditorReducer: ActionEditorStateType
    minuteActionItemsReducer: MinuteActionItemsStateType
    minutesAttendeesReducer: MinutesAttendeesStateType
    actionDashboardReducer: ActionDashboardStateType
}
type ApplicationStateType = {}

type MinuteEditorStateType = {}

const rootReducer: Reducer<RootState> = combineReducers({
    applicationReducer,
    minuteEditorReducer,
    minuteTakerReducer,
    actionEditorReducer,
    minuteActionItemsReducer,
    minutesAttendeesReducer,
    actionDashboardReducer
})

export default rootReducer
