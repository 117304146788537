import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
`
const ExportToExcelIcon = () => (
    <Svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.25 16.3808V2.83402L4.45128 6.1941C4.18618 6.51237 3.71327 6.55548 3.395 6.29038C3.07673 6.02528 3.03362 5.55237 3.29872 5.2341L7.42004 0.286138C7.44378 0.25722 7.46964 0.23011 7.49738 0.205044C7.63038 0.0848864 7.80665 0.0117188 8 0.0117188C8.21593 0.0117188 8.41054 0.102968 8.54739 0.249008C8.55864 0.261014 8.5695 0.273391 8.57995 0.286118L12.7013 5.2341C12.9664 5.55237 12.9233 6.02528 12.605 6.29038C12.2867 6.55548 11.8138 6.51237 11.5487 6.1941L8.75 2.83402V16.3808C8.75 16.795 8.41421 17.1308 8 17.1308C7.58579 17.1308 7.25 16.795 7.25 16.3808ZM1.25 9.91648C0.835786 9.91648 0.5 10.2523 0.5 10.6665V23.2379C0.5 23.6521 0.835786 23.9879 1.25 23.9879H14.75C15.1642 23.9879 15.5 23.6521 15.5 23.2379V10.6665C15.5 10.2523 15.1642 9.91648 14.75 9.91648H11C10.5858 9.91648 10.25 10.2523 10.25 10.6665C10.25 11.0807 10.5858 11.4165 11 11.4165H14V22.4879H2V11.4165H5C5.41421 11.4165 5.75 11.0807 5.75 10.6665C5.75 10.2523 5.41421 9.91648 5 9.91648H1.25Z"
            fill="#5D7599"
        />
    </Svg>
)
export default ExportToExcelIcon
