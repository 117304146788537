import { exchangeUuidToken } from 'businesslayer/api/identity'
import { useEffect, useState } from 'react'

type SuccesURLState = {
    error: any
    url: string
}
type FailURL = {
    source: any
    status: any
    error: any
    url: string
    message: string
}
export const useUuid = (uuid) => {
    const [urlData, setUrlData] = useState<SuccesURLState>({
        error: '',
        url: ''
    })
    const [errorMessage, setErrorMessage] = useState<FailURL>({
        source: '',
        status: '',
        error: '',
        url: '',
        message: ''
    })

    const fetchApi = async (uuid) => {
        try {
            const data = await exchangeUuidToken(uuid, exchangeUuidToken.bind(null, uuid))
            setUrlData(data)
        } catch (err) {
            const errordata = await err.response.json()

            setErrorMessage({
                source: errordata.source,
                status: err.response.status,
                error: err.response.statusText,
                url: errordata.parent_url,
                message: errordata.message
            })
        }
    }
    useEffect(() => {
        if (uuid) {
            fetchApi(uuid)
        }
    }, [uuid])
    if (urlData.url) {
        const redirectUrl = new URL(urlData.url)
        window.location.href = `${redirectUrl.pathname}`
    }
    if (errorMessage.status === 403) {
        window.location.href = errorMessage.url
    }

    return {
        errorMessage
    }
}
