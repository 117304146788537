import React, { FC, useMemo } from 'react'
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers'
import { TextField } from '@material-ui/core'
import { makeMuiFieldAdapter } from 'components/final-form/final-form-helper'
import { getAtlasHelperText } from 'components/text-field/input-error-text'
import { AtlasIcon } from 'web-components/atlas-icon'
import { AtlasBlackNearVar } from 'assets/styles/variables'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import {
    get12or24HourTimeFormat,
    isTwelveHourTime,
    getTimeMaskText,
    getTimePickerMask
} from 'common/util/date'
import i18n from 'common/util/internationalization'

export const AtlasTimePicker: FC<KeyboardTimePickerProps & TestIdAttribute> = ({
    helperText,
    value,
    'data-testid': data_testid,
    ...props
}) => {
    const { timeFormat: rawTimeFormat } = transientStorageManager
    const AtlasHelperTextComponent = useMemo(() => getAtlasHelperText({ errorText: helperText }), [
        helperText
    ])
    const timeFormat = get12or24HourTimeFormat(rawTimeFormat)
    const isAMPM = isTwelveHourTime(timeFormat)
    const placeholder = getTimeMaskText(timeFormat)
    const timeMask = getTimePickerMask(timeFormat)

    return (
        <KeyboardTimePicker
            value={value || null}
            placeholder={placeholder}
            ampm={isAMPM}
            format={timeFormat}
            mask={timeMask}
            keyboardIcon={<AtlasIcon name="manage-minutes" color={AtlasBlackNearVar} />}
            helperText={AtlasHelperTextComponent}
            DialogProps={{
                fullWidth: false,
                PaperProps: {
                    'aria-label': i18n.t('TIME_PICKER')
                }
            }}
            TextFieldComponent={TextField}
            onAccept={(e) => !!props.onBlur && props.onBlur(e as any)}
            inputProps={{ 'data-testid': data_testid }}
            KeyboardButtonProps={{ 'data-testid': `${data_testid}_Btn` } as any}
            {...props}
        />
    )
}

export const AtlasTimePickerAdapter = makeMuiFieldAdapter<
    KeyboardTimePickerProps & TestIdAttribute
>(AtlasTimePicker)
