export default class textValidator {

    static isLengthValid(value, min, max) {

        if (min && !value) {
            return false
        }

        if (typeof value !== 'string') {
            return false
        }


        if (min && value.length < min) {
            return false
        }

        if (max && value.length > max) {
            return false
        }

        const trimmed = value.trim()
        if (min && trimmed.length < min) {
            return false
        }
        return true
    }

}