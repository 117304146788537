import React from 'react'

function DiligentErrorIcon() {
    return (
        <svg
            width="44"
            height="48"
            viewBox="0 0 44 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.4043 23.9685C43.4043 31.3414 40.7763 37.416 36.2835 41.6149C36.2318 41.6605 36.193 41.7082 36.1413 41.7538C32.7997 44.8215 28.4428 46.8602 23.3701 47.6396L23.0273 47.2879L30.5878 3.48669L30.8724 2.68557C38.6118 6.49361 43.4043 14.0945 43.4043 23.9685Z"
                fill="#EE312E"
            />
            <path
                d="M30.8746 2.68777L0.0999756 25.6619V47.2141V48H18.6316C20.2722 48 21.8524 47.8741 23.3723 47.6396C23.3723 47.6418 30.8746 2.68777 30.8746 2.68777Z"
                fill="#D3222A"
            />
            <path
                d="M30.8746 2.68777L30.6611 3.00692L0.259508 25.8117L0.0999756 25.6619C0.0999756 25.6619 0.0999756 25.6554 0.0999756 25.6489V0H18.5604C19.8345 0 21.0698 0.0738161 22.2663 0.219277H22.3116C25.3341 0.579673 28.1173 1.37211 30.6073 2.55968C30.7064 2.60744 30.8746 2.68777 30.8746 2.68777Z"
                fill="#AF292E"
            />
        </svg>
    )
}

export default DiligentErrorIcon
