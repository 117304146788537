import React from 'react'

type Props = {
    title?: string
    className?: string
}

const ActionDashboardIcon = ({ title, className }: Props) => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        {title}
        <path
            d="M16 20.07L12.0961 16.1662L13.15 15.1124L16 17.9624L21.6692 12.2932L22.723 13.347L16 20.07ZM21 10.5008H19.5V5.50084H17V8.11619H8V5.50084H5.49997V19.5008H11.5V21.0008H4V4.00087H10.2135C10.3519 3.5137 10.635 3.10987 11.0625 2.78937C11.49 2.46885 11.9692 2.30859 12.5 2.30859C13.0512 2.30859 13.5381 2.46885 13.9605 2.78937C14.383 3.10987 14.6634 3.5137 14.8019 4.00087H21V10.5008ZM12.5016 5.61624C12.7582 5.61624 12.9727 5.52946 13.1452 5.35589C13.3176 5.18234 13.4038 4.96729 13.4038 4.71072C13.4038 4.45415 13.317 4.23965 13.1435 4.06722C12.9699 3.89479 12.7549 3.80857 12.4983 3.80857C12.2417 3.80857 12.0272 3.89534 11.8548 4.06889C11.6823 4.24246 11.5961 4.45753 11.5961 4.71409C11.5961 4.97066 11.6829 5.18516 11.8565 5.35759C12.03 5.53003 12.2451 5.61624 12.5016 5.61624Z"
            fill="white"
        />
    </svg>
)

export default ActionDashboardIcon
