import React, { Component } from 'react'
import { pathOr } from 'rambdax'
import { log } from 'businesslayer/api/log'
import ms from 'ms'

const propOr: <T>(prop: T, path: string, obj: any) => T = (prop, path: string, obj: any) =>
    pathOr(prop, path.split('.'), obj)

const logPingError = (url: string, timeout: number) => {
    const error = `Failed to reach ${url} after ${ms(timeout)}`
    console.error(error)
    log({ message: error })
}

const ping = ({ url, timeout }: { url: string; timeout: number }) => {
    return new Promise((resolve) => {
        const resolveOffline = () => {
            logPingError(url, timeout)
            resolve(false)
        }
        const xhr = new XMLHttpRequest()

        xhr.onerror = resolveOffline
        xhr.ontimeout = resolveOffline
        xhr.onload = () => {
            const isSuccess = xhr.statusText === 'OK' || xhr.status === 200

            if (!isSuccess) {
                logPingError(url, timeout)
            }

            resolve(isSuccess)
        }

        xhr.open('GET', url)
        xhr.timeout = timeout
        xhr.send()
    })
}

type InjectedReachabilityProps = { reset: () => void } & ReachabilityState

type PollingProps = {
    url: string
    interval: number
    timeout: number
    failInterval: number
    retryCount: number
}

type ReachabilityProps = {
    enabled?: boolean
    polling?: Partial<PollingProps>
    onChange?: (status: ReachabilityStatus, prevousValue: ReachabilityStatus) => void
    children: (props: InjectedReachabilityProps) => React.ReactNode
}

type ReachabilityState = { status: ReachabilityStatus }

const defaultProps = {
    enabled: true,
    polling: {
        url: '/ping',
        timeout: 5000,
        interval: 5000,
        failInterval: 2000,
        retryCount: 5
    }
}

enum ReachabilityStatus {
    None = 'none',
    Reachable = 'reachable',
    Unreachable = 'unreachable'
}

export class Reachability extends Component<ReachabilityProps, ReachabilityState> {
    pollingId = 0
    failurePollingId = 0
    isFetching = false
    _ismounted = false

    currentRetryCount = 0

    state = {
        status: ReachabilityStatus.None
    }

    componentDidMount() {
        this._ismounted = true

        window.addEventListener('online', this.handleOnline)
        window.addEventListener('offline', this.handleOffline)

        this.setState(
            {
                status: navigator.onLine ? ReachabilityStatus.None : ReachabilityStatus.Unreachable
            },
            () => this.props.enabled && navigator.onLine && this.startPolling()
        )
    }

    componentWillUnmount() {
        this._ismounted = false
        this.clearIntervals()

        window.removeEventListener('online', this.handleOnline)
        window.removeEventListener('offline', this.handleOffline)
    }

    clearIntervals = () => {
        window.clearInterval(this.pollingId)
        window.clearInterval(this.failurePollingId)
    }

    private handleOnline = () => {
        this.setState(
            {
                status: ReachabilityStatus.None
            },
            this.startPolling
        )
    }

    private handleOffline = () => {
        this.setState(
            {
                status: ReachabilityStatus.Unreachable
            },
            this.clearIntervals
        )
    }

    private onChange = (updatedValue: ReachabilityStatus, previousValue: ReachabilityStatus) => {
        if (this.props.onChange) {
            this.props.onChange(updatedValue, previousValue)
        }
    }

    private startFailurePolling = () => {
        const url = propOr(defaultProps.polling.url, 'polling.url', this.props)
        const failInterval = propOr(
            defaultProps.polling.failInterval,
            'polling.failInterval',
            this.props
        )
        const timeout = propOr(defaultProps.polling.timeout, 'polling.timeout', this.props)

        this.clearIntervals()

        this.failurePollingId = window.setInterval(() => {
            if (!this.isFetching) {
                this.isFetching = true

                ping({ url, timeout })
                    .then((online) => {
                        if (!this._ismounted) return
                        if (online) {
                            this.setReachable()
                            this.startPolling()
                        } else {
                            this.setUnreachable()
                        }
                    })
                    .then(() => (this.isFetching = false))
            }
        }, failInterval)
    }

    private startPolling = () => {
        const url = propOr(defaultProps.polling.url, 'polling.url', this.props)
        const interval = propOr(defaultProps.polling.interval, 'polling.interval', this.props)
        const timeout = propOr(defaultProps.polling.timeout, 'polling.timeout', this.props)

        this.clearIntervals()

        this.pollingId = window.setInterval(() => {
            if (!this.isFetching) {
                this.isFetching = true

                ping({ url, timeout })
                    .then((online) => {
                        if (!this._ismounted) return

                        return online ? this.setReachable() : this.startFailurePolling()
                    })
                    .then(() => (this.isFetching = false))
            }
        }, interval)
    }

    private setReachable = () => {
        const valueChanged =
            this.state.status === ReachabilityStatus.None ||
            this.state.status === ReachabilityStatus.Unreachable

        if (valueChanged) {
            this.currentRetryCount = 0
            const previousStatus = this.state.status
            this.setState({ status: ReachabilityStatus.Reachable }, () =>
                this.onChange(this.state.status, previousStatus)
            )
        }
    }

    private setUnreachable = () => {
        const retryCount = propOr(defaultProps.polling.retryCount, 'polling.retryCount', this.props)
        const valueChanged =
            this.state.status === ReachabilityStatus.None ||
            this.state.status === ReachabilityStatus.Reachable

        this.currentRetryCount = this.currentRetryCount + 1

        if (valueChanged && this.currentRetryCount > retryCount - 1) {
            const previousStatus = this.state.status
            this.setState({ status: ReachabilityStatus.Unreachable }, () =>
                this.onChange(this.state.status, previousStatus)
            )
        }
    }

    private reset = () => {
        this.setState({ status: ReachabilityStatus.None }, this.startPolling)
    }

    render() {
        return (
            this.props.children &&
            this.state.status !== ReachabilityStatus.None &&
            this.props.children({ reset: this.reset, ...this.state })
        )
    }

    static defaultProps = defaultProps
}

export default Reachability
