import { createAction, handleActions } from 'redux-actions'
import { dataState } from '../components/helpers/reducerHelpers'
import { Reducer } from 'redux'
// Define action types
const CREATE_NEW_REPORT = 'CREATE_NEW_REPORT'
const STORE_CREATE_REPORT_RESPONSE = 'STORE_CREATE_REPORT_RESPONSE'
const SET_CREATE_REPORT_LOADING = 'SET_CREATE_REPORT_LOADING'
const PRESERVE_FORM_STATE = 'PRESERVE_FORM_STATE'
const INITIAL_STATE = 'INITIAL_STATE'
const SET_MEETINGS_LOADING = 'SET_MEETINGS_LOADING'
const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES'
const SET_IDENTITY_TOKEN = 'SET_IDENTITY_TOKEN'

// Create actions
export const actions = {
    createNewReport: createAction(CREATE_NEW_REPORT),
    storeCreateReportResponse: createAction(STORE_CREATE_REPORT_RESPONSE),
    setCreateReportLoading: createAction(SET_CREATE_REPORT_LOADING),
    preserveFormState: createAction(PRESERVE_FORM_STATE),
    initializeState: createAction(INITIAL_STATE),
    setMeetingsLoading: createAction(SET_MEETINGS_LOADING),
    setChatMessages: createAction(SET_CHAT_MESSAGES),
    setIdentityToken: createAction(SET_IDENTITY_TOKEN)
}

// Define initial state
interface NewReportData {
    selectedCommittee: string[]
    committeeId: string
    committeeName: string
    startDate: string | null
    endDate: string | null
}
interface ReportRow {
    name: string
    attended: string
    percentage: string
}
interface AttendanceData {
    attendance_data: ReportRow[]
    total_meeting_count?: number
}
interface DataState {
    newReportData: NewReportData
    attendanceReportResponse: AttendanceData | null
    meetingsLoading: boolean
    chatMessages: ChatObjects[]
    identityToken: ChatTokenResponse
}

interface ReportAttendee {
    name: string
    attended: string
    percentage: string
}
export interface PreservedFormState {
    committeeName: string
    startDate: string
    endDate: string
    isReportGenerated: boolean
}

interface ControlState {
    createReportLoading: boolean
    preservedFormState: PreservedFormState
}

interface AppState {
    dataState: DataState
    controlState: ControlState
}

export const initialState: AppState = {
    dataState: {
        newReportData: {
            selectedCommittee: [],
            committeeId: '',
            committeeName: '',
            startDate: '',
            endDate: ''
        },
        attendanceReportResponse: null,
        meetingsLoading: false,
        chatMessages: [],
        identityToken: {
            connector_api_url: null,
            connector_token: null
        }
    },
    controlState: {
        createReportLoading: false,
        preservedFormState: {
            committeeName: '',
            startDate: '',
            endDate: '',
            isReportGenerated: false
        }
    }
}

const reducer: Reducer<AppState> = handleActions<AppState>(
    {
        [INITIAL_STATE]: () => initialState,
        [CREATE_NEW_REPORT]: (state, action) => {
            const newReportData: NewReportData = action.payload
            return dataState(state, { newReportData: newReportData })
        },
        [SET_CHAT_MESSAGES]: (state, action) => {
            const chatMessages: any = action.payload
            if (chatMessages.message.trim().length > 0) {
                return dataState(state, {
                    chatMessages: [...state.dataState.chatMessages, chatMessages]
                })
            } else {
                return dataState(state, {
                    chatMessages: [...state.dataState.chatMessages]
                })
            }
        },
        [SET_IDENTITY_TOKEN]: (state, action) => {
            const identityToken = action.payload
            return dataState(state, { identityToken: identityToken })
        },
        [STORE_CREATE_REPORT_RESPONSE]: (state, action) => {
            const attendanceReportResponse = action.payload
            return dataState(state, { attendanceReportResponse: attendanceReportResponse })
        },
        [SET_CREATE_REPORT_LOADING]: (state, action) => {
            const createReportLoading = action.payload
            return {
                ...state,
                controlState: {
                    ...state.controlState,
                    createReportLoading
                }
            }
        },
        [PRESERVE_FORM_STATE]: (state, action) => {
            const preservedFormState = action.payload
            return {
                ...state,
                controlState: {
                    ...state.controlState,
                    preservedFormState
                }
            }
        },
        [SET_MEETINGS_LOADING]: (state, action) => {
            const meetingsLoading = action.payload
            return {
                ...state,
                controlState: {
                    ...state.controlState,
                    meetingsLoading
                }
            }
        }
    },
    initialState
)

export default reducer

export interface MinutesAttendeesStateType {
    attendees: ReportAttendee[]
    totalMeetingCount?: number
}
