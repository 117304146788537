import React from 'react'
import { MenuItem } from '@material-ui/core'
import i18n from 'common/util/internationalization'

export type BookStructure = 'TOP_LEVEL' | 'ENTIRE_BOOK' | 'NONE'
type BookStructureItem = { id: BookStructure; label: string }

/**
 * Recommended to memoize the result when using this
 * Ex: useMemo(() => timezoneMenuItems(), []))
 */
export const linkedBookStructureMenuItems = (): JSX.Element[] => {
    const items: BookStructureItem[] = [
        { id: 'NONE', label: i18n.t('BOOK_STRUCTURE_ITEMS.NONE') },
        { id: 'TOP_LEVEL', label: i18n.t('BOOK_STRUCTURE_ITEMS.TOP_LEVEL') },
        { id: 'ENTIRE_BOOK', label: i18n.t('BOOK_STRUCTURE_ITEMS.ENTIRE_BOOK') }
    ]

    const menuItems = items.map((val, index) => {
        return (
            <MenuItem
                key={val.id + index}
                value={val.id}
                data-testid={`LinkedBookStructureSelect_Option`}>
                {val.label}
            </MenuItem>
        )
    })

    return menuItems
}

export const DEFAULT_BOOK_STRUCTURE_ITEM = 'TOP_LEVEL'
