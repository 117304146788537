import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #2379a0;
`

type Props = {
    title?: JSX.Element
    className?: string
}

export const StopIcon = ({ className }: Props) => (
    <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5998 4.79999H6.3998C5.5174 4.79999 4.7998 5.51679 4.7998 6.39999V17.6C4.7998 18.4824 5.5174 19.2 6.3998 19.2H17.5998C18.4822 19.2 19.1998 18.4824 19.1998 17.6V6.39999C19.1998 5.51679 18.4822 4.79999 17.5998 4.79999Z"
            fill="#455D82"
        />
    </Svg>
)

export default StopIcon
