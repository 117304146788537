import React, { useEffect, useRef, FC, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { makeStyles } from '@material-ui/core'
import { CellValueChangedEvent, ColDef, GridOptions } from 'ag-grid-community'

interface AgGridComponentProps {
    columns: ColDef[]
    data: any[]
    gridOptions?: GridOptions
    filterState?: any
    onCellValueChanged?: (event: CellValueChangedEvent<any, any>) => void
    pagination?: boolean
    triggerColumnResizing?: boolean
    OverlayNoRowsTemplate?: any
    onSortChanged?: any
    formData?: any
    gridClass?: string
}
const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto'
    },
    gridContainer: {
        width: '100%',
        height: '100%',
        minHeight: '400px'
    },
    customHeader: {
        borderLeft: '1px solid #E6E6E6'
    }
}))
const AgGridComponent: FC<AgGridComponentProps> = React.memo(
    ({
        columns,
        data,
        gridOptions,
        filterState,
        onCellValueChanged,
        pagination = false,
        OverlayNoRowsTemplate,
        onSortChanged,
        formData,
        gridClass
    }) => {
        const apiRef = useRef<any>(null)
        const classes = useStyles()
        const containerRef = useRef<HTMLDivElement>(null)
        useEffect(() => {
            if (filterState) {
                apiRef.current?.setFilterModel?.({
                    ...(apiRef.current?.getFilterModel() || {}),
                    ...filterState
                })
            }
        }, [filterState])

        const onGridReady = useCallback((params: any) => {
            apiRef.current = params.api
            // params.api.sizeColumnsToFit() //for future test
        }, [])
        useEffect(() => {
            const observer = new ResizeObserver(() => {
                if (apiRef.current && (containerRef?.current?.clientWidth ?? 0) > 1400) {
                    apiRef.current.sizeColumnsToFit()
                }
            })
            if (containerRef.current) {
                observer.observe(containerRef.current)
            }
            return () => {
                observer.disconnect()
            }
        }, [])

        const memoizedData = useMemo(() => data, [data])

        const memoizedColumns = useMemo(
            () =>
                columns.map((column) => ({
                    ...column,
                    headerClass: `${classes.customHeader} ${column.headerClass || ''}`
                })),

            [columns, classes.customHeader]
        )

        const defaultColDef = useMemo<ColDef>(() => {
            return {
                flex: 1,
                minWidth: 100,
                cellStyle: {
                    borderLeft: '1px solid #E6E6E6',
                    lineHeight: '1.7',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                    font: '400 16px/20px Source Sans Pro'
                },
                wrapText: true,
                autoHeight: true,
                suppressSizeToFit: true
            }
        }, [])
        const noRowsOverlayComponent = useMemo(() => {
            return OverlayNoRowsTemplate
        }, [OverlayNoRowsTemplate])
        if (!memoizedData || memoizedData.length === 0)
            return <OverlayNoRowsTemplate formData={formData} />
        return (
            <div className={`${classes.root}`}>
                <div className={`ag-theme-alpine ${classes.root} ${gridClass}`} ref={containerRef}>
                    <AgGridReact
                        columnDefs={memoizedColumns}
                        rowData={memoizedData}
                        domLayout="autoHeight"
                        pagination={pagination}
                        gridOptions={gridOptions}
                        paginationPageSize={10}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        rowMultiSelectWithClick={true}
                        animateRows={true}
                        defaultColDef={defaultColDef}
                        suppressDragLeaveHidesColumns={true}
                        onGridReady={onGridReady}
                        onCellValueChanged={onCellValueChanged}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        onSortChanged={onSortChanged}
                        onCellClicked={gridOptions?.onCellClicked}
                        onCellKeyPress={gridOptions?.onCellClicked}
                        tooltipShowDelay={0}
                    />
                </div>
            </div>
        )
    }
)

export default AgGridComponent
