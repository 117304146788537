/** @jsx jsx */
import { FC } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/react'

/**
 * Provides Components for overall modal form
 */
export const FormBody: FC<React.HTMLAttributes<HTMLFormElement>> = ({ ...props }) => {
    return <Body {...props} autoComplete={'off'} />
}

/**
 * Row Container for Form
 * - verticalSpacing: We provide this prop because of the perceived
 *   difference in spacing due to the hint text below some fields
 * - visibleWhen: Allows clean conditional rendering of rows in a form
 * (ex: Duplicate Minutes Options)
 */
export const FormRow: FC<{
    visibleWhen?: boolean
    verticalSpacing?: 'small'
    allignItems?: string
}> = ({ visibleWhen = true, verticalSpacing, allignItems, children }) => {
    return !!visibleWhen ? (
        <Row verticalSpacing={verticalSpacing} allignItems={allignItems}>
            {children}
        </Row>
    ) : null
}

const Body = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow-y: hidden;

    /* TODO: Can be removed when fully Atlas  */
    /*  - sets the default font to be Atlas Compliant */
    * {
        font-family: 'Source Sans Pro', sans-serif;
    }
`
const Row = styled.div<{ verticalSpacing?: 'small'; allignItems?: string }>`
    display: flex;
    justify-content: flex-start;
    column-gap: ${({ allignItems }) => (allignItems ? '1rem' : 'unset')};
    align-items: ${({ allignItems }) => (allignItems ? 'baseline' : 'center')};
    min-height: 40px;
    margin-bottom: ${({ verticalSpacing }) => (verticalSpacing === 'small' ? '2px' : '20px')};
    > div {
        overflow-x: hidden;
        overflow-y: hidden;
    }
`
