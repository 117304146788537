import { useState, useEffect, useCallback } from 'react'
import { path } from 'rambdax'
import constate from 'constate'

interface ProviderProps {
    platform: string | null
}

const useBoardsIOSBridge = ({ platform }: ProviderProps) => {
    const [authResolvers, setAuthResolvers] = useState<Array<AuthResolver>>([])

    const isBoardsIOS = platform === 'boardsios'

    // Add the bridge callback for boardsios
    useEffect(() => {
        if (isBoardsIOS && !window.nativeWrapperNotification) {
            const nativeWrapperNotification = (data) => {
                var parsedData: BridgeMessage = JSON.parse(data)
                // TODO: Remove log after integration testing
                console.log('Response: ', parsedData)
                // Condition for different responses
                if (parsedData.mode === 'MINUTES_AUTH_TOKEN_CHANGED') {
                    const authMessageData = parsedData as TokenProvidedMessage

                    // Call each subscribed resolver
                    authResolvers.forEach((resolver) => {
                        resolver(authMessageData.MINUTES_AUTH_TOKEN)
                    })

                    // Clear the resolvers
                    setAuthResolvers([])
                }
            }

            window.nativeWrapperNotification = nativeWrapperNotification
        }
        return () => {
            window.nativeWrapperNotification = undefined
        }
    }, [isBoardsIOS, authResolvers])

    // Bridge Requests
    const requestAuthToken = useCallback((resolver: AuthResolver) => {
        // Test Response from Console:
        //  window.nativeWrapperNotification(JSON.stringify({
        //      "mode": "MINUTES_AUTH_TOKEN_CHANGED",
        //      "MINUTES_AUTH_TOKEN": "mock"
        //  }))
        setAuthResolvers((prevState) => {
            if (prevState.length === 0) {
                postMessage({ mode: 'MINUTES_AUTH_TOKEN_REQUESTED' })
            }

            return [...prevState, resolver]
        })
    }, [])

    // Not a Boards iOS User; Return no-op
    if (!isBoardsIOS) {
        return {
            requestAuthToken: () => {}
        }
    }

    return {
        requestAuthToken
    }
}

const postMessage = (wrapperRequest) => {
    const notifyContainer = path<NotifyContainer>(
        'window.webkit.messageHandlers.notifyContainer',
        window
    )
    if (!!notifyContainer && !!notifyContainer.postMessage) {
        // TODO: Remove log after integration testing
        console.log('Request: ' + JSON.stringify(wrapperRequest))
        notifyContainer.postMessage(wrapperRequest)
    }
}

// Constate factory returns the useable (Provider, useStateHook, useActionsHook)
const [BoardsIOSBridgeProvider, useBoardsIOSBridgeContext] = constate(
    (providerProps: ProviderProps) => useBoardsIOSBridge(providerProps),
    (value) => {
        return value
    }
)

export { BoardsIOSBridgeProvider, useBoardsIOSBridgeContext }
