import React, { useState } from 'react'
import PropTypes from 'prop-types'

//================ Components Material =======================
import { IconButton } from 'components/shared/StyledComponents'

//================ Components ===============================

import i18n from 'i18next'

import RevealListIcon from 'assets/icons/RevealListIcon'
import HideListIcon from 'assets/icons/HideListIcon'
import RichEditor from '../../minutericheditor/MinuteEditorArea'
import Title from '../../minutericheditor/MinuteTitleArea'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import { reorderActions } from 'businesslayer/api/actions'
import { getActions } from 'businesslayer/api/actions'
//================ Redux ====================================
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../../reducers/minuteTakerReducer'

import selectors from '../../../selectors/minuteTakerSelectors'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import { makeStyles } from '@material-ui/core/styles'

//================ DnD ====================================

import { TrashCanDeleteIcon as DeleteSectionIcon } from 'assets/icons/TrashCanDeleteIcon'
import { MoveSectionIcon } from 'assets/icons/MoveSectionIcon'
import {
    ColorBaseGreyLight,
    ColorBaseSkyBlue,
    ColorBaseMulberryBlue,
    ColorBaseRichBlack
} from 'assets/styles/variables'
import { pathOr } from 'rambdax'
import { deleteSection } from 'businesslayer/api/sections'

//================ Styles ===================================

const foldIconWrapperStyle = css`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    cursor: pointer;
    :focus {
        box-shadow: none;
        box-shadow: 0px 0px 0px 2px ${ColorBaseSkyBlue};
        width: 40px;
        height: 30px;
        outline: none;
        border-radius: 2px;
    }
`

const iconContainerStyle = css`
    height: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 90px;
    margin-right: 20px;
`

const useStyles = makeStyles({
    myIconButton: {
        padding: '7px',
        '&:focus': {
            border: `2px solid ${ColorBaseMulberryBlue}`,
            padding: '5px',
            borderRadius: ' 40px'
        }
    },
    disabledIconButton: {
        opacity: 0.2,
        pointerEvents: 'none'
    }
})

//================ Implementation ===========================

const StyledDeleteSectionIcon = styled(DeleteSectionIcon)`
    fill: ${ColorBaseRichBlack};
    width: 22px;
    height: 22px;
`

const StyledMoveSectionIcon = styled(MoveSectionIcon)`
    fill: ${ColorBaseRichBlack};
    width: 24px;
    height: 24px;
`

type Props = {
    actions: any
    order: number
    sectionId: any
    currentMinuteItem: any
    sections: any
    selectedSection: any
    originalKey: any
    isOpened: boolean
    deletingSectionId: any
    actionItems: Array<any>
    readonly?: boolean
    dragProps: any
    snapshot: any
    isSavingSection?: boolean
    onSectionReorder: (...args: any[]) => void
    onActionChecked: (...args: any[]) => void
    onEditAction: (...args: any[]) => void
    onDeleteAction: (...args: any[]) => void
    onViewAllActions: (...args: any[]) => void
}

async function reloadActions(minutesId, loadActionsFulfilled) {
    const [minutesActions] = await Promise.all([getActions(minutesId)])
    loadActionsFulfilled(minutesActions)
}

function MinuteEditorListItem(props: Props) {
    const [showConfirmation, setShowConfirmation] = useState(false)
    const classes = useStyles(props)

    const onDelete = () => {
        const { sectionId, currentMinuteItem, sections } = props

        if (!sectionId || isNaN(sectionId) || !currentMinuteItem) {
            return
        }

        //Step 1 is to select section by id so we could have current section property correct
        props.actions.setSelectedSection(sections.find((c) => c.id === sectionId))

        //We use timeout to let selection settle down
        setTimeout(() => {
            //Get section id again
            //Step 2 toggle on confirmation modal
            setShowConfirmation(true)
        }, 50)
    }

    function togglePopup() {
        // Get all elements with the class 'ai-modal'
        const popups = document.querySelectorAll('.ai-modal')
        const selectedSelection = isSelectedSection
        // If popups exist for selected section, hide them
        if (popups.length) {
            if (selectedSelection) {
                document
                    .querySelectorAll('.ai-modal')
                    .forEach((it: any) => (it.style.display = 'none'))
            }
        } else {
            console.error("Element(s) with class 'ai-modal' not found.")
        }
    }
    const onExpandCollapse = () => {
        togglePopup()
        props.actions.toggleSectionExpandCollapse(props.sectionId)
    }

    const handleConfirmAction = async () => {
        if (isNaN(sectionId)) {
            //Looks like user is trying to remove section he not yet saved, we cleat remove it locally
            props.actions.purgeSection(sectionId)
        } else {
            const minutesId = props.currentMinuteItem.id
            props.actions.deactivateSection(sectionId)

            try {
                props.actions.deleteSectionPending()
                const deleteResult = await deleteSection(
                    sectionId,
                    minutesId,
                    deleteSection.bind(null, sectionId, minutesId)
                )
                props.actions.deleteSectionFulfilled(deleteResult)
                props.actions.deleteActionsBySection(sectionId)
            } catch (error) {
                props.actions.deleteSectionRejected(error)
            }
        }

        setShowConfirmation(false)
    }

    const renderConfirmedAction = () => {
        if (!showConfirmation) {
            return null
        }

        const { selectedSection, sectionId, sections } = props
        let sectionName = selectedSection
            ? selectedSection.name
            : sections.find((c) => c.id === sectionId).name
        sectionName =
            sectionName && sectionName.length > 70 ? `${sectionName.substr(0, 70)}...` : sectionName

        return (
            <ConfirmationDialog
                preserveFormatting={true}
                message={i18n.t('DELETE_SECTION_MESSAGE', { section: sectionName })}
                title={i18n.t('DELETE_SECTION_TITLE')}
                onConfirm={handleConfirmAction}
                onCancel={() => setShowConfirmation(false)}
                confirmLabel={i18n.t('DELETE')}
            />
        )
    }

    const getRightsideButtons = () => {
        const deleteIcon = (
            <div>
                <IconButton
                    onClick={onDelete}
                    disableFocusRipple
                    disabled={props.isSavingSection}
                    className={`${classes.myIconButton} ${
                        props.isSavingSection ? classes.disabledIconButton : ''
                    }`}>
                    <StyledDeleteSectionIcon />
                </IconButton>
            </div>
        )
        const dragStyle = props?.snapshot?.isDragging
            ? {
                  border: '2px solid #3e95fa',
                  padding: '6px',
                  borderRadius: '40px',
                  outline: 'none',
                  cursor: 'pointer'
              }
            : {}
        //There is some glitch in FF so it cannot work with a button being drag area
        //So we icon wrapped in a div here
        //TODO: Alignment in all browsers

        //FF also has issue with colorization so it is switched off
        //var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
        const dragIcon = (
            <div style={dragStyle} {...props?.dragProps} className="dragHandleStyle">
                <div
                    aria-label={i18n.t('DRAGGABLE_ICON')}
                    style={{ paddingTop: '3px' }}
                    role="button"
                    tabIndex={-1}>
                    <StyledMoveSectionIcon />
                </div>
            </div>
        )

        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            ${iconContainerStyle}
                        `}>
                        {deleteIcon}
                        {dragIcon}
                    </div>
                )}
            </ClassNames>
        )
    }
    const handleEditorClicked = (sectionId) =>
        props.actions.setSelectedSection(props.sections.find((section) => section.id === sectionId))

    const updateSectionContent = (editor, sectionId) => {
        const minutesId = props.currentMinuteItem.id

        props.actions.updateSectionContentPending()
        props.actions.updateSectionContent({
            id: sectionId,
            minutesId,
            html: editor.getData()
        })
    }

    const handleActionReorder = (newSections) => {
        const { actionItems } = props

        let index = 0
        newSections.forEach((c) => (c.sortOrder = index++))

        // Call end point
        reorderActions(newSections, null).then(() => {
            reloadActions(actionItems[0].minutesId, props.actions.loadActionsFulfilled)
        })
    }

    const isOpened = props.isOpened

    const { selectedSection, sectionId, readonly } = props

    const section = props.sections.find((section) => section.id === sectionId)
    const editorData = pathOr('', ['html'], section)
    const editor = (
        <RichEditor
            sectionId={props.sectionId}
            actionItems={props.actionItems}
            onEditorClicked={handleEditorClicked}
            onActionReorder={handleActionReorder}
            onActionChecked={props.onActionChecked}
            onEditAction={props.onEditAction}
            onDeleteAction={props.onDeleteAction}
            onViewAllActions={props.onViewAllActions}
            onEditorDataChanged={updateSectionContent}
            editorData={editorData}
            isOpened={isOpened}
            readonly={readonly}
            key={props.originalKey}
        />
    )

    let isSelectedSection = false
    if (selectedSection && selectedSection.id === sectionId) {
        isSelectedSection = true
    }

    const StyledFoldIcon = styled(isOpened ? RevealListIcon : HideListIcon)<{
        isSelected: boolean
    }>`
        stroke: #1e1e1e;
        color: #1e1e1e;
    `

    const iconWrapped = (
        <ClassNames>
            {({ css }) => (
                <button
                    style={{
                        borderWidth: '0px',
                        backgroundColor: isSelectedSection ? '#f5f8f9' : '#F5F8F9',
                        paddingTop: '4px'
                    }}
                    className={css`
                        ${foldIconWrapperStyle}
                    `}
                    tabIndex={0}
                    aria-expanded={isOpened ? 'true' : 'false'}
                    aria-label={isOpened ? i18n.t('EXPAND_SECTION') : i18n.t('COLLAPSE_SECTION')}
                    aria-labelledby={isOpened ? 'new-section-title' : 'new-section-title'}
                    onClick={onExpandCollapse}>
                    <StyledFoldIcon isSelected={isSelectedSection} />
                </button>
            )}
        </ClassNames>
    )

    return (
        <ClassNames>
            {({ css }) => (
                <div>
                    <div
                        className={css`
                            ${minuteSectionClass}
                        `}>
                        <div
                            className={css`
                                ${sectionHeaderClass(
                                    props.deletingSectionId === props.sectionId,
                                    isSelectedSection
                                )}
                            `}>
                            {iconWrapped}
                            <Title
                                sectionId={props.sectionId}
                                isSelected={isSelectedSection}
                                readonly={readonly}
                            />
                            {!readonly && getRightsideButtons()}
                        </div>
                        {editor}
                        {renderConfirmedAction()}
                    </div>
                </div>
            )}
        </ClassNames>
    )
}
MinuteEditorListItem.propTypes = {
    key: PropTypes.string,
    originalKey: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired
}

const minuteSectionClass = css`
    border-bottom: 15px solid transparent;
`

const sectionHeaderClass: any = (isDeleting, isSelected) => {
    let background = '#F5F8F9'

    if (isSelected) {
        background = '#f5f8f9'
    }

    return css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${background};
        opacity: ${isDeleting ? 0.2 : 1.0};
        pointer-events: ${isDeleting ? 'none' : 'auto'};
        padding-top: 0;
        padding-bottom: 0;
        border-top: ${ColorBaseGreyLight};
        transition: all 0ms;
        padding-left: 10px;
        height: 60px;
        border-radius: 4px 4px 0 0;
    `
}

//================ Export / Redux Bindings ==================

const mapStateToProps = (state, props) => {
    return {
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        isOpened: selectors.isSectionOpened(state.minuteTakerReducer, props.sectionId),
        deletingSectionId: selectors.deletingSectionId(state.minuteTakerReducer),
        selectedSection: selectors.selectedSection(state.minuteTakerReducer),
        sections: selectors.minutesSections(state.minuteTakerReducer),
        isSavingSection: selectors.isSavingSection(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinuteEditorListItem)
