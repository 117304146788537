import React, { FC } from 'react'
import { AtlasStatusError, AtlasGreyDark } from 'assets/styles/variables'
import { FlexFill } from 'assets/sharedStyles'
import styled from '@emotion/styled'

type Props = {
    errorText?: any
    maxValue?: number
    value?: string
}

export const InputErrorText: FC<Props> = ({ errorText, maxValue, value }) => {
    const currentCount = value ? value.length : 0

    return (
        <Wrapper>
            <MessageText>{!!errorText && errorText}</MessageText>
            <FlexFill />
            {!!maxValue && (
                <CounterStyle maxReached={currentCount > maxValue}>
                    {`${currentCount}/${maxValue}`}
                </CounterStyle>
            )}
        </Wrapper>
    )
}

export const getAtlasHelperText: (args: Props) => JSX.Element | null = ({
    errorText,
    value,
    maxValue
}: Props) => {
    // This allows us to hide helperText componet by setting helperText to null
    const shouldRender = errorText !== null

    return shouldRender ? (
        <InputErrorText
            errorText={errorText || ''}
            value={!!maxValue ? value : undefined}
            maxValue={!!maxValue ? maxValue : undefined}
        />
    ) : null
}

const MessageText = styled('span')`
    color: ${AtlasStatusError};
    margin-left: 2px;
`
const Wrapper = styled('span')`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    font-size: 12px;
    line-height: 1.17;
`

const CounterStyle = styled('span')<{ maxReached: boolean }>`
    text-align: right;
    font-size: 12px;
    line-height: 1.17;
    color: ${({ maxReached }) => (maxReached ? AtlasStatusError : AtlasGreyDark)};
`
