import React from 'react'

//#region Components Material

//#endregion

//#region Components

import EditLabel from '../../../shared/EditLabel'
import i18n from 'i18next'
//#endregion

//#region Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as editorActions } from '../../../../reducers/actionEditorReducer'

//#endregion

//#region Props
type Props = {
    actions: {
        saveExistingAction: (...args: any[]) => void
    }

    rowIndex: number
    action: any
}

//#endregion

//#region Implementation
function InlineNotesTextEditor({ rowIndex, action, actions }: Props) {
    const saveNotesText = (action: any, newText: string | null) => {
        action.notes = newText === '' ? null : newText

        const newAction = Object.assign({}, action)
        actions.saveExistingAction(newAction)
    }

    return (
        <EditLabel
            maxLength={2000}
            emptyValuePlaceholder={i18n.t('INTERNAL_NOTE')}
            text={action.notes}
            onSave={(c) => saveNotesText(action, c)}
            id={`NotesEditor${rowIndex}}`}
        />
    )
}

//#endregion

//#region Export / Redux Bindings

const mapDispatchToProps = (dispatch) => {
    const { saveExistingAction } = editorActions

    return {
        actions: bindActionCreators(
            {
                saveExistingAction
            },
            dispatch
        )
    }
}

export default connect(null, mapDispatchToProps)(InlineNotesTextEditor)

//#endregion
