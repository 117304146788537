import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'

export type FieldRenderPropsType<T> = FieldRenderProps<any, HTMLElement> & T

type MakeAdapterProps<T> = {
    showErrorWhen?: boolean
} & FieldRenderPropsType<T>

type MakeAdapter = <T>(ComponentAdapter: FC<any>) => (props: MakeAdapterProps<T>) => JSX.Element
/**
 * final-form adapter for general MUI Component
 */
export const makeMuiFieldAdapter: MakeAdapter = (ComponentAdapter) => ({
    input,
    meta: { touched, error, submitError },
    showErrorWhen,
    ...rest
}) => {
    const showError = showErrorWhen === undefined ? !!touched : showErrorWhen
    const fieldError = !!error || !!submitError
    const helperText = !!showError && !!error ? error : undefined

    return (
        <ComponentAdapter
            error={!!showError && fieldError}
            helperText={helperText}
            {...input}
            {...rest}
        />
    )
}
