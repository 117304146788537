import { getIdentityToken } from 'businesslayer/api/identityToken'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { isChatBotEnable } from 'common/util/config'
import { UseChatTokenResponse } from 'components/minutetaker/components/InvitationView/types'
import { useCallback, useEffect, useState } from 'react'

// type Props = {}

export const useChatAiToken = (props: UseChatTokenResponse) => {
    // const [identityToken, setIdentityToken] = useState()
    const [tokenError, setTokenError] = useState<any>()
    const {
        identityToken,
        actions: { setIdentityToken }
    } = props
    const platform = transientStorageManager.platform || ''

    const isValidPlatform = isChatBotEnable(transientStorageManager?.ai_chat_bot, platform)

    const fetchIdentityToken = useCallback(async () => {
        try {
            const data = await getIdentityToken(getIdentityToken.bind(null))
            setIdentityToken(data)
        } catch (error) {
            setTokenError(error)
        }
    }, [setIdentityToken])

    useEffect(() => {
        if (!identityToken.connector_token && isValidPlatform) {
            fetchIdentityToken()
        }
    }, [fetchIdentityToken, identityToken.connector_token, isValidPlatform])

    return { identityToken, tokenError }
}
