import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'

type Props = {
    error: string
    style?: CSSProperties
    className?: string
}
const InlineError = (props: Props) => {
    if (!props.error) {
        return null
    }

    return <StyledError className={props.className}>{props.error}</StyledError>
}

const StyledError = styled('span')`
    color: red;
    font-size: 12px;
    line-height: 24px;
`

export default InlineError
