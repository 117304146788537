import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: ColorBaseGreyDark;
`

type Props = {
    title?: JSX.Element
    className?: string
    skeleton?: boolean
    fill?: string
}

const SendNotificationIcon = ({ title, skeleton, fill, ...props }: Props) =>
    skeleton ? (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.0303 0.969692C23.2014 1.14073 23.2725 1.37383 23.2438 1.59651C23.2343 1.6702 23.2139 1.74275 23.1825 1.81153L14.1894 22.7955C14.0784 23.0543 13.8318 23.2292 13.5508 23.2483C13.2698 23.2674 13.0018 23.1274 12.8569 22.8859L8.45335 15.5467L1.11413 11.1431C0.872624 10.9982 0.732657 10.7302 0.751728 10.4492C0.770799 10.1682 0.945693 9.92161 1.20457 9.81066L22.1885 0.817543C22.2566 0.786483 22.3284 0.766139 22.4013 0.756511C22.4509 0.749917 22.5009 0.748363 22.5504 0.751706C22.7252 0.763405 22.8967 0.836067 23.0303 0.969692ZM19.2159 3.72347L3.14366 10.6116L8.88375 14.0556L19.2159 3.72347ZM9.94441 15.1163L20.2766 4.78414L13.3885 20.8564L9.94441 15.1163Z"
                fill={fill ?? 'white'}
            />
        </svg>
    ) : (
        <Svg width="1em" height="1em" viewBox="0 0 20 20" aria-hidden="true" {...props}>
            <title>{title}</title>
            <path
                d="M13.09 7.343l-4.74 5.755 3.036 5.796a.967.967 0 0 0 1.774-.133l5.931-17.067A.926.926 0 0 0 17.915.518L.853 6.438A.97.97 0 0 0 .72 8.21l5.797 3.035 5.744-4.728a.584.584 0 0 1 .823.823l.006.002z"
                fillRule="evenodd"
            />
        </Svg>
    )

export default SendNotificationIcon
