import React, { useState } from 'react'
import { About } from 'components/modals'

export const useAboutModal = () => {
    const [showAboutDialog, setShowAboutDialog] = useState(false)

    const handleOpenAboutModal = (e) => {
        e.preventDefault()
        setShowAboutDialog(true)
    }
    const handleCloseAbout = () => {
        setShowAboutDialog(false)
    }

    const AboutModal = (
        <About key="aboutModal" isOpen={showAboutDialog} onClose={handleCloseAbout} />
    )
    return { AboutModal, handleOpenAboutModal }
}
