import React, { useState } from 'react'
import i18n from 'i18next'
import Chip from '@material-ui/core/Chip'

type Props = {
    className?: string
    name: string
    email: string | null
    readonly?: boolean
    onDelete?: (...args) => void
}

export const PersonChip = (props: Props) => {
    const [displayType, setDisplayType] = useState<DisplayType>('name')

    const chipEmail = !!props.email ? props.email : i18n.t('NO_EMAIL_PERSON_LABEL')
    const label = displayType === 'name' ? props.name : chipEmail

    const handleClick = () => {
        displayType === 'name' ? setDisplayType('email') : setDisplayType('name')
    }
    return props.readonly ? (
        <Chip label={label} onClick={handleClick} />
    ) : (
        <Chip
            className={props.className}
            label={label}
            onClick={handleClick}
            onDelete={props.onDelete}
            data-testid="person_chip"
        />
    )
}

type DisplayType = 'name' | 'email'

export default PersonChip
