import React, { FC, useState, useMemo } from 'react'

import i18n from 'i18next'
import { AtlasModal } from 'components/atlas-components/atlas-modal/atlas-modal'
import { AtlasButtonOld } from 'components/atlas-material/button'
import {
    getUpdatedMinutesItem,
    initializeFormValues,
    getMinutesMetaData
} from './minutes-properties-form-functions'
import { DialogContent, Button } from '@material-ui/core'
import { css, ClassNames } from '@emotion/react'
import { EditMode } from './use-minutes-properties-modal'
import { MinutesPropertiesForm, submitMinutesPropertiesForm } from './minutes-properties-form'
import { AtlasActionPrimary, ColorBaseSkyBlue } from 'assets/styles/variables'
import blockingOperation from 'businesslayer/blockingOperation'
import { useEditModal } from 'businesslayer/networkrequests/minutes/useEditModal'
import { MINUTES_EDIT_OPTIONS } from 'components/minutetaker/components/InvitationView/types'
import LinearIndeterminate from 'components/shared/LinearProgress'

type Props = {
    isOpen: boolean
    editMode: EditMode
    minutesItem: Partial<MinutesUnnormalized> | undefined
    title: string
    onCloseMinutesEditor: () => void
    onSaveMinutesItem: ({ updatedMinutesItem }) => void
}

export const MinutesPropertiesModal: FC<Props> = ({
    minutesItem,
    isOpen,
    onCloseMinutesEditor,
    onSaveMinutesItem,
    title,
    editMode
}) => {
    const [canSaveMinutes, setCanSaveMinutes] = useState(false)
    const { editModalData, editMinutesDetails } = useEditModal(
        minutesItem?.id,
        minutesItem?.committee_id || minutesItem?.committee?.id,
        editMode
    )
    let obj = { ...minutesItem }
    if (editModalData.committeeId) {
        obj = {
            ...minutesItem,
            ...editModalData
        }
    }

    const initialFormValues = useMemo(
        () =>
            initializeFormValues(
                { ...minutesItem, ...editModalData, editoMode: editMode },
                editMode
            ),
        [minutesItem, editMode, editModalData]
    )

    const handleClickSave = () => {
        if (canSaveMinutes) {
            // Submit logic for final-form
            submitMinutesPropertiesForm()
        }
    }
    const handleSave = (values) => {
        blockingOperation.showProgress(null, true)
        if (!canSaveMinutes) return
        const updatedMinutesItem = getUpdatedMinutesItem(minutesItem, values)
        onSaveMinutesItem({ updatedMinutesItem })
        return false
    }

    const renderModalActionButtons = (
        okButtonLabel: string,
        saveButtonDisabled: boolean
    ): Array<any> => {
        const cancelButton = (
            <ClassNames key={'DialogActionCancel'}>
                {({ css }) => (
                    <Button
                        key={'DialogActionCancel'}
                        className={css`
                            ${btnCancel}
                        `}
                        onClick={onCloseMinutesEditor}
                        disableFocusRipple
                        disableRipple
                        data-testid="MinutesManagerModal_CancelBtn">
                        {i18n.t('CANCEL')}
                    </Button>
                )}
            </ClassNames>
        )

        const okButton = (
            <ClassNames key={'DialogActionSave'}>
                {({ css }) => (
                    <AtlasButtonOld
                        key={'DialogActionSave'}
                        disabled={saveButtonDisabled}
                        onClick={handleClickSave}
                        className={css`
                            ${btnOk}
                        `}
                        dataTestId="MinutesManagerModal_SaveBtn">
                        {okButtonLabel}
                    </AtlasButtonOld>
                )}
            </ClassNames>
        )
        return [cancelButton, okButton]
    }

    const okButtonLabel = getOkButtonLabel(editMode)
    const dialogActions = renderModalActionButtons(okButtonLabel, !canSaveMinutes)

    const minutesMetaData =
        !!minutesItem && editMode === MINUTES_EDIT_OPTIONS.EDIT ? getMinutesMetaData(obj) : []

    return (
        <AtlasModal
            open={isOpen}
            modalTitle={title}
            maxWidth="md"
            onClose={onCloseMinutesEditor}
            data-testid="MinutesManagerModal"
            PaperProps={{
                ref: (elem: Element) => {
                    !!elem && elem.setAttribute('data-testid', 'MinutesManagerModal_MUIPaper')
                }
            }}
            actions={dialogActions}>
                <LinearIndeterminate loading={editMinutesDetails}/>
            <DialogContent>
                <MinutesPropertiesForm
                    minutesMetaData={minutesMetaData}
                    onValidationChange={setCanSaveMinutes}
                    initialFormValues={initialFormValues}
                    editMode={editMode}
                    onSave={handleSave}
                />
            </DialogContent>
        </AtlasModal>
    )
}

const getOkButtonLabel = (editMode: EditMode): string => {
    if (editMode === 'CREATE') return i18n.t('CREATE')
    if (editMode === 'EDIT') return i18n.t('SAVE')
    if (editMode === 'DUPLICATE') return i18n.t('DUPLICATE')

    return i18n.t('SAVE')
}

const btnCancel = css`
    color: ${AtlasActionPrimary};
    height: 40px;
    span {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
    }
    :focus {
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
`
export const btnOk = css`
    height: 40px;
    span {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
    }
    :focus {
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
`
