import { ColorBaseBlueLight, ColorBaseGreyDark } from 'assets/styles/variables'
import { createMuiTheme } from '@material-ui/core'
import { MuiMenuItem } from 'material-atlas/overrides/mui-menu-item'

export const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: ColorBaseBlueLight,
            main: ColorBaseBlueLight,
            dark: ColorBaseBlueLight
        }
    },
    typography: {
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',')
    },
    overrides: {
        MuiInput: {
            underline: {
                '&&&&:hover:before': {
                    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`
                }
            }
        },
        MuiInputLabel: {
            root: {
                color: ColorBaseGreyDark,
                '&$focused': {
                    color: ColorBaseGreyDark
                }
            },
            shrink: {
                '&$focused': {
                    color: ColorBaseGreyDark
                }
            }
        },
        MuiMenuItem: MuiMenuItem
    }
})
