import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { all, keys } from 'rambdax'

/**
 * Debounce a function by {delay} milliseconds
 * @param fn
 * @param delay
 */
export const debounce = (fn: Function, delay: number) => {
    let timer: any = null
    return function (...args) {
        const context = this
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, delay)
    }
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const remove_non_ascii = (str) => {
    if (str === null || str === '') return false

    const asString = str.toString()

    // Remove all non ascii chars in the string
    return asString.replace(/[^\x20-\x7E]/g, '')
}

export const refit_keys = (obj, changeFn, shallow = false) => {
    const build = {}
    for (const key in obj) {
        // Get the destination key
        const destKey = changeFn(key) || key

        // Get the value
        const value = obj[key]
        const isValueArray = Array.isArray(value)
        const isValueObject = typeof value === 'object'

        const refited_value =
            isValueArray && !shallow
                ? value.map((i) => refit_keys(i, changeFn))
                : isValueObject && !shallow
                ? refit_keys(value, changeFn)
                : value

        // Set it on the result using the destination key
        build[destKey] = refited_value
    }
    return build
}

export const snake_to_camel_case = (string: string) => {
    const REGEX = /(_[a-zA-Z])/g
    return string.replace(REGEX, (match) => match[1].toUpperCase())
}

export const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max))
}

export const getRandomHexString = (max: number) => {
    return getRandomInt(max).toString(16)
}

export const allObjectValues = <T = any>(fn: (value: T) => boolean, obj: Record<string, T>) => {
    const withValue = (obj_key) => fn(obj[obj_key])
    return all(withValue, keys(obj))
}

export const addKeyPressHandler = (event: React.KeyboardEvent<any>) =>
    event.which === 13 || event.which === 32

export const handleLinkFocus = (e: React.FocusEvent<HTMLAnchorElement>) => {
    e.target.style.clip = 'auto' /* unclip the link to show it when it receives focus */
}

export const handleLinkBlur = (e: React.FocusEvent<HTMLAnchorElement>) => {
    e.target.style.clip = 'rect(0 0 0 0)' /* clip the link again to hide it when it loses focus */
}

export const ContentContainerhandleFocus = () => {
    const contentBody = document.getElementById('ContentContainer_ContentBody') as HTMLElement
    if (contentBody) {
        contentBody.tabIndex = 0
        contentBody.focus()
    }
}

export const pendoTrack = (eventName, data) => {
    if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
        return window.pendo.track(eventName, data)
    }
    setTimeout(function () {
        pendoTrack(eventName, data)
    }, 500)
}

export const rounded = (count) => {
    return Math.round(count / 100) * 100
}

function getElementDefaultDisplay(tag) {
    var cStyle,
        t = document.createElement(tag),
        gcs = 'getComputedStyle' in window

    document.body.appendChild(t)
    cStyle = (gcs ? window.getComputedStyle(t, '') : t.currentStyle).display
    document.body.removeChild(t)

    return cStyle
}

export function removeTags(htmlString) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const body = doc.body

    let strippedText = ''

    function processNode(node) {
        const nodeType = node.nodeType

        if (nodeType === Node.TEXT_NODE) {
            strippedText += node.textContent
        } else if (nodeType === Node.ELEMENT_NODE) {
            const tagName = node.tagName.toLowerCase()

            if (getElementDefaultDisplay(tagName) === 'inline') {
                for (let i = 0; i < node.childNodes.length; i++) {
                    processNode(node.childNodes[i])
                }
            } else {
                strippedText += ' '
                for (let i = 0; i < node.childNodes.length; i++) {
                    processNode(node.childNodes[i])
                }
                strippedText += ' '
            }
        }
    }

    processNode(body)

    return strippedText.replace(/\s+/g, ' ').trim()
}

/**
 * Initialize Pendo
 * This function initializes the Pendo analytics tool, but only for specific platforms.
 * The function checks if the current platform is "board" or "boardeffect", and only initializes Pendo for these platforms.
 * If platform is board then enable tracting based on CSP settings.
 * An empty visitor ID is used to generate a unique ID in the Pendo dashboard.
 */

export const initializePendo = (settings, language) => {
    const {
        currentUser: { platform, site_name },
        pendoAnalytics: pendo_analytics,
        accountId,
        userLanguage: lang
    } = settings

    let region = settings?.region || 'None'
    let subRegion = settings?.subRegion || 'None'
    let superRegion = settings?.superRegion || 'None'
    let blcTier = settings?.blcTier || 'None'

    if (platform === 'boardeffect') {
        ;[region, subRegion, superRegion, blcTier] = [...Array(4).fill('None')]
    }
    //add mock platform for enable pendo in local
    if (!['boards', 'boardeffect'].includes(platform)) {
        return
    }

    const platformDetails = {
        boards: getSessionStorageItem('parentPlatform') || '',
        boardeffect: 'boardeffect',
        mock: 'mock'
    }

    const getPlatformDetails = (platform) => platformDetails[platform]
    const getId = () => {
        const idMapping = {
            boardswebdirector: 'board-web-director',
            boardswebadmin: accountId,
            boardeffect: site_name,
            mock: 'mock'
        }
        return idMapping[getPlatformDetails(platform)]
    }

    const shouldInitialize = () => {
        const platformDetails = getPlatformDetails(platform)

        if (!platformDetails && !getId()) {
            return false
        }
        return (
            (pendo_analytics?.enable_admin && pendo_analytics?.enable_director) ||
            platformDetails === 'boardeffect' ||
            (platformDetails === 'boardswebadmin' && pendo_analytics?.enable_admin) ||
            (platformDetails === 'boardswebdirector' && pendo_analytics?.enable_director) ||
            platformDetails === 'mock'
        )
    }

    if (shouldInitialize()) {
        const payload = () => {
            const pendoObj: PendoInitalize = {
                visitor: { id: '', lang, language },
                account: {
                    id: getId(),
                    platform: getPlatformDetails(platform),
                    region,
                    superRegion,
                    subRegion,
                    blcTier
                },
                excludeAllText: true
            }

            if (getPlatformDetails(platform) === 'boardswebdirector') {
                const account = Object.assign({}, pendoObj.account)
                delete account.id

                const directorMetaData = {
                    visitor: {
                        ...pendoObj.visitor,
                        ...account
                    },
                    account: {
                        id: getId()
                    },
                    excludeAllText: true
                }
                return directorMetaData
            }
            return pendoObj
        }

        window.pendo.isInitialized = shouldInitialize()
        window.pendo.metaData = payload()
        window.pendo.initialize(payload())
    }
}

/**
 * Update pendo metadata
 * if metadata need to update in visitor then pass isVisitior as true
 */
export const updatePendoMetadata = (value, isVisitior: boolean) => {
    if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
        const { visitor, account } = window.pendo.metaData
        const updatedData = isVisitior ? { ...visitor, ...value } : { ...account, ...value }
        const updateOption = isVisitior
            ? { ...window.pendo.metaData, visitor: updatedData }
            : { ...window.pendo.metaData, account: updatedData }
        window.pendo.metaData = updateOption
        window.pendo.updateOptions(updateOption)
    } else {
        console.warn('Pendo is not initialized')
    }
}

export interface PendoInitalize {
    visitor: {
        id: string
        lang: string
        language: string
        platform?: string
        region?: string
        superRegion?: string
        subRegion?: string
        blcTier?: string
    }
    account: {
        id?: string
        platform?: string
        role?: string
        region?: string
        superRegion?: string
        subRegion?: string
        blcTier?: string
    }
    excludeAllText: boolean
}

export interface LengthStats {
    avgCharCount: number
    avgWordCount: number
    minCharCount: number
    minWordCount: number
    maxCharCount: number
    maxWordCount: number
}

export function calculateLength(minutesSections) {
    let charCount = 0
    let secCount = 0
    let wordCount = 0
    let charLen: Record<string, number> = {}
    let wordLen: Record<string, number> = {}
    const sectionLists = minutesSections?.minutesSections

    for (let k in sectionLists) {
        const section = sectionLists[k]

        if (
            section.attributes.sectionType === 'minutes' &&
            section?.attributes?.htmlBody !== null
        ) {
            secCount++
            const htmlStr = section.attributes.htmlBody
            const cleanedString = removeTags(htmlStr)
            const newStr = htmlStr
                ?.replace(/(<([^>]+)>)/gi, '')
                .split('&nbsp;')
                .join('')

            if (cleanedString.length > 0) {
                wordCount = wordCount + cleanedString.split(' ').length

                charCount = charCount + newStr.length
                charLen = { ...charLen, [k]: newStr.length }
                wordLen = { ...wordLen, [k]: cleanedString.split(' ').length }
            } else {
                charLen = { ...charLen, [k]: newStr.length }
                wordLen = { ...wordLen, [k]: cleanedString.length }
            }
        }
    }
    const avgCharCount = Math.ceil(charCount / secCount)
    const avgWordCount = Math.ceil(wordCount / secCount)

    const charValues = Object.values(charLen)
    const wordValues = Object.values(wordLen)

    const minCharCount = Math.min(...charValues)
    const minWordCount = Math.min(...wordValues)

    const maxCharCount = Math.max(...charValues)
    const maxWordCount = Math.max(...wordValues)

    return {
        avgCharCount,
        avgWordCount,
        minCharCount,
        minWordCount,
        maxCharCount,
        maxWordCount
    }
}
export const calculateRestrictedPendoValues = (
    reviewersList: PersonChip[],
    payload: any,
    totalNumberOfSections: number
) => {
    const isNewReview = reviewersList.length === 0
    const restrictedReviewers: PersonChip[] = payload.recipients.filter((it) => !it.is_general)
    const restrictedSecLength: number[] = restrictedReviewers.map((it) => it.section_ids.length - 1)
    const maximumSectionsPermitted =
        restrictedSecLength.length > 0 ? Math.max(...restrictedSecLength) : 0
    const restrictedPendoObj = {
        isNewReview: isNewReview,
        generalReviewersCount: payload.recipients.length - restrictedReviewers.length,
        restrictedReviewersCount: restrictedReviewers.length,
        totalNumberOfSections: totalNumberOfSections - 1,
        maximumSectionsPermitted: maximumSectionsPermitted
    }
    return restrictedPendoObj
}
/**
 * @description Filters an array of meeting objects to include only those meetings that fall within a specified date range.
 * @param {string} startDateStr - The start date of the date range in string format.
 * @param {string} endDateStr - The end date of the date range in string format.
 * @param {MinutesUnnormalized[]} datesArray - An array of meeting objects, each containing an array of meeting dates.
 * @returns {MinutesUnnormalized[]} An array of meeting objects that have at least one meeting date within the specified range.
 */

export function filterDatesBetween(
    startDateStr: string,
    endDateStr: string,
    datesArray: MinutesUnnormalized[]
): MinutesUnnormalized[] {
    const filteredMeetings: MinutesUnnormalized[] = []
    const startDate = new Date(startDateStr)
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date(endDateStr)
    endDate.setHours(23, 59, 59, 999)

    for (const meeting of datesArray) {
        for (const meetingDate of meeting.meeting_dates) {
            const startDateString = meetingDate.start_date || ''
            const currentDate = new Date(startDateString)
            if (currentDate >= startDate && currentDate <= endDate) {
                filteredMeetings.push(meeting)
                break
            }
        }
    }

    return filteredMeetings
}
