import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { getMeetingDate } from 'components/minutes-card/minutes-card-functions'
import i18n from 'i18next'

type Props = {
    meetingLists: MinutesUnnormalized
}

export type MinutesUnnormalized = {
    title: string
    meeting_dates: { start_date: string }[]
}

const NO_DUE_DATE = i18n.t('NO_DUE_DATE')

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: 'flex-start',
        gap: '8px',
        flex: '1 0 0'
    },
    committeeDetails: {
        color: theme.palette.primary.main,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '18px',
        backgroundColor: theme.palette.background.paper,
        wordBreak: 'break-word'
    },
    dateRange: {
        color: theme.palette.primary.main,
        fontStyle: 'normal',
        paddingBottom: '8px',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '16px'
    }
}))

const MeetingsLists: FC<Props> = ({ meetingLists }) => {
    const classes = useStyles()
    const hasMeetingDates = meetingLists.meeting_dates.length > 0

    if (!hasMeetingDates) {
        return null
    }

    return (
        <Box className={classes.root}>
            <Typography className={classes.committeeDetails}>{meetingLists.title}</Typography>
            <Typography className={classes.dateRange}>
                {getMeetingDate(meetingLists, NO_DUE_DATE)}
            </Typography>
        </Box>
    )
}

export default MeetingsLists
