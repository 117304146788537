import { saveNewReport } from 'businesslayer/api/minutes'
import { useCallback, useState } from 'react'

export const useCreateReport = ({ storeCreateReportResponse, setCreateReportLoading }) => {
    const [errorMessage, setErrorMessage] = useState()

    const handleCreateReport = useCallback(
        async (newReportData) => {
            setCreateReportLoading(true)
            try {
                const response = await saveNewReport(
                    newReportData,

                    saveNewReport.bind(null, newReportData)
                )
                if (response) {
                    //storing the response in redux store
                    storeCreateReportResponse(response)
                    setCreateReportLoading(false)
                }
            } catch (err) {
                setErrorMessage(err)
            }
        },
        [storeCreateReportResponse, setCreateReportLoading]
    )

    return {
        handleCreateReport,
        errorMessage
    }
}
