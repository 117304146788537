import blockingOperation from './blockingOperation'

type PromiseFn = Promise<any>
type Options = {
    repeatAction?: any
    isForeground?: boolean
}

export const executeRequest = async (
    request: PromiseFn,
    onSuccess?,
    onFail?,
    options: Options = {}
) => {
    const { repeatAction, isForeground = true } = options

    if (isForeground) {
        blockingOperation.start(repeatAction)
    }

    try {
        const result = await request

        return handleSuccess({ result, isForeground, onSuccess, repeatAction })
    } catch (error) {
        handleError({ error, isForeground, repeatAction, onFail })
    }
}

const handleSuccess = ({ isForeground, repeatAction, onSuccess, result }) => {
    if (onSuccess) {
        onSuccess(result)
    }

    if (isForeground) blockingOperation.stop(repeatAction)

    return result
}

const handleError = ({ isForeground, repeatAction, onFail, error }) => {
    const is401Error = error.response && error.response.status === 401
    const lastError = is401Error ? null : onFail ? onFail(error) : error.message

    if (isForeground) blockingOperation.stop(repeatAction, lastError)

    throw error
}
