import React from 'react'

import MaterialCircularProgress from '@material-ui/core/CircularProgress'
import MaterialFormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialButton from '@material-ui/core/Button'
import MaterialIconButton from '@material-ui/core/IconButton'
import MaterialDialog from '@material-ui/core/Dialog'
import MaterialDialogActions from '@material-ui/core/DialogActions'
import MaterialDialogContent from '@material-ui/core/DialogContent'
import MaterialDialogTitle from '@material-ui/core/DialogTitle'
import MaterialTextField, { TextFieldProps } from '@material-ui/core/TextField'
import MaterialMenu from '@material-ui/core/Menu'
import MaterialCheckbox from '@material-ui/core/Checkbox'
import MaterialChip from '@material-ui/core/Chip'
import MaterialRadio from '@material-ui/core/Radio'
import MaterialRadioGroup from '@material-ui/core/RadioGroup'
import MaterialList from '@material-ui/core/List'
import MaterialExpansionPanel from '@material-ui/core/ExpansionPanel'
import MaterialExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MaterialExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import MaterialDivider from '@material-ui/core/Divider'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import MaterialRootRef from '@material-ui/core/RootRef'
import { withStyles } from '@material-ui/core/styles'

import { DatePicker as MuiDatePicker } from 'material-ui-pickers'

import {
    ColorBaseBlueMedium,
    ColorBaseBlueLight,
    ColorBaseGreyLight,
    ColorBaseGreyDark,
    ColorBaseBlueGreyMedium,
    ColorBaseWhite,
    ColorBaseBlueGreyLightest,
    ColorBaseRed,
    ColorBaseBlueGreyDark,
    FontWeightLightBold,
    ColorBaseRichBlack,
    FontWeightRegular,
    AtlasBlack,
    ColorBaseDarkGreen,
    AtlasGreyMedium,
    ColorBaseMulberryBlue,
    ColorEastBay
} from 'assets/styles/variables'
import { atlasFontFamily } from 'components/atlas-components/typography'

/* * * Root Ref * * */

export const RootRef = MaterialRootRef

/* * * Circular Progress * * */

export const CircularProgress = MaterialCircularProgress

/* * * Chips * * */
const chipStyles: any = {
    root: {
        backgroundColor: ColorBaseGreyLight,
        display: 'flex',
        height: '25px',
        float: 'left',
        '&:hover': {
            backgroundColor: ColorBaseGreyLight
        },
        alignItems: 'center',
        '&.invalid': {
            backgroundColor: '#f8c2c2',
            '& svg': {
                fill: ColorBaseRed
            }
        }
    },
    label: {
        float: 'left',
        paddingLeft: '12px',
        paddingRight: '12px',
        fontSize: '12px',
        minHeight: '16px',

        // truncate chip
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '335px'
    },
    deleteIcon: {
        margin: '0px 2px 0px 4px',
        height: '20px'
    }
}

const selectChipStyles: any = {
    ...chipStyles,
    root: {
        ...chipStyles.root,
        margin: '10px 20px 5px 0px'
    }
}

export const SelectChip = withStyles(selectChipStyles)(MaterialChip)

/* * * Dialog * * */

const StyledDialog = (props: any) => {
    return (
        <MaterialDialog
            maxWidth={!!props.maxWidth ? props.maxWidth : 'md'}
            fullWidth={true}
            disableBackdropClick
            {...props}
        />
    )
}

export const Dialog = StyledDialog

export const DialogTitle = withStyles({
    root: {
        display: 'flex',
        backgroundColor: ColorBaseBlueMedium,
        minHeight: '55px',
        alignItems: 'center',
        '& h2': {
            color: 'white'
        },
        '&.center': {
            justifyContent: 'center'
        }
    }
})(MaterialDialogTitle)

export const NewDialogTitle = withStyles({
    root: {
        display: 'flex',
        backgroundColor: ColorBaseWhite,
        minHeight: '72px',
        borderBottom: '2px solid #e6e6e6',
        borderTop: '1px solid transparent',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        fontFamily: 'Source Sans Pro',
        '& h2': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'center'
        },
        '& div': {
            color: AtlasBlack,
            fontFamily: atlasFontFamily,
            fontStyle: 'normal',
            fontWeight: FontWeightLightBold,
            fontSize: '22px',
            lineHeight: '26px'
        },
        '&.center': {
            justifyContent: 'center'
        }
    }
})(MaterialDialogTitle)

export const NewDialogContent = withStyles({
    root: {
        borderBottom: '2px solid #e6e6e6',
        Width: '100%',
        padding: '0px 24px 24px 24px',
        overflowY: 'auto'
    }
})(MaterialDialogContent)

export const DialogContent = withStyles({
    root: {
        borderBottom: `2px solid #ebebeb`,
        Width: '100%',
        marginTop: '3px',
        overflowY: 'auto'
    }
})(MaterialDialogContent)

export const DialogActions = withStyles({
    root: {
        height: '72px',
        padding: '1.5rem'
    }
})(MaterialDialogActions)

/* * * Buttons * * */
const buttonStyles = {
    root: {
        color: ColorBaseGreyDark,
        '&:disabled': {
            color: '#767676'
        },
        '&.primary': {
            color: ColorBaseBlueLight
        }
    }
}

const actionButtoStyles: any = {
    ...buttonStyles,
    root: {
        ...buttonStyles.root,
        marginRight: '10px',
        marginBottom: '0',
        color: ColorBaseBlueLight,
        '&.delete': {
            color: ColorBaseRed
        },
        '&.left': {
            position: 'absolute',
            left: '22px',
            paddingLeft: '15px'
        }
    }
}

export const AddActionButtonStyle: any = {
    root: {
        height: '40px',
        padding: '8px 12px',
        border: 'none',
        background: ColorBaseWhite,
        color: ColorEastBay,
        textTransform: 'none',
        '& span': {
            fontFamily: atlasFontFamily,
            fontStyle: 'normal',
            fontWeight: FontWeightLightBold,
            fontSize: '16px',
            lineHeight: '20px'
        },
        '&:hover': {
            backgroundColor: '#e6e6e6',
            borderRadius: '2px solid transparent'
        },
        '&:focus': {
            outline: 'none',
            boxShadow: `0 0 0 1px ${ColorBaseWhite}, 0 0 0 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    }
}

const iconButtonStyles: any = {
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}

const utilityIconButtonStyles: any = {
    root: {
        padding: 0,
        height: 56,
        width: 56,

        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}

const flatButtonStyles: any = {
    root: {
        ...buttonStyles.root,
        color: ColorBaseBlueGreyMedium,
        fontWeight: '500',
        textTransform: 'capitalize'
    }
}

const sendInvitationButtonStyles: any = {
    root: {
        color: ColorBaseBlueLight,
        fontWeight: '500',
        textTransform: 'capitalize'
    }
}

const popoverButtonStyles: any = {
    ...buttonStyles,
    root: {
        ...buttonStyles.root,
        textTransform: 'none'
    }
}

/* * *Spinner Circular Progress * * */

export const circularLoaderStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative'
        },
        bottom: {
            color: '#D7DDE6'
        },
        top: {
            color: '#37465B',
            animationDuration: '1s',
            position: 'absolute',
            left: 0
        },
        circle: {
            strokeLinecap: 'square'
        }
    })
)

export const Button = withStyles(buttonStyles)(MaterialButton)
export const ActionButton = withStyles(actionButtoStyles)(MaterialButton)
export const AddActionEditActionButton = withStyles(AddActionButtonStyle)(MaterialButton)
export const IconButton = withStyles(iconButtonStyles)(MaterialIconButton)

export const UtilityIconButton = withStyles(utilityIconButtonStyles)(MaterialIconButton)
export const FlatButton = withStyles(flatButtonStyles)(MaterialButton)
export const SendInvitationButton = withStyles(sendInvitationButtonStyles)(MaterialButton)
export const PopoverButton = withStyles(popoverButtonStyles)(MaterialButton)

/* * * Fields * * */

const textFieldStyles = {
    root: {
        width: '100%',
        color: ColorBaseBlueGreyMedium,
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #1E1E1E',
            boxShadow: `0px 0px 0px 2px ${ColorBaseMulberryBlue}`
        }
    },
    inputLabelProps: {
        '&$focused': {
            color: ColorBaseGreyDark,
            backgroundColor: ColorBaseWhite
        }
    },
    inputProps: {
        color: ColorBaseBlueGreyMedium,
        '&:focus': {
            backgroundColor: '#fff'
        }
    },
    focused: {}
}

const StyledMaterialTextField = (props: { classes: any } & TextFieldProps) => (
    <MaterialTextField
        {...props}
        classes={{
            root: props.classes.root
        }}
        InputLabelProps={{
            classes: {
                root: props.classes.inputLabelProps,
                focused: props.classes.focused
            },
            shrink: false
        }}
        inputProps={{
            className: props.classes.inputProps
        }}
        InputProps={{ classes: { root: props.classes.root } }}
    />
)

export const TextField = withStyles(textFieldStyles)(StyledMaterialTextField)

/* * * New Dialog Fields * * */

const newTextFieldStyles = {
    root: {
        width: '100%',
        color: ColorBaseRichBlack,
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${AtlasGreyMedium}`
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #1E1E1E',
            padding: '10px 16px',
            boxShadow: `0px 0px 0px 2px ${ColorBaseMulberryBlue}`
        },
        '& .MuiInputBase-input': {
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: FontWeightRegular,
            fontSize: '16px',
            lineHeight: '20px'
        },
        '& .MuiOutlinedInput-multiline': {
            padding: '10px 16px'
        },
        '&.Mui-error': {
            borderColor: '#af292e'
        }
    },

    inputProps: {
        color: ColorBaseRichBlack,
        '&:focus': {
            backgroundColor: '#fff'
        }
    },
    focused: {}
}

const NewStyledMaterialTextField = (
    props: { classes: any; maxLength?: number } & TextFieldProps
) => (
    <MaterialTextField
        {...props}
        variant="outlined"
        classes={{
            root: props.classes.root
        }}
        InputLabelProps={{
            classes: {
                root: props.classes.inputLabelProps,
                focused: props.classes.focused
            }
        }}
        inputProps={{
            maxLength: props.maxLength,
            className: props.classes.inputProps
        }}
        InputProps={{ classes: { root: props.classes.root } }}
    />
)

export const NewDialogTextField = withStyles(newTextFieldStyles)(NewStyledMaterialTextField)

/* * * List * * */

const listStyles: any = {
    root: {
        color: ColorBaseBlueGreyDark,
        padding: 0,
        height: '100%',
        overflowY: 'auto',
        overflowX: 'auto',
        maxWidth: '100vw'
    }
}

export const List = withStyles(listStyles)(MaterialList)

/* * * Menus * * */

const menuStyles: any = {
    paper: {
        minWidth: '160px'
    }
}

export const Menu = withStyles(menuStyles)(MaterialMenu)

/* * * Form Controls * * */

const formControlStyles = {
    root: {
        width: '100%'
    },
    label: {
        width: '100%',
        fontSize: '16px',
        color: ColorBaseBlueGreyMedium,
        '&:disabled': {
            color: ColorBaseGreyDark
        }
    },
    disabled: {}
}

export const FormControlLabel = withStyles(formControlStyles)(MaterialFormControlLabel)

/* * * Selection Controls * * */

const checkboxStyles = {
    root: {
        color: 'var(--background-top)',
        width: 'auto',
        paddingRight: '5px',
        '&$checked': {
            color: 'var(--background-top)'
        },
        '&$disabled': {
            color: ColorBaseBlueGreyLightest
        },
        '& input:focus ~ .MuiSvgIcon-root': {
            boxShadow: '0px 0px 0px 3px #3E95FA'
        }
    },
    checked: {},
    disabled: {}
}

export const Checkbox = withStyles(checkboxStyles)(MaterialCheckbox)

const radioStyles = {
    root: {
        '&$checked': {
            color: ColorBaseDarkGreen
        },
        width: '24px',
        height: '24px',
        margin: '0.5rem',
        '&.Mui-focusVisible, &:active': {
            boxShadow: '0px 0px 0px 3px #0774EE, 0px 0px 0px 1px #FFF',
            borderRadius: '4px',
            background: 'rgba(255, 255, 255, 0.00)'
        }
    },
    checked: {}
}

export const RadioButton = withStyles(radioStyles)(MaterialRadio)

export const RadioButtonGroup = MaterialRadioGroup

/* * * Pickers * * */
// TODO: Picker and Mui seem to be out of date; causes Console Error
export const DatePicker = withStyles({
    root: {
        width: '100%',
        color: ColorBaseGreyDark,
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #1E1E1E',
            boxShadow: `0px 0px 0px 2px ${ColorBaseMulberryBlue}`
        },
        '& .MuiInputBase-input': {
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: FontWeightRegular,
            fontSize: '16px',
            lineHeight: '20px',
            color: '#676767'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px'
        },
        '& .MuiIconButton-root': {
            padding: '3px',
            '&:focus': {
                outline: 'none',
                border: '1px solid #1E1E1E',
                boxShadow: `0px 0px 0px 1px ${ColorBaseMulberryBlue}`,
                borderRadius: '1px'
            },
            '&:hover': {
                background: '#ffffff'
            }
        }
    }
})(MuiDatePicker)

/* * * Divider * * */
export const Divider = MaterialDivider

const expansionSummaryStyles = {
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        paddingLeft: '25px'
    }
}

const expansionDetailsStyles = {
    root: {
        width: '100%'
    }
}

export const ExpansionPanel = MaterialExpansionPanel
export const ExpansionPanelSummary = withStyles(expansionSummaryStyles)(
    MaterialExpansionPanelSummary
)
export const ExpansionPanelDetails = withStyles(expansionDetailsStyles)(
    MaterialExpansionPanelDetails
)
