import fileSaver from 'file-saver'

export const saveFile = ({
    response,
    responseType
}: {
    response: Response
    responseType: string | null
}) => {
    const normalizedType = responseType || 'docx'
    const baseName =
        normalizedType === 'docx'
            ? 'MinutesExport'
            : response.url && response.url.includes('export_attendance_report')
            ? 'AttendanceReport'
            : 'Actions'
    const filename = `${baseName}.${normalizedType}`

    //Read only once
    if (response.body && response.body.locked) {
        return
    }

    response.blob().then((blob) => {
        fileSaver.saveAs(blob, filename)
    })
}
