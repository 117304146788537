import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'
import { EllipseString } from 'assets/sharedStyles'
import { v4 as uuid } from 'uuid'

export type MetaDataItemType = { key: string; value: any }
export type MetaDataList = MetaDataItemType[]
export type MetaDataGroupedList = MetaDataItemType[][]

const SECTION_MARGIN = 10

type Props = {
    metaData: MetaDataList | MetaDataGroupedList
    columns: number
    maxWidth: number
    labelClass?: string
    valueClass?: string
}
/**
 * MetaDataColumnList shows key value pairs of data in
 * a given amount of columns that will start to wrap and collapse
 * when smaller than a given maxWidth
 */
export const MetaDataColumnList: FC<Props> = ({
    metaData,
    columns,
    maxWidth,
    labelClass = 'text-xsmall-emphasis',
    valueClass = ''
}) => {
    const metaDataList = useMemo(() => {
        // This allows us to make the list composable or just use
        // a default component/style for the meta data
        const dataItem = ({ item, key }) => (
            <MetaDataItem key={key} item={item} labelClass={labelClass} valueClass={valueClass} />
        )
        return renderMetaData(metaData, dataItem)
    }, [metaData, labelClass, valueClass])

    const sectionWidth = calculateSectionWidth(maxWidth, columns, SECTION_MARGIN)
    return (
        <ListWrapper className="text-xsmall" sectionWidth={sectionWidth} maxWidth={maxWidth}>
            {metaDataList}
        </ListWrapper>
    )
}

const renderMetaData = (metaData, dataItem) => {
    if (!metaData) return []

    return metaData.map((item) => {
        const key = uuid()
        const itemGroup = Array.isArray(item)
            ? item.map((groupedItem) => {
                  const sub_key = uuid()
                  return dataItem({ item: groupedItem, key: sub_key })
              })
            : [dataItem({ item, key })]
        return <MetaDataSection key={key}>{itemGroup}</MetaDataSection>
    })
}

const calculateSectionWidth = (maxWidth, columns, section_margin) => {
    return maxWidth / columns - columns * section_margin
}

const ListWrapper = styled.div<{ sectionWidth: number; maxWidth: number }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-transform: none;
    max-width: ${({ maxWidth }) => maxWidth}px;

    > div {
        /* three columns */
        width: ${({ sectionWidth }) => sectionWidth}px;
    }
`
const MetaDataSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: ${SECTION_MARGIN}px;
    overflow: hidden;
`

export const MetaDataItem = ({ item, labelClass = 'text-xsmall-emphasis', valueClass = '' }) => {
    return (
        <DataItemWrapper>
            <div className={labelClass}>{`${item.key}:`}&nbsp;</div>
            <EllipseString className={valueClass}>{item.value}</EllipseString>
        </DataItemWrapper>
    )
}
const DataItemWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 22px;
`
