import React, { useCallback, useEffect, useState } from 'react'
import { Box, MuiThemeProvider, Theme, Typography } from '@material-ui/core'
import { materialAtlasTheme } from 'material-atlas/theme'
import authenticationManager from 'businesslayer/authenticationManager'
import GlobalProgressModal from 'components/global-progress-modal/global-progress-modal'
import { useMinutesManager } from './use-minutes-manager'
import {
    PageLayout,
    PageHeader,
    PageBody,
    MinutesManagerToolbar,
    PageFooter
} from 'components/layout/page-layout'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import i18n from 'i18next'
import { countlyEvent } from 'businesslayer/Countly'
import { addKeyPressHandler, updatePendoMetadata } from 'common/util/functions'
import Minutesdocuments from 'components/minutes-documents/minutes-documents'
import { AtlasSelect } from 'components/atlas-material/select'
import { makeStyles } from '@material-ui/core'
import { statusMenuItems } from 'components/popovers/status-list/status-menu-items'
import { css } from '@emotion/react'
import { useMinutesList } from 'businesslayer/networkrequests/minutes/useMinutesList'
import { CommitteeSelect } from 'components/popovers/committee-select/committee-select'
import { useCommitteesState } from 'components/contexts/committee-context'
import { AtlasButton } from 'components/atlas-material/button'
import PlusIcon from 'assets/icons/PlusIcon'
import { ColorBaseMulberryBlue, ColorBaseWhite, ColorEastBay } from 'assets/styles/variables'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { setSessionStorageItem, getSessionStorageItem } from 'businesslayer/minutesLocalStore'
/**
 * Minutes Manager Page
 */

const useStyles = makeStyles((theme: Theme) => ({
    filterBar: {
        display: 'grid',
        gridTemplateColumns: ({ numColumns }: any) =>
            numColumns === 3 ? '2.5fr 1fr 1fr' : numColumns === 2 ? '3fr 1fr' : '3fr',
        gap: '24px',
        padding: '12px 24px'
    },
    minutesDocumentsText: {
        fontFamily: 'Source Sans Pro',
        color: '#1e1e1e',
        fontWeight: 600,
        fontSize: '18px',
        margin: 'auto 0px auto 0px'
    },
    noMinutesText: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        padding: '24px',
        minHeight: '68px',
        maxHeight: '98px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '68px'
    },
    header: {
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '100px',
        borderBottom: '1px solid #E6E6E6',
        padding: '24px',
        backgroundColor: '#FFFFFF',
        display: 'flex'
    },
    subHeaderText: {
        color: '#282E37',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '32px'
    },
    subTitleText: {
        color: '#282E37',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px'
    },
    createMinutesContainerCls: {
        textTransform: 'none',
        backgroundColor: ColorEastBay,
        display: 'flex',
        color: '#fff',
        borderWidth: '0px',
        borderRadius: '3px',
        letterSpacing: '0.5px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        padding: '8px 12px',
        fontWeight: 600,
        placeContent: 'center',
        gap: '8px',
        minWidth: '168px',
        '&:focus, &:active': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px',
            backgroundColor: `${ColorEastBay}`
        },
        '&:hover': {
            backgroundColor: '#364262',
            color: '#fff',
            cursor: 'pointer'
        }
    },
    createMinutesTxtCls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        placeContent: 'center',
        gap: '5px'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 300px)'
    },
    loaderStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '104px'
    },
    loaderText: {
        paddingLeft: '1%',
        color: theme.palette.text.disabled,
        textAlign: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600,
        marginTop: theme.spacing(2)
    },
    fullScreenLoader: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999 // Ensure the loader is on top of other elements
    },
    loaderContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))

declare global {
    interface Window {
        canAddMinutes: boolean
    }
}

export const MinutesManagerPage = () => {
    const { fetchMinutesDocuments, documentLists, isLoading, totalDocuments } = useMinutesList()
    const {
        statusFilter,
        canAddMinutes,
        handleOpenMinutesModal,
        handleOpenAboutModal,
        handleOpenMinutes,
        handleClickMinutesOptions,
        setStatusFilter,
        modals,
        isAdminOfCommittee,
        deleteResponse,
        setDeleteResponse,
        updatedMinutesItem,
        setUpdatedMinutesItem,
        isDeleting
    } = useMinutesManager()

    const [page, setPage] = useState<number>(1)
    const [committeeValue, setCommitteeValue] = useState<Committee>()
    const [sortBy, setSortBy] = useState<string>('updated_at')
    const [order, setOrder] = useState<string>('desc')
    const { committees } = useCommitteesState()

    //Update pendo option after roles loaded
    if (window.pendo.isInitialized) {
        const role = canAddMinutes ? 'Administrator' : 'Reviewer'
        updatePendoMetadata({ role }, true)
    }
    const handleStatusFilterChange = (event: any) => {
        countlyEvent('Mgr_AllMinutesTabStatuses', { filterOption: event.target.value })
        setStatusFilter(event.target.value)
        setSessionStorageItem('selectedStatusFilter', event.target.value)
        setPage(1)
        fetchMinutesDocuments(event.target.value, committeeValue?.id, 1, sortBy, order)
    }
    const handlePageChange = (page: number) => {
        setPage((prev) => prev + 1)
        fetchMinutesDocuments(statusFilter, committeeValue?.id, page, sortBy, order)
    }
    const handleCommiitteeChange = (value: Committee) => {
        setCommitteeValue(value)
        setSessionStorageItem('selectedCommitteeFilter', value.id)
        setPage(1)
        fetchMinutesDocuments(statusFilter, value.id, 1, sortBy, order)
    }
    const handleSortBy = (sortBy: string, order: string) => {
        setSortBy(sortBy)
        setPage(1)
        setOrder(order)
        fetchMinutesDocuments(statusFilter, committeeValue?.id, 1, sortBy, order)
    }
    const obj = {
        id: ' ',
        is_admin: true,
        name: i18n.t('ALL_COMMITTEE')
    }

    useEffect(() => {
        const selectedStatusFilter = getSessionStorageItem(
            'selectedStatusFilter'
        ) as DocumentStatusFilter
        const selectedCommitteeFilter = getSessionStorageItem('selectedCommitteeFilter')
        if (selectedCommitteeFilter) {
            const selectedCommittee = committees.find(
                (committee) => committee.id === selectedCommitteeFilter
            )
            setCommitteeValue(selectedCommittee)
        }
        if (selectedStatusFilter) {
            setStatusFilter(selectedStatusFilter)
        }

        if (deleteResponse || updatedMinutesItem) {
            setTimeout(() => {
                fetchMinutesDocuments(statusFilter, committeeValue?.id, page, sortBy, order)
                setDeleteResponse('')
                setUpdatedMinutesItem('')
            }, 0)
        }
    }, [
        deleteResponse,
        committeeValue,
        fetchMinutesDocuments,
        statusFilter,
        page,
        sortBy,
        order,
        setDeleteResponse,
        updatedMinutesItem,
        setUpdatedMinutesItem,
        setStatusFilter,
        committees
    ])
    const handleCreateMinutes = useCallback(() => {
        handleOpenMinutesModal('CREATE')
        countlyEvent('Mgr_CreateNewButton')
    }, [handleOpenMinutesModal])
    const classes = useStyles({
        numColumns: canAddMinutes ? 3 : 2
    })
    return (
        <MuiThemeProvider theme={materialAtlasTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <PageLayout>
                    <PageHeader
                        title={i18n.t('PAGE_TITLE_MM')}
                        onSignout={authenticationManager.handleSignout}>
                        <MinutesManagerToolbar />
                    </PageHeader>

                    <Box className={classes.header} data-testid="MinutesManager_hrader">
                        <Box>
                            <Typography
                                variant="h1"
                                component="h1"
                                className={classes.subHeaderText}>
                                {i18n.t('MINUTES_MANAGER')}
                            </Typography>
                            {canAddMinutes && (
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    className={classes.subTitleText}>
                                    {i18n.t('MANAGE_MINUTES_HEADER_TITLE')}
                                </Typography>
                            )}
                        </Box>
                        {canAddMinutes && (
                            <AtlasButton
                                role="button"
                                tabIndex={0}
                                data-testid="MinutesManager_Create_Minutes_Btn"
                                disableRipple
                                className={classes.createMinutesContainerCls}
                                onKeyPress={(e) =>
                                    addKeyPressHandler(e) ? handleCreateMinutes : null
                                }
                                onClick={handleCreateMinutes}>
                                <Box
                                    className={classes.createMinutesTxtCls}
                                    data-testid="MinutesManager_Create_Minutes_ICON">
                                    <PlusIcon /> {i18n.t('CREATE_MINUTES')}
                                </Box>
                            </AtlasButton>
                        )}
                    </Box>
                    {<LinearIndeterminate loading={!isLoading || isDeleting} />}
                    <Box className={classes.filterBar}>
                        <Box
                            aria-label={i18n.t('MANAGE_YOUR_MINUTES')}
                            className={classes.minutesDocumentsText}>
                            {i18n.t('MANAGE_YOUR_MINUTES')}
                        </Box>
                        <CommitteeSelect
                            css={css`
                                flex: 1;
                                > label {
                                    color: #1e1e1e;
                                    font-family: 'Source Sans Pro';
                                    color: #1e1e1e;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 16px;
                                }
                            `}
                            label={i18n.t('FILTER_BY_COMITTEE')}
                            isReadonly={false}
                            onChange={handleCommiitteeChange}
                            value={committeeValue || obj}
                            committees={[...[obj], ...committees]}></CommitteeSelect>
                        {canAddMinutes && (
                            <AtlasSelect
                                css={css`
                                    flex: 1;
                                    > label {
                                        color: #1e1e1e;
                                        font-family: 'Source Sans Pro';
                                        color: #1e1e1e;
                                        font-weight: 400;
                                        font-size: 13px;
                                        line-height: 16px;
                                    }
                                `}
                                data-testid="MinutesManager_Filter"
                                role="combobox"
                                id="filter"
                                label={i18n.t('FILTER_BY_STATUS')}
                                ariaLabelledby="filter"
                                value={statusFilter}
                                onChange={handleStatusFilterChange}>
                                {statusMenuItems()}
                            </AtlasSelect>
                        )}
                    </Box>
                    <PageBody>
                        {/* Always show the LoaderView when isLoading is true
                            Show existing records only when not loading
                        */}
                        {
                            <div>
                                {documentLists.length > 0 ? (
                                    <Minutesdocuments
                                        statusFilter={statusFilter}
                                        onClickOptions={handleClickMinutesOptions}
                                        onOpenMinutes={handleOpenMinutes}
                                        documentLists={documentLists}
                                        handlePageChange={handlePageChange}
                                        handleSortBy={handleSortBy}
                                        page={page}
                                        isAdminOfCommittee={isAdminOfCommittee}
                                        totalDocuments={totalDocuments}
                                        sortBy={sortBy}
                                        order={order}
                                    />
                                ) : isLoading ? (
                                    <Box className={classes.noMinutesText}>
                                        {typeof committeeValue?.name === undefined &&
                                        statusFilter === 'all' ? (
                                            <div>{i18n.t('NO_MINUTES_TEXTS')}</div>
                                        ) : (
                                            <div>
                                                <h5>{i18n.t('NO_MINUTES_FOUND')}</h5>
                                                {i18n.t('NO_MINUTES_FOUND_ALT')}
                                            </div>
                                        )}
                                    </Box>
                                ) : null}
                            </div>
                        }
                    </PageBody>
                    <PageFooter onAboutClick={handleOpenAboutModal} />
                </PageLayout>
                {modals}
                <GlobalProgressModal />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    )
}
