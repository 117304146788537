/** @jsx jsx */
import { FC } from 'react'
import { jsx, css } from '@emotion/react'
import { MeetingDateFields } from './meeting-date-fields'
import { AtlasButton } from 'components/atlas-material/button'
import { useLowecaseButtonStyles } from 'material-atlas/overrides/mui-button-base'
import { AtlasIcon } from 'web-components/atlas-icon'
import styled from '@emotion/styled'
import { EMPTY_MEETING_DATE_KEYS } from 'components/modals/minutes-properties-modal/minutes-properties-form-functions'
import { isDateRowEmpty } from 'common/util/validators/minutes-properties/minutes-properties-validators'
import { FormSpy } from 'react-final-form'
import { InputErrorText, getAtlasHelperText } from 'components/text-field/input-error-text'
import { keys, intersection, pick } from 'rambdax'
import { AtlasGreyMediumVar, AtlasBlackVar } from 'assets/styles/variables'
import i18n from 'i18next'

const MAX_ROW_COUNT = 5

type Props = {
    onAddMeetingDate: (dateRow: DateRow) => void
    resetDateFields: () => void
    meetingDateCount: number
}

export const AddMeetingDateFields: FC<Props> = ({
    onAddMeetingDate,
    resetDateFields,
    meetingDateCount
}) => {
    const lowercaseButton = useLowecaseButtonStyles()

    const handleAddMeetingDate = (canAddMeetingDate, meetingDate) => {
        if (canAddMeetingDate) {
            onAddMeetingDate(meetingDate)
            resetDateFields()
        }
    }

    return meetingDateCount < MAX_ROW_COUNT ? (
        <div>
            <AddDateRow>
                <MeetingDateFields />
                <AtlasButton
                    data-testid="DateList_Clear_Btn"
                    aria-label={i18n.t('REMOVE_DUE_DATE')}
                    className="text-medium-link"
                    disableFocusRipple
                    disableRipple
                    id="cancelBtn"
                    style={{ fontWeight: 600 }}
                    classes={lowercaseButton}
                    onClick={() => resetDateFields()}>
                    {i18n.t('CLEAR_MEETING_DATE_FIELDS')}
                </AtlasButton>
                <FormSpy
                    subscription={{ values: true, errors: true }}
                    render={({ values, errors }) => {
                        const meetingDate = pick(EMPTY_MEETING_DATE_KEYS, values) as DateRow
                        const canAddMeetingDate =
                            !isDateRowEmpty(meetingDate) &&
                            !dateFieldsHasErrors(errors) &&
                            values.meeting_dates.length < MAX_ROW_COUNT
                        return (
                            <IcontButtonContainer>
                                <button
                                    style={{
                                        backgroundColor: 'white',
                                        border: 'none',
                                        outline: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '2rem',
                                        width: '2rem'
                                    }}
                                    aria-label={i18n.t('ADD_ANOTHER_DAY')}
                                    id="addBtn"
                                    disabled={!canAddMeetingDate}
                                    data-testid="DateList_Add_Btn"
                                    onClick={() =>
                                        handleAddMeetingDate(canAddMeetingDate, meetingDate)
                                    }>
                                    <AtlasIcon
                                        color={
                                            canAddMeetingDate ? AtlasBlackVar : AtlasGreyMediumVar
                                        }
                                        name="add"
                                    />
                                </button>
                            </IcontButtonContainer>
                        )
                    }}
                />
            </AddDateRow>
            <FieldsError>
                <FormSpy
                    subscription={{ errors: true, values: true }}
                    render={({ errors }) => {
                        return getAtlasHelperText({
                            errorText: <InputErrorText errorText={getRowErrorText(errors)} />
                        })
                    }}
                />
            </FieldsError>
        </div>
    ) : (
        <div
            css={css`
                margin: 0 0 8px 2px;
            `}>
            <div className="text-small">Meeting Dates</div>
        </div>
    )
}
const dateFieldsHasErrors = (errors) => {
    const dateFieldNames = EMPTY_MEETING_DATE_KEYS
    const errorFieldNames = keys(errors)

    return intersection(errorFieldNames, dateFieldNames).length > 0
}
const getRowErrorText = (rowErrors) => {
    const {
        date: dateError,
        startTime: startTimeError,
        endTime: endTimeError,
        timezone: timezoneError
    } = rowErrors

    return dateError || startTimeError || endTimeError || timezoneError
}

const AddDateRow = styled.div`
    display: flex;
    align-items: flex-end;
    > * {
        margin-right: 10px;
    }
`
const IcontButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
`
const FieldsError = styled.div`
    margin-top: 5px;
    margin-left: -2px;
`
