/**
 * Do not edit directly
 * Generated on Fri, 09 Nov 2018 06:39:08 GMT
 * Probably N/A
 */

export const ColorBaseBlueLight = '#252C44' // C8
export const ColorBaseBlueMedium = '#3a81ae' // C7
export const ColorBaseBlueDark = '#355f8a' // C6
export const ColorBaseBlueGreyLightest = '#A4BCC4' // C5
export const ColorBaseBlueGreyLight = '#415464' // C3
export const ColorBaseBlueGreyMedium = '#303a48' // C2
export const ColorBaseBlueGreyDark = '#131e29' // C1
export const ColorBaseGreyLight = '#eef1f1' // C10
export const ColorBaseGreyMedium = '#e4e7e9' // C9
export const ColorBaseGreyDark = '#252C44' // C4
export const ColorBaseGreen = '#59ac45' // C12
export const ColorBaseRed = '#ec3431' // C13
export const ColorBaseOrange = '#f38a00' // C14
export const ColorBaseWhite = '#ffffff' // C11
export const ColorBaseSkyBlue = '#3E95FA'
export const ColorbaseSteelBlue = '#5D7599'
export const ColorBaseRichBlack = '#1E1E1E'
export const ColorBaseDarkGray = '#676767'
export const ColorBaseLightRed = '#AF292E'
export const ColorBaseGreyNew = '#ebebeb'
export const ColorBaseDarkBlack = '#000'
export const ColorBaseDarkGreen = '#05704B'
export const ColorBaseDarkRed = '#AF292E'
export const ColorEastBay = '#455D82'
export const ColorBaseMulberryBlue = '#0774EE'
export const ColorButtonHover = '#364262'

export const SizeFontExtraSmall = '0.6875rem' // the extra small size of the font
export const SizeFontSmall = '0.9375rem' // the small size of the font
export const SizeFontMedium = '1rem' // the medium size of the font
export const SizeFontLarge = '1.0625rem' // the base large of the font
export const SizeFontExtraLarge = '1.25rem' // the extra large size of the font

export const FontWeightRegular = 400
export const FontWeightMedium = 500
export const FontWeightLightBold = 600
export const FontWeightBold = 700

/**
 * Atlas Colors 0.5.0-build.76
 */

/**
 * --black-00: #000
 */
export const AtlasBlackVar = `--black-00`
/**
 * --black-00: #000
 */
export const AtlasBlack = `var(--black-00)`

/**
 * --black-near: #1e1e1e
 */
export const AtlasBlackNearVar = `--black-near`
/**
 * --black-near: #1e1e1e
 */
export const AtlasBlackNear = `var(--black-near)`

/**
 * --grey-dark: #676767
 */
export const AtlasGreyDarkVar = `--grey-dark`
/**
 * --grey-dark: #676767
 */
export const AtlasGreyDark = `var(--grey-dark)`

/**
 * --grey-medium: #949494
 */
export const AtlasGreyMediumVar = `--grey-medium`
/**
 * --grey-medium: #949494
 */
export const AtlasGreyMedium = `var(--grey-medium)`

/**
 * --grey-light: #e6e6e6
 */
export const AtlasGreyLightVar = `--grey-light`
/**
 * --grey-light: #e6e6e6
 */
export const AtlasGreyLight = `var(--grey-light)`

/**
 * --white-100: #fff
 */
export const AtlasWhite_100Var = `--white-100`
/**
 * --white-100: #fff
 */
export const AtlasWhite_100 = `var(--white-100)`

/**
 * --dataviz-01: #2f3b4d
 */
export const AtlasDataviz_01Var = `--dataviz-01`
/**
 * --dataviz-01: #2f3b4d
 */
export const AtlasDataviz_01 = `var(--dataviz-01)`

/**
 * --dataviz-02: #6790cc
 */
export const AtlasDataviz_02Var = `--dataviz-02`
/**
 * --dataviz-02: #6790cc
 */
export const AtlasDataviz_02 = `var(--dataviz-02)`

/**
 * --dataviz-03: #979da6
 */
export const AtlasDataviz_03Var = `--dataviz-03`
/**
 * --dataviz-03: #979da6
 */
export const AtlasDataviz_03 = `var(--dataviz-03)`

/**
 * --dataviz-04: #dee0e3
 */
export const AtlasDataviz_04Var = `--dataviz-04`
/**
 * --dataviz-04: #dee0e3
 */
export const AtlasDataviz_04 = `var(--dataviz-04)`

/**
 * --dataviz-05: #ffcc52
 */
export const AtlasDataviz_05Var = `--dataviz-05`
/**
 * --dataviz-05: #ffcc52
 */
export const AtlasDataviz_05 = `var(--dataviz-05)`

/**
 * --dataviz-06: #ffcc52
 */
export const AtlasDataviz_06Var = `--dataviz-06`
/**
 * --dataviz-06: #ffcc52
 */
export const AtlasDataviz_06 = `var(--dataviz-06)`

/**
 * --dataviz-07: #ff4066
 */
export const AtlasDataviz_07Var = `--dataviz-07`
/**
 * --dataviz-07: #ff4066
 */
export const AtlasDataviz_07 = `var(--dataviz-07)`

/**
 * --dataviz-08: #382458
 */
export const AtlasDataviz_08Var = `--dataviz-08`
/**
 * --dataviz-08: #382458
 */
export const AtlasDataviz_08 = `var(--dataviz-08)`

/**
 * --dataviz-09: #5d7599
 */
export const AtlasDataviz_09Var = `--dataviz-09`
/**
 * --dataviz-09: #5d7599
 */
export const AtlasDataviz_09 = `var(--dataviz-09)`

/**
 * --dataviz-10: #acb1b8
 */
export const AtlasDataviz_10Var = `--dataviz-10`
/**
 * --dataviz-10: #acb1b8
 */
export const AtlasDataviz_10 = `var(--dataviz-10)`

/**
 * --dataviz-11: #eff0f1
 */
export const AtlasDataviz_11Var = `--dataviz-11`
/**
 * --dataviz-11: #eff0f1
 */
export const AtlasDataviz_11 = `var(--dataviz-11)`

/**
 * --dataviz-12: #ffe6a9
 */
export const AtlasDataviz_12Var = `--dataviz-12`
/**
 * --dataviz-12: #ffe6a9
 */
export const AtlasDataviz_12 = `var(--dataviz-12)`

/**
 * --dataviz-13: #c9dfe0
 */
export const AtlasDataviz_13Var = `--dataviz-13`
/**
 * --dataviz-13: #c9dfe0
 */
export const AtlasDataviz_13 = `var(--dataviz-13)`

/**
 * --dataviz-14: #ffa0b3
 */
export const AtlasDataviz_14Var = `--dataviz-14`
/**
 * --dataviz-14: #ffa0b3
 */
export const AtlasDataviz_14 = `var(--dataviz-14)`

/**
 * --dataviz-15: #cec9d6
 */
export const AtlasDataviz_15Var = `--dataviz-15`
/**
 * --dataviz-15: #cec9d6
 */
export const AtlasDataviz_15 = `var(--dataviz-15)`

/**
 * box-shadow
 * --low: 0 2px 10px 0 rgba(0,0,0,0.1)
 */
export const AtlasLowVar = `--low`
/**
 * box-shadow
 * --low: 0 2px 10px 0 rgba(0,0,0,0.1)
 */
export const AtlasLow = `var(--low)`

/**
 * box-shadow
 * --medium: 0 2px 10px 0 rgba(0,0,0,0.3)
 */
export const AtlasMediumVar = `--medium`
/**
 * box-shadow
 * --medium: 0 2px 10px 0 rgba(0,0,0,0.3)
 */
export const AtlasMedium = `var(--medium)`

/**
 * box-shadow
 * --high: 0 2px 10px 0 rgba(0,0,0,0.5)
 */
export const AtlasHighVar = `--high`
/**
 * box-shadow
 * --high: 0 2px 10px 0 rgba(0,0,0,0.5)
 */
export const AtlasHigh = `var(--high)`

/**
 * --primary: #2f3b4d
 */
export const AtlasPrimaryVar = `--primary`
/**
 * --primary: #2f3b4d
 */
export const AtlasPrimary = `var(--primary)`

/**
 * --secondary: #76b1e2
 */
export const AtlasSecondaryVar = `--secondary`
/**
 * --secondary: #76b1e2
 */
export const AtlasSecondary = `var(--secondary)`

/**
 * --tertiary: #d8ecf8
 */
export const AtlasTertiaryVar = `--tertiary`
/**
 * --tertiary: #d8ecf8
 */
export const AtlasTertiary = `var(--tertiary)`

/**
 * --background-top: #2f3b4d
 */
export const AtlasBackgroundTopVar = `--background-top`
/**
 * --background-top: #2f3b4d
 */
export const AtlasBackgroundTop = `var(--background-top)`

/**
 * --background-base: #f4f6f8
 */
export const AtlasBackgroundBaseVar = `--background-base`
/**
 * --background-base: #f4f6f8
 */
export const AtlasBackgroundBase = `var(--background-base)`

/**
 * --background-statusbar: #202935
 */
export const AtlasBackgroundStatusbarVar = `--background-statusbar`
/**
 * --background-statusbar: #202935
 */
export const AtlasBackgroundStatusbar = `var(--background-statusbar)`

/**
 * --background-container: #fff
 */
export const AtlasBackgroundContainerVar = `--background-container`
/**
 * --background-container: #fff
 */
export const AtlasBackgroundContainer = `var(--background-container)`

/**
 * --action-primary: #5d7599
 */
export const AtlasActionPrimaryVar = `--action-primary`
/**
 * --action-primary: #5d7599
 */
export const AtlasActionPrimary = `var(--action-primary)`

/**
 * --action-primary-hover: #455772
 */
export const AtlasActionPrimaryHoverVar = `--action-primary-hover`
/**
 * --action-primary-hover: #455772
 */
export const AtlasActionPrimaryHover = `var(--action-primary-hover)`

/**
 * --action-primary-active: #37465b
 */
export const AtlasActionPrimaryActiveVar = `--action-primary-active`
/**
 * --action-primary-active: #37465b
 */
export const AtlasActionPrimaryActive = `var(--action-primary-active)`

/**
 * --action-primary-disabled: #d7dde6
 */
export const AtlasActionPrimaryDisabledVar = `--action-primary-disabled`
/**
 * --action-primary-disabled: #d7dde6
 */
export const AtlasActionPrimaryDisabled = `var(--action-primary-disabled)`

/**
 * --link: #385f99
 */
export const AtlasLinkVar = `--link`
/**
 * --link: #385f99
 */
export const AtlasLink = `var(--link)`

/**
 * --link-hover: #294772
 */
export const AtlasLinkHoverVar = `--link-hover`
/**
 * --link-hover: #294772
 */
export const AtlasLinkHover = `var(--link-hover)`

/**
 * --link-active: #21395b
 */
export const AtlasLinkActiveVar = `--link-active`
/**
 * --link-active: #21395b
 */
export const AtlasLinkActive = `var(--link-active)`

/**
 * --link-disabled: #cdd7e5
 */
export const AtlasLinkDisabledVar = `--link-disabled`
/**
 * --link-disabled: #cdd7e5
 */
export const AtlasLinkDisabled = `var(--link-disabled)`

/**
 * --status-success: #05704b
 */
export const AtlasStatusSuccessVar = `--status-success`
/**
 * --status-success: #05704b
 */
export const AtlasStatusSuccess = `var(--status-success)`

/**
 * --status-error: #af292e
 */
export const AtlasStatusErrorVar = `--status-error`
/**
 * --status-error: #af292e
 */
export const AtlasStatusError = `var(--status-error)`

/**
 * --status-alert: #eaa14b
 */
export const AtlasStatusAlertVar = `--status-alert`
/**
 * --status-alert: #eaa14b
 */
export const AtlasStatusAlert = `var(--status-alert)`

/**
 * --status-new: #e71613
 */
export const AtlasStatusNewVar = `--status-new`
/**
 * --status-new: #e71613
 */
export const AtlasStatusNew = `var(--status-new)`

/**
 * --accent-01: #a5ccec
 */
export const AtlasAccent_01Var = `--accent-01`
/**
 * --accent-01: #a5ccec
 */
export const AtlasAccent_01 = `var(--accent-01)`

/**
 * --accent-02: #c0dbd1
 */
export const AtlasAccent_02Var = `--accent-02`
/**
 * --accent-02: #c0dbd1
 */
export const AtlasAccent_02 = `var(--accent-02)`

/**
 * --accent-03: #efb5b5
 */
export const AtlasAccent_03Var = `--accent-03`
/**
 * --accent-03: #efb5b5
 */
export const AtlasAccent_03 = `var(--accent-03)`

/**
 * --accent-04: #f1c189
 */
export const AtlasAccent_04Var = `--accent-04`
/**
 * --accent-04: #f1c189
 */
export const AtlasAccent_04 = `var(--accent-04)`

/**
 * --accent-05: #c1c1c1
 */
export const AtlasAccent_05Var = `--accent-05`
/**
 * --accent-05: #c1c1c1
 */
export const AtlasAccent_05 = `var(--accent-05)`
