import React from 'react'

const HomeIcon = () => (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.62497 16.0202H4.97115V10.0779H10.2788V16.0202H13.625V7.02015L7.62498 2.50093L1.62497 7.02015V16.0202ZM0.125 17.5201V6.27018L7.62498 0.625977L15.125 6.27018V17.5201H8.77883V11.5778H6.47113V17.5201H0.125Z"
            fill="white"
        />
    </svg>
)
export default HomeIcon
