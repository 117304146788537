import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ActionEditFormRightPanel from './form/ActionEditFormRightPanel'
import minuteTakerSelectors from 'selectors/minuteTakerSelectors'
import actionEditorSelectors from 'selectors/actionEditorSelectors'
import { bindActionCreators } from 'redux'
import { actions as actionEditorReducer } from 'reducers/actionEditorReducer'
import { actions as editorActions } from '../../../reducers/minuteEditorReducer'
import { actions } from '../../../reducers/minuteTakerReducer'
import { actions as actionDashboard } from '../../../reducers/actionDashboardReducer'
import { connect } from 'react-redux'
import { Box, Button, Divider } from '@material-ui/core'
import { translate } from 'components/shared/internationalization'
import { ColorBaseMulberryBlue, ColorBaseWhite, ColorEastBay } from 'assets/styles/variables'
import { StyledInviteNotificationIcon } from './ActionItemDetailsCard/Atoms'
import NotificationView from '../actionlist/NotificationView'
import { ClassNames } from '@emotion/react'
import { AddActionEditActionButton } from 'components/shared/StyledComponents'
import {
    DeleteButton,
    StyledDeleteActionItemIcon,
    StyledAtlasButtonOld
} from './ActionItemEditDialog/ActionItemEditDialog'
import ConfirmationDialog from 'components/shared/ConfirmationDialog'
import { deleteAction, getActions } from 'businesslayer/api/actions'
import { AtlasIcon } from '@diligentcorp/atlas-react'
import { EllipseHeading } from 'assets/sharedStyles'
import blockingOperation from 'businesslayer/blockingOperation'
import { ActionData, CurrentMinutesProps, Details, MinutesSectionsData } from 'Types'
import { formatDate } from 'common/formatters/date'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'

const drawerWidth = 658

const useStyles = makeStyles((theme) => ({
    drawer: {
        maxWidth: drawerWidth,
        [theme.breakpoints.down('md')]: {
            maxWidth: '100vw'
        },
        '@media (max-width: 768px)': {
            width: '100vw'
        }
    },
    drawerPaper: {
        position: 'fixed',
        maxWidth: drawerWidth,
        [theme.breakpoints.down('md')]: {
            maxWidth: '100vw'
        },
        '@media (max-width: 768px)': {
            width: '100vw'
        }
    },
    table: {
        maxWidth: 610,
        margin: 'auto',
        border: '1px solid #E6E6E6',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        }
    },
    tableCell: {
        border: '1px solid #E6E6E6',
        height: '56px'
    },
    formContainer: {
        width: '100%',
        padding: '24px 24px 10px',
        gap: '0px'
    },
    title: {
        padding: '16px'
    },
    actionsSummary: {
        '&.MuiTypography-body1': {
            padding: '16px',
            fontFamily: 'Source Sans Pro',
            fontWeight: 600,
            fontSize: '22px',
            lineHeight: '26px'
        }
    },
    headerBox: {
        height: '64px',
        backgroundColor: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        flexShrink: 0,
        display: 'flex'
    },
    notificationTitle: {
        color: ColorEastBay,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '26px',
        textAlign: 'center',
        textTransform: 'none'
    },
    btnContainer: {
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        marginRight: '16px',
        textDecoration: 'none'
    },
    statusClass: {
        color: '#FFFFFF',
        padding: '4px 8px 4px 8px',
        gap: '1px',
        borderRadius: '4px 0px 0px 0px',
        width: 'fit-content',
        height: '24px',
        placeContent: 'center',
        textTransform: 'capitalize'
    },
    notificationClass: {
        textTransform: 'capitalize'
    },
    statusClassPending: {
        backgroundColor: '#676767'
    },
    statusClassDue: {
        backgroundColor: 'orange',
        color: '#000000'
    },
    statusClassComplete: {
        backgroundColor: '#05704B'
    },
    btnClass: {
        padding: '8px 12px 8px 12px'
    },
    panal: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    footer: {
        height: '72px',
        backgroundColor: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        flexShrink: 0
    },
    body: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    footerComp: {
        display: 'flex',
        padding: theme.spacing(2),
        gap: '16px'
    },
    backButton: {
        backgroundColor: '#2f3b4d',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        margin: 'auto',
        marginRight: '8px',
        width: '40px',
        height: '40px',
        '&:focus, &:active': {
            outline: 'none',
            width: '40px',
            height: '40px',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    padHeader: {
        backgroundColor: '#2f3b4d',
        width: '100%',
        display: 'none',
        height: '64px',
        [theme.breakpoints.down('md')]: {
            display: 'flex'
        }
    },
    headerCenterLogo: {
        flex: '1 0 0',
        color: 'var(--core-color-legacy-white, #fff)',
        textAlign: 'center',
        fontFamily: 'source sans pro',
        font: ' 600 18px/24px Source Sans Pro',
        placeSelf: 'center'
    },
    sendNotification: {
        '&:focus, &:active': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        },
        '& span': {
            gap: '8px'
        }
    }
}))

interface RightDrawerProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    isOpen: boolean
    selectedAction: Details | null
    allContacts: Array<any>
    dateInputMask: string
    sections: Array<MinutesSectionsData>
    selectedSection: any
    actions: {
        loadActionsFulfilled: (...args: any) => void
        updateTakerListAction: (...args: any) => void
        resetEditedAction: (...args: any) => void
        removeAction: (id: string) => void
        saveExistingAction: (params: ActionData) => void
        loadContacts: () => void
        deleteDashboardAction: (...args: any) => void
        editActionDashboardFullfilled: (...args: any) => void
    }
    saveComplete: any
    currentMinuteItem: CurrentMinutesProps
    savedAction: any
    dateFormat: string
    minutesActions: any
    handleNotificationStatusChange?: (...args : any) => void
}

const RightDrawer: React.FC<RightDrawerProps> = ({
    setIsOpen,
    isOpen,
    selectedAction = {} as Details,
    allContacts,
    sections,
    selectedSection,
    dateInputMask,
    actions,
    saveComplete,
    currentMinuteItem,
    savedAction,
    minutesActions,
    handleNotificationStatusChange
}) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [showIndicator, setShowIndicator] = useState(null)
    const toggleDrawer = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    useEffect(() => {
        if (!allContacts) actions.loadContacts()
    }, [actions, allContacts])

    const { completed_date, status, id } = selectedAction ?? {}
    const { dateFormat } = transientStorageManager
    const date = completed_date
        ? formatDate({ date: completed_date, dateFormat: dateFormat })
        : translate('NO_DUE_DATE')
    const getDatefnsFormat = useCallback((dateFormat) => {
        return dateFormat.replace(/[DY]/g, function (c: string) {
            return c.toLowerCase()
        })
    }, [])
    useEffect(() => {
        if (saveComplete && savedAction) {
            actions.updateTakerListAction(savedAction)
            actions.resetEditedAction()
            setIsOpen(false)
        }
    }, [actions, saveComplete, savedAction, setIsOpen])
    const onCloseNotificationView = useCallback(() => setOpen(false), [])
    const handleOpenNotification = useCallback(() => setOpen(true), [])
    const handleSendNotification = useCallback((args) => {
        handleNotificationStatusChange?.(args)
    }, [handleNotificationStatusChange])
    const reloadActions = useCallback(async () => {
        if (currentMinuteItem?.id) {
            const data = await getActions(currentMinuteItem.id)
            actions.loadActionsFulfilled(data)
        }
        onCloseNotificationView()
    }, [actions, currentMinuteItem, onCloseNotificationView])

    const handleSaveButtonClick = useCallback(() => {
        const form = document.getElementById('editActionsForm')
        if (form) {
            form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
        }
    }, [])
    const handleDeleteAction = useCallback(async () => {
        setShowConfirmation(false)
        setIsOpen(false)
        blockingOperation.showProgress(null, true)
        const minutesId = currentMinuteItem?.id ?? selectedAction?.minutes_document_id
        await deleteAction(
            minutesId,
            id,
            deleteAction.bind(null, currentMinuteItem?.id ?? minutesId, id, selectedAction),
            selectedAction
        )
        if (!currentMinuteItem) {
            actions.deleteDashboardAction(selectedAction?.id!)
        } else {
            actions.removeAction(selectedAction?.id!)
            reloadActions()
        }
        blockingOperation.hideProgress()
    }, [actions, currentMinuteItem, id, selectedAction, reloadActions, setIsOpen])

    const notificationStatus = useCallback(() => {
        const currentAction =
            !!minutesActions &&
            minutesActions.length &&
            minutesActions.filter((value) => {
                return value.id === selectedAction?.id
            })
        return (
            (!!currentAction && currentAction.length && currentAction[0].notificationStatus) ||
            selectedAction?.notificationStatus
        )
    }, [minutesActions, selectedAction])

    const renderConfirmedAction = useMemo(
        () => (
            <ConfirmationDialog
                message={(translate('DELETE_ACTION_MESSAGE') as unknown) as string}
                title={(translate('DELETE_ACTION_TITLE') as unknown) as string}
                onConfirm={handleDeleteAction}
                onCancel={() => setShowConfirmation(false)}
                confirmLabel={(translate('DELETE') as unknown) as string}
            />
        ),
        [handleDeleteAction, setShowConfirmation]
    )
    const handleonActionItemSubmit = useCallback(
        async (action) => {
            blockingOperation.showProgress(null, true)
            const data = (await actions.saveExistingAction(action)) as any
            actions.editActionDashboardFullfilled(data?.value)
            blockingOperation.hideProgress()
        },
        [actions]
    )
    const confirmationDialog = showConfirmation ? renderConfirmedAction : null
    const statusClass = useMemo(() => {
        switch (status) {
            case 'complete':
                return classes.statusClassComplete
            case 'overdue':
                return classes.statusClassDue
            default:
                return classes.statusClassPending
        }
    }, [status, classes])
    return (
        <Drawer
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor="right"
            open={isOpen}
            onClose={toggleDrawer}>
            <div className={classes.panal}>
                <Box className={classes.padHeader}>
                    <button onClick={toggleDrawer} className={classes.backButton}>
                        <AtlasIcon
                            name="expand-left"
                            size={'30'}
                            color="--background-base"
                            data-rh={translate('BACK_TO_MINUTES_MANAGER')}
                        />
                    </button>
                    <EllipseHeading className={classes.headerCenterLogo}>
                        {translate('WINDOW_TITLE')}
                    </EllipseHeading>
                </Box>
                <Box className={classes.headerBox}>
                    <Typography className={classes.actionsSummary}>
                        {translate('ACTION_SUMMARY')}
                    </Typography>
                    <Box className={classes.btnContainer}>
                        <Button
                            disableFocusRipple
                            disableRipple
                            className={classes.sendNotification}
                            onClick={handleOpenNotification}>
                            <StyledInviteNotificationIcon
                                isActive={false}
                                skeleton={true}
                                fill={ColorEastBay}
                            />
                            <Typography className={classes.notificationTitle}>
                                {translate('SEND_NOTIFICATION_TITLE')}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Divider />
                {<LinearIndeterminate loading={showIndicator} />}
                <Box className={classes.body}>
                    <TableContainer className={classes.title}>
                        <Table className={classes.table} aria-label="details table">
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        {translate('ACTION_ITEM_STATUS')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Box className={`${classes.statusClass} ${statusClass}`}>
                                            {status}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        {translate('COMPLETED_DATE')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>{date}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        {translate('NOTIFICATION')}
                                    </TableCell>
                                    <TableCell
                                        className={`${classes.tableCell} ${classes.notificationClass}`}>
                                        {notificationStatus()}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {open && (
                        <NotificationView
                            action={selectedAction}
                            minutesItem={currentMinuteItem}
                            onClose={onCloseNotificationView}
                            onSaved={handleSendNotification}
                            reloadActions={reloadActions}
                            setIndicator={setShowIndicator}
                        />
                    )}
                    <div className={classes.formContainer}>
                        <ActionEditFormRightPanel
                            action={selectedAction ?? {}}
                            allContacts={allContacts}
                            dateInputMask={getDatefnsFormat(dateInputMask)}
                            sections={selectedAction?.sections ?? sections}
                            selectedSection={selectedSection}
                            onActionItemSubmit={handleonActionItemSubmit}
                            currentMinuteItem={currentMinuteItem}
                            setShowSaveButton={setShowSaveButton}
                            actions={actions}
                        />
                    </div>
                </Box>
                <Divider />
                <Box className={classes.footer}>
                    <Box className={classes.footerComp}>
                        <ClassNames key="actionDeleteCss">
                            {({ css }) => (
                                <button
                                    key="actionDelete"
                                    className={`${css`
                                        ${DeleteButton}
                                    `} ${classes.btnClass} `}
                                    onClick={() => setShowConfirmation(true)}>
                                    {<StyledDeleteActionItemIcon />}
                                    {translate('DELETE_ACTION_CTA')}
                                </button>
                            )}
                        </ClassNames>
                        <AddActionEditActionButton
                            key="actionCancel"
                            disableFocusRipple
                            disableRipple
                            disabled={false}
                            onClick={toggleDrawer}>
                            {translate('CANCEL')}
                        </AddActionEditActionButton>
                        <StyledAtlasButtonOld
                            key="actionSave"
                            disabled={!showSaveButton}
                            type="submit"
                            onClick={handleSaveButtonClick}
                            disableFocusRipple>
                            {translate('SAVE')}
                        </StyledAtlasButtonOld>
                    </Box>
                </Box>
                {confirmationDialog}
            </div>
        </Drawer>
    )
}
const mapStateToProps = (state, _) => {
    return {
        sections: minuteTakerSelectors.minutesSections(state.minuteTakerReducer),
        currentMinuteItem: minuteTakerSelectors.currentMinuteItem(state.minuteTakerReducer),
        selectedSection: minuteTakerSelectors.selectedSection(state.minuteTakerReducer),
        editedAction: minuteTakerSelectors.editedAction(state.minuteTakerReducer),
        block: minuteTakerSelectors.editedBlock(state.minuteTakerReducer),
        allContacts: actionEditorSelectors.allContacts(state.actionEditorReducer),
        readyToSave: actionEditorSelectors.readyToSave(state.actionEditorReducer),
        saveComplete: actionEditorSelectors.saveComplete(state.actionEditorReducer),
        savedAction: actionEditorSelectors.savedAction(state.actionEditorReducer),
        dateFormat: actionEditorSelectors.dateFormat(state.actionEditorReducer),
        dateInputMask: actionEditorSelectors.dateInputMask(state.actionEditorReducer),
        minutesActions: minuteTakerSelectors.minutesActions(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        removeAction,
        resetEditedAction,
        updateTakerListAction,
        saveExistingAction,
        editSectionAction,
        loadActionsFulfilled,
        loadContacts,
        deleteDashboardAction,
        editActionDashboardFullfilled
    } = { ...actions, ...editorActions, ...actionEditorReducer, ...actionDashboard }

    return {
        actions: bindActionCreators(
            {
                removeAction,
                resetEditedAction,
                updateTakerListAction,
                saveExistingAction,
                editSectionAction,
                loadActionsFulfilled,
                loadContacts,
                deleteDashboardAction,
                editActionDashboardFullfilled
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightDrawer)
