import React, { useState, ReactNode, useContext } from 'react'
import { DialogContext, DialogContextProps } from './DialogContext'

type InjectedDialogSubscriberProps = {
    isDialogShown: boolean
} & Pick<DialogContextProps, 'showDialog'> &
    Pick<DialogContextProps, 'hideDialog'>

type DialogSubscriberProps = {
    name: string
    children?(props: InjectedDialogSubscriberProps): JSX.Element | null
}

export function DialogProvider(props: { children?: ReactNode }): JSX.Element {
    const [dialog, setDialog] = useState({})

    const showDialog = (dialog: string) => {
        setDialog({ [dialog]: true })
    }

    const hideDialog = (dialog: string) => {
        setDialog({ [dialog]: false })
    }

    return (
        <DialogContext.Provider
            value={{
                dialog: dialog,
                showDialog: showDialog,
                hideDialog: hideDialog
            }}>
            {props.children}
        </DialogContext.Provider>
    )
}

export function useDialog(name) {
    const context = useContext(DialogContext)
    if (context === undefined) {
        throw new Error('useDialog must be used within a DialogContainer')
    }

    const isDialogShown = !!context.dialog && context.dialog[name]

    return { isDialogShown, showDialog: context.showDialog, hideDialog: context.hideDialog }
}

export default function DialogSubscriber(props: DialogSubscriberProps) {
    const context = useDialog(props.name)

    return isFunction(props.children) ? props.children(context) : null
}

const isFunction = <T extends Function>(value: any): value is T => typeof value === 'function'
