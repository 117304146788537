import React, { FC } from 'react'
import { ClassNames } from '@emotion/react'
import { Button } from 'components/shared/StyledComponents'
import i18n from 'i18next'
import RevealListIcon from 'assets/icons/RevealListIcon'
import HideListIcon from 'assets/icons/HideListIcon'
import { ColorBaseWhite, ColorBaseMulberryBlue, ColorEastBay } from 'assets/styles/variables'
import { makeStyles } from '@material-ui/core'
import styled from '@emotion/styled'
import selectors from '../../../selectors/minuteTakerSelectors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

interface UtilityExpandCollapseProps {
    toggleExpandCollapseAll: (shouldExpand: boolean) => void
    isMinutesExpanded: boolean
}
const useStyles = makeStyles(() => ({
    ButtonCss: {
        borderRadius: '2px',
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        },
        '&:hover': {
            color: ColorEastBay,
            backgroundColor: '#94949429'
        },
        padding: 0
    },
    CollapseExpandToggleCss: {
        border: `1px solid ${ColorEastBay}`,
        color: '#455d82',
        textTransform: 'capitalize',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        outline: 'none',
        padding: '8px 12px 8px 12px',
        borderRadius: '2px'
    }
}))

const StyledRevealListIcon = styled(RevealListIcon)`
    stroke: #455d82;
`
const StyledHideListIcon = styled(HideListIcon)`
    stroke: #455d82;
`

function togglePopup(isMinutesExpanded: boolean) {
    // Get all elements with the class 'ai-modal'
    const popups = document.querySelectorAll('.ai-modal')

    // If popups exist, hide them
    if (popups.length) {
        if (isMinutesExpanded) {
            document.querySelectorAll('.ai-modal').forEach((it: any) => (it.style.display = 'none'))
        }
    } else {
        console.error("Element(s) with class 'ai-modal' not found.")
    }
}
export const UtilityExpandCollapse: FC<UtilityExpandCollapseProps> = (props) => {
    const handleToggleExpand = () => {
        togglePopup(props.isMinutesExpanded)
        props.toggleExpandCollapseAll && props.toggleExpandCollapseAll(!props.isMinutesExpanded)
    }
    const classes = useStyles()
    return (
        <ClassNames>
            {() => (
                <>
                    <Button
                        className={classes.ButtonCss}
                        aria-label={
                            !props.isMinutesExpanded ? i18n.t('COLLAPSE_ALL') : i18n.t('EXPAND_ALL')
                        }
                        data-testid={!props.isMinutesExpanded ? 'ToolbarCollapse' : 'ToolbarExpand'}
                        onClick={handleToggleExpand}
                        disableFocusRipple
                        aria-expanded={props.isMinutesExpanded}
                        aria-haspopup="false"
                        disableTouchRipple
                        disableRipple>
                        {props.isMinutesExpanded === true ? (
                            <>
                                <span className={classes.CollapseExpandToggleCss}>
                                    <StyledHideListIcon />
                                    {i18n.t('COLLAPSE')}
                                </span>
                            </>
                        ) : (
                            <>
                                <span className={classes.CollapseExpandToggleCss}>
                                    <StyledRevealListIcon />
                                    {i18n.t('EXPAND')}
                                </span>
                            </>
                        )}
                    </Button>
                </>
            )}
        </ClassNames>
    )
}

const mapStateToProps = (state, _) => {
    return {
        isMinutesExpanded: selectors.isMinutesExpanded(state.minuteTakerReducer)
    }
}

export default withRouter(connect(mapStateToProps)(UtilityExpandCollapse))
