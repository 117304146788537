/** @jsx jsx */
import {
    IconButton,
    ButtonText,
    ButtonIcon
} from 'components/minutemanager/components/MinuteCard/IconButton'
import { SortPopover } from './SortPopover'
import PopoverButton from 'components/shared/PopoverButton'
import { jsx, css } from '@emotion/react'
import { pathOr } from 'rambdax'
import React from 'react'
import { translate } from 'components/shared/internationalization'
import {
    ColorBaseBlueGreyLight,
    ColorBaseBlueGreyMedium,
    ColorBaseSkyBlue,
    ColorBaseWhite
} from 'assets/styles/variables'
import CarrotUpIcon from 'assets/icons/CarrotUpIcon'
import UnfoldIcon from 'assets/icons/UnfoldIcon'
import { MinutesManager_BtnFocusCss } from 'components/layout/page-layout'

const openCloseIconClass = css`
    fill: ${ColorBaseBlueGreyMedium};
    stroke: ${ColorBaseBlueGreyMedium};
    width: 10px;
    height: 10px;
`

const OpenCloseIcon = ({ isOpen }) =>
    isOpen ? <CarrotUpIcon css={openCloseIconClass} /> : <UnfoldIcon css={openCloseIconClass} />

type Props = {
    className?: string
    sortOrder?: string
    sortField?: string
    onChangeSortField: (sortField: string) => void
    onChangeSortOrder: (sortOrder: 'ascending' | 'descending') => void
}

export class SortPopoverButton extends React.PureComponent<Props> {
    render() {
        const buttonTextMap = {
            completionStatus: translate('ACTION_COMPLETE'),
            dueDate: translate('DUE_DATE'),
            assignees: translate('ASSIGNEES_MULTIPLE'),
            sectionOrder: translate('SECTION_LABEL')
        }

        return (
            <PopoverButton>
                {({ onShowPopover, isOpen, onClosePopover, anchorElement, closeReason }) => {
                    const buttonText = (
                        <React.Fragment>
                            {translate('SORT_BY')}:{' '}
                            {pathOr(
                                buttonTextMap.sectionOrder,
                                [this.props.sortField || ''],
                                buttonTextMap
                            )}
                        </React.Fragment>
                    )

                    return (
                        <React.Fragment>
                            <IconButton
                                css={css`
                                    color: ${ColorBaseBlueGreyLight};
                                    margin-right: 4px;
                                    &:focus,
                                    &:active {
                                        height: 50px;
                                        box-shadow: 0px 0px 0px 1px ${ColorBaseWhite},
                                            0px 0px 0px 3px ${ColorBaseSkyBlue};
                                        border-radius: 2px;
                                    }
                                `}
                                className={this.props.className}
                                onClick={onShowPopover}>
                                <ButtonIcon>
                                    <OpenCloseIcon isOpen={isOpen} />
                                </ButtonIcon>
                                <ButtonText>{buttonText}</ButtonText>
                            </IconButton>
                            <SortPopover
                                anchorElement={anchorElement}
                                isOpen={isOpen}
                                onClosePopover={onClosePopover}
                                onSortChange={this.props.onChangeSortField}
                                onRequestCloseExportPopover={onClosePopover}
                                closeReason={closeReason || this.props.sortField}
                            />
                            <div
                                css={css`
                                    margin-right: 0.5rem;
                                    cursor: pointer;
                                    color: ${ColorBaseBlueGreyLight};
                                    user-select: none;
                                    display: flex;
                                    width: 55px;
                                    height: 50px;
                                    padding: 0px 16px;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    ${MinutesManager_BtnFocusCss}
                                `}
                                tabIndex={0}
                                onClick={() =>
                                    this.props.onChangeSortOrder(
                                        this.props.sortOrder === 'ascending'
                                            ? 'descending'
                                            : 'ascending'
                                    )
                                }>
                                {this.props.sortOrder === 'ascending' ? '↑' : '↓'}
                            </div>
                        </React.Fragment>
                    )
                }}
            </PopoverButton>
        )
    }
}
