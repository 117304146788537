import React, { FC, Fragment } from 'react'
import styled from '@emotion/styled'
import { FieldRenderProps } from 'react-final-form'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { formatTime, formatDate, getTimezoneLabelByID } from 'common/formatters/date'
import { get12or24HourTimeFormat } from 'common/util/date'
import { AtlasIcon } from 'web-components/atlas-icon'
import i18n from 'i18next'
import { AddMeetingDateFields } from './add-meeting-date-fields'
import { sortMeetingDateRows } from 'components/modals/minutes-properties-modal/minutes-properties-form-functions'

type Props = {
    formMutators: any
    getFieldState: any
    change: any
} & FieldRenderProps<any, HTMLElement>

export const MeetingDatesFieldControl: FC<Props> = ({
    formMutators,
    getFieldState,
    change,
    input: { value, onChange }
}) => {
    const handleRemoveRow = (getFieldState: any, change: any, index: number) => {
        const fieldArray = getFieldState('meeting_dates').value
        const updatedArray = [...fieldArray.slice(0, index), ...fieldArray.slice(index + 1)]
        change('meeting_dates', updatedArray)
    }

    const addMeetingDate = (dateRow: DateRow) => {
        onChange(sortMeetingDateRows([...value, dateRow]))
    }

    return (
        <div>
            <AddMeetingDateFields
                onAddMeetingDate={addMeetingDate}
                resetDateFields={formMutators.resetDateFields}
                meetingDateCount={value.length}
            />
            <MeetingDateListDisplay
                meetingDateList={value}
                getFieldState={getFieldState}
                change={change}
                onRemoveDate={handleRemoveRow}
            />
        </div>
    )
}

type MeetingDateListProps = {
    meetingDateList: DateRow[]
    getFieldState: any
    change: any
    onRemoveDate: (getFieldState: any, change: any, index: number) => void
}
export const MeetingDateListDisplay: FC<MeetingDateListProps> = ({
    meetingDateList,
    onRemoveDate,
    getFieldState,
    change
}) => {
    return (
        <MeetingDateGrid>
            {meetingDateList.length > 0 &&
                meetingDateList.map((d, index) => {
                    const meetingDateStrings = meetingDateRowToString(d)

                    return !!meetingDateStrings ? (
                        <Fragment key={index}>
                            <div>{meetingDateStrings.dateString} </div>
                            <div>{meetingDateStrings.timeString} </div>
                            <div>{meetingDateStrings.timezoneLabel}</div>
                            <button
                                style={{
                                    backgroundColor: 'white',
                                    outline: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '2rem',
                                    width: '2rem',
                                    border: 'none'
                                }}
                                onClick={() => onRemoveDate(getFieldState, change, index)}
                                id="addBtn"
                                aria-label={i18n.t('REMOVE_DUE_DATE')}
                                data-testid="DateList_Remove_Btn">
                                <AtlasIcon name="remove" />
                            </button>
                        </Fragment>
                    ) : null
                })}
        </MeetingDateGrid>
    )
}

const meetingDateRowToString = (dateRow: DateRow) => {
    const { dateFormatInput, timeFormat: rawTimeFormat } = transientStorageManager
    const timeFormat = get12or24HourTimeFormat(rawTimeFormat)
    const dateString = formatDate({ date: dateRow.date, dateFormat: dateFormatInput }) || ''
    const startTime = formatTime({ time: dateRow.startTime, timeFormat }) || ''
    const endTime = formatTime({ time: dateRow.endTime, timeFormat }) || ''
    const timeString = !!endTime ? `${startTime} - ${endTime}` : startTime
    const timezoneLabel = getTimezoneLabelByID(dateRow.timezone)

    return { dateString, timeString, timezoneLabel }
}

const MeetingDateGrid = styled.div`
    display: grid;
    grid-template-columns: min-content min-content min-content min-content;
    grid-row-gap: 8px;
    padding-left: 20px;
    margin-bottom: 8px;
    align-items: center;

    > div {
        white-space: nowrap;
        margin-right: 15px;
    }
`
