import { transientStorageManager } from 'businesslayer/minutesSessionStore'

export const getHelpURL = () => {
    const { platform } = transientStorageManager

    const defaultHelpURL = 'https://diligent.com'
    const boardsHelpURL = 'https://www.diligent.com/support'
    const helpURLMap: Record<Platform, string | null> = {
        boardeffect: 'https://www.boardeffect.com/contact-support/',
        boards: boardsHelpURL,
        boardswebadmin: boardsHelpURL,
        boardswebdirector: boardsHelpURL,
        boardsios: null,
        mock: defaultHelpURL
    }
    return helpURLMap[platform || '']
}
