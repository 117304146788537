import { translate } from 'components/shared/internationalization'
import * as requests from '../networkrequests/reviewInvitation'
import { executeRequest } from '../request-executor'
import { countlyEvent } from 'businesslayer/Countly'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

export const getReviewers = () => {
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_CONTACTS_ERROR', {
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(requests.getReviewers(committeeId), null, onError)
}

export const inviteReviewers = (minutesId: string, data: requests.InviteReviewers) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('SEND_INVITATION_ERROR', {
            committeeId: committeeId,
            minutesId: minutesId,
            message: errorObject.message,
            code: httpCode
        })
    }

    const onSuccess = () => void countlyEvent('ReviewMin_InviteReviewers_Modal')

    return executeRequest(
        requests.inviteReviewers(committeeId, minutesId, data),
        onSuccess,
        onError
    )
}

export const getCurrentReviewDetails = (minutesId) => {
    const committeeId = getSessionStorageItem('currentCommitteeId')

    if (!committeeId) {
        return Promise.reject(translate('YOU_MUST_BE_LOGGED_IN'))
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('SEND_INVITATION_ERROR', {
            committeeId: committeeId,
            minutesId: minutesId,
            message: errorObject.message,
            code: httpCode
        })
    }

    return executeRequest(requests.getCurrentReviewDetails(committeeId, minutesId), null, onError)
}
