import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #fff;
    height: 17px;
    width: 30px;
`
type Props = {
    title?: JSX.Element
    className?: string
}

export const SectionIcon = ({ title, className }: Props) => (
    <Svg viewBox="0 0 14.05 15" className={className}>
        <title>{title} </title>
        <g id="Layer_4" data-name="Layer 4">
            <g id="side_bar_4" data-name="side bar 4">
                <g id="DARK_minutes" data-name="DARK minutes">
                    <g id="toolbar">
                        <g id="new_section_button_icon" data-name="new section button icon">
                            <rect x="3.75" y="13.11" width="10.3" height="1.89" />
                            <rect x="3.75" y="6.56" width="10.3" height="1.89" />
                            <rect width="14.05" height="1.89" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </Svg>
)

export default SectionIcon
