import React, { Component } from 'react'

//region Components

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import InlineError from '../../shared/InlineError'
import { v4 as uuid } from 'uuid'

//endregion

//region Redux

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../../reducers/minuteEditorReducer'

import selectors from '../../../selectors/minuteEditorSelectors'
import ChipInputField, { makeOnChipChange } from 'components/chip-input/chip-input-field'
import UserChip from 'components/shared/UserChip'
import styled from '@emotion/styled'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { MAX_ATTENDEE_COUNT } from 'common/util/validators/minutes-properties/minutes-properties-validators'
import {
    AssigneeTextField,
    Assignees,
    InputLabelStyled
} from 'components/minutetaker/components/form/AssigneeChipInputField'
import { css } from '@emotion/css'

//endregion

//region Props

type Props = {
    actions: {
        editMinuteItem: (...args: any[]) => void
        loadContacts: (...args: any[]) => void
        validateChanges: (...args: any[]) => void
        saveExistingMinuteItem: (...args: any[]) => void
    }
    editedItem: any
    linkedBook: any
    currentMinuteItem: any
    saveComplete: boolean
    readyToSave: boolean
    isSavingExisting: boolean
    saveAttendeesUponEdit: boolean
    hideAvatars: boolean
    hideFloatingLabel: boolean
    onAttendeesAdd: ((...args: any[]) => void) | null
    onAttendeesDelete: ((...args: any[]) => void) | null
    allContacts: Array<any> | null
    readonly?: boolean
}

type State = {
    inlineError: string | null
}

//endregion

//region Implementation
/**
 * This Component needs MAJOR refactoring. It should simply take
 * a list of attendeees and a callback to add/delete instead of
 * using redux internally. This has confusing logic in order
 * to be "reused" on the taker.
 */
class MinuteManagerAttendees extends Component<Props, State> {
    state = {
        inlineError: null
    }

    loadMinuteItem = (item) => {
        if (!this.props.editedItem || +item.id !== +this.props.editedItem.id) {
            // reset minutesItem
            transientStorageManager.selectedMinuteItem = undefined
            this.props.actions.editMinuteItem(item)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        //If currentMinuteItem property provided it means we use this control externally,
        //from taker. Then we need to nmake sure reducer is initialized properly.
        if (nextProps.currentMinuteItem) {
            this.loadMinuteItem(nextProps.currentMinuteItem)
        }

        //
        if (!this.props.saveComplete && nextProps.saveComplete) {
            //When we complete saving we would want to get item refreshed in the reducer
            //Technically this case is only applicable when this editor component is reused
            //externally, so consumers of this component could get a hold of fresh object
            //after it was saved.
            //console.log('Refreshing attendee editor with new fresh data')
            this.props.actions.editMinuteItem()
        }
    }

    isAttendeesLengthEqual = (
        assignedAttendees: Array<any> | null,
        reducerAttendees: Array<any> | null
    ): boolean => {
        if (!assignedAttendees || !reducerAttendees) {
            return false
        }

        if (assignedAttendees.length !== reducerAttendees.length) {
            return false
        }

        return true
    }

    private getAttendeesArrays = (
        currentMinuteItem: any,
        editedItem: any
    ): {
        assignedAttendees: Array<any> | null
        reducerAttendees: Array<any> | null
    } => {
        const assignedAttendees = currentMinuteItem.attributes
            ? currentMinuteItem.attributes.attendees
            : null
        const reducerAttendees = editedItem ? editedItem.attendees : null

        return {
            assignedAttendees,
            reducerAttendees
        }
    }

    private isAttendeesContentDifferent = () => {
        const { editedItem, currentMinuteItem } = this.props

        if (!editedItem && !currentMinuteItem) {
            return true
        }

        if (!currentMinuteItem) {
            return false
        }

        const { assignedAttendees, reducerAttendees } = this.getAttendeesArrays(
            currentMinuteItem,
            editedItem
        )

        if (!this.isAttendeesLengthEqual(assignedAttendees, reducerAttendees)) {
            return true
        }

        for (var idx in assignedAttendees!) {
            if (assignedAttendees!.hasOwnProperty(idx)) {
                var elementA = assignedAttendees![idx]
                var elementB = reducerAttendees![idx]

                if (elementB.text !== elementA.display_name) {
                    return true
                }
            }
        }

        return false
    }
    componentDidMount() {
        const { editedItem, currentMinuteItem } = this.props
        //
        if (!editedItem && currentMinuteItem) {
            this.loadMinuteItem(currentMinuteItem)
        }

        if (editedItem && editedItem.attendees) {
            editedItem.attendees.forEach((c) => (c.isInverted = false))
        }

        // Make sure state is up to date on mount
        // We have to do this because this component used to be used
        // in multiple places and for some reason minutes has duplicated
        // state in redux for minutesItem.
        this.props.actions.editMinuteItem(currentMinuteItem)

        this.props.actions.loadContacts(true)
    }

    private setPropertyValue = (propName, value) => {
        const { editedItem } = this.props
        //
        if (!editedItem) {
            return
        }

        editedItem[propName] = value

        this.props.actions.validateChanges(editedItem)
    }

    private hasMaxAttendeeCount = (editedItem: any): boolean => {
        if (editedItem.attendees.length >= MAX_ATTENDEE_COUNT) {
            //We cannot add more than certain amount of folks
            this.setState({
                inlineError: i18n.t('INVALID_ATTENDEE_COUNT', { count: MAX_ATTENDEE_COUNT })
            })
            setTimeout(() => this.setState({ inlineError: null }), 3000)
            return true
        }

        return false
    }

    private addAttendee = (editedItem: any, newAttendee: any) => {
        editedItem.attendees.push(newAttendee)
        this.setPropertyValue('attendees', editedItem.attendees)

        if (this.props.onAttendeesAdd) {
            this.props.onAttendeesAdd(editedItem.attendees)
        }

        if (this.props.saveAttendeesUponEdit) {
            this.props.actions.saveExistingMinuteItem({ editedItem, isBackground: true })
        }
    }

    private recreateAttendeeFromContact = (allContacts: Array<any>, attendeeObject: any) => {
        const contact = allContacts[attendeeObject.value.key]

        return {
            text: attendeeObject.text,
            email: contact.attributes.email,
            id: attendeeObject.value.key,
            value: attendeeObject.text
        }
    }

    private isValidAttendeeDataToAdd = (newObject: any): boolean => {
        if (!newObject.text || !newObject.text.trim()) {
            return false
        }

        return true
    }
    private onAttendeesAdd = (newObject: any) => {
        const { isSavingExisting } = this.props

        if (isSavingExisting) {
            return
        }

        if (!this.isValidAttendeeDataToAdd(newObject)) {
            return
        }

        const { editedItem } = this.props
        if (!editedItem.attendees) {
            editedItem.attendees = []
        }

        let newAttendee = newObject

        //BREAKING CHANGE Since 1.0: Control no longer sends the same object from autoselect so we have to
        //preprocess it to find out if that is our object
        if (!newObject.email && typeof newObject.value === 'object') {
            const { allContacts } = this.props

            if (!allContacts) {
                return
            }

            newAttendee = this.recreateAttendeeFromContact(allContacts, newObject)
        }

        if (this.hasMaxAttendeeCount(editedItem)) {
            return
        }

        this.addAttendee(editedItem, newAttendee)
    }

    private onAttendeesDelete = (toDelete) => {
        const { isSavingExisting, readyToSave } = this.props
        if (isSavingExisting || readyToSave) {
            return
        }

        const { editedItem } = this.props

        const item = editedItem.attendees.find((existing) => {
            return existing.value === toDelete.value
        })

        const index = editedItem.attendees.indexOf(item)
        editedItem.attendees.splice(index, 1)

        this.setPropertyValue('attendees', editedItem.attendees)

        if (this.props.onAttendeesDelete) {
            this.props.onAttendeesDelete(editedItem.attendees)
        }

        if (this.props.saveAttendeesUponEdit) {
            this.props.actions.saveExistingMinuteItem({ editedItem, isBackground: true })
        }
    }

    private renderAttendeesDataSource = () => {
        const { allContacts, editedItem } = this.props

        if (!allContacts) {
            return
        }

        const keys = Object.keys(allContacts)
        //Dont show ones we already added
        const filtered = keys.filter(
            (key) =>
                !editedItem.attendees.find((existing) => {
                    return `${existing.id}` === key
                })
        )

        return filtered.map((key) => {
            const item = allContacts[key]

            return {
                text: item.attributes.displayName,
                email: item.attributes.email,
                id: item.id,
                value: item.attributes.displayName
            }
        })
    }

    private addAttendeeCurrentUser = (editedItem, currentUser) => {
        if (!currentUser) {
            return
        }

        const currentUserData = {
            text: currentUser.display_name,
            email: currentUser.email,
            id: currentUser.id,
            value: currentUser.display_name
        }

        const alreadyAdded = editedItem.attendees.find((c) => c.id === currentUserData.id)

        if (alreadyAdded) {
            return
        }

        editedItem.attendees.push(currentUserData)
    }

    private initDefaultAttendeesList = (editedItem, linkedBook, currentUser) => {
        if (linkedBook && linkedBook.books) {
            const books = linkedBook.books
            const key = Object.keys(books)[0]

            if (books[key].attributes.attendees) {
                const users = books[key].attributes.attendees

                editedItem.attendees = Object.keys(users).map((key) => {
                    return {
                        text: users[key].display_name,
                        email: users[key].email,
                        value: users[key].display_name,
                        id: users[key].id
                    }
                })
            } else {
                editedItem.attendees = []
            }
        } else {
            editedItem.attendees = []
        }

        this.addAttendeeCurrentUser(editedItem, currentUser)
    }

    private getExistingAttendeesData = () => {
        //[{text:'John Doe', id : 2001 }, {text : 'Emilia Clarke', id : 2002}]

        const { editedItem, linkedBook } = this.props
        const currentUser = transientStorageManager.currentUser

        if (!editedItem.attendees) {
            this.initDefaultAttendeesList(editedItem, linkedBook, currentUser)
        }

        //We are returning new modified array in here because we need to modify data just in time
        //That midification is pertaining to flip of email / display name
        //This array is only used by control so original array stays unchanged.
        //This way we preserve original display name value
        return [
            ...editedItem.attendees.map((c) => {
                if (c.isInverted) {
                    let newObject = Object.assign({}, c)

                    //If we change object's display name we have to make a copy so original would not be affected.
                    newObject.text = newObject.email
                        ? newObject.email
                        : translate('NO_EMAIL_PERSON_LABEL')
                    return newObject
                }
                return c
            })
        ]
    }

    private renderInlineError = (inlineError: any): JSX.Element | null => {
        const result = inlineError ? (
            <section>
                <InlineError error={inlineError || ''} />
            </section>
        ) : null

        return result
    }

    render() {
        const { editedItem, readonly } = this.props
        if (!editedItem) {
            return null
        }

        const inlineError = this.renderInlineError(this.state.inlineError)
        const attendeesData = this.getExistingAttendeesData()
        return (
            <>
                <InputLabelStyled
                    aria-label={i18n.t('ADD_INVITEES_HINT')}
                    htmlFor="attendees-chip-input">
                    {i18n.t('ADD_INVITEES_HINT')}
                </InputLabelStyled>
                <div
                    data-testid="MinuteManagerModal_AttendeesChipInput"
                    id={'ChipInputFieldContainer'}
                    role="group"
                    aria-describedby="attendees-chip-input">
                    {readonly ? (
                        <ReadOnlyChipArea aria-live="polite">
                            {attendeesData.map((attendee) => {
                                const key = uuid()
                                return (
                                    <UserChip
                                        readonly
                                        key={key}
                                        name={attendee.text}
                                        email={attendee.email}
                                    />
                                )
                            })}
                        </ReadOnlyChipArea>
                    ) : (
                        <ChipInputField
                            className={css`
                                ${Assignees}${AssigneeTextField}
                            `}
                            variant="outlined"
                            options={this.renderAttendeesDataSource()}
                            aria-label={i18n.t('ADD_INVITEES_HINT')}
                            aria-describedby="attendees-chip-input"
                            value={attendeesData}
                            onChange={(options, action) =>
                                makeOnChipChange({
                                    addChip: this.onAttendeesAdd,
                                    deleteChip: this.onAttendeesDelete
                                })({ options, action })
                            }
                        />
                    )}
                    {inlineError}
                </div>
            </>
        )
    }
}

const ReadOnlyChipArea = styled('div')`
    border: 1px solid #949494;
    border-radius: 4px;
    padding: 10px 16px;
    margin-top: 6px;
    min-height: 90px;
    line-height: 20px;
`

//endregion

//region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        editedItem: selectors.editedItem(state.minuteEditorReducer),
        allContacts: selectors.allContacts(state.minuteEditorReducer),
        linkedBook: selectors.linkedBook(state.minuteEditorReducer),
        saveComplete: selectors.saveComplete(state.minuteEditorReducer),
        isSavingExisting: selectors.isSavingExisting(state.minuteEditorReducer),
        readyToSave: selectors.readyToSave(state.minuteEditorReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const { editMinuteItem, loadContacts, validateChanges, saveExistingMinuteItem } = actions
    return {
        actions: bindActionCreators(
            {
                editMinuteItem,
                loadContacts,
                validateChanges,
                saveExistingMinuteItem
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinuteManagerAttendees)

//endregion
