import React, { useState } from 'react'
import { css, ClassNames } from '@emotion/react'

//#region Components

import { translate } from 'components/shared/internationalization'
import EditAssigneesDialog from './EditAssigneesDialog'
import { addKeyPressHandler } from 'common/util/functions'

//#endregion

//#region Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as editorActions } from '../../../../reducers/actionEditorReducer'

import editorSelectors from '../../../../selectors/actionEditorSelectors'
import { WordWrapContainer } from 'components/minutetaker/components/ActionItemDetailsCard/Atoms'
import { MinutesManager_BtnFocusCss } from 'components/layout/page-layout'
//#endregion

//#region Styles
const rootStyle = css`
    cursor: pointer;
    height: 100%;
`
//#endregion

//#region Props
type Props = {
    actions: {
        saveExistingAction: (...args: any[]) => void
        validateSectionAction: (...args: any[]) => void
    }
    allContacts: any
    rowIndex: number
    action: any
}

//#endregion

//#region Implementation

const determineAssigneesText = ({ assignees }) => {
    return assignees && assignees.length > 0
        ? assignees.reduce((acc, current) => {
              return `${acc}${acc ? ',' : ''} ${current.text}`
          }, '')
        : translate('UNASSIGNED')
}

//TODO: Upgrade when taker merged.
function InlineAssigneesEditor(props: Props) {
    const [isEditMode, setIsEditMode] = useState(false)

    function handleEditorActivated() {
        setIsEditMode(true)
    }

    function handleCloseAssigneesEditor() {
        setIsEditMode(false)
    }

    function handleSaveAssignees(assignees: Array<any>) {
        const { action } = props
        action.assignees = assignees
        props.actions.saveExistingAction(action)

        setIsEditMode(false)
    }

    const { rowIndex, action, allContacts } = props

    const actionToEdit = { ...action }
    actionToEdit.assignees = action.assignees ? [...action.assignees] : []

    const assigneesDialog = isEditMode ? (
        <EditAssigneesDialog
            action={actionToEdit}
            allContacts={allContacts}
            onClose={handleCloseAssigneesEditor}
            onSave={handleSaveAssignees}
        />
    ) : null

    return (
        <ClassNames>
            {({ css }) => (
                <div
                    id={`NotesEditor${rowIndex}}`}
                    className={css`
                        ${rootStyle}
                        ${MinutesManager_BtnFocusCss};
                    `}
                    onClick={handleEditorActivated}
                    tabIndex={0}
                    onKeyPress={(e) => (addKeyPressHandler(e) ? handleEditorActivated() : null)}>
                    <WordWrapContainer>{determineAssigneesText(action)}</WordWrapContainer>
                    {assigneesDialog}
                </div>
            )}
        </ClassNames>
    )
}
//#endregion

//#region Export / Redux Bindings
const mapStateToProps = (state, _) => {
    return {
        allContacts: editorSelectors.allContacts(state.actionEditorReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const { saveExistingAction, validateSectionAction } = editorActions

    return {
        actions: bindActionCreators(
            {
                saveExistingAction,
                validateSectionAction
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InlineAssigneesEditor)

//#endregion
