/**
 * This is the Saving Context used to communicate a saving state to multiple components
 */
import constate from 'constate'
import { useState, useCallback } from 'react'

export type ProviderProps = {}

const useSaving = () => {
    const [isSavingState, setIsSavingState] = useState(false)

    const setIsSaving = useCallback((isSaving) => {
        setIsSavingState((state) => (state !== isSaving ? isSaving : state))
    }, [])

    return { isSaving: isSavingState, setIsSaving }
}

// Constate factory returns the useable (Provider, useStateHook, useActionsHook)
const [SavingProvider, useSavingContext] = constate(
    () => useSaving(),
    (value) => {
        return value
    }
)

// Consumer for Class Components
const SavingContextConsumer = ({ children }) => {
    const { isSaving, setIsSaving } = useSavingContext()
    return children({ isSaving, setIsSaving })
}

// Export (Provider, Consumer, useStateHook, useActionsHook)
export { SavingProvider, SavingContextConsumer, useSavingContext }
