import { createSelector, Selector } from 'reselect'
import moment from 'moment'

type DataStateType = {
    newReportData: {
        startDate: string
        endDate: string
        selectedCommittee: MinutesUnnormalized[]
        committeeName: string
        committeeId: string
    }
    attendanceReportResponse: any
    chatMessages: ChatObjects[]
    identityToken: ChatTokenResponse
}

type ControlStateType = {
    preservedFormState: any
    createReportLoading: boolean
    meetingsLoading: boolean
}

const selectorDataState: Selector<any, DataStateType> = (state) => {
    return state.dataState
}

const selectorControlState: Selector<any, ControlStateType> = (state) => {
    return state.controlState
}
const selectorState = (state) => {
    return state
}
const selectors = {
    setNewReportData: createSelector(selectorDataState, (dataState) => dataState.newReportData),
    setCreateReportDataResponse: createSelector(
        selectorDataState,
        (dataState) => dataState.attendanceReportResponse
    ),
    getStartDate: createSelector(
        selectorDataState,
        (dataState) => dataState.newReportData.startDate
    ),
    getChatMessages: createSelector(selectorDataState, (dataState) => dataState.chatMessages),
    getIdentityToken: createSelector(selectorDataState, (dataState) => dataState.identityToken),
    getEndDate: createSelector(selectorDataState, (dataState) => dataState.newReportData.endDate),
    setCreateReportLoading: createSelector(
        selectorControlState,
        (controlState) => controlState.createReportLoading
    ),
    getFormChangeState: createSelector(selectorState, (state) => {
        const dateFormat = 'YYYY-MM-DD'

        const formatReportData = (reportData) => ({
            startDate: moment(reportData.startDate).format(dateFormat),
            endDate: moment(reportData.endDate).format(dateFormat),
            committeeName: reportData.committeeName
        })
        const dataStateReportData = formatReportData(selectorDataState(state).newReportData)
        const controlStateReportData = formatReportData(
            selectorControlState(state).preservedFormState
        )
        return {
            isStateMatched:
                JSON.stringify(dataStateReportData) === JSON.stringify(controlStateReportData),
            isReportGenerated: selectorControlState(state).preservedFormState?.isReportGenerated
        }
    }),
    getMinutesLoadingState: createSelector(selectorControlState, (controlState) => {
        return controlState.meetingsLoading
    })
}

export default selectors
