import { dataState, communicationState, controlState } from '../components/helpers/reducerHelpers'
import dataManager from '../businesslayer/minutesDataManager'
import { transientStorageManager } from '../businesslayer/minutesSessionStore'
import applicationRouter from '../businesslayer/applicationRouter'
import { Reducer } from 'redux'
import validator from '../businesslayer/validation/minuteItemValidator'

import { createAction, handleActions } from 'redux-actions'

import moment from 'moment'
import { getContacts } from 'businesslayer/api/contacts'
import { saveMinutes } from 'businesslayer/api/minutes'
interface ControlState {
    readyToSave?: boolean
    lastSaveFailed?: boolean
    validationTriggeredOnce: boolean
}

interface MeetingDate {
    id: string
    minutesId: string
    date: Date
    startTime: Date
    endTime: Date | null
    timeZoneCode: string
    end_hour: number
    end_minute: number
}

interface Attendee {
    id: string
    text: string
    value: string
    email: string
}

interface DataState {
    editedItem: {
        id: string
        meetingDates: MeetingDate[]
        attendees: Attendee[]
        // ... other properties
    } | null
    allContacts: any[] | null // Change `any[]` to the actual type if known
    linkedBook: any | null // Change `any` to the actual type if known
    linkedBookError: any | null // Change `any` to the actual type if known
}

interface CommunicationState {
    saveComplete: boolean
    isSavingExisting: boolean
}

interface SessionState {
    // Define session state properties as needed
}

interface ReducerState {
    controlState: ControlState
    dataState: DataState
    communicationState: CommunicationState
    sessionState: SessionState
}
type ActionsType = {
    saveNewMinuteItem: any
    saveExistingMinuteItem: any
    createMinuteItem: any
    validateMeetingDate: any
    addMeetingDay: any
    removeMeetingDate: any
    validateSavedMinuteItem: any
    saveComplete: any
    validateChanges: any
    saveNewMinuteItemPending: any
    saveNewMinuteItemFulfilled: any
    saveNewMinuteItemRejected: any
    saveExistingMinuteItemPending: any
    saveExistingMinuteItemFulfilled: any
    saveExistingMinuteItemRejected: any
    loadContacts: any
    loadContactsPending: any
    loadContactsFulfilled: any
    loadContactsRejected: any
    editMinuteItem: any
    resetLinkedItem: any
    setInitialReadyToSave: any
}
export const actions: ActionsType = {
    createMinuteItem: createAction('CREATE_MINUTE_ITEM'),
    validateChanges: createAction('VALIDATE_MEETING_OBJECT_CHANGES'),
    validateMeetingDate: createAction('VALIDATE_MEETING_DATE_CHANGES'),
    addMeetingDay: createAction('ADD_MEETING_DAY'),
    removeMeetingDate: createAction('REMOVE_MEETING_DAY'),
    validateSavedMinuteItem: createAction('VALIDATE_SAVED_MINUTE_ITEM'),

    saveComplete: createAction('SAVE_COMPLETE'),

    saveNewMinuteItem: createAction('SAVE_NEW_MINUTE_ITEM', (payload) => {
        return saveMinutes(payload, false, actions.saveNewMinuteItem.bind(this, payload))
    }),

    saveNewMinuteItemPending: createAction('SAVE_NEW_MINUTE_ITEM_PENDING'),
    saveNewMinuteItemFulfilled: createAction('SAVE_NEW_MINUTE_ITEM_FULFILLED'),
    saveNewMinuteItemRejected: createAction('SAVE_NEW_MINUTE_ITEM_REJECTED'),

    saveExistingMinuteItem: createAction('SAVE_EXISTING_MINUTE_ITEM', (payload) => {
        return saveMinutes(
            payload.editedItem,
            payload.isBackground,
            actions.saveExistingMinuteItem.bind(this, payload)
        )
    }),

    saveExistingMinuteItemPending: createAction('SAVE_EXISTING_MINUTE_ITEM_PENDING'),
    saveExistingMinuteItemFulfilled: createAction('SAVE_EXISTING_MINUTE_ITEM_FULFILLED'),
    saveExistingMinuteItemRejected: createAction('SAVE_EXISTING_MINUTE_ITEM_REJECTED'),

    loadContacts: createAction('LOAD_CONTACTS', (payload = false) => {
        return getContacts(payload, actions.loadContacts.bind(this, payload))
    }),

    loadContactsPending: createAction('LOAD_CONTACTS_PENDING'),
    loadContactsFulfilled: createAction('LOAD_CONTACTS_FULFILLED'),
    loadContactsRejected: createAction('LOAD_CONTACTS_REJECTED'),

    editMinuteItem: createAction('EDIT_MINUTE_ITEM'),
    resetLinkedItem: createAction('RESET_LINKED_MINUTE_ITEM'),
    setInitialReadyToSave: createAction('SET_INITIAL_READY_TO_SAVE')
}

//http://jamesknelson.com/5-types-react-application-state/
const initialState: ReducerState = {
    controlState: {
        readyToSave: false,
        lastSaveFailed: false,
        validationTriggeredOnce: false
    },
    dataState: {
        editedItem: null,
        allContacts: null,
        linkedBook: null,
        linkedBookError: null
    },
    communicationState: {
        saveComplete: false,
        isSavingExisting: false
    },
    sessionState: {}
}

let updatedState = null as any
let editedItem = null as any

const reducer: Reducer<ReducerState> = handleActions<ReducerState>(
    {
        [actions.createMinuteItem]: (state: ReducerState) => {
            updatedState = dataState(state, { editedItem: dataManager.createDefaultMinuteItem() })

            //TODO: async book retrieval
            updatedState = dataState(updatedState, {
                linkedBook: transientStorageManager.createForBook,
                linkedBookError: null
            })

            updatedState = controlState(updatedState, {
                lastSaveFailed: false,
                readyToSave: false,
                validationTriggeredOnce: false
            })

            return communicationState(updatedState, {
                saveComplete: false
            })
        },

        [actions.editMinuteItem]: (state: ReducerState, action) => {
            //For editing we transform JSON api structure back to simple structure we use in the app locally:
            let jsonAPiObject = transientStorageManager.selectedMinuteItem

            if (!jsonAPiObject) {
                if (action.payload) {
                    jsonAPiObject = action.payload
                    transientStorageManager.selectedMinuteItem = jsonAPiObject
                } else {
                    return state
                }
            }

            const hourToDate = (date, hour, minute) => {
                if (!date) {
                    return new Date()
                }
                let dateMoment = moment(date, 'YYYY-MM-DD')
                dateMoment = dateMoment.hour(hour || 0)
                dateMoment = dateMoment.minute(minute || 0)
                return dateMoment.toDate()
            }

            const appObject = { id: jsonAPiObject.id, ...jsonAPiObject.attributes }
            if (appObject.meetingDates) {
                //Transform dates as well
                appObject.meetingDates = appObject.meetingDates.map((date) => {
                    return {
                        id: date.id,
                        minutesId: date.minutes_document_id,
                        date: moment(date.start_date).toDate(),
                        startTime: hourToDate(date.start_date, date.start_hour, date.start_minute),
                        endTime: !!date.end_date
                            ? hourToDate(date.start_date, date.end_hour, date.end_minute)
                            : null,
                        timeZoneCode: date.time_zone_code,
                        end_hour: date.end_hour,
                        end_minute: date.end_minute
                    }
                })

                //text, value, it, email
            }

            if (appObject.attendees) {
                //Transform attendees
                appObject.attendees = appObject.attendees.map((person) => {
                    return {
                        id: person.external_id,
                        text: person.display_name,
                        value: person.external_id || person.display_name,
                        email: person.email
                    }
                })
            }

            //TP335624
            const relations = jsonAPiObject.relationships
            const hasBookRelated = relations && relations.book && relations.book.data

            updatedState = dataState(state, {
                linkedBook: transientStorageManager.linkedBookDetails,
                linkedBookError: hasBookRelated ? transientStorageManager.linkedBookError : null
            })

            updatedState = dataState(updatedState, { editedItem: appObject })

            updatedState = controlState(updatedState, {
                lastSaveFailed: false,
                validationTriggeredOnce: false,
                readyToSave: false
            })

            return communicationState(updatedState, { saveComplete: false })
        },

        [actions.validateChanges]: (state: ReducerState, action) => {
            const targetObject = validator.validateMinuteItem(action.payload)

            updatedState = dataState(state, { editedItem: targetObject })

            return controlState(updatedState, {
                validationTriggeredOnce: true
            })
        },

        [actions.validateSavedMinuteItem]: (state: ReducerState, action) => {
            updatedState = state

            const minuteItem = validator.validateMinuteItem(action.payload)

            const noRootErrors =
                !minuteItem.validationErrors || minuteItem.validationErrors.size === 0

            if (minuteItem.meetingDates) {
                const noChildErrors =
                    minuteItem.meetingDates.filter(
                        (item) => item.validationErrors && item.validationErrors.size > 0
                    ).length === 0

                //Emulation for now
                updatedState = dataState(updatedState, { editedItem: minuteItem })

                if (noChildErrors && noRootErrors) {
                    //
                    return controlState(updatedState, { readyToSave: true })
                }
            } else {
                updatedState = dataState(updatedState, { editedItem: minuteItem })
                //Emulation for now
                if (noRootErrors) {
                    return controlState(updatedState, { readyToSave: true })
                }
            }

            return controlState(updatedState, { readyToSave: false })
        },

        //Handling save results

        [actions.saveNewMinuteItemPending]: (state: ReducerState) => {
            return controlState(state, { lastSaveFailed: false })
        },

        [actions.saveNewMinuteItemRejected]: (state: ReducerState) => {
            updatedState = controlState(state, { readyToSave: false, lastSaveFailed: true })
            return communicationState(updatedState, { saveComplete: true })
        },

        [actions.saveNewMinuteItemFulfilled]: (state: ReducerState, action) => {
            if (action.payload.minutesDocuments) {
                const id = Object.keys(action.payload.minutesDocuments)[0]
                transientStorageManager.selectedMinuteItem = action.payload.minutesDocuments[id]
                applicationRouter.navigateTo(`/taker/${id}`)
            }

            updatedState = controlState(state, { readyToSave: false })
            return communicationState(updatedState, { saveComplete: true })
        },

        [actions.saveExistingMinuteItemPending]: (state: ReducerState) => {
            updatedState = communicationState(state, {
                isSavingExisting: true,
                saveComplete: false
            })
            return controlState(updatedState, { lastSaveFailed: false })
        },

        [actions.saveExistingMinuteItemRejected]: (state: ReducerState) => {
            updatedState = controlState(state, { readyToSave: false, lastSaveFailed: true })
            return communicationState(updatedState, { saveComplete: true, isSavingExisting: false })
        },

        [actions.saveExistingMinuteItemFulfilled]: (state: ReducerState, action) => {
            if (action.payload.minutesDocuments) {
                const id = Object.keys(action.payload.minutesDocuments)[0]
                transientStorageManager.selectedMinuteItem = action.payload.minutesDocuments[id]
            }

            //For existing item edits we dont navigate to taker after saving

            updatedState = controlState(state, { readyToSave: false })
            return communicationState(updatedState, { saveComplete: true, isSavingExisting: false })
        },

        [actions.validateMeetingDate]: (state: ReducerState, action) => {
            updatedState = state
            editedItem = state.dataState.editedItem

            const dateObject = validator.validateMeetingDate(editedItem, action.payload)
            const index = editedItem.meetingDates.indexOf(dateObject)

            editedItem.meetingDates = [
                ...editedItem.meetingDates.slice(0, index),
                dateObject,
                ...editedItem.meetingDates.slice(index + 1)
            ]

            return dataState(updatedState, { editedItem: editedItem })
        },

        [actions.addMeetingDay]: (state: ReducerState) => {
            updatedState = state

            editedItem = state.dataState.editedItem
            editedItem.meetingDates = dataManager.addMeetingDay(editedItem)

            return dataState(updatedState, { editedItem: editedItem })
        },

        [actions.removeMeetingDate]: (state: ReducerState, action) => {
            updatedState = state

            editedItem = state.dataState.editedItem
            editedItem.meetingDates = dataManager.removeMeetingDay(editedItem, action.payload)

            return dataState(updatedState, { editedItem: editedItem })
        },

        [actions.saveComplete]: () => {
            return initialState
        },

        [actions.resetLinkedItem]: (state: ReducerState) => {
            //Once user presses cancel we reset current book for editor and for creator
            //For creator we do in central storage so user would have to use URL again to restore them there.
            transientStorageManager.createForBook = null
            return dataState(state, {
                linkedBook: null,
                linkedBookError: null
            })
        },

        //Handling loading contacts

        [actions.loadContactsFulfilled]: (state: ReducerState, action) => {
            const allContacts = action.payload.contacts ? action.payload.contacts : null
            return dataState(state, { allContacts: allContacts })
        }
    },
    initialState
)

export default reducer
export type ActionEditorStateType = {}
