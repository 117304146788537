import React from 'react'
import { useSortState, useSortActions } from 'components/contexts/actions/SortConstate'
import { SortPopoverButton } from '../../../minutetaker/actionlist/SortPopoverButton/SortPopoverButton'
import UtilityIconBar from '../../../minutetaker/sectionlist/UtilityIconBar'
import styled from '@emotion/styled'
import ToolbarSaveStatus from 'components/shared/Toolbar/ToolbarSaveStatus'

type Props = {
    isToolbarLabelsCollapsed: boolean
    minutesSections: Array<any>
    currentMinuteItem: any
    isExportingMinutes: boolean
    isSavingSection?: boolean
    isIconOnlyMode: boolean
    sortField: ActionListSort
}
/**
 * Toolbar for Actions Page
 *
 * State: Depends on SortConstate
 */
export const ActionsToolbar = (props: Props) => {
    const { minutesSections, currentMinuteItem, isExportingMinutes, isSavingSection } = props

    const separator = <Separator />
    const margin = <Margin />

    const { sortField, sortOrder } = useSortState()
    const { updateSortField, updateSortOrder } = useSortActions()

    return (
        <>
            <SortPopoverButton
                sortOrder={sortOrder}
                sortField={sortField}
                onChangeSortField={updateSortField}
                onChangeSortOrder={updateSortOrder}
            />
            <UtilityIconBar
                minutesSections={minutesSections}
                currentMinuteItem={currentMinuteItem}
                isExportingMinutes={isExportingMinutes}
                isIconOnlyMode={props.isToolbarLabelsCollapsed}
                isSavingSection={isSavingSection}
                withExport
            />
            {separator}
            {margin}
            <ToolbarSaveStatus />
        </>
    )
}

export const Separator = styled('div')`
    height: 70%;
    border-right: 2px solid #e0e0e0;
    margin-right: 10px;
    margin-left: 10px;
`

const Margin = styled('div')`
    margin-right: 10px;
`
