import { Component } from 'react'

import 'url-search-params-polyfill'
type WidthAndHeight = { width: number; height: number }
type Props = {
    onResize: ({ width, height }: WidthAndHeight) => void
}
export default class DomHelper extends Component<Props> {
    onResize = () => {
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight

        if (this.props.onResize) {
            this.props.onResize({ width, height })
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize, false)
        this.onResize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize, false)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.onResize !== this.props.onResize
    }

    render() {
        return null
    }
}

export const triggerClick = (el: HTMLInputElement | null) => {
    if (!el) return

    const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true
    })

    event ? el.dispatchEvent(event) : el.click && el.click()
}

export const getSearchParameter = (location, nameVariations) => {
    if (!location || !location.search) {
        return null
    }

    const params = new URLSearchParams(location.search)

    const value = nameVariations.reduce((acc, val) => {
        return acc || params.get(val)
    }, null)

    return value
}
export const getUUID = (path) => {
    let uuidList = path.split('/')

    uuidList.shift()
    if (uuidList[uuidList.length - 1] === '') {
        uuidList.pop()
    }

    if (uuidList[0] === 'notification' && uuidList.length === 2) {
        return uuidList[uuidList.length - 1]
    }
    return ''
}
export const isIE11 = () => /rv:11.0/i.test(navigator.userAgent)

export const isEdge = () => /Edge\/\d./i.test(navigator.userAgent)

export const isSafari = () =>
    /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)

export const isFirefox = () => /Firefox\//.test(navigator.userAgent)

export const isScrolledIntoView = (el, topCorrection) => {
    const elemTop = el.getBoundingClientRect().top
    const elemBottom = el.getBoundingClientRect().bottom

    // Only completely visible elements return true:
    //var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    const isVisible =
        elemTop < window.innerHeight && elemBottom - (topCorrection ? topCorrection : 0) >= 0
    return isVisible
}

export const refreshScreen = () => {
    setTimeout(() => {
        if (isIE11()) {
            let event = document.createEvent('Event')
            event.initEvent('resize', false, true)
            window.dispatchEvent(event)
        } else {
            window.dispatchEvent(new Event('resize'))
        }
    }, 0)
}
