import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #a4bcc4;
`

type Props = {
    title?: string
    className?: string
}

export const XOnlyIcon = ({ title, className }: Props) => (
    <Svg viewBox="0 0 7.6 7.6" width="1em" height="1em" className={className}>
        <title>{title}</title>
        <defs />
        <path
            className="cls-1"
            d="M7.6 6.6l-1 1-2.8-2.9L.9 7.6l-.9-1 2.8-2.8L0 .9.9 0l2.8 2.8L6.6 0l.9.9-2.8 2.9 2.9 2.8z"
        />
    </Svg>
)

export default XOnlyIcon
