import * as requests from '../networkrequests/settings'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { translate } from 'components/shared/internationalization'
import { executeRequest } from 'businesslayer/request-executor'
import i18n from 'i18next'

import {
    USA_DATE_FORMAT,
    getDateFormat_WorldOrUSA,
    timezoneListWithUniqueCodes
} from 'common/formatters/date'

export const getSettings = () => {
    if (!transientStorageManager.currentTimezone) {
        transientStorageManager.currentTimezone = i18n.t('DEFAULT_TIMEZONE')
        transientStorageManager.dateFormat = USA_DATE_FORMAT
        transientStorageManager.timeFormat = 'hh:mm A'
        transientStorageManager.timeZones = []
        transientStorageManager.isDateInputMaskUS = true
    }

    const onSuccess = (response) => {
        if (response.settings && response.settings.setting) {
            const settings = response.settings.setting.attributes
            const isDateInputMaskUS = settings.usaDateMask !== null ? settings.usaDateMask : true
            const sessionPlatform = getSessionStorageItem('parentPlatform')

            transientStorageManager.currentTimezone = settings.currentTimezone
            transientStorageManager.dateFormatInput = getDateFormat_WorldOrUSA(isDateInputMaskUS)
            transientStorageManager.dateFormat = settings.dateFormat
            transientStorageManager.timeFormat = settings.timeFormat

            /**
             * We are making the switch to displaying the time zone code instead of name/id because with
             * BoardEffect we use the ActiveSupport::TimeZone. They provide non-unique time zone
             * codes with the name (potentially unsafe string) as the key. We store the time zone code
             * in the database which leads to certain time zone codes mapping to multiple time zone names.
             * This makes the UI unpredictable on what will be displayed. Because of this we need to dedupe
             * the time zone list based on the time zone code.
             */
            transientStorageManager.timeZones = timezoneListWithUniqueCodes(settings.timeZones)

            /**
             * The session platform takes priority over what /settings returns.
             * Note: For historical reasons, minutes-api will always return a value for "platform" to us
             * when we call /settings. Unfortunately minutes-api can only distinguish between BoardEffect and RMS.
             * If a more granular platform is passed in via URL parameter at launch of the application
             * we will set that as platform, otherwise fallback on 'boardeffect' or 'boards' from the api.
             */
            transientStorageManager.platform = sessionPlatform || settings.platform

            transientStorageManager.siteName = settings.siteName
            transientStorageManager.clientTitle = settings.clientTitle

            transientStorageManager.isDateInputMaskUS = isDateInputMaskUS

            transientStorageManager.currentUser = settings.currentUser
            transientStorageManager.ai_chat_bot = settings.aiChatBot
            transientStorageManager.logo_blob = settings.logoBlob
            transientStorageManager.parentAppUrl = settings.parentAppUrl
            transientStorageManager.book_summarization_enabled = settings.bookSummarizationEnabled
        }
    }

    const onError = (errorObject) => {
        const httpCode = errorObject.response ? errorObject.response.status : ''
        return translate('LOAD_SETTINGS_ERROR', {
            message: errorObject.message,
            code: httpCode || errorObject.stack
        })
    }

    return executeRequest(requests.getSettings(), onSuccess, onError, { isForeground: false })
}
