import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
    margin-right: 15px;
    position: relative;
`

const DividerIcon = () => {
    return (
        <Svg width="1" height="24" viewBox="0 0 1 24" xmlns="http://www.w3.org/2000/svg">
            <rect width="1" height="24" fill="#6381AB" />
        </Svg>
    )
}

export default DividerIcon
