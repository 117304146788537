import { AccordionSummaryClassKey } from '@material-ui/core/AccordionSummary'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import { atlasTitleSmall } from 'components/atlas-components/typography'

export const MuiAccordionSummary: Partial<StyleRules<AccordionSummaryClassKey, {}>> = {
    root: {
        ...atlasTitleSmall,
        minHeight: '56px',
        paddingLeft: '20px',
        paddingRight: '0px',
        border: `none`,
        '&$expanded': {
            minHeight: '56px'
        }
    },
    content: {
        overflow: 'hidden'
    },
    expandIcon: {
        order: -1,
        margin: '0 20px 0 0',
        '&$expanded': {
            transform: 'rotate(90deg)'
        }
    },
    expanded: {}
}
