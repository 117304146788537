/**
 * common/util/date.ts
 *
 * This file exports functions to help derive data from existing Moment dates
 * (https://momentjs.com/)
 */
import moment from 'moment'
import i18n from 'i18next'

export const determineLastDate = ({ dates }) => {
    if (!dates || !dates.length) {
        return null
    }

    return moment(dates[dates.length - 1].start_date)
}

export const determineFirstDate = ({ dates }) => {
    if (!dates || !dates.length) {
        return null
    }

    return dates[0].start_date
}

export const diff = (aDate, bDate, defaultDate = maxDate) =>
    moment(aDate || defaultDate).diff(moment(bDate || defaultDate), 'minutes')

export const isAfterToday = (date) => moment(date).isSameOrAfter(moment().startOf('day'))
export const isBeforeToday = (date) => moment(date).isBefore(moment().startOf('day'))

const maxDate = new Date(8640000000000000)

// = = = = = = = = =
//  Time
// = = = = = = = = =
const TIME_FORMAT_12_HOUR = 'hh:mm A'
const TIME_FORMAT_24_HOUR = 'HH:mm'

export const isTwelveHourTime = (timeFormat: string): boolean => {
    return timeFormat.includes('hh')
}
export const get12or24HourTimeFormat = (timeFormat: string): string => {
    return isTwelveHourTime(timeFormat) ? TIME_FORMAT_12_HOUR : TIME_FORMAT_24_HOUR
}
export const getTimeMaskText = (timeFormat: string): string => {
    return isTwelveHourTime(timeFormat) ? i18n.t('USA_TIME_MASK') : i18n.t('NON_USA_TIME_MASK')
}
export const getTimePickerMask = (timeFormat: string): string => {
    return isTwelveHourTime(timeFormat) ? '__:__ _M' : '__:__'
}
