/**
 * This is the Committees Context Hook.
 * It is used to manage context state and actions.
 */
import { useEffect, useState, useCallback } from 'react'
import { getCommittees } from 'businesslayer/networkrequests/committees'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { find, compose } from 'rambdax'
import { getCurrentUser } from 'businesslayer/api/contacts'
import { removeSessionStorageItem, setSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { ProviderProps } from './committee-context'

// Committee Context Hook
export function useCommittees({
    initialCommitteeId,
    //isStaticRoleMode = false,
    platform
}: ProviderProps) {
    const [loading, setLoading] = useState(true)
    const [committeeState, setCommitteeState] = useState<CommitteeState>(
        getIntitialCommiteeState(initialCommitteeId)
    )
    const [errorMessage, setErrorMessage] = useState(null)
    // This is to only use the role the user launched minutes with regardless
    // of committee role. I am not a fan of this but perhaps we can find a way
    // to rethink this flow after v2.0
    const [staticRoleState, setStaticRoleState] = useState<StaticRoleState>(
        getInitialStaticRole(platform, false)
    )

    // Useful derivations of state
    const currentCommittee = getCurrentCommittee(committeeState) || intitialNonCommitee
    const committeeAccessRole = calculateAccessRole(committeeState)
    const currentAccessRole = calculateOverallAccessRole(staticRoleState, committeeAccessRole)
    const hasAccessibleCommittees = calculateHasAccessibleCommittees(
        committeeState,
        currentAccessRole
    )

    const adminCommittees = getAdminCommitteesWithState(committeeState)
    const directorCommittees = getDirectorCommittees(committeeState.committees)

    // Fetch Data on Provider Mount
    useEffect(() => {
        let isMounted = true

        //Get committee data from API
        const callCommittee = async () => {
            setLoading(true)
            try {
                const [committeesData, userData]: [any, UserResponse] = await Promise.all([
                    getCommittees(),
                    getCurrentUser()
                ])

                const sortedCommittees = sortCommittees(committeesData)

                /* 
            Merge the committes and user committees to get authorization.
            until we refactor;
            getCommittees: 
                - filters out committees that a user does not have 'files' permissions
                - gives a sort order
            getCurrentUser:
                - gives us the is_admin for each committee they are apart of
                - gives top level admin flag
            */
                const committeesWithPermissions = addCommitteePermissions(
                    sortedCommittees,
                    userData.committees
                )

                const validInitialCommitteeId = getValidInitialCommitteeId(
                    getValidPlatformCommitteesForInitialId(committeesWithPermissions),
                    initialCommitteeId
                )

                const newCommitteeState = {
                    committees: committeesWithPermissions,
                    isTopLevelAdmin: !!userData.top_level_admin,
                    currentCommitteeId: validInitialCommitteeId
                }

                if (isMounted) {
                    // Remove currentCommitteeId from Local Storage
                    // if we don't load any committees for the User
                    if (committeesWithPermissions.length === 0) {
                        removeSessionStorageItem('currentCommitteeId')
                    }

                    setCommitteeState(
                        createSetCurrentCommittee(validInitialCommitteeId)(newCommitteeState)
                    )
                    setStaticRoleState(
                        compose(createSetStaticRole, calculateAccessRole)(newCommitteeState)
                    )
                    setLoading(false)
                }
            } catch (err) {
                setErrorMessage(err)
            }
        }

        callCommittee()

        return () => {
            isMounted = false
        }
    }, [initialCommitteeId, platform])

    // = = = = = = = = = =
    //  Context Actions
    // = = = = = = = = = =
    const setCurrentCommittee = useCallback((committeeId: string) => {
        setCommitteeState(createSetCurrentCommittee(committeeId))
    }, [])

    const setStaticRoleMode = useCallback((isModeActive: boolean) => {
        setStaticRoleState(createSetStaticRoleMode(isModeActive))
    }, [])

    const setStaticRole = useCallback((role: UserRole) => {
        setStaticRoleState(createSetStaticRole(role))
    }, [])

    // Consolidate state and actions for the Constate factory to use
    const committeesState = {
        committees: committeeState.committees,
        currentCommittee,
        currentCommitteeRole: currentAccessRole,
        hasAccessibleCommittees,
        adminCommittees,
        directorCommittees,
        loading
    }

    const committeesActions = { setCurrentCommittee, setStaticRoleMode, setStaticRole }

    return { committeesState, committeesActions, errorMessage }
}

// = = = = = = = = = =
//  Functions
// = = = = = = = = = =

/* * * Getters * * */
const getCommitteeById = (committees: Committee[], committeeId: string): Committee | undefined => {
    return !!committeeId ? committees.find((c) => +c.id === +committeeId) : undefined
}

const getCurrentCommittee = (state: CommitteeState): Committee | undefined => {
    return state.committees.find((c) => +c.id === +state.currentCommitteeId)
}

const getAdminCommittees = (committees: Committee[], isTopLevelAdmin) => {
    return isTopLevelAdmin ? committees : committees.filter((c) => !!c.is_admin)
}
/**
 * call getAdminCommittees() using the State object instead
 */
const getAdminCommitteesWithState = (state: CommitteeState) => {
    const { committees, isTopLevelAdmin } = state
    return getAdminCommittees(committees, isTopLevelAdmin)
}

const getDirectorCommittees = (committees: Committee[]) => {
    return committees.filter((c) => !c.is_admin)
}

/**
 * Get the list of committees to use for calculating initialCommitteeId based on platform
 * boardswebadmin:      only adminCommittees
 * default:             all Committees
 *
 * Will not need when a single manager page is implemented
 */
const getValidPlatformCommitteesForInitialId = (committees: Committee[]) => {
    //return platform === 'boardswebadmin' ? getAdminCommittees(committees, false) : committees
    return committees;
}

const getValidInitialCommitteeId = (committees: Committee[], testCommitteeId: string) => {
    if (committees.length === 0) return ''
    const defaultCommitteeId = committees[0].id
    const isValidCommitteeId = !!getCommitteeById(committees, testCommitteeId)

    return isValidCommitteeId ? testCommitteeId : defaultCommitteeId
}

const calculateAccessRole = (committeeState: CommitteeState): UserRole => {
    const { isTopLevelAdmin, currentCommitteeId } = committeeState

    if (isTopLevelAdmin) return 'ADMIN'

    const curentCommittee = getCurrentCommittee(committeeState)
    return currentCommitteeId !== '' && !!curentCommittee
        ? !!curentCommittee.is_admin
            ? 'ADMIN'
            : 'DIRECTOR'
        : 'NONE'
}

const calculateHasAccessibleCommittees = (
    committeeState: CommitteeState,
    currentAccessRole: UserRole
) => {
    return currentAccessRole === 'ADMIN'
        ? getAdminCommitteesWithState(committeeState).length > 0
        : currentAccessRole === 'DIRECTOR'
        ? committeeState.committees.length > 0
        : false
}

const calculateOverallAccessRole = (
    staticRoleState: StaticRoleState,
    committeeAccessRole: UserRole
): UserRole => {
    return staticRoleState.isStaticRoleMode ? staticRoleState.staticRole : committeeAccessRole
}

/* * * Create Actions * * */

/**
 * Returns a Function that returns a CommitteeState with an updated currentCommitteeId
 * and keeps local storage in sync with the change.
 */
const createSetCurrentCommittee = (committeeId: string) => (state: CommitteeState) => {
    // If no committees do not return new state
    if (state.committees.length === 0) return state

    const foundCommittee = getCommitteeById(state.committees, committeeId)
    if (!!foundCommittee) {
        // Refactor out the need for this
        // @ts-ignore: committeeName was declared without ts and is implicitly type null
        transientStorageManager.committeeName = foundCommittee.name
        setSessionStorageItem('currentCommitteeId', foundCommittee.id)

        return { ...state, currentCommitteeId: foundCommittee.id }
    }

    return state
}

/**
 * Returns a Function that returns a StaticRoleState with an updated staticRole
 * based on the parameter given if staticRole is not yet set
 */
const createSetStaticRole = (newStaticRole: UserRole) => (
    state: StaticRoleState
): StaticRoleState => {
    return state.staticRole === 'NONE' ? { ...state, staticRole: newStaticRole } : state
}

/**
 * Returns a Function that returns a StaticRoleState with an updated isStaticRoleMode
 * based on the parameter given
 */
const createSetStaticRoleMode = (isStaticRoleMode: boolean) => (
    state: StaticRoleState
): StaticRoleState => {
    return { ...state, isStaticRoleMode }
}

/* * * Helpers * * */

/**
 * Returns a sorted Committee array based on a sorted array of committee IDs
 */
const sortCommittees = (committeesData: { sort: string[]; data: CommitteeData[] }): Committee[] => {
    return committeesData.sort.reduce((sorted: Committee[], committeeId: string) => {
        const item = committeesData.data.find((c: CommitteeData) => c.id === committeeId)
        return !!item ? [...sorted, { id: item.id, ...item.attributes }] : sorted
    }, [])
}

/**
 * Returns all of the authorizedCommittees that are also in the committeesState
 */
const addCommitteePermissions = (sourceCommittees: Committee[], findInCommittees: Committee[]) => {
    if (sourceCommittees.length === 0) return findInCommittees
    if (findInCommittees.length === 0) return sourceCommittees

    return sourceCommittees.map((committee) => {
        const userCommittee = find((c) => +c.id === +committee.id, findInCommittees)
        return { ...committee, is_admin: !!userCommittee && userCommittee.is_admin }
    })
}

/* Default States */

/**
 * Returns an initial CommitteeState based on a given committeeId
 */
const getIntitialCommiteeState = (initialCommitteeId: string): CommitteeState => ({
    committees: [],
    currentCommitteeId: initialCommitteeId,
    documentCount: 0,
    isTopLevelAdmin: false
})
/**
 * For BoardsWeb we decide a static UserRole based on platform of
 * 'boardswebadmin' | 'boardswebdirector'
 */
const getInitialStaticRole = (platform: Platform, isStaticRoleMode: boolean): StaticRoleState => {
    const staticRole =
        platform === 'boardswebadmin'
            ? 'ADMIN'
            : platform === 'boardswebdirector'
            ? 'DIRECTOR'
            : 'NONE'
    return {
        isStaticRoleMode,
        staticRole
    }
}

const intitialNonCommitee: Committee = {
    id: '',
    name: '',
    is_admin: false
}

type CommitteeState = {
    committees: Array<Committee>
    currentCommitteeId: string
    documentCount?: number
    isTopLevelAdmin: boolean
}
type StaticRoleState = {
    isStaticRoleMode: boolean
    staticRole: UserRole
}

type UserResponse = {
    id: string | number
    top_level_admin: boolean
    committees: Committee[]
}
