import React, { useCallback, useEffect, useState } from 'react'

import { withRouter } from 'react-router-dom'

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import { IconFailure } from '../../assets/images'
import { XOnlyIcon } from 'assets/icons/XOnlyIcon'
import { useHistory } from 'react-router'
//================ Redux ====================================
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../reducers/applicationReducer'

import selectors from '../../selectors/applicationSelectors'
import { css, ClassNames } from '@emotion/react'
import {
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    ActionButton,
    Button,
    NewDialogTitle,
    IconButton
} from './StyledComponents'
import {
    ColorBaseRichBlack,
    ColorbaseSteelBlue,
    FontWeightLightBold,
    FontWeightRegular
} from 'assets/styles/variables'
import {
    AtlasButtonCss,
    StyledAtlasButtonOld
} from 'components/minutetaker/components/ActionItemEditDialog/ActionItemEditDialog'
import { AtlasIcon } from 'web-components/atlas-icon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CircularProgressLoader } from 'components/global-progress-modal/global-progress-modal'
import { Box } from '@material-ui/core'
import { RestrictedError } from 'components/chip-input/types'
//#region Props

type Props = {
    actions: {
        toggleProgress: (...args: any[]) => void
        cancelBlockingOperation: (...args: any[]) => void
        resetLastError: (...args: any[]) => void
        loadPlatformInfo: (...args: any[]) => void
        [x: string]: (...args: any[]) => void
        setRestrictedError: (...args: any[]) => void
    }
    controlState: {
        title: string
        message: string
        isVisible: boolean
        retryAction: () => void | null
        hideActions?: boolean
    }
    isFirstLoad?: boolean
    lastError: string
    restrictedError: RestrictedError
}

//#endregion

//#region Implementation

function FullscreenProgress(props: Props) {
    const [showingRealError, setShowingRealError] = useState(false)
    const history = useHistory()
    const isRestrictedError = !!props.restrictedError.error
    const onCancel = () => {
        props.actions.setRestrictedError({
            error: '',
            assistance: ''
        })
        props.actions.toggleProgress(false, null, null)
        props.actions.cancelBlockingOperation()

        setShowingRealError(false)
        if (isRestrictedError) {
            history.push(`/`)
        }
    }

    const onRetry = () => {
        if (props.controlState.retryAction) {
            props.actions.resetLastError()
            props.controlState.retryAction()
            setShowingRealError(false)
        } else {
            onCancel()
        }
    }

    const onErrorDoubleClick = () => {
        setShowingRealError(!showingRealError)
    }

    const renderDialogTitle = () => {
        if (props.isFirstLoad) {
            return (
                <ClassNames>
                    {({ css }) => (
                        <NewDialogTitle
                            className={css`
                                ${firstLoadTitleCss};
                            `}>
                            <Button key="cancel-btn" onClick={onCancel}>
                                <XOnlyIcon />
                            </Button>
                        </NewDialogTitle>
                    )}
                </ClassNames>
            )
        } else if (props.lastError) {
            return (
                <NewDialogTitle className={'center'}>
                    {isRestrictedError ? (
                        i18n.t('REVIEW_ACCESS_REMOVED')
                    ) : (
                        <IconFailure className={errorIconStyle} />
                    )}
                </NewDialogTitle>
            )
        } else {
            return (
                <ClassNames>
                    {({ css }) => (
                        <NewDialogTitle
                            className={css`
                                min-height: ${dialogTitle === i18n.t('GLOBAL_PROGRESS_ERROR')
                                    ? '84px'
                                    : dialogTitle === i18n.t('GLOBAL_PROGRESS_TITLE')
                                    ? '64px'
                                    : ''};
                                .MuiTypography-h6 {
                                    padding: 0px 0px 0px 0px;
                                    justify-content: flex-end;
                                    ${dialogTitleCss}
                                }
                            `}>
                            {dialogTitle === i18n.t('GLOBAL_PROGRESS_ERROR') ? dialogTitle : ''}
                            {!props.controlState?.hideActions && (
                                <IconButton
                                    tabIndex={0}
                                    disableFocusRipple
                                    disableRipple
                                    className={css`
                                        ${AtlasButtonCss}
                                    `}
                                    onClick={onCancel}>
                                    <AtlasIcon
                                        name="close"
                                        size={24}
                                        data-testid="AtlasModal_CloseIcon"
                                    />
                                </IconButton>
                            )}
                        </NewDialogTitle>
                    )}
                </ClassNames>
            )
        }
    }

    let actions: JSX.Element[] = []

    if (!props.isFirstLoad && props.controlState?.isVisible) {
        const okButton = (
            <ClassNames key={actions.length}>
                {({ css }) => (
                    <StyledAtlasButtonOld
                        className={css`
                            span {
                                display: flex;
                                justify-content: center;
                            }
                        `}
                        key="cancel-btn"
                        disableFocusRipple
                        onClick={onCancel}>
                        {dialogTitle === i18n.t('GLOBAL_PROGRESS_TITLE')
                            ? translate('CANCEL')
                            : isRestrictedError
                            ? i18n.t('BACK_TO_MINUTES_MANAGER')
                            : translate('CLOSE')}
                    </StyledAtlasButtonOld>
                )}
            </ClassNames>
        )
        actions.push(okButton)
    }
    //As of 1.3 we now need to support cases where retry button needs to be hidden.
    //null action will be indicator for that.
    if (props.lastError && props.controlState.retryAction && props.controlState.retryAction()) {
        const retryButton = (
            <ActionButton key="retry-btn" onClick={onRetry}>
                {translate('RETRY')}
            </ActionButton>
        )

        actions.push(retryButton)
    }

    const { isVisible } = props.controlState
    const { setRestrictedError } = props.actions
    useEffect(() => {
        if (!isVisible) {
            setRestrictedError({
                error: '',
                assistance: ''
            })
        }
    }, [isVisible, setRestrictedError])
    let dialogTitle = i18n.t('GLOBAL_PROGRESS_TITLE')
    let dialogMessage: string | JSX.Element = i18n.t('GLOBAL_PROGRESS_TITLE_DEFAULT_WORDING')
    const checkRestrictedError = useCallback((restrictedError) => {
        return (
            <ClassNames>
                {({ css }) => (
                    <Box
                        className={css`
                            ${restrictedCss}
                        `}>
                        <Box>{restrictedError.error}</Box>
                        <Box>{restrictedError.assistance}</Box>
                    </Box>
                )}
            </ClassNames>
        )
    }, [])
    if (props.lastError) {
        dialogTitle = i18n.t('GLOBAL_PROGRESS_ERROR')
        dialogMessage = showingRealError
            ? props.lastError
            : isRestrictedError
            ? checkRestrictedError(props.restrictedError)
            : i18n.t('GLOBAL_PROGRESS_ERROR_MESSAGE')
    }

    if (props.isFirstLoad) {
        dialogMessage = (
            <ClassNames>
                {({ css }) => (
                    <CircularProgress
                        size={90}
                        thickness={4}
                        classes={{
                            colorPrimary: css`
                                color: #27aad4;
                            `
                        }}
                        color="primary"
                    />
                )}
            </ClassNames>
        )
        dialogTitle = ''
    }

    const zIndex = 1501

    const dialogCss = css`
        z-index: ${zIndex};
        div div {
            max-width: 440px;
            max-height: 100%;
        }
    `

    const useStyles = makeStyles({
        root: {
            flexGrow: 1
        }
    })

    const classes = useStyles()

    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    className={css`
                        ${dialogCss}
                    `}
                    open={isVisible}>
                    {renderDialogTitle()}
                    <DialogContent
                        className={css`
                            ${dialogMessageCss}
                            overflow-y: hidden;
                            ${dialogTitle === i18n.t('GLOBAL_PROGRESS_ERROR')
                                ? `
                            padding: 24px 24px;
                            margin-top: 0px;
                          `
                                : ''};
                        `}>
                        {dialogTitle === i18n.t('GLOBAL_PROGRESS_TITLE') ? (
                            <section
                                className={css`
                                    ${dialogBodyCss}${classes.root}
                                `}>
                                <CircularProgressLoader />
                                <span
                                    className={css`
                                        ${GlobalProgressTitleCss}
                                    `}>
                                    {dialogTitle}
                                </span>
                                <span
                                    className={css`
                                        ${GlobalprogressDefaultWordingCss} ${errorMessage}
                                    `}
                                    onDoubleClick={onErrorDoubleClick}>
                                    {dialogMessage}
                                </span>
                            </section>
                        ) : (
                            <span onDoubleClick={onErrorDoubleClick}>{dialogMessage}</span>
                        )}
                    </DialogContent>
                    {!props.controlState?.hideActions && props.controlState?.isVisible && (
                        <DialogActions>{actions}</DialogActions>
                    )}
                </Dialog>
            )}
        </ClassNames>
    )
}

//#endregion

//#region Styles

export const dialogBodyCss = css`
    color: #2a455a;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    flex-direction: column;
    text-align: center;
    div > div {
        margin-top: 44px;
    }
`

const errorIconStyle = css`
    position: relative;
    top: inherit;
    right: inherit;
    transform: none;
    padding: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 62px;
    height: 62px;
`

export const errorMessage = css`
    font-size: large;
    max-width: 80%;
`
const restrictedCss = css`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`

const firstLoadTitleCss = css`
    background-color: white;
    display: flex;
    justify-content: flex-end;
`

export const GlobalprogressDefaultWordingCss = css`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: ${FontWeightRegular};
    line-height: 20px;
    color: ${ColorBaseRichBlack};
    margin-bottom: 46px;
    margin-top: 8px;
`

export const GlobalProgressTitleCss = css`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: ${FontWeightLightBold};
    font-size: 16px;
    line-height: 20px;
    color: ${ColorbaseSteelBlue};
    margin-top: 8px;
`
const dialogTitleCss = css`
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-style: normal;
    font-weight: ${FontWeightLightBold};
    line-height: 26px;
`
const dialogMessageCss = css`
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: ${FontWeightRegular};
    line-height: 20px;
`

//#endregion

//================ Export / Redux Bindings ==================

//Note: we listen to data changes just so dialog could auto resize itself
//in case more dates added and a need to have scroll is added.
const mapStateToProps = (state, _) => {
    return {
        lastError: selectors.lastError(state.applicationReducer),
        controlState: selectors.fullscreenProgressData(state.applicationReducer),
        restrictedError: selectors.restrictedError(state.applicationReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FullscreenProgress))
