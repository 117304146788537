import { geteditProperties } from 'businesslayer/api/editProperties'
import { getMinutesDocuments } from 'businesslayer/api/minutesDocuments'
import { useCallback, useEffect, useState } from 'react'
import { MINUTES_EDIT_OPTIONS } from 'components/minutetaker/components/InvitationView/types'

export const useEditModal = (documentId, committeeId, editMode) => {
    const [editModalData, setEditModalData] = useState<any>({})
    const [editMinutesDetails, setEditMinutesDetails ] = useState<boolean>(false)

    const fetchMinutesDocuments = useCallback(async () => {
        setEditMinutesDetails(true)
        try {
            const data = await geteditProperties(
                documentId,
                committeeId,
                getMinutesDocuments.bind(null, documentId, committeeId)
            )
            const obj = {
                committee: {
                    ...data.minutesDocuments[documentId].attributes,
                    id: data.minutesDocuments[documentId].attributes.committeeId,
                    name: data.minutesDocuments[documentId].attributes.committeeName
                }
            }

            setEditModalData({ ...data.minutesDocuments[documentId].attributes, ...obj })
        } catch (error) {
            console.log(error)
        }
        setEditMinutesDetails(false)
    }, [documentId, committeeId])
    useEffect(() => {
        if (editMode === MINUTES_EDIT_OPTIONS.EDIT || editMode === MINUTES_EDIT_OPTIONS.DUPLICATE) {
            fetchMinutesDocuments()
        }
    }, [fetchMinutesDocuments, editMode])

    return { editModalData, editMinutesDetails }
}
