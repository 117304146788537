import React from 'react'

//#region Components Material

//#endregion

//#region Components

import EditLabel from '../../../shared/EditLabel'
import i18n from 'i18next'
import { isIE11 } from '../../../helpers/DomHelper'
//#endregion

//#region Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as editorActions } from '../../../../reducers/actionEditorReducer'
import { actions as takerActions } from '../../../../reducers/minuteTakerReducer'
import { actions as actionEditorReducer } from 'reducers/actionEditorReducer'

//#endregion

//#region Styles

//#endregion

//#region Props
type Props = {
    actions: {
        saveExistingAction: (...args: any[]) => void
        // editContentAction: (...args: any[]) => void
        saveSection: (...args: any[]) => void
    }
    rowIndex: number
    action: any
}

//#endregion

//#region Implementation

function InlineActionTextEditor(props: Props) {
    const saveActionText = (action: any, newText: string | null) => {
        if (!newText) {
            return
        }

        action.text = newText
        const newAction = Object.assign({}, action)
        props.actions.saveExistingAction(newAction)
        // props.actions.editContentAction(newAction)
    }

    return (
        <EditLabel
            disableEditor={isIE11()}
            maxLength={10000}
            emptyTextError={i18n.t('INVALID_ACTION_TEXT')}
            text={props.action.text}
            onSave={(c) => saveActionText(props.action, c)}
            id={`ActionTextEditor${props.rowIndex}`}
        />
    )
}

//#endregion

//#region Export / Redux Bindings

const mapDispatchToProps = (dispatch) => {
    const { saveExistingAction, saveSection } = {
        ...takerActions,
        ...editorActions,
        ...actionEditorReducer
    }

    return {
        actions: bindActionCreators(
            {
                saveExistingAction,
                saveSection
            },
            dispatch
        )
    }
}

export default connect(
    null,
    mapDispatchToProps
)(InlineActionTextEditor)

//#endregion
