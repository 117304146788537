import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@minutes/editor'
import { Editor as DecoupledEditorType } from '@ckeditor/ckeditor5-core'

type EditorProps = {
    placeholder?: string
    data?: string
    runtimeConfig?: Record<string, any>
    onAutosave?: (editor: any) => void
    onReady?: (editor: any) => void
    onChange?: (event: any, editor: any) => void
    onBlur?: (editor: any) => void
    onFocus?: (editor: any, focusEditor: any) => void
    handleError?: (error: any) => void
    getAiSupport?: (error: any) => void
}

export function Editor({
    data = '',
    placeholder,
    onAutosave,
    runtimeConfig = {},
    getAiSupport,
    ...rest
}: EditorProps) {
    const [editor, setEditor] = useState<DecoupledEditorType | null>(null)

    function onInit(editor) {
        setEditor(editor)

        rest.onReady && rest.onReady(editor)

        if (getAiSupport) {
            editor.getAiSupport = getAiSupport
        }

        editor.ui.focusTracker.on('change:isFocused', (_evt, _name, isFocused) => {
            const toolbarElement = editor.ui.view.toolbar.element
            if (isFocused) {
                toolbarElement.style.position = 'sticky'
            } else {
                toolbarElement.style.position = 'static'
            }
        })
    }

    function onFocus(focusEditor) {
        rest.onFocus && rest.onFocus(editor, focusEditor)
    }

    const config = {
        placeholder,
        autosave: { save: onAutosave, waitingTime: 200 },
        ...runtimeConfig
    }
    if (getAiSupport) {
        (config as any).isAISupported = true
    }
    return (
        <CKEditor
            editor={DecoupledEditor.DecoupledEditor}
            data={data || ''}
            config={config}
            onReady={onInit}
            onFocus={onFocus}
            onChange={rest.onChange}
            onBlur={rest.onBlur}
        />
    )
}

export function Realtime({
    data = '',
    placeholder,
    onAutosave,
    runtimeConfig = {},
    getAiSupport,
    ...rest
}: EditorProps) {
    const [editor, setEditor] = useState(undefined)

    function onInit(editor) {
        setEditor(editor)
        rest.onReady && rest.onReady(editor)
        
        if (getAiSupport) {
            editor.getAiSupport = getAiSupport
        }
        
        editor.ui.focusTracker.on('change:isFocused', (_evt, _name, isFocused) => {
            const toolbarElement = editor.ui.view.toolbar.element
            if (isFocused) {
                toolbarElement.style.position = 'sticky'
            } else {
                toolbarElement.style.position = 'static'
            }
        })
    }

    function onFocus(focusEditor) {
        rest.onFocus && rest.onFocus(editor, focusEditor)
    }

    const config = {
        placeholder,
        autosave: { save: onAutosave, waitingTime: 200 },
        ...runtimeConfig
    }
    if (getAiSupport) {
        (config as any).isAISupported = true
    }
    return (
        <CKEditor
            editor={DecoupledEditor.DecoupledEditorRealtime}
            data={data || ''}
            config={config}
            onReady={onInit}
            onFocus={onFocus}
            onChange={rest.onChange}
            onBlur={rest.onBlur}
            onError={rest.handleError}
        />
    )
}
