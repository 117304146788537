/**
 * Design constants for the App
 * Hopefully we wont need these as much after a ui refactor
 * or Atlas Implementation.
 */

export const DocumentDetailsLayout = {
    sidebarNav: {
        width: 64
    },
    header: {
        height: 64
    },
    toolbar: {
        height: 56
    },
    document: {
        minWidth: 400,
        maxWidth: 800
    },
    reviewSidebar: {
        minWidth: 300,
        maxWidth: 400
    }
}
