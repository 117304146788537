import React from 'react'

const PencilIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8146 1.55842L4.53909 13.8539C4.47773 13.9153 4.42985 13.9889 4.39851 14.0699L1.41714 21.7744C1.22195 22.2789 1.71614 22.7763 2.2218 22.5843L9.94625 19.6525C10.029 19.6211 10.1042 19.5724 10.1668 19.5098L22.4423 7.21431C22.6855 6.97071 22.686 6.57632 22.4433 6.33216L17.7003 1.55944C17.4563 1.31392 17.0592 1.31346 16.8146 1.55842ZM17.2559 2.88562L21.1179 6.77171L19.8187 8.07295L15.9447 4.19894L17.2559 2.88562ZM18.9356 8.95755L15.0616 5.08354L5.51674 14.6438L3.08878 20.9183L9.37709 18.5315L18.9356 8.95755Z"
                fill="#455D82"
            />
        </svg>
    )
}

export default PencilIcon
