import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
`

type Props = {
    title?: string
    className?: string
}
const CarrotUpIcon = ({ title, className }: Props) => (
    <Svg width="1em" height="1em" viewBox="0 0 10 7" className={className}>
        <title>{title}</title>
        <path
            d="M5.027.566c.158 0 .308.066.415.183L9.5 5.18a.562.562 0 1 1-.83.759L5.048 1.983l-3.645 4.38a.564.564 0 0 1-.865-.72L4.595.769a.561.561 0 0 1 .419-.202h.013z"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </Svg>
)

export default CarrotUpIcon
