import { useContext } from 'react'
import { FeatureContext } from './FeatureContext'
import { Flags } from '../../../common/constants/flags'

type InjectedFlagProps = {
    isFeatureEnabled: boolean
}

type FlagKeys = keyof Flags

type FlagProps = {
    name: FlagKeys
    children(props: InjectedFlagProps): JSX.Element | null
}

export function useFlag(name: FlagKeys) {
    const context = useContext(FeatureContext)

    return !!context[name]
}

export function Flag(props: FlagProps) {
    const isFeatureEnabled = useFlag(props.name)

    return props.children({ isFeatureEnabled })
}

export default Flag
