import { getJSON } from 'common/util/request'

export const minutesDocumentsList = (
    page = 1,
    status = '',
    committeeId,
    sortBy = '',
    order = ''
) => {
    const cId = committeeId === ' ' ? [] : [committeeId]

    const serializedArray = cId.map((item) => (item ? encodeURIComponent(item) : ''))?.join(',')

    const result = getJSON(
        `minutes_documents/minutes_list?page=${page}&status=${status}&committee_ids=${serializedArray}&sort_by=${sortBy}&sort_order=${order}&per_page=${20}`,
        { shouldNormalize: false }
    )

    return result
}
