import { saveFile } from 'common/util/file'
import { exportMinutes } from 'businesslayer/api/minutes'
import { exportActions } from 'businesslayer/api/actions'
import { OptionType } from './minutes-card-popover-button'

/**
 * MinutesCardPopoverDispatcher
 * Dispatches a function based on the Option selected
 * from the Minutes Card Popover
 */
export const MinutesCardPopoverDispatcher = ({
    history,
    handleOpenMinutesModal,
    handleOpenExportModal,
    handleOpenDeleteMinutesConfirmation
}) => {
    // = = = = = = = = =
    // Actions
    // = = = = = = = = =
    const editMinutes = ({ minutesItem }) => {
        handleOpenMinutesModal('EDIT', minutesItem)
    }
    const duplicateMinutes = ({ minutesItem }) => {
        handleOpenMinutesModal('DUPLICATE', minutesItem)
    }
    const viewActions = ({ minutesItem }) => {
        history.push({ pathname: `/taker/${minutesItem.id}`, state: { from: '/' } })
    }
    const makeExportMinutes = ({ excludeActions }: { excludeActions: boolean }) => ({
        minutesItem,
        committeeId
    }) => {
        try {
            exportMinutes(
                committeeId,
                minutesItem.id,
                excludeActions,
                exportMinutes.bind(null, committeeId, minutesItem.id, excludeActions)
            ).then((response) => {
                handleDownloadFile({ response, responseType: 'docx' })
            })
        } catch (error) {}
    }
    const handleExportActions = ({ minutesItem, committeeId }) => {
        const sortField = { name: 'section', ascending: true }
        try {
            exportActions(
                committeeId,
                minutesItem.id,
                sortField,
                exportActions.bind(null, committeeId, minutesItem.id, sortField)
            ).then((response) => {
                handleDownloadFile({ response, responseType: 'xlsx' })
            })
        } catch (error) {}
    }
    const exportConsolidatedActions = () => {
        handleOpenExportModal()
    }
    const handleDeleteMinutes = ({ minutesItem, committeeId }) => {
        handleOpenDeleteMinutesConfirmation({ minutesId: minutesItem.id, committeeId })
    }

    const handleDownloadFile = ({ response, responseType }) => {
        // setIsSaving(true)
        saveFile({ response, responseType })
        // setIsSaving(false)
    }

    // = = = = = = = = =
    // Dispatcher
    // = = = = = = = = =
    const dispatchOption: Record<
        OptionType,
        ({ minutesItem, committeeId }: { minutesItem: any; committeeId: string }) => any
    > = {
        EDIT: editMinutes,
        ACTIONS: viewActions,
        DUPLICATE: duplicateMinutes,
        EXPORT_MINUTES: makeExportMinutes({ excludeActions: true }),
        EXPORT_MINUTES_WITH_ACTIONS: makeExportMinutes({ excludeActions: false }),
        EXPORT_ACTIONS: handleExportActions,
        EXPORT_CONSOLIDATED_ACTIONS: exportConsolidatedActions,
        DELETE: handleDeleteMinutes
    }
    const handleClickMinutesOptions = (
        optionValue: OptionType,
        minutesItem: any,
        committeeId: string
    ) => {
        const optionAction = dispatchOption[optionValue]
        optionAction({ minutesItem, committeeId })
    }

    return handleClickMinutesOptions
}
