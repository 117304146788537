import { NamespacesConsumer } from 'react-i18next'
import { TFunction } from 'i18next'
import React from 'react'

type Props = { id: string; [x: string]: any }

const Trans = ({ id, ...rest }: Props) => {
    return <NamespacesConsumer>{(t: TFunction) => t(id, rest)}</NamespacesConsumer>
}

export const translate = (id, opts?) => (
    <NamespacesConsumer>{(t: TFunction) => t(id, opts)}</NamespacesConsumer>
)

export default Trans
