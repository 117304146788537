/** @jsx jsx */
import { useState, useEffect } from 'react'

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import { License } from './license'
import { jsx, css } from '@emotion/react'
import { getApiVersion } from 'businesslayer/networkrequests'
import { AtlasModal } from 'components/atlas-components/atlas-modal/atlas-modal'
import { AtlasButtonOld } from 'components/atlas-material/button'
import { DialogContent } from '@material-ui/core'

const DEFAULT_VERSION = '0.0.0'

export const About = ({ onClose, isOpen = false }) => {
    const [apiVersion, setApiVersion] = useState(DEFAULT_VERSION)

    useEffect(() => {
        getApiVersion().then((result) => {
            setApiVersion(result.Version || DEFAULT_VERSION)
        })
    }, [])

    const dialogTitle = translate('ABOUT_MINUTES')

    let versionLabel = ''
    const apiVersionLabel = translate('API_VERSION_LABEL', { apiVersion: apiVersion })

    if (process.env.REACT_APP_UI_VERSION) {
        versionLabel = i18n.t('UI_VERSION_LABEL', { uiVersion: process.env.REACT_APP_UI_VERSION })
    }

    return (
        <AtlasModal
            open={isOpen}
            modalTitle={dialogTitle}
            actions={
                <AtlasButtonOld
                    key="closeButton"
                    dataTestId="AboutModal_CloseBtn"
                    onClick={onClose}>
                    {i18n.t('CLOSE')}
                </AtlasButtonOld>
            }
            onClose={onClose}>
            <DialogContent>
                <div css={versionLabelStyle}>
                    {versionLabel}
                    {apiVersionLabel}
                </div>
                <License />
            </DialogContent>
        </AtlasModal>
    )
}

const versionLabelStyle = css`
    margin: 10px 0px;
    font-size: 16px;
    color: #8a92a1;
    width: 100%;
`
