import React, { FC } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { DialogTitleProps } from '@material-ui/core/DialogTitle'
import DialogActions, { DialogActionsProps } from '@material-ui/core/DialogActions'
import styled from '@emotion/styled'
import { AtlasIcon } from 'web-components/atlas-icon'
import { ColorBaseSkyBlue } from 'assets/styles/variables'
import { ClassNames } from '@emotion/react'
import { NewDialogTitle } from 'components/shared/StyledComponents'
type AtlasModalProps = {
    modalTitle?: string | JSX.Element
    actions?: JSX.Element | JSX.Element[]
} & DialogProps
/**
 * Atlas Modal Wrapper
 * If title prop is included it will render the title component
 * If actions prop is included it will render the actions component
 * Otherwise you can compose it yourself
 */
const useStyles = makeStyles({
    root: {
        height: '40px',
        width: '40px',
        '&:focus': {
            border: `2px solid ${ColorBaseSkyBlue}`,
            borderRadius: '4px'
        }
    }
})
export const AtlasModal: FC<AtlasModalProps> = ({ modalTitle, actions, ...props }) => {
    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    className={css`
                        .MuiDialog-paperWidthMd-170 {
                            max-width: 1000px;
                        }
                    `}
                    {...props}>
                    {!!modalTitle && (
                        <AtlasModalTitle onClose={props.onClose}>{modalTitle}</AtlasModalTitle>
                    )}
                    {props.children}
                    {!!actions && <AtlasModalActions>{actions}</AtlasModalActions>}
                </Dialog>
            )}
        </ClassNames>
    )
}

type AtlasModalTitleProps = {
    onClose: any
    center?: boolean
    controlState?: {
        hideActions: boolean
    }
} & DialogTitleProps

// Define the component
export const AtlasModalTitle: FC<AtlasModalTitleProps> = ({
    onClose,
    center,
    controlState,
    ...props
}) => {
    const classes = useStyles()

    return (
        <NewDialogTitle {...props}>
            <TitleText center={center}>{props.children}</TitleText>
            {!controlState?.hideActions && (
                <IconButton tabIndex={0} onClick={onClose} classes={{ root: classes.root }}>
                    <AtlasIcon name="close" size={24} data-testid="AtlasModal_CloseIcon" />
                </IconButton>
            )}
        </NewDialogTitle>
    )
}

const useAtlasModalActionsStyles = makeStyles({
    root: {
        justifyContent: 'flex-end',
        height: '72px'
    }
})
export const AtlasModalActions: FC<DialogActionsProps> = (props) => {
    // If only one action button provided we center the button
    // but if we have multiple they will be right aligned
    const shouldCenterActions =
        Array.isArray(props.children) && props.children.length > 1 ? false : true
    const classes = useAtlasModalActionsStyles({ center: shouldCenterActions })
    return <DialogActions className={classes.root} {...props} />
}
const TitleText = styled.div<{ center?: boolean }>`
    ${({ center }) => !!center && { width: '100%', justifyContent: 'center' }}
    display: flex;
    align-items: center;
`
