import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
`

type Props = {
    title?: string
    className?: string
}

const UnfoldIcon = ({ title, className }: Props) => (
    <Svg viewBox="0 0 14.6 9.5" width="1em" height="1em" className={className}>
        <title id='new-section-title'>{title}</title>
        <path
            className="prefix__st0sectionopen"
            d="M7.3 9.5c-.2 0-.5-.1-.7-.3l-6.4-7c-.3-.4-.3-.9.1-1.3.4-.3.9-.3 1.2.1l5.7 6.3 5.8-7c.3-.4.9-.4 1.3-.1.4.3.4.9.1 1.3L8 9.2c-.2.2-.4.3-.7.3z"
        />
    </Svg>
)

export default UnfoldIcon
