import { AI_CHAT_BOT_TYPE } from 'businesslayer/minutesSessionStore'

export const isChatBotEnable = (ai_chat_bot: AI_CHAT_BOT_TYPE, platform: string) => {
    if (ai_chat_bot?.help_ai_for_bwa_enabled && platform === 'boardswebadmin') {
        return true
    }
    if (
        ai_chat_bot?.help_ai_for_bwd_enabled &&
        (platform === 'boardswebdirector' || platform === 'mock')
    ) {
        return true
    }
    return false
}
