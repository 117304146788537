import React, { useState, useRef, useEffect } from 'react'
import { pathOr } from 'rambdax'
//#region  Components

import { translate } from 'components/shared/internationalization'
import LengthCounterHelperText from '../shared/TextLengthCounter'
import {
    ColorBaseBlueGreyMedium,
    ColorBaseSkyBlue,
    ColorBaseWhite,
    FontWeightLightBold
} from 'assets/styles/variables'
import { TextField, ActionButton, AddActionEditActionButton } from './StyledComponents'
import { ClassNames } from '@emotion/react'
import styled from '@emotion/styled'
import { addKeyPressHandler } from 'common/util/functions'
import { MinutesManager_BtnFocusCss } from 'components/layout/page-layout'
import { css } from '@emotion/css'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

//#endregion

//#region Props

type Props = {
    text: string
    id: string
    onSave: (newTest: string | null) => void
    emptyTextError?: string
    emptyValuePlaceholder?: string
    maxLength: number
    disableEditor?: boolean
}

type OnTextClickedFn = (e: React.MouseEvent<HTMLElement>) => void
type OnTextKeyFn = (e: React.KeyboardEvent<HTMLElement>) => void
type OnTextChangedFn = (e: React.ChangeEvent<HTMLInputElement>) => void
type TextInputRef = any
//#endregion

//#region Implementation

const theme = createMuiTheme({
    overrides: {
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottom: '2px solid #455D82'
                }
            }
        }
    }
})

function EditLabel(props: Props) {
    const [text, setText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isEditMode, setIsEditMode] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const textInputRef: TextInputRef = useRef(null)

    useEffect(() => {
        setText(props.text)
        setErrorMessage('')
    }, [props.text])

    function onTextClicked(e: React.MouseEvent<HTMLElement>) {
        if (e.button !== 0) {
            return
        }
        setIsEditMode(true)
        setIsSaving(false)

        setTimeout(() => {
            textInputRef && textInputRef.current.focus()
        }, 50)
    }

    function handlekey(e) {
        if (addKeyPressHandler(e)) {
            setIsEditMode(true)
            setIsSaving(false)

            setTimeout(() => {
                textInputRef && textInputRef.current.focus()
            }, 50)
        }
    }

    function onTextChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
        const { emptyTextError, maxLength } = props
        let errorMessage: string = ''

        if (emptyTextError) {
            errorMessage = value ? '' : emptyTextError
        }

        if (value && value.length > maxLength) {
            value = value.substr(0, maxLength)
        }

        setText(value)
        setErrorMessage(errorMessage)
    }

    function canSaveCurrentText(newText: string | null, emptyTextError?: string): boolean {
        const couldSaveEmpty = emptyTextError === undefined

        return !!newText || (!newText && couldSaveEmpty)
    }

    function handleBlurOnClickSaveButton(newText: string, emptyTextError?: string) {
        //If user gets blur even after pressing save we handle it different than regular blur
        if (canSaveCurrentText(newText, emptyTextError)) {
            setIsEditMode(false)
            setIsSaving(false)
        } else {
            setIsSaving(false)
            setTimeout(() => {
                textInputRef && textInputRef.current.focus()
            }, 50)
        }
    }
    const saveTextOnBlur = () => {
        const { emptyTextError } = props

        if (isSaving) {
            handleBlurOnClickSaveButton(text, emptyTextError)
            return
        }
        setIsEditMode(false)
        setErrorMessage('')

        if (canSaveCurrentText(text, emptyTextError)) {
            if (props.text !== text) {
                props.onSave(text)
                setText(text)
                return
            }
        }
        setText(props.text)
    }

    const onBlur = (e: React.FocusEvent<HTMLElement>) => {
        const id = pathOr('', ['relatedTarget', 'id'], e)
        if (!['cancelBtn', 'saveBtn'].includes(id))
            setTimeout(() => {
                saveTextOnBlur()
            }, 50)
    }

    const onSave = () => {
        const { emptyTextError } = props
        const textToSave = isBlankOrEmpty(text) ? '' : text

        setIsSaving(true)

        //Draft.js is crashing on Safari if we dont schedule this on new loop
        setTimeout(() => {
            if (canSaveCurrentText(text, emptyTextError)) {
                props.onSave(textToSave)
                setText(textToSave)
                setIsEditMode(false)
            }
        }, 50)
    }

    const onCancel = () => {
        setText(props.text)
        setIsEditMode(false)
        setErrorMessage('')
    }

    const renderEditor = (
        originalText: string,
        newText: string | null,
        id: string,
        errorMessage: string | null,
        maxLength: number
    ) => {
        const text = newText !== null ? newText : originalText
        return (
            <ClassNames>
                {({ css }) => (
                    <div onBlur={onBlur}>
                        {renderTextField({
                            text,
                            id,
                            errorMessage,
                            maxLength,
                            onTextChange,
                            textInputRef
                        })}
                        <section
                            className={css`
                                text-align: right;
                            `}>
                            <AddActionEditActionButton
                                id="cancelBtn"
                                disableFocusRipple
                                className={css`
                                    margin-right: 30px;
                                `}
                                onClick={onCancel}
                                // onMouseDown to beat onBlur for iPad
                                onMouseDown={onCancel}>
                                {translate('CANCEL')}
                            </AddActionEditActionButton>

                            <ActionButton
                                className={css`
                                    ${ActionButtonCss}
                                `}
                                id="saveBtn"
                                disableFocusRipple
                                onClick={onSave}>
                                {translate('SAVE')}
                            </ActionButton>
                        </section>
                    </div>
                )}
            </ClassNames>
        )
    }

    if (isEditMode) {
        return renderEditor(props.text, text, props.id, errorMessage, props.maxLength)
    }

    if (props.emptyValuePlaceholder && !text) {
        return renderEmptyValuePlaceholder({
            emptyValuePlaceholder: props.emptyValuePlaceholder,
            onTextClicked,
            handlekey
        })
    }

    return renderText({
        originalText: props.text,
        newText: text,
        disableEditor: props.disableEditor,
        onTextClicked,
        handlekey
    })
}

const renderTextField = ({
    text,
    id,
    errorMessage,
    maxLength,
    onTextChange,
    textInputRef
}: {
    text: string
    id: string
    errorMessage: string | null
    maxLength: number
    onTextChange: OnTextChangedFn
    textInputRef: any
}) => (
    <ClassNames>
        {({ css }) => (
            <ThemeProvider theme={theme}>
                <TextField
                    id={`${id}`}
                    multiline={true}
                    onChange={onTextChange}
                    value={text || ''}
                    className={css`
                        margin-top: 1px;
                    `}
                    helperText={
                        <LengthCounterHelperText
                            errorText={errorMessage}
                            maxValue={maxLength}
                            value={text}
                        />
                    }
                    inputRef={textInputRef}
                />
            </ThemeProvider>
        )}
    </ClassNames>
)

const renderEmptyValuePlaceholder = ({
    emptyValuePlaceholder,
    onTextClicked,
    handlekey
}: {
    emptyValuePlaceholder: string
    onTextClicked: OnTextClickedFn
    handlekey: OnTextKeyFn
}) => (
    <ClassNames>
        {({ css }) => (
            <Content
                onClick={onTextClicked}
                onKeyPress={(e) => {
                    handlekey(e)
                }}
                tabIndex={0}>
                <p
                    className={css`
                        color: ${ColorBaseBlueGreyMedium};
                    `}>
                    {emptyValuePlaceholder}
                </p>
                <div>&nbsp;</div>
            </Content>
        )}
    </ClassNames>
)

const renderText = ({
    originalText,
    newText,
    disableEditor,
    onTextClicked,
    handlekey
}: {
    originalText: string
    newText: string | null
    disableEditor?: boolean
    onTextClicked: OnTextClickedFn
    handlekey: OnTextKeyFn
}) => {
    const onClickHandler = disableEditor ? () => false : onTextClicked
    return (
        <Content
            disableEditor={disableEditor}
            onClick={onClickHandler}
            tabIndex={0}
            onKeyPress={(e) => handlekey(e)}>
            <p>{newText !== null ? newText : originalText}</p>
        </Content>
    )
}

//#endregion

export default EditLabel

const Content = styled('div')<{ disableEditor?: boolean }>`
    padding-top: 2px;
    font-size: 1rem;
    cursor: ${({ disableEditor }) => (disableEditor ? 'inherit' : 'pointer')};
    width: 100%;
    height: 100%;
    line-height: 18px;
    ${MinutesManager_BtnFocusCss};
`
const ActionButtonCss = css`
    background-color: #5d7599;
    height: 40px;
    border-radius: 2px;
    span {
        text-transform: capitalize;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: ${FontWeightLightBold};
        font-size: 16px;
        line-height: 20px;
        color: white;
    }
    &:focus {
        box-shadow: 0 0 0 1px ${ColorBaseWhite}, 0 0 0 3px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
    &:hover {
        border-radius: 2px;
        background: #364262;
    }
`

const isBlankOrEmpty = (str) => !str || /^\s*$/.test(str)
