import React, { FC, useMemo, ChangeEventHandler, FocusEventHandler } from 'react'
import { getAtlasHelperText } from 'components/text-field/input-error-text'
import { TextField } from '@material-ui/core'
import { makeMuiFieldAdapter } from 'components/final-form/final-form-helper'

type AtlasTextFieldProps = {
    label: string
    value: string
    placeholder: string
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    helperText?: string
    error: boolean
    maxLength?: number
    autoComplete?: 'on' | 'off'
    'data-testid'?: string
}

export const AtlasTextField: FC<AtlasTextFieldProps & TestIdAttribute> = ({
    label,
    value,
    helperText,
    maxLength,
    autoComplete = 'off',
    'data-testid': data_testid,
    ...rest
}) => {
    const AtlasHelperTextComponent = useMemo(
        () => getAtlasHelperText({ errorText: helperText, value, maxValue: maxLength }),
        [helperText, value, maxLength]
    )
    return (
        <TextField
            id={`input-${label}`}
            label={label}
            value={value || ''}
            autoComplete={autoComplete}
            helperText={AtlasHelperTextComponent}
            inputProps={{ 'data-testid': data_testid, 'aria-required': true }}
            {...rest}
        />
    )
}

/**
 * final-form adapter for AtlasTextField
 */
export const AtlasTextFieldAdapter = makeMuiFieldAdapter<AtlasTextFieldProps>(AtlasTextField)
