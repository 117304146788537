import { createSelector } from 'reselect'

const selectorDataState = (state) => {
    return state.dataState
}

const selectorCommunicationState = (state) => {
    return state.communicationState
}

export default {
    sortField: createSelector(selectorDataState, (dataState) => dataState.sortField),
    notification: createSelector(selectorDataState, (dataState) => dataState.notification),
    dateFormat: createSelector(selectorDataState, (dataState) => dataState.dateFormat),
    savedAction: createSelector(selectorDataState, (dataState) => dataState.savedAction),
    isSendingNotification: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.isSendingNotification
        ),
        platform: createSelector(
            selectorDataState,
            (dataState) => dataState.platform
    )
}
