import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/minutemanager/components/MinuteCard/Atoms'

const IconWrapper = styled('div')`
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`
const TextWrapper = styled('div')`
    max-width: 250px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
`

type IconButtonProps = {
    icon?: any
    text?: string | ReactNode
    onClick?: (...args: any[]) => void
    disabled?: boolean
    className?: string
    children?: any
}

export const IconButton = ({ onClick, disabled, className, children }: IconButtonProps) => {
    return (
        <Button disableRipple disabled={disabled} onClick={onClick} className={className}>
            {children}
        </Button>
    )
}

export const ButtonText = ({ children }) => {
    return <TextWrapper>{children}</TextWrapper>
}

export const ButtonIcon = ({ children }: { children: JSX.Element }) => {
    return <IconWrapper>{children}</IconWrapper>
}

export default IconButton
