import { FormControlLabelClassKey } from '@material-ui/core/FormControlLabel'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiFormControlLabel: Partial<StyleRules<FormControlLabelClassKey, {}>> = {
    root: {
        display: 'flex',
        maxWidth: '300px',
        marginRight: '35px',
        marginLeft: 0
    }
}
