import React from 'react'

//#region Components Material

import { isSafari } from '../../helpers/DomHelper'
import styled from '@emotion/styled'

import { ColorBaseGreyDark } from 'assets/styles/variables'
import { IconButton } from 'components/shared/StyledComponents'
import { addKeyPressHandler } from 'common/util/functions'
import { MinutesManager_BtnFocusCss } from 'components/layout/page-layout'

//#endregion

//#region Styles

const ButtonWrapper = styled('section')<{ hasScroller?: boolean }>`
    margin-right: ${({ hasScroller = false }) => (hasScroller && isSafari() ? 20 : 'inherit')};
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
`

const Label = styled('div')<{ isActive: boolean; activeColor?: string; inactiveColor?: string }>`
    font-size: 1rem;
    color: ${(props) => (props.isActive ? props.activeColor : props.inactiveColor)};
    cursor: pointer;
    ${MinutesManager_BtnFocusCss};
`
//#endregion

//#region Props

type Props = {
    tooltip?: string
    isActive: boolean
    isFailed: boolean
    activeIcon?: JSX.Element
    inactiveIcon?: JSX.Element
    failedIcon?: JSX.Element
    activeLabel: string
    inactiveLabel: string
    failedLabel?: string
    labelActiveColor?: string
    labelInactiveColor?: string
    onToggle: () => void
    className?: string
}

//#endregion

//#region Implementation

const getLabelIcon = (props: Props): { label: string; icon?: JSX.Element } => {
    let icon = props.isActive ? props.activeIcon : props.inactiveIcon
    let label = props.isActive ? props.activeLabel : props.inactiveLabel

    if (props.isFailed && props.failedIcon) {
        icon = props.failedIcon
        label = props.failedLabel || props.inactiveLabel
    }

    return {
        label: label,
        icon: icon
    }
}

const IconButtonView: React.SFC<Props> = (props: Props) => {
    const { label, icon } = getLabelIcon(props)
    return (
        <ButtonWrapper className={props.className}>
            {icon && <IconButton onClick={props.onToggle}>{icon}</IconButton>}

            <Label
                isActive={props.isActive}
                activeColor={props.labelActiveColor || '#59ac45'}
                inactiveColor={props.labelInactiveColor || ColorBaseGreyDark}
                onKeyPress={(e) => (addKeyPressHandler(e) ? props.onToggle() : null)}
                tabIndex={0}
                onClick={props.onToggle}>
                {label}
            </Label>
        </ButtonWrapper>
    )
}

export default IconButtonView
//#endregion
