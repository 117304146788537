/**
 * This is the Committees Context used to store and update
 * the Users committee and current committee in the app
 */
import constate from 'constate'
import { useCommittees } from './use-committees'

export type ProviderProps = {
    initialCommitteeId: string
    isStaticRoleMode?: boolean
    platform: Platform
}

// Constate factory returns the useable (Provider, useStateHook, useActionsHook)
const [CommitteesProvider, useCommitteesState, useCommitteesActions] = constate(
    (providerProps: ProviderProps) => useCommittees(providerProps),
    (value) => {
        return value.committeesState
    },
    (value) => {
        return value.committeesActions
    }
)

// Consumer for Class Components
const CommitteeContextConsumer = ({ children }) => {
    const { currentCommittee, committees } = useCommitteesState()
    return children({ committees, currentCommittee })
}

// Export (Provider, Consumer, useStateHook, useActionsHook)
export { CommitteesProvider, CommitteeContextConsumer, useCommitteesState, useCommitteesActions }
