import React, { useState } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuEditActionIcon from 'assets/icons/MenuEditActionIcon'
import MenuActionManagerIcon from 'assets/icons/MenuActionManagerIcon'
import { Menu } from 'components/shared/StyledComponents'
import { translate } from 'components/shared/internationalization/Trans'
import i18n from 'i18next'
import MinuteActionItemView from './MinuteActionItemView'
import MenuDeleteActionIcon from 'assets/icons/MenuDeleteActionIcon'
import ConfirmationDialog from 'components/shared/ConfirmationDialog'
import { MenuItem } from '@material-ui/core'
import { Body } from 'components/shared/typography/Typography'

export default function MinuteActionItemContainer(props: Props) {
    const [showContextMenu, setShowContextMenu] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [anchorEl, setAnchorEl] = useState(undefined)

    const {
        isReadonly,
        onEditAction,
        onDeleteAction,
        onViewAllActions,
        actionItem,
        onActionChecked,
        previewHeight,
        snapshot,
        dragProps
    } = props

    const popover = !!showContextMenu
        ? renderPopover({
              showContextMenu,
              setShowContextMenu,
              setShowConfirmation,
              onEditAction,
              onViewAllActions,
              anchorEl
          })
        : null

    const confirmationDialog = !!showConfirmation
        ? renderConfirmedAction({ setShowConfirmation, onDeleteAction })
        : null

    return (
        <div>
            <div>
                <MinuteActionItemView
                    isReadonly={!!isReadonly}
                    actionItem={actionItem}
                    previewHeight={previewHeight}
                    isDroppingOver={false}
                    onActionChecked={onActionChecked}
                    onContextMenu={(e) => handleContextMenu({ e, setShowContextMenu, setAnchorEl })}
                    onClick={onEditAction}
                    dragProps={dragProps}
                    snapshot={snapshot}
                />
                {popover}
                {confirmationDialog}
            </div>
        </div>
    )
}

type Props = {
    actionItem: ActionItem
    isReadonly?: boolean
    previewHeight: number
    onActionItemReorder: (...args: any[]) => void
    onActionChecked: (...args: any[]) => void
    onEditAction: (...args: any[]) => void
    onDeleteAction: (...args: any[]) => void
    onViewAllActions: (...args: any[]) => void
    dragProps: any
    snapshot: any
}

const handleContextMenu = ({ e, setShowContextMenu, setAnchorEl }) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
    //disable right click on action item
    setShowContextMenu(false)
}

const handleRequestPopoverClose = (setShowContextMenu) => {
    setShowContextMenu(false)
}

const renderConfirmedAction = ({ setShowConfirmation, onDeleteAction }) => {
    return (
        <ConfirmationDialog
            message={i18n.t('DELETE_ACTION_MESSAGE')}
            title={i18n.t('DELETE_ACTION_TITLE')}
            onConfirm={onDeleteAction}
            onCancel={() => setShowConfirmation(false)}
            confirmLabel={i18n.t('DELETE')}
        />
    )
}

const renderMenu = ({
    setShowContextMenu,
    setShowConfirmation,
    onEditAction,
    onViewAllActions
}) => {
    return [
        <MenuItem
            key="edit-action"
            onClick={() => {
                setShowContextMenu(false)
                onEditAction()
            }}>
            <ListItemIcon>
                <MenuEditActionIcon />
            </ListItemIcon>
            <Body type="body1">{translate('ACTION_MENU_EDIT_ACTION')}</Body>
        </MenuItem>,

        <MenuItem
            key="delete-action"
            onClick={() => {
                setShowContextMenu(false)
                setShowConfirmation(true)
            }}>
            <ListItemIcon>
                <MenuDeleteActionIcon />
            </ListItemIcon>
            <Body type="body1">{translate('ACTION_MENU_DELETE_ACTION')}</Body>
        </MenuItem>,

        <MenuItem
            key="view-action"
            onClick={() => {
                setShowContextMenu(false)
                onViewAllActions()
            }}>
            <ListItemIcon>
                <MenuActionManagerIcon />
            </ListItemIcon>
            <Body type="body1">{translate('ACTION_MENU_GO_TO_ACTIONS')}</Body>
        </MenuItem>
    ]
}

const renderPopover = ({
    showContextMenu,
    setShowContextMenu,
    setShowConfirmation,
    onEditAction,
    onViewAllActions,
    anchorEl
}: {
    showContextMenu: boolean
    setShowContextMenu: (...args: any[]) => void
    setShowConfirmation: (...args: any[]) => void
    onEditAction: (...args: any[]) => void
    onViewAllActions: (...args: any[]) => void
    anchorEl: Element | undefined
}) => {
    const anchorOrigin: any = { vertical: 'bottom' }

    return (
        <Menu
            onClose={() => handleRequestPopoverClose(setShowContextMenu)}
            open={showContextMenu}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            disableEnforceFocus={true}
            disableAutoFocusItem={true}
            disableAutoFocus={true}
            getContentAnchorEl={null}>
            {renderMenu({
                setShowContextMenu,
                setShowConfirmation,
                onEditAction,
                onViewAllActions
            })}
        </Menu>
    )
}
