import React, { Component } from 'react'

//================ Components ===============================
// import * as MM from '../../../Types'

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'

import InlineError from '../../../shared/InlineError'
//#region Components Material

import {
    DialogActions,
    Dialog,
    AddActionEditActionButton,
    NewDialogTitle,
    NewDialogContent,
    IconButton
} from 'components/shared/StyledComponents'
import ChipInputField, { makeOnChipChange } from 'components/chip-input/chip-input-field'
import { css, ClassNames } from '@emotion/react'
import {
    Assignees,
    InputLabelStyled
} from 'components/minutetaker/components/form/AssigneeChipInputField'
import {
    AtlasButtonCss,
    StyledAtlasButtonOld
} from 'components/minutetaker/components/ActionItemEditDialog/ActionItemEditDialog'
import { AtlasIcon } from 'web-components/atlas-icon'

//#endregion

//#region Styles

export const getDialogTitleStyle = () => {
    return {
        backgroundColor: '#2379a0',
        color: '#fff',
        height: 50,
        fontSize: 18,
        padding: 0,
        paddingLeft: 25,
        lineHeight: '50px',
        fontWeight: 'normal',
        marginBottom: 0
    }
}

//#endregion

//#region Props

type Props = {
    action: any
    allContacts: any
    onClose: () => void
    onSave: (assigness: Array<any>) => void
}

type State = {
    visible: boolean
    assigneeError: string | null
}

//#endregion

//#region Implementation

//Note: the set of functions used in main action editor dialog
export const filterNotAddedAssignees = (allContacts: Array<any>, action: any) => {
    const keys = Object.keys(allContacts)
    //Dont show ones we already added
    const filtered = keys.filter(
        (key) =>
            !action.assignees.find((existing) => {
                return existing.text === allContacts[key].attributes.displayName
            })
    )

    return filtered
}
export const getAssigneesDataSource = (allContacts: Array<any> | null, action: any) => {
    if (!allContacts) {
        return
    }

    const availableAssignees = filterNotAddedAssignees(allContacts, action)

    const result = availableAssignees.map((key) => {
        const item = allContacts[key]

        return {
            text: item.attributes.displayName,
            email: item.attributes.email,
            id: item.id,
            value: item.attributes.displayName
        }
    })

    return result
}

export const getAssigneeText = (item: any) => {
    if (item.isInverted) {
        return item.email ? item.email : translate('NO_EMAIL_PERSON_LABEL')
    }

    return item.text ?? item.display_name
}

export const getExistingAssigneesData = (action: any) => {
    if (!action?.assignees) {
        action.assignees = []
    }

    return action.assignees.map((item) => {
        const itemText = getAssigneeText(item)
        return {
            text: itemText,
            email: item.email,
            id: item.id,
            value: itemText
        }
    })
}

export const findAssigneeToInvert = (action: any, chipValue: any) => {
    const toInvert = action.assignees.find((c) => {
        const currentValue = `${c.id || c.value}`
        const currentKeyEqual = c.id && chipValue.key && `${c.id}` === `${chipValue.key}`

        return currentValue === `${chipValue}` || currentKeyEqual
    })

    return toInvert
}

export const adjustNewAssigneeObject = (allContacts: Array<any>, newObject: any) => {
    let newAssignee = newObject

    //BREAKING CHANGE Since 1.0: Control no longer sends the same object from autoselect so we have to
    //preprocess it to find out if that is our object
    if (!newObject.email && typeof newObject.value === 'object') {
        const contact = allContacts[newObject.value.key]

        newAssignee = {
            text: newObject.text,
            email: contact.attributes.email,
            id: newObject.value.key,
            value: newObject.text
        }
    }

    return newAssignee
}

export const getAssigneeError = (assigneeErrorText: string | null) => {
    const assigneeError = assigneeErrorText ? (
        <section>
            <InlineError error={assigneeErrorText} />
        </section>
    ) : null
    return assigneeError
}
class EditAssigneesDialog extends Component<Props, State> {
    state = {
        visible: false,
        assigneeError: ''
    }

    componentDidMount() {
        if (this.state.visible) {
            return
        }

        const { action } = this.props
        if (action && action.assignees) {
            action.assignees.forEach((c) => (c.isInverted = false))
        }
    }

    onCloseDialog = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.onClose()
    }

    onSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()

        const { action } = this.props
        this.props.onSave(action.assignees)
    }

    onAssigneeAdd = (newObject) => {
        if (!newObject.text || !newObject.text.trim()) {
            return
        }
        const { allContacts, action } = this.props

        if (!allContacts) {
            return
        }

        let newAssignee = adjustNewAssigneeObject(allContacts, newObject)

        if (!action.assignees) {
            action.assignees = []
        }

        if (action.assignees.length >= 100) {
            //We cannot add more than certain amount of folks
            this.setState({ assigneeError: i18n.t('INVALID_ASSIGNEES_COUNT') })
            setTimeout(() => this.setState({ assigneeError: null }), 3000)
            return
        }

        action.assignees.push(newAssignee)

        this.forceUpdate()
    }

    onAssigneeDelete = (toDelete: any) => {
        if (!toDelete) {
            return
        }
        const { action } = this.props

        const item = action.assignees.find((existing) => {
            return existing.id === toDelete.id
        })
        if (!item) {
            return
        }
        const index = action.assignees.indexOf(item)
        action.assignees.splice(index, 1)

        this.forceUpdate()
    }

    renderDialogContent = (allContacts: any, action: any): JSX.Element => {
        const assigneeError = getAssigneeError(this.state.assigneeError)
        const existingAssignees = getExistingAssigneesData(action)
        const floatingLabelText = <>{/* {translate('ASSIGNEES')} */}</>
        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            ${Assignees}
                        `}>
                        <ChipInputField
                            className={css`
                                ${AssigneeTextField}
                            `}
                            options={getAssigneesDataSource(allContacts, action)}
                            value={existingAssignees}
                            variant="outlined"
                            floatingLabelText={floatingLabelText}
                            onChange={(options, action) =>
                                makeOnChipChange({
                                    addChip: this.onAssigneeAdd,
                                    deleteChip: this.onAssigneeDelete
                                })({ options, action })
                            }
                            // onBlur={(event) =>
                            //     makeOnBlurAddUnique(this.onAssigneeAdd)({
                            //         event,
                            //         currentData: existingAssignees
                            //     })
                            // }
                        />
                        {assigneeError}
                    </div>
                )}
            </ClassNames>
        )
    }

    renderAssigneesDialog = (allContacts: any, action: any) => {
        const cancelButton = (
            <AddActionEditActionButton
                key="cancel"
                disableFocusRipple
                disableRipple
                color="primary"
                onClick={this.onCloseDialog}>
                {translate('CANCEL')}
            </AddActionEditActionButton>
        )

        const okButton = (
            <StyledAtlasButtonOld
                key="save"
                className={css`
                    span {
                        display: flex;
                        justify-content: center;
                    }
                `}
                color="primary"
                onClick={this.onSave}>
                {translate('SAVE')}
            </StyledAtlasButtonOld>
        )

        return (
            <ClassNames>
                {({ css }) => (
                    <Dialog
                        className={css`
                            .MuiPaper-root.MuiDialog-paper {
                                max-width: 1000px;
                                max-height: 100%;
                            }
                        `}
                        scroll="paper"
                        fullWidth
                        maxWidth="sm"
                        open={true}
                        onClose={this.onCloseDialog}>
                        <NewDialogTitle>
                            {translate('ACTION_MENU_ASSIGN_ACTION')}
                            <IconButton
                                tabIndex={0}
                                disableFocusRipple
                                disableRipple
                                className={css`
                                    ${AtlasButtonCss}
                                `}
                                onClick={this.onCloseDialog}>
                                <AtlasIcon
                                    name="close"
                                    size={24}
                                    data-testid="AtlasModal_CloseIcon"
                                />
                            </IconButton>
                        </NewDialogTitle>
                        <InputLabelStyled
                            className={css`
                                margin-top: 32px;
                                margin-left: 24px;
                            `}>
                            {`${i18n.t('ASSIGNEES')}`}
                        </InputLabelStyled>
                        <NewDialogContent>
                            {this.renderDialogContent(allContacts, action)}
                        </NewDialogContent>
                        <DialogActions>{[cancelButton, okButton]}</DialogActions>
                    </Dialog>
                )}
            </ClassNames>
        )
    }

    render() {
        const { allContacts, action } = this.props
        return <div>{this.renderAssigneesDialog(allContacts, action)}</div>
    }
}

//#endregion

//#region Export
const AssigneeTextField = css`
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
        margin-top: 8px;
    }
    .MuiOutlinedInput-root {
        min-height: 40px;
        margin-bottom: 24px;
    }
`
export default EditAssigneesDialog

//#endregion
