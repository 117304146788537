import React, { useState, ReactNode, useContext, createContext } from 'react'

const EditorContext = createContext<EditorContextProps | undefined>(undefined)

export function EditorProvider(props: { children?: ReactNode }) {
    const [currentEditor, setCurrentEditor] = useState<Editor | undefined>()

    function getData() {
        return currentEditor ? currentEditor.getData() : ''
    }

    return (
        <EditorContext.Provider
            value={{
                currentEditor,
                setCurrentEditor: (editor) => setCurrentEditor(editor),
                getData
            }}>
            {props.children}
        </EditorContext.Provider>
    )
}

export function useEditor() {
    const context = useContext(EditorContext)
    if (!context) {
        throw new Error(`useEditor must be used within a EditorProvider`)
    }

    return context
}

type EditorContextProps = {
    currentEditor?: Editor
    setCurrentEditor(editor?: Editor): void
    getData(): string
}

export type Editor = {
    getData(): string
    editing: { view: { focus(): void } }
    commands: any
    plugins: any
}
