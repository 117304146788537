import React, { useRef, useCallback } from 'react'
import moment from 'moment'
//#region Components Material

import MaterialIconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core'
import { DatePicker } from 'material-ui-pickers'
//#endregion

//#region Components

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import { DeleteDateRowIcon as IconDeleteDateRow } from 'assets/icons/DeleteDateRowIcon'
import { addKeyPressHandler } from 'common/util/functions'

//#endregion

//#region Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as editorActions } from '../../../../reducers/actionEditorReducer'
import editorSelectors from '../../../../selectors/actionEditorSelectors'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'
import { ColorBaseRed, ColorBaseSkyBlue, ColorBaseWhite } from 'assets/styles/variables'
import { MinutesManager_BtnFocusCss } from 'components/layout/page-layout'
import { css } from '@emotion/css'

//#endregion

//#region Props
type Props = {
    actions: {
        saveExistingAction: (...args: any[]) => void
    }
    isSelected: boolean
    action: any
    dateFormat: string
    saveComplete: boolean
    savedAction: any
}

type DatePickerRef = any

//#endregion

//#region Implementation

//TODO: Upgrade when taker merged.
function InlineDateEditor(props: Props) {
    const pickerRef: DatePickerRef = useRef(null)

    const openPicker = useCallback(
        (e) => {
            if (pickerRef.current) {
                pickerRef.current.open(e)
            }
        },
        [pickerRef]
    )

    const { action, dateFormat = 'MMMM DD, YYYY' } = props
    const { actionColor, overdueLabel } = renderOverdueLabel(action)
    const dueDate = action.dueDate
        ? moment(action.dueDate).format(dateFormat)
        : i18n.t('NO_DUE_DATE')
    const dueDateObject = action.dueDate ? moment(action.dueDate).toDate() : null

    const datePicker = (
        <ClassNames>
            {({ css }) => (
                <DatePicker
                    className={css`
                        display: none;
                    `}
                    okLabel={translate('OK')}
                    cancelLabel={translate('CANCEL')}
                    autoOk={false}
                    onChange={createHandleChangeDate(props, action)}
                    ref={pickerRef}
                    value={dueDateObject}
                    clearable
                />
            )}
        </ClassNames>
    )

    const dueDateIcon = action.dueDate && (
        <IconButton
            disableFocusRipple
            className={css`
                padding: 2px;
                &:focus,
                &:active {
                    box-shadow: 0px 0px 0px 2px ${ColorBaseSkyBlue};
                    border-radius: 2px;
                    padding: 2px;
                }
            `}
            onClick={createHandleCleanDate(props, action)}>
            <StyledIconDeleteDateRow />
        </IconButton>
    )

    return (
        <React.Fragment>
            <DueDateLabel
                actionColor={actionColor}
                onClick={openPicker}
                onKeyPress={(e) => (addKeyPressHandler(e) ? openPicker(e) : null)}
                tabIndex={0}>
                {overdueLabel && <OverdueLabelStyle>{overdueLabel}</OverdueLabelStyle>}
                <DateContainer>
                    {dueDate}
                    {dueDateIcon}
                </DateContainer>
            </DueDateLabel>
            {datePicker}
        </React.Fragment>
    )
}

//#endregion

//#region Styles
const DateContainer = styled('div')`
    display: inline-flex;
    width: 100%;
    padding-left: 0px;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
`

const OverdueLabelStyle = styled('span')`
    padding-right: 4px;
`

const StyledIconDeleteDateRow = styled(IconDeleteDateRow)``

const DueDateLabel = styled('div')<{ actionColor: string }>`
    color: ${({ actionColor }) => actionColor};
    width: 100%;
    text-align: center;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    ${MinutesManager_BtnFocusCss};
`
//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => ({
    saveComplete: editorSelectors.saveComplete(state.actionEditorReducer),
    savedAction: editorSelectors.savedAction(state.actionEditorReducer)
})

const mapDispatchToProps = (dispatch) => {
    const { saveExistingAction } = editorActions

    return {
        actions: bindActionCreators(
            {
                saveExistingAction
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InlineDateEditor)

const iconButtonStyles: any = {
    root: {
        padding: '0px',
        '&:hover': {
            backgroundColor: ColorBaseWhite
        }
    }
}

const createHandleCleanDate = (props, action) => (e) => {
    if (e.button !== 0) {
        //Only first mouse button handling
        return
    }
    e.preventDefault()
    e.stopPropagation()
    action.dueDate = null

    props.actions.saveExistingAction(Object.assign({}, action))
    return false
}

const createHandleChangeDate = (props, action) => (value) => {
    action.dueDate = value

    props.actions.saveExistingAction(Object.assign({}, action))
}

const renderOverdueLabel = (action): { actionColor: string; overdueLabel: string | null } => {
    let actionColor = 'inherit'

    let dayDifference = moment(action.dueDate).diff(Date.now(), 'days')
    let overdueLabel: string | null = null
    if (action.completionStatus !== 'complete' && !isNaN(dayDifference) && dayDifference < 0) {
        //Seems to be in past
        actionColor = ColorBaseRed
        overdueLabel = `${i18n.t('OVERDUE_ACTION_LIST_LABEL')}: `
    }

    return {
        actionColor,
        overdueLabel
    }
}

export const IconButton = withStyles(iconButtonStyles)(MaterialIconButton)

//#endregion
