/**
 * This is the Sort Constate. It is currently only being used for sorting actions
 * but should be abstsact enough to be used for other sorting needs throughout the app
 */
import { useState, useCallback } from 'react'
import constate from 'constate'

// Types
type SortOrder = 'ascending' | 'descending'
type State = {
    sortField?: string
    sortOrder: SortOrder
}

// Create a custom hook
function useSort({
    initialState = { sortField: undefined, sortOrder: 'ascending' }
}: {
    initialState: State
}) {
    /* * * STATE * * */
    const [sortState, setSortState]: [State, any] = useState(initialState)

    /* * * ACTIONS * * */
    const updateSortField = useCallback(
        (sortField) => setSortState({ sortField, sortOrder: 'ascending' }),
        []
    )
    const updateSortOrder = useCallback((sortOrder) => setSortState({ ...sortState, sortOrder }), [
        sortState
    ])

    // Consolidate all actions for the Constate factory to use
    const sortActions = { updateSortField, updateSortOrder }
    return { sortState, sortActions }
}

// Constate factory returns the useable (Provider, useStateHook, useActionsHook)
const [SortProvider, useSortState, useSortActions] = constate(
    useSort,
    (value) => {
        return value.sortState
    },
    (value) => {
        return value.sortActions
    }
)

// Export (Provider, useStateHook, useActionsHook)
export { SortProvider, useSortState, useSortActions }
