import { getJSON, putJSON, postJSON, deleteJSON, getJSONBody } from 'common/util/request'
import moment from 'moment-timezone'
import * as MM from '../../../Types'
import i18n from 'common/util/internationalization'
import { getOfficeXls } from 'common/util/request'
import { serialize, serializeActions } from 'businesslayer/networkrequests/actions/serializer'

export const getActions = (minutesId) => {
    return getJSON(`action_items/fetchall/${minutesId}`, { shouldNormalize: false })
}

export const getFilteredActions = (data) => {
    return getJSONBody(`action_items/actions_list`, data, { shouldNormalize: false })
}

export const deleteAction = (committeeId, minutesId, actionId) => {
    return deleteJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/action_items/${actionId}`
    )
}
export const completeAction = (body) => {
    return postJSON(`action_items/mark_complete`, body, { shouldNormalize: false })
}

export const exportActions = (
    committeeId: string,
    minutesId: string,
    sortField: MM.ActionListSort
) => {
    const timeZone = encodeURIComponent(moment.tz.guess())
    const url = `committees/${committeeId}/minutes_documents/${minutesId}/action_items/export/?timezone=${timeZone}&sort_name=${
        sortField.name
    }&sort_direction=${sortField.ascending ? 'asc' : 'desc'}&user_language=${i18n.language}`
    const result = getOfficeXls(url)

    return result
}

export const exportConsolidatedActions = (committeeIds: string, sortField: MM.ActionListSort) => {
    const timeZone = encodeURIComponent(moment.tz.guess())
    const url = `action_items/consolidated_export?timezone=${timeZone}&committees=${committeeIds}&sort_name=${
        sortField.name
    }&sort_direction=${sortField.ascending ? 'asc' : 'desc'}&user_language=${i18n.language}`
    const result = getOfficeXls(url)

    return result
}

export const saveAction = (committeeId, item) => {
    const jsonApiBody = serialize(item)

    //-1 meant user rolled back to NO section for the action so we need to correct it to NULL expected by API
    const correctedSectionId = item.minutesSectionId === -1 ? null : item.minutesSectionId

    if (item.id) {
        return putJSON(
            `committees/${committeeId}/minutes_documents/${item.minutesId}/action_items/${item.id}/?minutes_section_id=${correctedSectionId}`,
            jsonApiBody
        )
    } else {
        return postJSON(
            `committees/${committeeId}/minutes_documents/${item.minutesId}/action_items?minutes_section_id=${correctedSectionId}`,
            jsonApiBody
        )
    }
}

export const reorderActions = (committeeId, actionItems) => {
    const jsonApiBody = serializeActions(actionItems)
    const minutesId = actionItems[0].minutesId

    return putJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/action_items/reorder`,
        jsonApiBody
    )
}

const templateObject = (): any => {
    return {
        recipients: [],
        subject: '',
        body: '',
        parent_platform: ''
    }
}

export const sendNotification = (committeeId, notification) => {
    const minutesId = notification.minutesId
    const actionId = notification.actionId

    const requestData = templateObject()
    requestData.body = notification.body
    requestData.subject = notification.subject
    requestData.parent_platform = notification.platform
    notification.to.forEach((c) => {
        requestData.recipients.push({ email: c.email, id: c.id })
    })

    const jsonApiBody = JSON.stringify(requestData)

    const result = postJSON(
        `committees/${committeeId}/minutes_documents/${minutesId}/action_items/${actionId}/notify`,
        jsonApiBody
    )
    return result
}
