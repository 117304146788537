import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    stroke: #303a48;
    fill: #303a48;
`

type Props = {
    title?: string
    className?: string
}

const RightCaretIcon = ({ title, ...props }: Props) => (
    <Svg width="1em" height="1em" viewBox="0 0 10 14" {...props}>
        <title>{title}</title>
        <path
            d="M9.662 6.962a.78.78 0 0 1-.261.58l-6.332 5.682a.814.814 0 0 1-1.135-.05.776.776 0 0 1 .05-1.112L7.638 6.99 1.379 1.887A.778.778 0 0 1 1.276.778a.814.814 0 0 1 1.13-.102l6.966 5.681a.779.779 0 0 1 .29.587v.018z"
            fill="#303A48"
            stroke="#303A48"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </Svg>
)

export default RightCaretIcon
