import Countly from 'countly-sdk-web'
const { _env_ } = window as typeof window & Window['_env_']

/**
 * Hate how imperative countly is,
 * but it requires initializing async and mutating the Countly.q array
 * @see https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-#setting-up-recommended-asynchronous-usage
 */
Countly.q = Countly.q || []
Countly.app_key = _env_.COUNTLY_APP_KEY
Countly.url = _env_.COUNTLY_URL
Countly.q.push(['track_sessions'])
Countly.q.push(['track_pageview'])
Countly.init()

/**
 * Util for firing off event in Countly that has the [type, { config }] shape.
 * Primarily used for everything BUT starting a timed event
 * @example
 * export const countlyEvent = (key, segmentation) =>
 *   void fireEventObject('add_event', key, segmentation)
 *
 * @param type Countly Event Type
 * @param key Event key for tracking on dashboard
 * @param segmentation Segmentation you want to split by on in dashboard
 */
const fireEventObject: CountlyEvent.Fire = (type, key, segmentation?) =>
    void Countly.q.push([type, { key, count: 1, segmentation }])

/**
 * Util for firing off event in Countly that has the [type, key] shape.
 * This is for events that don't include any data.
 * Primarily starting a timed event.
 * @example
 * export const startTimedCountlyEvent = (key) =>
 *   void fireEventObjectShort('start_event', key)
 *
 * @param type Countly Event Type
 * @param key Event key for tracking on dashboard
 */
const fireEventObjectShort: CountlyEvent.Fire = (type, key) => void Countly.q.push([type, key])

/**
 * Generic count event with optional segmentation.
 * Increment an event in Countly and optionally segment it.
 * @see https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-#adding-an-event
 *
 * @param key Event key for tracking on dashboard
 * @param segmentation Segmentation you want to split by on in dashboard
 */
export const countlyEvent: CountlyEvent.Trigger = (key, segmentation?) =>
    void fireEventObject('add_event', key, segmentation)

/**
 * Start a timed Countly event.
 * @see https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-#timed-events
 * @example
 * startTimedCountlyEvent('user_entered')
 *
 * @param key Event key for tracking on dashboard
 */
export const startTimedCountlyEvent: CountlyEvent.Trigger = (key) =>
    void fireEventObjectShort('start_event', key)

/**
 * End a timed count event with optional segmentation.
 * @see https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-#timed-events
 * @example
 * endTimedCountlyEvent('user_entered', { reason: 'got_bored' })
 *
 * @param key Event key for tracking on dashboard
 * @param segmentation Segmentation you want to split by on in dashboard
 */
export const endTimedCountlyEvent: CountlyEvent.Trigger = (key, segmentation?) =>
    void fireEventObject('end_event', key, segmentation)
