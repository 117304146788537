import 'react-app-polyfill/stable'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom' // Updated imports

//============= Polyfills
//FIXME: Determine whether both are needed
import 'unfetch/polyfill'
//=============

import App from './App'
import './index.css'
import '@diligentcorp/atlas-core/icons'
import '@diligent/sso-login/dist'
import IdentityLoginCallback from 'common/sso/IdentityLoginCallback'

ReactDOM.render(
    <Router>
        <Switch>
            <Route path="/identity-login-callback" component={IdentityLoginCallback} />
            <Route path="/" component={App} />
        </Switch>
    </Router>,
    document.getElementById('root')
)
