/**
 * Helper functions for Minutes Parent Platforms
 */

export const VALID_PLATFORMS = [
    'boardeffect',
    'boards',
    'boardswebadmin',
    'boardswebdirector',
    'boardsios',
    'mock'
]

export const checkValidPlatform = (platform) => VALID_PLATFORMS.includes(platform)
