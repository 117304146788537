export default class objectErrorHelper {


    static addValidationError(obj, prop, message) {
        if (!obj.validationErrors) {
            obj.validationErrors = new Map()
        }

        obj.validationErrors.set(prop, message)
    }

    static removeValidationError(obj, prop, message) {
        if (!obj.validationErrors) {
            obj.validationErrors = new Map()
        }

        obj.validationErrors.delete(prop)
    }
}