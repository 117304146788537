import React, { PureComponent } from 'react'

import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'
import { translate } from 'components/shared/internationalization/Trans'
import { Subtitle } from 'components/shared/typography/Typography'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { actions as editorActions } from 'reducers/minuteTakerReducer'
import { connect } from 'react-redux'
import { EDITOR_TABS } from 'components/minutetaker/components/InvitationView/types'

type Status = 'draft' | 'inReview' | 'reviewed' | 'approved'

type StatusPopoverProps = {
    onClosePopover: ({ closeReason }: { closeReason: Status }) => void
    onStatusChange: (status: Status, prevStatus?: Status) => void
    onRequestCloseExportPopover: () => void
    isOpen: boolean
    anchorElement: any
    closeReason?: string
    actions: {
        currentSelectedTab: (...args: any[]) => void
    }
}

const menuItems = [
    {
        primaryText: translate('DRAFT'),
        value: 'draft'
    },
    {
        primaryText: translate('IN_REVIEW'),
        value: 'in_review'
    },
    {
        primaryText: translate('FINAL_DRAFT'),
        value: 'final_draft'
    },
    {
        primaryText: translate('APPROVED'),
        value: 'approved'
    }
]

class StatusPopover extends PureComponent<StatusPopoverProps> {
    onClosePopover = (closeReason, prevStatus) => {
        this.props.onClosePopover({ closeReason })
        this.props.onStatusChange(closeReason, prevStatus)
        this.props.actions.currentSelectedTab(EDITOR_TABS.EDITOR)
    }

    render() {
        const { isOpen, onRequestCloseExportPopover, anchorElement, closeReason } = this.props
        const anchorOrigin: any = { horizontal: 'left', vertical: 'bottom' }
        const targetOrigin: any = { horizontal: 'left', vertical: 'top' }

        return (
            <Menu
                open={isOpen}
                anchorEl={anchorElement}
                anchorOrigin={anchorOrigin}
                transformOrigin={targetOrigin}
                onClose={onRequestCloseExportPopover}
                getContentAnchorEl={null}>
                {menuItems.map((menuItem) =>
                    isHidden(closeReason, menuItem.value) ? null : (
                        <MenuItem
                            key={`popover-menu-item-${menuItem.value}`}
                            data-testid={`DocumentStatusMenuItem-${menuItem.value}`}
                            // TODO: for status logic of review process
                            disabled={isDisabled(closeReason, menuItem.value)}
                            className="dropDownFocus"
                            style={{ paddingRight: '75px' }}
                            onClick={this.onClosePopover.bind(this, menuItem.value, closeReason)}
                            selected={!!closeReason && closeReason === menuItem.value}>
                            <Subtitle type="subtitle1">{menuItem.primaryText}</Subtitle>
                        </MenuItem>
                    )
                )}
            </Menu>
        )
    }
}

const isDisabled = (currentStatus, menuItemValue) => {
    // return false for self
    if (currentStatus === menuItemValue) return false

    if (currentStatus === 'draft') {
        return false
    }
    if (currentStatus === 'in_review') {
        return menuItemValue === 'draft'
    }
    if (currentStatus === 'final_draft') {
        return false
    }
    if (currentStatus === 'approved') {
        return false
    }
    return true
}

const isHidden = (currentStatus, menuItemValue) => {
    if (currentStatus === 'in_review') {
        return menuItemValue === 'draft'
    }
    return false
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    const { currentSelectedTab } = editorActions
    return {
        actions: bindActionCreators(
            {
                currentSelectedTab
            },
            dispatch
        )
    }
}

export default connect(null, mapDispatchToProps)(StatusPopover)
