import { atlasFontFamily } from 'components/atlas-components/typography'
import { AppBarClassKey, AppBarProps } from '@material-ui/core/AppBar'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiAppBar: Partial<StyleRules<AppBarClassKey, AppBarProps>> = {
    root: {
        fontFamily: atlasFontFamily,
        boxShadow: 'none'
    }
}
