import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #000;
`
const DiligentBrandIcon = () => (
    <Svg
        width="116"
        height="32"
        viewBox="0 0 116 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M54.899 12.0635H52.1992V24.3256H54.899V12.0635Z" fill="white" />
        <path d="M54.899 7.4707H52.1992V10.5247H54.899V7.4707Z" fill="white" />
        <path d="M60.1661 7.2002H57.4663V24.327H60.1661V7.2002Z" fill="white" />
        <path d="M65.8756 12.0635H63.1758V24.3256H65.8756V12.0635Z" fill="white" />
        <path d="M65.8756 7.4707H63.1758V10.5247H65.8756V7.4707Z" fill="white" />
        <path
            d="M77.5383 13.5438C77.152 13.0604 76.6979 12.6725 76.1775 12.3801C75.477 11.9879 74.6684 11.7925 73.7545 11.7925C72.6475 11.7925 71.6586 12.053 70.7894 12.5726C69.9188 13.0937 69.2298 13.8058 68.7195 14.7104C68.2107 15.615 67.9556 16.6484 67.9556 17.8107C67.9556 18.957 68.2093 19.9904 68.7195 20.9109C69.2284 21.83 69.926 22.5552 70.8111 23.082C71.6961 23.6103 72.6921 23.8737 73.7992 23.8737C74.6986 23.8737 75.5073 23.6783 76.2222 23.2846C76.6792 23.0342 77.0727 22.71 77.4057 22.312V23.7362C77.4057 24.3687 77.2687 24.9129 76.9963 25.3645C76.7239 25.8175 76.3505 26.1606 75.8792 26.3936C75.4064 26.6266 74.8615 26.7439 74.2417 26.7439C73.459 26.7439 72.7988 26.5629 72.2612 26.2011C71.7221 25.8393 71.3574 25.3717 71.1657 24.7986L68.6648 25.7944C68.9156 26.5036 69.3134 27.1187 69.8597 27.6383C70.4046 28.1594 71.0547 28.5661 71.8071 28.8599C72.5595 29.1537 73.3783 29.3014 74.2633 29.3014C75.3992 29.3014 76.4067 29.0597 77.2846 28.5777C78.1624 28.0943 78.8514 27.4357 79.3545 26.5977C79.8561 25.7596 80.1069 24.8058 80.1069 23.7362V12.0631H77.5397V13.5438H77.5383ZM76.9862 19.6213C76.7051 20.1496 76.3188 20.5636 75.8244 20.8661C75.33 21.1671 74.7649 21.3191 74.1307 21.3191C73.4806 21.3191 72.9026 21.1642 72.3938 20.8545C71.8849 20.5462 71.4871 20.1308 71.1988 19.6097C70.9105 19.0887 70.7678 18.4982 70.7678 17.8338C70.7678 17.1695 70.912 16.5746 71.1988 16.0463C71.4871 15.518 71.8849 15.0997 72.3938 14.79C72.9026 14.4817 73.482 14.3254 74.1307 14.3254C74.7505 14.3254 75.3069 14.4803 75.8013 14.79C76.2957 15.0997 76.6864 15.518 76.9747 16.0463C77.263 16.5746 77.4057 17.1695 77.4057 17.8338C77.4057 18.4967 77.2658 19.093 76.9862 19.6213Z"
            fill="white"
        />
        <path
            d="M91.9567 13.5573C91.4623 12.9986 90.8569 12.5658 90.1419 12.2561C89.4255 11.9478 88.6111 11.7915 87.6958 11.7915C86.5744 11.7915 85.5639 12.0665 84.663 12.6165C83.7635 13.168 83.0515 13.922 82.5268 14.8788C82.0035 15.8369 81.7412 16.9268 81.7412 18.1484C81.7412 19.3555 81.9992 20.4454 82.5153 21.418C83.0313 22.3906 83.7549 23.1635 84.6846 23.7367C85.6144 24.3098 86.6839 24.5964 87.8933 24.5964C88.7192 24.5964 89.476 24.4676 90.1621 24.2114C90.8482 23.9552 91.4421 23.5977 91.9437 23.1375C92.4454 22.6772 92.8144 22.1605 93.0508 21.5873L90.8381 20.4786C90.5571 20.9765 90.1736 21.3804 89.6879 21.6887C89.2007 21.9984 88.6111 22.1533 87.9178 22.1533C87.2389 22.1533 86.6335 21.9912 86.103 21.6669C85.5711 21.3427 85.1661 20.8796 84.8864 20.276C84.7062 19.8867 84.5967 19.4568 84.5563 18.9864H93.3405C93.3996 18.8055 93.4371 18.6057 93.4515 18.3872C93.4659 18.1686 93.4731 17.9617 93.4731 17.7648C93.4731 16.9499 93.3434 16.1843 93.0854 15.4678C92.8273 14.7528 92.4511 14.116 91.9567 13.5573ZM84.8634 15.9672C85.1286 15.3564 85.5091 14.8889 86.0035 14.5647C86.498 14.2405 87.0616 14.0784 87.6972 14.0784C88.3459 14.0784 88.9066 14.2448 89.3794 14.5763C89.8522 14.9077 90.1981 15.3535 90.4201 15.9107C90.534 16.2002 90.5989 16.5085 90.6147 16.8385H84.6126C84.6659 16.5244 84.7481 16.2335 84.8634 15.9672Z"
            fill="white"
        />
        <path
            d="M103.863 12.3801C103.177 11.9879 102.391 11.7925 101.506 11.7925C100.65 11.7925 99.9021 11.9893 99.2606 12.3801C98.7792 12.6739 98.3986 13.0705 98.1204 13.5699V12.0631H95.5532V24.3253H98.253V17.1072C98.253 16.5341 98.3597 16.0405 98.5745 15.6251C98.7878 15.2097 99.0905 14.8899 99.4826 14.6641C99.8732 14.4383 100.32 14.3239 100.822 14.3239C101.309 14.3239 101.744 14.4368 102.128 14.6641C102.511 14.8899 102.809 15.2112 103.024 15.6251C103.238 16.0405 103.346 16.5341 103.346 17.1072V24.3253H106.045V16.4067C106.045 15.5021 105.854 14.7032 105.47 14.0084C105.084 13.3166 104.551 12.7724 103.863 12.3801Z"
            fill="white"
        />
        <path
            d="M114.796 21.9966C114.657 22.0111 114.527 22.0198 114.409 22.0198C113.952 22.0198 113.571 21.9445 113.269 21.794C112.966 21.6435 112.745 21.4249 112.604 21.1383C112.464 20.8518 112.394 20.4972 112.394 20.0745V14.5311H115.138V12.0633H112.395V9.2583H109.695V10.2078C109.695 10.7954 109.537 11.2528 109.22 11.577C108.902 11.9012 108.456 12.0633 107.88 12.0633H107.615V14.5296H109.695V20.2077C109.695 21.5653 110.057 22.6132 110.779 23.3528C111.501 24.0924 112.52 24.4615 113.834 24.4615C114.04 24.4615 114.272 24.447 114.531 24.4167C114.789 24.3863 115.014 24.3559 115.206 24.3255V21.9503C115.073 21.9662 114.936 21.9822 114.796 21.9966Z"
            fill="white"
        />
        <path
            d="M45.7487 8.53454C44.473 7.82533 42.971 7.47217 41.2456 7.47217H35.2002V24.3283H41.2456C42.9724 24.3283 44.473 23.9693 45.7487 23.2529C47.0243 22.5364 48.0132 21.545 48.7137 20.2771C49.4142 19.0106 49.7645 17.5473 49.7645 15.8872C49.7645 14.2285 49.4142 12.7652 48.7137 11.4973C48.0132 10.2309 47.0258 9.24375 45.7487 8.53454ZM46.2359 18.9991C45.7717 19.882 45.1187 20.5637 44.2769 21.0471C43.4366 21.5305 42.4406 21.7708 41.2888 21.7708H38.0096V10.0282H41.2888C42.4391 10.0282 43.4351 10.2656 44.2769 10.7403C45.1173 11.2151 45.7703 11.891 46.2359 12.7652C46.7 13.6409 46.9335 14.6729 46.9335 15.8655C46.9335 17.0712 46.7015 18.1162 46.2359 18.9991Z"
            fill="white"
        />
        <path
            d="M28.8693 15.9791C28.8693 20.8944 27.1173 24.9442 24.1222 27.7434C24.0877 27.7738 24.0618 27.8056 24.0273 27.836C21.7996 29.8812 18.895 31.2403 15.5132 31.7599L15.2847 31.5254L20.325 2.32461L20.5147 1.79053C25.6744 4.32922 28.8693 9.39648 28.8693 15.9791Z"
            fill="#EE312E"
        />
        <path
            d="M20.5164 1.79199L0 17.1081V31.4762V32.0001H12.3544C13.4481 32.0001 14.5016 31.9162 15.5149 31.7599C15.5149 31.7613 20.5164 1.79199 20.5164 1.79199Z"
            fill="#D3222A"
        />
        <path
            d="M20.5164 1.79185L20.3741 2.00461L0.106355 17.2078L0 17.108C0 17.108 0 17.1036 0 17.0993V0H12.307C13.1564 0 13.9799 0.0492107 14.7776 0.146185H14.8077C16.8227 0.386449 18.6782 0.914741 20.3382 1.70645C20.4043 1.7383 20.5164 1.79185 20.5164 1.79185Z"
            fill="#AF292E"
        />
    </Svg>
)
export default DiligentBrandIcon
