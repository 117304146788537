import React, { useState } from 'react'
import ConfirmationDialog from 'components/shared/ConfirmationDialog'
import i18n from 'i18next'
import { countlyEvent } from 'businesslayer/Countly'

export const useDeleteMinutesConfirmation = ({ onConfirm }) => {
    const [state, setState] = useState({
        showConfirmation: false,
        committeeId: undefined,
        minutesId: undefined
    })

    const handleOpenDeleteMinutesConfirmation = ({ committeeId, minutesId }) => {
        setState({ showConfirmation: true, committeeId, minutesId })
    }
    const handleCountlyDeleteCounting = (deleteOption: DeleteOptionType) => {
        countlyEvent('Mgr_DeleteModal_Options', { deleteOption: deleteOption })
    }

    const DeleteMinutesConfirmation = !!state.showConfirmation ? (
        <ConfirmationDialog
            key="deleteMinutesModal"
            isAtlas
            message={i18n.t('DELETE_MINUTES_MESSAGE')}
            title={i18n.t('DELETE_MINUTES_MODAL_TITLE')}
            onConfirm={() => {
                handleCountlyDeleteCounting('confirmDelete')
                onConfirm(state.committeeId, state.minutesId)
                setState({ showConfirmation: false, committeeId: undefined, minutesId: undefined })
            }}
            onCancel={() => {
                handleCountlyDeleteCounting('cancelDelete')
                setState({ showConfirmation: false, committeeId: undefined, minutesId: undefined })
            }}
            confirmLabel={i18n.t('DELETE')}
        />
    ) : null
    return { DeleteMinutesConfirmation, handleOpenDeleteMinutesConfirmation }
}
