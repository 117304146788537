import React, { Component } from 'react'

//#region Components

import DocumnetDetailsHeader from 'components/minutetaker/MinuteTakerHeader'
import MinutesDocumentDetail from 'components/minutetaker/MinutesDocumentDetail'

import FullscreenProgress from 'components/shared/FullscreenProgress'
import OfflineNotifier from 'components/shared/OfflineNotifier'
import DomHelper from 'components/helpers/DomHelper'
import selectors from '../../selectors/minuteTakerSelectors'
import ExportActionsDialog from 'components/minutetaker/components/ExportActionsDialog'

//#endregion

//#region Redux

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../reducers/minuteTakerReducer'
import { actions as editorActions } from '../../reducers/actionEditorReducer'
import { RouteComponentProps } from 'react-router-dom'
import { path, pathOr } from 'rambdax'
import { getMinutesDetail } from 'businesslayer/api/minutes'
import { getSections } from 'businesslayer/api/sections'
import { getActions, saveAction } from 'businesslayer/api/actions'
import { DialogSubscriber } from 'components/shared/context/dialog'
import { EditorProvider } from 'components/contexts/editor'
import { LengthStats, calculateLength, pendoTrack, rounded } from 'common/util/functions'
import { pendoEvents } from 'common/util/pendoEvents'
import { Box } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import LinearIndeterminate from 'components/shared/LinearProgress'

//#endregion

//#region Styles

const initialTeakerHeight = 640
const initialTeakerWidth = 1024

//#endregion

//#region Props
type ActionFn = (...args: any[]) => void

type Props = {
    actions: {
        fetchMinuteItemFulfilled: ActionFn
        loadActionsFulfilled: ActionFn
        saveExistingActionPending: ActionFn
        saveExistingActionRejected: ActionFn
        saveExistingActionFulfilled: ActionFn
        loadSections: ActionFn
        resetCurrentMinuteItem: ActionFn
        selectTab: ActionFn
        setCollapseAllVisibility: ActionFn
    }
    onSetCommittee: (committeeId: string) => void
    minutesActions: ActionItem[] | null
    currentMinuteItem: Minutes
    committeeRole: UserRole
    currentCommittee: Committee
    history: any
} & RouteComponentProps

type State = {
    height: number
    width: number
    isLoading: boolean
    hasAccess: boolean
}

enum SelectedTab {
    Taker = 0,
    Actions = 1
}

//#endregion

//#region Implementation
//Note: that is umbrella component comprising others
class MinutesDetailPage extends Component<Props, State> {
    _isMounted = false
    // TODO: UI Refactor the layout. Layout should be declarative
    // and not need to keep track of dimensions in state.
    state = {
        height: initialTeakerHeight,
        width: initialTeakerWidth,
        isLoading: true,
        hasAccess: false
    }
    action: any

    async componentDidMount() {
        this._isMounted = true
        this.loadData()
    }
    componentDidUpdate() {
        const { committeeRole, currentMinuteItem, currentCommittee } = this.props
        const url: string = pathOr('', 'match.path', this.props)

        if (url.includes('/taker/:minutesId/actions')) {
            this.props.actions.setCollapseAllVisibility(false)
        } else {
            this.props.actions.setCollapseAllVisibility(true)
        }

        const documentCommitteeId: string = pathOr(
            '',
            ['relationships', 'committee', 'data', 'id'],
            currentMinuteItem
        )
        const documentStatus: DocumentStatusKey = pathOr(
            '',
            ['attributes', 'status'],
            currentMinuteItem
        )
        const isLoaded =
            !!currentCommittee.id &&
            !!documentCommitteeId &&
            currentCommittee.id === documentCommitteeId

        if (isLoaded) {
            const hasAccess = hasDocumentAccess(committeeRole, documentStatus)

            // Redirect to Landing Page if User does not have access
            if (!hasAccess) this.handleRedirectToHome()
            if (this.state.hasAccess !== hasAccess) {
                this.setState({ hasAccess })
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false
        //We have to reset current minute item and the list on unload to prevent the following glitch:
        //When user comes back to the taker, it renders old item first
        //(as reducer still has old current item).
        //Then reducer gets new item loaded in an async way and UI re-renders itself again for new data.
        //This makes visible artifact of some controls preserving older geometry (sizes).
        //So if we clean reducer upon exit, next time when we come back,
        //the render will happen in much better way.
        this.props.actions.resetCurrentMinuteItem()
    }

    loadData = async (status?: string, prevStatus?: string) => {
        const minutesId: string = pathOr('-1', 'match.params.minutesId', this.props)
        const [minutesDetails, minutesSections, minutesActions] = await Promise.all([
            getMinutesDetail(minutesId, null, true),
            getSections(minutesId),
            getActions(minutesId)
        ])

        if (prevStatus === 'draft' && status === 'in_review') {
            let lengthObj = {}
            const {
                avgCharCount,
                avgWordCount,
                minCharCount,
                minWordCount,
                maxCharCount,
                maxWordCount
            }: LengthStats = calculateLength(minutesSections)
            lengthObj = {
                AVG_WORD_COUNT: avgWordCount,
                AVG_CHARACTER_COUNT: avgCharCount,
                MIN_WORD_COUNT: minWordCount,
                MIN_CHARACTER_COUNT: minCharCount,
                MAX_CHARACTER_COUNT: rounded(maxCharCount),
                MAX_WORD_COUNT: maxWordCount
            }

            pendoTrack(pendoEvents.SECTIONDETAILS, lengthObj)
        }

        // Get committee id of current minutes document and set that as the current committee id
        // in order to prevent errors while we refactor endpoints to not include committee ids any more
        if (!!minutesDetails?.committees) {
            this.props.onSetCommittee(Object.keys(minutesDetails.committees)[0])
        }

        if (this._isMounted) {
            this.props.actions.fetchMinuteItemFulfilled(minutesDetails)
            this.props.actions.loadSections(minutesSections)
            this.props.actions.loadActionsFulfilled(minutesActions)

            setTimeout(() => {
                !!this._isMounted && this.setState({ isLoading: false })
            }, 500)
        }
    }

    handleStatusChange = (status?: string, prevStatus?: string) => {
        this.loadData(status, prevStatus)
    }

    private async reloadActions() {
        const minutesId: string = pathOr('-1', 'match.params.minutesId', this.props)
        const data = await getActions(minutesId)

        this.props.actions.loadActionsFulfilled(data)
    }

    private handleActionChecked = (actionItem: ActionItem) => {
        if (actionItem.completionStatus === 'complete') {
            actionItem.completionStatus = 'incomplete'
        } else {
            actionItem.completionStatus = 'complete'
        }

        this.props.actions.saveExistingActionPending()
        try {
            saveAction(actionItem, false).then((result) => {
                this.props.actions.saveExistingActionFulfilled(result)
                this.reloadActions()
            })
        } catch (e) {
            this.props.actions.saveExistingActionRejected(e)
        }
    }

    private handleRedirectToHome = () => {
        const { history } = this.props
        const currentMinutesId = path<string>('match.params.minutesId', this.props)
        history.push({ pathname: '/', state: { currentMinutesId } })
    }

    private onResize = (evt) => {
        this.setState({ height: evt.height, width: evt.width })
    }

    private renderLoading = () => {
        return <LinearIndeterminate loading={this.state.isLoading} />
    }

    private setIsLoading = (isLoading) => {
        this.setState({ isLoading: isLoading })
    }

    render() {
        const { minutesActions } = this.props

        const normalizewdWidth = Math.max(this.state.width, 800)
        const selectedTabIndex = determineSelectedTab(this.props)

        return (
            <EditorProvider>
                <React.Fragment>
                    <Box style={{ flex: 'auto' }}>
                        <Box>
                            <DocumnetDetailsHeader
                                width={normalizewdWidth}
                                title={i18n.t('TOOLTIP_MINUTES')}
                            />
                            <Box>
                                {this.renderLoading()}
                                <Box>
                                    {this.state.hasAccess ? (
                                        <MinutesDocumentDetail
                                            minutesActions={minutesActions || []}
                                            onActionChecked={this.handleActionChecked}
                                            selectedTab={selectedTabIndex}
                                            currentMinuteItem={this.props.currentMinuteItem}
                                            onStatusChange={this.handleStatusChange}
                                            reloadActions={this.reloadActions.bind(this)}
                                            setIsLoading={this.setIsLoading}
                                        />
                                    ) : null}
                                </Box>
                            </Box>

                            <FullscreenProgress />
                            <DomHelper onResize={this.onResize} />
                            <OfflineNotifier />
                        </Box>
                    </Box>
                    <DialogSubscriber name="ExportActionsDialog">
                        {({ isDialogShown }) => {
                            return isDialogShown ? <ExportActionsDialog /> : null
                        }}
                    </DialogSubscriber>
                </React.Fragment>
            </EditorProvider>
        )
    }
}

const hasDocumentAccess = (committeeRole: UserRole, documentStatus: DocumentStatusKey) => {
    if (committeeRole === 'ADMIN') return true
    if (committeeRole === 'DIRECTOR' && documentStatus === 'in_review') return true

    return false
}

// Tooltips in ckeditor are 1000 and sit above navbar

//#endregion

//#region Export / Redux Bindings
const mapStateToProps = (state, _) => {
    return {
        minutesActions: selectors.minutesActions(state.minuteTakerReducer),
        selectedTabIndex: selectors.selectedTabIndex(state.minuteTakerReducer),
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        fetchMinuteItemFulfilled,
        resetCurrentMinuteItem,
        setCollapseAllVisibility,
        selectTab,
        loadActionsFulfilled,
        saveExistingActionPending,
        saveExistingActionRejected,
        saveExistingActionFulfilled,
        loadSections
    } = { ...actions, ...editorActions }

    return {
        actions: bindActionCreators(
            {
                fetchMinuteItemFulfilled,
                resetCurrentMinuteItem,
                setCollapseAllVisibility,
                loadActionsFulfilled,
                saveExistingActionPending,
                saveExistingActionRejected,
                saveExistingActionFulfilled,
                loadSections,
                selectTab
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinutesDetailPage)

//#endregion

const determineSelectedTab = (props) =>
    path('match.path', props) === '/taker/:minutesId'
        ? // || path('match.path', props) === '/review/:minutesId' ||
          // path('match.path', props) === '/final-draft/:minutesId' ||
          // path('match.path', props) === '/approved/:minutesId'
          SelectedTab.Taker
        : SelectedTab.Actions
