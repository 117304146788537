import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import { connect } from 'react-redux'
import i18n from 'common/util/internationalization'
import StatusErrorIcon from 'assets/icons/formatting/StatusErrorIcon'
import moment from 'moment'
import selector from 'selectors/minuteAttendeesSelectors'
import MeetingsLists, { MinutesUnnormalized } from './meetings-lists'
import { AtlasGreyLight, ColorBaseRichBlack } from 'assets/styles/variables'
import { CircularProgressLoader } from 'components/global-progress-modal/global-progress-modal'

interface Props {
    meetingList: {
        selectedCommittee: MinutesUnnormalized[]
    }
    startDate: string
    endDate: string
    minutesLoading: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.text.primary,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        padding: theme.spacing(3, 0, 0, 0)
    },
    accordionDetails: {
        paddingLeft: '0px',
        overflowY: 'auto'
    },
    meetingListContainer: {
        width: '100%',
        borderRadius: '4px',
        background: '#fff',
        gap: '1rem',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    defaultCss: {
        color: ColorBaseRichBlack,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '16px'
    },
    errorCss: {
        color: theme.palette.error.main,
        fontSize: '13px',
        lineHeight: '16px',
        fontStyle: 'normal',
        display: 'flex',
        padding: '14px 10px 14px 48px',
        alignItems: 'flex-start',
        gap: '8px',
        width: '100%',
        marginTop: '16px',
        borderRadius: '4px',
        border: `1px solid ${theme.palette.error.main}`,
        background: theme.palette.background.paper
    },
    errorIcon: {
        marginLeft: '-2rem',
        fill: theme.palette.error.main
    },
    innerErrorCss: {
        padding: '8px',
        borderBottom: `solid 1px ${AtlasGreyLight}`
    },
    loaderStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '104px'
    },
    loaderText: {
        paddingLeft: '1%',
        color: theme.palette.text.disabled,
        textAlign: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600
    },
}))

const ErrorNoMeetings: React.FC = () => {
    const classes = useStyles()
    return (
        <p className={classes.errorCss}>
            <StatusErrorIcon className={classes.errorIcon} />
            {i18n.t('NO_RELEVANT_MEETING')}
        </p>
    )
}

export const LoaderView: React.FC = React.memo(() => {
    const classes = useStyles()
    return (
        <div className={classes.loaderStyle}>
            <CircularProgressLoader size={22} />
            <h3 className={classes.loaderText}>{i18n.t('LOADING_INFO')}</h3>
        </div>
    )
})

const MinutesMeetingsComponent = React.memo(({ startDate, meetingList, endDate, minutesLoading }: Props) => {
    const classes = useStyles()

    const isValidMeetingsAndDates =
        meetingList.selectedCommittee?.length &&
        moment(startDate).isValid() &&
        moment(endDate).isValid()

    const isMeetingsExist =
        moment(startDate).isValid() &&
        moment(endDate).isValid() &&
        meetingList.selectedCommittee?.length === 0

    return (
        <>
            <Typography className={classes.root}>{i18n.t('MEETINGS')}</Typography>
            <AccordionDetails className={classes.accordionDetails}>
                <Box
                    className={classes.meetingListContainer}
                    style={isValidMeetingsAndDates ? { marginLeft: '8px' } : {}}>
                    {isValidMeetingsAndDates ? (
                        meetingList.selectedCommittee.map((value: MinutesUnnormalized, key) => (
                            <Box key={key} className={classes.innerErrorCss}>
                                <MeetingsLists meetingLists={value} />
                            </Box>
                        ))
                    ) : (
                        <Typography className={classes.defaultCss}>
                            {i18n.t('MEETING_LIST_REPORT')}
                        </Typography>
                    )}
                    {isMeetingsExist && <ErrorNoMeetings />}
                    {minutesLoading && <LoaderView />}
                </Box>
            </AccordionDetails>
        </>
    )
})
const mapStateToProps = (state: any) => {
    return {
        meetingList: selector.setNewReportData(state.minutesAttendeesReducer),
        startDate: selector.getStartDate(state.minutesAttendeesReducer),
        endDate: selector.getEndDate(state.minutesAttendeesReducer),
        minutesLoading: selector.getMinutesLoadingState(state.minutesAttendeesReducer)

    }
}

const ConnectedMinutesMeetingsComponent = connect(mapStateToProps, null)(MinutesMeetingsComponent)

const MinutesMeetings = React.memo(ConnectedMinutesMeetingsComponent)

export default MinutesMeetings
