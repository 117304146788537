import { exchangeToken, exchangeUuid } from 'businesslayer/networkrequests'
import { executeRequest } from 'businesslayer/request-executor'

export const exchangeAuthToken = (initialAuthToken, repeatAction) => {
    const request = exchangeToken(initialAuthToken)

    return executeRequest(request, null, null, { repeatAction, isForeground: false })
}

export const exchangeUuidToken = (uuid, repeatAction) => {
    const request = exchangeUuid(uuid)

    const result = executeRequest(request, null, null, { repeatAction, isForeground: false })

    return result
}
