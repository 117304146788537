import React from 'react'
import Loads from 'react-loads'
import * as requests from 'businesslayer/api/settings'
import { path } from 'rambdax'
import { getLocalStorageItem, getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import emitter from 'common/util/events'
import { initializePendo } from 'common/util/functions'
import LinearIndeterminate from 'components/shared/LinearProgress'

type InjectedGetSettingsProps = {
    settings: Settings
    load: () => void
    responseSettings: Settings
}

type GetSettingsProps = {
    children(props: InjectedGetSettingsProps): React.ReactNode
}

export const GetSettings = (props: GetSettingsProps) => (
    <Loads
        cacheKey={`${getCommitteeId()}-settings`}
        loadOnMount
        loadPolicy="load-only"
        fn={requests.getSettings}>
        {({ isSuccess, isError, isLoading, response, load }) => {
            const responseSettings: Settings | undefined = path(
                'settings.setting.attributes',
                response
            )

            if (isError) {
                emitter.emit('locked')
                return null
            }

            if (isLoading) {
                return <LinearIndeterminate loading={true} />
            }

            if (isSuccess && !!responseSettings) {
                const settings = finalizeSettings(responseSettings)
                initializePendo(settings, navigator.language)
                return props.children({ settings, load, responseSettings })
            }

            // If not success, error, or loading
            return null
        }}
    </Loads>
)

/**
 * Return Settings but overriden with browser storage values (userLanguage, platform).
 * Support for BoardsWeb: if they pass us language or platform in a GET parameter,
 * we've stored them in browser storage and takes priority.
 */
const finalizeSettings = (responseSettings: Settings): Settings => {
    // Check Browser Storage
    const userLanguage = getLocalStorageItem('userLanguage')
    const platform = getSessionStorageItem('parentPlatform') as Platform | ''

    return {
        ...responseSettings,
        ...(userLanguage && { userLanguage }),
        ...(platform && { platform })
    }
}

const getCommitteeId = () => getSessionStorageItem('currentCommitteeId')
