import { pathOr, path } from 'rambdax'
import { getJSON } from 'common/util/request'
import { executeRequest } from 'businesslayer/request-executor'

type DefaultFetcher = <Data = any>(url, fetcherOptions?: FetcherOptions) => Data | Promise<Data>

/**
 * Defines how SWR will fetch the remote data with default, app specific options
 * @param url
 * @param fetcherOptions?
 */
export const defaultFetcher: DefaultFetcher = (url, fetcherOptions?) => {
    const shouldNormalize = pathOr<boolean>(false, 'shouldNormalize', fetcherOptions)
    const fetcherCallback = path<FetcherOptions['fetcherCallback']>(
        'fetcherCallback',
        fetcherOptions
    )
    const onError = path<FetcherOptions['onError']>('onError', fetcherOptions)
    const onSuccess = path<FetcherOptions['onSuccess']>('onSuccess', fetcherOptions)

    const request = getJSON(url, { shouldNormalize }, fetcherCallback)

    return executeRequest(request, onSuccess, onError, undefined)
}

/**
 * Allows us to set customize the default fetcher on a per hook basis.
 * We can define things like onError, onSuccess, and data normalization.
 * @param fetcherOptions
 */
export const makeFetcher = (fetcherOptions?: FetcherOptions) => {
    return (url) => defaultFetcher(url, fetcherOptions)
}
