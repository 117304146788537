import React, { FC, useCallback, useEffect, useState } from 'react'

import { Field, FormSpy } from 'react-final-form'

import { AtlasDatePickerAdapter } from 'components/date-time-pickers/date-picker'
import { LabelCss } from 'components/meeting-date-time-control/meeting-date-fields'
import { css } from '@emotion/css'
import moment from 'moment'
import { getAtlasHelperText } from 'components/text-field/input-error-text'
import { AtlasStatusError, ColorBaseWhite } from 'assets/styles/variables'
import { makeStyles } from '@material-ui/core/styles'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { differentDueDateOnChange } from '../../util'
import i18n from 'common/util/internationalization'

const useStyles = makeStyles(() => ({
    copyBtn: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        color: '#455D82',
        fontWeight: 600,
        verticalAlign: 'middle',
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px #0774EE`,
            borderRadius: '2px'
        }
    },
    dateField: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px',
        margin: '10px 0',
        '& > div > div > div > div': {
            marginTop: '0px !important'
        }
    },
    reviewerSection: {
        width: '60%',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#1E1E1E',
        margin: '10px 0'
    }
}))

type Props = {
    val?: PersonChip
    k: number
    values?: DueDateFormValues
    form?: any
    stepTwoError: DueDateError
    setStepTwoError: React.Dispatch<React.SetStateAction<DueDateError>>
}
const DifferentDueDate: FC<Props> = (props) => {
    const classes = useStyles()
    const [selectedDate, setSelectedDate] = useState<Moment | null | string>(null)
    const { dateFormatInput } = transientStorageManager
    const validMap = props?.stepTwoError?.recipients?.length > 0
    const copyToClipboard = useCallback(
        (e) => {
            e.preventDefault()

            if (selectedDate) {
                const formatDate = moment(selectedDate).format(dateFormatInput)
                navigator.clipboard
                    .writeText(formatDate)
                    .then(() => {})
                    .catch(() => {})
            }
        },
        [selectedDate, dateFormatInput]
    )
    useEffect(() => {
        const abortController = new AbortController()
        return () => {
            abortController.abort()
            // cleanup function to prevent memory leak
        }
    }, [])
    return (
        <tr>
            <td className={classes.reviewerSection}>{props.val?.name ?? props.val?.text}</td>
            <FormSpy
                onChange={({ values }) => {
                    setTimeout(() => {
                        differentDueDateOnChange(selectedDate, dateFormatInput, props, values)
                    }, 0)
                }}
            />
            <td className={classes.dateField}>
                <div>
                    <Field
                        name={`recipients[${props.k}].due_date`}
                        data-testid="MinutesProperties_MeetingDatePicker"
                        className={css`
                            ${LabelCss}
                            width: auto;
                            fieldset {
                                border-color: ${validMap &&
                                props?.stepTwoError?.recipients[props.k]?.due_date &&
                                AtlasStatusError} !important;
                            }
                        `}
                        value={selectedDate}
                        render={({ input, value, ...props }) => {
                            setTimeout(() => {
                                setSelectedDate(input.value)
                            }, 0)

                            return (
                                <div>
                                    <AtlasDatePickerAdapter
                                        value={input.value}
                                        onChange={input.onChange}
                                        input={input}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'checkbox' }}
                                        {...props}
                                    />
                                </div>
                            )
                        }}
                        helperText={null}
                        showErrorWhen={true}
                    />
                    {props.stepTwoError?.recipients?.length > 0 &&
                        getAtlasHelperText({
                            errorText: props?.stepTwoError?.recipients[props.k]?.due_date
                        })}
                </div>

                <button className={classes.copyBtn} onClick={copyToClipboard}>
                    {i18n.t('COPY')}
                </button>
            </td>
        </tr>
    )
}

export default DifferentDueDate
