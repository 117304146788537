import textValidator from 'businesslayer/validation/textValidator'
import { PersonChip } from 'components/chip-input/types'
import moment from 'moment'
import i18n from 'i18next'

export const MIN_RECIPIENTS = 1
export const MAX_RECIPIENTS = 100

export const MIN_SUBJECT = 1
export const MAX_SUBJECT = 500

export const MIN_MESSAGE = 1
export const MAX_MESSAGE = 10000

/**
 * Validate To
 * @param chips
 */
export const validateTo = (chips: PersonChip[]) => {
    if (chips.length < MIN_RECIPIENTS || chips.length > MAX_RECIPIENTS) {
        return i18n.t('INVALID_EMAILS_COUNT')
    } else {
        return ''
    }
}

/**
 * Validate Subject
 * @param subject
 */
export const validateSubject = (subject: string) => {
    if (!textValidator.isLengthValid(subject, MIN_SUBJECT, MAX_SUBJECT)) {
        return i18n.t('INVALID_EMAIL_SUBJECT')
    } else {
        return ''
    }
}

/**
 * Validate Message
 * @param message
 */
export const validateMessage = (message: string) => {
    if (!textValidator.isLengthValid(message, MIN_MESSAGE, MAX_MESSAGE)) {
        return i18n.t('INVALID_EMAIL_BODY')
    } else {
        return ''
    }
}

/**
 * Validate date
 * @param date
 * @param format
 */
export const validateDate = (date: any, format: string, dateText: string) => {
    if (!date && !dateText) {
        return ''
    }
    if (!moment(date, format, true).isValid()) {
        return i18n.t('INVALID_MINUTES_DATE_FORMAT', { mask: format })
    } else {
        return ''
    }
}
