import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: transparent;
`

type Props = {
    title?: string
    className?: string
}

const RevealListIcon = ({ title, className }: Props) => (
    <Svg
        width="20"
        height="12"
        viewBox="0 0 20 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}>
        <title id="reveal-list-icon">{title}</title>
        <path
            d="M1 1.75L9.9955 10.75L19 1.75"
            strokeWidth="1.75"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </Svg>
)

export default RevealListIcon
