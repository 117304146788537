import { atlasTextBody } from 'components/atlas-components/typography'
import { AtlasGreyLight } from 'assets/styles/variables'
import { DialogContentClassKey, DialogContentProps } from '@material-ui/core/DialogContent'
import { StyleRules } from '@material-ui/core/styles/withStyles'

export const MuiDialogContent: Partial<StyleRules<DialogContentClassKey, DialogContentProps>> = {
    root: {
        ...atlasTextBody,
        borderBottom: `2px solid ${AtlasGreyLight}`,
        width: '100%',
        padding: '24px'
    }
}
