import { getMinutesDocuments } from 'businesslayer/api/minutesDocuments'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { useCallback, useEffect, useState } from 'react'
export const useMinutesList = () => {
    const [documentLists, setDocumentLists] = useState<DocumentsList[]>([])
    const [isLoading, setLoading] = useState(false)
    const [totalDocuments, setTotalDocuments] = useState(0)

    const fetchMinutesDocuments = useCallback(
        async (status?: string, committeeId?: any, page?: any, sortBy?: any, order?: any) => {
            status = status === 'all' ? '' : status
            setLoading(false)
            try {
                let data = await getMinutesDocuments(
                    page,
                    status,
                    committeeId,
                    sortBy,
                    order,
                    getMinutesDocuments.bind(null, page, status, committeeId, sortBy, order)
                )
                const documents =
                    data.documents.length > 0 &&
                    data.documents.map((obj) => ({
                        ...obj,
                        committee: { id: obj.committee_id, name: obj.committee_name }
                    }))
                if (data.documents.length > 0 && page > 1) {
                    setDocumentLists((prev) => [...prev, ...documents])
                } else {
                    setDocumentLists(documents)
                    setTotalDocuments(data.total_count)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(true)
            }
        },
        []
    )
    useEffect(() => {
        const selectedStatusFilter = getSessionStorageItem('selectedStatusFilter')
        const selectedCommitteeFilter = getSessionStorageItem('selectedCommitteeFilter')
        if (selectedStatusFilter || selectedCommitteeFilter) {
            fetchMinutesDocuments(selectedStatusFilter, selectedCommitteeFilter, 1)
        } else {
            fetchMinutesDocuments()
        }
    }, [fetchMinutesDocuments])

    return { documentLists, fetchMinutesDocuments, isLoading, totalDocuments }
}
