import React, { FC, useMemo } from 'react'
import { Container, withFont } from 'assets/sharedStyles'
import { Field } from 'react-final-form'
import { AtlasSelectAdapter } from 'components/atlas-material/select'
import { linkedBookStructureMenuItems } from 'components/popovers/linked-book-structure/linked-book-structure-items'
import styled from '@emotion/styled'
import i18n from 'i18next'

type Props = {
    bookTitle: string | number | null | undefined
}

export const BookStructureField: FC<Props> = ({ bookTitle }) => {
    const memoLinkedBookStructureMenuItems = useMemo(() => linkedBookStructureMenuItems(), [])

    return (
        <>
            <Container width={270}>
                <Field
                    name={`bookStructure`}
                    render={AtlasSelectAdapter}
                    label={null}
                    data-testid="MinutesProperties_BookStructure"
                    children={memoLinkedBookStructureMenuItems}
                    showErrorWhen={true}
                />
            </Container>
            <BookStructureText>{i18n.t('BOOK_STRUCTURE_LABEL', { bookTitle })}</BookStructureText>
        </>
    )
}

const BookStructureText = withFont('text-medium-grey')(styled.div`
    margin-left: 24px;
`)
