import { useState, useEffect } from 'react'

import {
    getSessionStorageItem,
    setLocalStorageItem,
    getLocalStorageItem,
    removeLocalStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem
} from 'businesslayer/minutesLocalStore'
import { exchangeAuthToken } from 'businesslayer/api/identity'
import { useBoardsIOSBridgeContext } from 'components/contexts/boards-ios/js-bridge'

type AuthTokens = {
    initialAuthToken: string | null
    sessionId: string | null
}

export const useAuthentication = (authTokens: AuthTokens, parentPlatform) => {
    let sessionStorageToken // TODO: walk around for BoardEffect
    const isBoardEffect = parentPlatform === 'boardeffect'
    if (isBoardEffect || !getLocalStorageItem('sessionId')) {
        sessionStorageToken = getSessionStorageItem('sessionId')
    } else {
        sessionStorageToken = getLocalStorageItem('sessionId')
    }

    const [hasAuthToken, setHasAuthToken] = useState(!!sessionStorageToken)
    const [isExchanging, setIsExchanging] = useState(true)
    const [isCallExchangeApi, setIsCallExchangeApi] = useState<boolean>(true)

    const sessionPlatform = getSessionStorageItem('parentPlatform')
    const isBoardsIOS = sessionPlatform === 'boardsios'

    const { requestAuthToken: requestBiosAuthToken } = useBoardsIOSBridgeContext()

    // Setup Re-authentication for boards
    useEffect(() => {
        const onReauthenticate = (sessionToken: string) => {
            if (isBoardEffect) {
                // TODO: walk around for BoardEffect
                removeLocalStorageItem('sessionId')
                setSessionStorageItem('sessionId', sessionToken)
            } else {
                setLocalStorageItem('sessionId', sessionToken)
                setSessionStorageItem('sessionId', sessionToken)
            }

            setHasAuthToken(true)
        }
        //If we are part of Boards and we got 401 we have to call shell app back
        window.handleReauthenticate = (sessionToken) => onReauthenticate(sessionToken)

        return () => {
            window.handleReauthenticate = undefined
        }
    }, [isBoardEffect])
    useEffect(() => {
        /* * * Authenticate non-BoardsIOS User * * */
        const isNonBoardIos = !isBoardsIOS && !hasAuthToken && isCallExchangeApi

        if (isNonBoardIos || parentPlatform) {
            // TODO: walk around for BoardEffect
            // Async function to authenticate user
            const callExchangeAuthToken = async (initialAuthToken, sessionId) => {
                setIsExchanging(true)
                const { isAuthToken } = await initialAuthExchange(
                    initialAuthToken,
                    sessionId,
                    isNonBoardIos,
                    isBoardEffect,
                    setIsCallExchangeApi
                )
                setHasAuthToken(isAuthToken)
                setIsExchanging(false)
            }

            callExchangeAuthToken(authTokens.initialAuthToken, authTokens.sessionId)
        }
    }, [isBoardsIOS, authTokens, hasAuthToken, parentPlatform, isBoardEffect, isCallExchangeApi])

    useEffect(() => {
        /* * * Authenticate BoardsIOS User * * */
        if (isBoardsIOS && !hasAuthToken) {
            setIsExchanging(true)
            requestBiosAuthToken((resolvedAuthToken) => {
                // TODO: Remove log after integration testing

                if (isBoardEffect) {
                    // TODO: walk around for BoardEffect
                    removeLocalStorageItem('sessionId')
                    setSessionStorageItem('sessionId', resolvedAuthToken)
                } else {
                    setLocalStorageItem('sessionId', resolvedAuthToken)
                    setSessionStorageItem('sessionId', resolvedAuthToken)
                }

                setHasAuthToken(!!resolvedAuthToken)
                setIsExchanging(false)
            })
        }
    }, [isBoardsIOS, hasAuthToken, requestBiosAuthToken, isBoardEffect])

    // General Re-authentication
    const reauthenticate = () => {
        // Embedded chromium
        if (window.cefQuery) {
            //Timeout is used here because if we call for parent app right away,
            //it blocks Javascript thread and no lock screen is shown
            setTimeout(() => {
                window.cefQuery({
                    request: 'reauthenticate',
                    persistent: false
                })
            }, 200)
        }

        // Clear current authentication values

        if (isBoardEffect) {
            // TODO: walk around for BoardEffect
            removeSessionStorageItem('sessionId')
        } else {
            removeLocalStorageItem('sessionId')
        }

        setHasAuthToken(false)
    }

    // Authenticated User; Proceed with loading the app
    return { isExchanging, hasAuthToken, reauthenticate }
}

const initialAuthExchange = async (
    initialAuthToken,
    sessionId,
    isNonBoardIos,
    isBoardEffect,
    setIsCallExchangeApi
) => {
    if (initialAuthToken && isNonBoardIos) {
        try {
            const exchangeToken = initialAuthToken || sessionId
            const exchangeTokenResponse = await exchangeAuthToken(
                exchangeToken,
                exchangeAuthToken.bind(null, exchangeToken)
            )
            if (exchangeTokenResponse.sessionId) {
                setIsCallExchangeApi(false)
            }
            setOrClearSessionId(exchangeTokenResponse.sessionId, isBoardEffect)
        } catch (error) {
            if (isBoardEffect) {
                // TODO: walk around for BoardEffect
                removeSessionStorageItem('sessionId')
            } else {
                removeLocalStorageItem('sessionId')
            }
            return { isAuthToken: false }
        }
    } else {
        if (sessionId) {
            if (isBoardEffect) {
                // TODO: walk around for BoardEffect

                removeLocalStorageItem('sessionId')
                setSessionStorageItem('sessionId', sessionId)
            } else {
                setLocalStorageItem('sessionId', sessionId)
                setSessionStorageItem('sessionId', sessionId)
            }
        }
    }
    if (isBoardEffect || !getLocalStorageItem('sessionId')) {
        // TODO: walk around for BoardEffect
        return { isAuthToken: !!getSessionStorageItem('sessionId') }
    } else {
        return { isAuthToken: !!getLocalStorageItem('sessionId') }
    }
}

const setOrClearSessionId = (sessionId, isBoardEffect) => {
    // TODO: walk around for BoardEffect
    if (!!sessionId) {
        if (isBoardEffect) {
            removeLocalStorageItem('sessionId')
            setSessionStorageItem('sessionId', sessionId)
        } else {
            setLocalStorageItem('sessionId', sessionId)
            setSessionStorageItem('sessionId', sessionId)
        }
    } else {
        if (isBoardEffect) {
            removeSessionStorageItem('sessionId')
        } else {
            removeLocalStorageItem('sessionId')
        }
    }
}
