import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
    fill: #2379a0;
`

type Props = {
    title?: JSX.Element
    className?: string
}

export const ClearIcon = ({ className }: Props) => (
    <Svg
        width="16"
        height="16"
        className={className}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6668 12.6667L3.33392 3.33337M12.6668 3.33422L3.3335 12.6667L12.6668 3.33422Z"
            stroke="#1E1E1E"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
)

export default ClearIcon
