import React from 'react'

//#region Components

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'

import { ColorBaseDarkRed, ColorBaseGreyDark, ColorBaseRed } from 'assets/styles/variables'
import { Form } from 'react-final-form'
import { CommitteeContextConsumer } from 'components/contexts/committee-context'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import CheckboxField from 'components/minutetaker/components/form/CheckboxField'
import RadioButtonGroupField from 'components/minutetaker/components/form/RadioButtonGroupField'
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    RadioButton,
    FormControlLabel
} from 'components/shared/StyledComponents'

//#endregion

//#region Implementation

type Props = {
    onExportActionsSubmit: any
    isSavable: any
}

export class ExportActionsForm extends React.Component<Props> {
    getSelectionStatus(form: any, committeeMap: Map<string, string>) {
        const values = form.getState().values
        const committeeNames = Object.keys(values)
            .filter(this.isCommitteeSelected(values))
            .map((name) => {
                return committeeMap.get(name.substring(name.lastIndexOf('_') + 1))
            })

        const statusString =
            values.include_committees === 'include_all'
                ? i18n.t('INCLUDE_ALL')
                : committeeNames.length === 0
                ? i18n.t('NO_SELECTION')
                : committeeNames.length === 1
                ? committeeNames[0]
                : i18n.t('MULTIPLE_SELECTIONS')

        const type = statusString === i18n.t('NO_SELECTION') ? 'error' : 'default'
        return { statusString, type }
    }

    updateSavable = (form, committeeMap) => {
        this.props.isSavable(
            this.getSelectionStatus(form, committeeMap).type === 'error' ? false : true
        )
    }

    onSubmit = (values, form) => {
        const committeeIds =
            values.include_committees === 'include_all'
                ? form
                      .getRegisteredFields()
                      .filter((key) => key.includes('committee_'))
                      .map((name) => {
                          return name.substring(name.lastIndexOf('_') + 1)
                      })
                : Object.keys(values)
                      .filter(this.isCommitteeSelected(values))
                      .map((name) => {
                          return name.substring(name.lastIndexOf('_') + 1)
                      })

        this.props.onExportActionsSubmit(committeeIds.join())
    }

    isCommitteeSelected = (values) => (name) => {
        return name.includes('committee_')
            ? values.include_committees === 'include_only'
                ? !!values[name]
                : true
            : false
    }

    renderSelectionStatus = ({ form, committeeMap }) => {
        const { statusString, type } = this.getSelectionStatus(form, committeeMap)
        return <SelectionStatus type={type}>{statusString}</SelectionStatus>
    }

    renderIncludeOnlyLable = ({ form, committeeMap }) => {
        const statusString =
            this.getSelectionStatus(form, committeeMap).statusString === i18n.t('NO_SELECTION')
                ? '(' + i18n.t('NO_SELECTION') + ')'
                : ''
        return (
            <ClassNames>
                {({ css }) => (
                    <span
                        className={css`
                            .MuiTypography-body1 {
                                font-family: 'Source Sans Pro';
                            }
                        `}>
                        {translate('INCLUDE_ONLY')}
                        <SelectionValidation>{statusString}</SelectionValidation>
                    </span>
                )}
            </ClassNames>
        )
    }

    render() {
        const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
        return (
            <CommitteeContextConsumer>
                {({ committees }) => {
                    const committeeMap = new Map()
                    committees.forEach((committee) => {
                        committeeMap.set(committee.id, committee.name)
                    })

                    // Set Initial Values for final-form
                    const initialValues = {
                        include_committees: 'include_only'
                    }
                    if (!!currentCommitteeId) {
                        const defaultCommittee = 'committee_' + currentCommitteeId
                        initialValues[defaultCommittee] = true
                    }

                    return (
                        <ClassNames>
                            {({ css }) => (
                                <div
                                    className={css`
                                        .MuiTypography-body1 {
                                            font-family: source sans pro;
                                        }
                                        .MuiFormControlLabel-root {
                                            font-family: source sans pro;
                                            font-weight: 400;
                                            font-size: 14px;
                                            line-height: 18px;
                                        }
                                    `}>
                                    <Form
                                        initialValues={initialValues}
                                        onSubmit={this.onSubmit}
                                        render={({ form, values, handleSubmit }) => {
                                            const validateCommittees = () => {
                                                this.updateSavable(form, committeeMap)
                                            }
                                            return (
                                                <ClassNames>
                                                    {({ css }) => (
                                                        <ExpansionPanel defaultExpanded>
                                                            <ExpansionPanelDetails>
                                                                <div>
                                                                    <form
                                                                        className={css`
                                                                            font-family: Source sans
                                                                                pro;
                                                                            font-style: normal;
                                                                            font-weight: 400;
                                                                            font-size: 16px;
                                                                            line-height: 20px;
                                                                        `}
                                                                        id="exportActionsForm"
                                                                        onSubmit={handleSubmit}>
                                                                        <RadioButtonGroupField
                                                                            name="include_committees"
                                                                            defaultSelected={
                                                                                initialValues.include_committees
                                                                            }>
                                                                            {() => {
                                                                                return [
                                                                                    <FormControlLabel
                                                                                        name="include_all"
                                                                                        key="include_all"
                                                                                        value="include_all"
                                                                                        control={
                                                                                            <RadioButton
                                                                                                disableFocusRipple
                                                                                            />
                                                                                        }
                                                                                        label={translate(
                                                                                            'INCLUDE_ALL'
                                                                                        )}
                                                                                    />,
                                                                                    <FormControlLabel
                                                                                        name="include_only"
                                                                                        key="include_only"
                                                                                        value="include_only"
                                                                                        control={
                                                                                            <RadioButton
                                                                                                disableRipple
                                                                                            />
                                                                                        }
                                                                                        label={this.renderIncludeOnlyLable(
                                                                                            {
                                                                                                form,
                                                                                                committeeMap
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                ]
                                                                            }}
                                                                        </RadioButtonGroupField>
                                                                        <div className="radioStyle">
                                                                            {CheckboxArea(
                                                                                values.include_committees ===
                                                                                    'include_all',
                                                                                committees,
                                                                                validateCommittees
                                                                            )}
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                    )}
                                                </ClassNames>
                                            )
                                        }}
                                    />
                                </div>
                            )}
                        </ClassNames>
                    )
                }}
            </CommitteeContextConsumer>
        )
    }
}

//#endregion

//#region Styles

const CheckboxArea = (isDisabled, committees, validateCommittees) =>
    committees.map((committee) => {
        return (
            <CheckboxField
                key={committee.id}
                name={'committee_' + committee.id}
                label={committee.name}
                isDisabled={isDisabled}
                validate={validateCommittees}
            />
        )
    })

const SelectionStatus = styled('div')<{ type: string }>`
    width: 100%;
    color: ${({ type }) => (type === 'error' ? ColorBaseRed : ColorBaseGreyDark)};
`

const SelectionValidation = styled('span')`
    color: ${ColorBaseDarkRed};
    padding-left: 3px;
    font-size: 11pt;
`
//#endregion

//#region export
export default ExportActionsForm
//#endregion
