//#region Components
import React from 'react'
import { Field } from 'react-final-form'
import { FormControlLabel, Checkbox } from 'components/shared/StyledComponents'
//#endregion

//#region Implementation
export const CheckboxField = ({ name, label, isDisabled, validate }) => (
    <Field
        name={name}
        validate={validate}
        render={({ input: { onChange, ...rest } }) => {
            return (
                <FormControlLabel
                    control={<Checkbox disableRipple disableFocusRipple onChange={onChange} />}
                    label={label}
                    disabled={isDisabled}
                    {...rest}
                />
            )
        }}
        type="checkbox"
    />
)
//#endregion

export default CheckboxField
