import React from 'react'

import moment from 'moment'
import styled from '@emotion/styled'
import { translate } from './internationalization'
import { ColorBaseBlueGreyMedium } from 'assets/styles/variables'

type SaveStatusProps = {
    updatedDate: string
    dateFormat: string
    timeFormat: string
    status: 'saving' | 'saved'
}

export const SaveStatus = ({ dateFormat, timeFormat, updatedDate, status }: SaveStatusProps) => {
    const { saveLabel, dateText, timeText } = determineSaveText({
        dateFormat,
        timeFormat,
        status,
        updatedDate
    })

    return (
        <Wrapper>
            <StyledSaveLabel>
                <SaveLabelStatus>{saveLabel}</SaveLabelStatus>
                <DateTimeText>
                    {timeText} {dateText}
                </DateTimeText>
            </StyledSaveLabel>
        </Wrapper>
    )
}

const SaveLabelStatus = styled('div')`
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
`
const DateTimeText = styled('div')`
    text-wrap: nowrap;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    display: inline;
    margin-left: 2px;
`

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    min-width: 72px;
`

const StyledSaveLabel = styled('div')`
    display: contents;
    word-wrap: nowrap;
    overflow-wrap: nowrap;
    white-space: normal;
    word-break: nowrap;
    font-size: 9px;
    color: ${ColorBaseBlueGreyMedium};
`

const determineSaveText = ({ dateFormat, timeFormat, status, updatedDate }: SaveStatusProps) => {
    let saveLabel: JSX.Element | undefined = undefined
    const dateText = updatedDate ? moment(updatedDate).format(dateFormat) : ''
    const timeText = updatedDate ? moment(updatedDate).format(timeFormat) : ''

    if (updatedDate) {
        const isSavingProcess = status === 'saving'

        saveLabel = isSavingProcess
            ? translate('SAVING_PROCESS_LABEL')
            : translate('SAVED_PROCESS_LABEL')
    }

    return { saveLabel, dateText, timeText }
}
